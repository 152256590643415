import React from 'react';

import cx from 'classnames';

import QuizButton from './QuizButton';

import iconCross from '../../static/img/icon-cross.svg';
import iconTick from '../../static/img/icon-tick.svg';
import infoIcon from '../../static/img/info_icon.svg';

const QuizFeedback = ({ feedback, identifier, isCorrect, text, isInformation = false }) => {
  const iconSuffix = isCorrect ? 'correct' : 'incorrect';

  const icon = {
    classNameSuffix: isInformation ? 'info' : iconSuffix,
    name: isCorrect ? 'tick' : 'cross',
    src: isCorrect ? iconTick : iconCross,
  };

  return (
    <div className="h-with-small-x-padding">
      <QuizButton className="c-quiz-button--selected" identifier={identifier} text={text} />
      <div className={`c-quiz-feedback c-quiz-feedback--${icon.classNameSuffix}`}>
        <div className="c-quiz-feedback__icon">
          <img alt={`${icon.name} icon`} className="c-quiz-img" src={isInformation ? infoIcon : icon.src} />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: feedback,
          }}
        />
      </div>
    </div>
  );
};

export default QuizFeedback;
