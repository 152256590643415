import React from 'react';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const CartoonAsset = ({ contentType, isAnimatedImage, s3Key, onLoadHandler }) => {
  const signedUrl = getSignedUrl(s3Key, contentType);

  return isAnimatedImage ? (
    <object className="c-cartoon-image-asset" title="cartoon slide" onLoad={onLoadHandler} type="image/svg+xml" data={signedUrl} />
  ) : (
    <img className="c-cartoon-image-asset" onLoad={onLoadHandler} alt="" src={signedUrl} />
  );
};

export default CartoonAsset;
