import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import AudioIcon from '../../../static/img/Media/Audio.svg';
import PauseIcon from '../../../static/img/Media/Pause.svg';
import PlayIcon from '../../../static/img/Media/Play.svg';

const PlayBtn = styled.button.attrs(props => ({
  disabled: props.disabled,
  title: props.tooltip,
}))`
  background: url(${PlayIcon}) no-repeat 50% 50%;
  background-size: 0.3em;
  border: none;
  border-radius: 50%;
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  height: 100%;
  outline: none;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const PauseBtn = styled(PlayBtn)`
  background: url(${PauseIcon}) no-repeat 50% 50%;
  background-size: 0.4em;
`;

const AudioBtn = styled(PlayBtn)`
  background: url(${AudioIcon}) no-repeat 50% 50%;
  background-size: 0.5em;
`;

const Outter = styled.div`
  display: flex;
  justify-content: center;
  background-color: #4c4d4f;
  border-radius: 50%;
  cursor: pointer;
  font-size: 3.2em;
  height: 3.3rem;
  margin: ${({ margin }) => (margin ? margin : '0.6em 0 0 0')};
  position: relative;
  width: 3.3rem;
`;

const Slice = styled.div`
  clip: rect(0, 3.3rem, 3.3rem, 0.5em);
  cursor: pointer;
  height: 3.3rem;
  position: absolute;
  width: 3.3rem;
`;

const Fill = styled.div`
  border: 0.4rem solid #4bc1c9;
  border-radius: 50%;
  clip: rect(0, 0.5em, 3.3rem, 0);
  height: 3.3rem;
  position: absolute;
  transform: ${props => `rotate(${360 * props.percent}deg) ` || 'rotate(0deg)'};
  width: 3.3rem;
`;

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTrack: null,
      player: 'stopped',
      percent: null,
    };
  }

  componentDidMount() {
    this.player.addEventListener('timeupdate', e => {
      const tmp = e.target.currentTime / e.target.duration;
      this.setState({
        percent: tmp,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTrack, player } = this.state;
    if (selectedTrack !== prevState.selectedTrack) {
      const track = selectedTrack;
      if (track) {
        this.player.src = track;
        this.player.play();
        this.handleStatus('playing');
      }
    }
    if (player !== prevState.player) {
      if (player === 'paused') {
        this.player.pause();
      } else if (player === 'stopped') {
        this.player.pause();
        this.player.currentTime = 0;
        this.handleStatus('stopped');
      } else if (player === 'playing' && prevState.player === 'paused') {
        this.player.play();
      }
    }
  }

  componentWillUnmount() {
    this.player.removeEventListener('timeupdate', () => {});
  }

  handleStatus(status, selected) {
    const { isClicked } = this.props;
    if (status !== 'stopped') {
      this.setState({ player: status });
    } else {
      this.setState({ selectedTrack: null });
    }
    if (status === 'frist') {
      this.setState({ player: 'playing', selectedTrack: selected });
    }
    isClicked();
  }

  audioRef(ref) {
    this.player = ref;
  }

  render() {
    const { player, percent } = this.state;
    const { selected, style, sideLengths, margin, disabled, cursor, tooltip } = this.props;
    return (
      <div style={style}>
        {player === 'stopped' && (
          <Outter margin={margin} sideLengths={sideLengths}>
            <AudioBtn title={tooltip} disabled={disabled} cursor={cursor} onClick={() => this.handleStatus('frist', selected)} />
            <Slice>
              <Fill />
            </Slice>
          </Outter>
        )}
        {player === 'paused' && (
          <Outter margin={margin} sideLengths={sideLengths}>
            <PlayBtn onClick={() => this.handleStatus('playing')} />
            <Slice>
              <Fill percent={percent} />
            </Slice>
          </Outter>
        )}
        {player === 'playing' && (
          <Outter margin={margin} sideLengths={sideLengths}>
            <PauseBtn onClick={() => this.handleStatus('paused')} />
            <Slice>
              <Fill percent={percent} />
            </Slice>
          </Outter>
        )}

        <audio ref={ref => this.audioRef(ref)}>
          <track kind="captions" srcLang="en" />
        </audio>
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  isClicked: PropTypes.func,
  selected: PropTypes.string,
  style: PropTypes.shape({}),
};

AudioPlayer.defaultProps = {
  isClicked: () => {},
  selected: '',
  style: {},
};

export default AudioPlayer;
