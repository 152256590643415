import gql from 'graphql-tag';

export const EXPORT_ALL_MODULE_PAGES = gql`
  query ExportAllModulePages($ianaTimeZone: String!, $userFlowId: Int!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportAllModulePages(
        ianaTimeZone: $ianaTimeZone
        userFlowId: $userFlowId
        dateFrom: $dateFrom
        dateTo: $dateTo
        fileType: $fileType
      ) {
        contentType
        s3Key
      }
    }
  }
`;
