import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../context/AppContext';
import ThemeContext from '../../context/ThemeContext';
import GetContentGroup from '../../middleware/ContentGroup/getContentGroup';
import GetContentSectionLayouts from '../../middleware/ContentSection/getContentSectionLayouts';
import GetContentSections from '../../middleware/ContentSection/getContentSections';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';
import GetProject from '../../middleware/Project/getProject';
import GetAllConditions from '../../middleware/QuestionAction/getAllConditionsOnContentGroup';

const PageInitial = (props) => {
  const {
    match: { params },
    getData,
    pageId,
    refreshData,
  } = props;
  const { org, updateOrg, project, updateProject, contentGroup, updateContentGroup } = useContext(AppContext);
  const { updateLogo, updateTheme } = useContext(ThemeContext);

  const [orgLoaded, setOrgLoaded] = useState(true);
  const [projectLoaded, setProjectLoaded] = useState(true);
  const [groupLoaded, setGroupLoaded] = useState(false);
  const [activePageId, setActivePageId] = useState(pageId);
  const [sectionArray, setSectionArray] = useState([]);
  const [dndSections, setDndSections] = useState({});
  const [sectionSortArray, setSectionSortArray] = useState(null);
  const [layoutLoad, setLayoutLoad] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [sectionLoad, setSectionLoad] = useState(true);
  const [answersLoad, setAnswersLoad] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [snippetArray, setSnippetArray] = useState([]);
  const [conditionsLoaded, setConditionsLoaded] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (!org.id || Number(params.orgId) !== org.id) {
      setOrgLoaded(false);
    }
    if (!project.id || params.projectId !== project.id) {
      setProjectLoaded(false);
    }
    // if (!contentGroup.id || Number(params.groupId) !== contentGroup.id) {
    //   setGroupLoaded(false);
    // }
  }, [contentGroup.id, org.id, params.groupId, params.orgId, params.projectId, project.id]);

  useEffect(() => {
    if (orgLoaded && projectLoaded && groupLoaded && layoutLoad && sectionLoad) {
      const data = {
        organisation: {
          id: org.id,
          name: org.name,
        },
        project: {
          id: project.id,
          name: project.name,
        },
        group: {
          id: contentGroup.id,
          name: contentGroup.title,
          type: contentGroup.type,
          formType: contentGroup.formType,
        },
        section: {
          sectionArray,
        },
        snippetArray,
        layout: {
          layoutArray,
        },
        answers,
        conditions,
        sectionSortArray,
        dndSections,
        activePageId,
        pageInitialized: true,
      };
      if (isInit) {
        getData(data);
      } else {
        refreshData(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgLoaded, projectLoaded, groupLoaded, layoutLoad, sectionLoad, answersLoad, pageId]);

  const handleOrgError = () => {
    setOrgLoaded(true);
  };

  const handleGetOrganisation = (data) => {
    updateOrg(data);
    setOrgLoaded(true);
  };

  const handleProjectError = () => {
    setProjectLoaded(true);
  };

  const handleGetProject = (data) => {
    updateProject(data);
    if (data.theme) {
      updateTheme(data.theme);
    }
    if (data.logo) {
      // setLogo
      updateLogo(data.logo);
    }
    setProjectLoaded(true);
  };

  const handleGroupError = () => {
    setGroupLoaded(true);
    setSectionLoad(false);
  };

  const handleGetGroup = (data) => {
    updateContentGroup(data);
    const sortedPages = data.contentPages.length > 0 && data.contentPages.sort((a, b) => a.sort - b.sort);
    const firstPageId = sortedPages && sortedPages.length > 0 && sortedPages[0].id;
    const currentPageId = data.contentPages.length === 0 ? null : firstPageId;
    if (currentPageId === null) {
      setSectionArray([]);
      setDndSections({});
      setSectionSortArray([]);
      setSectionLoad(true);
    }
    if (!pageId) {
      setActivePageId(currentPageId);
    }
    setGroupLoaded(true);
    setIsInit(true);
    if (data.contentPages.length === 0) setSectionLoad(true);
    else setSectionLoad(false);
  };

  const handleConditionsError = () => {
    setConditionsLoaded(true);
  };

  const handleGetAllConditions = (data) => {
    setConditions(data);
    setConditionsLoaded(true);
  };

  const handleLoadAnswersSuccess = (data) => {
    if (!answersLoad) {
      data.forEach((item) => {
        item.sectionContainers.forEach((ite) => {
          ite.snippets.forEach((it) => {
            // eslint-disable-next-line no-underscore-dangle
            if (it.__typename === 'ContentSnippetFormType') {
              const tmp = it.form.contentPages[0].contentSections[0].sectionContainers[0].snippets;
              tmp.forEach((i) => {
                const tmpAns = answers;
                if (i.answers) {
                  const tp = i.answers;
                  tp.questionId = i.id;
                  tmpAns.push(tp);
                }
                setAnswers(tmpAns);
              });
            }
          });
        });
      });
    }
    setAnswersLoad(true);
  };

  const handleSectionsError = (errorMessage) => {
    setSectionLoad(true);
  };

  // **** Get data from backend and transfor to dnd required format ******
  const handleGetSections = (value) => {
    // *****************************************************************//
    // Format data to dnd data. Very complex, don't touch these -- Luke //
    // react-beautiful-dnd requires "The dnd id must be a string".      //
    // I create a dndId for identifing droppableId and draggableId.     //
    // For each level, data needs an array of IDs and an object contains//
    // all elements in next level.                                      //
    // *****************************************************************//
    const tmpSnip = [];
    const sortArray = value // section dndId array
      .sort((a, b) => a.sort - b.sort)
      .map((item) => `section-${item.id}`);
    const DndSections = {}; // object for sections
    let sIndex = 0;
    value.map((item) => {
      const { visible } = item;

      const containers = {}; // object for containers
      const containerOrder = []; // container dndId array
      item.sectionContainers
        .sort((a, b) => a.sort - b.sort)
        .map((citem) => {
          citem.dndId = `container-${citem.id}`;
          containerOrder.push(citem.dndId);
          containers[citem.dndId] = citem;
          containers[citem.dndId].snippetOrder = citem.snippets.sort((a, b) => a.sort - b.sort).map((sitem) => `snippet-${sitem.id}`); // container dndId array
          const snippets = {};
          citem.snippets
            .sort((a, b) => a.sort - b.sort)
            .map((sitem) => {
              sIndex += 1;
              sitem.index = sIndex;
              // These data is for dnd feature
              sitem.dndId = `snippet-${sitem.id}`;
              snippets[sitem.dndId] = sitem;
              tmpSnip.push(sitem);
              return null;
            });
          // dnd object for snippets
          containers[citem.dndId].dndSnippets = snippets;
          return null;
        });

      DndSections[`section-${item.id}`] = {
        id: item.id,
        dndId: `section-${item.id}`,
        contentPageId: item.contentPageId,
        contentLayoutId: item.contentLayoutId,
        sort: item.sort,
        background: item.background,
        backgroundColor: item.backgroundColor,
        borderColor: item.borderColor,
        cssStyle: item.cssStyle,
        style: item.style,
        contentEvent: item.contentEvent,
        containers,
        groupId: contentGroup.id,
        groupType: contentGroup.type,
        organisationId: org.id,
        projectId: project.id,
        type: layoutArray.find((obj) => obj.id === item.contentLayoutId).name,
        // I have no ideas about containerNumber
        containerNumber: layoutArray.find((obj) => obj.id === item.contentLayoutId).containerNumber,
        containerOrder,
        visible,
      };
      handleLoadAnswersSuccess(value);
      return null;
    });
    setSnippetArray(tmpSnip);
    setSectionArray(value);
    setDndSections(DndSections);
    setSectionSortArray(sortArray);
    setSectionLoad(true);
  };

  const handleLayoutError = () => {
    setLayoutLoad(true);
  };

  const handleGetLayout = (value) => {
    setLayoutLoad(true);
    setLayoutArray(value);
  };

  return (
    <>
      {!orgLoaded && <GetOrganisation id={Number(params.orgId)} onError={handleOrgError} onSuccessResult={handleGetOrganisation} />}
      {!projectLoaded && <GetProject id={params.projectId} onError={handleProjectError} onSuccessResult={handleGetProject} />}
      {/* Conditional, check param for form vs content, */}
      {/* if form pass pageId */}
      {!groupLoaded && (
        <GetContentGroup contentGroupId={Number(params.groupId)} onError={handleGroupError} onSuccessResult={handleGetGroup} />
      )}
      {!layoutLoad && <GetContentSectionLayouts onError={handleLayoutError} onSuccessResult={handleGetLayout} />}
      {!sectionLoad && activePageId && layoutArray.length > 0 && (
        <GetContentSections contentPageId={activePageId} onError={handleSectionsError} onSuccessResult={handleGetSections} />
      )}
      {!conditionsLoaded && (
        <GetAllConditions
          contentGroupId={Number(params.groupId)}
          onError={handleConditionsError}
          onSuccessResult={handleGetAllConditions}
        />
      )}
    </>
  );
};

PageInitial.propTypes = {
  getData: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired,
      orgId: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  pageId: PropTypes.number,
};

PageInitial.defaultProps = {
  pageId: null,
};

export default PageInitial;
