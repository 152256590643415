import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const RATE_MYAPP = gql`
  mutation updateMyRate(
    $rateId: Int!
    $rate: Int!
    $comment: String!
    $title: String!
  ) {
    app {
      updateRate(
        inputType: {
          rateId: $rateId
          rate: $rate
          comment: $comment
          title: $title
        }
        rateId: $rateId
      )
    }
  }
`;

const updateRateApp = ({
  rateId,
  rate,
  title,
  comment,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={RATE_MYAPP}>
    {(updateMyRate, { loading, error, data }) => {
      const variables = {
        rateId,
        rate,
        comment,
        title,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.updateRate);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateMyRate}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateRateApp.propTypes = {
  comment: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  rate: PropTypes.number.isRequired,
  rateId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

updateRateApp.defaultProps = {};

export default updateRateApp;
