import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const SEARCH_SLIDERS = gql`
  query searchSliders($projectId: String!, $title: String) {
    slider {
      searchSliders(projectId: $projectId, title: $title) {
        id
        title
      }
    }
  }
`;

const searchSliders = ({ projectId, title, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={SEARCH_SLIDERS}
    variables={{ projectId, title }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={Object.entries(data).length !== 0 && data.slider.searchSliders}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);
searchSliders.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

searchSliders.defaultProps = {
  title: '',
};

export default searchSliders;
