import React from 'react';

import { SettingsButton, LinkButton } from '@netfront/ui-library';

import { StatusIndicator } from '../../components/StatusIndicator/StatusIndicator';

import './LibraryFormPage.css';

const FORMS_TABLE_COLUMNS = [
  {
    accessor: 'title',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Title</div>,
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Description</div>,
  },
  {
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <StatusIndicator status={value} />
      </div>
    ),
    Header: () => <div>Status</div>,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { title, id, onClick, orgId, projectId } }) => (
      <div className="c-forms-table__settings">
        <SettingsButton supportiveText={`${title} settings`} onClick={() => onClick(id)} />
        <LinkButton size="small" text="Open" url={`/dashboard/${orgId}/project/${projectId}/form-builder/form/${id}/?library=true`} />
      </div>
    ),
    Header: '',
    width: '0.5rem',
  },
];

export { FORMS_TABLE_COLUMNS };
