import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS, getItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

import { IUseGetUserFlowsOnCompletedResponse, useGetUserFlows } from '../useGetUserFlows';
import {
  getToolBoxData,
  getToolBoxItems,
  IUseToolBoxOnCompletedResponse,
  TOOLBOX_USER_FLOW_IDS_STORAGE_EXPIRY_OPTIONS,
  useToolBox,
} from '../useToolBox';

import { TOOLBOX_ACTIVITIES_CONTENT_SNIPPET_TYPES } from './useToolBoxActivities.constants';
import { IUseToolBoxActivities, IUseToolBoxActivitiesOptions } from './useToolBoxActivities.interfaces';

import { IDBContentPage, IToolBoxActivitiesData, IToolBoxActivitiesContentSnippet, IToolBoxData } from '../../interfaces';

const {
  MESSAGES: {
    ERROR: { NOT_FOUND },
  },
} = COMMON_LIBRARY_CONSTANTS;

const useToolBoxActivities = ({
  fetchPolicy,
  projectId,
  onCompleted,
  onError,
  product,
  token,
  userFlowIdsStorageExpiryTimeOptions,
}: IUseToolBoxActivitiesOptions): IUseToolBoxActivities => {
  const [userFlowIdsFromLocalStorage, setUserFlowIdsFromLocalStorage] = useState<string | undefined>(
    getItemFromLocalStorage('userFlowIds')
  );

  const handleGetContentSnippetsForToolBoxCompleted = (data?: IUseToolBoxOnCompletedResponse) => {
    if (!data) {
      return;
    }

    if (!onCompleted) {
      return;
    }

    const { contentSnippets: returnedContentSnippets } = data;

    const filteredContentSnippets = returnedContentSnippets.filter(({ __typename }) =>
      TOOLBOX_ACTIVITIES_CONTENT_SNIPPET_TYPES.includes(__typename)
    );

    const toolboxData = filteredContentSnippets.reduce(
      (accumulator, currentContentSnippet) => {
        const { contentPages, contentSnippets, groupingContentGroups, userFlows, userFlowSteps } = accumulator;
        const { app, contentPage, form, id: contentSnippetId, __typename: typeName } = currentContentSnippet;

        const toolboxItems = getToolBoxItems(contentPage as IDBContentPage, typeName);

        const currentToolboxData: IToolBoxData = {
          contentPages,
          groupingContentGroups,
          userFlows,
          userFlowSteps,
        };

        const { toolboxContentPage, toolboxGroupingContentGroup, toolboxUserFlow, toolboxUserFlowStep } = toolboxItems;

        const {
          contentPages: combinedContentPages,
          groupingContentGroups: combinedGroupingContentGroups,
          userFlows: combinedUserFlows,
          userFlowSteps: combinedUserFlowSteps,
        } = getToolBoxData(currentToolboxData, toolboxItems);

        const toolboxContentSnippet: IToolBoxActivitiesContentSnippet = {
          app,
          contentPageId: toolboxContentPage.id,
          form,
          groupingContentGroupId: toolboxGroupingContentGroup ? toolboxGroupingContentGroup.id : undefined,
          typeName,
          userFlowId: toolboxUserFlow.id,
          id: contentSnippetId,
          userFlowStepId: toolboxUserFlowStep.id,
        };

        const combinedContentSnippets = sortBy(
          uniqBy([...contentSnippets, toolboxContentSnippet], ({ id }) => id),
          'sort'
        );

        return {
          ...accumulator,
          contentPages: combinedContentPages,
          contentSnippets: combinedContentSnippets,
          groupingContentGroups: combinedGroupingContentGroups,
          userFlows: combinedUserFlows,
          userFlowSteps: combinedUserFlowSteps,
        };
      },
      {
        contentPages: [],
        contentSnippets: [],
        groupingContentGroups: [],
        userFlows: [],
        userFlowSteps: [],
      } as IToolBoxActivitiesData
    );

    onCompleted({
      toolboxData,
    });
  };

  const handleGetContentSnippetsForToolBoxError = (error: ApolloError) => {
    if (!onError) {
      return;
    }

    onError(error);
  };

  const handleGetUserFlowsCompleted = (data?: IUseGetUserFlowsOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { userFlows } = data;

    if (!userFlows.length) {
      throw new Error(`No user flows found for this project id=${projectId}`);
    }

    const userFlowIds = userFlows.map(({ id }) => id).join(',');

    saveItemWithExpiryToLocalStorage(
      'userFlowIds',
      userFlowIds,
      userFlowIdsStorageExpiryTimeOptions ?? TOOLBOX_USER_FLOW_IDS_STORAGE_EXPIRY_OPTIONS
    );

    setUserFlowIdsFromLocalStorage(userFlowIds);
  };

  const handleGetUserFlowsError = (error: ApolloError) => {
    if (!onError) {
      return;
    }

    onError(error);
  };

  const { handleGetContentSnippetsForToolBox, isLoading: isGetContentSnippetsForToolBoxLoading = false } = useToolBox({
    fetchPolicy,
    onCompleted: handleGetContentSnippetsForToolBoxCompleted,
    onError: handleGetContentSnippetsForToolBoxError,
    product,
    token,
  });

  const { handleGetUserFlows, isLoading: isGetUserFlowsLoading = false } = useGetUserFlows({
    fetchPolicy,
    onCompleted: handleGetUserFlowsCompleted,
    onError: handleGetUserFlowsError,
    product,
    token,
  });

  useEffect(() => {
    if (userFlowIdsFromLocalStorage) {
      return;
    }

    handleGetUserFlows({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFlowIdsFromLocalStorage]);

  const handleGetToolBoxActivities = () => {
    if (!userFlowIdsFromLocalStorage) {
      throw new Error(`User flow ids ${NOT_FOUND}`);
    }

    const userFlowIds = userFlowIdsFromLocalStorage.split(',').map((userFlowId) => Number(userFlowId));

    handleGetContentSnippetsForToolBox({
      projectId,
      shouldIncludeApps: true,
      shouldIncludeAudios: false,
      shouldIncludeContentPage: true,
      shouldIncludeDocuments: false,
      shouldIncludeEmbeds: false,
      shouldIncludeForms: true,
      shouldIncludeUserFlowStep: true,
      shouldIncludeVideos: false,
      userFlowIds,
    });
  };

  return {
    handleGetToolBoxActivities,
    isLoading: isGetContentSnippetsForToolBoxLoading || isGetUserFlowsLoading,
    isReady: Boolean(userFlowIdsFromLocalStorage),
  };
};

export { useToolBoxActivities };
