import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const ADD_TAG_PROJECT = gql`
  mutation addTag($projectId: String!, $tagName: String!) {
    tagMutation {
      addTagInProject(projectId: $projectId, tagName: $tagName) {
        id
        name
      }
    }
  }
`;

const addTagInProject = ({ projectId, tagName, onError, onSuccessResult }) => (
  <Mutation mutation={ADD_TAG_PROJECT}>
    {(addTag, { loading, error, data }) => {
      const variables = {
        projectId,
        tagName,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.tagMutation.addTagInProject);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={addTag}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

addTagInProject.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  tagName: PropTypes.string.isRequired,
};

export default addTagInProject;
