import { DocumentNode, gql } from '@apollo/client';

import { CONTENT_PAGE_CONTEXT_GRAPHQL } from '../../contexts';
import { DBQuestionConfigurationTypeType } from '../../interfaces';

export type UserGeneratedResponseQuestionConfigurationTypeType = Extract<DBQuestionConfigurationTypeType, 'Checkbox' | 'Radio'>;

const ADD_USER_GENERATED_CHECKBOX_RESPONSE_MUTATION = gql`
  mutation addUserGeneratedCheckboxResponse($contentSnippetId: Int!, $label: String!, $questionResponseSetId: Int!) {
    questionSet {
      addAvailableAnswer(
        isUserGeneratedResponse: true
        responseCheckbox: { contentSnippetId: $contentSnippetId, label: $label, scoreValue: 0, value: "" }
        responseSetId: $questionResponseSetId
      ) {
        availableResponses(fetchUserGeneratedResponses: true) {
          asset {
            ...asset
          }
          id
          label
          ... on QuestionResponseCheckboxType {
            scoreValue
            sort
            userId
          }
          value
        }
        id
      }
    }
  }

  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.asset}
`;

const ADD_USER_GENERATED_RADIO_RESPONSE_MUTATION = gql`
  mutation addUserGeneratedRadioResponse($contentSnippetId: Int!, $label: String!, $questionResponseSetId: Int!) {
    questionSet {
      addAvailableAnswer(
        isUserGeneratedResponse: true
        responseRadio: { contentSnippetId: $contentSnippetId, label: $label, scoreValue: 0, value: "" }
        responseSetId: $questionResponseSetId
      ) {
        availableResponses(fetchUserGeneratedResponses: true) {
          asset {
            ...asset
          }
          id
          label
          ... on QuestionResponseRadioType {
            scoreValue
            sort
            userId
          }
          value
        }
        id
      }
    }
  }

  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.asset}
`;

const QUESTION_CONFIGURATION_TYPE_ADD_USER_GENERATED_RESPONSE_MUTATION_MAP: Record<
  UserGeneratedResponseQuestionConfigurationTypeType,
  DocumentNode
> = {
  Checkbox: ADD_USER_GENERATED_CHECKBOX_RESPONSE_MUTATION,
  Radio: ADD_USER_GENERATED_RADIO_RESPONSE_MUTATION,
};

export { QUESTION_CONFIGURATION_TYPE_ADD_USER_GENERATED_RESPONSE_MUTATION_MAP };
