/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import ContentSnippet from '../ContentSnippet/ContentSnippet';
import Icon from '../Icon/Icon';
import PlusBtn from '../UI/Button/PlusButtonCircle';
import Tooltip from '../UI/Tooltip/Tooltips';

import AppContext from '../../context/AppContext';
import AdvIcon from '../../static/img/contentSnippet/advanced.svg';

const PlusBtnWrapper1 = styled.div`
  margin-right: 0.6em;
`;

const PlusBtnWrapper2 = styled.div`
  margin-top: 1em;
  visibility: hidden;
`;

const AddContent = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  top: 45%;
  width: 100%;
`;

const EditBtn = styled.div`
  background-color: #33878d;
  border-radius: 5px 5px 0 0;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 14px;
  height: 20px;
  left: 1.1rem;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: -20px;
  width: 40px;
  z-index: 10;

  @media screen and (prefers-reduced-motion: reduce) {
    &:hover {
      height: 30px;
      padding-top: 5px;
      top: -30px;
      transition: none;

      /* background-color: #44bed3; */
    }
  }

  img {
    filter: brightness(0) invert(1);
    width: 14px;
  }

  &:hover {
    height: 30px;
    padding-top: 5px;
    top: -30px;
    transition: top height font-size 4s ease;

    /* background-color: #44bed3; */
  }
`;

const DeleteBtn = styled(EditBtn)`
  background-color: #4bc2ca;
  left: 3.7rem;
`;

const Wrapper = styled.div`
  background-color: ${props => (props.isDragging ? '#e3fcef' : props.bgColor)};
  border: 1px solid;
  border-color: ${props => props.borderColor || 'transparent'};
  border-radius: 5px;
  color: ${props => (props.isDragging ? 'red' : '#000')};
  display: flex;
  flex-direction: column;
  min-height: 10em;
  padding: 1em;
  position: relative;
  width: ${props => props.width || '100%'};

  &:hover {
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #f4f5f7;
    ${PlusBtnWrapper2} {
      visibility: visible;
    }
    ${EditBtn} {
      display: block;
    }
  }
`;

const InnerWrapper = styled.div`
  border-radius: 5px;
  flex-grow: 1;
  min-height: 100px;
  background-color: ${({ isDraggingOver, draggingFromThisWith }) => {
    const draggingFromThisWithColor = draggingFromThisWith ? '#e6fcff' : 'transparent';
    return isDraggingOver ? '#ffebe6' : draggingFromThisWithColor;
  }};
`;

const ContentContainer = props => {
  const { container, index, width, answers, identities, sectionId, onAddSnippet, onUpdateSnippet, onDeleteSnippet, onDeleteContainer } =
    props;

  const { handleShowSidebar } = useContext(AppContext);

  const handleAddSnippet = id => {
    onAddSnippet(id);
  };

  const handleEditContainer = () => {
    handleShowSidebar([
      true,
      'CONTAINER',
      [
        sectionId,
        container.id,
        {
          borderColor: container.borderColor,
          backgroundColor: container.backgroundColor,
          contentEventId: container.contentEventId,
          contentEvent: container.contentEvent && {
            animation: container.contentEvent.animation,
            animationDirection: container.contentEvent.animationDirection,
            entityId: container.contentEvent.entityId,
            event: container.contentEvent.event,
            eventDirection: container.contentEvent.eventDirection,
            eventTitle: container.contentEvent.eventTitle,
            infinite: container.contentEvent.infinite,
            millisecondDelay: container.contentEvent.millisecondDelay,
            millisecondSpeed: container.contentEvent.millisecondSpeed,
            asset: container.contentEvent.asset && {
              assetId: container.contentEvent.asset.assetId,
              contentType: container.contentEvent.asset.contentType,
              s3Key: container.contentEvent.asset.s3Key,
            },
            backgroundColor: container.contentEvent.backgroundColor,
            borderColor: container.contentEvent.borderColor,
            titleColor: container.contentEvent.titleColor,
            style: container.contentEvent.style,
            triggerStyle: container.contentEvent.triggerStyle,
            iconHorizontalAlignment: container.contentEvent.iconHorizontalAlignment,
          },
        },
      ],
    ]);
  };

  const handleDeleteContainer = () => {
    container.sectionId = sectionId;
    onDeleteContainer(container);
  };

  const containerLength = container.snippetOrder.length;

  const addContent = (
    <AddContent onClick={() => handleAddSnippet(container.id)}>
      <PlusBtnWrapper1>
        <PlusBtn onClick={() => handleAddSnippet(container.id)} />
      </PlusBtnWrapper1>
      <p>Add Content</p>
    </AddContent>
  );

  const snippets = container.snippetOrder.map((sinppetId, index) => {
    const currentSnippet = container.dndSnippets[sinppetId];
    return (
      <ContentSnippet
        answers={answers}
        containerId={container.id}
        identities={identities}
        index={index}
        key={sinppetId}
        onUpdateSnippet={onUpdateSnippet}
        onDeleteSnippet={onDeleteSnippet}
        sectionId={sectionId}
        snippet={currentSnippet}
      />
    );
  });

  return (
    <>
      <Draggable draggableId={container.dndId} index={index}>
        {(provided, snapshot) => (
          <Wrapper
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            bgColor={container.backgroundColor || 'transparent'}
            borderColor={container.borderColor}
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            width={width}
          >
            <Tooltip
              text="Container settings"
              triggerElement={() => (
                <EditBtn onClick={handleEditContainer}>
                  <img alt="edit icon" src={AdvIcon} />
                </EditBtn>
              )}
            />
            <Tooltip
              text="Delete container"
              triggerElement={() => (
                <DeleteBtn onClick={handleDeleteContainer}>
                  <Icon id="icon-trash" title="trash" style={{ width: '1.9rem', height: '1rem' }} />
                </DeleteBtn>
              )}
            />
            <Droppable direction="vertical" droppableId={container.dndId} type="snippet">
              {(provided, snapshot) => (
                <InnerWrapper
                  draggingFromThisWith={snapshot.draggingFromThisWith}
                  isDraggingOver={snapshot.isDraggingOver}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {containerLength === 0 && addContent}
                  {snippets}
                  {provided.placeholder}
                  {containerLength > 0 && (
                    <PlusBtnWrapper2>
                      <PlusBtn onClicked={() => handleAddSnippet(container.id)} />
                    </PlusBtnWrapper2>
                  )}
                </InnerWrapper>
              )}
            </Droppable>
          </Wrapper>
        )}
      </Draggable>
    </>
  );
};

ContentContainer.propTypes = {
  container: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    contentEvent: PropTypes.shape({
      animation: PropTypes.string,
      animationDirection: PropTypes.string,
      asset: PropTypes.shape({
        assetId: PropTypes.string,
        contentType: PropTypes.string,
        s3Key: PropTypes.string,
      }),
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
      entityId: PropTypes.number,
      event: PropTypes.string,
      eventDirection: PropTypes.string,
      eventTitle: PropTypes.string,
      infinite: PropTypes.bool,
      millisecondDelay: PropTypes.number,
      millisecondSpeed: PropTypes.number,
      style: PropTypes.string,
      titleColor: PropTypes.string,
      triggerStyle: PropTypes.string,
      iconHorizontalAlignment: PropTypes.string,
    }),
    contentEventId: PropTypes.number,
    dndId: PropTypes.string,
    dndSnippets: PropTypes.object,
    id: PropTypes.number,
    snippetOrder: PropTypes.array,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onAddSnippet: PropTypes.func.isRequired,
  onUpdateSnippet: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      questionId: PropTypes.number,
    }),
  ),
  identities: PropTypes.shape({}),
};

ContentContainer.defaultProps = {
  answers: [],
  identities: {},
};

export default ContentContainer;
