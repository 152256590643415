import { getAccessTokenCookie } from '@netfront/common-library';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import fetch from 'cross-fetch';

import introspectionQueryResultData from '../schema/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_EKARDO_API_URL,
  fetch,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAccessTokenCookie(),
  },
}));

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
});

export default client;
