/* eslint-disable @typescript-eslint/indent */
import { camelCase, pascalCase } from 'change-case';

import {
  COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP,
  COOKIE_EXPIRED_DATE,
  COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP,
} from './useCookie.constants';
import { ICookieOptionalAttributesInput, ICookieOptionalAttributes, ICookie, ICreateSpecificCookieParams } from './useCookie.interfaces';

const createCookie = (name: string, value: string, optionalAttributesInput?: ICookieOptionalAttributesInput): ICookie => {
  const optionalAttributes = optionalAttributesInput
    ? ((): ICookieOptionalAttributes => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { path = '/', sameSite = 'Lax', secure, storageExpiryOptions, ...remainingOptionalAttributes } = optionalAttributesInput;

        const { expiryTime } = storageExpiryOptions ?? {};
        const { unit: expiryTimeUnit, value: expiryTimeValue } = expiryTime ?? {};

        const expires =
          expiryTimeUnit && expiryTimeValue
            ? COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP[expiryTimeUnit](new Date(), expiryTimeValue).toUTCString()
            : undefined;

        return {
          ...remainingOptionalAttributes,
          expires,
          path,
          sameSite,
          secure: sameSite === 'None' ? true : secure,
        };
      })()
    : ((): ICookieOptionalAttributes => {
        return {
          sameSite: 'Lax',
        };
      })();

  return {
    name,
    value,
    ...optionalAttributes,
  };
};

const getAllCookies = (): Map<string, string> | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return document.cookie.split('; ').reduce((accumulator, cookie) => {
    const [name, value] = cookie.split('=');

    accumulator.set(name, value);

    return accumulator;
  }, new Map<string, string>());
};

const getCookieValue = (name: string): string | undefined => {
  return getAllCookies()?.get(name);
};

const serializeCookie = (cookie: ICookie): string => {
  const { name, value, ...optionalAttributes } = cookie;
  const { httpOnly, maxAge, secure } = COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP;

  const optionalAttributesMap = Object.keys(optionalAttributes).reduce((accumulator, key) => {
    const optionalCookieAttribute = cookie[key];

    const cookieKey = key === maxAge.interfaceKey ? maxAge.attributeName : pascalCase(key);
    const cookieAttributeValue = key === httpOnly.interfaceKey || key === secure.interfaceKey ? '' : `=${optionalCookieAttribute}`;

    if (optionalCookieAttribute) {
      accumulator.set(cookieKey, `${cookieKey}${cookieAttributeValue}`);
    }

    return accumulator;
  }, new Map());

  const optionalAttributesMapValues = Array.from<string>(optionalAttributesMap.values());
  const serializedOptionalAttributes = optionalAttributesMapValues.join('; ');
  const outputSerializedOptionalAttributes = serializedOptionalAttributes ? `; ${serializedOptionalAttributes}` : '';

  return `${name}=${value}${outputSerializedOptionalAttributes}`;
};

const setCookie = (cookie: ICookie): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const serializedCookie = serializeCookie(cookie);

  document.cookie = serializedCookie;
};

const deleteCookie = (name: string): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const value = getCookieValue(name);

  if (!value) {
    return;
  }

  const updatedCookie: ICookie = {
    expires: COOKIE_EXPIRED_DATE,
    name,
    value,
  };

  setCookie(updatedCookie);
};

const deserializeCookie = (cookie: string): ICookie => {
  const { expires, httpOnly, maxAge, secure } = COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP;

  return cookie.split('; ').reduce((accumulator, keyValuePair, index) => {
    const [key, value] = keyValuePair.split('=');

    const cookieKey = camelCase(key);
    const isFirstIndex = index === 0;

    let cookieValue;

    switch (cookieKey) {
      case expires.interfaceKey: {
        cookieValue = new Date(value).toISOString();
        break;
      }
      case httpOnly.interfaceKey: {
        cookieValue = true;
        break;
      }
      case maxAge.interfaceKey: {
        cookieValue = Number(value);
        break;
      }
      case secure.interfaceKey: {
        cookieValue = true;
        break;
      }
      default: {
        cookieValue = value;
      }
    }

    const partialCookie: Partial<ICookie> = isFirstIndex
      ? {
          name: key,
          value,
        }
      : {
          [cookieKey]: cookieValue,
        };

    return {
      ...accumulator,
      ...partialCookie,
    };
  }, {}) as ICookie;
};

const createCustomBuildProjectGuidCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('customBuildProjectGuid', value, optionalCookieAttributesInput));
};

const createAccessTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('accessToken', value, optionalCookieAttributesInput));
};

const createLastProjectVisitedCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('lastProjectVisited', value, optionalCookieAttributesInput));
};

const createLastUrlBeforeAuthenticationExpiredCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('lastUrlBeforeAuthenticationExpired', value, optionalCookieAttributesInput));
};

const createOrganisationIdCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('organisationId', value, optionalCookieAttributesInput));
};

const createProjectGuidCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('projectGuid', value, optionalCookieAttributesInput));
};

const createProjectLogoUrlCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('projectLogoUrl', encodeURIComponent(value), optionalCookieAttributesInput));
};

const createProjectNameCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('projectName', value, optionalCookieAttributesInput));
};

const createRedirectAfterLoginUrlCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('redirectAfterLoginUrl', value, optionalCookieAttributesInput));
};

const createRefreshTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('refreshToken', value, optionalCookieAttributesInput));
};

const createTemporaryTokenCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('temporaryToken', value, optionalCookieAttributesInput));
};

const createUserDataCookie = ({ optionalCookieAttributesInput, value }: ICreateSpecificCookieParams): void => {
  setCookie(createCookie('userData', value, optionalCookieAttributesInput));
};

const deleteAccessTokenCookie = (): void => {
  deleteCookie('accessToken');
};

const deleteCustomBuildProjectGuidCookie = (): void => {
  deleteCookie('customBuildProjectGuid');
};

const deleteLastProjectVisitedCookie = (): void => {
  deleteCookie('lastProjectVisited');
};

const deleteLastUrlBeforeAuthenticationExpiredCookie = (): void => {
  deleteCookie('lastUrlBeforeAuthenticationExpired');
};

const deleteOrganisationIdCookie = (): void => {
  deleteCookie('organisationId');
};

const deleteProjectGuidCookie = (): void => {
  deleteCookie('projectGuid');
};

const deleteProjectLogoUrlCookie = (): void => {
  deleteCookie('projectLogoUrl');
};

const deleteProjectNameCookie = (): void => {
  deleteCookie('projectName');
};

const deleteRedirectAfterLoginUrlCookie = (): void => {
  deleteCookie('redirectAfterLoginUrl');
};

const deleteRefreshTokenCookie = (): void => {
  deleteCookie('refreshToken');
};

const deleteTemporaryTokenCookie = (): void => {
  deleteCookie('temporaryToken');
};

const deleteUserDataCookie = (): void => {
  deleteCookie('userData');
};

const getAccessTokenCookie = (): string | undefined => {
  return getCookieValue('accessToken');
};

const getCustomBuildProjectGuidCookie = (): string | undefined => {
  return getCookieValue('customBuildProjectGuid');
};

const getLastProjectVisitedCookie = (): string | undefined => {
  const value = getCookieValue('lastProjectVisited');
  return value ? decodeURIComponent(value) : undefined;
};

const getLastUrlBeforeAuthenticationExpiredCookie = (): string | undefined => {
  const value = getCookieValue('lastUrlBeforeAuthenticationExpired');
  return value ? decodeURIComponent(value) : undefined;
};

const getOrganisationIdCookie = (): string | undefined => {
  return getCookieValue('organisationId');
};

const getProjectGuidCookie = (): string | undefined => {
  return getCookieValue('projectGuid');
};

const getProjectLogoUrlCookie = (): string | undefined => {
  const projectLogoUrlCookie = getCookieValue('projectLogoUrl');
  return projectLogoUrlCookie ? decodeURIComponent(projectLogoUrlCookie) : undefined;
};

const getProjectNameCookie = (): string | undefined => {
  return getCookieValue('projectName');
};

const getRedirectAfterLoginUrlCookie = (): string | undefined => {
  const redirectAfterLoginUrlCookie = getCookieValue('redirectAfterLoginUrl');
  return redirectAfterLoginUrlCookie ? decodeURIComponent(redirectAfterLoginUrlCookie) : undefined;
};

const getRefreshTokenCookie = (): string | undefined => {
  return getCookieValue('refreshToken');
};

const getTemporaryTokenCookie = (): string | undefined => {
  return getCookieValue('temporaryToken');
};

const getUserDataCookie = (): string | undefined => {
  return getCookieValue('userData');
};

export {
  createAccessTokenCookie,
  createCookie,
  createCustomBuildProjectGuidCookie,
  createLastProjectVisitedCookie,
  createLastUrlBeforeAuthenticationExpiredCookie,
  createOrganisationIdCookie,
  createProjectGuidCookie,
  createProjectLogoUrlCookie,
  createProjectNameCookie,
  createRedirectAfterLoginUrlCookie,
  createRefreshTokenCookie,
  createTemporaryTokenCookie,
  createUserDataCookie,
  deleteAccessTokenCookie,
  deleteCookie,
  deleteCustomBuildProjectGuidCookie,
  deleteLastProjectVisitedCookie,
  deleteLastUrlBeforeAuthenticationExpiredCookie,
  deleteOrganisationIdCookie,
  deleteProjectGuidCookie,
  deleteProjectLogoUrlCookie,
  deleteProjectNameCookie,
  deleteRedirectAfterLoginUrlCookie,
  deleteRefreshTokenCookie,
  deleteTemporaryTokenCookie,
  deleteUserDataCookie,
  deserializeCookie,
  getAccessTokenCookie,
  getAllCookies,
  getCookieValue,
  getCustomBuildProjectGuidCookie,
  getLastProjectVisitedCookie,
  getLastUrlBeforeAuthenticationExpiredCookie,
  getOrganisationIdCookie,
  getProjectGuidCookie,
  getProjectLogoUrlCookie,
  getProjectNameCookie,
  getRedirectAfterLoginUrlCookie,
  getRefreshTokenCookie,
  getTemporaryTokenCookie,
  getUserDataCookie,
  serializeCookie,
  setCookie,
};
