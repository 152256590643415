import gql from 'graphql-tag';

export const GET_COMMENTS = gql`
  query getComments($postId: Int) {
    comment {
      getComments(postId: $postId) {
        edges {
          cursor
          node {
            author {
              displayedName
            }
            id
            authorId
            author {
              firstName
              lastName
            }
            message
            createdDate
            createdDateTime
          }
        }
      }
    }
  }
`;
