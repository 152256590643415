import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { Container, DropDownContainer, InputContainer } from './styled';

const DropDownSelect = props => {
  const { children, name, value, onClose } = props;

  const [isOpen, setIsopen] = useState(false);
  const input = useRef(null);

  const handleTarget = () => {
    input.current.focus();
    if (onClose) onClose();
    setIsopen(!isOpen);
  };

  document.addEventListener('click', ({ target }) => {
    if (target.attributes.additionalattribute && target.attributes.additionalattribute.value === 'disable-click') return;
    if (input.current !== null) {
      setIsopen(input.current.contains(target));
    }
  });

  return (
    <Container>
      <InputContainer isOpen={isOpen} onClick={handleTarget} ref={input} role="button">
        <input aria-haspopup="true" autoComplete="off" id={name} name={name} type="button" value={value} />
        <svg focusable="false" height="24" role="presentation" viewBox="0 0 24 24" width="24">
          <path
            d="M6.744 8.744a1.053 1.053 0 0 0 0 1.49l4.547 4.557a1 1 0 0 0 1.416 0l4.55-4.558a1.051 1.051 0 1 0-1.488-1.488l-3.77 3.776-3.768-3.776a1.051 1.051 0 0 0-1.487 0z"
            fill="currentColor"
          />
        </svg>
      </InputContainer>

      {isOpen && <DropDownContainer aria-hidden={!isOpen}>{children}</DropDownContainer>}
    </Container>
  );
};

DropDownSelect.propTypes = {
  children: PropTypes.oneOfType([
    // PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  value: PropTypes.string.isRequired,
};

export default DropDownSelect;
