import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_CONTENT_SECTION_LAYOUTS = gql`
  query getLayout {
    contentSection {
      searchContentSectionLayouts {
        id
        name
        containerNumber
      }
    }
  }
`;

const getContentSectionLayouts = ({ onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ALL_CONTENT_SECTION_LAYOUTS}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.contentSection.searchContentSectionLayouts
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentSectionLayouts.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getContentSectionLayouts;
