import React, { useState } from 'react';

import { formatISO, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { DEFAULT_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_API_ERROR_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerCalendarSnippetProps } from './QuestionAnswerCalendarSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerCalendarMutationVariables,
  ISetUserAnswerMutationGraphQLResponse,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerCalendar, IQuestionConfigurationCalendar } from '../../../../interfaces';
import { CalendarSnippet } from '../../CalendarSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerCalendarSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  userFlowStepTrackId,
}: QuestionAnswerCalendarSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration } = contentSnippet ?? {};
  const { dateAnswered } = answer ?? ({} as IQuestionAnswerCalendar);
  const { defaultDate, maxDate, minDate } = configuration ?? ({} as IQuestionConfigurationCalendar);

  const parsedDateAnswered = dateAnswered ? parseISO(dateAnswered) : undefined;
  const parsedDefaultDate = defaultDate ? parseISO(defaultDate) : undefined;

  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);
  const [savedDateAnswered, setSavedDateAnswered] = useState<Date | undefined>(parsedDateAnswered ?? parsedDefaultDate);

  const [executeSetUserAnswerCalendar] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerCalendarMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerCalendar,
    options: {
      onCompleted: () => {
        if (!savedDateAnswered) {
          return;
        }

        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            calendarDateAnswered: formatISO(savedDateAnswered),
          },
        });

        setMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);
        setMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const handleDateChanged = (inputDate: Date) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));
    const dateAnsweredUtc = zonedTimeToUtc(inputDate, 'UTC');

    setSavedDateAnswered(inputDate);

    executeSetUserAnswerCalendar({
      variables: {
        ...baseVariables,
        dateAnswered: dateAnsweredUtc,
      },
    });
  };

  return (
    <CalendarSnippet
      id={`calendar-${contentSnippetId}`}
      maxDate={maxDate}
      message={message}
      minDate={minDate}
      selectedDate={savedDateAnswered}
      type="SINGLE_DATE"
      onSingleDateChange={handleDateChanged}
    />
  );
};

export { QuestionAnswerCalendarSnippet };
