import React, { useEffect, useState } from 'react';

import { useCookie } from '@netfront/common-library';
import {
  useCreateGeladaOrganisation,
  useGetGeladaOrganisationsForConnectedUser,
  useUpdateGeladaOrganisation,
} from '@netfront/gelada-identity-library';
import styled from 'styled-components';

import Backdrop from '../../components/UI/Backdrop/Backdrop';
import AddNewCard from '../../components/UI/Card/AddNewCard';
import { LoadingMenuCard } from '../../components/UI/Card/LoadingMenuCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import { getAssetsUrl } from '../../config';
import Organisation from '../../containers/Organisation/Organisation';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { useToast } from '../../hooks';

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
`;

// TODO:
//    - Need to fix deleting an organisation since it currently does not work

function OrganisationsPage() {
  const { handleToastError } = useToast();
  const { getAccessTokenCookie } = useCookie();

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(undefined);

  const token = getAccessTokenCookie();

  const { handleCreateGeladaOrganisation, isLoading: isCreateGeladaOrganisationLoading = false } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation }) => {
      const updatedGeladaOrganisations = [...organisations, organisation];

      setIsPanelOpen(false);
      setOrganisations(updatedGeladaOrganisations);
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const { handleGetGeladaOrganisationsForConnectedUser, isLoading: isGetGeladaOrganisationsForConnectedUserLoading = false } =
    useGetGeladaOrganisationsForConnectedUser({
      onCompleted: ({ geladaOrganisations }) => {
        setOrganisations(geladaOrganisations);
      },
      onError(error) {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
      token,
    });

  const { handleUpdateGeladaOrganisation, isLoading: isUpdateGeladaOrganisationLoading = false } = useUpdateGeladaOrganisation({
    onCompleted: ({ organisation: returnedOrganisation }) => {
      const updatedGeladaOrganisations = organisations.map((organisation) => {
        if (organisation.id === returnedOrganisation.id) {
          return returnedOrganisation;
        }

        return organisation;
      });

      setIsPanelOpen(false);
      setOrganisations(updatedGeladaOrganisations);
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    token,
  });

  const handleClosePanel = () => {
    setIsPanelOpen(false);
  };

  const handleEditOrganisation = (event, organisation) => {
    event.preventDefault();

    setIsPanelOpen(true);
    setSelectedOrganisation(organisation);
  };

  const handleOpenPanel = () => {
    setIsPanelOpen(true);
  };

  useEffect(() => {
    handleGetGeladaOrganisationsForConnectedUser({
      shouldIncludeOrganisationLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    isCreateGeladaOrganisationLoading || isGetGeladaOrganisationsForConnectedUserLoading || isUpdateGeladaOrganisationLoading;

  return (
    <PageWrapper info="Manage organisations" pageDescription="Manage all organisations in Ekardo" pageTitle="Manage organisations">
      <CardsBox>
        {isLoading && <LoadingMenuCard count={3} />}

        {!isLoading &&
          organisations.map((organisation) => {
            const { name, key } = organisation;

            return (
              <MenuCard
                key={`organisation-${key}`}
                edit={(event) => handleEditOrganisation(event, organisation)}
                href={`/dashboard/${key}`}
                name={name}
                src={`${getAssetsUrl()}/logo/logo-dark.svg`}
              />
            );
          })}
        <AddNewCard onClick={() => handleOpenPanel()} />
      </CardsBox>

      {isPanelOpen && (
        <>
          <Organisation
            description={selectedOrganisation?.description}
            handleClosePanel={() => handleClosePanel()}
            handleCreate={(description, name, tag, url) => {
              handleCreateGeladaOrganisation({
                description,
                name,
                tag,
                url,
              });
            }}
            handleDelete={() => {}}
            handleUpdate={(description, id, name, tag, url) => {
              handleUpdateGeladaOrganisation({
                description,
                id,
                name,
                tag,
                url,
              });
            }}
            handleUpdateStatus={() => {}}
            id={selectedOrganisation?.id}
            isPanelOpen={isPanelOpen}
            name={selectedOrganisation?.name}
            status={selectedOrganisation?.status}
            tag={selectedOrganisation?.tag}
            url={selectedOrganisation?.url}
          />
          <Backdrop isClicked={() => handleClosePanel()} />
        </>
      )}
    </PageWrapper>
  );
}

export default OrganisationsPage;
