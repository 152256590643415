import styled from 'styled-components';

export const Container = styled.div``;

export const AudioContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AudioTextContainer = styled.div`
  display: flex;
`;

export const AudioItemContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  overflow: hidden;
`;

export const SingleButtonContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Item = styled.div`
  margin: 1em auto;
  width: 100%;
`;

export const ColorItem = styled(Item)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.label.attrs(({ htmlFor }) => ({
  htmlFor,
}))`
  display: flex;
  font-weight: bold;
  color: ${({ color }) => color};
  justify-content: space-between;
  line-height: ${({ lineHeight }) => lineHeight};
  margin: ${({ margin }) => margin};
  width: 100%;
`;

export const ColorTitle = styled(Title)`
  margin-left: 1em;
`;

export const EditBtn = styled.button`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const Preview = styled.div`
  position: relative;

  &:hover {
    ${EditBtn} {
      display: block;
    }
  }
`;

export const ChooseFromLibraryButton = styled.button.attrs(({ className }) => ({
  className,
}))`
  margin-top: 1rem;
  width: 100%;
`;

export const DeleteButton = styled.button`
  align-items: center;
  background-color: #adb4bd;
  border: none;
  border-radius: 100%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  margin-bottom: 0.3rem;
  width: 1.5rem;
`;

export const EditButton = styled.button`
  align-items: center;
  background-color: #adb4bd;
  border: none;
  border-radius: 100%;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
`;

export const SaveButton = styled.button`
  background: #4bc2ca;
  border: none;
  border-radius: 5px;
  color: black;
  height: 38px;
  padding: 0 1rem;
`;

export const InputTitleBlock = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const TranscriptList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const TranscriptListItem = styled.li`
  align-items: center;
  border-radius: 0.3rem;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  padding: 0 0.9rem;

  &:hover {
    border: ${({ border }) => border};
  }
`;

export const TextItem = styled.div`
  font-size: inherit;
  line-height: ${({ lineHeight }) => lineHeight};
  margin-left: ${({ marginLeft }) => marginLeft};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PlusIcon = styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Icon = styled.i.attrs((props) => ({
  className: props.className,
}))`
  height: ${({ height }) => height};
  transform: ${({ transform }) => transform};
  width: ${({ width }) => width};
`;

export const SquareButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: hidden;
  border-radius: 25%;
  color: #6d7a8a;
  display: flex;
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  min-width: 1.9rem;
  justify-content: center;
  padding: 0;
  width: ${({ width }) => width};
`;

export const ButtonContainer = styled.div`
  margin-left: ${({ margin }) => margin || '1rem'};
`;

export const CircleButton = styled.button`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: hidden;
  border-radius: 50%;
  color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: ${({ height }) => height};
  justify-content: center;
  padding: 0;
  transform: ${({ transform }) => transform};
  width: ${({ width }) => width};
`;

export const Logo = styled.img`
  width: 100%;
  border-radius: 0.4rem;
  max-height: 14rem;
  object-fit: cover;
`;

export const TickBtn = styled.button`
  background-color: #4bc2ca;
  border: hidden;
  border-radius: 50%;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  height: 1.5em;
  margin: 6px 0 0.5em;
  padding: 0;
  width: 1.7em;
`;

export const AddNewTag = styled.div`
  align-items: center;
  background: #e4e4e3;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  display: flex;
  height: 2.8em;
  justify-content: flex-start;
  width: 100%;
`;

export const SearchSuggetionsBox = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow-y: auto;
`;

export const SearchSuggetionsRow = styled.button`
  background: none;
  border: 1px solid #e1e1e1;
  padding: 10px;
  text-align: left;
  &:hover {
    background-color: #e8e8e8;
  }
`;

export const Search = styled.input`
  background-color: unset;
  border: hidden;
  height: 3em;
  width: 100%;
`;

export const TagInput = styled.div`
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  margin: 0.2em 0;
  position: relative;
`;

export const InfoIcon = styled.span`
  background-color: #4bc1c9;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 1em;
  line-height: 13px;
  margin: 0 1em;
  text-align: center;
  width: 1em;
`;

export const PasswordField = styled.div`
  border: #e1e1e1 solid 1px;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 0.8rem;
`;

export const PasswordFieldTitle = styled.label`
  font-weight: 300;
  margin-top: 0.2rem;
`;

export const PasswordFieldButton = styled.button`
  border: #000 solid 1px;
  border-radius: 5px;
  margin-top: 0.5rem;
`;

export const AdvanceItem = styled.div`
  margin: 1em auto;
  width: 100%;
`;

export const AdvanceTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${({ padding }) => padding};
`;

export const NavigationOptionGroupContainer = styled.div`
  border: 1px dashed #a3a3a3;
  border-radius: 6px;

  &:last-child {
    margin-top: 1rem;
  }
`;

export const NavigationOptionHeaderRow = styled.div`
  background-color: #f1f1f1;
  color: #000;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.75rem;
`;

export const NavigationOptionBodyRow = styled.div`
  padding: 0.75rem;
  padding-bottom: ${(props) => (props.showToolTip ? '0' : '0.75rem')};
`;

export const SwitchButtonContainer = styled.div`
  display: flex;
`;

export const SwitchButtonLabelContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const SwitchButtonWithLabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  font-weight: bold;
`;

export const TextFieldLabel = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 6px -1em 1em 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.4em;
`;

export const ListRow = styled.button`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0.5em;
  width: 100%;
  margin: 0.3em;
  outline: none;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #e4e4e3;
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    border: 1px solid #e4e4e3;
    border-radius: 0.2em;
  }
  &:focus {
    outline: 0;
  }
`;

export const TitleText = styled.strong``;

export const Wrapper = styled.div``;
