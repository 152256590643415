import gql from 'graphql-tag';

export const EXPORT_MODULE_PAGE_COMPLETIONS = gql`
  query ExportModulePageCompletions($ianaTimeZone: String!, $stepId: Int!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportModulePageCompletions(ianaTimeZone: $ianaTimeZone, stepId: $stepId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
      }
    }
  }
`;
