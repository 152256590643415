import isPlainObject from 'lodash.isplainobject';
import { parse, ParsedQuery } from 'query-string';

const getObjectFromSerializedString = (serializedString: string) => {
  let json;

  try {
    json = JSON.parse(serializedString);
  } catch {
    return undefined;
  }

  if (!isPlainObject(json)) {
    return undefined;
  }

  return json;
};

const getParsedQuery = (path: string): ParsedQuery<string> => {
  const querystring = path.substring(path.indexOf('?') + 1);
  return parse(querystring);
};

export { getObjectFromSerializedString, getParsedQuery };
