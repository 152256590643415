import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  CREATE_CONDITION_ON_ACTION_CHECKBOX,
  CREATE_CONDITION_ON_ACTION_RADIO,
  CREATE_CONDITION_ON_ACTION_SLIDER,
  CREATE_CONDITION_ON_ACTION_TEXT,
} from './UseConditionCreate.graphql';

const useConditionAdd = (options) => {
  const { mutation, onCompleted, onError, token, conditionType, variables } = options ?? {};

  const addConditionMutationQueryMap = {
    checkbox: CREATE_CONDITION_ON_ACTION_CHECKBOX,
    radio: CREATE_CONDITION_ON_ACTION_RADIO,
    slider: CREATE_CONDITION_ON_ACTION_SLIDER,
    text: CREATE_CONDITION_ON_ACTION_TEXT,
  };

  const [executeAddCondition, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? addConditionMutationQueryMap[conditionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { addConditionOnAction: condition },
        } = data;

        onCompleted({
          condition,
        });
      },
      onError,
    },
    token,
    conditionType,
    variables,
  });

  const handleAddCondition = () => {
    executeAddCondition({
      variables,
    });
  };

  return {
    handleAddCondition,
    isLoading,
  };
};

export { useConditionAdd };
