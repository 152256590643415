import styled from 'styled-components';

export const AppImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 115px;
  margin-right: 1em;
  width: 115px;
`;
export const OptionsBanner = styled.div`
  background-color: #f7f7f7;
  padding: 2em 0 0 0;
  margin: -1em 0 3em 0;
  width: 100%;
`;
export const OutlineContainer = styled.div`
  border: 1px solid #dfe1e6;
  border-radius: 6px;
  margin: 0 0 2em 0;
  padding: 1em;
`;
export const Heading1 = styled.h1`
  font-size: 1.5em;
  font-weight: 600;
  margin: 0 0 0.2em 0;

  @media (min-width: 768px) {
    font-size: 1.7em;
  }
`;
export const Heading3 = styled.h3`
  margin: 0 0 0.8em 0;
  font-size: 1.4em;
  font-weight: 600;
`;
export const Heading4 = styled.h4`
  margin: 0 0 0.6em 0;
  font-size: 1.3em;
  font-weight: 600;
`;
export const SubTitle = styled.p`
  color: rgb(107, 119, 140);
  font-size: 0.9em;
`;

export const EditButton = styled.div`
  display: none;
  position: absolute;
  right: 0.6em;
  top: 0.6em;
`;

export const RatingContainer = styled(OutlineContainer)`
  display: flex;
  justify-content: space-between;
  position: relative;
  &:hover {
    ${EditButton} {
      display: block;
    }
  }
`;

export const OverallRating = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-right: 0.2em;
  }
`;
export const OptionsBannerInner = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: space-between;
`;
export const RatingKey = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  margin-right: 0;

  @media (min-width: 768px) {
    margin-right: 2em !important;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
`;

export const CenterContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Container50 = styled.div`
  flex-basis: 50%;
  max-width: 50%;
`;
export const ContainerGrow = styled.div`
  flex-grow: 1;
`;
export const PushRight = styled.div`
  margin-left: auto;
`;

export const PushRight1 = styled.div`
  position: relative;
  top: -9.5em;
  margin-bottom: -3em;
  margin-left: auto;
`;

export const ReturnButton = styled.a`
  align-items: center;
  background-color: #4bc1c9;
  border: none;
  border-radius: 6px;
  color: #000;
  display: flex;
  font-size: 1em;
  height: 43px;
  margin: 1em 0;
  padding: 0 1rem;
`;

export const Button = styled.button`
  background-color: #00c4cc;
  border: none;
  border-radius: 6px;
  color: #000;
  display: inline-block;
  font-size: 1em;
  font-weight: 500;
  margin: 0 0.4em 0 0;
  padding: 0.2em 0.8em;
`;

export const LinkButton = styled.a`
  background-color: #fff;
  border: 1px solid ${({ color }) => color};
  border-radius: 6px;
  color: ${({ color }) => color} !important;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 500;
  padding: 0.2em 0.8em;

  & + & {
    margin-left: 0.6em;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ProjectItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #e4e4e3;
  border-top: unset;
  border-radius: 5px;
  padding: 0.5em;
  & :nth-child(1) {
    border-top: 1px solid #e4e4e3;
  }
  label {
    margin-bottom: 0;
  }
`;

export const BannerBG = styled.div`
  width: 100%;
  height: 10.5em;
  position: absolute;
  background-color: #f7f7f7;
  left: 0;
  margin-top: -2em;
`;

export const OrgDropBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em 0 1em 0;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 1em;
`;
