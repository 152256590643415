import gql from 'graphql-tag';

export const GET_SNIPPET_ANSWERS = gql`
  query getSnippetAnswers($contentSnippetQuestionId: Int!) {
    contentSnippet {
      question(id: $contentSnippetQuestionId) {
        question
        configuration {
          ... on Radio {
            responseSet {
              availableResponses {
                label
                value
                id
              }
            }
          }
          ... on Slider {
            minValue
            maxValue
          }
          ... on SingleText {
            description
          }
          ... on Checkbox {
            responseSet {
              availableResponses {
                id
                label
                value
              }
            }
            id
            description
            __typename
          }
        }
        contentPage {
          contentGroupId
        }
      }
    }
  }
`;
