import styled from 'styled-components';

export const AdvanceTitle = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

export const AdvanceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #e4e4e3;
  padding: 1em;
  border-radius: 5px;
`;

export const AdvanceOpt = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  align-items: center;
  &:hover {
    background-color: #fff;
  }
`;

export const AdvanceOptTitle = styled.p`
  font-weight: 600;
`;

export const AdvanceOptTitleBox = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptySnippet = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2em auto;
`;
