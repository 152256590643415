import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


const BaseTabItem = styled.li`
  color: ${({ isSelectd }) => (isSelectd ? '#000' : 'grey')};
  cursor: pointer;
  font-weight: ${({ isSelectd }) => (isSelectd ? '700' : 'inherit')};
  padding: 8px;
  white-space: nowrap;

  &:focus {
    outline: none;
  }
`;

export const TabItem = props => {
  const { children, id, isSelectd, onClick } = props;

  return (
    <BaseTabItem
      aria-posinset={id}
      aria-selected={isSelectd}
      isSelectd={isSelectd}
      onClick={onClick}
      role="tab"
      tabIndex={isSelectd ? 0 : -1}
    >
      {children}
    </BaseTabItem>
  );
};

TabItem.defaultProps = {
  isSelectd: false,
  onClick: () => undefined,
};

TabItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.number.isRequired,
  isSelectd: PropTypes.bool,
  onClick: PropTypes.func,
};
