import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import ConsoleMaster from '../../components/ConsoleMaster/ConsoleMaster';
import InfoBanner from '../../components/InfoBanner/InfoBanner';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MenuCard from '../../components/UI/Card/MenuCard';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';
import GetProject from '../../middleware/Project/getProject';
import GetReportsForProject from '../../middleware/Report/getReportsForProject';

const CardAvatar = styled.div`
  align-items: center;
  background: transparent linear-gradient(65deg, #4bc1c9 0%, #4bc1d9 68%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6em;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: 0 auto 0.6em auto;
  max-height: 50px;
  max-width: 50px;
  width: 100%;
`;

const CardInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

const ReportRow = styled.div`
  display: flex;
`;

const ReportPage = (props) => {
  const { match } = props;
  const { params } = match;
  const { orgId, projectId } = params;

  const [organisationName, setOrganisationName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [reports, setReports] = useState([]);

  const [createReport] = useState(false);
  const [isOrganisationLoaded, setIsOrganisationLoaded] = useState(!!organisationName.length);
  const [isProjectLoaded, setIsProjectLoaded] = useState(!!projectName.length);
  const [isReportLoaded, setIsReportLoaded] = useState(false);

  const handleGetOrganisation = ({ name }) => {
    setOrganisationName(name);
    setIsOrganisationLoaded(true);
  };

  const handleGetProject = ({ name }) => {
    setProjectName(name);
    setIsProjectLoaded(true);
  };

  const handleGetReports = (data) => {
    setIsReportLoaded(true);
    setReports(data);
  };

  const handleGroupAvatar = (title) =>
    title
      .split(' ')
      .map((item) => item.substring(0, 1).toUpperCase())
      .join('');

  const handleOrganisationError = () => {
    setIsOrganisationLoaded(true);
  };

  const handleProjectError = () => {
    setIsProjectLoaded(true);
  };

  const handleReportError = () => {
    setIsReportLoaded(true);
  };

  const reportingMenuCardName = 'Reporting';

  useEffect(() => {
    setOrganisationName(organisationName);
    setProjectName(projectName);
  }, [organisationName, projectName]);

  return (
    <ConsoleMaster path={props}>
      <>
        <ProjectNavBar title="Reports" />
        <ConsoleContainer styles={{ marginTop: '2em' }}>
          {isProjectLoaded && (
            <Breadcrumb
              avatar={projectName}
              breads={[
                {
                  id: 1,
                  path: `/dashboard/${orgId}`,
                  title: 'Dashboard',
                },
                {
                  id: 2,
                  path: `/dashboard/${orgId}/project/${projectId}`,
                  title: projectName,
                },
                {
                  id: 3,
                  path: `/dashboard/${orgId}/project/${projectId}/reporting`,
                  title: 'Reporting',
                },
              ]}
              title={projectName}
            />
          )}

          <InfoBanner
            text={
              <p>
                <strong>Manage</strong> your reports
              </p>
            }
          />
        </ConsoleContainer>

        <div className="container-console">
          <ReportRow>
            {reports.map((item) => (
              <MenuCard key={item.id} href={`/dashboard/${orgId}/project/${projectId}/report-viewer/${item.id}`} name={item.title}>
                <CardInner>
                  <CardAvatar>{handleGroupAvatar(item.title)}</CardAvatar>
                </CardInner>
              </MenuCard>
            ))}
          </ReportRow>
          <ReportRow>
            <MenuCard
              key={reportingMenuCardName}
              href={`/dashboard/${orgId}/project/${projectId}/reporting/export-users`}
              name={reportingMenuCardName}
            >
              <CardInner>
                <CardAvatar>{handleGroupAvatar(reportingMenuCardName)}</CardAvatar>
              </CardInner>
            </MenuCard>
          </ReportRow>
        </div>
      </>
      {createReport && <Redirect to={`/dashboard/${orgId}/project/${projectId}/report-builder/`} />}
      {!isReportLoaded && <GetReportsForProject projectId={projectId} onError={handleReportError} onSuccessResult={handleGetReports} />}
      {!isOrganisationLoaded && (
        <GetOrganisation id={Number(orgId)} onError={handleOrganisationError} onSuccessResult={handleGetOrganisation} />
      )}
      {!isProjectLoaded && <GetProject id={projectId} onError={handleProjectError} onSuccessResult={handleGetProject} />}
    </ConsoleMaster>
  );
};

ReportPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string,
      projectId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default ReportPage;
