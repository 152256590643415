import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_SECTION_STYLE = gql`
  mutation UpdateContentSection(
    $contentLayoutId: Int!
    $sectionId: Int!
    $borderColor: String
    $backgroundColor: String
    $style: ESectionStyle
    $backgroundImage: String
    $visible: Boolean
  ) {
    contentSection {
      updateSection(
        contentSection: {
          contentLayoutId: $contentLayoutId
          sectionId: $sectionId
          borderColor: $borderColor
          backgroundColor: $backgroundColor
          backgroundImage: $backgroundImage
          style: $style
          visible: $visible
        }
      ) {
        id
        sort
        borderColor
        backgroundColor
        visible
      }
    }
  }
`;

const updateSectionStyle = ({
  sectionId,
  contentLayoutId,
  borderColor,
  backgroundColor,
  backgroundImage,
  style,
  visible,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_CONTENT_SECTION_STYLE}>
    {(updateSection, { loading, error, data }) => {
      const variables = {
        sectionId,
        contentLayoutId,
        borderColor,
        backgroundColor,
        backgroundImage,
        style,
        visible,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSection.updateSection);
      }

      return <MutationRequest isLoading={loading} mutation={updateSection} variables={variables} />;
    }}
  </Mutation>
);

updateSectionStyle.propTypes = {
  contentLayoutId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  borderColor: PropTypes.string,
  style: PropTypes.string,
  visible: PropTypes.bool,
};

updateSectionStyle.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  borderColor: null,
  style: 'NORMAL',
};

export default updateSectionStyle;
