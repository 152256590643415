import { IStorageExpiryOptions } from '@netfront/common-library';

const TOOLBOX_USER_FLOW_IDS_STORAGE_EXPIRY_OPTIONS: IStorageExpiryOptions = {
  currentTimeValueInMilliseconds: new Date().getTime(),
  expiryTime: {
    unit: 'days',
    value: 1,
  },
};

export { TOOLBOX_USER_FLOW_IDS_STORAGE_EXPIRY_OPTIONS };
