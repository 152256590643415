import React, { useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { addMonths, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';
import CONSTANTS from './Shared/Constants';
import Constants from './Shared/Constants';
import getDropdownOptions from './Shared/getDropdownOptions';

import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import client from '../../../middleware/client';
import { EXPORT_ALL_MODULE_PAGES } from '../../../middleware/Export/exportAllModulePages';
import { GET_USERFLOWS } from '../../../middleware/UserFlow/getUserFlows';
import { downloadFileByUrl } from '../../../utils/utils';

const ExportAllModulePagesPage = () => {
  const { projectId } = useParams();

  const [apiError, setApiError] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [defaultUserFlowId] = useState(CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID);
  const [hasLoadingUserFlowsError, setHasLoadingUserFlowsError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [userFlowId, setUserFlowId] = useState(defaultUserFlowId);
  const [userFlowOptions, setUserFlowOptions] = useState(null);

  const { loading: isLoadingUserFlows } = useQuery(GET_USERFLOWS, {
    client,
    onCompleted: (data) => {
      setIsFormValid(isValid(dateFrom) && isValid(dateTo) && userFlowId > -1);
      setUserFlowOptions(
        getDropdownOptions(
          CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID,
          CONSTANTS.DROPDOWN.DEFAULT_OPTION_LABEL.USER_FLOW,
          data.userFlow.getUserFlows,
          ['title'],
          'id',
        ),
      );
    },
    onError: (error) => {
      setApiError(error);
      setHasLoadingUserFlowsError(true);
    },
    variables: {
      projectId,
    },
  });

  const [sendExportAllModulePagesQuery, { loading: isGeneratingReport }] = useLazyQuery(EXPORT_ALL_MODULE_PAGES, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { contentType, s3Key } = data.report.exportAllModulePages;
      const signedUrl = getSignedUrl(s3Key, contentType);

      downloadFileByUrl(signedUrl);
    },
    onError: (error) => {
      setApiError(error);
    },
  });

  const download = (fileType = 'EXCEL' | 'CSV') => {
    if (userFlowId === CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID) {
      return;
    }

    sendExportAllModulePagesQuery({
      variables: {
        dateFrom,
        dateTo,
        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userFlowId,
        fileType,
      },
    });
  };

  const handleDateFromChange = (value) => {
    setDateFrom(value);
    setIsFormValid(isValid(value) && isValid(dateTo) && userFlowId > -1);
  };

  const handleDateToChange = (value) => {
    setDateTo(value);
    setIsFormValid(isValid(dateFrom) && isValid(value) && userFlowId > -1);
  };

  const handleOptionSelected = (selectedId) => {
    setUserFlowId(selectedId);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo) && selectedId > -1);
  };

  const options = {
    apiError,
    downloadCSVCallback: () => download('CSV'),
    downloadExcelCallback: () => download('EXCEL'),
    filtering: {
      date: {
        dateFrom,
        dateTo,
        handleDateFromChangeCallback: handleDateFromChange,
        handleDateToChangeCallback: handleDateToChange,
      },
      userFlows: {
        handleOptionSelectedCallback: handleOptionSelected,
        hasLoadError: hasLoadingUserFlowsError,
        options: userFlowOptions,
        selectedId: userFlowId,
      },
    },
    flags: {
      isFormValid,
      preloader: {
        isGeneratingReport,
        isLoadingUserFlows,
      },
    },
    infoBannerText: 'This page exports all module progress based on the selected filters',
  };

  return <ExportPage options={options} />;
};

export default ExportAllModulePagesPage;
