import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

export const GET_ALL_PENDING_APP_VERSIONS = gql`
  query GET_ALL_APPS {
    app {
      getPendingAppVersions {
        app {
          id
          description
          identifier
          title
          averageRateValue
        }
        id
        appId
        version
      }
    }
  }
`;

export default function GetAllPendingAppVersions(props) {
  const { onError, onSuccess } = props;

  return (
    <Query query={GET_ALL_PENDING_APP_VERSIONS}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) onError(error);
        if (!data || data.apps) return <span>No apps available</span>;

        onSuccess(data.app.getPendingAppVersions);
        return null;
      }}
    </Query>
  );
}

GetAllPendingAppVersions.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
