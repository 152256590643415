import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

import CONSTANTS from './Shared/Constants';
import FilterOptionsError from './Shared/FilterOptionsError';
import {
  AccordianMenuContainer,
  ButtonContainer,
  ButtonsContainer,
  DatePickerContainer,
  DatePickerGroupContainer,
  DownloadButton,
  DropdownListOptionContainer,
  DropdownListOptionsContainer,
  ErrorContainer,
  FilterContentsContainer,
  PageContentsContainer,
} from './Shared/styled';

import AccordionMenu from '../../../components/AccordionMenu/AccordionMenu';
import InfoBanner from '../../../components/InfoBanner/InfoBanner';
import ProjectNavBar from '../../../components/LeftNavBar/ProjectNavBar';
import Preloader from '../../../components/Preloader/Preloader';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import DatePicker from '../../../components/UI/Input/DatePicker';
import { NetfrontServices } from '../../../constants/NetfrontServices';
import PageWrapper from '../../../containers/PageWrapper/PageWrapper';
import AppContext from '../../../context/AppContext';
import { getApiErrorMessages } from '../../../utils/utils';

const breadCrumbPath = [
  {
    id: 'reporting-1',
    path: '../reporting',
    title: 'Reporting',
  },
];

const menuGroups = {
  content: 'Content',
  feedback: 'Feedback',
  notifications: 'Notifications',
  questionnaires: 'Questionnaires',
  social: 'Social',
  users: 'Users',
  orders: 'Orders',
};

const menuItems = {
  allModulePages: {
    route: './export-all-module-pages',
    text: 'All module progress',
  },
  allModulePageCompletions: {
    route: './export-all-module-page-completions',
    text: 'All module page completions',
  },
  bookmarks: {
    route: './export-bookmarks',
    text: 'Bookmarks',
  },
  comments: {
    route: './export-comments',
    text: 'Comments',
  },
  feedback: {
    route: './export-feedback',
    text: 'Feedback',
  },
  groups: {
    route: './export-groups',
    text: 'Groups',
  },
  groupsContacts: {
    route: './export-groups-contacts',
    text: 'Groups contacts',
  },
  logins: {
    route: './export-logins',
    text: 'Logins',
  },
  sessions: {
    route: './export-sessions',
    text: 'Sessions',
  },
  mobileAppAccess: {
    route: './export-mobile-app-access',
    text: 'Mobile app access',
  },
  modulePages: {
    route: './export-module-pages',
    text: 'Module progress',
  },
  modulePageCompletions: {
    route: './export-module-page-completions',
    text: 'Module page completions',
  },
  notifications: {
    route: './export-notifications',
    text: 'Notifications',
  },
  posts: {
    route: './export-posts',
    text: 'Posts',
  },
  questionnaires: {
    route: './export-questionnaires',
    text: 'User responses',
  },
  users: {
    route: './export-users',
    text: 'Profile information',
  },
  orders: {
    route: './export-orders',
    text: 'Orders',
  },
};

const menuOptions = [
  {
    group: menuGroups.users,
    items: [menuItems.groups, menuItems.groupsContacts, menuItems.logins, menuItems.users, menuItems.sessions],
  },
  {
    group: menuGroups.orders,
    items: [menuItems.orders],
    service: NetfrontServices.VERVET,
  },
  {
    group: menuGroups.content,
    items: [
      menuItems.allModulePages,
      menuItems.allModulePageCompletions,
      menuItems.modulePages,
      menuItems.modulePageCompletions,
      menuItems.bookmarks,
    ],
    service: NetfrontServices.EKARDO,
  },
  {
    group: menuGroups.notifications,
    items: [menuItems.notifications],
    service: NetfrontServices.HOWLER,
  },
  {
    group: menuGroups.questionnaires,
    items: [menuItems.questionnaires],
    service: NetfrontServices.EKARDO,
  },
  {
    group: menuGroups.feedback,
    items: [menuItems.feedback],
    service: NetfrontServices.EKARDO,
  },
  {
    group: menuGroups.social,
    items: [menuItems.comments, menuItems.posts],
    service: NetfrontServices.BONOBO,
  },
];

const ExportPage = (props) => {
  const { options } = props;

  const {
    apiError,
    dataConfiguration,
    downloadCSVCallback,
    downloadExcelCallback,
    downloadSPSSCallback,
    filtering: {
      date: { dateFrom, dateTo, handleDateFromChangeCallback, handleDateToChangeCallback },
      pageSpecificComponents = [],
      userFlowSteps,
      userFlows,
    },
    flags: {
      isFormValid,
      preloader: { isGeneratingReport, isLoadingGroupTypes = false, isLoadingUserFlowSteps = false, isLoadingUserFlows = false },
    },
    infoBannerText,
  } = options;

  const {
    handleOptionSelectedCallback: handleStepOptionSelectedCallback,
    hasLoadError: hasLoadStepsError,
    options: stepOptions,
    selectedId: selectedStepId,
  } = userFlowSteps || {};

  const {
    handleOptionSelectedCallback: handleUserFlowOptionSelectedCallback,
    hasLoadError: hasLoadUserFlowError,
    options: userFlowOptions,
    selectedId: selectedUserFlowId,
  } = userFlows || {};

  const { pathname } = useLocation();
  const params = useParams();

  const { project } = useContext(AppContext);
  const [apiErrorMessages, setApiErrorMessages] = useState([]);

  const { hasMobileApplication, services } = project;

  if (hasMobileApplication) {
    const userMenuGroup = menuOptions.find((menuOption) => menuOption.group === menuGroups.users);
    if (!userMenuGroup.items.find((r) => r.route === './export-mobile-app-access')) {
      userMenuGroup.items.splice(1, 0, menuItems.mobileAppAccess);
    }
  }
  const handleDownloadExcel = () => {
    downloadExcelCallback();
    setApiErrorMessages([]);
  };

  const handleDownloadSpss = () => {
    downloadSPSSCallback();
    setApiErrorMessages([]);
  };

  const handleDownloadCsv = () => {
    downloadCSVCallback();
    setApiErrorMessages([]);
  };

  useEffect(() => {
    if (!apiError) {
      return;
    }

    setApiErrorMessages(getApiErrorMessages(apiError));
  }, [apiError]);

  const filterByOptionsComponent =
    hasLoadStepsError || hasLoadUserFlowError ? (
      <FilterOptionsError />
    ) : (
      <>
        {stepOptions && (
          <DropdownListOptionsContainer>
            <h2>Step</h2>

            <DropdownListOptionContainer>
              <Dropdown
                availableResponses={stepOptions}
                name="steps"
                selectedValue={selectedStepId.toString()}
                onChangeHandler={(id) => handleStepOptionSelectedCallback(Number(id))}
              />
            </DropdownListOptionContainer>
          </DropdownListOptionsContainer>
        )}

        {userFlowOptions && (
          <DropdownListOptionsContainer>
            <h2>User flow</h2>

            <DropdownListOptionContainer>
              <Dropdown
                availableResponses={userFlowOptions}
                name="user-flows"
                selectedValue={selectedUserFlowId.toString()}
                onChangeHandler={(id) => handleUserFlowOptionSelectedCallback(Number(id))}
              />
            </DropdownListOptionContainer>
          </DropdownListOptionsContainer>
        )}
      </>
    );

  const buttonDisabled = !isFormValid;
  const isPreloaderVisible =
    (isGeneratingReport || isLoadingGroupTypes || isLoadingUserFlows || isLoadingUserFlowSteps) && !apiErrorMessages.length;

  return (
    <>
      {isPreloaderVisible && <Preloader />}
      {!isGeneratingReport && (
        <PageWrapper breadPath={breadCrumbPath} info="Reporting" params={params} hasNoTheme>
          <ProjectNavBar title={project.name} />

          <PageContentsContainer>
            <AccordianMenuContainer>
              {services && (
                <AccordionMenu
                  currentRoute={pathname}
                  menuOptions={menuOptions.filter((r) => !r.service || (services && services.includes(r.service)))}
                />
              )}
            </AccordianMenuContainer>

            <FilterContentsContainer>
              <h1>Reporting</h1>

              <InfoBanner text={infoBannerText} />

              {Boolean(apiErrorMessages.length) && <ErrorContainer>{apiErrorMessages}</ErrorContainer>}

              {filterByOptionsComponent}

              {pageSpecificComponents.map((pageSpecificComponent) => pageSpecificComponent)}

              {dataConfiguration && dataConfiguration.component}

              {(dateFrom || dateTo) && (
                <DatePickerContainer>
                  <h2>Date</h2>
                  <DatePickerGroupContainer>
                    {dateFrom && (
                      <DatePicker
                        id="export_page_date_picker_from_date"
                        label={CONSTANTS.DATE_PICKER.LABELS.DATE_FROM}
                        name={CONSTANTS.DATE_PICKER.NAMES.DATE_FROM}
                        value={dateFrom}
                        onChange={(e) => handleDateFromChangeCallback(e.target.value)}
                      />
                    )}
                    {dateTo && (
                      <DatePicker
                        id="export_page_date_picker_to_date"
                        label={CONSTANTS.DATE_PICKER.LABELS.DATE_TO}
                        name={CONSTANTS.DATE_PICKER.NAMES.DATE_TO}
                        value={dateTo}
                        onChange={(e) => handleDateToChangeCallback(e.target.value)}
                      />
                    )}
                  </DatePickerGroupContainer>
                </DatePickerContainer>
              )}

              <ButtonsContainer>
                {Boolean(downloadSPSSCallback) && (
                  <ButtonContainer>
                    <DownloadButton disabled={buttonDisabled} onClick={() => handleDownloadSpss()}>
                      {CONSTANTS.BUTTONS.TEXT.DOWNLAD_SPSS}
                    </DownloadButton>
                  </ButtonContainer>
                )}
                <ButtonContainer>
                  <DownloadButton disabled={buttonDisabled} onClick={() => handleDownloadCsv()}>
                    {CONSTANTS.BUTTONS.TEXT.DOWNLOAD_CSV}
                  </DownloadButton>
                </ButtonContainer>
                <ButtonContainer>
                  <DownloadButton disabled={buttonDisabled} onClick={() => handleDownloadExcel()}>
                    {CONSTANTS.BUTTONS.TEXT.DOWNLOAD_EXCEL}
                  </DownloadButton>
                </ButtonContainer>
              </ButtonsContainer>
            </FilterContentsContainer>
          </PageContentsContainer>
        </PageWrapper>
      )}
    </>
  );
};

ExportPage.propTypes = {
  options: PropTypes.shape({
    apiError: PropTypes.instanceOf(Error),
    dataConfiguration: PropTypes.shape({
      component: PropTypes.node.isRequired,
    }),
    downloadCSVCallback: PropTypes.func.isRequired,
    downloadSPSSCallback: PropTypes.func,
    downloadExcelCallback: PropTypes.func.isRequired,
    filtering: PropTypes.shape({
      date: PropTypes.shape({
        handleDateFromChangeCallback: PropTypes.func.isRequired,
        handleDateToChangeCallback: PropTypes.func.isRequired,
        dateFrom: PropTypes.instanceOf(Date),
        dateTo: PropTypes.instanceOf(Date),
        hasDateFiltering: PropTypes.bool,
      }).isRequired,
      pageSpecificComponents: PropTypes.arrayOf(PropTypes.node),
      userFlowSteps: PropTypes.shape({
        handleOptionSelectedCallback: PropTypes.func.isRequired,
        hasLoadError: PropTypes.bool.isRequired,
        selectedId: PropTypes.number.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ),
      }),
      userFlows: PropTypes.shape({
        handleOptionSelectedCallback: PropTypes.func.isRequired,
        hasLoadError: PropTypes.bool.isRequired,
        selectedId: PropTypes.number.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ),
      }),
    }).isRequired,
    flags: PropTypes.shape({
      isFormValid: PropTypes.bool.isRequired,
      preloader: PropTypes.shape({
        isGeneratingReport: PropTypes.bool.isRequired,
        isLoadingGroupTypes: PropTypes.bool,
        isLoadingUserFlowSteps: PropTypes.bool,
        isLoadingUserFlows: PropTypes.bool,
      }),
    }).isRequired,
    infoBannerText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ExportPage;
