import React from 'react';

import PropTypes from 'prop-types';

import {
  QUESTION_ACTION_PROP_TYPE,
  QUESTION_CONDITION_CHECKBOX_PROP_TYPE,
  QUESTION_CONDITION_RADIO_PROP_TYPE,
  QUESTION_CONDITION_SLIDER_PROP_TYPE,
  QUESTION_CONDITION_TEXT_PROP_TYPE,
} from '../QuestionConditions.constants';

import { QUESTION_CONDITION_EDIT_TYPE_COMPONENT_MAP } from '../../ActionTab.constants';

const QuestionConditionEdit = ({
  questionAction,
  questionCondition,
  titleMaxCharacter,
  onClose,
  onCloseSidebar,
  onUpdateConditionCompleted,
}) => {
  const { __typename: questionConditionTypeName } = questionCondition;

  const questionConditionEditArgs = {
    questionAction,
    questionCondition,
    titleMaxCharacter,
    onClose,
    onCloseSidebar,
    onUpdateConditionCompleted,
  };

  const QuestionConditionEditComponent = QUESTION_CONDITION_EDIT_TYPE_COMPONENT_MAP[questionConditionTypeName];

  return <QuestionConditionEditComponent args={questionConditionEditArgs} />;
};

QuestionConditionEdit.propTypes = {
  onClose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  onUpdateConditionCompleted: PropTypes.func,
  questionAction: QUESTION_ACTION_PROP_TYPE,
  questionCondition: PropTypes.oneOfType([
    QUESTION_CONDITION_CHECKBOX_PROP_TYPE,
    QUESTION_CONDITION_RADIO_PROP_TYPE,
    QUESTION_CONDITION_SLIDER_PROP_TYPE,
    QUESTION_CONDITION_TEXT_PROP_TYPE,
  ]),
  titleMaxCharacter: PropTypes.number,
};

export { QuestionConditionEdit };
