export enum ERefreshTokenKeys {
  /** Audience */
  AUD = 'aud',
  /** Expiration (timespan) */
  EXPIRATION = 'exp',
  /** User id */
  USER_ID = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata',
  /** Define when the token has been issued */
  ISSUED_AT = 'iat',
  /** Define who issued the token */
  ISSUER = 'iss',
  /** Define when the token became valid */
  NOT_VALID_BEFORE = 'nbf',
  /** Define the type of token */
  TOKEN_TYPE = 'token_type',
}
