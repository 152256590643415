import React from 'react';

import ImageIcon from '../Icon/ImageIcon';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import deleteIcon from '../../static/img/bin-icon.svg';
import editSVG from '../../static/img/next-icon.svg';
import './ListItemEditDelete.scss';

const ListItemEditDelete = ({ icon, text, onEdit, onDelete, asset, isBorder, isTextFewLines }) => {
  const isIcon = icon && <ImageIcon src={icon} />;
  const isEditAndDelete = onEdit && onDelete;
  const textLineStyle = isTextFewLines ? 'l-text-item-few-lines' : 'l-text-item-single-line';

  return (
    <li className={`l-list-item-container l-margin-bottom ${isBorder && 'c-item-border h-margin-top-sm h-margin-bottom-sm'}`}>
      <div className="l-item-contents-container ">
        <div className="l-text-container">
          {isIcon}
          <div className={`${textLineStyle} ${isIcon && 'l-margin-left'}`}>
            {asset ? (
              <a className="c-a-link" href={getSignedUrl(asset.s3Key, asset.contentType)} rel="noreferrer" target="_blank">
                {text}
              </a>
            ) : (
              text
            )}
          </div>
        </div>
        <div>
          {onEdit && (
            <button className={`l-button ${isEditAndDelete && 'l-margin-bottom'}`} type="button" onClick={onEdit}>
              <ImageIcon src={editSVG} />
            </button>
          )}
          {onDelete && (
            <button className="l-button" type="button" onClick={onDelete}>
              <ImageIcon src={deleteIcon} width="1" />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};
export default ListItemEditDelete;
