import React from 'react';

import Avatar from '../../../../components/UI/Avatar/colorAvatar';
import RoundSettingsButton from '../../../../components/UI/Button/RoundSettingsButton/RoundSettingsButton';
import { capitalize } from '../../../../utils/utils';
import { ActiveStatus } from '../../../OrganisationUsersPage/styled';

export const createGroupTableData = (data, onEdit) =>
  data.map((group, id) => {
    const { name, status: groupStatus, smartCodes = [] } = group;

    const smartCodesList = smartCodes.map((item) => item.code).join(', ');

    const title = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar name={name} />
        <p style={{ marginLeft: '1rem' }}> {name}</p>
      </div>
    );

    const status = (
      <div>
        <ActiveStatus isActive={groupStatus === 'ACTIVE'} />
        {capitalize(groupStatus)}
      </div>
    );
    const button = <RoundSettingsButton onClick={() => onEdit(group)} />;

    return {
      id,
      title,
      smartCodesList,
      status,
      button,
    };
  });
