import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { ContentPage } from '../ContentSnippet/Fragments/Collection';
import QueryRequest from '../QueryRequest';

export const GET_ALL_CONTENT_PAGES = gql`
  query GetContentPage($contentPageId: Int!) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        ...ContentPage
      }
    }
  }
  ${ContentPage}
`;

const getContentPage = ({ contentPageId, onError, onSuccessResult }) => (
  <Query fetchPolicy="no-cache" query={GET_ALL_CONTENT_PAGES} variables={{ contentPageId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.contentPage.getContentPage}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentPage.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  contentPageId: PropTypes.number,
};

getContentPage.defaultProps = {
  contentPageId: null,
};

export default getContentPage;
