import React, { useState, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import Checkbox from '../Checkbox/Checkbox';
import Field from '../Forms/Field';
import Form from '../Forms/Form';
import FormHeader from '../Forms/FormHeader';
import TextField from '../TextField/TextField';
import SwitchBtn from '../UI/Button/SwitchButton';

import RightNavBar from './RightNavBar';

import AppContext from '../../context/AppContext';
import CreateUserType from '../../middleware/UserType/createUserTypeRequest';
import UpdateUserType from '../../middleware/UserType/updateUserTypeRequest';
import { permissionTypes } from '../../pages/OrganisationUsersPage/OrganisationUserPageNew';

const ProjectUserTypeSideBar = (props) => {
  const { type, onCancel } = props;

  const { project } = useContext(AppContext);

  const defaultPermission = Object.keys(permissionTypes)[0];

  const [isEdit, setIsEdit] = useState(false);
  const [newType, setNewType] = useState({});
  const [permission, setPermission] = useState(defaultPermission);
  const [sendRequest, setSendRequest] = useState(false);

  const handleNewTypeInputs = (e) => {
    const { name, value } = e.target;
    setNewType({ ...newType, [name]: value });
    if (name === 'permission') setPermission(value);
  };

  const handleStatusInput = (value) => {
    setNewType({ ...newType, status: value });
  };

  const handleCreateError = () => {
    setSendRequest(false);
  };

  const handleCreateSuccess = (data) => {
    setSendRequest(false);
    onCancel('create', data);
  };

  const handleUpdateError = () => {
    setSendRequest(false);
  };

  const handleUpdateSuccess = (data) => {
    setSendRequest(false);
    onCancel('update', data);
  };

  const handleAddBtn = () => {
    setSendRequest(true);
  };

  useEffect(() => {
    if (Object.keys(type).length !== 0) {
      setIsEdit(true);
      setNewType(type);
      setPermission(type.permission);
    }
  }, [type]);

  return (
    <>
      <RightNavBar confirmBtnName={!isEdit ? 'Add' : 'Update'} hasDelete={isEdit} onCancel={onCancel} onSave={handleAddBtn}>
        <Form name="member">
          <FormHeader title={!isEdit ? 'Add new user type' : `Edit ${type.name} details`} />
          <Field id="name" label="Title">
            <TextField name="name" onChange={handleNewTypeInputs} value={newType.name} />
          </Field>
          <Field id="permissions" label="Permissions">
            {Object.keys(permissionTypes).map((pType) => (
              <Checkbox
                displayText={permissionTypes[pType]}
                isSelected={pType === permission}
                key={pType}
                name="permission"
                onChange={handleNewTypeInputs}
                value={pType}
              />
            ))}
          </Field>
          <Field id="status" label="Status">
            <SwitchBtn defaultChecked={newType.status !== 'ENABLED'} id={0} isChanged={handleStatusInput} name="status" />
          </Field>
        </Form>
      </RightNavBar>
      {sendRequest && !isEdit && (
        <CreateUserType
          approvalRequired
          name={newType.name}
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
          permission={permission}
          projectId={project.id}
          userTypeCode={newType.userTypeCode}
        />
      )}
      {sendRequest && isEdit && (
        <UpdateUserType
          approvalRequired
          name={newType.name}
          onError={handleUpdateError}
          onSuccessResult={handleUpdateSuccess}
          permission={newType.permission}
          userTypeId={newType.id}
        />
      )}
    </>
  );
};

ProjectUserTypeSideBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.shape({
    name: PropTypes.string,
    permission: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      title: PropTypes.string,
    }),
    userId: PropTypes.number,
  }),
};

ProjectUserTypeSideBar.defaultProps = {
  type: {},
};

export default ProjectUserTypeSideBar;
