import React from 'react';

import PropTypes from 'prop-types';

/**
 * Used to insert an SVG icon into other components.
 */
const Icon = ({ className, id, style }) => (
  <svg aria-hidden="true" className={className} style={style}>
    <use xlinkHref={`#${id}`} />
  </svg>
);

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

Icon.defaultProps = {
  className: null,
  style: null,
};

export default Icon;
