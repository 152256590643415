import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { ButtonIconOnly } from '@netfront/ui-library';
import isNil from 'lodash.isnil';

import { DEFAULT_API_ERROR_MESSAGE, DEFAULT_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerRadioSnippetProps } from './QuestionAnswerRadioSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerRadioMutationVariables,
  useContentPageContext,
} from '../../../../contexts';
import { useDeleteUserGeneratedResponse, useEkardoMutation } from '../../../../hooks';
import { IContentPage, IDBQuestionResponse, IQuestionAnswerRadio, IQuestionConfigurationRadio } from '../../../../interfaces';
import { IUserGeneratedResponseOnSaveCompletedResponse, UserGeneratedResponse } from '../../../UserGeneratedResponse';
import { IRadioOption, RadioButtonSnippet } from '../../RadioButtonSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerRadioSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  response,
  userFlowStepTrackId,
}: QuestionAnswerRadioSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false } = contentSnippet ?? {};
  const { questionResponseRadioId } = answer ?? ({} as IQuestionAnswerRadio);
  const {
    allowUserGeneratedResponses: shouldAllowUserGeneratedResponses,
    canModify,
    responseSet: { availableResponses, id: questionResponseSetId },
  } = configuration ?? ({} as IQuestionConfigurationRadio);

  const [deletedQuestionResponseRadioId, setDeletedQuestionResponseRadioId] = useState<number>();
  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);
  const [savedAvailableResponses, setSavedAvailableResponses] = useState<IDBQuestionResponse[]>(availableResponses);
  const [savedQuestionResponseRadioId, setSavedQuestionResponseRadioId] = useState<number | undefined>(questionResponseRadioId);

  const [executeSetUserAnswerRadio] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerRadioMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerRadio,
    options: {
      onError: (error) => {
        handleApolloError(error);
        setMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const handleAddUserGeneratedResponseSaveCompleted = (data: IUserGeneratedResponseOnSaveCompletedResponse) => {
    if (!contentSnippetFormId) {
      return;
    }

    const { updatedAvailableResponses } = data;

    const currentQuestionResponseIds = availableResponses.map(({ id }) => id);

    setSavedAvailableResponses(updatedAvailableResponses);

    const newAvailableResponseIds = updatedAvailableResponses
      .filter(({ id }) => !currentQuestionResponseIds.includes(id))
      .map(({ id }) => id);

    setSavedQuestionResponseRadioId(Number(newAvailableResponseIds[0]));

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    executeSetUserAnswerRadio({
      variables: {
        ...baseVariables,
        questionResponseRadioId: Number(newAvailableResponseIds[0]),
      },
    });
  };

  const handleRadioButtonChange = (inputQuestionResponseRadioId: string) => {
    if (!contentSnippetFormId) {
      return;
    }

    setSavedQuestionResponseRadioId(Number(inputQuestionResponseRadioId));

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    executeSetUserAnswerRadio({
      variables: {
        ...baseVariables,
        questionResponseRadioId: Number(inputQuestionResponseRadioId),
      },
    });
  };

  const handleDeleteUserGeneratedResponseCompleted = () => {
    const updatedSavedAvailableResponses = savedAvailableResponses.filter(({ id }) => id !== deletedQuestionResponseRadioId);

    setSavedAvailableResponses(updatedSavedAvailableResponses);
    setSavedQuestionResponseRadioId(0);
  };

  const handleDeleteUserGeneratedResponseError = (error: ApolloError) => {
    handleApolloError(error);
    setMessage({
      error: DEFAULT_API_ERROR_MESSAGE,
      success: '',
    });
  };

  const { handleDeleteUserGeneratedResponse } = useDeleteUserGeneratedResponse({
    onCompleted: handleDeleteUserGeneratedResponseCompleted,
    onError: handleDeleteUserGeneratedResponseError,
  });

  useEffect(() => {
    if (isNil(savedQuestionResponseRadioId)) {
      return;
    }

    saveContentSnippetQuestionAndApplyQuestionActions({
      actions,
      answer,
      availableResponses: savedAvailableResponses,
      contentPage,
      contentSnippetId,
      dispatch,
      selectedQuestionAnswers: {
        radioId: savedQuestionResponseRadioId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAvailableResponses, savedQuestionResponseRadioId]);

  const responseOptions = response ? [response] : availableResponses;

  const radioButtonOptions = responseOptions.map((availableResponse) => {
    const { asset, id, label = '', userId } = availableResponse;

    const idAsString = id.toString();

    const deleteButton = userId ? (
      <div className="radio-button-option-delete-button-container">
        <ButtonIconOnly
          iconId="id_bin_icon"
          text="Delete"
          onClick={() => {
            setDeletedQuestionResponseRadioId(id);

            handleDeleteUserGeneratedResponse({
              questionResponseId: id,
              questionResponseSetId,
            });
          }}
        />
      </div>
    ) : null;

    const radioOption: IRadioOption = {
      asset,
      deleteButton,
      id: idAsString,
      label,
      value: idAsString,
    };

    return radioOption;
  });

  const identifier = `radio-button-${contentSnippetId}`;
  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <React.Fragment>
      <RadioButtonSnippet
        id={identifier}
        isDisabled={!canModify || isSummary}
        isRequired={isMandatory}
        message={message}
        name={identifier}
        options={radioButtonOptions}
        selectedValue={(savedQuestionResponseRadioId ?? '').toString()}
        onChange={handleRadioButtonChange}
      />
      {contentSnippet && shouldAllowUserGeneratedResponses && (
        <UserGeneratedResponse
          accessToken={accessToken}
          contentSnippetId={contentSnippet.id}
          currentAvailableResponses={availableResponses}
          questionConfigurationType="Radio"
          questionResponseSetId={questionResponseSetId}
          onSaveCompleted={handleAddUserGeneratedResponseSaveCompleted}
        />
      )}
    </React.Fragment>
  );
};

export { QuestionAnswerRadioSnippet };
