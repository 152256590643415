import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import UpdateContentPageStatusRequest from '../../../middleware/ContentPage/updateContentPageStatusRequest';
import EditBtn from '../../UI/Button/EditButton';

const DDContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  left: -8rem;
  top: 2rem;
  position: absolute;
  visibility: ${props => (props.isDropDownOpen ? 'visible' : 'hidden')};
  width: 9em;
`;

const Wrapper = styled.div``;

const DDItem = styled.button`
  background-color: inherit;
  border: none;

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);
    fill: rgba(9, 30, 66, 0.04);
  }
`;

const Dropdown = props => {
  const { item, handleRefresh, onSideBarOpen, handleDeleteItem, isSettingsOnly } = props;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const handleDropDown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const handleDuplicate = () => {
    onSideBarOpen('duplicate page');
  };

  const handleSetting = () => {
    onSideBarOpen('setting');
  };

  const handleNewPage = () => {
    onSideBarOpen('new page');
  };

  const handleNewSubPage = () => {
    onSideBarOpen('new subpage');
  };

  const handleDeletePage = id => {
    setDeleteRequest(true);
    setDeleteId(id);
  };

  const handleError = () => {};

  const onDelete = id => {
    setDeleteRequest(false);
    handleRefresh();
    handleDeleteItem(id);
  };

  return (
    <Wrapper>
      <EditBtn isClicked={() => handleDropDown()} />
      <DDContainer isDropDownOpen={isDropDownOpen} onMouseLeave={handleDropDown}>
        {isSettingsOnly ? (
          <DDItem onClick={handleSetting}>Setting</DDItem>
        ) : (
          <>
            <DDItem onClick={handleSetting}>Setting</DDItem>
            <DDItem onClick={handleNewPage}>New page</DDItem>
            <DDItem onClick={handleNewSubPage}>New sub page</DDItem>
            <DDItem onClick={handleDuplicate}>Duplicate</DDItem>
            <DDItem onClick={() => handleDeletePage(item.id)}>Delete</DDItem>
          </>
        )}
      </DDContainer>
      {deleteRequest && (
        <UpdateContentPageStatusRequest contentPageId={deleteId} onError={handleError} onSuccessResult={onDelete} status={2} />
      )}
    </Wrapper>
  );
};

Dropdown.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  item: PropTypes.shape({
    children: PropTypes.array,
    data: PropTypes.object,
    hasChildren: PropTypes.bool,
    id: PropTypes.number,
    isChildrenLoading: PropTypes.bool,
    isExpanded: PropTypes.bool,
  }).isRequired,
  onSideBarOpen: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func,
  isSettingsOnly: PropTypes.bool,
};

Dropdown.defaultProps = {
  handleDeleteItem: () => {},
  isSettingsOnly: false,
};

export default Dropdown;
