import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_MEMBERSHIP_DETAILS = gql`
  mutation updateMembershipDetails(
    $userId: Int!
    $organisationId: Int!
    $membershipType: EMembershipType!
    $permission: EMemberPermission!
  ) {
    membership {
      updateMembership(
        membership: {
          userId: $userId
          organisationId: $organisationId
          membershipType: $membershipType
          permission: $permission
        }
      )
    }
  }
`;

const updateOrgMembershipDetail = ({
  userId,
  organisationId,
  membershipType,
  permission,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_MEMBERSHIP_DETAILS}>
    {(updateMembershipDetails, { loading, error, data }) => {
      const variables = {
        userId,
        organisationId,
        membershipType,
        permission,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.membership.updateMembership);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateMembershipDetails}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateOrgMembershipDetail.propTypes = {
  membershipType: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  organisationId: PropTypes.number.isRequired,
  permission: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
};

updateOrgMembershipDetail.defaultProps = {};

export default updateOrgMembershipDetail;
