import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const TOGGLE_ASSET_PUBLIC = gql`
  mutation($assetId: String!) {
    asset {
      toggleAssetPublic(assetId: $assetId) {
        s3Key
        contentType
        assetId
        alt
        fileName
        tags
        transcript
        alt
        xAxisFocus
        uploaded
        isPublic
        publicS3Key
        fileName
        publicUrl
      }
    }
  }
`;

const toggleAssetPublicRequest = ({ assetId, onError, onSuccessResult }) => (
  <Mutation mutation={TOGGLE_ASSET_PUBLIC}>
    {(toggleAssetPublic, { loading, error, data }) => {
      const variables = {
        assetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.asset.toggleAssetPublic);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={toggleAssetPublic}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

toggleAssetPublicRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default toggleAssetPublicRequest;
