import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_QUE_CHECKBOX_CONFIGURATION = gql`
  mutation createCheckboxQuesConfigRequest(
    $allowUserGeneratedResponses: Boolean
    $description: String!
    $maxCheckedResponse: Int!
    $projectId: String!
    $title: String!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { description: $description, projectId: $projectId }
        questionConfigurationCheckbox: {
          allowUserGeneratedResponses: $allowUserGeneratedResponses
          maxCheckedResponse: $maxCheckedResponse
          questionResponseSet: { title: $title }
        }
      ) {
        ... on Checkbox {
          id
          description
          maxCheckedResponse
          responseSet {
            id
            availableResponses {
              id
              label
              questionResponseSetId
              value
            }
          }
        }
      }
    }
  }
`;

const createCheckboxQuesConfigRequest = ({
  allowUserGeneratedResponses,
  description,
  maxCheckedResponse,
  onError,
  onSuccessResult,
  projectId,
  title,
}) => (
  <Mutation mutation={CREATE_QUE_CHECKBOX_CONFIGURATION}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        allowUserGeneratedResponses,
        description,
        projectId,
        maxCheckedResponse,
        title,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.questionConfiguration.createQuestionConfiguration.id,
          data
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createCheckboxQuesConfigRequest.propTypes = {
  description: PropTypes.string.isRequired,
  maxCheckedResponse: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  allowUserGeneratedResponses: PropTypes.bool,
};

createCheckboxQuesConfigRequest.defaultProps = {
  allowUserGeneratedResponses: false,
};

export default createCheckboxQuesConfigRequest;
