const COMMON_LIBRARY_CONSTANTS = Object.freeze({
  MESSAGES: Object.freeze({
    ERROR: Object.freeze({
      ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED: 'ensure the correct environment variables are configured',
      NOT_FOUND: 'not found',
      PLEASE_CONFIGURE_HOOK_USING_EITHER_ON_COMPLETED_ON_OR_COMPLETED_ASYNC_CONFIG:
        'Please configure this hook using either onCompleted OR onCompletedAsync',
      PLEASE_CONFIGURE_HOOK_USING_EITHER_ON_ERROR_ON_ERROR_ASYNC_CONFIG: 'Please configure this hook using either onError OR onErrorAsync',
      UNEXPECTED: 'Oops, something went wrong. Please try again later.',
    }),
  }),
});

export { COMMON_LIBRARY_CONSTANTS };
