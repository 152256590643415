import React from 'react';

import { parseISO } from 'date-fns';
import isEmpty from 'lodash.isempty';

import './Moderation.scss';

import dashIcon from '../../../static/img/dash-icon.svg';
import activeAttachment from '../../../static/img/file-icon.svg';
import ImageIcon from '../../Icon/ImageIcon';
import RoundSettingsButton from '../../UI/Button/RoundSettingsButton/RoundSettingsButton';

const NOT_APPLICABLE = 'N/A';

export const getPostTableData = (posts, onSideBarOpenHandler) =>
  posts.map(({ assets, author: { displayedName }, createdDate, commentCount, id, message }) => {
    const attachment = (
      <button className="d-attachment-button" disabled={isEmpty(assets)} type="button" onClick={() => onSideBarOpenHandler(id)}>
        <ImageIcon src={isEmpty(assets) ? dashIcon : activeAttachment} />
      </button>
    );

    return {
      attachment,
      author: displayedName || NOT_APPLICABLE,
      commentCount,
      createdDate: parseISO(createdDate).toLocaleDateString(),
      id,
      message: message || NOT_APPLICABLE,
      roundSettingsButton: <RoundSettingsButton onClick={() => onSideBarOpenHandler(id)} />,
    };
  });

export const getUserTableData = (users, onSideBarOpenHandler) =>
  users.map(({ email, firstName, id, lastName, postCount }) => ({
    email,
    firstName,
    id,
    lastName,
    postCount,
    roundSettingsButton: <RoundSettingsButton onClick={() => onSideBarOpenHandler(id)} />,
  }));
