import styled from 'styled-components';

export const AdvanceContainer = styled.div`
  overflow-y: scroll;
`;

export const AdvanceItem = styled.div`
  margin: 1rem auto;
  width: 100%;
`;

export const AdvanceTitle = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  width: 100%;
`;

export const AttachmentIconImage = styled.img`
  margin-right: 0.5rem;
`;

export const AttachmentLink = styled.a`
  text-decoration: none;
`;

export const AttachmentLinkContainer = styled.div`
  border: 1px solid #e4e4e3;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 0.5rem;
`;

export const DropZoneAreaContainer = styled.div`
  margin-top: 1rem;
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexFull = styled(Flex)`
  flex: 1;
  justify-content: ${props => (props.isCentered ? 'center' : 'flex-start')};
`;

export const FlexHorizontal = styled(Flex)`
  flex-direction: row;
`;

export const FlexVertical = styled(Flex)`
  flex-direction: column;
`;

export const CreatedByUserContainer = styled(Flex)`
  font-size: 0.75rem;
  font-weight: normal;
  justify-content: flex-end;
`;

export const Header = styled(Flex)`
  align-items: center;
  font-weight: bold;
`;

export const Icon = styled.i`
  background-color: rgb(109, 122, 138);
  border-radius: 50%;
  color: #fff;
  padding: 0.25rem 0.43rem;
  transform: rotate(270deg);
`;

export const NoteDetailsContainer = styled(FlexHorizontal)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const PdfLink = styled.a`
  text-decoration: none;
`;

export const PdfLinkContainer = styled.div`
  border: 1px solid #e4e4e3;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.5rem;
  padding: 0.5rem;
`;

export const PdfIconImage = styled.img`
  margin-right: 0.5rem;
`;

export const Text = styled.span`
  font-size: 0.85rem;
  font-weight: normal;
  line-height: 1.25em;
  padding-bottom: 0.25rem;
`;
