import { gql } from '@apollo/client';

const END_USER_FLOW_STEP_MUTATION = gql`
  mutation endUserFlowStep($userFlowStepTrackId: Int!) {
    userFlowStepTrack {
      endUserFlowStep(trackingId: $userFlowStepTrackId, status: COMPLETED) {
        id
      }
    }
  }
`;

export { END_USER_FLOW_STEP_MUTATION };
