/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImageUploader from '../UI/AssetUploader/ImageUploader';
import SwitchBtn from '../UI/Button/SwitchButton';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { Preview, Logo, EditBtn } from './styled';

import { getAssetsUrl } from '../../config';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateDragAndDropConfigRequest from '../../middleware/FormContainer/createDragAndDropConfigRequest';

const Container = styled.div`
  margin: 1em 0;
`;

const Input = styled.input`
  background-color: #fff;
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  display: block;
  line-height: 1.6em;
  margin-top: 0.5em;
  width: 100%;

  &:focus {
    background: #fff;
    border-color: lightgray;
    box-shadow: 0 0 0 1px lightgrey;
    outline: none;
  }
`;

const AddInput = styled(Input)`
  background-color: #fff;
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  line-height: 1.7em;
  min-width: 75%;
`;
const ValueInput = styled(Input)`
  border: 1px solid #e4e4e3;
  height: fit-content;
  line-height: 1.8em;
  margin-top: 0.5em;
  min-width: unset;
  padding: 0.45em;
  text-align: center;
`;

const NumInput = styled(Input)`
  width: 35%;
`;

const AddTitle = styled.div`
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: 3fr 1fr;
  justify-content: space-between;
`;

const AddNewResponse = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 2.6em;
  justify-content: flex-start;
  width: 100%;
`;

const InfoIcon = styled.span`
  border-radius: 50%;
  color: #4bc1c9;
  display: inline-block;
  height: 1em;
  line-height: 16px;
  margin: 0 1em;
  text-align: center;
  width: 1em;
`;

const DeleteIcon = styled(InfoIcon)`
  background-color: #f27576;
  display: none;
  height: 1.2em;
  margin: 0;
  width: 1.2em;
`;

const DeleteImg = styled.img`
  height: 14px;
  width: 14px;
`;

const QuestionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ResRow = styled.div`
  align-items: center;
  column-gap: 1em;
  cursor: pointer;
  display: grid;
  grid-template-columns: 3fr 1fr;
  justify-content: space-between;
  width: 100%;

  &:hover {
    ${AddInput} {
      background-color: #f4f4f4;
    }
    ${ValueInput} {
      background-color: #f4f4f4;
    }
    ${DeleteIcon} {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      position: absolute;
      right: 1em;
      top: 35%;
    }
  }
`;

const AddImages = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;

  & div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: cetner;
  }
`;

const ImageManage = styled.div`
  grid-column: 1 / span 2;

  & img {
    object-fit: contain;
    width: 4em;
  }
`;

const MatchAlert = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  height: 95.5%;
  position: fixed;
  right: 0;
  top: 0;
  width: 416px;
  z-index: 99;

  & div {
    align-items: center;
    background: white;
    border: 2px solid black;
    display: flex;
    flex-direction: column;
    height: 284px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 3em;
    position: relative;
    text-align: center;
    top: 25%;
    width: 336px;

    & button {
      background-color: black;
      border: none;
      color: white;
      margin-top: 2em;
      padding-bottom: 0.5em;
      padding-left: 2em;
      padding-right: 2em;
      padding-top: 0.5em;
    }
  }
`;

const ResponseSideBar = props => {
  const {
    description,
    projectId,
    setResponseSetData,
    onClick,
    resType,
    onChangeTitle,
    defaultValueSl,
    maxValueSl,
    minValueSl,
    stepSl,
    onChangeSliderInput,
    setPreviewRespData,
    setResponseSetID,
    setQuestConfId,
    matchType,
  } = props;
  const [title, setTitle] = useState('');
  const [label, setLabel] = useState('');
  const [value, setValue] = useState(0);
  const [asset, setAsset] = useState();
  const [resGroup, setResGroup] = useState([
    {
      label: '',
      value: 0,
      asset: null,
      assetId: null,
    },
  ]);
  const [matchItems, setMatchItems] = useState([]);
  const [availableResponses, setAvailableResponses] = useState([]);
  const [hasDragAndDropData, setHasDragAndDropData] = useState(false);
  const [matchObjects, setMatchObjects] = useState([
    { name: '', items: [] },
    { name: '', items: [] },
  ]);
  const [showWhichSection, setShowWhichSection] = useState('Drop');

  const handleNewRes = () => {
    const res = [...resGroup];

    res.push({
      label,
      value: value.toString(),
      id: res.length,
      asset,
      assetId: asset ? asset.assetId : null,
    });

    setResGroup(res);
    setLabel('');
    setValue(Number.parseInt(res[res.length - 1].value || 0, 10) + 1);
    setAsset();
  };

  const editLabel = (val, id) => {
    const editedResGroup = [...resGroup];
    editedResGroup.map(item => {
      if (item.id === id) {
        // eslint-disable-next-line no-param-reassign
        item.label = val;
      }
      return item;
    });
    setResGroup(editedResGroup);
    // setResponseSetData(editedResGroup);
  };

  const editValue = (val, id) => {
    const editedResGroup = [...resGroup];
    editedResGroup.map(item => {
      if (item.id === id) {
        // eslint-disable-next-line no-param-reassign
        item.value = val;
      }
      return item;
    });
    setResGroup(editedResGroup);
    // setResponseSetData(editedResGroup);
  };

  const editAsset = (newAsset, id) => {
    const editedResGroup = [...resGroup];
    editedResGroup.map(item => {
      if (item.id === id) {
        // eslint-disable-next-line no-param-reassign
        item.asset = newAsset;
        // eslint-disable-next-line no-param-reassign
        item.assetId = newAsset.assetId;
      }
      return item;
    });
    setResGroup(editedResGroup);
    // setResponseSetData(editedResGroup);
  };

  const handleOnChangeTitle = e => {
    setTitle(e.target.value);
    onChangeTitle(e.target.value);
  };

  const onCancelResponseSideBar = () => {
    onClick();
    setPreviewRespData([]);
    // setResGroup(res);
    setResponseSetData([]);
  };

  const isDragDropType = matchType === 'ROW';

  const [isAddImage, setIsAddImage] = useState(false);
  const handleImageAdd = () => {
    setIsAddImage(!isAddImage);
  };

  const ImageManager = ({ item: { id }, getAsset, currentAsset }) => {
    const [isEditAsset, setEditAsset] = useState(false);
    const [assetUrl, setAssetUrl] = useState(
      currentAsset
        ? // eslint-disable-next-line react/prop-types
          getSignedUrl(currentAsset.s3Key, currentAsset.contentType)
        : ''
    );
    const [localAsset, setLocalAsset] = useState(
      currentAsset || { assetId: null }
    );

    const getAssetData = newAsset => {
      setEditAsset(false);
      setAssetUrl(getSignedUrl(newAsset.s3Key, newAsset.contentType));
      setLocalAsset(newAsset);
    };

    useEffect(() => {
      if (
        localAsset.assetId &&
        localAsset.assetId !== (currentAsset || {}).assetId
      )
        getAsset(localAsset);
    }, [localAsset.assetId]);

    return (
      <ImageManage
        key={`imageManage${id}`}
        style={{ display: isAddImage ? 'block' : 'none' }}
      >
        {assetUrl && !isEditAsset ? (
          <Preview>
            <EditBtn
              onClick={() => {
                setEditAsset(true);
              }}
            >
              Change the image
            </EditBtn>
            <Logo alt="logo" src={assetUrl} />
          </Preview>
        ) : (
          <ImageUploader
            getData={getAssetData}
            name="bgImg"
            projectId={projectId}
            type="IMAGE"
          />
        )}
      </ImageManage>
    );
  };

  ImageManager.propTypes = {
    getAsset: PropTypes.func.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    currentAsset: PropTypes.shape({}),
  };

  ImageManager.defaultProps = {
    currentAsset: null,
  };

  // eslint-disable-next-line react/prop-types
  function ResItem({ item, index }) {
    // eslint-disable-next-line react/prop-types
    const [localLabel, setLocalLabel] = useState(item.label);
    // eslint-disable-next-line react/prop-types
    const [localValue, setLocalValue] = useState(item.value);

    return (
      <ResRow key={index}>
        <div style={{ minWidth: '75%', position: 'relative' }}>
          <DeleteIcon>
            <DeleteImg
              alt="delete"
              className="delete-icon-img"
              src={`${getAssetsUrl()}/icons/content-snippet/delete-icon.svg`}
            />
          </DeleteIcon>

          <AddInput
            // eslint-disable-next-line react/prop-types
            onBlur={e => editLabel(e.target.value, item.id)}
            onChange={e => setLocalLabel(e.target.value)}
            placeHolder="Add a label here"
            value={localLabel}
          />
        </div>
        <ValueInput
          // eslint-disable-next-line react/prop-types
          onBlur={e => editValue(e.target.value, item.id)}
          onChange={e => setLocalValue(e.target.value)}
          value={localValue}
        />
        <ImageManager
          // eslint-disable-next-line react/prop-types
          currentAsset={item.asset}
          getAsset={localAsset => {
            // eslint-disable-next-line react/prop-types
            editAsset(localAsset, item.id);
          }}
          item={item}
        />
      </ResRow>
    );
  }

  // beginning of match sections
  const DragItemInput = ({ item, itemIndex, handleItemChange }) => {
    const [dragInput, setDragInput] = useState(item);
    return (
      <AddInput
        id="addInput"
        // eslint-disable-next-line react/no-array-index-key
        key={`drag_item_${item}_${itemIndex}`}
        onBlur={e => handleItemChange(e.target.value, itemIndex)}
        onChange={e => setDragInput(e.target.value)}
        value={dragInput}
      />
    );
  };

  DragItemInput.propTypes = {
    handleItemChange: PropTypes.func.isRequired,
    item: PropTypes.string.isRequired,
    itemIndex: PropTypes.number.isRequired,
  };

  const DragItem = ({ category, handleItemChange }) => (
    <>
      <QuestionTitle key="DragItem_Title">
        <p>
          <strong>Items in category {category.name}:</strong>
        </p>
        <Tooltip text="add the items which correctly fall into this category" />
      </QuestionTitle>
      {category.items.map((item, itemIndex) => (
        <DragItemInput
          handleItemChange={handleItemChange}
          item={item}
          itemIndex={itemIndex}
        />
      ))}
      {(isDragDropType || category.items.length === 0) && (
        <AddNewResponse
          onClick={() => {
            handleItemChange('', category.items.length);
          }}
        >
          <InfoIcon>+</InfoIcon>Add New Item
        </AddNewResponse>
      )}
    </>
  );

  DragItem.propTypes = {
    category: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    handleItemChange: PropTypes.func.isRequired,
  };

  const DragSection = ({ handleItemChange }) => (
    <div style={{ display: showWhichSection === 'Drag' ? 'block' : 'none' }}>
      {matchObjects.map((category, index) => {
        const handleDragSectionItemChange = (dragValue, itemIndex) => {
          handleItemChange(dragValue, itemIndex, index);
        };
        return (
          <DragItem
            category={category}
            handleItemChange={handleDragSectionItemChange}
            index={index}
            key={index}
          />
        );
      })}
    </div>
  );
  DragSection.propTypes = {
    handleItemChange: PropTypes.func.isRequired,
  };

  const DropItem = ({ handleCategoryChange, index, category }) => {
    const [dragItemValue, setDragItemValue] = useState(category.name);
    return (
      <div style={{ marginBottom: '1em' }}>
        <QuestionTitle key="Drop_Category_Title">
          <p>
            <strong>Drop Category {index + 1}</strong>
          </p>
          <Tooltip text="please add drop categories before adding drag items" />
        </QuestionTitle>
        <AddInput
          id="addInput"
          onBlur={e => handleCategoryChange(e.target.value, index)}
          onChange={e => setDragItemValue(e.target.value)}
          value={dragItemValue}
        />
      </div>
    );
  };

  DropItem.propTypes = {
    category: PropTypes.shape({ name: PropTypes.string }).isRequired,
    handleCategoryChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  };

  const DropSection = ({ handleCategoryChange }) => (
    <div style={{ display: showWhichSection === 'Drop' ? 'block' : 'none' }}>
      {matchObjects.map((category, index) => (
        <DropItem
          category={category}
          handleCategoryChange={handleCategoryChange}
          index={index}
          key={index}
        />
      ))}
      <AddNewResponse
        onClick={() => {
          handleCategoryChange('', matchObjects.length);
        }}
      >
        <InfoIcon>+</InfoIcon>Add New Category
      </AddNewResponse>
    </div>
  );

  DropSection.propTypes = {
    handleCategoryChange: PropTypes.func.isRequired,
  };

  // TODO: later once cyrille has updated the api

  const MatchButtonDiv = styled.div`
    margin-bottom: 2em;

    & button {
      background: none;
      border: none;
      line-height: 2em;
      margin-right: 2em;

      &:focus {
        outline: none;
      }
    }
  `;

  const handleSave = obj => {
    // callback
    if (resType === 'Ma' || resType === 'DD') {
      setMatchItems(
        obj.map((item, index) => ({
          sort: index,
          // eslint-disable-next-line react/prop-types
          text: item.name,
        }))
      );

      let localAvailableResponse = [];

      obj.forEach((matchObject, matchIndex) => {
        localAvailableResponse = localAvailableResponse.concat(
          matchObject.items.map((item, itemIndex) => ({
            correctPosition: matchIndex,
            label: item,
            startPosition: itemIndex,
            value: itemIndex,
            static: false,
          }))
        );

        setAvailableResponses(localAvailableResponse);
      });
      setHasDragAndDropData(true);
    } else {
      onClick();
      setResponseSetData(resGroup);
      setPreviewRespData(resGroup);
    }
  };

  const onClickDragAndDropCallback = (id, data) => {
    setResponseSetID(
      data.questionConfiguration.createQuestionConfigurationMatch.responseSet.id
    );
    setQuestConfId(
      data.questionConfiguration.createQuestionConfigurationMatch.id
    );
    setHasDragAndDropData(false);
    onClick();
  };

  const MatchOptions = () => {
    const [isAlert, setIsAlert] = useState(false);

    const handleCategoryChange = (categoryValue, index) => {
      setMatchObjects(currentState => {
        const temp = [...currentState];
        temp[index] = {
          name: categoryValue,
          items: !matchObjects[index] ? [] : matchObjects[index].items,
        };
        return temp;
      });
    };
    const handleItemChange = (itemValue, itemIndex, index) => {
      setMatchObjects(currentState => {
        const temp = [...currentState];
        const tempItems = [...currentState[index].items];
        tempItems[itemIndex] = itemValue;
        temp[index] = {
          name: matchObjects[index].name,
          items: tempItems,
        };
        return temp;
      });
    };
    const handleMatchSectionChange = sectionTitle => {
      if (
        sectionTitle === 'Drag' &&
        !(matchObjects[0].name && matchObjects[1].name)
      ) {
        setIsAlert(true);
      } else {
        setShowWhichSection(sectionTitle);
      }
    };
    const SectionHead = ({ matchLabel }) => (
      <button
        onClick={() => handleMatchSectionChange(matchLabel)}
        style={{
          borderBottom:
            showWhichSection === matchLabel ? '2px solid black' : 'none',
          color: showWhichSection === matchLabel ? 'black' : 'lightgray',
        }}
        type="button"
      >
        {matchLabel}
      </button>
    );
    SectionHead.propTypes = { matchLabel: PropTypes.string.isRequired };

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <MatchButtonDiv>
          <SectionHead matchLabel="Drag" />
          <SectionHead matchLabel="Drop" />
        </MatchButtonDiv>
        <DropSection
          handleCategoryChange={handleCategoryChange}
          matchObjects={matchObjects}
          showWhichSection={showWhichSection}
        />

        <DragSection
          handleItemChange={handleItemChange}
          matchObjects={matchObjects}
          showWhichSection={showWhichSection}
        />
        {isAlert && (
          <MatchAlert>
            <div>
              Drop Categories have have not been created. Please create Drop
              Categories first before adding Drag items.
              <button onClick={() => setIsAlert(false)} type="button">
                Got it!
              </button>
            </div>
          </MatchAlert>
        )}
        {/* // TODO: later once cyrille has updated the api */}
        {/* <AdvancedSection /> */}
      </div>
    );
  };

  const dragAndDropError = () => {
    setHasDragAndDropData(false);
  };

  // End of match section7

  return (
    <RightNavBar
      hasDelete={false}
      onCancel={onCancelResponseSideBar}
      onSave={() => handleSave(matchObjects)}
    >
      <div>
        <h3>
          {resType === 'RB' ? 'Single Choice' : null}
          {resType === 'CB' ? 'Multiple Choice' : null}
          {resType === 'Dd' ? 'Dropdown' : null}
          {resType === 'Sl' ? 'Slider' : null}
          {resType === 'DD' ? 'Drag & Drop' : null}
          {resType === 'Ma' ? 'Match' : null}
        </h3>
        <Container>
          <QuestionTitle key="Response_Title">
            <p>
              <strong>Title</strong>
            </p>
            <Tooltip text="add a meaningful title" />
          </QuestionTitle>
          <Input name="title" onChange={handleOnChangeTitle} value={title} />
        </Container>
        {resType === 'CB' || resType === 'RB' ? (
          <AddImages>
            <div>
              <p>
                <strong>Add Images</strong>
              </p>
              <Tooltip text="select to include images with your responses" />
            </div>
            <SwitchBtn
              defaultChecked={isAddImage}
              id={0}
              name="linkType"
              onChange={handleImageAdd}
            />
          </AddImages>
        ) : null}
        {resType === 'Sl' ? (
          <div>
            <Container>
              Minimun value
              <Tooltip text="set each desired value" />
              <NumInput
                name="minValueSl"
                onChange={onChangeSliderInput}
                type="number"
                value={minValueSl}
              />
            </Container>
            <Container>
              Maximun value
              <Tooltip />
              <NumInput
                name="maxValueSl"
                onChange={onChangeSliderInput}
                type="number"
                value={maxValueSl}
              />
            </Container>
            <Container>
              Default value
              <Tooltip />
              <NumInput
                name="defaultValueSl"
                onChange={onChangeSliderInput}
                type="number"
                value={defaultValueSl}
              />
            </Container>
            <Container>
              Step
              <Tooltip />
              <NumInput
                name="stepSl"
                onChange={onChangeSliderInput}
                type="number"
                value={stepSl}
              />
            </Container>
            <b>Tick marks (optional)</b>
            <Tooltip />
          </div>
        ) : null}
        {resType === 'Ma' || resType === 'DD' ? (
          <MatchOptions />
        ) : (
          <>
            <AddTitle>
              <p>
                <strong>Label</strong>
              </p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p>
                  <strong>Value</strong>
                </p>
                <Tooltip text="input each value" />
              </div>
            </AddTitle>
            {resGroup.map((item, index) => (
              <ResItem index={index} item={item} />
            ))}
            <AddNewResponse onClick={handleNewRes}>
              <InfoIcon>+</InfoIcon>
              Add Response
            </AddNewResponse>

            {/* <AddContent>
              <Response>
                <AddInput
                  id="addInput"
                  key="live_que_label"
                  onChange={e => addLabel(e.target.value, value)}
                  value={label}
                />
              </Response>
              <ValueInput
                key="live_que_value"
                onChange={handleValue}
                value={value}
              />
              <ImageManager
                currentAsset={asset}
                getAsset={handleAsset}
                item={{id: 123}}
              />
              <AddNewResponse onClick={handleNewRes}>
                <InfoIcon>+</InfoIcon>
                Add Response
              </AddNewResponse>
            </AddContent> */}
          </>
        )}
      </div>
      {hasDragAndDropData ? (
        <CreateDragAndDropConfigRequest
          availableResponses={availableResponses}
          description={description}
          matchItems={matchItems}
          onError={dragAndDropError}
          onSuccessResult={onClickDragAndDropCallback}
          projectId={projectId}
          title={title}
          type={matchType}
        />
      ) : null}
    </RightNavBar>
  );
};

ResponseSideBar.propTypes = {
  defaultValueSl: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  maxValueSl: PropTypes.number.isRequired,
  minValueSl: PropTypes.number.isRequired,
  onChangeSliderInput: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
  setPreviewRespData: PropTypes.func.isRequired,
  setQuestConfId: PropTypes.func.isRequired,
  setResponseSetData: PropTypes.func.isRequired,
  setResponseSetID: PropTypes.func.isRequired,
  stepSl: PropTypes.number.isRequired,
  matchType: PropTypes.string,
};

ResponseSideBar.defaultProps = {
  matchType: null,
};

export default ResponseSideBar;
