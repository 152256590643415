import gql from 'graphql-tag';

export const FRAGMENT_CODE = gql`
  fragment code on ContentSnippetCodeType {
    location {
      assetId
      contentType
      fileName
      s3Key
    }
  }
`;
