// TODO: Expose more RangePicker props so it can be customized further
// Note: Ekardo doesn't support date ranges but this can be used as a standalone component

import React, { useState } from 'react';

import generatePicker from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { RangeValue } from 'rc-picker/lib/interface';

import { getAntdLocale } from '../DatePickers.helpers';
import { IDateRange } from '../DatePickers.interfaces';

import { getPresetRanges } from './DateRangePicker.helpers';
import { DateRangePickerProps } from './DateRangePicker.interfaces';

import '../../../css/netfront-ui-external.min.css';

const {
  lang: { dateFormat: format },
} = getAntdLocale();

const DateRangePicker = ({ onChangeHandler, selectedDate }: DateRangePickerProps) => {
  const { RangePicker } = generatePicker(dateFnsGenerateConfig);

  const [currentSavedDate, setCurrentSavedDate] = useState<IDateRange | undefined>(selectedDate);

  const handleDateRangePickerChange = (value: RangeValue<Date>) => {
    if (!value) {
      setCurrentSavedDate(undefined);
      return;
    }

    const [start, end] = value as [Date, Date];

    const updatedSavedDate: IDateRange = {
      end,
      start,
    };

    setCurrentSavedDate(updatedSavedDate);

    if (!onChangeHandler) {
      return;
    }

    onChangeHandler(updatedSavedDate);
  };

  return (
    <div className="netfront-ui-external-antd">
      <RangePicker
        defaultValue={currentSavedDate ? [currentSavedDate.start, currentSavedDate.end] : undefined}
        format={format}
        ranges={getPresetRanges()}
        size="large"
        onChange={(value) => handleDateRangePickerChange(value)}
      />
    </div>
  );
};

export { DateRangePicker };
