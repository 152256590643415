import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import PropTypes from 'prop-types';

import {
  LandingImage,
  LandingDetails,
  PlusBtnWrapper1,
  PlusBtnWrapper2,
  AddFirstPage,
} from '../ContentPage/styled';

import { ReturnToApp, AppBuilder } from './styled';

import AppAdvanceFooter from '../../components/ConsoleFooter/AppAdvanceFooter';
import ComponentNavBar from '../../components/LeftNavBar/AppBuilderNavBar';
import Preloader from '../../components/Preloader/Preloader';
import { FadeIn } from '../../components/UI/Animations/FadeIn/FadeIn';
import PlusBtn from '../../components/UI/Button/PlusButtonCircle';
import Input from '../../components/UI/Input/InputWhite';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import CreateAppAssetRequest from '../../middleware/AppAssets/createAppAssetRequest';
import GetAppUserData from '../../middleware/AppUserData/getUserData';
import SaveAppUserData from '../../middleware/AppUserData/saveUserData';
import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateFilesIntoComponentRequest from '../../middleware/MyAppDashboard/createFilesIntoComponentRequest';
import CreateAppComponent from '../../middleware/MyAppDashboard/createMyAppComponentRequest';
import GetVersionDetails from '../../middleware/MyAppDashboard/getMyAppVersionDetailsRequest';
import ContentLanding from '../../static/img/content-landing.svg';

const AppBuilderPage = props => {
  const { match, history } = props;
  const iframRef = React.createRef();

  const [isApproval, setIsApproval] = useState(false);
  const [assetSource, setAssetSource] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(true);
  const [updateUserData, setUpdateUserData] = useState(false);
  const [userData, setUserData] = useState('');
  const [filesLoaded, setFilesLoaded] = useState({
    html: false,
    js: true,
    css: true,
  });
  const [createAsset, setCreateAsset] = useState({
    html: false,
    js: false,
    css: false,
  });
  const [fileAssetId, setFileAssetId] = useState({});
  const [fileContent, setFileContent] = useState({
    htmlContent: '',
    cssContent: '',
    jsContent: '',
  });
  const [newFiles, setNewFiles] = useState({});

  //   html: { created: false },
  //   js: { created: false },
  //   css: { created: false },
  // });
  const [newHtml, setNewHtml] = useState({ created: false });
  const [newJs, setNewJs] = useState({ created: false });
  const [newCss, setNewCss] = useState({ created: false });
  const [versionDetails, setVersionDetails] = useState({});
  const [actvieComp, setActiveComp] = useState({});
  const [hasFirstComp, setHasFirstComp] = useState(false);
  const [createRequest, setCreateRequest] = useState(false);
  const [compTitle, setCompTitle] = useState('');
  const [newContent, setNewContent] = useState({
    htmlContent: '',
    cssContent: '',
    jsContent: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [createFilesRequest, setCreateFilesRequest] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (match.url.substring(match.url.lastIndexOf('/') + 1) === 'approval')
      setIsApproval(true);
  }, [match.url]);

  const handleAddCompByFooter = () => {
    setLoaded(false);
  };

  const handleGetDetailsError = () => {
    setLoaded(true);
  };

  const handleGetDetailsSuccess = data => {
    setVersionDetails(data);
    if (data.components.length > 0) {
      // setCurrentCompId(data.components[0].id);
      setActiveComp(data.components[0]);
      let tmpAssetId = {};
      data.components[0].files.map(file => {
        tmpAssetId = { ...tmpAssetId, [file.fileType]: file.locationId };
        return null;
      });
      setFileAssetId(tmpAssetId);
    }
    setAssetSource(
      data.logo
        ? `${getSignedUrl(data.logo.s3Key, data.logo.contentType)}`
        : 'https://dev.assets.ekardo.com/logo/logo-dark.svg'
    );
    setLoaded(true);
    setUserDataLoaded(false);
  };

  const handleUpdateUserData = () => {
    //   `USER_DATA_${versionDetails.app.title}_${actvieComp.title}`
    // );
    const tmpData = localStorage.getItem('USER_DATA');
    setUserData(tmpData);
    setUpdateUserData(true);
  };

  const handleGetUserDataError = () => {
    setUserDataLoaded(true);
  };

  const handleGetUserDataSuccess = data => {
    // We gonna use this new name of app data
    // localStorage.setItem(
    //   `USER_DATA_${versionDetails.app.title}_${actvieComp.title}`,
    //   data
    // );
    localStorage.setItem('USER_DATA', data);
    setUserDataLoaded(true);
  };

  const handleSaveUserDataError = () => {
    setUpdateUserData(false);
  };

  const handleSaveUserDataSuccess = () => {
    setUpdateUserData(false);
  };

  const getActiveCompId = id => {
    const tmp = versionDetails.components.filter(comp => comp.id === id);
    let tmpAssetId = {};
    tmp[0].files.map(file => {
      tmpAssetId = { ...tmpAssetId, [file.fileType]: file.locationId };
      return null;
    });
    setFileAssetId(tmpAssetId);
    setActiveComp(tmp[0]);
    setFilesLoaded({
      html: false,
      js: true,
      css: true,
    });
    setNewContent({});
    setUserDataLoaded(false);
  };

  const onFirstCompName = value => {
    setCompTitle(value);
  };

  const handleCreateFirstComp = () => {
    setCreateRequest(true);
  };

  const handleAddFirstComp = () => {
    setHasFirstComp(true);
  };

  const handleCreateComponentError = () => {
    setCreateRequest(false);
  };

  const handleCreateComponentSuccess = () => {
    setLoaded(false);
    setCreateRequest(false);
  };

  const handleInputs = e => {
    const { name, value } = e.target;
    setNewContent({
      ...newContent,
      [name]: value,
    });
  };

  const handleClearErrorMessages = () => {
    if (errorMessage !== '') {
      setErrorMessage('');
    }
  };

  const readTextFile = (file, type) => {
    const rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          const allText = rawFile.responseText;
          setFileContent({
            ...fileContent,
            [type]: allText,
          });
          setNewContent({
            ...fileContent,
            [type]: allText,
          });
        }
      }
    };
    rawFile.send(null);
  };

  const handleGetAssetError = () => {
    setFilesLoaded({
      html: true,
      js: true,
      css: true,
    });
  };

  const handleGetHtmlAssetSuccess = (s3Key, contentType) => {
    setFilesLoaded({
      ...filesLoaded,
      html: true,
      js: false,
    });
    readTextFile(getSignedUrl(s3Key, contentType), 'htmlContent');
  };

  const handleGetJsAssetSuccess = (s3Key, contentType) => {
    setFilesLoaded({
      ...filesLoaded,
      js: true,
      css: false,
    });
    readTextFile(getSignedUrl(s3Key, contentType), 'jsContent');
  };

  const handleGetCssAssetSuccess = (s3Key, contentType) => {
    setFilesLoaded({
      ...filesLoaded,
      css: true,
    });
    readTextFile(getSignedUrl(s3Key, contentType), 'cssContent');
  };

  const handleOnSaveComponent = () => {
    const fileHtml = new File([newContent.htmlContent], 'foo.html', {
      type: 'text/html',
    });
    const fileCss = new File([newContent.cssContent], 'foo.css', {
      type: 'text/css',
    });
    const fileJs = new File([newContent.jsContent], 'foo.js', {
      type: 'text/javascript',
    });
    setNewFiles({
      fileHtml,
      fileCss,
      fileJs,
    });
    setCreateAsset({
      html: true,
      js: false,
      css: false,
    });
  };

  const handleCreateAssetError = () => {
    setCreateAsset({
      html: true,
      js: true,
      css: true,
    });
  };

  const handleHtmlAssetSuccess = (signedUrl, asset) => {
    setCreateAsset({
      html: false,
      js: true,
      css: false,
    });
    setLoader(true);
    Object.defineProperty(newFiles.fileHtml, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, newFiles.fileHtml, { headers: {} })
      .then(() => {
        setNewHtml({
          created: true,
          assetId: asset.assetId,
        });
        setLoader(false);
      })
      .catch(() => {
        // this.setState({
        //   loader: false,
        // });
      });
  };

  const handleJsAssetSuccess = (signedUrl, asset) => {
    setCreateAsset({
      html: false,
      js: false,
      css: true,
    });
    setLoader(true);
    Object.defineProperty(newFiles.fileJs, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, newFiles.fileJs, { headers: {} })
      .then(() => {
        setNewJs({
          created: true,
          assetId: asset.assetId,
        });
        setLoader(false);
      })
      .catch(() => {
        // this.setState({
        //   loader: false,
        // });
      });
  };

  const handleCssAssetSuccess = (signedUrl, asset) => {
    setCreateAsset({
      html: false,
      js: false,
      css: false,
    });
    setLoader(true);
    Object.defineProperty(newFiles.fileCss, 'name', {
      writable: true,
      value: asset.s3Key,
    });

    Axios.put(signedUrl, newFiles.fileCss, { headers: {} })
      .then(() => {
        setNewCss({
          created: true,
          assetId: asset.assetId,
        });
        setLoader(false);
        setCreateFilesRequest(true);
      })
      .catch(() => {
        // this.setState({
        //   loader: false,
        // });
      });
  };

  const handleCreateFilesError = () => {
    setCreateFilesRequest(false);
  };

  const handleCreateFilesSuccess = () => {
    setCreateFilesRequest(false);
    setNewCss({ created: false });
    setNewJs({ created: false });
    setNewHtml({ created: false });
  };

  const firstCompOfApp = (
    <FadeIn>
      <LandingImage alt="add page" src={ContentLanding} />
      <LandingDetails>
        <h4>Add new component</h4>
        <p>
          An app is comprised of components. Click the plus button to create
          your first component.
        </p>
        {hasFirstComp ? (
          <AddFirstPage>
            <Input isChanged={onFirstCompName} />
            <PlusBtnWrapper1>
              <PlusBtn onClicked={handleCreateFirstComp} />
            </PlusBtnWrapper1>
          </AddFirstPage>
        ) : (
          <PlusBtnWrapper2>
            <PlusBtn onClicked={handleAddFirstComp} />
          </PlusBtnWrapper2>
        )}
      </LandingDetails>
    </FadeIn>
  );

  const breadPath = [
    {
      id: 1,
      path: '/marketplace',
      title: 'Marketplace',
    },

    {
      id: 2,
      path: '',
      title: 'New app',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Build a new app"
        pageDescription="Manage all your app components"
        pageTitle="Build app page"
        params={match.params}
      >
        {Object.entries(versionDetails).length !== 0 && (
          <ComponentNavBar
            defaultCompId={actvieComp.id}
            getActiveCompId={getActiveCompId}
            version={versionDetails}
          />
        )}
        <ReturnToApp appId={match.params.appId} />
        {Object.entries(versionDetails).length !== 0 &&
          (versionDetails.components.length !== 0 ? (
            <AppBuilder
              app={versionDetails.app}
              appVersionId={versionDetails.id}
              assetSource={assetSource}
              component={actvieComp}
              content={newContent}
              handleClearErrorMessages={handleClearErrorMessages}
              handleInputs={handleInputs}
              handleOnSaveComponent={handleOnSaveComponent}
              history={history}
              iframe={iframRef}
              isApproval={isApproval}
              onUpdateUserData={handleUpdateUserData}
              versionStatus={versionDetails.status}
            />
          ) : (
            firstCompOfApp
          ))}
        {Object.entries(versionDetails).length !== 0 &&
          versionDetails.status === 'DRAFT' && (
            <AppAdvanceFooter
              onAdded={handleAddCompByFooter}
              version={versionDetails}
            />
          )}
        {loader && <Preloader text="Uploading Files. Please wait!" />}
      </PageWrapper>
      {!loaded && (
        <GetVersionDetails
          onError={handleGetDetailsError}
          onSuccessResult={handleGetDetailsSuccess}
          versionId={Number(match.params.versionId)}
        />
      )}
      {!userDataLoaded && (
        <GetAppUserData
          appApiKey={versionDetails.app.apiKey}
          appComponentId={actvieComp.id}
          onError={handleGetUserDataError}
          onSuccessResult={handleGetUserDataSuccess}
        />
      )}
      {updateUserData && (
        <SaveAppUserData
          appApiKey={versionDetails.app.apiKey}
          appComponentId={actvieComp.id}
          content={userData}
          onError={handleSaveUserDataError}
          onSuccessResult={handleSaveUserDataSuccess}
        />
      )}
      {createRequest && (
        <CreateAppComponent
          componentTitle={compTitle}
          identifier={versionDetails.app.identifier}
          onError={handleCreateComponentError}
          onSuccessResult={handleCreateComponentSuccess}
          version={versionDetails.version}
        />
      )}

      {/* TODO: Update multi same queries */}

      {!filesLoaded.html && fileAssetId.HTML && (
        <GetAsset
          assetId={fileAssetId.HTML}
          onError={handleGetAssetError}
          onSuccessResult={handleGetHtmlAssetSuccess}
        />
      )}
      {!filesLoaded.js && fileAssetId.JAVASCRIPT && (
        <GetAsset
          assetId={fileAssetId.JAVASCRIPT}
          onError={handleGetAssetError}
          onSuccessResult={handleGetJsAssetSuccess}
        />
      )}
      {!filesLoaded.css && fileAssetId.CSS && (
        <GetAsset
          assetId={fileAssetId.CSS}
          onError={handleGetAssetError}
          onSuccessResult={handleGetCssAssetSuccess}
        />
      )}

      {createAsset.html && (
        <CreateAppAssetRequest
          appVersionId={versionDetails.id}
          contentType={newFiles.fileHtml.type}
          fileName={newFiles.fileHtml.name}
          fileSizeInBytes={newFiles.fileHtml.size}
          onError={handleCreateAssetError}
          onSuccessResult={handleHtmlAssetSuccess}
          type="DOCUMENT"
        />
      )}

      {createAsset.js && (
        <CreateAppAssetRequest
          appVersionId={versionDetails.id}
          contentType={newFiles.fileJs.type}
          fileName={newFiles.fileJs.name}
          fileSizeInBytes={newFiles.fileJs.size}
          onError={handleCreateAssetError}
          onSuccessResult={handleJsAssetSuccess}
          type="DOCUMENT"
        />
      )}

      {createAsset.css && (
        <CreateAppAssetRequest
          appVersionId={versionDetails.id}
          contentType={newFiles.fileCss.type}
          fileName={newFiles.fileCss.name}
          fileSizeInBytes={newFiles.fileCss.size}
          onError={handleCreateAssetError}
          onSuccessResult={handleCssAssetSuccess}
          type="DOCUMENT"
        />
      )}

      {createFilesRequest &&
        newHtml.created &&
        newJs.created &&
        newCss.created && (
          <CreateFilesIntoComponentRequest
            componentId={actvieComp.id}
            cssLocationId={newCss.assetId}
            htmlLocationId={newHtml.assetId}
            jsLocationId={newJs.assetId}
            onError={handleCreateFilesError}
            onSuccessResult={handleCreateFilesSuccess}
          />
        )}
    </>
  );
};

AppBuilderPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string,
      versionId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default AppBuilderPage;
