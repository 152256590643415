import kebabCase from 'lodash.kebabcase';

import { LETTERS } from './string.constants';

declare global {
  interface String {
    capitalize: () => string;
    contains: (string: string) => boolean;
    formatPossessive: () => string;
    toKebabCase: () => string;
  }
}

String.prototype.capitalize = function (): string {
  return `${this.substring(0, 1).toUpperCase()}${this.substring(1)}`;
};

String.prototype.contains = function (string: string): boolean {
  return this.toLowerCase().includes(string.toLowerCase());
};

String.prototype.formatPossessive = function (): string {
  const lastLetter = this.substring(this.length - 1).toLowerCase();
  const shouldIncludeTheLetterS = lastLetter !== LETTERS.s;

  return `${this}'${shouldIncludeTheLetterS ? LETTERS.s : ''}`;
};

String.prototype.toKebabCase = function (): string {
  return kebabCase(`${this}`);
};

export {};
