import enGBLocale from 'antd/es/date-picker/locale/en_GB';
import enUSLocale from 'antd/es/date-picker/locale/en_US';

const getAntdLocale = () => {
  const { locale: antdEnGBLocale } = enGBLocale.lang;
  const { locale: intlLocale } = Intl.DateTimeFormat().resolvedOptions();

  const antdEnGBLocaleParts = antdEnGBLocale.split('_');
  if (antdEnGBLocaleParts.length === 1) {
    return undefined;
  }

  const antdEnGBLocaleCode = antdEnGBLocaleParts[1].toUpperCase();

  const intlLocaleParts = intlLocale.split('-');
  const intlLocaleCode = intlLocaleParts.length > 0 ? intlLocaleParts[1]?.toUpperCase() : undefined;

  // TODO: Support more locales if needed

  switch (intlLocaleCode) {
    case antdEnGBLocaleCode: {
      return enGBLocale;
    }
    default: {
      return enUSLocale;
    }
  }
};

export { getAntdLocale };
