import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Edit = styled.button`
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  line-height: 1em;
  background-color: #e1e1e1;
  border-radius: 50%;
  border: none;
  padding: 0;
  right: 0;
  /* display: none; */
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #4bc2ca;
    color: white;
  }
`;

const EditButton = props => {
  const { isClicked, onClick } = props;
  function handleClick(...args) {
    onClick(...args);
    isClicked(...args);
  }
  return <Edit onClick={handleClick}>···</Edit>;
};

EditButton.propTypes = {
  isClicked: PropTypes.func,
  onClick: PropTypes.func,
};

EditButton.defaultProps = {
  isClicked: () => {},
  onClick: () => {},
};

export default EditButton;
