import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const RELATE_CONTENT_PAGE = gql`
  mutation RelateContentPage($fromContentPageId: Int!, $toContentPageId: Int!) {
    contentPage {
      toggleRelatedResource(
        fromContentPageId: $fromContentPageId
        toContentPageId: $toContentPageId
      )
    }
  }
`;

const relateContentPage = ({
  fromContentPageId,
  toContentPageId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={RELATE_CONTENT_PAGE}>
    {(RelateContentPage, { loading, error, data }) => {
      const variables = {
        fromContentPageId,
        toContentPageId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentPage.toggleRelatedResource);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={RelateContentPage}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

relateContentPage.propTypes = {
  fromContentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  toContentPageId: PropTypes.number.isRequired,
};

export default relateContentPage;
