import { Tr, Td } from 'react-super-responsive-table';
import styled from 'styled-components';

export const Button = styled.button`
  color: #fff;
  background-color: #00c4cc;
  border: 1px solid transparent;
  padding: 15px 35px;
  margin: 0;
  display: inline-block;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
`;
export const Cell = styled.div`
  display: flex;
  flex: 1;
  padding: 8px 8px 8px 15px;
  text-align: left;
`;

export const Row = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: inset 6px 0px 0px 0px rgba(0, 0, 0, 0);
  color: rgb(23, 43, 77);
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -1px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(235, 236, 240);
  border-image: initial;
  text-decoration: none;
  padding: 4px 8px 4px 15px;

  &:hover {
    background-color: #f7f7f7;
    box-shadow: inset 6px 0px 0px 0px #00c4cc;
  }
`;

export const EditBox = styled.div`
  display: none;
  position: relative;
  margin-bottom: 1.4em;
`;

export const EditTd = styled(Td)`
  /* display: none; */
  width: 3em;
  padding-right: 2em !important;
`;

export const OptBtn = styled.button`
  border: none;
  background: transparent;
  margin: 0 -1em;
  padding: 0 1em;
`;

export const VersionTr = styled(Tr)`
  &:hover {
    ${EditTd} {
      ${EditBox} {
        display: block;
      }
    }
  }
`;
