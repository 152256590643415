/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Range } from 'react-range';

import { RangeInputProps } from './RangeInput.types';

import './RangeInput.css';

const RangeInput = ({ maxValue, minValue, onChange, selectedValues = [], step = 1 }: RangeInputProps) => {
  return (
    <Range
      max={maxValue}
      min={minValue}
      renderMark={({ index }) => <span className="c-range-slider__mark">{index}</span>}
      renderThumb={({ props, value }) => (
        <div
          {...props}
          className="c-range-slider__thumb"
          style={{
            ...props.style,
            backgroundColor: '#999',
            height: '10px',
            width: '10px',
          }}
        >
          <div className="c-range-slider__value">{value / step}</div>
        </div>
      )}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          className="c-range-slider__track"
          style={{
            ...props.style,
            backgroundColor: '#ccc',
            display: 'flex',
            height: '3px',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {children}
        </div>
      )}
      step={step}
      values={selectedValues}
      onChange={(values) => onChange(values)}
      onFinalChange={(values) => onChange(values)}
    />
  );
};

export { RangeInput };
