import React, { useRef, useState } from 'react';

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { Client } from '@netfront/gelada-react-shared';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';

import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import { EXPORT_GROUPS_CONTACTS } from '../../../middleware/Export/exportGroupContacts';
import { downloadFileByUrl } from '../../../utils/utils';

const ExportGroupsContactsPage = () => {
  const { projectId } = useParams();

  const client = useRef(Client()).current;

  const [apiError, setApiError] = useState(undefined);
  const [sendExportGroupsQuery, { loading: isGeneratingReport }] = useLoggedGeladaLazyQuery({
    query: EXPORT_GROUPS_CONTACTS,
    options: {
      client,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { contentType, s3Key } = data.report.groupContacts;
        const signedUrl = getSignedUrl(s3Key, contentType);
        downloadFileByUrl(signedUrl);
      },
      onError: (error) => {
        setApiError(error);
      },
    },
  });

  const download = (fileType = 'EXCEL' | 'CSV') => {
    sendExportGroupsQuery({
      variables: {
        projectId,
        fileType,
        ianaTimeZone: 'Australia/Sydney',
      },
    });
  };

  const options = {
    apiError,
    downloadCSVCallback: () => download('CSV'),
    downloadExcelCallback: () => download('EXCEL'),
    filtering: {
      date: {},
    },
    flags: {
      isFormValid: true,
      preloader: {
        isGeneratingReport,
        isLoadingUserFlowSteps: false,
      },
    },
    infoBannerText: 'This page exports groups contacts',
  };

  return <ExportPage options={options} />;
};

export default ExportGroupsContactsPage;
