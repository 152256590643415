export enum EAccessTokenKeys {
  /** Audience */
  AUD = 'aud',
  /** Define if the user is part of a custom build */
  CUSTOM_BUILD = 'custom_build',
  /** Expiration (timespan) */
  EXPIRATION = 'exp',
  /** User id */
  USER_ID = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata',
  /** Define when the token has been issued */
  ISSUED_AT = 'iat',
  /** Define who issued the token */
  ISSUER = 'iss',
  /** Define when the token became valid */
  NOT_VALID_BEFORE = 'nbf',
  /** User permissions */
  PERMISSIONS = 'permissions',
  /** User role */
  ROLE = 'role',
  /** Services that the user has access to */
  SERVICES = 'services',
  /** User status */
  STATUS = 'status',
  /** Define the type of token */
  TOKEN_TYPE = 'token_type',
  /** Token version */
  VERSION = '_v',
}
