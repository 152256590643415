import { gql } from '@apollo/client';

const GET_USER_FLOWS = gql`
  query getUserFlows($filterWithConditions: Boolean, $projectId: String!) {
    userFlow {
      getUserFlows(filterWithConditions: $filterWithConditions, projectId: $projectId) {
        id
        url
        userFlowSteps {
          contentGroup {
            contentPages(status: [PUBLISHED, UNPUBLISHED], topLevelOnly: true) {
              ...userFlowStepContentPage
            }
            description
            groupingContentGroup {
              description
              id
              name
              url
            }
            id
            title
          }
          description
          id
          isComplete
          lastViewedContentPage {
            ...userFlowStepContentPage
          }
          percentageCompleted
          sort
          stepName
          url
          userStatus
        }
      }
    }
  }

  fragment userFlowStepContentPage on ContentPageType {
    hasBeenViewed
    id
    sort
    title
    url
  }
`;

export { GET_USER_FLOWS };
