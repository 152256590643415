import React from 'react';

import { PropTypes } from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 19998;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const spinAnimation = keyframes`
  to { transform: rotate(360deg); }
`;

export const Spinner = styled.span`
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${spinAnimation};
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: ${props =>
    props.borderColor ? props.borderColor : 'rgba(255, 255, 255, 0.8)'};
  display: inline-block;
  height: 2rem;
  width: 2rem;
`;

const Preloader = props => {
  const { text } = props;

  return (
    <Container>
      <Row>
        <Spinner />
      </Row>
      <Row>{text && <strong>{text}</strong>}</Row>
    </Container>
  );
};

Preloader.propTypes = {
  text: PropTypes.string,
};

Preloader.defaultProps = {
  text: '',
};

export default Preloader;
