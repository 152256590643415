import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import styled from 'styled-components';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { versionStatus } from './versionStatus';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import Footer from '../../components/ConsoleFooter/Footer';
import ConsoleMaster from '../../components/ConsoleMaster/ConsoleMaster';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import PopupMessageSideBar from '../../components/PopupMessageSideBar/PopupMessageSideBar';
import SideBarRight from '../../components/SideBarRight/SideBarRight';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import { getAssetsUrl, errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import AppContext from '../../context/AppContext';
import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import CreateAppVersionRequest from '../../middleware/MyAppDashboard/createAppVersionRequest';
import GetMyAppVersionList from '../../middleware/MyAppDashboard/getMyAppVersionList';
import PublishAppVersionRequest from '../../middleware/MyAppDashboard/publishAppVersionRequest';
import SubmitAppForApprovalRequest from '../../middleware/MyAppDashboard/submitAppForApprovalRequest';
import GetOrganisation from '../../middleware/Organisation/getOrganisation';
import GetProject from '../../middleware/Project/getProject';
import { dateUtil } from '../../utils/utils';

const VersionHeader = styled.div`
  background: #fafdfe;
  border: 1px solid #e7eef1;
  border-radius: 6px 6px 0 0;
  display: flex;
  font-size: 0.6em;
  margin-top: 30px;

  @media (min-width: 768px) {
    font-size: 1em;
  }

  span {
    flex: 1;
    padding: 1em;
  }
`;

//   border: 1px solid #e1e1e1;
//   border-radius: 6px;
// `;

const VersionItem = styled.tr`
  background-color: ${({ active }) => (active ? '#F4F4F4' : '#fff')};
  border: 1px solid;
  border-color: #e1e1e1;
  border-left: 5px solid;
  border-left-color: ${({ active }) => (active ? '#00c4cc' : '#fff')};
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s ease-in-out;

  td {
    padding: 0.2em 1.2em;
  }

  &:hover {
    background-color: #f4f4f4;
    border-left-color: #00c4cc;
  }
`;

class VersionListPage extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      organisationId: null,
      projectId: null,
      projectLoaded: true,
      orgLoaded: true,
      getAppVesrionsList: false,
      versionList: [],
      isRightOpen: false,
      appVersion: '',
      errorMessage: '',
      appVersionIdentifier: '',
      appType: '',
      createAppVersion: false,
      appTitle: '',
      getAssetSent: false,
      assetId: '',
      fileUrl: '',
      submitForApprovalRequest: false,
      publishAppVersionRequest: false,
      appVersionId: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { org: orgId, projectId } = params;
    const { orgName, projectName } = this.context;

    this.setState({
      organisationId: Number(orgId),
      projectId,
      orgLoaded: orgName !== '',
      projectLoaded: projectName !== '',
    });
  }

  validate = () => {
    const { appVersion, appType } = this.state;

    return {
      AppVersionEmpty: {
        validation: appVersion.trim().length > 0,
        errorMessage: 'App version must not be empty',
      },
      AppTypeEmpty: {
        validation: appType.trim().length > 0,
        errorMessage: 'App type must not be empty',
      },
    };
  };

  handleOrgError = () => {
    this.setState({
      orgLoaded: true,
    });
  };

  handleProjectError = () => {
    this.setState({
      projectLoaded: true,
    });
  };

  handleGetOrganisation = (name) => {
    const { updateOrgName } = this.context;
    updateOrgName(name);
    this.setState({
      orgLoaded: true,
    });
  };

  handleGetProject = (name) => {
    const { updateProjectName } = this.context;

    updateProjectName(name);

    this.setState({
      projectLoaded: true,
    });
  };

  handleGetVersionListError = () => {
    this.setState({
      getAppVesrionsList: true,
    });
  };

  handleGetVersionListPage = (data) => {
    this.setState({
      getAppVesrionsList: true,
      versionList: data,
      appTitle: data.title,
      assetId: data.logoId,
      getAssetSent: true,
      appVersionIdentifier: data.identifier,
    });
  };

  renderStatusComponent = (status, publishStatus) => (
    <div>
      <span
        className={classnames(
          'c-tag',
          {
            'c-tag-danger': status.toUpperCase() === versionStatus.REJECTED,
          },
          {
            'c-tag-success': status.toUpperCase() === versionStatus.ACCEPTED,
          },
          {
            'c-tag-info': status.toUpperCase() === versionStatus.DRAFT,
          },
          {
            'c-tag-info': status.toUpperCase() === versionStatus.PENDING,
          },
        )}
      >
        {status}
      </span>
      {publishStatus === 'PUBLISHED' && <span className="c-tag c-tag-info ml-1">{publishStatus}</span>}
    </div>
  );

  handleToggleRightSideBar = () => {
    const { isRightOpen } = this.state;
    this.setState({
      isRightOpen: !isRightOpen,
    });
  };

  onHandleToggleRightSideBar = () => {
    this.handleToggleRightSideBar();
  };

  handleInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleClearErrorMessages = () => {
    const { errorMessage } = this.state;
    if (errorMessage !== '') {
      this.setState({
        errorMessage: '',
      });
    }
  };

  handleOnSubmit = () => {
    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
      setTimeout(() => {
        this.setState({
          errorMessage: '',
        });
      }, 2000);
    } else {
      this.setState({
        // createMyAppRequest: true,
        createAppVersion: true,
      });
    }
  };

  handleCreateAppVersionResponse = () => {
    const { isRightOpen } = this.state;
    this.setState({
      createAppVersion: false,
      getAppVesrionsList: false,
      isRightOpen: !isRightOpen,
      appVersion: '',
      appType: '',
    });
  };

  handleCreateAppVersionError = (errorMessage) => {
    this.setState({
      createAppVersion: false,
      errorMessage,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleAssetError = () => {
    this.setState({
      getAssetSent: false,
    });
  };

  handleAssetResponse = (s3Key, contentType) => {
    this.setState({
      getAssetSent: false,
      fileUrl: getSignedUrl(s3Key, contentType),
    });
  };

  handleOnClickEditComponent = (id) => {
    const {
      history,
      match: { url },
    } = this.props;
    history.push(`${url}/${id}`);
  };

  handleOnClickSubmitForApproval = (version) => {
    this.setState({
      appVersion: version,
      submitForApprovalRequest: true,
    });
  };

  handleSubmitForApprovalError = (message) => {
    this.setState({
      appVersion: '',
      getAppVesrionsList: false,
      submitForApprovalRequest: false,
    });

    this.setState({
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleSubmitForApprovalResponse = () => {
    this.setState({
      appVersion: '',
      getAppVesrionsList: false,
      submitForApprovalRequest: false,
    });
  };

  handleOnClickPublishApp = (id) => {
    this.setState({
      appVersionId: id,
      publishAppVersionRequest: true,
    });
  };

  handlePublishAppError = (message) => {
    this.setState({
      appVersionId: '',
      getAppVesrionsList: false,
      publishAppVersionRequest: false,
    });

    this.setState({
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handlePublishAppResponse = () => {
    this.setState({
      appVersionId: '',
      getAppVesrionsList: false,
      publishAppVersionRequest: false,
    });
  };

  render() {
    const {
      organisationId,
      projectId,
      projectLoaded,
      orgLoaded,
      getAppVesrionsList,
      versionList,
      isRightOpen,
      appVersion,
      appVersionIdentifier,
      appType,
      createAppVersion,
      errorMessage,
      appTitle,
      assetId,
      getAssetSent,
      fileUrl,
      submitForApprovalRequest,
      publishAppVersionRequest,
      appVersionId,
    } = this.state;

    const {
      match: {
        params: { appId },
      },
    } = this.props;

    const { projectName } = this.context;
    return (
      <>
        <ConsoleMaster path={this.props}>
          <ConsoleContainer styles={{ marginTop: '1em' }}>
            <Breadcrumb
              avatar={appTitle}
              breads={[
                { id: 1, path: '/dashboard', title: 'Dashboard' },
                {
                  id: 2,
                  path: `/dashboard/${organisationId}/project/${projectId}`,
                  title: projectName,
                },
                {
                  id: 3,
                  path: `/dashboard/${organisationId}/project/${projectId}/myApps`,
                  title: 'My App',
                },
                { id: 4, path: '', title: appTitle },
              ]}
              title={appTitle}
            />
            <div className="d-flex mb-3">
              <div className="mr-3">
                <img alt="img" className="rounded" height="90px" src={fileUrl} width="90px" />
              </div>
              <div className="">
                <h5 className="m-0">
                  <strong>{appTitle}</strong>
                </h5>
                <small>by Nefront</small>
              </div>
            </div>
            <div className="mb-3 text-right">
              <button className="button--blue" type="button" onClick={this.handleToggleRightSideBar}>
                <span className="icon-plus is-filled" /> Create new version
              </button>
            </div>
            {versionList.versions && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Publish date</Th>
                    <Th>Status</Th>
                    <Th>Current Users</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {versionList.versions.map((version) => (
                    <VersionItem>
                      <Td>{version.version}</Td>
                      <Td>{dateUtil(version.uploadedDateTime)}</Td>
                      <Td>{this.renderStatusComponent(version.status, version.publishStatus)}</Td>
                      <Td>{versionList.users.length}</Td>
                    </VersionItem>
                  ))}
                </Tbody>
              </Table>
            )}

            <VersionHeader>
              <span>NAME</span>
              <span>PUBLISH DATE</span>
              <span>STATUS</span>
              <span>CURRENT USERS</span>
              <span> </span>
            </VersionHeader>

            {/* <VersionListContainer>
              <VersionList>
                {versionList.versions && (
                  versionList.versions.map(item => (
                    <VersionItem>Puppo</VersionItem>
                  ))
                )}
              </VersionList>
            </VersionListContainer> */}

            <ul className="c-card-list mb-3">
              {versionList.versions &&
                versionList.versions.length &&
                versionList.versions.map((version) => (
                  <li key={version.id} className="child-list px-0">
                    <div className="col-md-2">
                      <strong>{version.version}</strong>
                    </div>
                    <div className="col-md-2">{dateUtil(version.uploadedDateTime)}</div>
                    <div className="col-md-3">{this.renderStatusComponent(version.status, version.publishStatus)}</div>
                    <div className="col-md-2">{versionList.users.length}</div>
                    <div className="col-md-2">
                      <div className="dropdown">
                        <button className="button-none mx-auto menu-icon-container d-flex justify-content-center" type="button">
                          <img
                            alt="edit"
                            aria-expanded="false"
                            aria-haspopup="true"
                            className="content-edit-icon"
                            data-toggle="dropdown"
                            id="dropdownMenuButton"
                            src={`${getAssetsUrl()}/icons/button-of-three-vertical-squares.svg`}
                          />
                        </button>
                        <div aria-labelledby="dropdownMenuButton" className="dropdown-menu">
                          {version.status.toLowerCase() === 'accepted' && version.publishStatus.toLowerCase() === 'unpublished' && (
                            <button className="dropdown-item" type="button" onClick={this.handleOnClickPublishApp.bind(this, version.id)}>
                              Publish
                            </button>
                          )}
                          {version.status.toLowerCase() === 'draft' && (
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={this.handleOnClickSubmitForApproval.bind(this, version.version)}
                            >
                              Submit for Approval
                            </button>
                          )}
                          <button className="dropdown-item" type="button" onClick={this.handleOnClickEditComponent.bind(this, version.id)}>
                            Edit Component
                          </button>
                          {version.status.toLowerCase() === 'draft' && (
                            <button className="dropdown-item" type="button">
                              Delete
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </ConsoleContainer>
          <SideBarRight open={isRightOpen}>
            <button
              className="c-sidebar-right__close"
              type="button"
              onClick={this.handleToggleRightSideBar}
              onKeyDown={this.handleToggleRightSideBar}
            >
              <span className="icon-plus" />
            </button>
            <strong className="c-sidebar-right-title text-bold">Create a Version</strong>

            <label className="text-left mt-2 " htmlFor="name">
              <strong>Version</strong>
              <br />
              <input
                aria-label="Version"
                className="w-100 bg-transparent p-2 border"
                name="appVersion"
                required=""
                value={appVersion}
                onChange={this.handleInputs}
                onClick={this.handleClearErrorMessages}
              />
            </label>

            <label className="text-left mt-2 " htmlFor="name">
              <strong>Type</strong>
              <br />
              <input
                aria-label="Type"
                className="w-100 bg-transparent p-2 border"
                name="appType"
                required=""
                value={appType}
                onChange={this.handleInputs}
                onClick={this.handleClearErrorMessages}
              />
            </label>
            <div className="text-center">
              <button className="button--black mt-4 mr-3" type="button" onClick={this.handleToggleRightSideBar}>
                Cancel
              </button>
              <button className="mt-4  button--blue" type="submit" onClick={this.handleOnSubmit}>
                Save
              </button>
            </div>
          </SideBarRight>
          {isRightOpen && <Backdrop isClicked={this.onHandleToggleRightSideBar} />}
        </ConsoleMaster>
        <Footer />
        {!orgLoaded && <GetOrganisation id={organisationId} onError={this.handleOrgError} onSuccessResult={this.handleGetOrganisation} />}
        {!projectLoaded && <GetProject id={projectId} onError={this.handleProjectError} onSuccessResult={this.handleGetProject} />}
        {!getAppVesrionsList && (
          <GetMyAppVersionList
            appId={Number(appId)}
            onError={this.handleGetVersionListError}
            onSuccessResult={this.handleGetVersionListPage}
          />
        )}
        {createAppVersion && (
          <CreateAppVersionRequest
            identifier={appVersionIdentifier}
            type={appType}
            version={appVersion}
            onError={this.handleCreateAppVersionError}
            onSuccessResult={this.handleCreateAppVersionResponse}
          />
        )}
        {getAssetSent && <GetAsset assetId={assetId} onError={this.handleAssetError} onSuccessResult={this.handleAssetResponse} />}
        {submitForApprovalRequest && (
          <SubmitAppForApprovalRequest
            identifier={appVersionIdentifier}
            version={appVersion}
            onError={this.handleSubmitForApprovalError}
            onSuccessResult={this.handleSubmitForApprovalResponse}
          />
        )}

        {publishAppVersionRequest && (
          <PublishAppVersionRequest
            appVersionId={appVersionId}
            onError={this.handlePublishAppError}
            onSuccessResult={this.handlePublishAppResponse}
          />
        )}
        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        </ReactCSSTransitionGroup>
      </>
    );
  }
}

VersionListPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: { appId: PropTypes.string.isRequired },
  }).isRequired,
};

export default VersionListPage;
