import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../../ErrorModal';
import MutationRequest from '../../MutationRequest';

export const CREATE_RADIO_RESPONSE_ANS_REQUEST = gql`
  mutation createRadioResponsesAnsRequest(
    $responseSetId: Int!
    $responsesRadio: [QuestionResponseRadioInputType]
  ) {
    questionSet {
      addAvailableAnswers(
        responseSetId: $responseSetId
        responsesRadio: $responsesRadio
      ) {
        id
      }
    }
  }
`;

const createRadioResponsesAnsRequest = ({
  responseSetId,
  responsesRadio,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_RADIO_RESPONSE_ANS_REQUEST}>
    {(createRadioResponseAns, { loading, error, data }) => {
      const variables = {
        responseSetId,
        responsesRadio,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createRadioResponseAns}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createRadioResponsesAnsRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  responseSetId: PropTypes.number.isRequired,
  responsesRadio: PropTypes.instanceOf(Array).isRequired,
};

createRadioResponsesAnsRequest.defaultProps = {};

export default createRadioResponsesAnsRequest;
