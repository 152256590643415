import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import { DELETE_LICENCE, UPDATE_LICENCE_EXPIRY_DATE } from './LicencedUserDetails.gql';

import { EKSingleDatePicker } from '../../../components/DatePickers/EKSingleDatePicker/EKSingleDatePicker';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import Preloader from '../../../components/Preloader/Preloader';
import Buttons from '../../../components/SideBar/Buttons/Buttons';
import vervetClient from '../../../middleware/vervetClient';

import './LicencedUserDetails.css';

const LicencedUserDetails = ({ licence, onCloseHandler, onDeleteHandler, onSaveHandler }) => {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [selectedLicence, setSelectedLicence] = useState(licence);

  const [deleteLicence, { loading: isDeleteLicenceLoading }] = useMutation(DELETE_LICENCE, {
    client: vervetClient,
    onCompleted: () => {
      onDeleteHandler(selectedLicence.id);
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  const [updateLicenceExpiryDate, { loading: isUpdateLicenceExpiryDateLoading }] = useMutation(UPDATE_LICENCE_EXPIRY_DATE, {
    client: vervetClient,
    onCompleted: (data) => {
      const {
        licences: {
          updateExpiryDate: { expiresAtUtc, id },
        },
      } = data;

      onSaveHandler(expiresAtUtc, id);
    },
    onError: (error) => {
      setErrorMessage(error.message);
    },
  });

  const handleClose = () => {
    onCloseHandler();
  };

  const handleDelete = () => {
    const { id: licenceId } = selectedLicence;

    deleteLicence({
      variables: {
        licenceId,
      },
    });
  };

  const handleSave = () => {
    const { id: licenceId, selectedDate } = selectedLicence;

    if (!selectedDate) {
      setErrorMessage('Expiry date is required');
      return;
    }

    updateLicenceExpiryDate({
      variables: {
        expiresAtUtc: selectedDate,
        licenceId,
      },
    });
  };

  const handleExpiryDateUpdate = (selectedDate) => {
    setSelectedLicence((previous) => ({
      ...previous,
      selectedDate,
    }));
  };

  const isLoading = isDeleteLicenceLoading || isUpdateLicenceExpiryDateLoading;

  const { email, expiryDate, fullName } = selectedLicence;

  return (
    <>
      {isLoading && <Preloader />}
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div className="c-form__container">
        <div className="c-form-item__container">
          <div className="c-form-input__label">Full name</div>
          <div>{fullName}</div>
        </div>
        <div className="c-form-item__container">
          <div className="c-form-input__label">Email</div>
          <div>{email}</div>
        </div>
        <div className="c-form-item__container">
          <div className="c-form-input__label">Expiry date</div>
          <div>
            <EKSingleDatePicker minDate={new Date()} selectedDate={expiryDate} onChangeHandler={handleExpiryDateUpdate} />
          </div>
        </div>
      </div>
      <Buttons onClose={handleClose} onDelete={handleDelete} onSave={handleSave} />
    </>
  );
};

LicencedUserDetails.propTypes = {
  licence: PropTypes.shape({
    expiryDate: PropTypes.instanceOf(Date).isRequired,
    fullName: PropTypes.string.isRequired,
  }).isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func.isRequired,
};

export { LicencedUserDetails };
