export const allPlans = {
  products: [
    {
      productId: 1,
      stripeProductId: 'TextToSpeech',
      active: true,
      created: '2019-05-20T15:47:52.4405825',
      deleted: false,
      description: 'Text to speech',
      type: 'service',
      updated: '2019-05-20T15:47:52.440675',
      plans: [
        {
          planId: 1,
          productId: 1,
          stripePlanId: 'TextToSpeech_Monthly',
          stripePlan: 20000,
          description: 'Text to speech 20 thousand',
          price: 0,
          active: true,
          created: '2019-05-20T15:47:53.1713376',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 2,
          productId: 1,
          stripePlanId: 'TextToSpeech_Monthly',
          stripePlan: 1000000,
          description: 'Text to speech 1 million',
          price: 10,
          active: true,
          created: '2019-05-20T15:47:53.1917919',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 3,
          productId: 1,
          stripePlanId: 'TextToSpeech_Monthly',
          stripePlan: 2000000,
          description: 'Text to speech 2 million',
          price: 20,
          active: true,
          created: '2019-05-20T15:47:53.191875',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 4,
          productId: 1,
          stripePlanId: 'TextToSpeech_Monthly',
          stripePlan: 5000000,
          description: 'Text to speech 5 million',
          price: 45,
          active: true,
          created: '2019-05-20T15:47:53.1918992',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 5,
          productId: 1,
          stripePlanId: 'TextToSpeech_Monthly',
          stripePlan: 10000000,
          description: 'Text to speech 10 million',
          price: 85,
          active: true,
          created: '2019-05-20T15:47:53.1919208',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 6,
          productId: 1,
          stripePlanId: 'TextToSpeech_Monthly',
          stripePlan: 0,
          description: 'Text to speech unlimited',
          price: 145,
          active: true,
          created: '2019-05-20T15:47:53.1919454',
          deleted: null,
          product: null,
          projects: [],
        },
      ],
    },
    {
      productId: 2,
      stripeProductId: 'TextToTranslate',
      active: true,
      created: '2019-05-20T15:47:52.7647222',
      deleted: false,
      description: 'Text to translate',
      type: 'service',
      updated: '2019-05-20T15:47:52.7647249',
      plans: [
        {
          planId: 7,
          productId: 2,
          stripePlanId: 'TextToTranslate_Monthly',
          stripePlan: 20000,
          description: 'Text to translate 20 thousands',
          price: 0,
          active: true,
          created: '2019-05-20T15:47:53.1919638',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 8,
          productId: 2,
          stripePlanId: 'TextToTranslate_Monthly',
          stripePlan: 1000000,
          description: 'Text to translate 1 million',
          price: 50,
          active: true,
          created: '2019-05-20T15:47:53.1919901',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 9,
          productId: 2,
          stripePlanId: 'TextToTranslate_Monthly',
          stripePlan: 2000000,
          description: 'Text to translate 2 millions',
          price: 95,
          active: true,
          created: '2019-05-20T15:47:53.1920176',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 10,
          productId: 2,
          stripePlanId: 'TextToTranslate_Monthly',
          stripePlan: 5000000,
          description: 'Text to translate 5 millions',
          price: 225,
          active: true,
          created: '2019-05-20T15:47:53.1920389',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 11,
          productId: 2,
          stripePlanId: 'TextToTranslate_Monthly',
          stripePlan: 10000000,
          description: 'Text to translate 10 millions',
          price: 425,
          active: true,
          created: '2019-05-20T15:47:53.1920581',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 12,
          productId: 2,
          stripePlanId: 'TextToTranslate_Monthly',
          stripePlan: 0,
          description: 'Text to translate unlimited',
          price: 735,
          active: true,
          created: '2019-05-20T15:47:53.1920809',
          deleted: null,
          product: null,
          projects: [],
        },
      ],
    },
    {
      productId: 3,
      stripeProductId: 'TextToSpeechAndTranslate',
      active: true,
      created: '2019-05-20T15:47:53.0194593',
      deleted: false,
      description: 'Text to speech & translate',
      type: 'service',
      updated: '2019-05-20T15:47:53.0194618',
      plans: [
        {
          planId: 13,
          productId: 3,
          stripePlanId: 'TextToSpeechAndTranslate_Monthly',
          stripePlan: 20000,
          description: 'Text to speech and translate 20 thousands',
          price: 0,
          active: true,
          created: '2019-05-20T15:47:53.1921034',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 14,
          productId: 3,
          stripePlanId: 'TextToSpeechAndTranslate_Monthly',
          stripePlan: 1000000,
          description: 'Text to speech and translate 1 million',
          price: 55,
          active: true,
          created: '2019-05-20T15:47:53.1921226',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 15,
          productId: 3,
          stripePlanId: 'TextToSpeechAndTranslate_Monthly',
          stripePlan: 2000000,
          description: 'Text to speech and translate 2 million',
          price: 105,
          active: true,
          created: '2019-05-20T15:47:53.1921538',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 16,
          productId: 3,
          stripePlanId: 'TextToSpeechAndTranslate_Monthly',
          stripePlan: 5000000,
          description: 'Text to speech and translate 5 million',
          price: 245,
          active: true,
          created: '2019-05-20T15:47:53.1922049',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 17,
          productId: 3,
          stripePlanId: 'TextToSpeechAndTranslate_Monthly',
          stripePlan: 10000000,
          description: 'Text to speech and translate 10 million',
          price: 455,
          active: true,
          created: '2019-05-20T15:47:53.1922241',
          deleted: null,
          product: null,
          projects: [],
        },
        {
          planId: 18,
          productId: 3,
          stripePlanId: 'TextToSpeechAndTranslate_Monthly',
          stripePlan: 0,
          description: 'Text to speech and translate unlimited',
          price: 785,
          active: true,
          created: '2019-05-20T15:47:53.192257',
          deleted: null,
          product: null,
          projects: [],
        },
      ],
    },
  ],
};
