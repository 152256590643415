import React, { Component } from 'react';

class Footer extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="footer">
        {/* <div className="footer__container">
          <ul className="footer__links">
            <li>
              <Link onClick={onClick} to="/">
                features
              </Link>
            </li>
            <li>
              {' '}
              <Link to="/contact">contact</Link>
            </li>
          </ul>

          <div className="footer__logo">
            <img
              alt="console"
              src="https://dev.assets.ekardo.com/logo/logo-dark.svg"
              srcSet=""
            />
          </div>
          <div className="footer__business">
            <div className="footer__social d-flex justify-content-end mb-2">
              <div className="mr-2">
                <img alt="fbLogin" height="24px" src={fbRed} width="24px" />
              </div>
              <div className="">
                <img
                  alt="twiterLogin"
                  height="24px"
                  src={twitterRed}
                  width="24px"
                />
              </div>
            </div>

            <ul className="footer__details">
              <li>
                <Link to="/privacy">privacy</Link>
              </li>
              <li>
                <Link to="/terms">terms & conditions</Link>
              </li>
              <li>&#169; Ekardo 2019</li>
            </ul>
          </div>
        </div> */}
      </div>
    );
  }
}

// Footer.propTypes = {
//   onClick: PropTypes.func,
// };

// Footer.defaultProps = {
//   onClick: () => {},
// };

export default Footer;
