import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import GeladaClient from '../_Gelada/geladaClient';
import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const ACCEPT_INVITATION_USER_CONNECTED_REQUEST = gql`
  mutation ($tokenId: String!) {
    invitation {
      acceptInvitation(tokenId: $tokenId)
    }
  }
`;
const cookies = new Cookies();

const acceptInvitationRequestConnectedUser = ({ tokenId, onError, accessToken, refreshToken, type, organisationId, projectGuid }) => (
  <Mutation client={GeladaClient(true, accessToken)} mutation={ACCEPT_INVITATION_USER_CONNECTED_REQUEST}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        tokenId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        cookies.remove('_a', { path: '/' });
        cookies.remove('REFRESH_TOKEN', { path: '/' });
        cookies.set('_a', accessToken, {
          path: '/',
        });
        cookies.set('REFRESH_TOKEN', refreshToken, {
          path: '/',
        });

        if (type === 'PROJECT') {
          const redirect = `/dashboard/${organisationId}/${projectGuid}`;
          return <Redirect to={redirect} />;
        }
        return <Redirect to="/dashboard" />;
      }

      return <MutationRequest isLoading={loading} mutation={createAppVersion} variables={variables} />;
    }}
  </Mutation>
);

acceptInvitationRequestConnectedUser.propTypes = {
  accessToken: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  organisationId: PropTypes.number.isRequired,
  projectGuid: PropTypes.string.isRequired,
  refreshToken: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

acceptInvitationRequestConnectedUser.defaultProps = {};

export default acceptInvitationRequestConnectedUser;
