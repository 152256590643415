import React from 'react';

import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import tooltipIcon from '../../../static/img/tooltip-icon.svg';
import ImageIcon from '../../Icon/ImageIcon';

export const Question = styled.span`
  align-items: center;
  border: none;
  border-radius: 100%;
  display: flex;
  height: 1.9rem;
  justify-content: center;
  width: 1.9rem;
  cursor: help;
`;

export const WithTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#303941',
    fontSize: '0.8rem',
  },
}));

const Tooltips = props => {
  const { customIcon, text, triggerElement, width } = props;

  return (
    <Tooltip classes={useStyles()} placement="top" title={text} TransitionComponent={Grow}>
      {triggerElement() || (
        <Question aria-hidden="true">
          <ImageIcon width={width} src={customIcon || tooltipIcon} />
        </Question>
      )}
    </Tooltip>
  );
};

Tooltips.propTypes = {
  customIcon: PropTypes.string,
  text: PropTypes.string,
  triggerElement: PropTypes.func,
  width: PropTypes.number,
};

Tooltips.defaultProps = {
  text: 'Tooltip text',
  triggerElement: () => {},
};

export default Tooltips;
