import { gql } from '@apollo/client';

export const ADD_FEEDBACK_RANGE_MULTI_RESPONSE_TEXT_MUTATION = gql`
  mutation addFeedbackRangeMultiResponseText($answerId: Int!, $responseId: Int!, $summaryId: Int!, $value: Int!) {
    summaryFeedback {
      addFeedbackRangeMultiResponseText(
        contentSnippetSummaryId: $summaryId
        questionAnswerId: $answerId
        responseId: $responseId
        value: $value
      )
    }
  }
`;
