import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

import { ContentStatusEnum } from '../../utils/utils';

export const UPDATE_CONTENT_GROUP_STATUS = gql`
  mutation UpdateContentGroup(
    $contentGroupId: Int!
    $status: EContentGroupStatus!
  ) {
    contentGroup {
      updateContentGroupStatus(id: $contentGroupId, status: $status) {
        id
      }
    }
  }
`;

const updateContentGroupStatusRequest = ({
  contentGroupId,
  status,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_CONTENT_GROUP_STATUS}>
    {(updateContentGroupStatus, { loading, error, data }) => {
      let contentGroupStatus;
      if (status === 0) {
        contentGroupStatus = ContentStatusEnum.UNPUBLISHED;
      } else if (status === 1) {
        contentGroupStatus = ContentStatusEnum.PUBLISHED;
      } else {
        contentGroupStatus = ContentStatusEnum.DELETED;
      }

      const variables = {
        contentGroupId,
        status: contentGroupStatus,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentGroup.updateContentGroupStatus.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentGroupStatus}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateContentGroupStatusRequest.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default updateContentGroupStatusRequest;
