import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import {
  AdvanceItem,
  AdvanceTitle,
  SquareButton,
  Icon,
  TranscriptList,
  TranscriptListItem,
  CircleButton,
  Title,
  TextItem,
  ButtonContainer,
} from '../styled';

import client from '../../../middleware/client';
import addIcon from '../../../static/img/add-icon.svg';
import arrowIcon from '../../../static/img/icon-chevron.svg';
import { getApiErrorMessages } from '../../../utils/utils';
import NotificationMessage from '../../UI/NotificationMessage/NotificationMessage';

export const SORT_BUBBLE_TEXTS = gql`
  mutation sortBubbleTexts($bubbleTextId: Int!, $sort: Int!) {
    bubbleText {
      sort(bubbleTextId: $bubbleTextId, sort: $sort) {
        id
      }
    }
  }
`;

const AddImg = styled.img`
  position: relative;
  right: 0.25rem;
  bottom: 0.25rem;
`;

const TranscriptListTab = ({ transcriptList = [], handleEditTranscript, handleCreateTranscript }) => {
  const [hoverEffect, setHoverEffect] = useState(false);
  const [listItems, setListItems] = useState(transcriptList);
  const [notificationType, setNotificationType] = useState(undefined);
  const [notificationAlerts, setNotificationAlerts] = useState([]);

  const [executeSortBubbleTexts] = useMutation(SORT_BUBBLE_TEXTS, {
    client,
    onCompleted(data) {
      const {
        bubbleText: {
          sort: { id },
        },
      } = data;
    },
    onError(error) {
      const errorMessage = getApiErrorMessages(error);
      setNotificationType('error');
      setNotificationAlerts(errorMessage);
    },
  });

  function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }

    executeSortBubbleTexts({
      variables: {
        bubbleTextId: Number(result.draggableId),
        sort: result.destination.index,
      },
    });

    const items = [...listItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setListItems(items);
  }

  const handleHideNotification = () => {
    setNotificationAlerts('');
  };

  return (
    <>
      <AdvanceItem>
        <AdvanceTitle padding="0 0.9rem ">
          <Title>Transcript</Title>
          <SquareButton height="1.9rem" type="button" width="1.9rem" onClick={handleCreateTranscript}>
            <Icon title="add" width="1.9rem">
              <AddImg alt="Add new transcript" src={addIcon} />
            </Icon>
          </SquareButton>
        </AdvanceTitle>
      </AdvanceItem>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(providedList) => (
            <TranscriptList className="characters" {...providedList.droppableProps} ref={providedList.innerRef}>
              {listItems.map(({ id, text }, index) => (
                <Draggable key={id} draggableId={String(id)} index={index}>
                  {(providedListItem) => (
                    <TranscriptListItem
                      ref={providedListItem.innerRef}
                      {...providedListItem.draggableProps}
                      {...providedListItem.dragHandleProps}
                      border={hoverEffect ? '0.06rem solid #e1e1e1' : 'none'}
                      onMouseLeave={() => setHoverEffect(false)}
                      onMouseOver={() => setHoverEffect(true)}
                    >
                      <TextItem> {text}</TextItem>
                      <ButtonContainer>
                        <CircleButton
                          color="white"
                          height="1.25rem"
                          transform="rotate(270deg)"
                          type="button"
                          width="1.25rem"
                          onClick={() => handleEditTranscript(id)}
                        >
                          <img alt="Edit transcript" src={arrowIcon} />
                        </CircleButton>
                      </ButtonContainer>
                    </TranscriptListItem>
                  )}
                </Draggable>
              ))}
              {providedList.placeholder}
            </TranscriptList>
          )}
        </Droppable>
      </DragDropContext>
      {notificationAlerts && notificationType && (
        <NotificationMessage message={notificationAlerts} type={notificationType} onHideNotificationMessage={handleHideNotification} />
      )}
    </>
  );
};

export default TranscriptListTab;
