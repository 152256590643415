import gql from 'graphql-tag';

export const UPDATE_QUESTION_CONFIGURATION_CHECKBOX = gql`
  mutation updateQuestionConfigurationCheckbox(
    $allowUserGeneratedResponses: Boolean!
    $maxCheckedResponse: Int!
    $questionConfigurationId: Int!
  ) {
    questionConfiguration {
      updateQuestionConfiguration(
        questionConfigurationId: $questionConfigurationId
        questionConfigurationCheckbox: {
          allowUserGeneratedResponses: $allowUserGeneratedResponses
          maxCheckedResponse: $maxCheckedResponse
        }
      ) {
        ... on Checkbox {
          allowUserGeneratedResponses
          maxCheckedResponse
        }
      }
    }
  }
`;
