import React, { useRef, useEffect, useState } from 'react';

import BubbleTail from './BubbleTail';

const tailAngles = {
  1: '-150deg',
  2: '-120deg',
  3: '-90deg',
  4: '-60deg',
  5: '-30deg',
  6: '0deg',
  7: '30deg',
  8: '60deg',
  9: '90deg',
  10: '120deg',
  11: '150deg',
  12: '180deg',
};

const bubbleShapeBaseStyles = {
  fill: '#fff',
  top: '50%',
  left: '50%',
  width: 'calc(100% + 2rem)',
};

const svgBased = ['OVAL', 'THINK'];

export const TextMessage = ({ isReceived, text }) => (
  <div className={`c-text-message c-text-message--${isReceived ? 'right-aligned' : 'left-aligned'}`}>
    <div className="h-background--color-brand-secondary c-text-message__avatar" />
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      className="c-text-message__bubble"
    />
  </div>
);

const BubbleShape = () => (
  <svg style={bubbleShapeBaseStyles} className="c-cartoon__image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 205 132">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="118" cy="43" r="43" />
        <circle className="cls-1" cx="63" cy="45" r="32" />
        <circle className="cls-1" cx="35" cy="89" r="29" />
        <circle className="cls-1" cx="82.5" cy="97.5" r="34.5" />
        <circle className="cls-1" cx="132" cy="97" r="25" />
        <circle className="cls-1" cx="170" cy="71" r="35" />
        <circle className="cls-1" cx="25" cy="58" r="25" />
      </g>
    </g>
  </svg>
);

const SpeechBubble = ({ children, angle, top, left, type, tailType, isTailVisible, width }) => {
  const bubbleRef = useRef(null);
  const isSVGBased = svgBased.includes(type);
  const [bubbleTailWidth, setBubbleTailWidth] = useState(0);

  useEffect(() => {
    if (bubbleRef.current) {
      setBubbleTailWidth(bubbleRef.current.clientWidth);
    }
  }, []);

  const EllipseShape = () => (
    <svg style={bubbleShapeBaseStyles} className="c-cartoon__image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1264 980">
      <ellipse id="Ellipse_594" data-name="Ellipse 594" cx="632" cy="500" rx="850" ry="550" fill="#fff" />
    </svg>
  );

  const RectangleShape = () => (
    <div
      className="c-cartoon__image c-cartoon__rectangle"
      ref={bubbleRef}
      style={{
        ...bubbleShapeBaseStyles,
        top: `${top}%`,
        left: `${left}%`,
        width: `${width}%`,
      }}
    >
      {isTailVisible && (
        <div
          className="c-rectangle-tail"
          style={{
            borderTop: `${bubbleTailWidth / 2 + 50}px solid #fff`,
            transform: `translate(-50%, 0) rotate(${tailAngles[Math.round(angle)]})`,
          }}
        />
      )}
      <div className="l-position--relative">{children}</div>
    </div>
  );
  const Label = () => {
    const isYAxisSpecified = Boolean(top);
    const isXAxisSpecified = Boolean(left);
    const isWidthSpecified = Boolean(width);

    return (
      <div
        style={{
          top: isYAxisSpecified && `${top}%`,
          left: isXAxisSpecified && `${left}%`,
          width: isWidthSpecified && `${width}%`,
        }}
        className="c-cartoon-label"
      >
        <div className="c-cartoon-label__content">{children}</div>
      </div>
    );
  };

  const ShapeNode = {
    THINK: <BubbleShape bubbleShapeBaseStyles={bubbleShapeBaseStyles} />,
    OVAL: <EllipseShape />,
    RECTANGLE: <RectangleShape hasTail />,
    QUIZ: <RectangleShape />,
    LABEL: <Label />,
  };

  return (
    <>
      {isSVGBased ? (
        <div
          className="c-cartoon__bubble"
          ref={bubbleRef}
          style={{
            top: `${top}%`,
            left: `${left}%`,
            width: `${width}%`,
          }}
        >
          <div className="l-position--relative">
            {ShapeNode[type]}
            {isTailVisible && <BubbleTail tailType={tailType} angle={angle} tailAngles={tailAngles} />}
            <div className="l-position--relative">{children}</div>
          </div>
        </div>
      ) : (
        <>{ShapeNode[type]}</>
      )}
    </>
  );
};

export default SpeechBubble;
