import React, { useContext } from 'react';

import styled from 'styled-components';

import ThemeContext from '../../context/ThemeContext';

// **************styled Components********************
const Wrapper = styled.div`
  background: ${props => props.bgColor};
  box-sizing: border-box;
  color: white;
  display: flex;
  font-size: 0.9em;
  height: 3.75rem;
  justify-content: space-between;
  padding: 1.2rem 2rem;
  width: 100%;
  z-index: 1;
  bottom: 0;

  @media only screen and (max-width: 768px) {
    align-items: center;
    flex-flow: column wrap;
    font-size: 0.8rem;
    padding: 0.7rem 0;
  }
`;

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
`;

const TextWrapper = styled(LinkWrapper)`
  @media only screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

//   margin: 0 1rem;

//   @media (min-wdith: 768px) {
//     margin: 1rem;
//   }
// `;

const NameText = styled.p`
  height: min-content;
`;

//   color: white;
//   margin: 0;
//   text-decoration: none;

//   &:hover {
//     color: #4bc2ca;
//   }

//   @media (min-wdith: 768px) {
//     margin: 1rem;
//   }
// `;
// **************styled Components********************

const Footer = () => {
  const { theme } = useContext(ThemeContext);
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <Wrapper bgColor={theme.footerColor}>
      <TextWrapper>
        <NameText>
          {currentYear} ©{/* {name.toUpperCase()} */}
        </NameText>
      </TextWrapper>
      {/* <LinkWrapper>
        <Link href="/privacy">Privacy</Link>
        <Line>|</Line>
        <Link href="/terms">Terms and conditions</Link>
        <Line>|</Line>
        <Link href="/contact">Contact</Link>
      </LinkWrapper> */}
    </Wrapper>
  );
};

// Footer.propTypes = {
//   name: PropTypes.string,
// };

// Footer.defaultProps = {
//   name: 'EKARDO',
// };

export default Footer;
