/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';

import { ApolloCache, DefaultContext, MutationHookOptions, MutationTuple, OperationVariables, useMutation } from '@apollo/client';

import { IUseApolloMutationOptions } from './useApolloMutation.interfaces';

import { getApolloClient } from '../../utils';

const useApolloMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>({
  apiName,
  apolloLink,
  mutation,
  options,
  requestCredentials,
}: IUseApolloMutationOptions<TData, TVariables, TContext>): MutationTuple<TData, TVariables, TContext, TCache> => {
  const client = useRef(
    getApolloClient({
      apiName,
      apolloLink,
      requestCredentials,
    }),
  ).current;

  const updatedOptions: MutationHookOptions<TData, TVariables, TContext> = {
    ...options,
    client,
  };

  return useMutation(mutation, updatedOptions);
};

export { useApolloMutation };
