import React from 'react';

import PropTypes from 'prop-types';

function Tab({ id, isInSideBar = false, isSelected = false, label, onTabChangeHandler }) {
  const sidebarTabStyle = isInSideBar && 'sidebar-flex';

  const handleTabChange = (selectedIndex) => {
    if (!onTabChangeHandler) {
      return;
    }

    onTabChangeHandler(selectedIndex);
  };

  return (
    <div className={`tabset ${sidebarTabStyle} underline-from-center`}>
      <input
        aria-controls={label.toLowerCase()}
        checked={isSelected}
        id={id.toString()}
        name={label.toLowerCase()}
        type="radio"
        value={id}
        onChange={() => handleTabChange(id)}
      />
      <label className="underline-from-center" htmlFor={id.toString()}>
        {label}
      </label>
    </div>
  );
}

Tab.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isInSideBar: PropTypes.bool,
  isSelected: PropTypes.bool,
  onTabChangeHandler: PropTypes.func,
};

export { Tab };
