import gql from 'graphql-tag';

export const GET_CONTENT_PAGE = gql`
  query getContentPage($contentPageId: Int!) {
    contentPage {
      getContentPage(contentPageId: $contentPageId) {
        actions {
          id
          ... on QuestionActionShowSectionType {
            sectionId
          }
          ... on QuestionActionHideSectionType {
            sectionId
          }
          ... on QuestionActionHideContainerType {
            containerId
          }
          ... on QuestionActionShowContainerType {
            containerId
          }
          ... on QuestionActionShowType {
            targetSnippetId
          }
          ... on QuestionActionHideType {
            targetSnippetId
          }
          conditions {
            id
            title
            contentPageId
            contentSnippetQuestionId
            ... on QuestionConditionRadioResponseSelectedType {
              questionResponseRadioId
            }
            ... on QuestionConditionTextType {
              text
            }
            ... on QuestionConditionSliderValueSelectedType {
              maxValue
              minValue
            }
            ... on QuestionConditionCheckboxSelectedType {
              questionResponseCheckboxId
            }
          }
        }
      }
    }
  }
`;

export const GET_TEMPLATES = gql`
  query getTemplates($projectId: String!, $title: String!) {
    questionAction {
      getConditions(projectId: $projectId, title: $title, isTemplate: true) {
        id
        title
        ... on QuestionConditionRadioResponseSelectedType {
          questionResponseRadioId
        }
        ... on QuestionConditionTextType {
          text
        }
        ... on QuestionConditionSliderValueSelectedType {
          maxValue
          minValue
        }
        ... on QuestionConditionCheckboxSelectedType {
          questionResponseCheckboxId
        }
      }
    }
  }
`;

export const APPLY_TEMPLATE = gql`
  mutation applyTemplate($templateId: Int!, $actionId: Int!) {
    questionAction {
      applyTemplate(templateId: $templateId, actionId: $actionId) {
        contentPageId
        contentSnippetQuestionId
        id
        title
        ... on QuestionConditionRadioResponseSelectedType {
          questionResponseRadioId
        }
        ... on QuestionConditionTextType {
          text
        }
        ... on QuestionConditionSliderValueSelectedType {
          minValue
          maxValue
        }
        ... on QuestionConditionCheckboxSelectedType {
          questionResponseCheckboxId
        }
      }
    }
  }
`;
