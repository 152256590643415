import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import MutationRequest from '../MutationRequest';

import Error404 from '../../pages/Error404/Error404';

export const ACTIVATE_ACCOUNT = gql`
  mutation($code: String!) {
    activateAccount(activationCode: $code) {
      refreshToken
      token
    }
  }
`;

const activateAccountRequest = ({ code }) => (
  <Mutation client={Client()} mutation={ACTIVATE_ACCOUNT}>
    {(forgotPassword, { loading, error, data }) => {
      const variables = {
        code,
      };
      if (loading) return 'activating';
      if (error) {
        return <Error404 />;
      }
      if (data) {
        return <Redirect to="/login" />;
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={forgotPassword}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

activateAccountRequest.propTypes = {
  code: PropTypes.string.isRequired,
};

export default activateAccountRequest;
