/* eslint-disable react/jsx-props-no-spreading */
import React, { createRef, Component } from 'react';

import Axios from 'axios';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Dropzone from 'react-dropzone';

import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import Preloader from '../Preloader/Preloader';
import SideBarRight from '../SideBarRight/SideBarRight';

import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import CreateAssetRequest from '../../middleware/Asset/createAssetRequest';
import DeleteAssetRequest from '../../middleware/Asset/deleteAssetRequest';

class UploadImageSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRightOpen: true,
      file: '',
      createAsset: false,
      assetId: '',
      loader: false,
      errorMessage: '',
      deleteAssetRequest: false,
    };
  }

  validate = () => {
    const { file } = this.state;

    return {
      FileEmpty: {
        validation: file,
        errorMessage: 'App logo must not be empty',
      },
    };
  };

  handleToggleRightSideBar = () => {
    const { isRightOpen } = this.state;
    this.setState({
      isRightOpen: !isRightOpen,
    });
  };

  handleOnSubmit = () => {
    this.setState({
      // createMyAppRequest: true,
      createAsset: true,
    });
  };

  handleOnSuccessResultCreateApp = () => {
    this.setState({
      isRightOpen: false,
      file: '',
    });
  };

  handleFileDrop = (files) => {
    this.setState({
      file: files[0],
    });
  };

  handleAssetError = (msg) => {
    this.setState({
      createAsset: false,
      errorMessage: msg,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, asset },
      },
    } = data;

    const { file } = this.state;
    const { onHandleToggleSidebar, onCreateImageAsset } = this.props;

    this.setState({
      createAsset: false,
      loader: true,
    });
    Object.defineProperty(file, 'name', {
      writable: true,
      value: asset.s3Key,
    });
    Axios.put(signedUrl, file, { headers: {} })
      .then(() => {
        onCreateImageAsset();
        onHandleToggleSidebar();
        this.setState({
          loader: false,
        });
      })
      .catch(() => {
        onHandleToggleSidebar();
        this.setState({
          loader: false,
        });
      });
  };

  handleDeleteSnippetError = (message) => {
    this.setState({
      deleteAssetRequest: false,
      errorMessage: message,
    });

    setInterval(() => {
      this.setState({
        errorMessage: '',
      });
    }, 3000);
  };

  handleDeleteSnippetResponse = () => {
    this.setState({
      deleteAssetRequest: false,
    });
  };

  render() {
    const { isRightOpen, projectId, onHandleToggleSidebar, type } = this.props;
    const { file, createAsset, assetId, loader, errorMessage, deleteAssetRequest } = this.state;
    const dropzoneRef = createRef();

    const openDialog = () => {
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };
    return (
      <>
        <SideBarRight open={isRightOpen}>
          <button className="c-sidebar-right__close" type="button" onClick={onHandleToggleSidebar} onKeyDown={onHandleToggleSidebar}>
            <span className="icon-plus" />
          </button>
          <strong className="mb-3 mt-3">Upload {type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()}</strong>
          <Dropzone ref={dropzoneRef} noClick noKeyboard onDropAccepted={this.handleFileDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="container dropzone-outer">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>{isDragActive ? 'Drop here' : `Drop ${type.toLowerCase()} here or click to upload`}</p>
                  <p>
                    (<strong>Maximum</strong> 1 file)
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="mt-3 text-center">
            <button className="button--green" type="button" onClick={openDialog}>
              Choose from library
            </button>
          </div>
          <div className="text-center">
            <button className="button--black mt-4 mr-3" type="button" onClick={onHandleToggleSidebar}>
              Cancel
            </button>
            <button className="mt-4  button--blue" type="submit" onClick={this.handleOnSubmit}>
              Save
            </button>
          </div>
        </SideBarRight>
        {createAsset && (
          <CreateAssetRequest
            contentType={file.type}
            fileName={file.name}
            fileSizeInBytes={file.size}
            projectId={projectId}
            type={type}
            onError={this.handleAssetError}
            onSuccessResult={this.handleAssetResponse}
          />
        )}
        {deleteAssetRequest && (
          <DeleteAssetRequest
            assetId={assetId}
            onError={this.handleDeleteSnippetError}
            onSuccessResult={this.handleDeleteSnippetResponse}
          />
        )}
        {loader && <Preloader text={`Uploading ${type.toLowerCase()}. Please wait!`} />}
        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        </ReactCSSTransitionGroup>
      </>
    );
  }
}

UploadImageSidebar.propTypes = {
  history: PropTypes.shape().isRequired,
  isRightOpen: PropTypes.bool.isRequired,
  onCreateImageAsset: PropTypes.func.isRequired,
  onHandleToggleSidebar: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default UploadImageSidebar;
