import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import Preloader from '../Preloader/Preloader';

import LeftNavBar from './LeftNavBar';
import NavTree from './NavTree/NavTree';
import { dataToTree } from './NavTree/TreeUtils';

import { useContentPageState } from '../../context/ContentPage/ContentPageContext';
import client from '../../middleware/client';
import { GET_LEFT_NAV_BAR_MENU } from '../../middleware/ContentGroup/getLeftNavBarMenu';

function ContentPageNavBar(props) {
  const [, dispatch] = useContentPageState();

  const { contentGroupId, contentGroupName, defaultPageId, getActivePageId, handleGetDisplayStatus, isSettingsOnly } = props;

  const [navTree, setNavTree] = useState(undefined);

  const [executeGetLeftNavBarMenu, { loading: isGetLeftNavBarMenuLoading }] = useLazyQuery(GET_LEFT_NAV_BAR_MENU, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const {
        contentGroup: {
          getContentGroup: { contentPages },
        },
      } = response;
      const tree = dataToTree(contentPages, defaultPageId);
      setNavTree(tree);
      dispatch({
        type: 'setNavTreeContentPageIds',
        newState: contentPages.map(({ id }) => id),
      });
    },
  });

  function handleGetActivePageId(id) {
    getActivePageId(id);
    handleGetDisplayStatus(false);
  }

  function handleRefresh() {
    setNavTree(undefined);
  }

  function handleShowStatus(show) {
    handleGetDisplayStatus(show);
  }

  useEffect(() => {
    if (navTree) {
      return;
    }

    executeGetLeftNavBarMenu({
      variables: {
        contentGroupId,
      },
    });
  }, [navTree]);

  if (isGetLeftNavBarMenuLoading || !navTree) {
    return <Preloader />;
  }

  return (
    <LeftNavBar isShowed={handleShowStatus} title={contentGroupName}>
      <NavTree
        activeId={defaultPageId}
        defaultTree={navTree}
        getActivePageId={handleGetActivePageId}
        handleRefresh={handleRefresh}
        isSettingsOnly={isSettingsOnly}
        isShowed={handleShowStatus}
      />
    </LeftNavBar>
  );
}

export default ContentPageNavBar;
