/* eslint-disable react/boolean-prop-naming */
import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_SLIDER = gql`
  mutation updateSlider(
    $sliderId: Int!
    $title: String!
    $arrows: Boolean
    $autoplay: Boolean
    $dots: Boolean
    $infinite: Boolean
    $slidesToScroll: Int
    $slidesToShow: Int
    $speed: Int
    $autoplaySpeed: Int
    $animation: ESlideAnimation
  ) {
    slider {
      updateSlider(
        slider: {
          sliderId: $sliderId
          title: $title
          arrows: $arrows
          autoplay: $autoplay
          autoplaySpeed: $autoplaySpeed
          dots: $dots
          infinite: $infinite
          slidesToScroll: $slidesToScroll
          slidesToShow: $slidesToShow
          speed: $speed
          animation: $animation
        }
      ) {
        id
      }
    }
  }
`;

const updateSlider = ({
  sliderId,
  title,
  arrows,
  autoplay,
  autoplaySpeed,
  animation,
  dots,
  infinite,
  slidesToScroll,
  slidesToShow,
  speed,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_SLIDER}>
    {(updateSliderFunc, { loading, error, data }) => {
      const variables = {
        sliderId,
        title,
        arrows,
        autoplay,
        dots,
        infinite,
        slidesToScroll,
        slidesToShow,
        speed,
        autoplaySpeed,
        animation,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.slider.updateSlider.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateSliderFunc}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateSlider.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sliderId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  animation: PropTypes.string,
  arrows: PropTypes.bool,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  speed: PropTypes.number,
};

updateSlider.defaultProps = {
  animation: 'LINEAR',
  arrows: true,
  autoplay: false,
  autoplaySpeed: 2000,
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 2000,
};

export default updateSlider;
