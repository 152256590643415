import React, { useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';

import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const CodeSnippet = props => {
  const { location } = props;
  const [getAssetSent, setGetAssetSent] = useState(
    location.s3Key !== undefined
  );
  const [htmlContent, setHtmlContent] = useState(null);

  const handleAssetError = () => {
    setGetAssetSent(true);
  };

  const handleAssetResponse = (s3Key, contentType) => {
    const tmpURL = getSignedUrl(s3Key, contentType);
    axios
      .get(tmpURL)
      .then(res => {
        if (!htmlContent) {
          setHtmlContent({ __html: res.data });
        }
      })
      .catch(() => {});
    setGetAssetSent(true);
  };

  if (location.s3Key !== undefined && !htmlContent) {
    const tmpURL = getSignedUrl(location.s3Key, location.contentType);
    axios
      .get(tmpURL)
      .then(res => {
        if (!htmlContent) {
          setHtmlContent({ __html: res.data });
        }
      })
      .catch(() => {});
  }
  return (
    <div>
      {/* <p>
        <strong>locationId</strong>: {location.assetId}
      </p> */}
      {/* eslint-disable-next-line react/no-danger */}
      {htmlContent && <div dangerouslySetInnerHTML={htmlContent} />}
      {!getAssetSent && (
        <GetAsset
          assetId={location.assetId}
          onError={handleAssetError}
          onSuccessResult={handleAssetResponse}
        />
      )}
    </div>
  );
};

CodeSnippet.propTypes = {
  location: PropTypes.shape({
    assetId: PropTypes.string,
    contentType: PropTypes.string,
    s3Key: PropTypes.string,
  }).isRequired,
};

export default CodeSnippet;
