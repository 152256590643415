import { FRAGMENT_CALENDAR } from './CalendarConfig';
import { FRAGMENT_CHECKBOX } from './CheckboxConfig';
import { FRAGMENT_DROPDOWNLIST } from './DropDownListConfig';
import { FRAGMENT_MATCH } from './MatchConfig';
import { FRAGMENT_MULTILINE } from './MultiLineTextConfig';
import { FRAGMENT_MULTI_RESPONSE } from './MultiResponseConfig';
import { FRAGMENT_NUMBER } from './NumberConfig';
import { FRAGMENT_RADIO } from './RadioConfig';
import { FRAGMENT_SINGLELINE } from './SingleLineTextConfig';
import { FRAGMENT_SLIDER } from './SliderConfig';
import { FRAGMENT_SUMMARY } from './SummaryConfig';

export const CalendarFragment = FRAGMENT_CALENDAR;
export const CheckboxFragment = FRAGMENT_CHECKBOX;
export const DropDownFragment = FRAGMENT_DROPDOWNLIST;
export const MatchFragment = FRAGMENT_MATCH;
export const MultilineFragment = FRAGMENT_MULTILINE;
export const MultiResponseFragment = FRAGMENT_MULTI_RESPONSE;
export const NumberFragment = FRAGMENT_NUMBER;
export const RadioFragment = FRAGMENT_RADIO;
export const SingleLineFragment = FRAGMENT_SINGLELINE;
export const SliderFragment = FRAGMENT_SLIDER;
export const SummaryFragment = FRAGMENT_SUMMARY;
