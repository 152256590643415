import gql from 'graphql-tag';

export const FRAGMENT_TEXT = gql`
  fragment text on ContentSnippetTextType {
    text
    fontColorSnippetText: fontColor
    linkColor
    listIconColor
  }
`;
