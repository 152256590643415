import gql from 'graphql-tag';

export const GET_DIRECTORY_TYPES = gql`
  query getDirectoryType($projectId: String!) {
    directoryType {
      get(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
