import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
`;

export const FlexHorizontal = styled(Flex)`
  flex-direction: row;
`;

export const FlexVertical = styled(Flex)`
  flex-direction: column;
`;

export const RoundButton = styled.button`
  background-color: rgb(136, 146, 159);
  border: 0;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  height: 1.5rem;
  width: 1.5rem;
`;

export const StyledTableBase = styled(Table)`
  border-radius: 1rem;
`;

export const StyledTableFilterContainer = styled(Flex)`
  margin-bottom: 1.5rem;
`;

export const StyledTBodyBordered = styled(Tbody)`
  border: 1px solid rgb(225, 225, 225);
`;

export const StyledTdBase = styled(Td)`
  padding: 0.6rem 1.2rem;
  vertical-align: middle;
`;

export const StyledTdHorizontalCentered = styled(StyledTdBase)`
  text-align: center;
`;

export const StyledTdRightAligned = styled(StyledTdBase)`
  text-align: right;
`;

export const StyledTdSpacer = styled(Td)`
  padding: 0;
  width: 3px;
`;

export const StyledThBase = styled(Th)`
  vertical-align: middle;
`;

export const StyledThCentered = styled(StyledThBase)`
  text-align: center;
`;

export const StyledTHeadBordered = styled(Thead)`
  background-color: #fff;
`;

export const StyledThRightAligned = styled(StyledThBase)`
  text-align: right;
`;

export const StyledThSpacer = styled(Th)`
  padding: 0;
  width: 3px;
`;

export const StyledTrBase = styled(Tr)`
  border-bottom: 1px solid rgb(225, 225, 225);

  td:last-child button {
    opacity: 0;
  }

  &:hover {
    td:first-child {
      background-color: #4bc1c9;
    }

    td:not(:first-child) {
      background-color: #f6f8f9;
    }

    td:last-child button {
      opacity: 1;
    }
  }
`;
