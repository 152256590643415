import React, { Fragment, useEffect, useRef, useState } from 'react';

import { Accordion, ButtonIconOnly, IMessage, TreeNodeInArray } from '@netfront/ui-library';
import noop from 'lodash.noop';
import orderBy from 'lodash.orderby';

import { DEFAULT_API_ERROR_MESSAGE, DEFAULT_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';

import { getAccordionData, getAvailableResponseNumber, getFirstUnansweredAvailableResponse } from './QuestionAnswerMatchSnippet.helpers';
import { QuestionAnswerMatchSnippetProps } from './QuestionAnswerMatchSnippet.interfaces';

import { getContentSnippet, getContentSnippetFormId, useContentPageContext } from '../../../../contexts';
import { useSetUserAnswerMatch } from '../../../../hooks';
import { IDBMatchItem, IDBQuestionAnswerMatchResponseMatch, IDBQuestionResponse } from '../../../../interfaces';
import { IRadioOption, RadioButtonSnippet } from '../../../Snippets/RadioButtonSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';

import './QuestionAnswerMatchSnippet.css';

const QuestionAnswerMatchSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  userFlowStepTrackId,
}: QuestionAnswerMatchSnippetProps) => {
  const { state: contentPage } = useContentPageContext();

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false } = contentSnippet ?? {};
  const { answers = [] } = answer ?? {};
  const { matchItems, responseSet } = configuration ?? {};
  const { availableResponses = [] } = responseSet ?? {};

  const { length: totalAnswers } = answers;
  const { length: totalAvailableResponses } = availableResponses;

  const sortedMatchItems = useRef<IDBMatchItem[]>(orderBy(matchItems, 'sort'));

  const [accordionData, setAccordionData] = useState<TreeNodeInArray[]>();
  const [currentAnswers, setCurrentAnswers] = useState<IDBQuestionAnswerMatchResponseMatch[]>(answers);
  const [firstUnansweredAvailableResponse, setFirstUnansweredAvailableResponse] = useState<IDBQuestionResponse | undefined>(
    getFirstUnansweredAvailableResponse(answers, availableResponses)
  );
  const [hasAnsweredAllAvailableResponses, setHasAnsweredAllAvailableResponses] = useState<boolean>(
    totalAnswers === totalAvailableResponses
  );
  const [matchItemRadioOptions, setMatchItemRadioOptions] = useState<IRadioOption[]>();
  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);

  const { handleSetUserAnswerMatch } = useSetUserAnswerMatch({
    onCompleted: ({ setUserAnswer: { answers: returnedAnswers } }) => {
      setCurrentAnswers(returnedAnswers);
      setFirstUnansweredAvailableResponse(getFirstUnansweredAvailableResponse(returnedAnswers, availableResponses));
      setHasAnsweredAllAvailableResponses(returnedAnswers.length === totalAvailableResponses);
    },
    onError: (error) => {
      handleApolloError(error);
      setMessage({
        error: DEFAULT_API_ERROR_MESSAGE,
        success: '',
      });
    },
    token: accessToken,
  });

  const handleMatchItemAnswered = (contentPageId: number, inputDefinedPosition: number, inputQuestionResponseMatchId: number) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    handleSetUserAnswerMatch({
      ...baseVariables,
      answers: [
        ...currentAnswers
          .filter(({ questionResponseMatchId }) => inputQuestionResponseMatchId !== questionResponseMatchId)
          .map(({ definedPosition, questionResponseMatchId }) => ({
            definedPosition,
            questionResponseMatchId,
          }))
          .concat({
            definedPosition: inputDefinedPosition,
            questionResponseMatchId: inputQuestionResponseMatchId,
          }),
      ],
    });
  };

  useEffect(() => {
    if (!contentPage) {
      return;
    }

    setAccordionData(
      getAccordionData({
        answers: currentAnswers,
        availableResponses,
        matchItems: sortedMatchItems.current,
        onMatchItemsDropdownChange: (availableResponseId, definedPosition) => {
          handleMatchItemAnswered(contentPage.id, definedPosition, availableResponseId);
        },
      })
    );

    setMatchItemRadioOptions(
      sortedMatchItems.current.map(({ id: matchItemId, maxItemsCount = 0, text = '' }, matchItemIndex): IRadioOption => {
        const matchItemIdAsString = String(matchItemId);
        const matchItemAnswers = currentAnswers.filter(({ definedPosition }) => definedPosition === matchItemIndex).length;

        return {
          id: matchItemIdAsString,
          isDisabled: maxItemsCount === matchItemAnswers,
          label: text,
          value: matchItemIdAsString,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswers]);

  return (
    <Fragment>
      <div className="c-question-answer-match__card">
        {hasAnsweredAllAvailableResponses && (
          <Fragment>
            <div className="c-question-answer-match__card-completed">Completed!</div>
            <div className="c-question-answer-match__card-completed-icon">
              <ButtonIconOnly iconId="id_tick_icon" text="" onClick={noop} />
            </div>
          </Fragment>
        )}
        {contentPage && firstUnansweredAvailableResponse && matchItemRadioOptions && (
          <Fragment>
            <div className="c-question-answer-match__card-counter">
              {getAvailableResponseNumber(firstUnansweredAvailableResponse, availableResponses)} of {totalAvailableResponses}
            </div>
            <div className="c-question-answer-match__card-label">{firstUnansweredAvailableResponse.label}</div>
            <RadioButtonSnippet
              id={`c-question-answer-match__card-available-response-${firstUnansweredAvailableResponse.id}-radio-button`}
              isRequired={isMandatory}
              message={message}
              options={matchItemRadioOptions}
              selectedValue=""
              onChange={(matchItemId) => {
                const definedPosition = sortedMatchItems.current.findIndex(({ id }) => id === Number(matchItemId));

                handleMatchItemAnswered(contentPage.id, definedPosition, firstUnansweredAvailableResponse.id);
              }}
            />
          </Fragment>
        )}
      </div>
      {accordionData && (
        <div className="c-question-answer-match__accordion">
          <Accordion data={accordionData} />
        </div>
      )}
    </Fragment>
  );
};

export { QuestionAnswerMatchSnippet };
