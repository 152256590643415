/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, createRef } from 'react'; // createRef,

import Axios from 'axios';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Dropzone from 'react-dropzone';

import InputHolder from '../InputHolder/InputHolder';
import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import Preloader from '../Preloader/Preloader';
import SideBarRight from '../SideBarRight/SideBarRight';

import { errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import CreateAssetRequest from '../../middleware/Asset/createAssetRequest';
import DeleteAssetRequest from '../../middleware/Asset/deleteAssetRequest';
import GetAsset from '../../middleware/Asset/getAsset';
import GetAssetSignedUrlForReplacementRequest from '../../middleware/Asset/getAssetSignedUrlForReplacementRequest';
import UpdateAssetLibraryRequest from '../../middleware/AssetLibrary/updateAssetLibraryRequest';
import { removeByAttr, assetType } from '../../utils/utils';

class EditAssetSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRightOpen: true,
      file: '',
      createAsset: false,
      assetId: '',
      loader: false,
      errorMessage: '',
      deleteAssetRequest: false,
      fileName: '',
      altTag: '',
      tag: '',
      tags: '',
      updatedTags: '',
      updateAssetLibraryRequest: false,
      transcript: '',
      xAxisFocus: 0,
      getSigneUrlRequest: false,
      getAssetSent: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      getAssetSent: true,
    });
  };

  validate = () => {
    const { altTag } = this.state;

    return {
      AltTagEmplty: {
        validation: altTag,
        errorMessage: 'Alt tag must not be empty',
      },
    };
  };

  handleErrorMessage = (msg) => {
    this.setState({
      errorMessage: msg,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
    }, 2000);
  };

  handleToggleRightSideBar = () => {
    const { isRightOpen } = this.state;
    this.setState({
      file: '',
      isRightOpen: !isRightOpen,
    });
  };

  handleOnSubmit = () => {
    this.setState({
      updateAssetLibraryRequest: true,
    });
  };

  handleFileDrop = (files) => {
    this.setState({
      file: files[0],
    });
  };

  handleAssetError = (msg) => {
    this.setState({
      createAsset: false,
    });
    this.handleErrorMessage(msg);
  };

  handleAssetResponse = (data) => {
    const {
      asset: {
        createAsset: { signedUrl, s3Key },
      },
    } = data;

    const { file } = this.state;
    const { onHandleToggleSidebar, onCreateImageAsset } = this.props;

    this.setState({
      createAsset: false,
      loader: true,
    });
    Object.defineProperty(file, 'name', {
      writable: true,
      value: s3Key,
    });
    Axios.put(signedUrl, file, { headers: {} })
      .then(() => {
        onCreateImageAsset();
        onHandleToggleSidebar();
        this.setState({
          loader: false,
        });
      })
      .catch(() => {
        onHandleToggleSidebar();
        this.setState({
          loader: false,
        });
      });
  };

  handleDeleteSnippetError = (message) => {
    this.setState({
      deleteAssetRequest: false,
    });
    this.handleErrorMessage(message);
  };

  handleDeleteSnippetResponse = () => {
    this.setState({
      deleteAssetRequest: false,
    });
  };

  handleInputs = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleAddTag = () => {
    const { tags, tag } = this.state;
    if (tag) {
      const newTags = tags || [];
      newTags.push({
        name: tag,
        id: newTags.length,
      });
      this.setState({
        tags: newTags,
        updatedTags: JSON.stringify(newTags),
        tag: '',
      });
    }
  };

  removeTag = (id) => {
    const { tags } = this.state;
    const newTags = removeByAttr(tags, 'id', id);
    this.setState({
      tags: newTags,
      updatedTags: JSON.stringify(newTags),
    });
  };

  handleOnResponseUpdateAsset = () => {
    const { onHandleToggleSidebar } = this.props;
    const { file } = this.state;
    this.setState({
      updateAssetLibraryRequest: false,
      getSigneUrlRequest: !!file,
      getAssetSent: true,
    });

    onHandleToggleSidebar();
  };

  handleOnErrorUpdateAsset = (message) => {
    const { onHandleToggleSidebar } = this.props;
    this.setState({
      updateAssetLibraryRequest: false,
      errorMessage: message,
    });
    setTimeout(() => {
      this.setState({
        errorMessage: '',
      });
      onHandleToggleSidebar();
    }, 2000);
  };

  handleFileDrop = (files) => {
    this.setState({
      file: files[0],
    });
  };

  handleGetSignedUrlError = (error) => {
    this.setState({
      getSigneUrlRequest: false,
    });
    this.handleErrorMessage(error);
  };

  handleGetSignedUrlResponse = (response) => {
    this.handleAssetResponse(response.signedUrl, response.asset.s3Key);

    this.setState({
      getSigneUrlRequest: false,
    });
  };

  handleAssetResponse = (s3key, test, data) => {
    this.setState({
      getAssetSent: false,
      fileName: data.fileName,
      altTag: data.alt,
      tags: data.tags ? JSON.parse(data.tags) : [],
      transcript: data.transcript,
      updatedTags: data.tags ? JSON.parse(data.tags) : [],
      assetId: data.assetId,
    });
  };

  handleAssetError = (error) => {
    this.setState({
      errorMessage: error,
      getAssetSent: false,
    });
  };

  // eslint-disable-next-line react/sort-comp
  // eslint-disable-next-line react/static-property-placement
  // static contextType = AppContext;

  render() {
    const { isRightOpen, projectId, onHandleToggleSidebar, type, asset } = this.props;
    const {
      file,
      createAsset,
      assetId,
      loader,
      errorMessage,
      deleteAssetRequest,
      fileName,
      altTag,
      tag,
      tags,
      updateAssetLibraryRequest,
      xAxisFocus,
      transcript,
      updatedTags,
      getSigneUrlRequest,
      getAssetSent,
    } = this.state;

    const dropzoneRef = createRef();
    // let filePreview = asset.src;
    // if (file) {
    //   filePreview = URL.createObjectURL(file);
    // }
    return (
      <div className="edit-asset-side-bar-container">
        <SideBarRight open={isRightOpen}>
          <button className="c-sidebar-right__close" type="button" onClick={onHandleToggleSidebar} onKeyDown={onHandleToggleSidebar}>
            <span className="icon-plus" />
          </button>
          <strong className="mb-3 ">Outside</strong>
          <InputHolder label="Name" name="fileName" value={fileName} onChange={this.handleInputs} onClick={this.handleClearErrorMessages} />
          {type === assetType.IMAGE && (
            <InputHolder
              className="mt-1"
              label="Alt tag"
              name="altTag"
              value={altTag}
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
            />
          )}
          {(type === assetType.AUDIO || type === assetType.VIDEO) && (
            <InputHolder
              className="mt-1"
              label="Transcript"
              name="transcript"
              value={transcript}
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
            />
          )}
          <InputHolder label="Tags" name="tag" value={tag} onChange={this.handleInputs} onClick={this.handleClearErrorMessages} />
          <div className="w-100 text-right mt-3">
            <button className="button--blue px-3" type="button" onClick={this.handleAddTag}>
              Add
            </button>
          </div>
          <div className="d-flex flex-wrap mb-2">
            {tags.length > 0 &&
              tags.map((item) => (
                <div
                  key={item.id}
                  className="bg-grey d-inline-block mr-2 rounded p-1 text-white d-flex align-items-center cursor-pointer p-2 mt-1"
                >
                  {item.name}
                  <button key={item.id} className="pl-2 text-black cross-button-tags" type="button" onClick={() => this.removeTag(item.id)}>
                    <div>x</div>
                  </button>
                </div>
              ))}
          </div>
          <Dropzone ref={dropzoneRef} noClick noKeyboard onDropAccepted={this.handleFileDrop}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className="container dropzone-outer">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>{isDragActive ? 'Drop here' : `Drop ${type.toLowerCase()} here or click to replace`}</p>
                  <p>
                    (<strong>Maximum</strong> 1 file)
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
          {/* {type === assetType.IMAGE && (
            // <DndProvider backend={createHTML5Backend}>
            <ImageRegion filePreview={filePreview} />
            // </DndProvider>
          )} */}
          <div className="text-center">
            <button className="button--black mt-4 mr-3" type="button" onClick={onHandleToggleSidebar}>
              Cancel
            </button>
            <button className="mt-4  button--blue" type="submit" onClick={this.handleOnSubmit}>
              Save
            </button>
          </div>
        </SideBarRight>
        {createAsset && (
          <CreateAssetRequest
            contentType={file.type}
            fileName={file.name}
            fileSizeInBytes={file.size}
            projectId={projectId}
            type={type}
            onError={this.handleAssetError}
            onSuccessResult={this.handleAssetResponse}
          />
        )}
        {deleteAssetRequest && (
          <DeleteAssetRequest
            assetId={assetId}
            onError={this.handleDeleteSnippetError}
            onSuccessResult={this.handleDeleteSnippetResponse}
          />
        )}
        {loader && <Preloader text={`Uploading ${type.toLowerCase()}. Please wait!`} />}
        {updateAssetLibraryRequest && (
          <UpdateAssetLibraryRequest
            alt={altTag}
            assetId={asset.id}
            tags={updatedTags}
            transcript={transcript}
            xAxisFocus={xAxisFocus}
            onError={this.handleOnErrorUpdateAsset}
            onSuccessResult={this.handleOnResponseUpdateAsset}
          />
        )}
        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        </ReactCSSTransitionGroup>
        {getSigneUrlRequest && (
          <GetAssetSignedUrlForReplacementRequest
            assetId={asset.id}
            onError={this.handleGetSignedUrlError}
            onSuccessResult={this.handleGetSignedUrlResponse}
          />
        )}

        {getAssetSent && <GetAsset assetId={asset.id} onError={this.handleAssetError} onSuccessResult={this.handleAssetResponse} />}
      </div>
    );
  }
}

EditAssetSideBar.propTypes = {
  asset: PropTypes.shape().isRequired,
  isRightOpen: PropTypes.bool.isRequired,
  onCreateImageAsset: PropTypes.func.isRequired,
  onHandleToggleSidebar: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default EditAssetSideBar;
