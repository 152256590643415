import gql from 'graphql-tag';

export const UPDATE_DIRECTORY = gql`
  mutation updateDirectory($directory: UpdateDirectoryInput!) {
    directory {
      updateDirectory(directory: $directory) {
        id
        address {
          city
          country
          line1
          latitude
          longitude
          postcode
          state
          suburb
        }
        asset {
          assetId
          contentType
          fileName
          s3Key
          type
        }
        description
        directoryType {
          id
          name
        }
        directoryTypeId
        phoneNumber
        email
        tags
        title
        status
        subTitle
        url
      }
    }
  }
`;
