import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  ButtonIconOnly,
  DropzoneFileUpload,
  Form,
  FormButtons,
  Input,
  Label,
  Select,
  Spacing,
  Textarea,
  Tooltip,
  Preloader,
} from '@netfront/ui-library';
import axios from 'axios';
import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { DIRECTORY_STATUS_OPTIONS } from './DirectoryTab.constants';

import { CREATE_ASSET } from '../../../../middleware/Asset/createAssetRequest';
import { DELETE_ASSET } from '../../../../middleware/Asset/deleteAssetRequest';
import { GET_ASSET } from '../../../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../../../middleware/AWS/getSignedUrl';
import client from '../../../../middleware/client';
import { ATTACH_FILE } from '../../../../middleware/Directory/attachFile';
import { DETACH_FILE } from '../../../../middleware/Directory/detachFile';
import attachmentIcon from '../../../../static/img/file-icon.svg';
import { DEFAULT_TOAST_OPTIONS, createKey } from '../../../../utils';
import ListItemEditDelete from '../../../List/ListItemEditDelete';
import ImageUploader from '../../../UI/AssetUploader/ImageUploader';
import { ConfirmModal } from '../../../UI/Modal/ConfirmModal';
import { EditBtn, Preview, Logo } from '../../styled';

import '../Tabs.css';

const DirectoryTab = ({
  directory,
  directoryTypeOptions,
  isCreateNew,
  onClose,
  onDelete,
  onSave,
  onUpdateAsset,
  onUpdateDirectoryType,
  onUpdateInfo,
  onUpdateStatus,
}) => {
  const { asset, description, id: directoryId, directoryTypeId, files, email, phoneNumber, status, subTitle, title, url } = directory || {};

  const { projectId } = useParams();

  const [assetData, setAssetData] = useState(asset);
  const [assetList, setAssetList] = useState([]);
  const [droppedFile, setDroppedFile] = useState(null);
  const [isAddNewAttachment, setIsAddNewAttachment] = useState(false);
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [modal, setModal] = useState(null);

  const showHideDropzoneIcon = isAddNewAttachment ? 'id_close_icon' : 'id_plus_icon';

  const [getAssetInfo, { loading: isAssetInfoLoading }] = useLazyQuery(GET_ASSET, {
    client,
    onCompleted(response) {
      const {
        asset: { getAsset },
      } = response;

      setAssetData(getAsset);
    },
  });

  const [attachFile, { loading: isAttachFileLoading }] = useMutation(ATTACH_FILE, {
    client,
    onCompleted() {
      toast.success('File successfully attached');
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const [createAsset, { loading: isCreateAssetLoading }] = useMutation(CREATE_ASSET, {
    client,
    onCompleted({ asset: { createAsset: createdAsset } }) {
      const {
        signedUrl,
        asset: { assetId: createdAssetId },
      } = createdAsset;

      axios.put(signedUrl, droppedFile, { headers: {} });

      setAssetList([...assetList, createdAsset]);
      setIsAddNewAttachment(false);

      attachFile({
        variables: {
          id: directoryId,
          assetId: createdAssetId,
        },
      });

      toast.success('File successfully uploaded');
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const [deleteAsset, { loading: isDeleteAssetLoading }] = useMutation(DELETE_ASSET, {
    client,
    onCompleted() {
      setModal(null);
      toast.success('File successfully deleted');
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const [detachFile, { loading: isDetachFileLoading }] = useMutation(DETACH_FILE, {
    client,
    onCompleted() {
      deleteAsset({
        variables: {
          assetId: modal.assetId,
        },
      });

      toast.success('File successfully deleted');
    },
    onError({ message }) {
      toast.error(message);
    },
  });

  const handleCloseModal = () => {
    setModal(null);
  };

  const handleConfirm = () => {
    detachFile({
      variables: {
        assetId: modal.assetId,
        id: directoryId,
      },
    });
  };

  const handleDelete = () => {
    if (!onDelete) {
      return;
    }

    onDelete({
      message: 'Are you sure you want to delete this directory?',
      type: 'directory',
    });
  };

  const handleFileDrop = (droppedFiles) => {
    setDroppedFile(droppedFiles[0]);
  };

  const handleOpenModal = (info) => {
    setModal(info);
  };

  const handleSaveFile = () => {
    createAsset({
      variables: {
        alt: 'asset',
        contentType: droppedFile.type,
        fileName: droppedFile.name,
        fileSizeInBytes: droppedFile.size,
        projectId,
        type: 'DOCUMENT',
      },
    });
  };

  const handleUpdateAsset = (value) => {
    setIsEditLogo(false);
    onUpdateAsset(value);
  };

  const handleUploadLogo = (uploadedAssetId) => {
    getAssetInfo({
      variables: {
        assetId: uploadedAssetId,
      },
    });
  };

  useEffect(() => {
    if (!files) {
      return;
    }

    setAssetList(files);
  }, [files]);

  useEffect(() => {
    if (!droppedFile) {
      return;
    }

    handleSaveFile();
  }, [droppedFile]);

  useEffect(() => {
    if (!isCreateNew) {
      return;
    }

    setAssetList([]);
  }, [isCreateNew]);

  const isLoading = isAssetInfoLoading || isAttachFileLoading || isCreateAssetLoading || isDeleteAssetLoading || isDetachFileLoading;

  return (
    <>
      <Preloader isLoading={isLoading} />
      {modal ? <ConfirmModal modal={modal} onCloseModal={handleCloseModal} onConfirm={handleConfirm} /> : null}
      <Form onSubmit={onSave}>
        <fieldset>
          <legend className="h-hide">General</legend>
          <div className="c-view-wrapper">
            {!isCreateNew && (
              <Spacing>
                <div className="c-tooltip-container">
                  <Tooltip text="The status of the directory" />
                </div>
                <Select
                  id="status"
                  labelText="Directory status"
                  name="status"
                  options={DIRECTORY_STATUS_OPTIONS}
                  value={status}
                  onChange={onUpdateStatus}
                />
              </Spacing>
            )}
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="The type of directory" />
              </div>
              <Select
                id="directory-type"
                labelText="Directory type"
                name="directoryType"
                options={directoryTypeOptions}
                value={directoryTypeId}
                isRequired
                onChange={onUpdateDirectoryType}
              />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="The title displayed in the directory listing" />
              </div>
              <Input id="title" labelText="Title" name="title" type="text" value={title} isRequired onChange={onUpdateInfo} />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="The subtitle displayed in the directory listing" />
              </div>
              <Input id="sub-title" labelText="Sub Title" name="subTitle" type="text" value={subTitle} onChange={onUpdateInfo} />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="An optional external link to the directory website" />
              </div>
              <Input id="link" labelText="Link" name="url" type="text" value={url} onChange={onUpdateInfo} />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="An optional phone number for the directory" />
              </div>
              <Input
                id="phone-number"
                labelText="Phone number"
                name="phoneNumber"
                type="text"
                value={phoneNumber}
                onChange={onUpdateInfo}
              />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="An optional email address for the directory" />
              </div>
              <Input
                id="email"
                labelText="Email address"
                name="email"
                type="text"
                value={email}
                onChange={onUpdateInfo}
              />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="The description shown in the directory listing" />
              </div>
              <Textarea
                id="description"
                labelText="Description"
                name="description"
                type="text"
                value={description}
                onChange={onUpdateInfo}
              />
            </Spacing>
            <Spacing>
              <div className="c-tooltip-container">
                <Tooltip text="The logo displayed with the directory title and description" />
              </div>
              <Label
                className="c-input-title h-flex h-align-items-center h-justify-content-space-between"
                forId="directory-logo"
                labelText="Logo"
              />
              {assetData && !isEditLogo ? (
                <Preview>
                  <EditBtn
                    onClick={() => {
                      setIsEditLogo(true);
                    }}
                  >
                    Edit the logo
                  </EditBtn>
                  <Logo alt={assetData.alt} src={getSignedUrl(assetData.s3Key, assetData.contentType)} />
                </Preview>
              ) : (
                <ImageUploader
                  getData={handleUpdateAsset}
                  id="directory-logo"
                  name="bgImg"
                  projectId={projectId}
                  type="IMAGE"
                  onImageUpload={handleUploadLogo}
                />
              )}
            </Spacing>
            <Spacing>
              <Spacing>
                <div className="h-flex h-justify-content-between h-align-items-center">
                  <div className="h-flex h-align-items-center h-flex-gap">
                    <Label forId="directory document" labelText="Document" spacing="none" />
                    {assetList.length ? (
                      <ButtonIconOnly
                        additionalClassNames="c-add-new-button"
                        iconId={showHideDropzoneIcon}
                        id="add-new-directory"
                        onClick={() => setIsAddNewAttachment(!isAddNewAttachment)}
                      />
                    ) : null}
                  </div>
                  <div className="c-tooltip-container">
                    <Tooltip text="Add, edit or remove a document" />
                  </div>
                </div>
              </Spacing>
              {isAddNewAttachment && (
                <DropzoneFileUpload
                  additionalClassNames="c-dropzone-file-upload"
                  fileType=""
                  id="directory-document"
                  onDrop={(file) => handleFileDrop(file)}
                />
              )}
              {assetList.length ? (
                <ul className="c-list-container">
                  {assetList.map(({ asset: { assetId: assetItemId, fileName, s3Key, contentType } }) => (
                    <ListItemEditDelete
                      key={`asset-buttons-${createKey(assetItemId)}`}
                      asset={{ s3Key, contentType }}
                      icon={attachmentIcon}
                      text={fileName}
                      isBorder
                      onDelete={() =>
                        handleOpenModal({
                          assetId: assetItemId,
                          message: 'Are you sure you want to delete this attachment?',
                          type: 'attachment',
                        })
                      }
                    />
                  ))}
                </ul>
              ) : (
                <DropzoneFileUpload
                  additionalClassNames="c-dropzone-file-upload"
                  fileType=""
                  id="attachment-dropzone"
                  onDrop={(file) => handleFileDrop(file)}
                />
              )}
            </Spacing>
          </div>
        </fieldset>
        <FormButtons additionalClassNames="c-form-button__directory" onClose={onClose} onDelete={handleDelete} />
      </Form>
      <Toaster toastOptions={DEFAULT_TOAST_OPTIONS} />
    </>
  );
};

export { DirectoryTab };

DirectoryTab.propTypes = {
  directory: PropTypes.shape({
    asset: PropTypes.shape({
      contentType: PropTypes.string,
      presignedUrl: PropTypes.string,
      s3Key: PropTypes.string,
    }),
    description: PropTypes.string,
    directoryTypeId: PropTypes.number,
    files: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.number,
    phoneNumber: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  directoryTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  isCreateNew: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  onUpdateAsset: PropTypes.func,
  onUpdateDirectoryType: PropTypes.func,
  onUpdateInfo: PropTypes.func,
};
