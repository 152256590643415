import React, { Fragment } from 'react';

import { ButtonIconOnly } from '@netfront/ui-library';
import cx from 'classnames';

import { DraggableItem } from '../DraggableItem';

import { QuestionResponseDraggableItemProps } from './QuestionResponseDraggableItem.interfaces';

import './QuestionResponseDraggableItem.css';

const QuestionResponseDraggableItem = ({
  canIncrementOrDecrementQuestionResponseRank,
  canRankQuestionResponses,
  id,
  index,
  isNoteVisible,
  label,
  lastSetRank,
  note,
  noteMaxLength,
  onDecrementRank,
  onDeleteConfirm,
  onIncrementRank,
  onNoteChange,
  onRank,
  onShowHideNote,
  questionResponse,
  rank,
  shouldAllowNote = false,
  shouldAllowDragAndDrop = true,
}: QuestionResponseDraggableItemProps) => {
  return (
    <DraggableItem id={id.toString()} index={index} shouldAllowDragAndDrop={shouldAllowDragAndDrop}>
      <div className="c-question-response-draggable-item__question-response-container">
        <div className="c-question-response-draggable-item__rank-container">
          <button
            className="c-question-response-draggable-item__rank-button"
            disabled={!canRankQuestionResponses || !!rank}
            type="button"
            onClick={() => onRank(questionResponse)}
          >
            {rank}
          </button>
        </div>
        <div className="c-question-response-draggable-item__label">{label}</div>
        {canIncrementOrDecrementQuestionResponseRank && (
          <Fragment>
            {rank && rank > 1 && (
              <div className="c-question-response-draggable-item__increment-rank">
                <ButtonIconOnly iconId="id_arrow_icon" text="Increment rank" onClick={() => onIncrementRank(questionResponse)} />
              </div>
            )}
            {rank && rank < lastSetRank && (
              <div className="c-question-response-draggable-item__decrement-rank">
                <ButtonIconOnly iconId="id_arrow_icon" text="Decrement rank" onClick={() => onDecrementRank(questionResponse)} />
              </div>
            )}
          </Fragment>
        )}
        {shouldAllowNote && onNoteChange && onShowHideNote && (
          <div
            className={cx('c-question-response-draggable-item__collapse-expand', {
              'c-question-response-draggable-item__collapse-expand--open': isNoteVisible,
            })}
          >
            <ButtonIconOnly iconId="id_arrow_icon" text="Show/hide note" onClick={() => onShowHideNote(questionResponse)} />
          </div>
        )}
        <div className="c-question-response-draggable-item__delete-button">
          <ButtonIconOnly iconId="id_bin_icon" text="Delete question response" onClick={() => onDeleteConfirm(questionResponse)} />
        </div>
      </div>
      {shouldAllowNote && onNoteChange && (
        <div
          className={cx('c-question-response-draggable-item__note-container', {
            'c-question-response-draggable-item__note-container--hidden': !isNoteVisible,
            'c-question-response-draggable-item__note-container--visible': isNoteVisible,
          })}
        >
          <input
            className="c-question-response-draggable-item__note"
            maxLength={noteMaxLength}
            type="text"
            value={note ?? ''}
            onChange={({ target: { value } }) => onNoteChange(questionResponse, value)}
          />
        </div>
      )}
    </DraggableItem>
  );
};

export { QuestionResponseDraggableItem };
