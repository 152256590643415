import React, { useEffect, useState } from 'react';

import { DEFAULT_API_ERROR_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerDropDownListSnippetProps } from './QuestionAnswerDropDownListSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerDropDownListMutationVariables,
  ISetUserAnswerMutationGraphQLResponse,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerDropDownList, IQuestionConfigurationDropDownList } from '../../../../interfaces';
import { DropDownListSnippet, IDropDownListOption } from '../../DropDownListSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerDropDownListSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  userFlowStepTrackId,
}: QuestionAnswerDropDownListSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false } = contentSnippet ?? {};
  const { questionResponseDropDownListId } = answer ?? ({} as IQuestionAnswerDropDownList);
  const {
    canModify,
    responseSet: { availableResponses },
  } = configuration ?? ({} as IQuestionConfigurationDropDownList);

  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);
  const [savedQuestionResponseDropDownListId, setSavedQuestionResponseDropDownListId] = useState<number | undefined>(
    questionResponseDropDownListId
  );

  const [executeSetUserAnswerDropDownList] = useEkardoMutation<
    ISetUserAnswerMutationGraphQLResponse,
    ISetUserAnswerDropDownListMutationVariables
  >({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerDropDownList,
    options: {
      onCompleted: () => {
        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            dropdownListId: savedQuestionResponseDropDownListId,
          },
        });

        setMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);
        setMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const handleDropDownListChange = (inputQuestionResponseDropDownListId: string) => {
    if (!contentSnippetFormId) {
      return;
    }

    setSavedQuestionResponseDropDownListId(Number(inputQuestionResponseDropDownListId));

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    executeSetUserAnswerDropDownList({
      variables: {
        ...baseVariables,
        questionResponseDropDownListId: Number(inputQuestionResponseDropDownListId),
      },
    });
  };

  useEffect(() => {
    if (!savedQuestionResponseDropDownListId) {
      return;
    }

    saveContentSnippetQuestionAndApplyQuestionActions({
      actions,
      answer,
      contentPage,
      contentSnippetId,
      dispatch,
      selectedQuestionAnswers: {
        dropdownListId: savedQuestionResponseDropDownListId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdownListItems = availableResponses.map((availableResponse) => {
    const { id, label } = availableResponse;

    const idAsString = id.toString();

    return {
      id: idAsString,
      label,
      value: idAsString,
    } as IDropDownListOption;
  });

  const identifier = `dropdown-list-${contentSnippetId}`;
  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <DropDownListSnippet
      defaultOptionText="Select a response"
      id={identifier}
      isDisabled={!canModify || isSummary}
      isRequired={isMandatory}
      message={message}
      name={identifier}
      options={dropdownListItems}
      selectedValue={(questionResponseDropDownListId || '').toString()}
      onChange={(value: string) => handleDropDownListChange(value)}
    />
  );
};

export { QuestionAnswerDropDownListSnippet };
