import { addWeeks, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subMonths, subWeeks } from 'date-fns';

const getPresetRanges = () => {
  const dateToday = new Date();

  const firstDateLastMonth = subMonths(dateToday, 1);
  const firstDateLastWeek = subWeeks(dateToday, 1);

  const lastDateNextMonth = addMonths(dateToday, 1);
  const lastDateNextWeek = addWeeks(dateToday, 1);

  return {
    'Last month': [firstDateLastMonth, dateToday],
    'Last week': [firstDateLastWeek, dateToday],
    'This week': [startOfWeek(dateToday), endOfWeek(dateToday)],
    'Next week': [dateToday, lastDateNextWeek],
    'This month': [startOfMonth(dateToday), endOfMonth(dateToday)],
    'Next month': [dateToday, lastDateNextMonth],
  };
};

export { getPresetRanges };
