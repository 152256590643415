import React from 'react';

import cx from 'classnames';

import { Icon } from '../Icon';

import { ButtonIconOnlyProps } from './ButtonIconOnly.types';

import './ButtonIconOnly.css';

const ButtonIconOnly = ({
  additionalClassNames,
  iconId,
  isDisabled = false,
  isIconBorderVisible = true,
  isIconSelected = false,
  onClick,
  text,
  type = 'button',
  variant = 'primary-inverse',
}: ButtonIconOnlyProps) => {
  const buttonClassNames = cx('c-button-icon-only', `c-button-icon-only--${variant}`, additionalClassNames, {
    'c-button-icon-only--disabled': isDisabled,
    'c-button-icon-only--no-border': !isIconBorderVisible,
    'c-button-icon-only--selected': isIconSelected,
    'c-button-icon-only--with-border': isIconBorderVisible,
  });

  return (
    // eslint-disable-next-line react/button-has-type
    <button className={buttonClassNames} disabled={isDisabled} type={type} onClick={onClick}>
      <span className="h-hide-visually">{text}</span>
      <Icon className="c-button-icon-only__icon" id={iconId} />
    </button>
  );
};

export { ButtonIconOnly };
