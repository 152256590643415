import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CreateQuestionConfigMessageRequestMutation = gql`
  mutation createQuestionConfigMessageRequest(
    $type: EQuestionConfigurationMessage!
    $questionConfigurationId: Int!
    $message: String!
  ) {
    questionConfiguration {
      defineMessage(
        type: $type
        questionConfigurationId: $questionConfigurationId
        message: $message
      ) {
        id
      }
    }
  }
`;

const createQuestionConfigMessageRequest = ({
  message,
  questionConfigurationId,
  type,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CreateQuestionConfigMessageRequestMutation}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        message,
        questionConfigurationId,
        type,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.questionConfiguration.defineMessage.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createQuestionConfigMessageRequest.propTypes = {
  message: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  questionConfigurationId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

createQuestionConfigMessageRequest.defaultProps = {};

export default createQuestionConfigMessageRequest;
