import gql from 'graphql-tag';

export const DELETE_NOTE_ATTACHMENT = gql`
  mutation DeleteNoteAttachment($noteAttachmentId: Int!) {
    noteAttachment {
      removeNoteAttachment(noteAttachmentId: $noteAttachmentId)
    }
  }
`;

export const DELETE_NOTE_ATTACHMENTS = gql`
  mutation DeleteNoteAttachments($noteAttachmentIds: [Int]!) {
    noteAttachment {
      removeNoteAttachments(noteAttachmentIds: $noteAttachmentIds)
    }
  }
`;

export const DELETE_NOTE_ATTACHMENTS_FOR_NOTE = gql`
  mutation DeleteNoteAttachmentsForNote($noteId: Int!) {
    noteAttachment {
      removeNoteAttachmentsForNote(noteId: $noteId)
    }
  }
`;
