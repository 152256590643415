import React, { Fragment, useEffect, useState } from 'react';

import { getS3SignedUrl } from '@netfront/common-library';
import to from 'await-to-js';

import { ImageSnippetProps } from './ImageSnippet.types';

import { ERROR_MESSAGES } from '../../../core';

import './ImageSnippet.css';

const ImageSnippet = ({ asset: { alt, contentType, s3Key } }: ImageSnippetProps) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const loadImageUrl = async () => {
      const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

      if (getS3SignedUrlError) {
        throw getS3SignedUrlError;
      }

      if (!s3SignedUrl) {
        throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
      }

      setImageUrl(s3SignedUrl);
    };

    loadImageUrl();
  }, [contentType, s3Key]);

  return (
    <Fragment>
      {imageUrl && (
        <div className="c-image-snippet">
          <img alt={alt} className="c-image-snippet__image" src={imageUrl} />
        </div>
      )}
    </Fragment>
  );
};

export { ImageSnippet };
