import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const SEARCH_USER_TYPES = gql`
  query($projectId: String) {
    userType {
      searchUserTypes(projectId: $projectId) {
        name
        userTypeCode
        id
        status
        permission
      }
    }
  }
`;

const getAllUserTypesRequest = ({ projectId, onError, onSuccessResult }) => (
  <Query
    client={Client()}
    query={SEARCH_USER_TYPES}
    variables={{
      projectId,
    }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.userType.searchUserTypes
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAllUserTypesRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default getAllUserTypesRequest;
