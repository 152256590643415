import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dropdown from '../UI/Dropdown/Dropdown';

import { AdvanceTitle } from './styled';

import AppContext from '../../context/AppContext';
import CreateFormContentSnippetRequest from '../../middleware/ContentSnippet/createFormContentSnippetRequest';
import UpdateFormContentSnippetRequest from '../../middleware/ContentSnippet/updateFormContentSnippet';
import SearchContentGroupRequest from '../../middleware/FormContainer/searchContentGroupRequest';

// **************styled Components********************
const Wrapper = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  width: 100%;
`;

const Search = styled.input`
  background-color: unset;
  border: none;
  height: 3em;
  width: 100%;
`;

const AddNew = styled.div`
  align-items: center;
  background: #e4e4e3;
  cursor: pointer;
  display: flex;
  height: 2.8em;
  justify-content: flex-start;
  width: 100%;
`;

const InfoIcon = styled.span`
  background-color: #4bc1c9;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  height: 1em;
  line-height: 13px;
  margin: 0 1em;
  text-align: center;
  width: 1em;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em -0.5em 0 0;
`;

const SearchSuggetionsRow = styled.button`
  background: none;
  border: 1px solid #e1e1e1;
  padding: 5px;
  text-align: left;
`;

const SearchSuggetionsBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 108px;
  overflow-y: scroll;
`;

const DDWrapper = styled.div`
  margin-top: 1rem;
`;

// **************styled Components********************

const typeOptions = [
  {
    id: 0,
    value: 'LIST',
    label: 'List',
  },
  {
    id: 1,
    value: 'TABLE',
    label: 'Table',
  },
];

const FormSnippet = props => {
  const { containerId, onCancel, onRefreshPage, snippet } = props;
  const { handleShowSidebar } = useContext(AppContext);

  const [loadedForms, setLoadedForms] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [title, setTitle] = useState(snippet ? snippet.form.title : '');
  const [searchSuggetions, setSearchSuggetions] = useState([]);
  const [formType, setFromType] = useState(snippet ? snippet.formType : 'LIST');
  const [contentSnippetForm, setContentSnippetForm] = useState(
    snippet
      ? {
          ...snippet,
          id: snippet.form.id,
          recommendedFormType: snippet.form.recommendedFormType,
        }
      : null,
  );
  const [createFormSnippetRequest, setCreateFormSnippetRequest] = useState(false);
  const onSearchContentGroup = e => {
    const { value } = e.target;
    setTitle(value);
    const filtered = allForms.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));
    setSearchSuggetions(filtered);
  };

  const onSearchContentGroupError = () => {
    setLoadedForms(true);
  };

  const onSearchContentGroupSuccessResult = data => {
    setLoadedForms(true);
    setAllForms(data);
  };

  const onSuggetionClick = item => {
    setTitle(item.title);
    setContentSnippetForm(item);
    setFromType('LIST');
    setSearchSuggetions([]);
  };

  const handleUpdate = value => {
    setFromType(typeOptions[value].value);
  };

  const handleAddForm = () => {
    handleShowSidebar([true, 'FORM', containerId]);
  };

  const onErrorCreateFormSnippet = () => {
    setCreateFormSnippetRequest(false);
  };

  const onSuccessCreateFormSnippet = () => {
    setCreateFormSnippetRequest(false);
    onRefreshPage();
  };

  const handleOnSaveCreateForm = () => {
    setCreateFormSnippetRequest(true);
  };

  return (
    <Wrapper>
      <Content>
        <Search onChange={onSearchContentGroup} placeholder="Begin typing to find your Form/Questionaire" value={title} />
        <SearchSuggetionsBox>
          {searchSuggetions.map(item => (
            <SearchSuggetionsRow key={item.id} onClick={() => onSuggetionClick(item)}>
              {item.title}
            </SearchSuggetionsRow>
          ))}
        </SearchSuggetionsBox>
        <AddNew onClick={handleAddForm}>
          <InfoIcon>+</InfoIcon>
          Add new form
        </AddNew>
      </Content>
      {contentSnippetForm && contentSnippetForm.recommendedFormType === 'TABLE' && (
        <DDWrapper>
          <AdvanceTitle>Type</AdvanceTitle>
          <Dropdown
            availableResponses={typeOptions}
            name="formType"
            selectedValue={formType}
            onChangeHandler={(_, value) => handleUpdate(value)}
          />
        </DDWrapper>
      )}

      <ButtonGroup>
        <button className="button--black mt-4 mr-3" onClick={onCancel} type="button">
          Cancel
        </button>
        <button className="button--blue mt-4" onClick={handleOnSaveCreateForm} type="submit">
          Save
        </button>
      </ButtonGroup>
      {!loadedForms && (
        <SearchContentGroupRequest
          contentGroupType="FORM"
          onError={onSearchContentGroupError}
          onSuccessResult={onSearchContentGroupSuccessResult}
        />
      )}
      {createFormSnippetRequest && !snippet && (
        <CreateFormContentSnippetRequest
          containerId={containerId}
          contentSnippetFormId={contentSnippetForm.id}
          isVisible
          onError={onErrorCreateFormSnippet}
          onSuccessResult={onSuccessCreateFormSnippet}
        />
      )}
      {createFormSnippetRequest && snippet && Object.keys(snippet).length > 0 && (
        <UpdateFormContentSnippetRequest
          contentSnippetFormId={contentSnippetForm.id}
          formType={formType}
          id={snippet.id}
          onError={onErrorCreateFormSnippet}
          onSuccessResult={onSuccessCreateFormSnippet}
        />
      )}
    </Wrapper>
  );
};

FormSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  snippet: PropTypes.shape({
    form: PropTypes.shape({
      id: PropTypes.number,
      recommendedFormType: PropTypes.string,
      title: PropTypes.string,
    }),
    formType: PropTypes.string,
    id: PropTypes.number,
    sliderId: PropTypes.number,
    sort: PropTypes.number,
  }),
};

FormSnippet.defaultProps = {
  snippet: null,
};

export default FormSnippet;
