import gql from 'graphql-tag';

export const GET_TOPICS_PER_PROJECT = gql`
  query getTopicsPerProject($projectId: String!) {
    topic {
      getTopicsPerProject(projectId: $projectId) {
        id
        title
        description
      }
    }
  }
`;
