import React from 'react';

import { AdvanceItem, AdvanceTitle, SquareButton, Icon, CircleButton, InputTitleBlock, Title } from '../styled';

import deleteIcon from '../../../static/img/icon-bin.svg';
import arrowIcon from '../../../static/img/icon-chevron.svg';
import { createKey } from '../../../utils';
import Tooltip from '../../UI/Tooltip/Tooltips';

const TranscriptInputTab = ({
  createBubbleTextRequest,
  handleBubbleTextRequest,
  handleDeleteTranscript,
  inputTypeMap,
  isShowTranscriptListTab,
  setIsShowTranscriptListTab,
  transcriptConfig,
  updateBubbleTextRequest,
}) => {
  const handleReturnToList = (isSave) => {
    setIsShowTranscriptListTab(!isShowTranscriptListTab);
    if (isSave) {
      handleBubbleTextRequest();
    }
  };

  return (
    <>
      <Title lineHeight="1.9rem" margin="0 0 0.4rem 0">
        {createBubbleTextRequest ? 'Create' : 'Edit'} Transcript
      </Title>
      <InputTitleBlock>
        <div style={{ display: 'flex' }}>
          <CircleButton
            width="1.9rem"
            height="1.9rem"
            backgroundColor="#e1e1e1"
            color="black"
            transform="rotate(90deg)"
            onClick={() => handleReturnToList(false)}
            type="button"
          >
            <Icon title="left arrow" width="1.9rem">
              <img alt="Save transcript" src={arrowIcon} />
            </Icon>
          </CircleButton>
          <Title lineHeight="1.9rem" margin="0 0 0 .9rem">
            Back
          </Title>
        </div>
        <div style={{ display: 'flex' }}>
          <Title color="#505354" lineHeight="1.9rem" margin="0 0.9rem 0 0">
            Save
          </Title>
          <CircleButton
            width="1.9rem"
            height="1.9rem"
            backgroundColor="#e1e1e1"
            color="black"
            transform="rotate(270deg)"
            onClick={() => handleReturnToList(true)}
            type="button"
          >
            <Icon title="right arrow" width="1.9rem">
              <img alt="Back to transcript list" src={arrowIcon} />
            </Icon>
          </CircleButton>
          {updateBubbleTextRequest && (
            <SquareButton height="1.9rem" width="1.9rem" margin="0 0 0 0.9rem" type="button" onClick={handleDeleteTranscript}>
              <img src={deleteIcon} alt="Delete the transcript" />
            </SquareButton>
          )}
        </div>
      </InputTitleBlock>
      {transcriptConfig.map(({ title, tooltip, inputType, ...otherTranscriptConfigProps }) => {
        const id = createKey(title);
        const InputComponent = inputTypeMap[inputType];

        return (
          <AdvanceItem key={id}>
            <AdvanceTitle>
              <Title>{title}</Title>
              <Tooltip text={tooltip} />
            </AdvanceTitle>
            <InputComponent {...otherTranscriptConfigProps} id={id} name={`${inputType}-${id}`} />
          </AdvanceItem>
        );
      })}
    </>
  );
};

export default TranscriptInputTab;
