import { format } from 'date-fns';

import { LayoutContainerConst } from '../components/LayoutContainer/LayoutContainerConst';

export const assetType = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  CODE: 'CODE',
  DOCUMENT: 'DOCUMENT',
};

export const ContentGroupTypeEnum = {
  TRAINING: 'TRAINING',
  CARTOON: 'CARTOON',
  PAGE: 'PAGE',
  HELP: 'HELP',
};

export const ContentSectionEnum = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const ContentStatusEnum = {
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED',
};

export const StatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
};

export const checkTokenValidity = (tokenObj) => {
  if (Object.keys(tokenObj).length === 0) {
    return false;
  }
  // let expiresUtc = JSON.parse(tokenObj);
  // let expiresUtc = tokenObj;
  // if (expiresUtc) {
  //   expiresUtc = stringToDate(expiresUtc.expireUtc);
  // }

  const getTokenRequest = true;

  // if (expiresUtc - new Date() <= 0) {
  //   getTokenRequest = false;
  // }
  return getTokenRequest;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const convertToURL = (str) =>
  str
    .trim()
    .replace(/[^0-9a-zA-Z\- ]/g, '')
    .toLowerCase()
    .split(' ')
    .join('-');

export const dateUtil = (date) => {
  const extract = date.split('T')[0].split('-');
  const year = extract[0];
  const month = extract[1];
  const day = extract[2];
  return `${day}/${month}/${year.substring(2, 4)}`;
};

export const downloadFileByUrl = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.click();
};

export const dynamicSort = (prop) => {
  let sortOrder = 1;
  let subProperty = prop;
  if (prop[0] === '-') {
    sortOrder = -1;
    subProperty = subProperty.substr(1);
  }
  return (a, b) => {
    if (a[subProperty] < b[subProperty]) {
      return -1 * sortOrder;
    }
    if (a[subProperty] > b[subProperty]) {
      return 1 * sortOrder;
    }
    return 0;
  };
};

export function getApiErrorMessages(error) {
  const { graphQLErrors = [], networkError = {} } = error || {};

  const apiErrorMessages = networkError
    ? networkError.result.errors.map(({ message }) => message)
    : graphQLErrors.map((graphQLError) => graphQLError.message);

  return apiErrorMessages.filter((apiErrorMessage) => apiErrorMessage);
}

export function getBreadCrumbPath(path, numberOfPathSegmentsToRemove, title) {
  const delimiter = '/';
  const pathParts = path.split(delimiter);

  return [
    {
      path: pathParts.splice(0, pathParts.length - numberOfPathSegmentsToRemove).join(delimiter),
      title,
    },
  ];
}

export const getContainers = (layout) => {
  let containers;
  switch (layout) {
    case LayoutContainerConst.PLANE:
      containers = [{ sort: 0 }];
      break;
    case LayoutContainerConst.ONE_BY_TWO:
    case LayoutContainerConst.ONE_BY_TWO_LEFT_BAR:
    case LayoutContainerConst.ONE_BY_TWO_RIGHT_BAR:
    case LayoutContainerConst.ONE_BY_TWO_RIGHT:
    case LayoutContainerConst.ONE_BY_TWO_LEFT:
      containers = [{ sort: 0 }, { sort: 1 }];
      break;
    case LayoutContainerConst.ONE_BY_THREE_RIGHT:
    case LayoutContainerConst.ONE_BY_THREE:
    case LayoutContainerConst.ONE_BY_THREE_LEFT:
      containers = [{ sort: 0 }, { sort: 1 }, { sort: 2 }];
      break;
    case LayoutContainerConst.ONE_BY_FOUR:
      containers = [{ sort: 0 }, { sort: 1 }, { sort: 2 }, { sort: 3 }];
      break;
    default:
      containers = [];
      break;
  }

  return containers;
};

export function getDateWithoutTime(date) {
  const newDate = date ? new Date(date) : new Date();
  return format(newDate, 'yyyy-MM-dd');
}

export function getFilteredResults(dataArray, keys, value) {
  return dataArray.filter((item) => {
    const searchValues = keys.map((key) => {
      const keyParts = key.split('.');
      const itemUsingQualifiedKeys = keyParts.reduce((accumulator, currentKey) => accumulator[currentKey], item);
      return itemUsingQualifiedKeys.toString().toLowerCase();
    });
    return searchValues.some((searchValue) => searchValue.indexOf(value.trim().toLowerCase()) > -1);
  });
}

export const getQueryStringValue = (location, key) =>
  decodeURIComponent(
    location.search.replace(
      new RegExp(
        `^(?:.*[&\\?]${encodeURIComponent(key).replace(
          // eslint-disable-next-line no-useless-escape
          /[\.\+\*]/g,
          '\\$&',
        )}(?:\\=([^&]*))?)?.*$`,
        'i',
      ),
      '$1',
    ),
  );

export const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');
  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`;
  }
  return newUrl;
};

export const graphqlErrorToString = (error) => error.toString().replace('Error: GraphQL error: ', '');

export const inWords = (number) => {
  const strLength = number.toString().length;
  if (strLength >= 2 && strLength <= 6) {
    return number;
  }
  if (strLength >= 7 && strLength <= 8) {
    return `${parseInt(number / 1000000, 10)} million`;
  }
  if (strLength >= 9 && strLength <= 10) {
    return `${parseInt(number / 1000000000, 10)} billion`;
  }
  return 'unlimited';
};

export const isStringEmpty = (str) => !(str || '').trim();

export const makeId = (l) => {
  let text = '';
  const charList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < l; i++) {
    text += charList.charAt(Math.floor(Math.random() * charList.length));
  }
  return text;
};

export const removeByAttr = (arr, attr, value) => {
  const filteredPeople = arr.filter((item) => item[attr] !== value);
  return filteredPeople;
};

export const searchByFieldInArray = (array, key, searchText) =>
  array.filter((item) => String(item[key]).toLowerCase().localeCompare(searchText.toLowerCase()) !== -1);

export const sort = (compareFunction, array) => array.slice().sort(compareFunction);

export const sortUtil = (compareFunction, array) => array.slice().sort(compareFunction);

export const stringToDate = (date) => {
  const finalDate = date.split('/');
  return new Date(finalDate[2], finalDate[1], finalDate[0]);
};

export const urlGenerator = (name) => {
  let newName = '';
  if (name) {
    newName = name.match(/[a-zA-Z\d' ']/g).join('');
    newName = newName.replace(/\s+/g, '-').toLowerCase();
  }
  return newName;
};

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return !!pattern.test(str);
};

export const validUrl = (string) => {
  const uriString = string.replace(/[^A-Z0-9]+/gi, '-');
  const firstChar = uriString.split('')[0];
  const lastChar = uriString.split('')[uriString.length - 1];

  if (firstChar === '-') {
    return uriString.slice(1);
  }

  if (lastChar === '-') {
    return uriString.slice(0, -1);
  }

  return uriString;
};

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_TOAST_OPTIONS = {
  duration: 3000,
  ariaProps: {
    'aria-live': 'polite',
    role: 'status',
  },
};
