import React, { useState } from 'react';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const MenuGroup = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 15,
    '&$expanded': {
      minHeight: 15,
    },
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    padding: '0.5rem',
  },
}))(MuiAccordionSummary);

const MenuGroupItems = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 20,
    '&$expanded': {
      minHeight: 20,
    },
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

const getRoute = route => {
  const routeItems = route.split('/');
  return routeItems[routeItems.length - 1];
};

const generateMenuItem = (currentRoute, menuItem, menuOptionIndex, menuItemIndex) => {
  const { route, text } = menuItem;

  const menuItemKey = `menu-option-item-${menuOptionIndex}-${menuItemIndex}`;

  const textElement = getRoute(currentRoute) === getRoute(route) ? <b>{text}</b> : text;

  return (
    <div key={menuItemKey}>
      <Link to={route}>{textElement}</Link>
    </div>
  );
};

const GenerateAccordianMenu = (currentRoute, menuOption, menuOptionIndex) => {
  const menuOptionKey = `menu-option-${menuOptionIndex}`;
  const expandMoreIcon = <ExpandMoreIcon />;

  const [expanded, setExpanded] = useState('');

  const handleChange = key => (event, newExpanded) => {
    setExpanded(newExpanded ? key : false);
  };

  const menuGroupItems = menuOption.items.map((menuItem, menuItemIndex) =>
    generateMenuItem(currentRoute, menuItem, menuOptionIndex, menuItemIndex),
  );

  return (
    <Accordion expanded={expanded === menuOptionKey} key={menuOptionKey} onChange={handleChange(menuOptionKey)} square>
      <MenuGroup aria-controls={`${menuOptionKey}-content`} expandIcon={expandMoreIcon} id={`${menuOptionKey}-header`}>
        {menuOption.group}
      </MenuGroup>
      {menuGroupItems && <MenuGroupItems>{menuGroupItems}</MenuGroupItems>}
    </Accordion>
  );
};

const AccordionMenu = props => {
  const { currentRoute, menuOptions } = props;

  return menuOptions.map((menuOption, menuOptionIndex) => GenerateAccordianMenu(currentRoute, menuOption, menuOptionIndex));
};

AccordionMenu.propTypes = {
  currentRoute: PropTypes.string,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          route: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ),
};

export default AccordionMenu;
