import gql from 'graphql-tag';

export const GET_PAGINATED_ASSETS = gql`
  query getPaginatedAssets($projectId: String, $type: [EAssetType], $first: Int, $after: String) {
    asset {
      searchPaginatedAssets(type: $type, projectId: $projectId, first: $first, after: $after) {
        totalCount
        edges {
          cursor
          node {
            assetId
            contentType
            fileName
            projectId
            s3Key
            uploaded
            alt
            tags
            xAxisFocus
            type
            fileName
            transcript
            publicS3Key
            isPublic
            publicUrl
            presignedUrl
          }
        }
      }
    }
  }
`;
