import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_FAV_ASSETS = gql`
  query getAllFavAssetsRequest($assetType: [EAssetType]!) {
    user {
      searchFavoriteAssets(assetType: $assetType)
    }
  }
`;

const getAllFavAssetsRequest = ({ assetType, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ALL_FAV_ASSETS} variables={{ assetType }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.user.searchFavoriteAssets}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAllFavAssetsRequest.propTypes = {
  assetType: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAllFavAssetsRequest;
