import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const DELETE_CONTENT_CONTAINER = gql`
  mutation deleteContentContainer(
    $sectionId: Int!
    $containerId: Int!
    $contentLayoutId: Int!
  ) {
    contentSection {
      deleteContentSectionContainer(
        sectionContainer: {
          sectionId: $sectionId
          containerId: $containerId
          contentLayoutId: $contentLayoutId
        }
      ) {
        id
      }
    }
  }
`;

const deleteContainerRequest = ({
  sectionId,
  containerId,
  contentLayoutId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={DELETE_CONTENT_CONTAINER}>
    {(createSection, { loading, error, data }) => {
      const variables = {
        sectionId,
        containerId,
        contentLayoutId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSection.deleteContentSectionContainer);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createSection}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

deleteContainerRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  contentLayoutId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
};

deleteContainerRequest.defaultProps = {};

export default deleteContainerRequest;
