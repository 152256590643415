import React, { useRef, useState } from 'react';

import { FaBell } from 'react-icons/fa';
import styled from 'styled-components';

import { DropDownMenu } from '../DropDownMenu/DropDownMenu';

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  position: relative;
  top: -0.125rem;

  svg {
    cursor: pointer;
    fill: #fff;
    height: 1.2em;
    width: 1.2em;
  }
`;

const ListItem = styled.li`
  margin-left: 0;
`;

export default function NotificationMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleMenuToggle = () => setIsOpen(!isOpen);

  const handleTarget = () => {
    document.addEventListener('click', (e) => {
      if (!ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    });
  };

  return (
    <Container ref={ref} onClick={handleTarget}>
      <FaBell onClick={handleMenuToggle} />
      <DropDownMenu open={isOpen}>
        <ListItem>No notifications</ListItem>
      </DropDownMenu>
    </Container>
  );
}
