import React from 'react';

function Header() {
  //   'button--cta': true,
  //   'button--outline': location === locations[0],
  //   'button--red': location === locations[1],
  //   'button--green': !locations.includes(location),
  // });

  //   'button--cta': true,
  //   'button--red': location === locations[0],
  //   'button--outline': location !== locations[0],
  // });

  return (
    <header>
      {/* <div className="header__logo">
        <Link to="/">
          <img
            alt="Ekardo logo"
            aria-label="Ekardo logo"
            src={`${getAssetsUrl()}/logo/logo-main.svg`}
          />
        </Link>
      </div>

      <Media query="(min-width: 800px)">
        {matches =>
          matches ? (
            <>
              <div className={`header header--${theme}`}>
                <Nav active={active} location={location} />
              </div>
              <div className="header__cta">
                <Link className={signInButtonClassName} to="/login">
                  Sign In
                </Link>

                <Link className={registerButtonClassName} to="/register">
                  Sign Up
                </Link>
              </div>
            </>
          ) : (
            <MobileMenu />
          )
        }
      </Media> */}
    </header>
  );
}

// Header.propTypes = {
//   // eslint-disable-next-line react/boolean-prop-naming
//   active: PropTypes.string,
//   location: PropTypes.string,
//   theme: PropTypes.string,
// };

// Header.defaultProps = {
//   active: null,
//   location: null,
//   theme: null,
// };

export default Header;
