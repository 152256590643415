import { gql } from '@apollo/client';

const START_INSTANCE_DATE_USER_FLOW_STEP_MUTATION = gql`
  mutation startInstanceDateUserFlowStep($instanceDate: Date!, $userFlowStepId: Int!) {
    userFlowStepTrack {
      startInstanceDateUserFlowStep(instanceDate: $instanceDate, stepId: $userFlowStepId) {
        id
      }
    }
  }
`;

export { START_INSTANCE_DATE_USER_FLOW_STEP_MUTATION };
