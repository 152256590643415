import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import ImageUploader from '../UI/AssetUploader/ImageUploader';
import Input from '../UI/Input/InputWhite';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { EditBtn, Item, Logo, Preview, Title, TitleText } from './styled';

import { GET_ASSET } from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import { createImageMapMutation } from '../../middleware/ImageMap/createImageMap';
import { updateImageMapMutation } from '../../middleware/ImageMap/updateImageMap';

const ImageMapSideBar = (props) => {
  const { closeSideBar, detail, onUpdate } = props;
  const isEditMode = Boolean(detail);
  const [hasErrors, setHasErrors] = useState({
    title: false,
    asset: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { projectId, orgId } = useParams();
  const [isEditLogoMode, setIsEditLogoMode] = useState(false);
  const [item, setItem] = useState(detail || { title: '' });
  const history = useHistory();
  const [getAssetInfo, { data: assetData }] = useLazyQuery(GET_ASSET, {
    client,
  });

  useEffect(() => {
    if (item.iconAssetId) getAssetInfo({ variables: { assetId: item.iconAssetId } });
  }, []);

  const [update] = useMutation(updateImageMapMutation, {
    client,
  });

  const [create] = useMutation(createImageMapMutation, {
    client,
  });

  const handleError = (err) => {
    setErrorMessage(err.toString());
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const isValid = () => {
    setHasErrors({
      ...hasErrors,
      title: item.title === '',
      assetId: item.iconAssetId === '',
    });
    return item.title !== '' && item.iconAssetId !== '';
  };

  const createImageMap = () => {
    if (isValid()) {
      create({
        variables: {
          title: item.title,
          iconAssetId: item.iconAssetId,
          projectId,
        },
      })
        .then(({ data }) => {
          history.push(
            `/dashboard/${orgId}/project/${projectId}/image-map-builder/${data.contentGroup.createContentGroup.id}?library=true`,
          );
        })
        .catch(handleError);
    }
  };

  const updateImageMap = () => {
    if (isValid()) {
      update({
        variables: {
          title: item.title,
          iconAssetId: item.iconAssetId,
          imageMapId: item.id,
        },
      })
        .then(() => {
          onUpdate();
        })
        .catch(handleError);
    }
  };

  const handleSave = () => (!isEditMode ? createImageMap() : updateImageMap());

  const handleUpdate = (value, e) => {
    setItem({
      ...item,
      [e.target.name]: value,
    });
  };

  const getLogoData = (asset) => {
    setIsEditLogoMode(false);
    if (item.iconAssetId !== asset.assetId) getAssetInfo({ variables: { assetId: asset.assetId } });
    setItem((currentState) => ({
      ...currentState,
      iconAssetId: asset.assetId,
    }));
  };

  if (!item) return null;

  return (
    <RightNavBar onCancel={closeSideBar} onSave={handleSave}>
      <h3 className="h-hide-visually">{isEditMode ? 'Edit' : 'Create'} Interactive Regions</h3>
      <Item>
        <Input isChanged={handleUpdate} name="title" text={item.title} />
        {hasErrors.title ? <p className="error-message">Title cannot be empty</p> : null}
      </Item>
      <Item>
        <Title>
          <TitleText>Image</TitleText>
          <Tooltip text="The image you want to use" />
        </Title>
        {item.iconAssetId && !isEditLogoMode ? (
          <Preview>
            <EditBtn
              onClick={() => {
                setIsEditLogoMode(true);
              }}
            >
              Change the image
            </EditBtn>
            <Logo src={assetData && getSignedUrl(assetData.asset.getAsset.s3Key, assetData.asset.getAsset.contentType)} />
          </Preview>
        ) : (
          <ImageUploader getData={getLogoData} name="bgImg" projectId={projectId} type="IMAGE" />
        )}
        {hasErrors.asset ? <p className="error-message">Please upload an image</p> : null}
      </Item>
      {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
    </RightNavBar>
  );
};

ImageMapSideBar.propTypes = {
  closeSideBar: PropTypes.func.isRequired,
  detail: PropTypes.shape({
    title: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
};

ImageMapSideBar.defaultProps = {
  detail: null,
  onUpdate: () => undefined,
};

export default ImageMapSideBar;
