import { DBQuestionConfigurationMultiBehaviorType, IQuestionAnswerMultiResponseTextItem } from '../../../interfaces';

const hasMinimumResponsesRequired = (
  behaviour: DBQuestionConfigurationMultiBehaviorType,
  minResponse: number,
  responses: IQuestionAnswerMultiResponseTextItem[]
): boolean => {
  if (behaviour === 'AUTO_SAVE') {
    return true;
  }

  return responses.filter(({ status }) => status !== 'NEW').filter(({ value }) => Boolean(value.length)).length >= minResponse;
};

const getOriginalResponsesAfterCancel = (
  behaviour: DBQuestionConfigurationMultiBehaviorType,
  currentSavedResponses: IQuestionAnswerMultiResponseTextItem[],
  minResponse: number,
  originalSavedResponse: IQuestionAnswerMultiResponseTextItem,
  selectedIndex: number
): IQuestionAnswerMultiResponseTextItem[] => {
  const savedResponse = currentSavedResponses[selectedIndex];
  const shouldRemoveResponse = hasMinimumResponsesRequired(behaviour, minResponse, currentSavedResponses);

  if (savedResponse.status === 'NEW') {
    return shouldRemoveResponse
      ? currentSavedResponses.filter((_, index) => selectedIndex !== index)
      : currentSavedResponses.map((currentSavedResponse, index) =>
          selectedIndex === index ? { ...currentSavedResponse, value: '' } : currentSavedResponse
        );
  }

  return currentSavedResponses.map((currentSavedResponse, index) =>
    selectedIndex === index ? originalSavedResponse : currentSavedResponse
  );
};

export { getOriginalResponsesAfterCancel, hasMinimumResponsesRequired };
