import { gql } from '@apollo/client';

const END_CONTENT_PAGE_COMPLETION_MUTATION = gql`
  mutation endContentPageCompletion($contentPageCompletionId: Int!) {
    userFlowStepTrack {
      endContentPageCompletion(trackingId: $contentPageCompletionId, status: COMPLETED) {
        id
      }
    }
  }
`;

export { END_CONTENT_PAGE_COMPLETION_MUTATION };
