// TODO: Is it worth splitting out the ContentPageContext into separate contexts such as:
//  - ContentSectionContext
//  - ContentSectionContainerContext
//  - ContentSnippetContext

import React, { createContext, useContext } from 'react';

import { IContextProvider } from '@netfront/common-library';
import { useImmerReducer } from 'use-immer';

import { IContentPageContext } from './ContentPageContext.interfaces';
import { contentPageReducer } from './ContentPageContext.reducer';

import { IContentPage } from '../../interfaces';

const ContentPageContext = createContext<IContentPageContext | undefined>(undefined);

const ContentPageProvider = ({ children, initialState }: IContextProvider<IContentPage>) => {
  const [state, dispatch] = useImmerReducer(contentPageReducer, initialState);

  const value = { dispatch, state };

  return <ContentPageContext.Provider value={value}>{children}</ContentPageContext.Provider>;
};

const useContentPageContext = (): IContentPageContext => {
  const contentPageContext = useContext(ContentPageContext);
  if (!contentPageContext) {
    throw new Error('useContentPageContext must be used within a ContentPageProvider');
  }

  return contentPageContext;
};

export { ContentPageProvider, useContentPageContext };
