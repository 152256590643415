import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import PropTypes from 'prop-types';

import ImageUploader from '../AssetUploader/ImageUploader';
import ColorInput from '../ColorPicker/ColorInput';
import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/InputWhite';
import Tooltip from '../Tooltip/Tooltips';

import client from '../../../middleware/client';
import { styleTypeOptions, triggerStyleTypeOptions, typeOptions, directionOptions } from '../../RightNavBar/DropdownOptions';
import { Title, ColorItem, Container, Item, EditBtn, Preview, Logo } from '../../RightNavBar/styled';

export const GET_HORIZONTAL_ALIGNMENT = gql`
  query {
    __type(name: "EHorizontalAlignment") {
      enumValues {
        name
      }
    }
  }
`;

const EventPart = (props) => {
  const { projectId, updateEventDetail, currentDetail, icon, setIcon } = props;

  const [alignmentOptions, setAlignmentOptions] = useState([]);
  const [editLogo, setEditLogo] = useState(false);
  const [eventDetail, setEventDetail] = useState(currentDetail);

  const { loading, data } = useQuery(GET_HORIZONTAL_ALIGNMENT, {
    client,
  });

  useEffect(() => {
    if (!loading && data) {
      setAlignmentOptions(
        data.__type.enumValues.map((value, index) => ({
          id: index,
          value: value.name,
          label: value.name.toLowerCase().charAt(0).toUpperCase() + value.name.toLowerCase().slice(1),
        })),
      );
    }
  }, [data]);

  const handleEventUpdate = (value, e) => {
    const newDetail = {
      ...eventDetail,
      [e.target.name]: value,
    };

    setEventDetail(newDetail);
    updateEventDetail(newDetail);
  };

  const getLogoData = (asset) => {
    setEditLogo(false);
    setIcon(asset.presignedUrl);
    updateEventDetail({
      ...eventDetail,
      assetId: asset.assetId,
      asset,
    });
    setEventDetail({
      ...eventDetail,
      assetId: asset.assetId,
      asset,
    });
  };

  useEffect(() => {
    setEventDetail(currentDetail);
  }, [currentDetail]);

  return (
    <Container>
      <Item>
        <Title>
          Event
          <Tooltip text="Define how content will displayed to the user" />
        </Title>
        <Dropdown
          availableResponses={typeOptions}
          name="type"
          selectedValue={eventDetail.type}
          onChangeHandler={(_, value, event) => handleEventUpdate(value, event)}
        />
      </Item>
      {eventDetail.type && eventDetail.type !== 'NONE' && (
        <div>
          <Item>
            <Title>
              Trigger type
              <Tooltip text="Define the display type for the trigger" />
            </Title>
            <Dropdown
              availableResponses={styleTypeOptions}
              name="style"
              selectedValue={eventDetail.style}
              onChangeHandler={(_, value, event) => handleEventUpdate(value, event)}
            />
          </Item>
          {(eventDetail.style === 'TEXT_AND_ICON' || eventDetail.style === 'TEXT_ONLY') && (
            <Item>
              <Title>
                Label
                <Tooltip text="Text content to trigger the action" />
              </Title>
              <Input isChanged={handleEventUpdate} name="title" text={eventDetail.title} />
            </Item>
          )}
          {eventDetail.type === 'REVEAL' && alignmentOptions && (
            <Item>
              <Title>
                Alignment
                <Tooltip text="Horizontal alignment of the trigger" />
              </Title>
              <Dropdown
                availableResponses={alignmentOptions}
                name="iconHorizontalAlignment"
                selectedValue={eventDetail.iconHorizontalAlignment}
                onChangeHandler={(_, value, event) => handleEventUpdate(value, event)}
              />
            </Item>
          )}
          <>
            <Title>
              Direction
              <Tooltip text="The location the trigger will end after the action is complete" />
            </Title>
            <Dropdown
              availableResponses={directionOptions}
              name="eventDirection"
              selectedValue={eventDetail.eventDirection}
              onChangeHandler={(_, value, event) => handleEventUpdate(value, event)}
            />
          </>
          {eventDetail.style !== 'ICON_ONLY' && eventDetail.type === 'REVEAL' && (
            <Item>
              <Title>
                Trigger icon
                <Tooltip text="Predefined trigger icon used with the label" />
              </Title>
              <Dropdown
                availableResponses={triggerStyleTypeOptions}
                name="triggerStyle"
                selectedValue={eventDetail.triggerStyle}
                onChangeHandler={(_, value, event) => handleEventUpdate(value, event)}
              />
            </Item>
          )}
          {eventDetail.type !== 'NONE' && (
            <div>
              <ColorItem>
                <Title>Border colour</Title>
                <ColorInput currentColor={eventDetail.borderColor} getColor={handleEventUpdate} id="color-1" name="borderColor" />
              </ColorItem>
              <ColorItem>
                <Title>Background colour</Title>
                <ColorInput currentColor={eventDetail.backgroundColor} getColor={handleEventUpdate} id="color-2" name="backgroundColor" />
              </ColorItem>
              <ColorItem>
                <Title>
                  <b>Title colour</b>
                </Title>
                <ColorInput currentColor={eventDetail.titleColor} getColor={handleEventUpdate} id="color-3" name="titleColor" />
              </ColorItem>
              {eventDetail.style !== 'TEXT_ONLY' && (
                <Item>
                  <Title>
                    <strong>Icon</strong>
                    <Tooltip text="Optional custom icon displayed next to the label" />
                  </Title>
                  {(icon || eventDetail.asset) && !editLogo ? (
                    <Preview>
                      <EditBtn
                        onClick={() => {
                          setEditLogo(true);
                        }}
                      >
                        Change the image
                      </EditBtn>
                      <Logo alt="logo" src={icon || eventDetail.asset.presignedUrl} />
                    </Preview>
                  ) : (
                    <ImageUploader getData={getLogoData} name="bgImg" projectId={projectId} type="IMAGE" />
                  )}
                </Item>
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

EventPart.propTypes = {
  currentDetail: PropTypes.shape({}).isRequired,
  projectId: PropTypes.string.isRequired,
  updateEventDetail: PropTypes.func.isRequired,
  icon: PropTypes.string,
  setIcon: PropTypes.func,
};

EventPart.defaultProps = {
  icon: null,
  setIcon: () => {},
};

export default EventPart;
