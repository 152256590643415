import React from 'react';

import cx from 'classnames';

import { IconProps } from './Icon.types';

/**
 * Used to insert an SVG icon into other components.
 */
const Icon = ({ className, hasAriaHidden = true, id }: IconProps) => {
  const iconClassNames = cx('c-icon', className);

  return (
    <svg aria-hidden={hasAriaHidden} className={iconClassNames} data-testid="qa-icon" focusable="false">
      <use data-testid="qa-icon-use" xlinkHref={`#${id}`} />
    </svg>
  );
};

export { Icon };
