import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_FORM_CONTENT_SNIPPET = gql`
  mutation updateContentSnippet(
    $id: Int!
    $contentSnippetFormId: Int!
    $formType: EFormType
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $id }
        contentSnippetForm: {
          contentSnippetFormId: $contentSnippetFormId
          formType: $formType
        }
      ) {
        id
        sort
      }
    }
  }
`;

const updateFormContentSnippet = ({
  id,
  contentSnippetFormId,
  formType,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_FORM_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        id,
        contentSnippetFormId,
        formType,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.contentSnippet.updateContentSnippet.id,
          data.contentSnippet.updateContentSnippet.sort
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateFormContentSnippet.propTypes = {
  contentSnippetFormId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  formType: PropTypes.string,
};

updateFormContentSnippet.defaultProps = {
  formType: 'LIST',
};

export default updateFormContentSnippet;
