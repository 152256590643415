import React from 'react';

import cx from 'classnames';
import isNil from 'lodash.isnil';

import { Icon } from '../Icon';
import { Label } from '../Label';

import { SelectProps } from './Select.types';

import './Select.css';

const Select = ({
  additionalClassNames,
  errorMessage,
  id,
  isDisabled = false,
  isLabelHidden,
  isRequired = false,
  labelDefaultText = 'Select an option',
  labelText,
  name,
  onChange,
  options,
  value,
}: SelectProps) => {
  const labelClassNames = cx('c-select-list__label', {
    'c-select-list__label--is-required': isRequired && !value,
  });

  const selectClassNames = cx(
    'c-select-list__input',
    additionalClassNames,
    { 'c-select-list__input--is-disabled': isDisabled },
    { 'c-select-list__input--is-required': isRequired && !value }
  );

  return (
    <div className="c-input-field">
      <Label additionalClassNames={labelClassNames} forId={id} isHidden={isLabelHidden} isRequired={isRequired} labelText={labelText} />
      <span className="c-select-list">
        <select
          aria-describedby={errorMessage && `id_${name}_error`}
          aria-invalid={Boolean(errorMessage)}
          className={selectClassNames}
          data-testid="qa-select"
          disabled={isDisabled}
          id={id}
          name={name}
          required={isRequired}
          value={value}
          onChange={onChange}
        >
          {isNil(value) && <option data-testid="qa-select-default-option" label={labelDefaultText} />}
          {options.map(({ isDisabled: isOptionDisabled, id: optionId, name: optionName, value: optionValue }) => (
            <option
              key={`${optionName}-${optionId}`}
              data-testid="qa-select-options"
              disabled={isOptionDisabled}
              id={String(optionId)}
              value={optionValue}
            >
              {optionName}
            </option>
          ))}
        </select>
        <Icon className="c-select-list__icon" id="id_caret_icon" />
      </span>
      {/* TODO: ErrorMessage component */}
      {errorMessage && !value && (
        <span className="c-input-field__error-message" id={`id_${name}_error`}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export { Select };
