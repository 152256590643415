import gql from 'graphql-tag';

export const CREATE_DIRECTORY = gql`
  mutation createDirectory($directory: CreateDirectoryInput!) {
    directory {
      createDirectory(directory: $directory) {
        id
        address {
          city
          country
          line1
          latitude
          longitude
          postcode
          state
          suburb
        }
        asset {
          assetId
          contentType
          fileName
          s3Key
          type
        }
        description
        directoryType {
          id
          name
        }
        directoryTypeId
        friendlyUrl
        phoneNumber
        tags
        title
        status
        subTitle
        url
      }
    }
  }
`;
