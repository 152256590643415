import { useRef } from 'react';

import { LazyQueryHookOptions, OperationVariables, QueryTuple, useLazyQuery } from '@apollo/client';

import { IUseApolloLazyQueryOptions } from './useApolloLazyQuery.interfaces';

import { getApolloClient } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useApolloLazyQuery = <TData = any, TVariables = OperationVariables | undefined>({
  apiName,
  apolloLink,
  options,
  query,
  requestCredentials,
}: IUseApolloLazyQueryOptions<TData, TVariables>): QueryTuple<TData, TVariables> => {
  const client = useRef(
    getApolloClient({
      apiName,
      apolloLink,
      requestCredentials,
    }),
  ).current;

  const updatedOptions: LazyQueryHookOptions<TData, TVariables> = {
    ...options,
    client,
  };

  return useLazyQuery(query, updatedOptions);
};

export { useApolloLazyQuery };
