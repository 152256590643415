import { AssetType, IconIdType } from '../../../types';

const ASSET_TYPE_ICON_MAP: Record<AssetType, IconIdType> = Object.freeze({
  audio: 'id_audio_asset_icon',
  document: 'id_file_asset_icon',
  image: 'id_image_asset_icon',
  video: 'id_video_asset_icon',
});

export { ASSET_TYPE_ICON_MAP };
