import React, { useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { RangeInput } from '@netfront/ui-library';
import { useDebouncedCallback } from 'use-debounce';

import { ADD_FEEDBACK_RANGE_MULTI_RESPONSE_TEXT_MUTATION } from './SummaryFeedbackConfigurationMultiResponseText.graphql';
import { ISummaryFeedbackConfigurationMultiResponseTextProps } from './SummaryFeedbackConfigurationMultiResponseText.interfaces';

import { useEkardoMutation } from '../../../../hooks';
import { ISummaryFeedbackConfigurationRange } from '../../../../interfaces';
import { handleApolloError } from '../../QuestionSnippet';

const SummaryFeedbackConfigurationMultiResponseText = ({
  accessToken,
  questionAnswerMultiResponseTextItem: { id, questionAnswerMultiResponseTextId, summaryFeedback },
  summaryFeedbackConfiguration,
}: ISummaryFeedbackConfigurationMultiResponseTextProps) => {
  const { value = 0 } = summaryFeedback ?? {};
  const { contentSnippetSummaryId, maxRange, minRange } = summaryFeedbackConfiguration as ISummaryFeedbackConfigurationRange;

  const [savedSliderValue, setSavedSliderValue] = useState<number>(value);

  const [executeAddFeedbackRangeMultiResponseText] = useEkardoMutation({
    mutation: ADD_FEEDBACK_RANGE_MULTI_RESPONSE_TEXT_MUTATION,
    options: {
      onError: (error) => {
        handleApolloError(error);
      },
    },
    token: accessToken,
  });

  const debouncedMutation = useDebouncedCallback((inputValue: number) => {
    executeAddFeedbackRangeMultiResponseText({
      variables: {
        answerId: questionAnswerMultiResponseTextId,
        responseId: id,
        summaryId: contentSnippetSummaryId,
        value: inputValue,
      },
    });
  }, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

  const handleSliderChange = (inputValues: number[]) => {
    debouncedMutation(inputValues[0]);
    setSavedSliderValue(inputValues[0]);
  };

  return <RangeInput maxValue={maxRange} minValue={minRange} selectedValues={[savedSliderValue]} onChange={handleSliderChange} />;
};

export { SummaryFeedbackConfigurationMultiResponseText };
