import gql from 'graphql-tag';

export const UPDATE_TOPIC = gql`
  mutation updateTopic($postId: Int!, $topicId: Int!) {
    post {
      updateTopic(postId: $postId, topicId: $topicId) {
        id
        topic {
          id
          title
        }
        tags
        authorId
        author {
          id
          displayedName
        }
        message
        commentCount
        comments {
          edges {
            node {
              authorId
              author {
                id
                displayedName
              }
              message
              postId
            }
          }
        }
        createdDate
        createdDateTime
        assets {
          assetId
          s3Key
          contentType
          fileName
        }
      }
    }
  }
`;
