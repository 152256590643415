/* eslint-disable no-nested-ternary */
import React from 'react';

import PropTypes from 'prop-types';

import CreateCheckboxResponsesAnsRequest from '../../middleware/FormContainer/ResponseSet/createCheckboxResponsesAnsRequest';
import CreateDropdownListResponsesAnsRequest from '../../middleware/FormContainer/ResponseSet/createDropdownListResponsesAnsRequest';
import CreateRadioResponsesAnsRequest from '../../middleware/FormContainer/ResponseSet/createRadioResponsesAnsRequest';
import DefineResponseSetRequest from '../../middleware/FormContainer/ResponseSet/defineResponseSetRequest';

export const ResponseRequest = props => {
  const {
    resType,
    isDefineResponseReq,
    onCreateError,
    onCreateSuccess,
    onDefineError,
    onDefineSuccess,
    questConfId,
    responseSet,
    responseSetID,
    isExistingResponseSet,
  } = props;

  return isDefineResponseReq ? (
    // for when selecting an existing response set
    <DefineResponseSetRequest
      onError={onDefineError}
      onSuccessResult={onDefineSuccess}
      questionConfigurationId={questConfId}
      questionResponseSetId={responseSetID}
    />
  ) : // for when creating a new response set
  responseSet.length > 0 && responseSetID && !isExistingResponseSet ? (
    {
      RB: (
        <CreateRadioResponsesAnsRequest
          onError={onCreateError}
          onSuccessResult={onCreateSuccess}
          responseSetId={responseSetID}
          responsesRadio={responseSet}
        />
      ),
      CB: (
        <CreateCheckboxResponsesAnsRequest
          onError={onCreateError}
          onSuccessResult={onCreateSuccess}
          responsesCheckbox={responseSet}
          responseSetId={responseSetID}
        />
      ),
      Dd: (
        <CreateDropdownListResponsesAnsRequest
          onError={onCreateError}
          onSuccessResult={onCreateSuccess}
          responsesDropDownList={responseSet}
          responseSetId={responseSetID}
        />
      ),
    }[resType]
  ) : (
    <></>
  );
};

ResponseRequest.propTypes = {
  isDefineResponseReq: PropTypes.bool.isRequired,
  isExistingResponseSet: PropTypes.bool.isRequired,
  onCreateError: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  onDefineError: PropTypes.func.isRequired,
  onDefineSuccess: PropTypes.func.isRequired,
  resType: PropTypes.string.isRequired,
  responseSet: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      id: PropTypes.number,
    })
  ).isRequired,
  questConfId: PropTypes.number,
  responseSetID: PropTypes.number,
};

ResponseRequest.defaultProps = {
  questConfId: null,
  responseSetID: null,
};
