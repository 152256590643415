import React, { useEffect, useRef } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { format } from 'date-fns';
import isEmpty from 'lodash.isempty';

import { useSocialState } from '../../../context/SocialPage/SocialPageContext';
import { useApolloClient } from '../../../middleware/apollo/useApolloClient';
import { GET_COMMENTS } from '../../../middleware/Social/getCommentsByPost';
import addIcon from '../../../static/img/add-icon.svg';
import { createKey } from '../../../utils';
import ImageIcon from '../../Icon/ImageIcon';
import ListItemEditDelete from '../../List/ListItemEditDelete';
import Tooltip from '../../UI/Tooltip/Tooltips';

import './Views.scss';

const Comments = () => {
  const client = useRef(useApolloClient('bonobo')).current;
  const [pageState, dispatch] = useSocialState();
  const { activePostId: postId, comments } = pageState;

  const [getAllCommentsByPostRequest] = useLazyQuery(GET_COMMENTS, {
    client,
    onCompleted(data) {
      const {
        comment: {
          getComments: { edges: getComments },
        },
      } = data;

      dispatch({
        type: 'setComments',
        newState: getComments,
      });
    },
  });

  const handleOnEdit = (id) => {
    dispatch({
      type: 'setActiveCommentId',
      newState: id,
    });
  };

  const handleAddNewComment = () => {
    dispatch({
      type: 'setIsAddNewComment',
      newState: true,
    });
  };

  useEffect(() => {
    if (!postId) {
      return;
    }

    getAllCommentsByPostRequest({
      variables: {
        postId,
      },
    });
  }, [postId, getAllCommentsByPostRequest]);

  return (
    <>
      <fieldset>
        <legend className="h-hide">Comments</legend>
        <div className="h-flex h-justify-content-between h-align-items-center">
          <div className="h-flex h-align-items-center">
            <label className="c-input-title h-margin-top-sm h-margin-bottom-sm h-margin-right-sm" htmlFor="post input">
              Comments
            </label>
            <button className="c-icon-button" type="button" onClick={handleAddNewComment}>
              <ImageIcon src={addIcon} />
            </button>
          </div>
          <Tooltip text="Posts related to comments" />
        </div>

        {isEmpty(comments) ? (
          <div className="c-no-items-block c-item-border">
            <p className="c-no-item-text">
              Click the <ImageIcon src={addIcon} /> icon above to add a comment
            </p>
          </div>
        ) : (
          <ul className="c-list-container">
            {comments.map(({ node: { message, id, author, createdDateTime } }) => {
              const { firstName, lastName } = author;
              const dateTime = new Date(createdDateTime);
              const createdDate = format(dateTime, 'Pp');

              return (
                <div key={`post-comment-${createKey(String(id))}`} className="h-box-drop-shadow h-margin-top-sm h-margin-bottom-sm">
                  <ListItemEditDelete text={message} isTextFewLines onEdit={() => handleOnEdit(id)} />
                  <div className="h-flex  c-item-padding">
                    <div>
                      <span className="c-list-item-info-text h-bold-text h-margin-right-sm">{`${firstName}  ${lastName}`}</span>
                    </div>
                    <div>
                      <span className="c-list-item-info-text c-light-text">{createdDate}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </ul>
        )}
      </fieldset>
    </>
  );
};

export default Comments;
