import { gql } from '@apollo/client';

const GET_ALL_TRACKING_OF_STEP_QUERY = gql`
  query getAllTrackingOfStep($startDate: Date!, $endDate: Date!, $userFlowStepId: Int!, $questionIds: [Int]) {
    userFlowStepTrack {
      getAllTrackingOfStep(startDate: $startDate, endDate: $endDate, userFlowStepId: $userFlowStepId, questionIds: $questionIds) {
        instanceDate
        id
        answers {
          ... on QuestionAnswerMultilineTextType {
            text
          }
        }
      }
    }
  }
`;

export { GET_ALL_TRACKING_OF_STEP_QUERY };
