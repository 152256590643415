/*
  TODO: May need to update the question configuration in the api to include
    - default option text
    - name for the select tag
*/

import React, { useState } from 'react';

import { ConfirmationMessages, Icon, Label } from '@netfront/ui-library';
import cx from 'classnames';

import { DEFAULT_MESSAGE } from '../QuestionSnippet/QuestionSnippet.constants';
import { getAriaDescribedBy, getHtmlElementOnChangeValueFromEvent } from '../Snippets.helpers';

import { DropDownListSnippetProps } from './DropDownListSnippet.interfaces';

import './DropDownListSnippet.css';

const DropDownListSnippet = ({
  defaultOptionText,
  describedById,
  id,
  isDirty = false,
  isDisabled = false,
  isLabelHidden = true,
  isRequired = false,
  label = '',
  message = DEFAULT_MESSAGE,
  name,
  onChange,
  options,
  selectedValue,
}: DropDownListSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message;

  const [currentSelectedValue, setCurrentSelectedValue] = useState<string>(selectedValue);

  const classes = cx('c-dropdown-list', {
    'is-dirty': isDirty,
    'is-disabled': isDisabled,
    'is-invalid': Boolean(errorMessage),
    'is-required': isRequired,
  });

  const ariaDescribedBy = getAriaDescribedBy(describedById, id, Boolean(errorMessage));

  return (
    <React.Fragment>
      <p className="c-input-field">
        <Label forId={`label-${id}`} isHidden={isLabelHidden} isRequired={isRequired} labelText={label} />
        <span className={classes}>
          <select
            aria-describedby={ariaDescribedBy}
            aria-invalid={Boolean(errorMessage)}
            className="c-dropdown-list__control"
            disabled={isDisabled}
            id={`dropdown-list-${id}`}
            name={name}
            required={isRequired}
            value={currentSelectedValue}
            onChange={(event) => {
              const updatedSelectedValue = getHtmlElementOnChangeValueFromEvent(event);

              setCurrentSelectedValue(updatedSelectedValue);
              onChange(updatedSelectedValue);
            }}
          >
            {defaultOptionText && <option disabled>{defaultOptionText}</option>}
            {options.map(({ isDisabled: isOptionDisabled = false, id: optionId, label: optionLabel, value: optionValue }) => {
              return (
                <option key={optionId} disabled={isOptionDisabled} id={optionId} value={optionValue}>
                  {optionLabel}
                </option>
              );
            })}
          </select>
          <Icon className="c-dropdown-list__icon" id="id_caret_icon" />
        </span>
      </p>
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};
export { DropDownListSnippet };
