import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_SNIPPET = gql`
  mutation updateContentSnippet($id: Int!, $contentSnippetId: Int!) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $id }
        contentSnippetImageMap: { contentSnippetImageMapId: $contentSnippetId }
      ) {
        id
        sort
      }
    }
  }
`;

const updateImageMapContentSnippet = ({
  id,
  contentSnippetId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        id,
        contentSnippetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.contentSnippet.updateContentSnippet.id,
          data.contentSnippet.updateContentSnippet.sort
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateImageMapContentSnippet.propTypes = {
  contentSnippetId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default updateImageMapContentSnippet;
