import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

// **************styled Components********************
const Wrapper = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  min-height: 20em;
  margin: 1em 0;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 1em;
`;

// **************styled Components********************

const SnippetContainer = props => {
  const { children } = props;
  return <Wrapper>{children}</Wrapper>;
};

SnippetContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SnippetContainer;
