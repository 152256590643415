import PropTypes from 'prop-types';
import React from 'react';
import {
  ButtonContent,
  ButtonGroup,
  Container,
  LoadingContainer
} from './styled';



const Button = props => {
  const { children, isDisabled, isLoading, isMarginUsed, onClick, styles, theme } = props;

  return (
    <Container
      disabled={isDisabled}
      isMarginUsed={isMarginUsed}
      onClick={onClick}
      styles={styles}
      theme={theme}
    >
      {isLoading && (
        <LoadingContainer>
          <svg
            className="sc-dnqmqq bhUIzZ"
            focusable="false"
            height="24"
            size="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="11" />
          </svg>
        </LoadingContainer>
      )}

      <ButtonContent isLoading={isLoading}>{children}</ButtonContent>
    </Container>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMarginUsed: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  theme: PropTypes.string,
};

Button.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isMarginUsed: true,
  onClick: () => undefined,
  styles: undefined,
  theme: undefined,
};

export default Button;
export { ButtonGroup };

