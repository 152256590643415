import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0;
`;

export const Hr = styled.hr`
  background-color: #e1e1e1;
  border: none;
  flex: 1;
  height: 1px;
`;

export const ButtonWrapper = styled.span`
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
`;

export const Select = styled.select`
  background-color: #fff;
  border-color: #b3b3b3;
  border-radius: 0.25rem;
  color: #b3b3b3;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  margin-right: 0.5rem;
  padding: 0.9rem 0.75rem;
  transition: 0.25s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #b3b3b3;
    border-color: #b3b3b3;
    color: #fff;
  }
`;
