import React from 'react';

import { parseISO } from 'date-fns';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

import Field from '../Forms/Field';
import Form from '../Forms/Form';
import ImageButton, { ICONS } from '../UI/Button/ImageButton';

import { NOTES_PROP_TYPES } from './Notes.constants';
import { CreatedByUserContainer, Flex, FlexFull, FlexHorizontal, FlexVertical, Header, NoteDetailsContainer, Text } from './styled';

import AttachmentIcon from '../../static/img/paperclip-solid-grey.svg';

const PREFIX = 'note-details-';

function NoteDetails(props) {
  const { currentNote, onCancelHandler, onEditHandler } = props;

  const {
    attachments,
    createdByUser: { firstname, lastname },
    creationDate,
    message,
    subject,
  } = currentNote;

  const createdByFullName = [firstname, lastname].filter(item => item).join(' ') || 'No name';
  const hasAttachments = !isEmpty(attachments);

  return (
    <>
      <FlexHorizontal>
        <Flex>
          <ImageButton borderRadius="15%" iconType={ICONS.back} onClicked={onCancelHandler} />
        </Flex>
        <FlexFull isCentered>
          <Header>Notes</Header>
        </FlexFull>
        <Flex>
          <ImageButton borderRadius="15%" iconType={ICONS.edit} onClicked={() => onEditHandler(currentNote)} />
        </Flex>
      </FlexHorizontal>
      <Form>
        <Field id={`${PREFIX}subject`} label={subject}>
          <NoteDetailsContainer>
            <FlexVertical>
              {message.split('\n').map((line, index) => (
                <Text key={`line-${index}`}>{line}</Text>
              ))}
            </FlexVertical>
            {hasAttachments && (
              <Flex>
                <img alt="attachment" src={AttachmentIcon} style={{ width: '1rem' }} />
              </Flex>
            )}
          </NoteDetailsContainer>
          <CreatedByUserContainer>
            Created by: {createdByFullName} at{' '}
            {parseISO(creationDate).toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { hour12: true })}
          </CreatedByUserContainer>
        </Field>
      </Form>
    </>
  );
}

NoteDetails.propTypes = {
  currentNote: PropTypes.shape(NOTES_PROP_TYPES).isRequired,
  onCancelHandler: PropTypes.func.isRequired,
  onEditHandler: PropTypes.func.isRequired,
};

export default NoteDetails;
