import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

// In ColorPicker, we use HEX;
const ColorPicker = props => {
  const { getColor, currentColor } = props;
  const [color, setColor] = useState({ hex: currentColor });

  const handleChange = value => {
    setColor(value.hex);
  };

  const handleChangeComplete = value => {
    setColor(value.hex);
    getColor(value.hex);
  };
  return (
    <ChromePicker
      color={color}
      disableAlpha
      onChange={handleChange}
      onChangeComplete={handleChangeComplete}
    />
  );
};

ColorPicker.propTypes = {
  getColor: PropTypes.func.isRequired,
  currentColor: PropTypes.string,
};

ColorPicker.defaultProps = {
  currentColor: '',
};

export default ColorPicker;
