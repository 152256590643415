import { getAccessTokenCookie } from '@netfront/common-library';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';

import introspectionQueryResultData from '../schema/fragmentTypes.json';

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
});

const authLink = setContext((_, { headers: previousContextHeaders }) => {
  const headers = {
    ...previousContextHeaders,
  };

  const token = getAccessTokenCookie();

  if (token) {
    headers.authorization = token;
  }

  return {
    headers,
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BONOBO_API_URL,
});

const link = authLink.concat(httpLink);

const bonoboClient = new ApolloClient({
  cache,
  link,
});

export default bonoboClient;
