import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  NewAppBtn,
  NewIcon,
  BtnGroup,
  AppCard,
} from '../MarketplacePage/styled';
import {
  Toolbar,
  // TabBar,
  // TabBtn,
  SearchBar,
  SearchInput,
  StyleView,
  ListViewBtn,
  GridViewBtn,
  AppContainer,
} from '../MyApplicationPage/styled';

import AppSideBar from '../../components/RightNavBar/AppSideBar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import GetAllPendingApps from '../../middleware/Marketplace/GetAllPendingAppVersions';

//   { id: 0, label: 'All', value: 'all' },
//   { id: 1, label: 'Popular', value: 'popular' },
//   { id: 2, label: 'Latest', value: 'latest' },
//   //   { id: 3, label: 'My apps', value: 'myapp' },
// ];

const ApprovalAppPage = props => {
  const { history, match } = props;

  const [appLoaded, setAppLoaded] = useState(false);
  const [apps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);

  const [openPanel, setOpenPanel] = useState(false);
  //   const [cursor, setCursor] = useState(null);
  //  const [showLoadBtn, setShowLoadBtn] = useState(true);
  //   const [sorting, setSorting] = useState(null);

  const handleSearch = e => {
    const { value } = e.target;
    const filtered = apps.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredApps(filtered);
  };

  //   setActiveTab(value);
  //   if (value === 'all') setSorting(null);
  //   if (value !== 'all' && value !== 'myapp')
  //   setSorting(value.toUpperCase());
  //   setAppLoaded(false);
  //   setApps([]);
  //   setShowLoadBtn(true);
  //   setCursor(null);
  // };

  const handleBackMarketplace = () => {
    history.push('/marketplace');
  };

  const handleClosePanel = (type, data) => {
    if (type === 'create') {
      const newList = [
        ...apps,
        {
          ...data,
          averageRateValue: 0,
        },
      ];
      setApps(newList);
      setFilteredApps(newList);
    }
    setOpenPanel(false);
  };

  const handleGetAppError = () => {
    setAppLoaded(true);
  };

  const handleGetAppSuccess = data => {
    // if (newApps.length === total) setShowLoadBtn(false);
    setApps(data);
    // setCursor(endCursor);
    setFilteredApps(data);
    setAppLoaded(true);
  };

  const buttonGroup = (
    <BtnGroup>
      <NewAppBtn onClick={handleBackMarketplace}>
        <NewIcon>
          <span className="icon-chevron" />
        </NewIcon>
        Return to store
      </NewAppBtn>
    </BtnGroup>
  );

  const appList = (
    <AppContainer>
      {filteredApps.map(item => (
        <AppCard
          href={`/marketplace/app/${item.app.id}/versions/${item.id}/approval`}
          id={item.app.id}
          img="https://dev.assets.ekardo.com/logo/logo-dark.svg"
          key={item.version}
          name={item.app.title}
          owner="Netfront"
          rating={item.app.averageRateValue}
          version={item.version}
        />
      ))}
    </AppContainer>
  );

  const toolbar = (
    <Toolbar>
      {buttonGroup}
      {/* <TabBar>
        {tabs.map(tab => (
          <TabBtn
            hasSelected={tab.value === activeTab}
            key={tab.id}
            onClick={() => hanldeSorting(tab.value)}
          >
            {tab.label}
          </TabBtn>
        ))}
      </TabBar> */}
      <SearchBar>
        <SearchInput onChange={e => handleSearch(e)} placeholder="Search" />
      </SearchBar>
    </Toolbar>
  );

  const breadPath = [
    {
      id: 1,
      path: '/marketplace',
      title: 'Marketplace',
    },

    {
      id: 2,
      path: '',
      title: 'Approve apps',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="App store"
        pageDescription="App store"
        pageTitle="App marketplace page"
        params={match.params}
      >
        {toolbar}
        <StyleView>
          <ListViewBtn />
          <GridViewBtn />
        </StyleView>
        {appList}
        {/* {showLoadBtn && (
          <Button isClicked={() => setAppLoaded(false)}>Load more ...</Button>
        )} */}
        {openPanel && <AppSideBar onClose={handleClosePanel} />}
        {openPanel && <Backdrop isClicked={handleClosePanel} />}
      </PageWrapper>
      {!appLoaded && (
        <GetAllPendingApps
          onError={handleGetAppError}
          onSuccess={handleGetAppSuccess}
        />
      )}
    </>
  );
};

ApprovalAppPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};

ApprovalAppPage.defaultProps = {
  location: undefined,
};

export default ApprovalAppPage;
