import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import client from '../client';
import QueryRequest from '../QueryRequest';
import { FRAGMENT_RESPONSE_SET } from '../QuestionConfiguration/Fragments/ResponseSetFragment';

export const GET_CONTENT_GROUP_DETAILS = gql`
  query getContentGroupFormDetailReq($contentGroupId: Int!) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        id
        projectId
        sort
        title
        url
        description
        createdByUserId
        status
        contentPages {
          id
          contentSections {
            id
            sectionContainers {
              id
              snippets {
                id
                ... on ContentSnippetQuestionType {
                  question
                  configuration {
                    id
                    ... on Checkbox {
                      responseSet {
                        ...ResponseSetFragment
                      }
                    }
                    ... on Radio {
                      responseSet {
                        ...ResponseSetFragment
                      }
                    }
                    ... on DropDownList {
                      responseSet {
                        ...ResponseSetFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_RESPONSE_SET}
`;

export function useFormDetailQuery({ contentGroupId, onError, onCompleted }) {
  return useQuery(GET_CONTENT_GROUP_DETAILS, {
    fetchPolicy: 'cache-and-network',
    client,
    onError,
    onCompleted,
    variables: {
      contentGroupId,
    },
  });
}

const getContentGroupFormDetailReq = ({ contentGroupId, onError, onSuccessResult }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Query fetchPolicy="network-only" query={GET_CONTENT_GROUP_DETAILS} variables={{ contentGroupId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.contentGroup.getContentGroup}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getContentGroupFormDetailReq.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getContentGroupFormDetailReq;
