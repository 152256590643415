import React, { useState, useContext, useEffect } from 'react';

import { useJwtDecoder, useCookie } from '@netfront/common-library';
import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Dropdown, Icon, OrganisationNavigation } from '@netfront/ui-library';
import { useParams } from 'react-router-dom';

import AppContext from '../../context/AppContext';

const OrganisationMenu = () => {
  const { projectId, orgId: organisationId } = useParams();

  const { org, project } = useContext(AppContext);
  const { isDomainReady } = useDomain();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie } = useCookie();
  const [isCustomBuild, setIsCustomBuild] = useState(false);

  const [identityUrl, setIdentityUrl] = useState(undefined);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState(false);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();
    if (!accessToken) return;

    const decoded = getDecodedJwt(accessToken);
    const claim = decoded.custom_build;
    setIsCustomBuild(Boolean(String(claim).toLowerCase() === 'true'));
  }, []);

  const baseIdentityPath = `${identityUrl}/project/${String(project.id)}`;

  const getNavigationItems = () => {
    const commonItems = [
      {
        href: `/dashboard`,
        name: 'Switch',
        visible: !isCustomBuild,
      },
    ];
    if (projectId) {
      return [
        {
          href: `${baseIdentityPath}/team`,
          name: 'Team',
          visible: true,
        },
        {
          href: `${baseIdentityPath}/user`,
          name: 'Users',
          visible: true,
        },
        ...commonItems,
      ];
    }
    if (organisationId) {
      return [
        {
          href: `${identityUrl}/account/${String(organisationId)}/team`,
          name: 'Team',
          visible: true,
        },
        ...commonItems,
      ];
    }
    return [];
  };

  return (
    <div className="c-organisation-menu">
      {project && (
        <Dropdown
          dropdownId="organisation-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Icon id="id_organisation_icon" />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <OrganisationNavigation
            organisation={{
              name: organisationId ? org.name : 'Organisations',
              logoIconId: 'id_group_icon',
              url: isCustomBuild ? '' : `/dashboard${organisationId ? `/${org.key}` : ''}`,
              dropdownNavigationItems: getNavigationItems().filter((r) => r.visible),
            }}
            smallText="Current"
          />
        </Dropdown>
      )}
    </div>
  );
};

export default OrganisationMenu;
