import React, { useState, Fragment } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getAssetsUrl } from '../../config';

function SideBarNav(props) {
  const { path, projects, orgId, projectId, onOpenPanel, isDefaultOpen } = props;

  // let storageOrgId = Number(localStorage.getItem('orgId'));
  // let storageOrgId = Number(cookies.get('orgId'));
  // if (orgId && storageOrgId !== orgId) {
  //   storageOrgId = orgId;
  //   // localStorage.setItem('orgId', orgId);
  //   // TODO: remove orgId, we already have org cookie
  //   cookies.set('orgId', orgId);
  // }

  const menu = [
    {
      id: 2,
      name: 'Projects',
      iconImage: `${getAssetsUrl()}/icons/projects.svg`,
      path: `/dashboard`,
      subfields: [],
    },
    {
      id: 3,
      name: 'Users',
      iconImage: `${getAssetsUrl()}/icons/projects.svg`,
      path: !projectId ? `/dashboard/${orgId}/users` : `/dashboard/${orgId}/project/${projectId}/users`,
    },
    {
      id: 4,
      name: 'Billing',
      iconImage: `${getAssetsUrl()}/icons/billing.svg`,
      path: '/payment',
    },
  ];
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const [subExpand, setSubExpand] = useState(-1);

  const handleSideToggle = () => setIsOpen(!isOpen);

  const handleDownToggle = (id) => {
    setSubExpand((prevState) => (prevState === id ? -1 : id));
  };

  return (
    <div>
      <div className={`content-sidebar-nav ${isOpen ? 'content-sidebar-nav__active' : 'content-sidebar-nav__inActive'}`}>
        <button
          className={`button-none content-sidebar-nav__trigger ${
            isOpen ? 'content-sidebar-nav__trigger__active' : 'content-sidebar-nav__trigger__inActive'
          }`}
          type="button"
          onClick={handleSideToggle}
          onKeyPress={handleSideToggle}
        >
          <span className="icon-chevron" />
        </button>
        <div className="sideBarNav-dashboard">
          <button
            className={classnames(
              'c-sidebar-nav__item--is-active',

              'button-none',
              'w-100',
              'p-3',
            )}
            type="button"
          >
            <div className="d-flex w-100" role="presentation" onClick={() => path.history.goBack()}>
              <div className="sideBarNav-dashboard-icon">
                <span className="icon-chevron" />
              </div>
              <h3>DASHBOARD</h3>
            </div>
          </button>
        </div>

        <nav
          className={classnames('sideBarNav-bg', {
            'c-sidebar-nav--closed': !isOpen,
          })}
        >
          <ul>
            <>
              {menu.map((item) => (
                <Fragment key={item.id}>
                  {item.path ? (
                    (item.id === 2 || item.id === 1 ? orgId : true) && (
                      <li>
                        <Link
                          to={item.id !== 2 && item.path}
                          // to={`${item.path}${
                          //   item.id === 2 ? `/${storageOrgId}` : ''
                          // }`}
                        >
                          <button
                            className={classnames(
                              'c-sidebar-nav__item',
                              {
                                '--is-active': item.id === subExpand,
                              },
                              'button-none',
                              'w-100',
                              'p-3',
                            )}
                            type="button"
                            onClick={() => handleDownToggle(item.id)}
                            onKeyPress={() => handleDownToggle(item.id)}
                          >
                            <div className="d-flex w-100">
                              {item.icon ? (
                                <span className={item.icon} />
                              ) : (
                                <img alt="icon" className="sideBarNav-item-icon" src={item.iconImage} />
                              )}
                              <p>{isOpen && item.name}</p>
                              {item.subfields && <span className="icon-chevron" />}
                            </div>
                          </button>
                        </Link>
                      </li>
                    )
                  ) : (
                    <li
                      key={item.id}
                      className={classnames('c-sidebar-nav__item', {
                        '--is-active': item.id === subExpand,
                      })}
                      role="presentation"
                      style={{ flexDirection: 'column' }}
                      onClick={() => handleDownToggle(item.id)}
                      onKeyPress={() => handleDownToggle(item.id)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className={item.icon} />
                        <p>{isOpen && item.name}</p>
                        <span className="icon-chevron" />
                      </div>
                    </li>
                  )}
                  {item.subfields && (
                    <li>
                      {item.id === 2 /* Projects */ && (
                        <ul className={`${item.id !== subExpand ? '--is-collapsed' : ''}`}>
                          {projects &&
                            projects.map((project) => (
                              <a href={`${path}/project/${project.id}`}>
                                <li
                                  key={project.id}
                                  className="c-sidebar-nav__sub"
                                  name={project.name}
                                  style={{
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p>{project.name}</p>
                                  </div>
                                </li>
                              </a>
                            ))}
                          <li key={item.subfields.length} className={classnames('--project-bar')} role="presentation" onClick={onOpenPanel}>
                            <span className="icon-plus" />
                            New project
                          </li>
                        </ul>
                      )}
                    </li>
                  )}
                </Fragment>
              ))}
            </>
          </ul>
        </nav>
      </div>
    </div>
  );
}

SideBarNav.propTypes = {
  path: PropTypes.object.isRequired,
  isDefaultOpen: PropTypes.bool,
  onOpenPanel: PropTypes.func,
  /* eslint react/forbid-prop-types: 0 */
  orgId: PropTypes.number,
  projectId: PropTypes.string,
  projects: PropTypes.any,
};

SideBarNav.defaultProps = {
  isDefaultOpen: false,
  onOpenPanel: () => {},
  orgId: undefined,
  projectId: undefined,
  projects: undefined,
};

export default SideBarNav;
