import React from 'react';

import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

export default function TermsPage() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Helmet>
        <title>Ekardo terms and conditions</title>
        <meta
          content="Ekardo is here to make your information accessible, to let us do this you must agree to our terms"
          name="description"
        />
      </Helmet>

      <div className="login-page">
        <div
          className="login-page__fold"
          style={{ maxHeight: '350px', minHeight: '320px' }}
        >
          <Header theme="red" />
          <div
            className="login-page__container"
            style={{
              margin: 'auto auto 3em auto',
              maxWidth: '700px',
            }}
          >
            <h2
              className="heading-page"
              style={{
                fontSize: '3em',
              }}
            >
              Terms
            </h2>
            <h2 className="heading-page">& conditions</h2>
          </div>
        </div>
      </div>

      <div className="u-container" style={{ marginTop: '1em' }}>
        <strong>Website Terms and Conditions</strong>
        <p>
          Thank you for visiting our website. This website is owned and operated
          by Netfront Pty Ltd (ABN 27 132 358 368). By accessing and/or using
          this website and related products and services, you agree to these
          Terms and Conditions, which include our Privacy Policy (Terms). You
          should review our Privacy Policy and these Terms carefully and
          immediately cease using our website if you do not agree to these
          Terms. In these Terms, &lsquo;us&rsquo;, &lsquo;we&rsquo; and
          &lsquo;our&rsquo; means Netfront Pty Ltd.
        </p>
      </div>

      <div className="u-container">
        <strong>Registration</strong>
        <p>
          You must be a registered member to access our products. When you
          register and activate your account, you will provide us with certain
          personal information as set out on our registration pages. You must
          ensure that this information is accurate and current. We will handle
          all personal information we collect in accordance with our Privacy
          Policy. When you register and activate your account, you are
          responsible for keeping this user name and password secure and are
          responsible for all use and activity carried out under this user name.
        </p>
      </div>

      <div className="u-container">
        <strong>Collection Notice</strong>
        <p>
          We collect personal information about you in order to process your
          registration and provide you with access to the products and services
          on our website and for business purposes otherwise set out in our
          Privacy Policy. The information you provide will be collected by or on
          behalf of us and may be disclosed to third parties that help us
          deliver our services (including information technology suppliers,
          communication suppliers and our business partners) or as required by
          law. If you do not provide this information, we may not be able to
          provide you with access to all of the products and services available
          on our website. Our Privacy Policy explains: (i) how we store and use,
          and how you may access and correct your personal information; (ii) how
          you can lodge a complaint regarding the handling of your personal
          information; and (iii) how we will handle any complaint. If you would
          like any further information about our privacy policies or practices,
          please contact us. By providing your personal information to us, you
          consent to the collection, use, storage and disclosure of that
          information as described in the Privacy Policy and this Collection
          Notice.
        </p>
      </div>

      <div className="u-container">
        <strong>Intellectual Property Rights</strong>
        <p>
          Unless otherwise indicated, we own or license from third parties all
          rights, title and interest (including copyright, designs, patents,
          trademarks and other intellectual property rights) in this website and
          in all of the material (including all text, graphics, logos, audio and
          software) made available on this website (Content). Your use of this
          website and use of and access to any Content does not grant or
          transfer any rights, title or interest to you in relation to this
          website or the Content. However we do grant you a licence to access
          the website and view the Content on the Terms set out in this
          Agreement.
        </p>
        <br />
        <p>
          Any reproduction or redistribution of this website or the Content is
          prohibited and may result in civil and criminal penalties. In
          addition, you must not copy the Content to any other server, location
          or support for publication, reproduction or distribution is expressly
          prohibited. All other use, copying or reproduction of this website,
          the Content or any part of it is prohibited, except to the extent
          permitted by law.
        </p>
      </div>

      <div className="u-container">
        <strong>Limitation of Liability</strong>
        <p>
          To the full extent permitted by law, including the Australian Consumer
          Law, our liability for breach of an implied warranty or condition will
          not be greater than the total amount you paid to subscribe for our
          products on this website and in no event shall we be liable for any
          direct and indirect loss, damage or expense – irrespective of the
          manner in which it occurs – which may be suffered due to your use of
          our website and/or the information or materials contained on it, or as
          a result of the inaccessibility of this website and/or the fact that
          certain information or materials contained on it are incorrect,
          incomplete or not up-to-date.
        </p>
      </div>

      <div className="u-container">
        <strong>Indemnity</strong>
        <p>
          By accessing our website, you agree to indemnify and hold us harmless
          from all claims, actions, damages, costs and expenses including legal
          fees arising from or in connection with your use of our website or its
          content.
        </p>
      </div>

      <div className="u-container">
        <strong>Severability</strong>
        <p>
          If any provision in these Terms is invalid under any law the provision
          will be limited, narrowed, construed or altered as necessary to render
          it valid but only to the extent necessary to achieve such validity. If
          necessary the invalid provision will be deleted from these Terms and
          the remaining provisions will remain in full force and effect.
        </p>
      </div>

      <div className="u-container">
        <strong>Jurisdiction</strong>
        <p>
          These Terms are to be governed by and construed in accordance with the
          laws of New South Wales, Australia and any claim made by either party
          against the other which in any way arises out of these Terms will be
          heard in New South Wales, Australia and you agree to submit to the
          jurisdiction of those Courts.
        </p>
      </div>

      <Footer />
    </div>
  );
}
