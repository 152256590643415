import React from 'react';

import { ConfirmationMessages, DateRangePicker, IDateRange, SingleDatePicker } from '@netfront/ui-library';

import { isCalendarTypeSupported } from './CalendarSnippet.helpers';
import { CalendarSnippetProps } from './CalendarSnippet.interfaces';

const CalendarSnippet = ({
  id,
  maxDate,
  message,
  minDate,
  onDateRangeChange,
  onSingleDateChange,
  selectedDate,
  type,
}: CalendarSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  if (!isCalendarTypeSupported(type)) {
    return <React.Fragment>Date type {type} is not supported</React.Fragment>;
  }

  return (
    <React.Fragment>
      {type === 'DATE_RANGE' && <DateRangePicker selectedDate={selectedDate as IDateRange} onChangeHandler={onDateRangeChange} />}
      {type === 'SINGLE_DATE' && (
        <SingleDatePicker
          maxDate={maxDate}
          minDate={minDate}
          selectedDate={selectedDate as Date | undefined}
          onChangeHandler={onSingleDateChange}
        />
      )}
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};

export { CalendarSnippet };
