import React, { useEffect } from 'react';

import errorIcon from '../../../static/img/error-icon.svg';
import infoIcon from '../../../static/img/info-icon.svg';
import successIcon from '../../../static/img/success-icon.svg';
import warningIcon from '../../../static/img/warning-icon.svg';

import './NotificationMessage.css';

const NotificationMessage = ({ message, type, onHideNotificationMessage }) => {
  useEffect(() => {
    setTimeout(() => {
      onHideNotificationMessage();
    }, 5000);
  }, []);

  const toastStyleTypeMap = {
    info: 'c-info',
    success: 'c-success',
    warning: 'c-warning',
    error: 'c-error',
  };

  const toastIconTypeMap = {
    info: infoIcon,
    success: successIcon,
    warning: warningIcon,
    error: errorIcon,
  };

  return (
    <div className={`c-message ${toastStyleTypeMap[type]}`}>
      <img className="c-notification-icon" src={toastIconTypeMap[type]} alt="alert popup" />
      {message}
    </div>
  );
};

export default NotificationMessage;
