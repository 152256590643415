import { useEkardoMutation } from '../useEkardoMutation';

import { QUESTION_CONFIGURATION_TYPE_ADD_USER_GENERATED_RESPONSE_MUTATION_MAP } from './useAddUserGeneratedResponse.graphql';
import {
  IAddUserGeneratedResponseMutationGraphQLResponse,
  IAddUserGeneratedResponseMutationVariables,
  IHandleAddUserGeneratedResponseParams,
  IUseAddUserGeneratedResponseOptions,
  IUseAddUserGeneratedResponse,
} from './useAddUserGeneratedResponse.interfaces';

const useAddUserGeneratedResponse = (options?: IUseAddUserGeneratedResponseOptions): IUseAddUserGeneratedResponse => {
  const { mutation, onCompleted, onError, product, questionConfigurationType, token } =
    options ?? ({} as IUseAddUserGeneratedResponseOptions);

  const [executeAddUserGeneratedResponse, { loading: isLoading }] = useEkardoMutation<
    IAddUserGeneratedResponseMutationGraphQLResponse,
    IAddUserGeneratedResponseMutationVariables
  >({
    mutation: mutation ?? QUESTION_CONFIGURATION_TYPE_ADD_USER_GENERATED_RESPONSE_MUTATION_MAP[questionConfigurationType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionSet: { addAvailableAnswer: questionResponseSet },
        } = data;

        onCompleted({
          questionResponseSet,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleAddUserGeneratedResponse = async ({
    contentSnippetId,
    label,
    questionResponseSetId,
  }: IHandleAddUserGeneratedResponseParams) => {
    await executeAddUserGeneratedResponse({
      variables: {
        contentSnippetId,
        label,
        questionResponseSetId,
      },
    });
  };

  return {
    handleAddUserGeneratedResponse,
    isLoading,
  };
};

export { useAddUserGeneratedResponse };
