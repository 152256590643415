import styled from 'styled-components';

export const Input = styled.div`
  position: relative;

  input {

    background-color: #F4F5F7;
    border: 1px solid;
    border-color: rgba(9,30,66,0.08);
    border-radius: 5px;
    min-width: 100%;
    padding: .5em 1em;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;

    &:focus {
      background-color: #fff;
      border-color: #00c4cc;
      outline: 0;
    }
  }

  svg {
    position: absolute;
    right: .6em;
    top: 47%;
    transform: translateY(-50%);
  }

}
`;
