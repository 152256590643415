import React from 'react';

import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContainerScroll } from '../OrganisationUsersPage/styled';
import {
  Flex,
  FlexHorizontal,
  RoundButton,
  StyledTableBase,
  StyledTBodyBordered,
  StyledTdBase,
  StyledTdHorizontalCentered,
  StyledTdRightAligned,
  StyledTdSpacer,
  StyledThBase,
  StyledThCentered,
  StyledTHeadBordered,
  StyledThRightAligned,
  StyledThSpacer,
  StyledTrBase,
} from '../styles';

import ColorAvatar from '../../components/UI/Avatar/colorAvatar';
import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';

const GroupNameContentsContainer = styled(FlexHorizontal)`
  align-items: center;
`;

const GroupNameContainer = styled(Flex)`
  margin-left: 0.5rem;
`;

function GroupsOrderSummaryTable(props) {
  const { groupsOrderSummaries, onView } = props;

  const hasGroupsOrderSummaries = Boolean(groupsOrderSummaries.length);

  return (
    <ContainerScroll>
      {!hasGroupsOrderSummaries && <NoDataAlerts />}
      {hasGroupsOrderSummaries && (
        <StyledTableBase>
          <StyledTHeadBordered>
            <StyledTrBase key="table-head">
              <StyledThSpacer />
              <StyledThBase data-sort="group" scope="col">
                Group
              </StyledThBase>
              <StyledThCentered data-sort="latestOrder" scope="col">
                Latest order
              </StyledThCentered>
              <StyledThRightAligned data-sort="licences" scope="col">
                Licences
              </StyledThRightAligned>
              <StyledThRightAligned scope="col" />
            </StyledTrBase>
          </StyledTHeadBordered>
          <StyledTBodyBordered>
            {groupsOrderSummaries.map((groupsOrderSummary) => {
              const { groupId, groupName, latestOrderDate, totalLicences } = groupsOrderSummary;

              return (
                <StyledTrBase key={`groups-order-summary-${groupId}`}>
                  <StyledTdSpacer />
                  <StyledTdBase>
                    <GroupNameContentsContainer>
                      <Flex>{groupName ? <ColorAvatar name={groupName} /> : null}</Flex>
                      <GroupNameContainer>{groupName}</GroupNameContainer>
                    </GroupNameContentsContainer>
                  </StyledTdBase>
                  <StyledTdHorizontalCentered>{parseISO(latestOrderDate).toLocaleDateString()}</StyledTdHorizontalCentered>
                  <StyledTdRightAligned>{totalLicences}</StyledTdRightAligned>
                  <StyledTdRightAligned>
                    <RoundButton onClick={() => onView(groupId)}>···</RoundButton>
                  </StyledTdRightAligned>
                </StyledTrBase>
              );
            })}
          </StyledTBodyBordered>
        </StyledTableBase>
      )}
    </ContainerScroll>
  );
}

GroupsOrderSummaryTable.propTypes = {
  groupsOrderSummaries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onView: PropTypes.func.isRequired,
};

export default GroupsOrderSummaryTable;
