import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { FormButtons, Icon, Label, Tooltip, Select, Spacing } from '@netfront/ui-library';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS, WRAPPER_TYPE_ACTION_KEY_MAP } from './ActionTab.constants';
import { GET_CONTENT_PAGE, GET_TEMPLATES, APPLY_TEMPLATE } from './ActionTab.graphql';
import { getEventValue } from './helpers/inputEvent';
import { QuestionConditionEdit } from './QuestionConditions/QuestionConditionEdit';
import { QuestionConditionNew } from './QuestionConditions/QuestionConditionNew';

import { useToast } from '../../../hooks';
import client from '../../../middleware/client';
import SearchIcon from '../../../static/img/search-icon.svg';
import { capitalize } from '../../../utils/utils';

import './ActionTab.css';

const ActionTab = ({ actionWrapper, activePageId, maxAutoCompleteSuggestions, maxCharacterCountTitle, onClose }) => {
  const { projectId } = useParams();

  const { handleToastError, handleToastSuccess } = useToast();

  const [allActions, setAllActions] = useState([]);
  const [isActionViewVisible, setIsActionViewVisible] = useState(true);
  const [isEditConditionViewVisible, setIsEditConditionViewVisible] = useState(false);
  const [isNewConditionViewVisible, setIsNewConditionViewVisible] = useState(false);
  const [selectedAction, setSelectedAction] = useState(undefined);
  const [selectedActionConditions, setSelectedActionConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(undefined);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [templates, setTemplates] = useState([]);

  const { id: wrapperId, type: wrapper } = actionWrapper;

  const [getContentPage] = useLazyQuery(GET_CONTENT_PAGE, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        contentPage: {
          getContentPage: { actions },
        },
      } = data;

      const actionKey = WRAPPER_TYPE_ACTION_KEY_MAP[wrapper];

      if (!actionKey) {
        throw new Error('Unsupported wrapper type');
      }

      setAllActions(actions.filter((item) => item[actionKey] === wrapperId));
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const [executeApplyTemplate] = useMutation(APPLY_TEMPLATE, {
    client,
    onCompleted(data) {
      const {
        questionAction: { applyTemplate: questionCondition },
      } = data;

      const updatedAction = {
        ...selectedAction,
        conditions: [...selectedAction.conditions, questionCondition],
      };

      setAllActions((currentActions) =>
        currentActions.map((currentAction) => (currentAction.id === selectedAction.id ? selectedAction : currentAction)),
      );
      setSelectedAction(updatedAction);
      setSelectedActionConditions(updatedAction.conditions);
    },
  });

  const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        questionAction: { getConditions },
      } = data;

      setTemplates(getConditions);
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const debounceGetQuestionQuery = useDebouncedCallback((inputText) => {
    getTemplates({
      variables: {
        projectId,
        title: inputText,
      },
    });
  }, DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS);

  const handleAddActionCompleted = (action) => {
    setAllActions([...allActions, action]);
  };

  const handleAddConditionClose = () => {
    setIsActionViewVisible(true);
    setIsNewConditionViewVisible(false);
  };

  const handleAddConditionCompleted = (action) => {
    setAllActions((currentActions) => currentActions.map((currentAction) => (currentAction.id === action.id ? action : currentAction)));
    setSelectedAction(action);
    setSelectedActionConditions(action.conditions);
    setIsActionViewVisible(true);
    setIsNewConditionViewVisible(false);

    handleToastSuccess({
      message: `Condition created successfully`,
    });
  };

  const handleApplyTemplate = (item) => {
    setSearchInputValue(item.title);

    executeApplyTemplate({
      variables: {
        templateId: item.id,
        actionId: selectedAction.id,
      },
    });

    handleToastSuccess({
      message: `Template added successfully`,
    });
  };

  const handleEventChange = (event) => {
    const value = getEventValue(event);

    setSelectedEvent(value);

    if (!allActions.length) {
      return;
    }

    const action = allActions.find((item) => item.__typename === value);
    if (!action) {
      setSelectedAction(undefined);

      return;
    }

    setSelectedAction(action);
    setSelectedActionConditions(action.conditions);
  };

  const handleOpenAddCondition = () => {
    setIsActionViewVisible(false);
    setIsNewConditionViewVisible(true);
  };

  const handleOpenUpdateCondition = (condition) => {
    setIsActionViewVisible(false);
    setIsEditConditionViewVisible(true);

    setSelectedCondition({
      ...condition,
      questionActionId: selectedAction.id,
    });
  };

  const handleRecommendedSearchInput = (event) => {
    const value = getEventValue(event);
    setSearchInputValue(value);

    if (value.length <= 2) {
      return;
    }

    debounceGetQuestionQuery(value);
  };

  const handleUpdateConditionClose = () => {
    setIsActionViewVisible(true);
    setIsEditConditionViewVisible(false);
  };

  const handleUpdateConditionCompleted = (action) => {
    setAllActions((currentActions) => currentActions.map((currentAction) => (currentAction.id === action.id ? action : currentAction)));
    setSelectedAction(action);
    setSelectedActionConditions(action.conditions);
    setIsActionViewVisible(true);
    setIsEditConditionViewVisible(false);
  };

  useEffect(() => {
    if (!activePageId) {
      return;
    }

    getContentPage({
      variables: {
        contentPageId: activePageId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageId]);

  return (
    <>
      <div className={`c-action ${!isActionViewVisible ? 'h-hide-visually' : null}`}>
        <div className="c-action__item c-action__item--select h-flex">
          <Label additionalClassNames="c-action__item__label h-flex" forId="event" labelText="Action">
            <Tooltip placement="left" text="Select an option to create show or hide event" />
          </Label>

          <Select
            name="dropdown"
            options={[
              {
                id: 2,
                name: 'Select an option',
                value: null,
              },
              {
                id: 1,
                name: 'Show',
                value: `QuestionActionShow${wrapper === 'targetSnippet' ? '' : capitalize(wrapper)}Type`,
              },
              {
                id: 0,
                name: 'Hide',
                value: `QuestionActionHide${wrapper === 'targetSnippet' ? '' : capitalize(wrapper)}Type`,
              },
            ]}
            value={selectedEvent}
            onChange={handleEventChange}
          />
        </div>

        {selectedAction && (
          <>
            <Spacing size="2x-large" />
            <div className="c-action__item h-flex">
              <Label additionalClassNames="c-action__item__label h-flex" forId="search" labelText="Search templates">
                <Tooltip placement="left" text="Search and choose one of the existing condition templates to reuse" />
              </Label>

              <Downshift itemToString={(item) => (item ? item.title : '')} onChange={handleApplyTemplate}>
                {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, getRootProps }) => (
                  <div className="c-autocomplete">
                    <div {...getRootProps({}, { suppressRefError: true })}>
                      <img alt="search" className="c-autocomplete__search-icon" src={SearchIcon} />
                      <input
                        className="c-autocomplete__search"
                        placeholder="Search"
                        required
                        {...getInputProps({
                          onChange: handleRecommendedSearchInput,
                          value: searchInputValue,
                        })}
                      />
                    </div>

                    <ul className="c-autocomplete-list" {...getMenuProps()}>
                      {isOpen && inputValue.trim()
                        ? templates
                            .slice(0, maxAutoCompleteSuggestions)
                            .filter((item) => item.title.toLowerCase().includes(inputValue))
                            .map((item, index) => (
                              <li
                                className="c-autocomplete-list__item"
                                {...getItemProps({
                                  key: item.title,
                                  index,
                                  item,
                                })}
                              >
                                {item.title}
                              </li>
                            ))
                        : null}
                    </ul>
                  </div>
                )}
              </Downshift>
              <Spacing size="2x-large" />
            </div>

            <div className="c-action__item h-flex">
              <Label additionalClassNames="c-action__item__label h-flex" forId="conditions" labelText="Conditions">
                <Tooltip
                  placement="left"
                  text={`List of conditions created for this ${wrapper === 'targetSnippet' ? 'snippet' : wrapper}`}
                />
              </Label>

              <div className="c-action__item__conditions-list">
                {selectedActionConditions.map((item) => (
                  <button
                    key={item.id}
                    className="c-action__item__condition h-flex"
                    type="button"
                    onClick={() => handleOpenUpdateCondition(item)}
                  >
                    <div className="c-action__item__condition__name h-flex">
                      <Icon className="c-action__item__three-dots-icon c-action__item__three-dots-icon-vertical" id="id_three_dots_icon" />
                      {item.title}
                    </div>
                    <Icon className="c-action__item__three-dots-icon" id="id_three_dots_icon" />
                  </button>
                ))}
              </div>
            </div>
          </>
        )}

        {selectedEvent && selectedEvent !== 'Select an option' ? (
          <div className="c-action__item h-flex">
            <Spacing size="large" />
            <button className="c-action__item__add" type="button" onClick={handleOpenAddCondition}>
              <Icon className="c-action__item__add-icon" id="id_add_rectangle_icon" />
            </button>
          </div>
        ) : null}

        <FormButtons additionalClassNames="c-form-buttons__action-tab" isDeleteButtonDisabled isSaveButtonDisabled onClose={onClose} />
      </div>

      {isNewConditionViewVisible && (
        <QuestionConditionNew
          actionWrapper={actionWrapper}
          activePageId={activePageId}
          conditionAction={selectedEvent}
          maxAutoCompleteSuggestions={maxAutoCompleteSuggestions}
          questionAction={selectedAction}
          titleMaxCharacter={maxCharacterCountTitle}
          wrapper={wrapper}
          wrapperId={wrapperId}
          onAddActionCompleted={handleAddActionCompleted}
          onAddConditionCompleted={handleAddConditionCompleted}
          onClose={handleAddConditionClose}
          onCloseSidebar={onClose}
        />
      )}

      {isEditConditionViewVisible && (
        <QuestionConditionEdit
          questionAction={selectedAction}
          questionCondition={selectedCondition}
          titleMaxCharacter={maxCharacterCountTitle}
          onClose={handleUpdateConditionClose}
          onCloseSidebar={onClose}
          onUpdateConditionCompleted={handleUpdateConditionCompleted}
        />
      )}
    </>
  );
};

ActionTab.propTypes = {
  actionWrapper: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['container', 'section', 'snippet']),
  }),
  activePageId: PropTypes.number,
  maxAutoCompleteSuggestions: PropTypes.number,
  maxCharacterCountTitle: PropTypes.number,
  onClose: PropTypes.func,
};

export { ActionTab };
