/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useEffect, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Button, Preloader, Table, Toolbar, ToggleSwitch } from '@netfront/ui-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FORMS_TABLE_COLUMNS } from './LibraryFormPage.constants';
import { getFormsTableData } from './LibraryFormPage.helpers';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import FormSideBar from '../../components/RightNavBar/FormSideBar';
import { SearchFilter } from '../../components/SearchFilter/SearchFilter';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import AddNewCard from '../../components/UI/Card/AddNewCard';
import { LoadingMenuCard } from '../../components/UI/Card/LoadingMenuCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { useToast } from '../../hooks';
import client from '../../middleware/client';
import GetContentGroupWithChildren from '../../middleware/ContentGroup/getContentGroupsWithChildren';
import { UPDATE_CONTENT_GROUP_STATUS } from '../../middleware/ContentGroup/updateContentGroupStatus';

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
  margin-top: 1.5rem;
`;

const CardAvatar = styled.div`
  align-items: center;
  background: transparent linear-gradient(65deg, #4bc1c9 0%, #4bc1d9 68%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6em;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: 0 auto 0.6em auto;
  max-height: 50px;
  max-width: 50px;
  width: 100%;
`;

const CardInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const LibraryFormPage = (props) => {
  const {
    match: { params },
  } = props;
  const { org, project } = useContext(AppContext);

  const { handleToastSuccess, handleToastError } = useToast();

  const [openPanel, setOpenPanel] = useState(false);
  const [formsLoaded, setFormsLoaded] = useState(false);
  const [formsTableData, setFormsTableData] = useState([]);
  const [formsTableFilteredData, setFormsTableFilteredData] = useState([]);
  const [forms, setForms] = useState([]);
  const [formsFiltered, setFormsFiltered] = useState([]);
  const [editForm, setEditForm] = useState({});
  const [selectedFormId, setSelectedFormId] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [isCardsViewVisible, setIsCardsViewVisible] = useState(false);
  const [isPublishForm, setIsPublishForm] = useState(false);

  const breadPath = [
    {
      id: 1,
      path: `/dashboard/${org.key}/project/${project.id}/library`,
      title: 'Library',
    },
    {
      id: 2,
      path: ``,
      title: 'Form library',
    },
  ];

  const handleDelete = () => {
    const newForms = forms.filter((obj) => obj.id !== editForm.id);
    setForms(newForms);
    setFormsFiltered(newForms);

    const updatedFormTableData = formsTableData.filter((obj) => obj.settingsButtonData.id !== editForm.id);

    setFormsTableData(updatedFormTableData);

    const updatedFormTableFilteredData = formsTableFilteredData.filter((obj) => obj.settingsButtonData.id !== editForm.id);
    setFormsTableFilteredData(updatedFormTableFilteredData);

    setOpenPanel(false);

    handleToastSuccess({
      message: 'Form deleted successfully',
    });
  };

  const [updateContentGroupStatus] = useMutation(UPDATE_CONTENT_GROUP_STATUS, {
    client,
    onCompleted() {
      if (!isPublishForm) {
        handleToastSuccess({
          message: 'Form activated successfully',
        });

        return;
      }

      handleToastSuccess({
        message: 'Form inactivated successfully',
      });
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const [executeDeleteForm, { loading: isDeleteFormLoading }] = useMutation(UPDATE_CONTENT_GROUP_STATUS, {
    client,
    onCompleted() {
      handleDelete();
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleContentGroupError = () => {
    setFormsLoaded(true);
  };

  const handleChangeSearchFilter = (event) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const handleClickSearchForm = () => {
    const filteredTableData = formsTableData.filter(
      ({ title, description }) =>
        title.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        description.trim().toLowerCase().includes(searchValue.toLowerCase()),
    );

    const filteredFormsData = formsFiltered.filter(
      ({ title, description }) =>
        title.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        description.trim().toLowerCase().includes(searchValue.toLowerCase()),
    );

    setFormsFiltered(filteredFormsData);
    setFormsTableFilteredData(filteredTableData);
  };

  const handleClickSettingsButton = (id) => {
    setSelectedFormId(id);
    setOpenPanel(true);
  };

  const handleGetContentGroups = (data) => {
    const filteredData = data.filter((u) => u.type === 'FORM');

    setFormsTableData(getFormsTableData(filteredData, handleClickSettingsButton, org.id, project.id));
    setFormsTableFilteredData(getFormsTableData(filteredData, handleClickSettingsButton, org.id, project.id));
    setFormsLoaded(true);
    setForms(filteredData);
    setFormsFiltered(filteredData);
  };

  const handleGroupAvatar = (title) => {
    const splitTitle = title.split(' ');
    return splitTitle.length > 1 ? `${splitTitle[0].split('')[0]}${splitTitle[1].split('')[0]}` : title.split('')[0];
  };

  const handleClosePanel = () => {
    setOpenPanel(false);
    setSelectedFormId();
    setEditForm({});
  };

  const handleEditForm = (e, item) => {
    e.preventDefault();
    setOpenPanel(true);
    setEditForm(item);
    selectedFormId(item.id);
  };

  const handleSave = () => {
    setFormsLoaded(false);
    setOpenPanel(false);
  };

  function handleUpdate({ group: { title, description } }) {
    const temp = [...forms];
    const index = temp.findIndex((form) => form.id === editForm.id);
    temp[index] = {
      ...temp[index],
      title,
      description,
      status: !isPublishForm ? 'Active' : 'Inactive',
    };
    setForms(temp);
    setFormsFiltered(temp);

    const updatedFormTableData = formsTableFilteredData.map((form) => {
      if (selectedFormId === form.settingsButtonData.id) {
        return {
          ...form,
          title,
          description,
          status: !isPublishForm ? 'Active' : 'Inactive',
        };
      }

      return form;
    });

    setFormsTableData(updatedFormTableData);
    setFormsTableFilteredData(updatedFormTableData);

    updateContentGroupStatus({
      variables: {
        contentGroupId: editForm.id,
        status: !isPublishForm ? 'PUBLISHED' : 'UNPUBLISHED',
      },
    });
  }

  useEffect(() => {
    if (!forms.length) {
      return;
    }

    if (!selectedFormId) {
      return;
    }

    setEditForm(forms.find((form) => form.id === selectedFormId));
  }, [forms, selectedFormId]);

  const isLoading = !formsTableData.length || isDeleteFormLoading;

  const menu = (
    <CardsBox>
      {!formsLoaded && <LoadingMenuCard count={11} />}
      {formsFiltered.map((form) => (
        <MenuCard
          key={form.id}
          edit={(e) => handleEditForm(e, form)}
          href={`/dashboard/${org.key}/project/${project.id}/form-builder/form/${form.id}/?library=true`}
          name={form.title}
          src={form.image}
        >
          <CardInner>
            <CardAvatar>{handleGroupAvatar(form.title)}</CardAvatar>
          </CardInner>
        </MenuCard>
      ))}
      <AddNewCard onClick={() => setOpenPanel(true)} />
    </CardsBox>
  );

  return (
    <>
      <Preloader isLoading={isLoading} />
      <PageWrapper
        breadPath={breadPath}
        info="Manage your forms"
        pageDescription="Manage all your forms"
        pageTitle="Form library page"
        params={params}
      >
        <ProjectNavBar title="Forms" />

        {openPanel && editForm && (
          <FormSideBar
            closeFromBar={handleClosePanel}
            groupId={editForm.id}
            isEdit={Boolean(editForm.id)}
            isPublishForm={isPublishForm}
            onDelete={() =>
              executeDeleteForm({
                variables: {
                  contentGroupId: editForm.id,
                  status: 'DELETED',
                },
              })
            }
            onPublishForm={setIsPublishForm}
            onSave={handleSave}
            // eslint-disable-next-line react/jsx-no-bind
            onUpdate={handleUpdate}
          />
        )}

        {openPanel && <Backdrop isClicked={handleClosePanel} />}

        <Toolbar
          additionalClassNames="c-forms-toolbar"
          childrenEnd={
            <>
              <ToggleSwitch
                additionalClassNames="c-forms-toggle"
                id="forms-toggle"
                isChecked={isCardsViewVisible}
                labelText={!isCardsViewVisible ? 'Show cards view' : 'Show table view'}
                onChange={() => setIsCardsViewVisible(!isCardsViewVisible)}
              />

              <Button text="Add new +" type="button" variant="primary-inverse" onClick={() => setOpenPanel(true)} />
            </>
          }
          childrenStart={
            <SearchFilter
              id="forms-filter"
              labelText="Forms filter"
              name="forms-filter"
              placeholder="Name, description"
              type="text"
              value={searchValue}
              isLabelHidden
              onChange={handleChangeSearchFilter}
              onClear={() => {
                setSearchValue('');
                setFormsTableFilteredData(formsTableData);
                setFormsFiltered(forms);
              }}
              onSearch={handleClickSearchForm}
            />
          }
        />

        {isCardsViewVisible ? menu : <Table columns={FORMS_TABLE_COLUMNS} data={formsTableFilteredData} />}
      </PageWrapper>
      {!formsLoaded && Object.entries(project).length !== 0 && (
        <GetContentGroupWithChildren projectId={project.id} onError={handleContentGroupError} onSuccessResult={handleGetContentGroups} />
      )}
    </>
  );
};

LibraryFormPage.propTypes = {
  history: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    push: PropTypes.any,
  }).isRequired,
  match: PropTypes.shape().isRequired,
};

export default LibraryFormPage;
