import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ContactConfirmation(props) {
  const { handleResend } = props;
  return (
    <>
      <img
        alt="tick"
        src="https://netfront.com.au/img/kanzi/static/tick.svg"
        style={{ width: '40px', marginTop: '1em' }}
      />
      <p
        style={{
          margin: '1em 0',
          padding: '0 1em',
          textAlign: 'center',
        }}
      >
        Thank you, please check your email for your reset link.
      </p>
      <p
        style={{
          margin: '1em 0',
          padding: '0 1em',
          textAlign: 'center',
        }}
      >
        If you have not recieved the link check your junk/spam or click resend
        below.
      </p>
      <div>
        <button
          className="button--outline fw-600 mr-3"
          onClick={handleResend}
          type="button"
        >
          RESEND
        </button>
        <Link to="/login">
          <button className="button--red" type="button">
            LOGIN
          </button>
        </Link>
      </div>
    </>
  );
}

ContactConfirmation.propTypes = {
  handleResend: PropTypes.func,
};

ContactConfirmation.defaultProps = {
  handleResend: () => {},
};

export default ContactConfirmation;
