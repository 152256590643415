import { gql } from '@apollo/client';

const START_CONTENT_PAGE_COMPLETION_MUTATION = gql`
  mutation startContentPageCompletion($contentPageId: Int!, $userFlowStepTrackId: Int!) {
    userFlowStepTrack {
      startContentPageCompletion(pageId: $contentPageId, trackingId: $userFlowStepTrackId) {
        id
      }
    }
  }
`;

export { START_CONTENT_PAGE_COMPLETION_MUTATION };
