import React from 'react';

import { SummaryResultSnippetProps } from './SummaryResultSnippet.interfaces';

import { IQuestionAnswer } from '../../../interfaces';
import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

const SummaryResultSnippet = ({ additionalMessage, answer, isCorrect, question }: SummaryResultSnippetProps) => {
  const { questionText } = question ?? {};

  const getAnswer = (inputAnswer?: IQuestionAnswer) => {
    if (!inputAnswer) {
      return '';
    }

    const { dateAnswered, numberAnswered, questionResponse, selectedAnswers, text, __typename: typename } = inputAnswer;

    switch (typename) {
      case 'QuestionAnswerCalendarType':
        return dateAnswered;
      case 'QuestionAnswerCheckboxType':
        return (
          <ol className="ek-quiz-feedback ek-quiz-feedback-list">
            {selectedAnswers.map(({ id, questionResponse: checkboxAnswerQuestionResponse }) => {
              const { label } = checkboxAnswerQuestionResponse ?? {};

              return (
                <li key={id} className="ek-quiz-feedback ek-quiz-feedback-list-item">
                  {label}
                </li>
              );
            })}
          </ol>
        );
      case 'QuestionAnswerDropDownListType':
      case 'QuestionAnswerMultiResponseTextType':
      case 'QuestionAnswerSingleLineTextType':
        return text;
      case 'QuestionAnswerNumberType':
        return numberAnswered;
      case 'QuestionAnswerRadioType': {
        const { label } = questionResponse ?? {};

        return label;
      }
      default:
        return '';
    }
  };

  return (
    <React.Fragment>
      {isCorrect && (
        <div className="ek-quiz-feedback ek-quiz-feedback--correct">
          <div className="ek-quiz-feedback__icon">
            <svg fill="none" height="24" stroke="currentColor" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 13l4 4L19 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
            <div className="ek-quiz-feedback__message__question">
              <DOMPurifySanitizedHtmlContent html={questionText} />
            </div>
          </div>
          <div className="ek-quiz-feedback__message">
            <p className="ek-quiz-feedback__message__response">{getAnswer(answer)}</p>
          </div>
        </div>
      )}
      {!isCorrect && (
        <div className="ek-quiz-feedback ek-quiz-feedback--incorrect">
          <div className="ek-quiz-feedback__icon">
            <svg fill="none" height="24" stroke="currentColor" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
            <div className="ek-quiz-feedback__message__question">
              <DOMPurifySanitizedHtmlContent html={questionText} />
            </div>
          </div>
          <div className="ek-quiz-feedback__message">
            <p className="ek-quiz-feedback__message__response">{getAnswer(answer)}</p>
            <div className="ek-quiz-feedback__additional_message">
              <DOMPurifySanitizedHtmlContent html={additionalMessage} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export { SummaryResultSnippet };
