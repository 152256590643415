import gql from 'graphql-tag';

export const EXPORT_FEEDBACK = gql`
  query ExportFeedback(
    $ianaTimeZone: String!
    $projectId: String
    $url: String
    $dateFrom: DateTime
    $dateTo: DateTime
    $fileType: FileType
  ) {
    report {
      exportFeedback(
        ianaTimeZone: $ianaTimeZone
        projectId: $projectId
        url: $url
        dateFrom: $dateFrom
        dateTo: $dateTo
        fileType: $fileType
      ) {
        contentType
        s3Key
      }
    }
  }
`;
