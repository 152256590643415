import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import ContentGroupSideBar from '../../components/RightNavBar/ContentGroupSideBar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import AddNewCard from '../../components/UI/Card/AddNewCard';
import { LoadingMenuCard } from '../../components/UI/Card/LoadingMenuCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import GetContentGroups from '../../middleware/ContentGroup/getContentGroups';
import { capitalize, getBreadCrumbPath } from '../../utils/utils';

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
`;

const CardAvatar = styled.div`
  align-items: center;
  background: transparent linear-gradient(65deg, #4bc1c9 0%, #4bc1d9 68%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6em;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: 0 auto 0.6em auto;
  max-height: 50px;
  max-width: 50px;
  width: 100%;
`;

const CardInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const STATUS_COLORS = {
  UNPUBLISHED: '#FA6980',
  PUBLISHED: '#4FBD9C',
};

const GroupStatus = styled.div`
  background-color: ${({ status }) => STATUS_COLORS[status]};
  border-radius: 20px;
  color: #fff;
  font-size: 0.9em;
  padding: 0.13em 0.6em;
  text-transform: capitalize;
`;

const ContentGroupsPage = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const history = useHistory();

  const { type } = params;

  const [groupLoaded, setGroupLoaded] = useState(false);
  const [contentGroups, setContentGroups] = useState([]);
  const [contentGroup, setContentGroup] = useState({});
  const [openPanel, setOpenPanel] = useState(false);

  const hadleEditContentGroup = (e, item) => {
    e.preventDefault();
    setContentGroup(item);
    setOpenPanel(true);
  };

  const handleGroupAvatar = (title) =>
    title
      .split(' ')
      .map((word) => word.substring(0, 1))
      .join('')
      .substring(0, 2);

  const handleClosePanel = () => {
    setContentGroup({});
    setOpenPanel(false);
  };

  const onCreate = (id) => {
    setOpenPanel(false);
    history.push(`${pathname}/${id}/content/`);
  };

  const onUpdate = (data) => {
    const newContentGroups = contentGroups.filter((group) => group.id !== data.id);
    newContentGroups.push(data);
    setContentGroups(newContentGroups);
    setOpenPanel(false);
  };

  const onDelete = (id) => {
    const newContentGroups = contentGroups.filter((obj) => obj.id !== id);
    setContentGroups(newContentGroups);
    setOpenPanel(false);
  };

  const handleContentGroupError = () => {
    setGroupLoaded(true);
  };

  const handleGetContentGroups = (data) => {
    setGroupLoaded(true);
    setContentGroups(data);
  };

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Content group library'), ...getBreadCrumbPath(pathname, 0, capitalize(type))];

  const content = (
    <CardsBox>
      {!groupLoaded && <LoadingMenuCard count={11} />}
      {contentGroups
        .sort((a, b) => a.sort - b.sort)
        .map((item) => {
          const logoURL = item.iconAsset ? getSignedUrl(item.iconAsset.s3Key, item.iconAsset.contentType) : '';
          return (
            <MenuCard
              edit={(e) => hadleEditContentGroup(e, item)}
              href={`${pathname}/${item.id}/content`}
              key={item.id}
              name={item.title}
              src={logoURL}
            >
              <CardInner>
                {!logoURL && <CardAvatar>{handleGroupAvatar(item.title)}</CardAvatar>}
                <GroupStatus status={item.status}>{item.status.toLowerCase()}</GroupStatus>
              </CardInner>
            </MenuCard>
          );
        })}
      <AddNewCard onClick={() => setOpenPanel(true)} />
    </CardsBox>
  );

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage your content groups"
        pageDescription="Manage all your content groups"
        pageTitle="Content groups management page"
        params={params}
      >
        <ProjectNavBar title={type} />
        {openPanel && (
          <ContentGroupSideBar
            contentGroup={contentGroup}
            onCancel={handleClosePanel}
            onCreate={onCreate}
            onDelete={onDelete}
            onUpdate={onUpdate}
            type={type || 'LEARNING'}
          />
        )}
        {openPanel && <Backdrop isClicked={handleClosePanel} />}
        {content}
      </PageWrapper>
      {!groupLoaded && (
        <GetContentGroups
          onError={handleContentGroupError}
          onSuccessResult={handleGetContentGroups}
          projectId={params.projectId}
          type={type.toUpperCase()}
        />
      )}
    </>
  );
};

export default ContentGroupsPage;
