import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_GROUP_MEMBERSHIP_LIST = gql`
  query getGroupsMember($projectId: String!) {
    membership {
      getGroupsMemberWithinProject(projectId: $projectId) {
        id
        isProjectAdmin
        userId
        user {
          id
          firstname
          lastname
          communityName
          status
          credential {
            email
            services
            phoneNumber
          }
        }
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;

const getGroupsMemberWithinProject = ({ projectId, onError, onSuccessResult }) => (
  <Query client={Client()} query={GET_GROUP_MEMBERSHIP_LIST} variables={{ projectId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.membership.getGroupsMemberWithinProject}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getGroupsMemberWithinProject.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default getGroupsMemberWithinProject;
