import gql from 'graphql-tag';

export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount(
    $id: Int!
    $code: String
    $description: String
    $maxUsage: Int
    $percentage: Decimal
    $period: DiscountPeriodInput
  ) {
    discounts {
      update(
        request: {
          id: $id
          code: $code
          description: $description
          maxUsage: $maxUsage
          percentage: $percentage
          period: $period
        }
      ) {
        id
      }
    }
  }
`;
