import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_DATA = gql`
  query getAppUserData($appApiKey: String, $appComponentId: Int) {
    appUserData {
      getUserData(appApiKey: $appApiKey, appComponentId: $appComponentId)
    }
  }
`;

const getUserData = ({
  appApiKey,
  appComponentId,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={GET_DATA}
    variables={{ appApiKey, appComponentId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.appUserData.getUserData
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getUserData.propTypes = {
  appApiKey: PropTypes.string.isRequired,
  appComponentId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getUserData;
