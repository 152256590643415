const getDropdownOptions = (defaultOptionId, defaultOptionLabel, options, textKeys, valueKey) => {
  const defaultOption = [
    {
      id: defaultOptionId,
      label: defaultOptionLabel,
      value: defaultOptionId,
    },
  ];

  const customOptions = options.map(option => {
    const value = option[valueKey];

    return {
      id: value,
      label: textKeys.map(textKey => option[textKey]).join(' - '),
      value,
    };
  });

  return customOptions.length ? [...defaultOption, ...customOptions] : null;
};

export default getDropdownOptions;
