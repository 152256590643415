import gql from 'graphql-tag';

export const EXPORT_USERS = gql`
  query ($projectId: String!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportGroupUsers(projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
      }
    }
  }
`;
