import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
// eslint-disable-next-line camelcase
import { ForgotPassword_Mutation, Client } from '@netfront/gelada-react-shared';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import InputHolder from '../../components/InputHolder/InputHolder';
import { graphqlErrorToString } from '../../utils/utils';

function validate({ username }) {
  return ValidationProcessor({
    UsernameEmpty: {
      validation: username.trim().length > 0,
      errorMessage: 'Username must not be empty',
    },
  });
}

export function ForgotPasswordForm({ setError, onComplete, onResetPassword }) {
  const [username, setUsername] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [mutation] = useMutation(ForgotPassword_Mutation, {
    client: Client(false),
  });

  function handleSubmit(e) {
    setSubmitting(true);
    const validationErrors = validate({ username });

    e.preventDefault();

    if (!validationErrors.modelValid) {
      setError(validationErrors.validations);
    } else {
      const cookie = new Cookies();
      cookie.remove('_a');
      setError('');

      if (submitting) return;

      mutation({ variables: { email: username } })
        .then(data => {
          onResetPassword();
          onComplete(data);
        })
        .catch(error => {
          setError(graphqlErrorToString(error));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }

  return (
    <form className="login-page__form" onSubmit={handleSubmit}>
      <InputHolder
        label="Username"
        name="username"
        onChange={({ target: { value } }) => setUsername(value)}
        required
        value={username}
      />
      <button
        className="button--outline"
        disabled={submitting}
        style={{
          margin: '1em 0 1em auto',
          textTransform: 'uppercase',
        }}
        type="submit"
      >
        Send
      </button>
    </form>
  );
}

ForgotPasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};
