import React from 'react';

import Avatar from '../../../../components/UI/Avatar/colorAvatar';
import RoundSettingsButton from '../../../../components/UI/Button/RoundSettingsButton/RoundSettingsButton';
import { ActiveStatus } from '../../../OrganisationUsersPage/styled';

export const createUserTypeTableData = (data, onEdit) =>
  data.map((user, id) => {
    const { name: title, status: userTypeStatus } = user;
    const isUserTypeEnabled = userTypeStatus === 'ENABLED';

    const type = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar name={`U ${title}`} />
        <p style={{ marginLeft: '1rem' }}> {title}</p>
      </div>
    );

    const status = (
      <div>
        <ActiveStatus isActive={isUserTypeEnabled} />
        {isUserTypeEnabled && 'Active'}
      </div>
    );
    const button = <RoundSettingsButton onClick={() => onEdit(user)} />;

    return {
      id,
      type,
      status,
      button,
    };
  });
