import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import SwitchBtn from '../Button/SwitchButton';
import Dropdown from '../Dropdown/Dropdown';
import Tooltip from '../Tooltip/Tooltips';

import { animationTypeOptions, animationSpeedOptions, delayTimeOptions, directionOptions } from '../../RightNavBar/DropdownOptions';
import { Title, Container, Item } from '../../RightNavBar/styled';

const hasAniDirection = ['BOUNCE_IN', 'FADE_IN', 'ZOOM_IN', 'SLIDE_IN', 'ROTATE_IN'];

const AnimationPart = props => {
  const { updateEventDetail, currentDetail } = props;

  const [showAniDirection, setShowAniDirection] = useState(false);
  const [animationDetail, setAnimationDetail] = useState(currentDetail);

  useEffect(() => {
    setAnimationDetail(currentDetail);
    setShowAniDirection(hasAniDirection.includes(currentDetail.animationType));
  }, [currentDetail]);

  const handleAnimationUpdate = (value, e) => {
    if (e.target.name === 'animationType') {
      const show = hasAniDirection.includes(value);
      setShowAniDirection(show);
    }

    const newDetail = {
      ...animationDetail,
      [e.target.name]: value,
    };

    setAnimationDetail(newDetail);
    updateEventDetail(newDetail);
  };

  return (
    <Container>
      <Item>
        <Title>
          <b>Animation Type</b>
          <Tooltip />
        </Title>
        <Dropdown
          availableResponses={animationTypeOptions}
          name="animationType"
          selectedValue={animationDetail.animationType}
          onChangeHandler={(_, value, event) => handleAnimationUpdate(value, event)}
        />
      </Item>
      {showAniDirection && (
        <Item>
          <Title>
            <b>Animation Direction</b>
            <Tooltip />
          </Title>
          <Dropdown
            availableResponses={directionOptions}
            name="animationDirection"
            selectedValue={animationDetail.animationDirection || 'TOP'}
            onChangeHandler={(_, value, event) => handleAnimationUpdate(value, event)}
          />
        </Item>
      )}
      <Item>
        <Title>
          <b>Delay Time</b>
          <Tooltip />
        </Title>
        <Dropdown
          availableResponses={delayTimeOptions}
          name="delayTime"
          selectedValue={(animationDetail.delayTime || '').toString()}
          onChangeHandler={(_, value, event) => handleAnimationUpdate(value, event)}
        />
      </Item>
      <Item>
        <Title>
          <b>Animation Speed</b>
          <Tooltip />
        </Title>
        <Dropdown
          availableResponses={animationSpeedOptions}
          name="animationSpeed"
          selectedValue={(animationDetail.animationSpeed || '').toString()}
          onChangeHandler={(_, value, event) => handleAnimationUpdate(value, event)}
        />
      </Item>
      <Item>
        <Title>
          <b>Infinite</b>
          <Tooltip />
        </Title>
        <SwitchBtn id={1} name="infinite" onChange={handleAnimationUpdate} value={animationDetail.infinite} />
      </Item>
    </Container>
  );
};

AnimationPart.propTypes = {
  currentDetail: PropTypes.shape({
    animationType: PropTypes.string,
  }).isRequired,
  updateEventDetail: PropTypes.func.isRequired,
};

export default AnimationPart;
