import React from 'react';

import cx from 'classnames';

import { Label } from '../Label';

import { ToggleSwitchProps } from './ToggleSwitch.types';

import './ToggleSwitch.css';

const ToggleSwitch = ({
  additionalClassNames,
  id,
  isChecked,
  isDisabled = false,
  isLabelHidden = false,
  labelText,
  onChange,
}: ToggleSwitchProps) => (
  <div className={cx('c-toggle-switch__wrapper', additionalClassNames)}>
    <Label forId={id} isHidden={isLabelHidden} labelText={labelText}>
      <div className="c-toggle-switch" data-testid="qa-toggle-switch">
        <span className="h-hide-visually">Toggle{id}</span>
        <input
          checked={isChecked}
          className="c-toggle-switch__checkbox"
          data-testid="qa-toggle-switch-input"
          disabled={isDisabled}
          id={id}
          type="checkbox"
          onChange={onChange}
        />
        <span className="c-toggle-switch__slider" role="presentation" />
      </div>
    </Label>
  </div>
);

export { ToggleSwitch };
