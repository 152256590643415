import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CreateQuestion } from '../Form/CreateQuestion';
import FormAdvance from '../Form/FormAdvance';
import { ResponseRequest } from '../Form/ResponseRequest';
import { responseLongToShortConst, responseShortToFriendly } from '../Form/responseSetTypeConstant';
import {
  AddNewResponse,
  IconImg,
  InfoIcon,
  ModeTitle,
  QuestionContainer,
  QuestionEditor,
  Response,
  ResponseIcon,
  ResponseSet,
  ResponseTypes,
  ResTooltip,
  Search,
  SearchSuggetionsBox,
  SearchSuggetionsRow,
  StyledTextarea,
  SwitchBox,
} from '../Form/styled';
import PopupMessage from '../PopupMessage/PopupMessage';
import ResponseSideBar from '../RightNavBar/ResponseSideBar';
import Backdrop from '../UI/Backdrop/Backdrop';
import ShowHideButton from '../UI/Button/ShowHideButton';
import SwitchButton from '../UI/Button/SwitchButton';
import TextEditor from '../UI/TextEditor/Editor';
import Tooltip from '../UI/Tooltip/Tooltips';

import { AdvanceTitle } from './styled';

import AppContext from '../../context/AppContext';
import client from '../../middleware/client';
import SearchResponseSetRequest from '../../middleware/FormContainer/ResponseSet/searchResponseSetRequest';
import SearchSliderConfRequest from '../../middleware/FormContainer/ResponseSet/searchSliderConfRequest';
import { UPDATE_QUESTION_CONFIGURATION_CHECKBOX } from '../../middleware/FormContainer/updateQuestionConfigurationCheckbox';
import { UPDATE_QUESTION_CONFIGURATION_RADIO } from '../../middleware/FormContainer/updateQuestionConfigurationRadio';
import CBIcon from '../../static/img/ResType/Checkbox.svg';
import CaIcon from '../../static/img/ResType/Date.svg';
import DDIcon from '../../static/img/ResType/DragDrop.svg';
import DdIcon from '../../static/img/ResType/Dropdown.svg';
import MaIcon from '../../static/img/ResType/Match.svg';
import MLIcon from '../../static/img/ResType/MultiLine.svg';
import MRIcon from '../../static/img/ResType/MultiResponse.svg';
import NuIcon from '../../static/img/ResType/Number.svg';
import RBIcon from '../../static/img/ResType/RadioButton.svg';
import SLIcon from '../../static/img/ResType/SingleLine.svg';
import SlIcon from '../../static/img/ResType/Slider.svg';

const ResMenu = [
  { type: 'SL', src: SLIcon },
  { type: 'ML', src: MLIcon },
  { type: 'RB', src: RBIcon },
  { type: 'CB', src: CBIcon },
  { type: 'Dd', src: DdIcon },
  { type: 'Nu', src: NuIcon },
  { type: 'Sl', src: SlIcon },
  { type: 'Ma', src: MaIcon },
  { type: 'Ca', src: CaIcon },
  { type: 'DD', src: DDIcon },
  { type: 'MR', src: MRIcon },
];

const ResSet = (type) => {
  switch (type) {
    case 'SL':
      return false;
    case 'ML':
      return false;
    case 'RB':
      return true;
    case 'CB':
      return true;
    case 'Dd':
      return true;
    case 'Nu':
      return false;
    case 'Sl':
      return true;
    case 'Ma':
      return true;
    case 'DD':
      return true;
    case 'Ca':
      return false;
    case 'MR':
      return false;
    default:
      return false;
  }
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -0.5em;
`;

const EditableQuestionSnippet = forwardRef((props, ref) => {
  const { containerId, onCancel, isSidebar, onCreate, onUpdate, snippet } = props;

  const isUpdate = Boolean(snippet);

  const [resType, setResType] = useState(responseLongToShortConst[snippet?.configuration?.__typename] || '');
  const [showAddRes, setShowAddRes] = useState(false);
  const [text, setText] = useState(snippet?.question || '');
  const [searchRespSetRequest, setSearchRespSetRequest] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [responseTitle, setResponseTitle] = useState(snippet?.configuration?.responseSet?.title || '');
  const [searchSuggetions, setSearchSuggetions] = useState([]);
  const [searchRespSetSliderRequest, setSearchRespSetSliderRequest] = useState(false);
  const { project } = useContext(AppContext);
  const [mode, setMode] = useState(false);
  const [question, setQuestionText] = useState('');

  // CreateQuestion State
  const [majorNumber, setMajorNumber] = useState(snippet?.majorNumber || '');
  const [minorNumber, setMinorNumber] = useState();
  const [customNumber, setCustomNumber] = useState();
  const [minResponses, setMinResponses] = useState(3);
  const [maxResponses, setMaxResponses] = useState(10);

  const [allowUserGeneratedResponses, setAllowUserGeneratedResponses] = useState(
    snippet?.configuration?.allowUserGeneratedResponses || false,
  );

  const [maxCheckedResponse] = useState(snippet?.configuration?.maxCheckedResponse || 0);

  const [canModify] = useState(snippet?.configuration?.canModify || true);

  const [executeUpdateQuestionConfigurationCheckbox] = useMutation(UPDATE_QUESTION_CONFIGURATION_CHECKBOX, {
    client,
  });

  const [executeUpdateQuestionConfigurationRadio] = useMutation(UPDATE_QUESTION_CONFIGURATION_RADIO, {
    client,
  });

  const [correctResponseMessage, setCorrectResponseMessage] = useState(snippet?.configuration?.messages?.correct || '');
  const [incorrectResponseMessage, setIncorrectResponseMessage] = useState(snippet?.configuration?.messages?.incorrect || '');

  const [incompleteResponseMessage, setIncompleteResponseMessage] = useState(snippet?.configuration?.messages?.imcomplete || '');
  const [exampleResponseMessage, setExampleResponseMessage] = useState(snippet?.configuration?.messages?.example || '');

  const [isMandatory, setIsMandatory] = useState(snippet?.mandatory ?? true);
  const [isVisible, setIsVisible] = useState(snippet?.visible ?? true);
  const [defaultDate, setDefaultDate] = useState(
    snippet?.configuration?.defaultDate ? new Date(snippet?.configuration?.defaultDate) : new Date(),
  );
  const [regex, setRegex] = useState(snippet?.configuration?.regex || '');
  const [title, setTitle] = useState('');
  const [defaultValue, setDefaultValue] = useState(snippet?.configuration?.defaultValue || 0);
  const [maxValue, setMaxValue] = useState(snippet?.configuration?.maxValue || 100);
  const [minValue, setMinValue] = useState(snippet?.configuration?.minValue || 0);
  const [minDate, setMinDate] = useState(
    snippet?.configuration?.minDate ? new Date(snippet?.configuration?.minDate) : new Date('1979-01-01'),
  );
  const [maxDate, setMaxDate] = useState(
    snippet?.configuration?.maxDate ? new Date(snippet?.configuration?.maxDate) : new Date('2022-12-31'),
  );
  const [showAdvance, setShowAdvance] = useState(false);
  const [minLength, setMinLength] = useState(snippet?.configuration?.minLength || 1);
  const [maxLength, setMaxLength] = useState(snippet?.configuration?.maxLength || 1000);
  const [responseSet, setResponseSet] = useState(snippet?.configuration?.responseSet?.availableResponses || []);
  const [maxResponseChecked, setMaxResponseChecked] = useState(snippet?.configuration?.maxResponseChecked);
  const [minValueSl, setMinValueSl] = useState(snippet?.configuration?.minValue || 0);
  const [maxValueSl, setMaxValueSl] = useState(snippet?.configuration?.maxValue || 10);
  const [defaultValueSl, setDefaultValueSl] = useState(snippet?.configuration?.defaultValue || 5);
  const [errMsg, setErrMsg] = useState('');
  const [stepSl, setStepSl] = useState(snippet?.configuration?.step || 1);
  const [questConfId, setQuestConfId] = useState(snippet?.configuration?.id);
  // response bar
  const [showResBar, setShowResBar] = useState(false);
  // set response set
  const [isDefineResponseReq, setDefineResponseReq] = useState(false);
  const [responseSetID, setResponseSetID] = useState(snippet?.configuration?.responseSet?.id);
  const [hasQuestionSnippet, setCreateQuestionSnippet] = useState(false);
  const [hasUpdateQuestionSnippet, setUpdateQuestionSnippet] = useState(false);
  const [hasCreateQuestionReq, setSendCreateQuestionReq] = useState(false);
  const [cleanText, setCleanText] = useState(false);
  const [isExistingResponseSet, setExistingResponseSet] = useState(false);
  const [matchType, setMatchType] = useState();

  const onSelectResponseSet = (id) => {
    setResponseSetID(id);
    setExistingResponseSet(true);
  };

  const handleCreateSection = (id, data) => {
    setText('');
    setQuestConfId(id);

    if (responseSetID) {
      setDefineResponseReq(true);
    } else if (!responseSetID && data && Object.keys(data).length > 0) {
      setResponseSetID(data.questionConfiguration.createQuestionConfiguration.responseSet.id);
    }
    setSendCreateQuestionReq(false);
    if (isUpdate) {
      setUpdateQuestionSnippet(true);
    } else {
      setCreateQuestionSnippet(true);
    }
  };

  const handleSlResponseType = (data) => {
    const temp = [];
    setMinValueSl(data.minValue);
    setMaxValueSl(data.maxValue);
    setDefaultValueSl(data.defaultValue);
    setStepSl(data.step);
    setTitle(data.title);
    setQuestConfId(data.id);
    if (data.ticks.length) {
      data.ticks.map((item) => {
        temp.push({
          label: item.label,
          sliderValue: Number(item.sliderValue),
        });
        return false;
      });
    }
    setResponseSet(temp);
  };

  const handleText = (value) => {
    if (cleanText) {
      setCleanText(false);
    }
    setText(value);
  };

  const getResType = (value) => {
    setResType(value);
  };

  const onChangeTitle = (inputTitle) => {
    setTitle(inputTitle);
  };

  const setResponseSetData = (data) => {
    const temp = [];
    if (resType === 'Sl') {
      data.map((item) => {
        temp.push({
          label: item.label,
          sliderValue: Number(item.value),
          // id:temp.length
        });
        return false;
      });
    } else {
      data.map((item) => {
        temp.push({
          value: item.value.toString(),
          label: item.label,
          scoreValue: 1,
          assetId: item.assetId,
          // id:temp.length
        });
        return false;
      });
    }
    setResponseSet(temp);
  };

  const onSuccessDefineResponseReq = () => {
    setResponseSetID();
    setResponseSet([]);
    setDefineResponseReq(false);
    setResType('');
    setTitle('');
  };

  const onClickResponsSideBar = () => {
    setShowResBar(false);
  };

  const handleCreateSectionError = (error) => {
    setText('');
    setErrMsg(error);
    setTimeout(() => {
      setErrMsg('');
    }, 3000);
    setSendCreateQuestionReq(false);
  };

  const handleTextContent = (value) => {
    handleText(value);
    setText(value);
  };

  const addResponse = () => {
    setExistingResponseSet(false);
    setResponseSetID();
    setResponseSet([]);
    setShowResBar(true);
  };

  const onSuccessCreateRadioRes = () => {
    setResponseSetID();
    setResponseSet([]);
  };

  const handleSourceChange = (e) => {
    handleText(e.target.value);
    setText(e.target.value);
  };

  const handleResType = (type) => {
    if (type === 'Ma') setMatchType('COLUMN');
    if (type === 'DD') setMatchType('ROW');
    setResponseTitle('');
    setSearchSuggetions([]);
    const showRes = ResSet(type);
    getResType(type);
    setShowAddRes(showRes);
    setResType(type);
  };

  const setPreviewRespData = (data) => {
    const temp = [];
    if (resType === 'Sl') {
      data.map((item) => {
        temp.push({
          label: item.label,
          sliderValue: Number(item.value),
        });
        return false;
      });
    } else {
      data.map((item) => {
        temp.push({
          value: item.value.toString(),
          label: item.label,
          scoreValue: 1,
        });
        return false;
      });
    }
  };

  const onChangeFormAdvanceInput = ({ target: { name, value } }) => {
    switch (name) {
      case 'allowUserGeneratedResponses': {
        setAllowUserGeneratedResponses(value);
        break;
      }
      case 'correctResponseMessage': {
        setCorrectResponseMessage(value);
        break;
      }
      case 'customNumber': {
        setCustomNumber(Number.parseInt(value, 10));
        break;
      }
      case 'defaultDate': {
        setDefaultDate(new Date(value));
        break;
      }
      case 'defaultValue': {
        setDefaultValue(value);
        break;
      }
      case 'exampleResponseMessage': {
        setExampleResponseMessage(value);
        break;
      }
      case 'incompleteResponseMessage': {
        setIncompleteResponseMessage(value);
        break;
      }
      case 'incorrectResponseMessage': {
        setIncorrectResponseMessage(value);
        break;
      }
      case 'isMandatory': {
        setIsMandatory(value);
        break;
      }
      case 'isVisible': {
        setIsVisible(value);
        break;
      }
      case 'majorNumber': {
        setMajorNumber(value);
        break;
      }
      case 'maxDate': {
        setMaxDate(new Date(value));
        break;
      }
      case 'maxLength': {
        setMaxLength(Number.parseInt(value, 10));
        break;
      }
      case 'maxResponseChecked': {
        setMaxResponseChecked(value);
        break;
      }
      case 'maxResponses': {
        setMaxResponses(Number.parseInt(value, 10));
        break;
      }
      case 'maxValue': {
        setMaxValue(Number.parseInt(value, 10));
        break;
      }
      case 'minDate': {
        setMinDate(new Date(value));
        break;
      }
      case 'minLength': {
        setMinLength(Number.parseInt(value, 10));
        break;
      }
      case 'minorNumber': {
        setMinorNumber(Number.parseInt(value, 10));
        break;
      }
      case 'minResponses': {
        setMinResponses(Number.parseInt(value, 10));
        break;
      }
      case 'minValue': {
        setMinValue(Number.parseInt(value, 10));
        break;
      }
      case 'regex': {
        setRegex(value);
        break;
      }
      default: {
        throw new Error('no updater available');
      }
    }
  };

  useImperativeHandle(ref, () => ({
    clearAll() {
      setResponseTitle('');
      setSearchSuggetions([]);
      setShowAddRes(false);
      setResType('');
    },
  }));

  const updateExistingQuestion = () => {
    setQuestionText(text);
    setUpdateQuestionSnippet(true);

    let mutationFunc = null;
    let variables = null;

    if (resType.toUpperCase() === 'CB') {
      mutationFunc = executeUpdateQuestionConfigurationCheckbox;
      variables = {
        allowUserGeneratedResponses,
        maxCheckedResponse,
        questionConfigurationId: questConfId,
        majorNumber,
      };
    } else if (resType.toUpperCase() === 'RB') {
      mutationFunc = executeUpdateQuestionConfigurationRadio;
      variables = {
        allowUserGeneratedResponses,
        canModify,
        questionConfigurationId: questConfId,
        majorNumber,
      };
    }

    if (mutationFunc) {
      mutationFunc({
        variables,
      });
    }
  };

  const saveNewQuestion = () => {
    if (text !== '' && resType !== '') {
      setQuestionText(text);
      if (!questConfId) {
        setSendCreateQuestionReq(true);
      } else {
        setCreateQuestionSnippet(true);
      }
    } else if (text === '') {
      setErrMsg('Please add a question');
      setTimeout(() => {
        setErrMsg('');
      }, 3000);
    } else if (resType === '') {
      setErrMsg('Please select a response');
      setTimeout(() => {
        setErrMsg('');
      }, 3000);
    }
  };

  const saveQuestion = () => {
    if (isUpdate) {
      updateExistingQuestion();
    } else {
      saveNewQuestion();
    }
  };

  const onChangeSliderInput = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    if (inputName === 'minValueSl') {
      setMinValueSl(inputValue);
    } else if (inputName === 'maxValueSl') {
      setMaxValueSl(inputValue);
    } else if (inputName === 'defaultValueSl') {
      setDefaultValueSl(inputValue);
    } else if (inputName === 'stepSl') {
      setStepSl(inputValue);
    }
  };

  if (question && question.resType && !resType) {
    setResType(responseLongToShortConst[question.resType]);
    getResType(responseLongToShortConst[question.resType]);
    handleResType(responseLongToShortConst[question.resType]);
  }

  const TypeGroup = ResMenu.map((item) => (
    <ResponseIcon isActive={resType === item.type} key={item.type} onClick={() => handleResType(item.type)}>
      <IconImg alt="icon" isActive={resType === item.type} src={item.src} />
      <ResTooltip>{responseShortToFriendly[item.type]}</ResTooltip>
    </ResponseIcon>
  ));

  const handleOnSuccessSearchRespSet = (data) => {
    const suggetions = [];
    if (resType === 'Sl') {
      if (data.length) {
        data.map((item) =>
          suggetions.push({
            title: item.description,
            id: item.id,
            ticks: item.ticks,
            maxValue: item.maxValue,
            minValue: item.minValue,
            defaultValue: item.defaultValue,
            step: item.step,
          }),
        );
      }
    } else if (data.length) {
      data.map((item) =>
        suggetions.push({
          title: item.title,
          id: item.id,
          availableResponses: item.availableResponses,
        }),
      );
    }
    setSearchRespSetSliderRequest(false);
    setSearchRespSetRequest(false);
    setSearchSuggetions(suggetions);
  };

  const onErrorCreateRadioRes = () => {
    setResponseSetID();
  };

  const handleOnErrorSearchRespSet = () => {
    setSearchRespSetRequest(false);
  };

  const onSearchContentGroup = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setResponseTitle(e.target.value);
    setTypingTimeout(
      setTimeout(() => {
        if (resType === 'Sl') {
          setSearchRespSetSliderRequest(true);
        } else {
          setSearchRespSetRequest(true);
        }
      }, 300),
    );
  };

  const onSuggetionClick = (items) => {
    setResponseTitle(items.title);
    const temp = [];
    if (resType === 'Sl') {
      handleSlResponseType(items);
    } else {
      items.availableResponses.map((item) => {
        temp.push({
          value: item.value,
          label: item.label,
          scoreValue: Number(item.value) ? Number(item.value) : 0,
        });
        return false;
      });
      onSelectResponseSet(items.id);
      setResponseSet(temp);
    }
    setSearchSuggetions([]);
  };

  const onErrorDefineResponseReq = () => {
    setResponseSetID();
    setResponseSet([]);
    setResType('');
    setDefineResponseReq(false);
  };

  const onErrorCreateQueSnippetRequest = (error) => {
    setErrMsg(error);
    setTimeout(() => {
      setErrMsg('');
    }, 3000);
    setQuestionText('');
    setCleanText(true);
    setCreateQuestionSnippet(false);
    if (!(resType === 'CB' || resType === 'RB' || resType === 'Dd')) {
      setResType('');
    }
  };

  const onCreateQueSnippetRequest = () => {
    setQuestionText('');
    setCleanText(true);
    setCreateQuestionSnippet(false);
    if (!((resType === 'CB' || resType === 'RB' || resType === 'Dd') && !isDefineResponseReq && responseSetID)) {
      setResType('');
    }
  };

  const onSuccessResult = (id, sort, snippetType, snippetContainerId, data) => {
    setQuestionText('');
    setCleanText(true);
    setCreateQuestionSnippet(false);

    if (!((resType === 'CB' || resType === 'RB' || resType === 'Dd') && !isDefineResponseReq && responseSetID)) {
      setResType('');
    }

    if (isUpdate) {
      onUpdate(snippet.id, snippet.sort, 'ContentSnippetQuestionType', containerId, data);
    } else {
      onCreate(id, sort, 'ContentSnippetQuestionType', snippetContainerId, data);
    }
  };

  const typeResponse = {
    RB: 'RADIO',
    CB: 'CHECKBOX',
    Ma: 'MATCH',
    Dd: 'DROP_DOWN_LIST',
  };

  return (
    <div>
      <QuestionContainer isSidebar={isSidebar}>
        <QuestionEditor isSidebar={isSidebar} isUpdate={isUpdate}>
          <SwitchBox>
            <ModeTitle>
              Source view
              <Tooltip text="If adding inline style in advanced mode, inline style will be lost in basic mode." />
            </ModeTitle>
            <SwitchButton defaultChecked={false} id={1} name="mode" onChange={() => setMode(!mode)} />
          </SwitchBox>
          {!mode ? (
            <TextEditor getContent={handleTextContent} isCleanText={cleanText} value={text} />
          ) : (
            <StyledTextarea minRows={8} onChange={handleSourceChange} value={text} />
          )}
        </QuestionEditor>
        {!isUpdate && (
          <Response isSidebar={isSidebar}>
            {!isSidebar && <ResponseTypes>{TypeGroup}</ResponseTypes>}
            {showAddRes && resType !== 'Ma' && resType !== 'DD' && (
              <ResponseSet>
                <Search
                  list="browsers"
                  name="browser"
                  onChange={onSearchContentGroup}
                  placeholder="search response type "
                  value={responseTitle}
                />
                <SearchSuggetionsBox className="d-flex flex-column">
                  {searchSuggetions.map((item) => (
                    <SearchSuggetionsRow key={item.id} onClick={() => onSuggetionClick(item)}>
                      {item.title}
                    </SearchSuggetionsRow>
                  ))}
                </SearchSuggetionsBox>
                <AddNewResponse onClick={() => addResponse()}>
                  <InfoIcon>+</InfoIcon>
                  Add new {resType === 'RB' ? 'Radio Button' : null}
                  {resType === 'CB' ? 'Check Box' : null}
                  {resType === 'Dd' ? 'Dropdown' : null}
                  {resType === 'Sl' ? 'Slider' : null}
                  {resType === 'Ma' ? 'Match' : null}
                </AddNewResponse>
              </ResponseSet>
            )}
            {showAddRes && (resType === 'Ma' || resType === 'DD') && (
              <ResponseSet>
                <AddNewResponse onClick={() => addResponse()}>
                  <InfoIcon>+</InfoIcon>
                  {resType === 'Ma' ? 'Match' : 'Drag & Drop'} config
                </AddNewResponse>
              </ResponseSet>
            )}
          </Response>
        )}

        {searchRespSetRequest && (
          <SearchResponseSetRequest
            onError={handleOnErrorSearchRespSet}
            onSuccessResult={handleOnSuccessSearchRespSet}
            projectId={project.id}
            title={responseTitle}
            type={typeResponse[resType]}
          />
        )}
        {searchRespSetSliderRequest && resType === 'Sl' && (
          <SearchSliderConfRequest
            configurationName={responseTitle}
            onError={handleOnErrorSearchRespSet}
            onSuccessResult={handleOnSuccessSearchRespSet}
            projectId={project.id}
          />
        )}
      </QuestionContainer>
      <ButtonGroup>
        <button className="button--black mt-4 mr-3" onClick={onCancel} type="button">
          Cancel
        </button>
        <button className="button--blue mt-4 mr-3" onClick={saveQuestion} type="button">
          {isUpdate ? 'Update' : 'Save'}
        </button>
      </ButtonGroup>
      <CreateQuestion
        allowUserGeneratedResponses={allowUserGeneratedResponses}
        containerId={containerId}
        correctResponseMessage={correctResponseMessage}
        customNumber={customNumber}
        defaultDate={defaultDate}
        defaultValue={defaultValue}
        defaultValueSl={defaultValueSl}
        exampleResponseMessage={exampleResponseMessage}
        handleCreateSection={handleCreateSection}
        handleCreateSectionError={handleCreateSectionError}
        hasCreateQuestionReq={hasCreateQuestionReq}
        hasQuestionSnippet={hasQuestionSnippet}
        hasUpdateQuestionSnippet={hasUpdateQuestionSnippet}
        incompleteResponseMessage={incompleteResponseMessage}
        incorrectResponseMessage={incorrectResponseMessage}
        isExistingResponseSet={isExistingResponseSet}
        isMandatory={isMandatory}
        isVisible={isVisible}
        majorNumber={majorNumber}
        maxDate={maxDate}
        maxLength={maxLength}
        maxResponseChecked={maxResponseChecked || responseSet.length}
        maxResponses={maxResponses}
        maxValue={maxValue}
        maxValueSl={maxValueSl}
        minDate={minDate}
        minLength={minLength}
        minorNumber={minorNumber}
        minResponses={minResponses}
        minValue={minValue}
        minValueSl={minValueSl}
        onCreateQueSnippetRequest={onCreateQueSnippetRequest}
        onErrorCreateQueSnippetRequest={onErrorCreateQueSnippetRequest}
        onSuccessResult={onSuccessResult}
        projectId={project.id}
        questConfId={questConfId}
        question={question}
        regex={regex}
        responseSet={responseSet}
        resType={resType}
        snippetId={snippet?.id}
        stepSl={stepSl}
        text={text}
        title={title}
        type="ROW"
      />
      <AdvanceTitle>
        Advance
        {!showAdvance ? (
          <ShowHideButton isClicked={() => setShowAdvance(true)} />
        ) : (
          <ShowHideButton direction="top" isClicked={() => setShowAdvance(false)} />
        )}
      </AdvanceTitle>
      {showAdvance ? (
        <FormAdvance
          allowUserGeneratedResponses={allowUserGeneratedResponses}
          correctResponseMessage={correctResponseMessage}
          customNumber={customNumber}
          defaultDate={defaultDate}
          defaultValue={defaultValue}
          exampleResponseMessage={exampleResponseMessage}
          incompleteResponseMessage={incompleteResponseMessage}
          incorrectResponseMessage={incorrectResponseMessage}
          isMandatory={isMandatory}
          isVisible={isVisible}
          majorNumber={majorNumber}
          maxDate={maxDate}
          maxLength={maxLength}
          maxResponseChecked={maxResponseChecked}
          maxResponses={maxResponses}
          maxValue={maxValue}
          minDate={minDate}
          minLength={minLength}
          minResponses={minResponses}
          minValue={minValue}
          onChangeFormAdvanceInput={onChangeFormAdvanceInput}
          regex={regex}
          resType={resType}
        />
      ) : null}
      {errMsg && <PopupMessage isError message={errMsg} />}
      {showResBar && (
        <div>
          <ResponseSideBar
            defaultValueSl={defaultValueSl}
            description={text}
            matchType={matchType}
            maxValueSl={maxValueSl}
            minValueSl={minValueSl}
            onChangeSliderInput={onChangeSliderInput}
            onChangeTitle={onChangeTitle}
            onClick={onClickResponsSideBar}
            projectId={project.id}
            resType={resType}
            setPreviewRespData={setPreviewRespData}
            setQuestConfId={setQuestConfId}
            setResponseSetData={setResponseSetData}
            setResponseSetID={setResponseSetID}
            stepSl={stepSl}
          />
          <Backdrop isClicked={() => setShowResBar(false)} />
        </div>
      )}
      <ResponseRequest
        isDefineResponseReq={isDefineResponseReq}
        isExistingResponseSet={isExistingResponseSet}
        onCreateError={onErrorCreateRadioRes}
        onCreateSuccess={onSuccessCreateRadioRes}
        onDefineError={onErrorDefineResponseReq}
        onDefineSuccess={onSuccessDefineResponseReq}
        questConfId={questConfId}
        responseSet={responseSet}
        responseSetID={responseSetID}
        resType={resType}
      />
    </div>
  );
});

EditableQuestionSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isSidebar: PropTypes.bool,
  snippet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sort: PropTypes.number.isRequired,
    configuration: PropTypes.shape({
      allowUserGeneratedResponses: PropTypes.bool,
      responseSet: PropTypes.shape({
        __typename: PropTypes.string,
        availableResponses: PropTypes.arrayOf(),
      }),
    }),
  }),
};

EditableQuestionSnippet.defaultProps = {
  isSidebar: false,
  snippet: {
    configuration: {
      allowUserGeneratedResponses: false,
      responseSet: {
        availableResponses: [],
      },
    },
  },
};

export default EditableQuestionSnippet;
