import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

import { COMMON_LIBRARY_CONSTANTS } from '../../constants';

const {
  MESSAGES: {
    ERROR: { ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED, NOT_FOUND },
  },
} = COMMON_LIBRARY_CONSTANTS;

const getS3Client = (): S3Client => {
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

  if (!accessKeyId) {
    throw new Error(`AWS access key id ${NOT_FOUND} - ${ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED}`);
  }

  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

  if (!secretAccessKey) {
    throw new Error(`AWS secret access key ${NOT_FOUND} - ${ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED}`);
  }

  const region = process.env.REACT_APP_AWS_REGION;

  if (!region) {
    throw new Error(`AWS region ${NOT_FOUND} - ${ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED}`);
  }

  return new S3Client({
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
    region,
  });
};

const getS3SignedUrlObjectCommand = (key: string, responseContentType: string, bucket?: string): GetObjectCommand => {
  const bucketName = bucket ?? process.env.REACT_APP_AWS_S3_BUCKET_NAME;

  if (!bucketName) {
    throw new Error(`AWS bucket name ${NOT_FOUND} - ${ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED}`);
  }

  return new GetObjectCommand({
    Bucket: bucketName,
    Key: key,
    ResponseContentType: responseContentType,
  });
};

const getS3SignedUrl = async (key: string, responseContentType: string, expiryTimeInSeconds?: number, bucket?: string): Promise<string> => {
  const expiresIn = expiryTimeInSeconds ?? process.env.REACT_APP_AWS_S3_SIGNED_URL_EXPIRY_TIME_IN_SECONDS;

  if (!expiresIn) {
    throw new Error(`AWS signed url expiry time ${NOT_FOUND} - ${ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED}`);
  }

  const client = getS3Client();
  const command = getS3SignedUrlObjectCommand(key, responseContentType, bucket);

  return getSignedUrl(client, command, {
    expiresIn: Number(expiresIn),
  });
};

export { getS3Client, getS3SignedUrl, getS3SignedUrlObjectCommand };
