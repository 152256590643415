import React, { useEffect, useState, useRef } from 'react';

import { useMutation } from '@apollo/react-hooks';

import { useSocialState } from '../../../../context/SocialPage/SocialPageContext';
import { useApolloClient } from '../../../../middleware/apollo/useApolloClient';
import { REMOVE_TOPIC } from '../../../../middleware/Social/deleteTopic';
import { EDIT_TOPIC } from '../../../../middleware/Social/editTopic';
import { ModalButtonGroup } from '../../../RightNavBar/RightNavBar';
import Buttons from '../../../SideBar/Buttons/Buttons';
import SquareButton from '../../../UI/Button/SquareButton';
import Input from '../../../UI/Input/InputWhite';
import NormalModal from '../../../UI/Modal/NormalModal';
import NotificationMessage from '../../../UI/NotificationMessage/NotificationMessage';
import Tooltip from '../../../UI/Tooltip/Tooltips';

import '../../../SideBar/Views/Views.scss';

const Topic = ({ onCloseHandler }) => {
  const client = useRef(useApolloClient('bonobo')).current;
  const [pageState, dispatch] = useSocialState();
  const { activeTopicId: topicId, topics } = pageState;
  const [title, setTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [notifications, setNotifications] = useState([]);

  const handleClearNotifications = () => {
    setNotifications([]);
  };

  const handleTopicError = (error) => {
    setNotifications([
      ...notifications,
      {
        group: 'topic',
        message: error,
        type: 'error',
      },
    ]);
  };

  const [editTopicRequest] = useMutation(EDIT_TOPIC, {
    client,
    onCompleted(response) {
      const {
        topic: { editTopic },
      } = response;
      dispatch({
        type: 'editTopic',
        newState: editTopic,
      });
      setNotifications([
        ...notifications,
        {
          group: 'topic',
          message: 'Topic successfully updated',
          type: 'success',
        },
      ]);
    },
    onError(error) {
      handleTopicError(error.message);
    },
  });

  const [deleteTopicRequest] = useMutation(REMOVE_TOPIC, {
    client,
    onCompleted() {
      setIsModalOpen(false);
      dispatch({
        type: 'deleteTopic',
        newState: topicId,
      });
      setNotifications([
        ...notifications,
        {
          group: 'topic',
          message: 'Topic successfully deleted',
          type: 'info',
        },
      ]);

      setTimeout(() => {
        handleClearNotifications();
        onCloseHandler();
      }, 1000);
    },
    onError(error) {
      handleTopicError(error.message);
    },
  });

  const handleSave = () => {
    editTopicRequest({
      variables: {
        topicId,
        title,
      },
    });
  };

  const handleDeleteTopic = () => {
    deleteTopicRequest({
      variables: {
        topicId,
      },
    });
  };

  useEffect(() => {
    if (!topicId) {
      return;
    }

    if (!topics.length) {
      return;
    }

    const activeTopic = topics.find((topic) => topic.id === topicId);
    const { title: activeTitle } = activeTopic || {};

    setTitle(activeTitle);
  }, [topicId, topics]);

  return (
    <>
      <NormalModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        Are you sure you want to delete this topic?
        <ModalButtonGroup>
          <SquareButton isClicked={() => setIsModalOpen(false)}>No, cancel</SquareButton>
          <SquareButton bgColor="#dc3545" color="white" isClicked={handleDeleteTopic}>
            Yes, delete
          </SquareButton>
        </ModalButtonGroup>
      </NormalModal>
      <div className="c-view-wrapper">
        <fieldset>
          <legend className="h-hide">Edit Topic</legend>
          <div className="h-flex h-justify-content-between h-align-items-center">
            <div>
              <label className="c-input-title h-margin-bottom-sm" htmlFor="topic-input">
                Edit topic
              </label>
            </div>
            <Tooltip text="Topic title" />
          </div>
          <Input id="topic-input" isChanged={(value) => setTitle(value)} text={title} value={title} />
          {notifications.map(({ group, message, type }) => {
            if (!group.includes('topic')) {
              return null;
            }

            return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
          })}
          <div className="c-sidebar-button-container c-flex-center">
            <Buttons onClose={onCloseHandler} onDelete={() => setIsModalOpen(true)} onSave={handleSave} />
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default Topic;
