import { getObjectFromSerializedString } from '../object';

import { EXPIRY_TIME_UNIT_CALCULATION_FUNCTION_MAP } from './storage.constants';
import { IStorageExpiryOptions } from './storage.interfaces';
import { StorageKeyType } from './storage.types';

const removeItemFromLocalStorage = (key: StorageKeyType): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.removeItem(key);
};

const getItemWithExpiry = (itemValue: string, storageExpiryOptions: IStorageExpiryOptions): string => {
  const {
    currentTimeValueInMilliseconds,
    expiryTime: { unit: expiryTimeUnit, value: expiryTimeValue },
  } = storageExpiryOptions;

  const expiryInMilliseconds = EXPIRY_TIME_UNIT_CALCULATION_FUNCTION_MAP[expiryTimeUnit](expiryTimeValue);

  const item = {
    expiry: currentTimeValueInMilliseconds + expiryInMilliseconds,
    value: itemValue,
  };

  return JSON.stringify(item);
};

const getItemFromLocalStorage = (key: StorageKeyType): string | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const item = localStorage.getItem(key);

  if (!item) {
    return undefined;
  }

  const object = getObjectFromSerializedString(item);
  if (!object) {
    return item;
  }

  const { expiry } = object;

  if (!expiry) {
    return item;
  }

  const now = new Date();

  if (now.getTime() > expiry) {
    removeItemFromLocalStorage(key);
    return undefined;
  }

  return object.value;
};

const saveItemToLocalStorage = (key: StorageKeyType, value: string): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(key, value);
};

const saveItemWithExpiryToLocalStorage = (key: StorageKeyType, value: string, storageExpiryOptions: IStorageExpiryOptions): void => {
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(key, getItemWithExpiry(value, storageExpiryOptions));
};

export { getItemFromLocalStorage, getItemWithExpiry, removeItemFromLocalStorage, saveItemToLocalStorage, saveItemWithExpiryToLocalStorage };
