import React from 'react';

import cx from 'classnames';

import { Spacing } from '../Spacing';

import { LabelProps } from './Label.types';

import './Label.css';

const Label = ({ children, additionalClassNames, forId, isHidden, isRequired, spacing = 'small', labelText }: LabelProps) => {
  return (
    <label className={cx('c-label', additionalClassNames)} htmlFor={forId}>
      <Spacing size={spacing}>
        <span
          className={cx('c-label__text', {
            'h-hide-visually': isHidden,
          })}
        >
          {labelText}
          {isRequired && (
            <abbr aria-label="required" title="required">
              *
            </abbr>
          )}
        </span>
      </Spacing>
      {children}
    </label>
  );
};

export { Label };
