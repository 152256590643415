import React from 'react';

import { DIRECTORY_TABLE_COLUMNS } from './DirectoryTable.constants';
import { getDirectoryTableData } from './DirectoryTable.helpers';

import { EKReactTable } from '../../../../components/Table/EKReactTable/EKReactTable';

const DirectoryTable = ({ directories, filter, onEdit }) => (
  <EKReactTable
    columns={DIRECTORY_TABLE_COLUMNS}
    data={getDirectoryTableData(directories, (item, isManageStatusFlag) => onEdit(item, isManageStatusFlag))}
    filter={filter}
  />
);

export { DirectoryTable };
