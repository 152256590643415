import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
`;

const StarIcon = styled.button`
  color: ${props => (props.active ? '#d9e021' : '#e1e1e1')};
  display: flex;
  font-size: 1.2em;
  margin: 0 0.2em 0 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
`;

export default function StarRating(props) {
  const { amount, hasNumber, rating, isChanged, name } = props;

  const [newRate, setNewRate] = useState(rating);

  useEffect(() => {
    setNewRate(rating);
  }, [rating]);

  const handleRating = (value, e) => {
    setNewRate(value);
    isChanged(value, e);
  };

  return (
    <Container>
      {[...Array(amount)].map((icon, key) => {
        const index = `icon-${key}`;
        return (
          <StarIcon
            active={key + 1 <= newRate}
            key={index}
            name={name}
            onClick={e => handleRating(Number(key + 1), e)}
          >
            &#9733;
          </StarIcon>
        );
      })}
      {hasNumber && rating}
    </Container>
  );
}

StarRating.propTypes = {
  amount: PropTypes.number,
  hasNumber: PropTypes.bool,
  isChanged: PropTypes.func,
  name: PropTypes.string,
  rating: PropTypes.number,
};

StarRating.defaultProps = {
  amount: 5,
  hasNumber: true,
  isChanged: () => {},
  name: 'star',
  rating: undefined,
};
