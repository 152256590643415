import { ApiNameType } from './api.types';

const API_ENDPOINTS: Record<ApiNameType, string | undefined> = Object.freeze({
  bonobo: process.env.REACT_APP_BONOBO_API_URL,
  capuchin: process.env.REACT_APP_CAPUCHIN_API_URL,
  ekardo: process.env.REACT_APP_EKARDO_API_URL,
  gelada: process.env.REACT_APP_GELADA_API_URL,
  howler: process.env.REACT_APP_HOWLER_API_URL,
  kanzi: process.env.REACT_APP_KANZI_API_URL,
  muriqui: process.env.REACT_APP_MURIQUI_API_URL,
  tamarin: process.env.REACT_APP_TAMARIN_API_URL,
  vervet: process.env.REACT_APP_VERVET_API_URL,
});

const API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS = Number(process.env.REACT_APP_API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

export { API_ENDPOINTS, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS };
