import React from 'react';

import cx from 'classnames';

import { MessageProps } from './Message.interfaces';

import './Message.css';

const Message = ({ id, text, type }: MessageProps) => {
  const classNames = cx({
    'c-error-message': type === 'ERROR',
    'c-success-message': type === 'SUCCESS',
  });

  return (
    <React.Fragment>
      {Boolean(text) && (
        <div className={classNames} data-testid={id} id={id}>
          <span className="c-message__text">{text}</span>
        </div>
      )}
    </React.Fragment>
  );
};

export { Message };
