import React, { Fragment, ReactNode, useEffect, useState } from 'react';

import { getS3SignedUrl } from '@netfront/common-library';
import { ConfirmationMessages, RadioButtonItem } from '@netfront/ui-library';
import to from 'await-to-js';
import cx from 'classnames';

import { getHtmlElementOnChangeValueFromEvent } from '../Snippets.helpers';

import { RadioButtonSnippetProps } from './RadioButtonSnippet.interfaces';

import { ERROR_MESSAGES } from '../../../core';

import './RadioButtonSnippet.css';

const RadioButtonSnippet = ({
  id,
  isLabelHidden,
  isRequired,
  label,
  message,
  name,
  onChange,
  options,
  selectedValue,
}: RadioButtonSnippetProps) => {
  const [radioButtonOptionLabelComponentMap, setRadioButtonOptionLabelComponentMap] = useState<Record<string, ReactNode>>();

  useEffect(() => {
    const loadRadioButtonOptionLabelComponentMap = async () => {
      const radioButtonOptionLabelComponents = await Promise.all(
        options.map(async ({ asset, id: optionId }) => {
          const { alt, contentType, s3Key } = asset ?? {};

          if (!(contentType && s3Key)) {
            return [];
          }

          const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

          if (getS3SignedUrlError) {
            throw getS3SignedUrlError;
          }

          if (!s3SignedUrl) {
            throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
          }

          const labelContentComponent = (
            <Fragment>
              {s3SignedUrl && <img alt={alt} className="c-radio-button-asset" src={s3SignedUrl} />}
              <span aria-hidden="true" className="c-radio-button__icon" />
            </Fragment>
          );

          return [
            {
              [optionId]: labelContentComponent,
            },
          ];
        })
      );

      setRadioButtonOptionLabelComponentMap(
        radioButtonOptionLabelComponents.flat().reduce(
          (accumulator, item) => ({
            ...accumulator,
            ...item,
          }),
          {}
        )
      );
    };

    loadRadioButtonOptionLabelComponentMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { error: errorMessage, success: successMessage } = message ?? {};

  return (
    <fieldset aria-describedby={errorMessage ? `id_${name}_error` : undefined}>
      <legend className="c-label">
        <span
          className={cx('c-label__text', {
            'h-hide-visually': isLabelHidden,
          })}
        >
          {label}
        </span>
        {isRequired && (
          <abbr aria-label="required" className="is-required" title="required">
            *
          </abbr>
        )}
      </legend>
      {options.map(({ deleteButton, id: optionId, isDisabled, label: optionLabel, value: optionValue }) => {
        const labelContentComponent = radioButtonOptionLabelComponentMap ? radioButtonOptionLabelComponentMap[optionId] : null;
        const radioButtonId = `${id}-${optionId}`;

        return (
          <div key={`${radioButtonId}-container`} className="c-radio-button__container">
            <RadioButtonItem
              id={radioButtonId}
              isChecked={optionValue === selectedValue}
              isDisabled={isDisabled}
              labelContentComponent={labelContentComponent}
              labelText={optionLabel}
              name={String(name)}
              value={optionValue}
              onChange={(event) => {
                const updatedSelectedValue = getHtmlElementOnChangeValueFromEvent(event);

                onChange(updatedSelectedValue);
              }}
            />
            {deleteButton}
          </div>
        );
      })}
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </fieldset>
  );
};

export { RadioButtonSnippet };
