import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_SPACER_SNIPPET = gql`
  mutation createSpacerSnippet(
    $containerId: Int!
    $visible: Boolean!
    $height: Int!
    $lineBreak: Boolean!
    $widthPercentage: Int
    $alignment: EHorizontalAlignment
  ) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible }
        contentSnippetSpacer: {
          height: $height
          lineBreak: $lineBreak
          widthPercentage: $widthPercentage
          alignment: $alignment
        }
      ) {
        id
        sort
      }
    }
  }
`;

const createSpacerSnippetRequest = ({
  alignment,
  containerId,
  isVisible,
  height,
  lineBreak,
  widthPercentage,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_SPACER_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        alignment,
        containerId,
        visible: isVisible,
        height,
        lineBreak,
        widthPercentage,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.contentSnippet.createContentSnippet.id,
          data.contentSnippet.createContentSnippet.sort
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createSpacerSnippetRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  lineBreak: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  alignment: PropTypes.objectOf,
  isVisible: PropTypes.bool,
  widthPercentage: PropTypes.number,
};

createSpacerSnippetRequest.defaultProps = {
  alignment: 'CENTER',
  isVisible: true,
  widthPercentage: 100,
};

export default createSpacerSnippetRequest;
