import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_POPUP_CONTENT_SNIPPET = gql`
  mutation updatePopupContentSnippet(
    $snippetId: Int!
    $text: String!
    $author: String!
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId }
        contentSnippetQuote: { text: $text, author: $author }
      ) {
        id
      }
    }
  }
`;

const updateQuoteContentSnippetRequest = ({
  snippetId,
  text,
  author,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_POPUP_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        text,
        author,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateQuoteContentSnippetRequest.propTypes = {
  author: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default updateQuoteContentSnippetRequest;
