/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';

import peniclImage from '../../static/img/pencil.svg';
import { StatusEnum, ContentStatusEnum } from '../../utils/utils';

function ProjectCard(props) {
  const {
    children,
    expires,
    image,
    name,
    onClick,
    status,
    projectId,
    defaultCard,
    paymentId,
    hadlePanelOpen,
    orgId,
    editProject,
    editOrg,
    deleteCard,
    publishStatus,
  } = props;
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {editProject && (
        <div className="edit-icon-holder">
          <img
            alt="edit"
            className="edit-icon"
            onClick={editProject.bind(this, projectId)}
            onKeyPress={editProject.bind(this, projectId)}
            src={peniclImage}
          />
        </div>
      )}
      {editOrg && (
        <div className="edit-icon-holder">
          <img
            alt="edit"
            className="edit-icon"
            onClick={editOrg.bind(this, orgId)}
            onKeyPress={editOrg.bind(this, orgId)}
            src={peniclImage}
          />
        </div>
      )}
      {deleteCard && (
        <button
          className="button-none"
          onClick={deleteCard.bind(this, paymentId)}
          onKeyPress={deleteCard.bind(this, paymentId)}
          type="button"
        >
          <span className="icon-settings edit-icon" />
        </button>
      )}
      <button
        className="button-none w-100"
        onClick={onClick && onClick.bind(this, projectId)}
        onKeyPress={onClick && onClick.bind(this, projectId)}
        type="button"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1em 1em 0.5em',
          }}
        >
          {/* <img alt="ekardo" src={image} style={{ maxWidth: '80%' }} /> */}
          <div className="project-avatar">{name && name.charAt(0)}</div>
        </div>
        <p
          className={!image ? 'c-project-card__number' : ''}
          style={{ textAlign: 'center', fontWeight: 'bold' }}
        >
          {name}
        </p>
        {expires && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0.8em 0.8em 0em',
            }}
          >
            <span>Expires:</span>
            <span
              className="c-project-card__status"
              style={{ color: '#303941' }}
            >
              {expires}
            </span>
          </div>
        )}
        {defaultCard !== undefined && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0.8em 0.8em 0em',
              paddingBottom: '0.8em',
            }}
          >
            <span>Default:</span>
            <button
              className="button-none"
              onClick={hadlePanelOpen && hadlePanelOpen.bind(this, paymentId)}
              onKeyPress={
                hadlePanelOpen && hadlePanelOpen.bind(this, paymentId)
              }
              type="button"
            >
              <span
                className={`c-project-card__status${
                  defaultCard ? '--active' : ''
                }`}
              >
                {defaultCard ? 'Default' : 'Alternative'}
              </span>
            </button>
          </div>
        )}
        {children}
        {status !== null && (
          <div
            className="mb-2"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0.8em 0.8em 0em',
            }}
          >
            <span>Status:</span>
            <span
              className={`c-project-card__status${
                status === StatusEnum.ACTIVE ? '--active' : ''
              }`}
            >
              {status === StatusEnum.ACTIVE ? 'Active' : 'Inactive'}
            </span>
          </div>
        )}
        {publishStatus !== null && (
          <div className="pb-3 pt-2 text-center">
            <span
              className={`c-project-card__status${
                publishStatus === ContentStatusEnum.PUBLISHED ? '--active' : ''
              }`}
            >
              {publishStatus === ContentStatusEnum.PUBLISHED
                ? 'Published'
                : 'Unpublished'}
            </span>
          </div>
        )}
      </button>
    </div>
  );
}

ProjectCard.propTypes = {
  children: PropTypes.element,
  defaultCard: PropTypes.string,
  deleteCard: PropTypes.func,
  editOrg: PropTypes.func,
  editProject: PropTypes.func,
  expires: PropTypes.string,
  hadlePanelOpen: PropTypes.func,
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  orgId: PropTypes.number,
  paymentId: PropTypes.string,
  projectId: PropTypes.string,
  publishStatus: PropTypes.string,
  status: PropTypes.string,
};

ProjectCard.defaultProps = {
  children: null,
  defaultCard: undefined,
  deleteCard: undefined,
  editOrg: undefined,
  editProject: undefined,
  expires: null,
  hadlePanelOpen: undefined,
  image: null,
  name: '',
  onClick: undefined,
  orgId: null,
  paymentId: null,
  projectId: null,
  publishStatus: null,
  status: null,
};

export default ProjectCard;
