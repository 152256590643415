import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;

  input {
    background-color: transparent;
  }
`;
export const DropDownContainer = styled.div`
  background-color: #fff;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  border-radius: 0 0 4px 4px;
  min-width: 150px;
  max-height: 250px;
  overflow: scroll;
  position: absolute;
  top: 100%;
  z-index: 999;

  ul {
    font-weight: normal;
    list-style-type: none;
    padding: 0;

    li {
      cursor: pointer;
      padding: 0.5em;

      &:hover {
        background-color: rgb(244, 245, 247);
      }
    }
  }
`;

export const InputContainer = styled.div`
  background-color: ${({ isOpen }) => (isOpen ? 'rgb(244, 245, 247)' : 'transparent')};
  border: 1px solid #e4e4e3;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  position: relative;
  min-width: 150px;

  input {
    border: none;
    margin: 0;
    min-width: 100%;
    padding-right: 1.8em;
    text-align: left;
  }

  svg {
    right: 0.2em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: rgb(244, 245, 247);
  }
`;
