import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_PROJECT_INVITATION_REQUEST = gql`
  mutation createProjectInvite(
    $firstName: String!
    $lastName: String!
    $emailInvited: String!
    $projectId: String!
    $permission: EMemberPermission!
    # $position: String
    $message: String
  ) {
    invitation {
      projectInvitation(
        invitation: {
          firstname: $firstName
          lastname: $lastName
          invitedEmail: $emailInvited
          projectId: $projectId
          permission: $permission
          # title: $position
          customMessage: $message
        }
      ) {
        id
        invitedUserEmail: emailUserInvited
        tokenId
      }
    }
  }
`;

const createProjectInvitationRequest = ({
  firstName,
  lastName,
  emailInvited,
  projectId,
  permission,
  position,
  message,
  onError,
  onSuccessResult,
}) => (
  <Mutation client={Client()} mutation={CREATE_PROJECT_INVITATION_REQUEST}>
    {(createProjectInvite, { loading, error, data }) => {
      const variables = {
        firstName,
        lastName,
        emailInvited,
        projectId,
        permission,
        position,
        message,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.invitation.createProjectInvitation);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createProjectInvite}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createProjectInvitationRequest.propTypes = {
  emailInvited: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  message: PropTypes.string,
  position: PropTypes.string,
};

createProjectInvitationRequest.defaultProps = {
  message: '',
  position: '',
};

export default createProjectInvitationRequest;
