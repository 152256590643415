import React, { useContext } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

import AppContext from '../../context/AppContext';

export const SEARCH_CONTENT_GROUP_REQUEST = gql`
  query searchContentGroupRequest(
    $projectId: String!
    $contentGroupType: EContentGroupType!
  ) {
    contentGroup {
      searchContentGroups(projectId: $projectId, type: [$contentGroupType]) {
        id
        title
        recommendedFormType
        # description
        # type
        # status
        # lastUpdate
        # parentId
        # sort
      }
    }
  }
`;

const SearchContentGroupRequest = ({
  onError,
  onSuccessResult,
  contentGroupType,
}) => {
  const {
    project: { id: projectId },
  } = useContext(AppContext);
  return (
    <Query
      fetchPolicy="network-only"
      query={SEARCH_CONTENT_GROUP_REQUEST}
      variables={{ projectId, contentGroupType }}
    >
      {({ loading, error, data }) => (
        <QueryRequest
          data={
            data &&
            Object.entries(data).length !== 0 &&
            data.contentGroup.searchContentGroups
          }
          error={error}
          isLoading={loading}
          onError={onError}
          onSuccessResult={onSuccessResult}
        />
      )}
    </Query>
  );
};

SearchContentGroupRequest.propTypes = {
  contentGroupType: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default SearchContentGroupRequest;
