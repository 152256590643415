import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_MYAPP_SECTIONS = gql`
  query getMyAppListRequest {
    user {
      getLoggedUser {
        apps {
          id
          title
          identifier
          # logoId
          # averageRateValue
          # isPublished
          # logo {
          #   alt
          #   assetId
          #   s3Key
          #   contentType
          # }
        }
        email
        firstname
        lastname
        id
      }
    }
  }
`;

// export const GET_ALL_MYAPP_SECTIONS = gql`
//   query getMyAppListRequest($projectId: String) {
//     app {
//       searchApps(
//         projectId: $projectId

//         visibility: PUBLIC
//       ) {
//         id
//         identifier
//         versions {
//           components {
//             id
//             title
//           }
//         }
//       }
//     }
//   }
// `;

// <Query fetchPolicy="network-only"
// query={GET_ALL_MYAPP_SECTIONS} variables={{ projectId }}>
const getMyAppListRequest = ({ onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ALL_MYAPP_SECTIONS}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data && Object.entries(data).length !== 0 && data.user.getLoggedUser
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getMyAppListRequest.propTypes = {
  // projectId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getMyAppListRequest;
