import React from 'react';

import PropTypes from 'prop-types';

import { FieldWrapper, Label } from './styled/Field';

const Field = ({ children, id, label }) => (
  <FieldWrapper>
    <Label htmlFor={id} id={id}>
      <span>{label}</span>
      {children}
    </Label>
  </FieldWrapper>
);

Field.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
};

Field.defaultProps = {
  id: undefined,
  label: undefined,
};

export default Field;
