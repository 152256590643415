import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { API_ENDPOINTS, IApiError } from '../api';

import { COMMON_LIBRARY_CONSTANTS } from '../../constants';
import { IApolloClientOptions, IApolloLinkOptions, IEkardoApolloClient, IGeladaApolloClient } from '../../interfaces';

const {
  MESSAGES: {
    ERROR: { ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED, NOT_FOUND },
  },
} = COMMON_LIBRARY_CONSTANTS;

const getApolloClient = ({ apiName, apolloLink, requestCredentials }: IApolloClientOptions): ApolloClient<NormalizedCacheObject> => {
  const apiEndpoint = API_ENDPOINTS[apiName];
  if (!apiEndpoint) {
    throw new Error(`Api endpoint ${NOT_FOUND} for ${apiName} - ${ENSURE_CORRECT_ENVIRONMENT_VARIABLES_ARE_CONFIGURED}`);
  }

  const httpLink = new HttpLink({
    uri: apiEndpoint,
  });

  const link = from([apolloLink, httpLink]);

  return new ApolloClient({
    cache: new InMemoryCache(),
    credentials: requestCredentials,
    link,
  });
};

const getApolloErrorMessages = (error: Error | undefined): string[] => {
  if (!error) {
    return [];
  }

  const { graphQLErrors, networkError } = error as ApolloError;

  if (graphQLErrors.length) {
    return graphQLErrors.map((graphQLError) => graphQLError.message);
  }

  const {
    response: { statusText },
  } = networkError as ServerParseError;

  if (statusText) {
    return [statusText];
  }

  const {
    result: { errors = [] },
  } = networkError as ServerError;

  if (errors.length) {
    return (errors as IApiError[]).map((apiError) => apiError.message);
  }

  const { message } = networkError as Error;
  return [message];
};

const getApolloLink = ({ apiName, isPublic = false, product, projectId, token = '' }: IApolloLinkOptions): ApolloLink => {
  return setContext((_, { headers }) => {
    const authorization = isPublic ? undefined : token;
    const actualProjectId = projectId ?? process.env.REACT_APP_PROJECT_ID;

    const updatedHeaders = {
      headers: {
        ...headers,
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': '*',
        custom_build_guid: apiName === 'gelada' && actualProjectId ? actualProjectId : '',
        product: product ?? '',
      },
    };

    if (authorization) {
      return {
        ...updatedHeaders,
        headers: {
          ...updatedHeaders.headers,
          authorization,
        },
      };
    }

    return updatedHeaders;
  });
};

const getEkardoApolloClient = ({ product, requestCredentials, token }: IEkardoApolloClient) => {
  return getApolloClient({
    apiName: 'ekardo',
    apolloLink: getApolloLink({
      apiName: 'ekardo',
      product,
      token,
    }),
    requestCredentials,
  });
};

const getGeladaApolloClient = ({ isPublic, requestCredentials, token }: IGeladaApolloClient) => {
  return getApolloClient({
    apiName: 'gelada',
    apolloLink: getApolloLink({
      apiName: 'gelada',
      isPublic,
      token,
    }),
    requestCredentials,
  });
};

export { getApolloClient, getApolloErrorMessages, getApolloLink, getEkardoApolloClient, getGeladaApolloClient };
