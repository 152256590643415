import gql from 'graphql-tag';

export const EXPORT_POSTS = gql`
  query ExportPosts($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime) {
    report {
      exportPosts(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo) {
        contentType
        s3Key
      }
    }
  }
`;
