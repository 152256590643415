import React from 'react';

import AccountDetails from '../../components/AccountDetails/AccountDetails';
import AccountMenu from '../../components/AccountMenu/AccountMenu';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ConsoleContainer from '../../components/ConsoleContainer/ConsoleContainer';
import Footer from '../../components/ConsoleFooter/Footer';
import ConsoleHeader from '../../components/Header/ConsoleHeader';
import SideBarNav from '../../components/SideBarNav/SideBarNav';
import GetUserRequest from '../../middleware/User/getUserRequest';

export const dateUtil = (date) => {
  const extract = date.split('T')[0].split('-');
  const year = extract[0];
  const month = extract[1];
  const day = extract[2];
  const format = `${day}/${month}/${year.substring(2, 4)}`;
  return format;
};
class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      created: '',
      secondaryEmail: '',
      mobilePhone: '',
      requestSent: false,
    };
    this.userNameUpdateHandler = this.userNameUpdateHandler.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.handleOnUserData = this.handleOnUserData.bind(this);
  }

  handleOnError() {
    this.setState({
      requestSent: true,
    });
  }

  handleOnUserData(id, firstName, lastName, email, created, secondaryEmail, mobilePhone) {
    this.setState({
      id,
      firstName,
      lastName,
      email,
      created,
      secondaryEmail,
      mobilePhone,
      requestSent: true,
    });
  }

  userNameUpdateHandler(firstName, lastName, secondaryEmail, mobilePhone) {
    this.setState({
      firstName,
      lastName,
      secondaryEmail,
      mobilePhone,
    });
  }

  render() {
    const { firstName, lastName, email, requestSent, created, id, secondaryEmail, mobilePhone } = this.state;

    return (
      <>
        <ConsoleHeader />

        <Breadcrumb
          breads={[
            { id: 1, path: '/dashboard', title: 'Dashboard' },
            { id: 2, path: '', title: 'Account' },
          ]}
        >
          <h3>Account | Details</h3>
        </Breadcrumb>
        <SideBarNav />
        <ConsoleContainer>
          <div className="user-head">
            <div className="user-avatar">{firstName?.charAt(0) + lastName?.charAt(0)}</div>

            <div className="user-detail">
              <div className="user-name">{`${firstName ?? ''} ${lastName ?? ''}`}</div>
              <div className="user-join-date">Joined: {created}</div>
            </div>
          </div>
          <div className="user-body">
            <AccountMenu />
            <AccountDetails
              email={email}
              firstName={firstName}
              id={id}
              lastName={lastName}
              mobilePhone={mobilePhone}
              secondaryEmail={secondaryEmail}
              updateUserName={this.userNameUpdateHandler}
            />
          </div>
        </ConsoleContainer>

        {!requestSent && <GetUserRequest onError={this.handleOnError} onSuccessResult={this.handleOnUserData} />}
      </>
    );
  }
}

export default AccountPage;
