import React, { Component } from 'react';

import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { allPlans } from './response';

import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ContactUserRequest from '../../middleware/Account/contactUserRequest';
import fbRed from '../../static/img/fb-red.svg';
import twitterRed from '../../static/img/twitter-red.svg';
import { inWords } from '../../utils/utils';

class PricingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      organisation: '',
      email: '',
      phoneNumber: '',
      message: '',
      errorMessage: '',
      messageSent: false,
      tierContent: null,
      responseStripPlan: null,
      selectedTab: 'texttospeech_btn',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({
      tierContent: allPlans.products[0].plans,
      responseStripPlan: allPlans,
    });
  }

  handleInputs = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePricingTierContent = e => {
    const { responseStripPlan: response } = this.state;
    if (e.target.id === 'combine_btn') {
      this.setState({
        tierContent: response.products[2].plans,
        selectedTab: 'combine_btn',
      });
    } else if (e.target.id === 'texttospeech_btn') {
      this.setState({
        tierContent: response.products[0].plans,
        selectedTab: 'texttospeech_btn',
      });
    } else if (e.target.id === 'translate_btn') {
      this.setState({
        tierContent: response.products[1].plans,
        selectedTab: 'translate_btn',
      });
    }
  };

  validate = () => {
    const { fullName, organisation, email, phoneNumber, message } = this.state;
    return {
      FullNameEmpty: {
        validation: fullName.trim().length > 0,
        errorMessage: 'Fullname must not be empty',
      },
      OrganisationEmpty: {
        validation: organisation.trim().length > 0,
        errorMessage: 'Organisation must not be empty',
      },
      EmailEmpty: {
        validation: email.trim().length > 0,
        errorMessage: 'Email must not be empty',
      },
      PhoneNumberEmpty: {
        validation: phoneNumber.trim().length > 0,
        errorMessage: 'Phone number must not be empty',
      },
      MessageEmpty: {
        validation: message.trim().length > 0,
        errorMessage: 'Message must not be empty',
      },
    };
  };

  handleSubmit = e => {
    e.preventDefault();

    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
    } else {
      this.setState({
        errorMessage: '',
        messageSent: true,
      });
    }
  };

  handleError = message => {
    this.setState({
      errorMessage: message,
    });
  };

  handleClear = () => {
    this.setState({
      errorMessage: '',
    });
  };

  render() {
    const {
      tierContent,
      selectedTab,
      errorMessage,
      messageSent,
      fullName,
      organisation,
      phoneNumber,
      email,
      message,
    } = this.state;

    const stripPlan = [];
    const stripPrice = [];

    if (tierContent) {
      tierContent.map(item => {
        stripPlan.push(inWords(item.stripePlan));
        stripPrice.push(item.price ? item.price : 'free');
        return true;
      });
    }

    const scrollFunction = () => {
      if (
        (document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20) &&
        document.getElementById('myBtn')
      ) {
        document.getElementById('myBtn').style.display = 'block';
      } else if (document.getElementById('myBtn')) {
        document.getElementById('myBtn').style.display = 'none';
      }
    };
    const topFunction = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    };

    // When the user scrolls down 20px from the top of the document,
    // show the button
    window.onscroll = () => {
      scrollFunction();
    };

    return (
      <div>
        <Helmet>
          <title>Pricing Tiers</title>
          <meta
            content="Ekardo takes your privacy seriously, read our statement for more detail"
            name="description"
          />
        </Helmet>

        <div className="pricing-page">
          <div className="pricing-page__fold">
            <Header active="pricing" location="/register" theme="red" />
            <button
              className="button--full myBtn"
              id="myBtn"
              onClick={topFunction}
              title="Go to top"
              type="button"
            >
              Top
            </button>
            <div className="container-fluid">
              <div className="container">
                <h2 className="fs-5 ml-md-5 pl-md-5 ml-0 pl-0 text-white mt-4">
                  PRICING TIERS
                </h2>
              </div>
              <div className="row">
                <div className="col position-relative">
                  <div className="h-100 d-flex flex-row flex-md-column justify-content-center banner__left--btn__box">
                    <button
                      className={classnames(
                        {
                          'on-click-tab': selectedTab === 'combine_btn',
                        },
                        'text-uppercase text-white rounded-pill py-2 pr-4 pl-4 pl-md-5 banner__left-btn mt-3'
                      )}
                      id="combine_btn"
                      onClick={e => this.changePricingTierContent(e)}
                      type="button"
                    >
                      {' '}
                      Combine{' '}
                    </button>
                    <button
                      className={classnames(
                        {
                          'on-click-tab': selectedTab === 'texttospeech_btn',
                        },
                        'text-uppercase text-white rounded-pill py-2 pr-4 pl-4 pl-md-5 banner__left-btn mt-3'
                      )}
                      id="texttospeech_btn"
                      onClick={e => this.changePricingTierContent(e)}
                      type="button"
                    >
                      {' '}
                      Text-to-Speech{' '}
                    </button>
                    <button
                      className={classnames(
                        {
                          'on-click-tab': selectedTab === 'translate_btn',
                        },
                        'text-uppercase text-white rounded-pill py-2 pr-4 pl-4 pl-md-5 banner__left-btn mt-3'
                      )}
                      id="translate_btn"
                      onClick={e => this.changePricingTierContent(e)}
                      type="button"
                    >
                      {' '}
                      Translate{' '}
                    </button>{' '}
                  </div>
                </div>
                <div className="col-12 col-md-9 ml-auto mr-auto">
                  <div className="pricing-page__price-card w-75 w-md-100 mr-auto ml-auto">
                    <div className="d-flex flex-column flex-md-row w-100">
                      <div className="bg-white w-100 rounded d-flex mr-2 flex-column text-center">
                        <div className="pricing-page__price-card-1 d-flex flex-column justify-content-center w-100 rounded-top">
                          <h3>{stripPlan[0]}</h3>
                          <h6>CHARACTERS</h6>
                        </div>
                        <div className="text-dark text-center mt-3 p-5 p-md-3">
                          <h2 className="fw-600">{stripPrice[0]}</h2>
                        </div>
                      </div>
                      <div className="bg-white w-100 rounded d-flex mr-2 flex-column text-center mt-4 mt-md-0">
                        <div className="pricing-page__price-card-2 d-flex flex-column justify-content-center w-100 rounded-top">
                          <h3>{stripPlan[1]}</h3>
                          <h6>CHARACTERS</h6>
                        </div>
                        <div className="text-dark text-center mt-3 p-5 p-md-3">
                          <h2 className="fw-600">${stripPrice[1]}</h2>
                          <h6>PER MONTH</h6>
                        </div>
                      </div>
                      <div className="bg-white w-100 rounded d-flex mr-2 flex-column text-center mt-4 mt-md-0">
                        <div className="pricing-page__price-card-3 d-flex flex-column justify-content-center w-100 rounded-top">
                          <h3>{stripPlan[2]}</h3>
                          <h6>CHARACTERS</h6>
                        </div>
                        <div className="text-dark text-center mt-3 p-5 p-md-3">
                          <h2 className="fw-600">${stripPrice[2]}</h2>
                          <h6>PER MONTH</h6>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row w-100">
                      <div className="bg-white w-100 rounded d-flex mr-2 flex-column text-center mt-4 mt-lg-0">
                        <div className="pricing-page__price-card-4 d-flex flex-column justify-content-center w-100 rounded-top">
                          <h3>{stripPlan[3]}</h3>
                          <h6>CHARACTERS</h6>
                        </div>
                        <div className="text-dark text-center mt-3 p-5 p-md-3">
                          <h2 className="fw-600">${stripPrice[3]}</h2>
                          <h6>PER MONTH</h6>
                        </div>
                      </div>
                      <div className="bg-white w-100 rounded d-flex mr-2 flex-column text-center mt-4 mt-lg-0">
                        <div className="pricing-page__price-card-5 d-flex flex-column justify-content-center w-100 rounded-top">
                          <h3>{stripPlan[4]}</h3>
                          <h6>CHARACTERS</h6>
                        </div>
                        <div className="text-dark text-center mt-3 p-5 p-md-3">
                          <h2 className="fw-600">${stripPrice[4]}</h2>
                          <h6>PER MONTH</h6>
                        </div>
                      </div>
                      <div className="bg-white w-100 rounded d-flex mr-2 flex-column text-center mt-4 mt-lg-0">
                        <div className="pricing-page__price-card-6 d-flex flex-column justify-content-center w-100 rounded-top">
                          <h3>{stripPlan[5]}</h3>
                          <h6>CHARACTERS</h6>
                        </div>
                        <div className="text-dark text-center mt-3 p-5 p-md-3">
                          <h2 className="fw-600">${stripPrice[5]}</h2>
                          <h6>PER MONTH</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col d-flex justify-content-end">
                  <div
                    className="flex-column text-right pr-3 mb-3 d-none d-lg-flex justify-content-end"
                    href="#"
                  >
                    <div className="mb-2">
                      <img
                        alt="fbLogin"
                        height="24px"
                        src={fbRed}
                        width="24px"
                      />
                    </div>
                    <div className="">
                      <img
                        alt="twiterLogin"
                        height="24px"
                        src={twitterRed}
                        width="24px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="text-center">
              <h1>FEATURES</h1>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between ml-5">
              <div className="d-flex align-items-center mt-3">
                <img
                  alt=""
                  className="mr-3"
                  src="https://assets.kanzi.io/images/icon-check.svg"
                />
                Convert text to speech
              </div>
              <div className="d-flex align-items-center mt-3">
                <img
                  alt=""
                  className="mr-3"
                  src="https://assets.kanzi.io/images/icon-check.svg"
                />
                Synchronised text highlighting
              </div>
              <div className="d-flex align-items-center mt-3">
                <img
                  alt=""
                  className="mr-3"
                  src="https://assets.kanzi.io/images/icon-check.svg"
                />
                Multi language conversion
              </div>
              <div className="d-flex align-items-center mt-3">
                <img
                  alt=""
                  className="mr-3"
                  src="https://assets.kanzi.io/images/icon-check.svg"
                />
                Email support
              </div>
            </div>
            <div className="d-flex justify-content-center my-4">
              {' '}
              <Link to="/register">
                <button className="button--full" type="button">
                  SIGN UP
                </button>
              </Link>
            </div>
          </div>
          <div>
            <div className="contact-form">
              <div className="title">
                <h1 style={{ fontFamily: 'rift, sans-serif' }}>
                  STILL HAVE MORE QUESTIONS?
                </h1>
                <div className="title___subtext">
                  <p>
                    Get in touch with the team to help you make the right
                    choice.
                  </p>
                  <p>Our friendly team are here to help!</p>
                </div>
              </div>
              <div className="contact-form__body container-fluid">
                <div className="container">
                  <div className="row contact-form__body__row">
                    <div
                      className="contact-form__text"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                      }}
                    >
                      <label htmlFor="fullName">
                        Full name<span className="required">*</span>
                        <input
                          autoComplete="off"
                          className="w-100"
                          id="fullName"
                          name="fullName"
                          onChange={this.handleInputs}
                          onClick={this.handleClear}
                          style={{ padding: '1em', marginRight: '5px' }}
                          value={fullName}
                        />{' '}
                      </label>
                    </div>
                    <div
                      className="contact-form__text"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '49%',
                        marginLeft: '5px',
                      }}
                    >
                      <label htmlFor="organisation">
                        Organisation<span className="required">*</span>
                        <input
                          autoComplete="off"
                          className="w-100"
                          name="organisation"
                          onChange={this.handleInputs}
                          onClick={this.handleClear}
                          style={{ padding: '1em' }}
                          value={organisation}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row contact-form__body__row">
                    <div
                      className="contact-form__text"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                      }}
                    >
                      <label htmlFor="email">
                        Email<span className="required">*</span>
                        <input
                          autoComplete="off"
                          className="w-100"
                          name="email"
                          onChange={this.handleInputs}
                          onClick={this.handleClear}
                          style={{ padding: '1em', marginRight: '5px' }}
                          value={email}
                        />
                      </label>
                    </div>
                    <div
                      className="contact-form__text"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '49%',
                        marginLeft: '5px',
                      }}
                    >
                      <label htmlFor="phoneNumber">
                        Phone number<span className="required">*</span>
                        <input
                          autoComplete="off"
                          className="w-100"
                          name="phoneNumber"
                          onChange={this.handleInputs}
                          onClick={this.handleClear}
                          style={{ padding: '1em' }}
                          value={phoneNumber}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row contact-form__body__row">
                    <div
                      className="contact-form__text"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <label htmlFor="message">
                        Message<span className="required">*</span>
                        <textarea
                          className="w-100"
                          name="message"
                          onChange={this.handleInputs}
                          onClick={this.handleClear}
                          style={{ height: '150px' }}
                          value={message}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-form__footer">
                <button
                  className="button--red contact-form__footer__send-button"
                  onClick={this.handleSubmit}
                  style={{ margin: '1em 0 1em auto' }}
                  type="button"
                >
                  <span>SEND</span>
                </button>
              </div>
            </div>
            <div className="errorFooter">
              {errorMessage !== '' && <ErrorMessage message={errorMessage} />}
              {messageSent && (
                <ContactUserRequest
                  content={message}
                  email={email}
                  firstname={fullName}
                  lastname={organisation}
                  onError={this.handleError}
                  phoneNumber={phoneNumber}
                />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PricingPage;
