import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from './legacy/components/PrivateRoute/PrivateRoute';
import AcceptInvitation from './legacy/pages/AcceptInvitation/AcceptInvitation';
import AccountPage from './legacy/pages/AccountPage/AccountPage';
import ActivatePage from './legacy/pages/ActivatePage/ActivatePage';
import AppBuilderPage from './legacy/pages/AppBuildPage/AppBuilderPage';
import AppBuildPage from './legacy/pages/AppBuildPage/AppBuildPage';
import ApprovalAppPage from './legacy/pages/ApprovalAppPage/ApprovalAppPage';
import AppVersionPage from './legacy/pages/AppVersionPage/AppVersionPage';
import CartoonPdfPage from './legacy/pages/CartoonPdf/CartoonPdfPage';
import ContactConfirmationPage from './legacy/pages/ContactConfirmationPage/ContactConfirmationPage';
import ContactPage from './legacy/pages/ContactPage/ContactPage';
import ContentGroupPage from './legacy/pages/ContentGroupPage/ContentGroupsPage';
import ContentPage from './legacy/pages/ContentPage/ContentPage';
import ContentGroupTypePage from './legacy/pages/ContentTypePage/ContentTypePage';
import CreateNotificationPage from './legacy/pages/CreateNotificationPage/CreateNotificationPage';
import DiscountsPage from './legacy/pages/DiscountsPage/DiscountsPage';
import Error404 from './legacy/pages/Error404/Error404';
import ForgotPasswordPage from './legacy/pages/ForgotPasswordPage/ForgotPasswordPage';
import GroupOrdersDetailsPage from './legacy/pages/GroupOrdersDetailsPage/GroupOrdersDetailsPage';
import GroupsOrderSummaryPage from './legacy/pages/GroupsOrderSummaryPage/GroupsOrderSummaryPage';
// Public Pages
import ImageMapBuilderPage from './legacy/pages/ImageMapBuilderPage/ImageMapBuilderPage';
import InvoicePage from './legacy/pages/InvoicePage/InvoicePage';
import LandingPage from './legacy/pages/LandingPage/LandingPage';
import LibraryAssetPage from './legacy/pages/LibraryAssetPage/LibraryAssetPage';
import LibraryAssetPageNew from './legacy/pages/LibraryAssetPage/LibraryAssetPageNew';
import LibraryDirectoryPage from './legacy/pages/LibraryDirectoryPage/LibraryDirectoryPage';
import LibraryFormPage from './legacy/pages/LibraryFormPage/LibraryFormPage';
import LibraryHomePage from './legacy/pages/LibraryHomePage/LibraryHomePage';
import LibraryImageMapPage from './legacy/pages/LibraryImageMapPage/LibraryImageMapPage';
import LibrarySliderPage from './legacy/pages/LibrarySliderPage/LibrarySliderPage';
import LibraryTagsPage from './legacy/pages/LibraryTagsPage/LibraryTagsPage';
import BackofficeLoginPage from './legacy/pages/LoginPage/BackofficeLoginPage';
import LoginPage from './legacy/pages/LoginPage/LoginPage';
import MarketplaceAppDetailsPage from './legacy/pages/MarketplaceAppDetailsPage/MarketplaceAppDetailsPage';
import MarketplacePageNew from './legacy/pages/MarketplacePage/MarketplacePageNew';
import ModerationPage from './legacy/pages/ModerationPage/ModerationPage';
import MyApplicationPage from './legacy/pages/MyApplicationPage/MyApplicationPage';
import MyAppPage from './legacy/pages/MyApplicationPage/MyAppPage';
// Console Pages
import NotificationsPage from './legacy/pages/NotificationsPage/NotificationsPage';
import NotificationTemplatesPage from './legacy/pages/NotificationsPage/NotificationTemplatesPage';
import PushNotificationPage from './legacy/pages/NotificationsPage/PushNotificationPage';
import OrganisationsPage from './legacy/pages/OrganisationsPage/OrganisationsPage';
import OrganisationUsersPage from './legacy/pages/OrganisationUsersPage/OrganisationUserPageNew';
import PaymentPage from './legacy/pages/PaymentPage/PaymentPage';
import PricingConfirmationPage from './legacy/pages/PricingConfirmationPage/PricingConfirmationPage';
import PricingPage from './legacy/pages/PricingPage/PricingPage';
import PrivacyPage from './legacy/pages/PrivacyPage/PrivacyPage';
import ProjectConsolePage from './legacy/pages/ProjectConsolePage/ProjectConsolePage';
import ProjectsPage from './legacy/pages/ProjectsPage/ProjectsPage';
import ProjectTeamPage from './legacy/pages/ProjectTeamPage/ProjectTeamPage';
import ProjectUserManagementPage from './legacy/pages/ProjectUserManagementPage/ProjectUserManagementPage';
import ProjectUsersPage from './legacy/pages/ProjectUsersPage/ProjectUsersPage';
import RegisterPage from './legacy/pages/RegisterPage/RegisterPage';
import ReportBuilderPage from './legacy/pages/ReportBuilderPage/ReportBuilderPage';
import ExportAllModulePageCompletionsPage from './legacy/pages/ReportPage/ExportPages/ExportAllModulePageCompletionsPage';
import ExportAllModulePagesPage from './legacy/pages/ReportPage/ExportPages/ExportAllModulePagesPage';
import ExportBookmarksPage from './legacy/pages/ReportPage/ExportPages/ExportBookmarksPage';
import ExportCommentsPage from './legacy/pages/ReportPage/ExportPages/ExportCommentsPage';
import ExportFeedbackPage from './legacy/pages/ReportPage/ExportPages/ExportFeedbackPage';
import ExportGroupsContactsPage from './legacy/pages/ReportPage/ExportPages/ExportGroupsContactsPage';
import ExportGroupsPage from './legacy/pages/ReportPage/ExportPages/ExportGroupsPage';
import ExportLoginsPage from './legacy/pages/ReportPage/ExportPages/ExportLoginsPage';
import ExportMobileAppAccessPage from './legacy/pages/ReportPage/ExportPages/ExportMobileAppAccessPage';
import ExportModulePageCompletionsPage from './legacy/pages/ReportPage/ExportPages/ExportModulePageCompletionsPage';
import ExportModulePagesPage from './legacy/pages/ReportPage/ExportPages/ExportModulePagesPage';
import ExportNotificationsPage from './legacy/pages/ReportPage/ExportPages/ExportNotificationsPage';
import ExportOrdersPage from './legacy/pages/ReportPage/ExportPages/ExportOrdersPage';
import ExportPostsPage from './legacy/pages/ReportPage/ExportPages/ExportPostsPage';
import ExportQuestionnairesPage from './legacy/pages/ReportPage/ExportPages/ExportQuestionnairesPage';
import ExportUserSessionsPage from './legacy/pages/ReportPage/ExportPages/ExportUserSessionsPage';
import ExportUsersPage from './legacy/pages/ReportPage/ExportPages/ExportUsersPage';
import ReportPage from './legacy/pages/ReportPage/ReportPage';
import ReportViewerPage from './legacy/pages/ReportViewer/ReportViewerPage';
import ResetPasswordPage from './legacy/pages/ResetPasswordPage/ResetPasswordPage';
import SliderBuilderPage from './legacy/pages/SliderBuilderPage/SliderBuilderPage';
import SocialPage from './legacy/pages/SocialPage/SocialPage';
import SubscriptionsPage from './legacy/pages/SubscriptionsPage/SubscriptionsPage';
import TermsPage from './legacy/pages/TermsPage/TermsPage';
import TopicManagementPage from './legacy/pages/TopicManagementPage/TopicManagementPage';
import UnauthorizedPage from './legacy/pages/Unauthorized/Unauthorized';
import VersionListPage from './legacy/pages/VersionListPage/VersionListPage';

const Routes = () => (
  <>
    <Switch>
      {/* ********************* Public Pages ********************* */}
      <Route component={LandingPage} path="/" exact />
      {/* <Route component={LoginPage} path="/" /> */}
      <Route component={ActivatePage} path="/activate/:token" />
      <Route component={LoginPage} path="/login" />
      <Route component={BackofficeLoginPage} path="/bo-login" />
      <Route component={ForgotPasswordPage} path="/forgot-password" />
      <Route component={ResetPasswordPage} path="/reset-password" />
      <Route component={PricingPage} path="/pricing" exact />
      <Route component={Error404} path="/404" />
      <Route component={PricingConfirmationPage} path="/pricing-thank-you" />
      <Route component={ContactConfirmationPage} path="/contact-thank-you" />
      <Route component={RegisterPage} path="/register" />
      <Route component={AcceptInvitation} path="/accept-invitation/:token" />
      <PrivateRoute component={PaymentPage} path="/payment" />
      <PrivateRoute component={AccountPage} path="/account" />
      <Route component={ContactPage} path="/contact" />
      <Route component={TermsPage} path="/terms" />
      <Route component={PrivacyPage} path="/privacy" />

      {/* ********************* Console Pages ********************* */}

      <PrivateRoute component={OrganisationsPage} path="/dashboard" exact />

      <PrivateRoute component={MyApplicationPage} path="/my-apps" exact />

      <PrivateRoute component={ProjectsPage} path="/dashboard/:orgId" exact />

      <PrivateRoute component={ProjectConsolePage} path="/dashboard/:orgId/project/:projectId" exact />

      {/* ********************* Marketplace Pages ********************* */}
      <PrivateRoute component={ApprovalAppPage} path="/marketplace/pending-apps" exact />

      <PrivateRoute component={MarketplacePageNew} path="/marketplace/:projectId?" exact />

      <PrivateRoute component={MarketplaceAppDetailsPage} path="/marketplace/app/:appId" exact />

      <PrivateRoute component={AppVersionPage} path="/marketplace/app/:appId/versions" exact />

      <PrivateRoute component={AppBuildPage} path="/marketplace/app/:appId/versions/:versionId/edit" exact />

      <PrivateRoute component={AppBuilderPage} path="/marketplace/app/:appId/versions/:versionId/components" exact />

      <PrivateRoute component={AppBuilderPage} path="/marketplace/app/:appId/versions/:versionId/approval" exact />

      {/* *********************** App Pages *********************** */}
      <PrivateRoute component={MyAppPage} path="/dashboard/:orgId/project/:projectId/library/myApps" exact />

      <PrivateRoute component={VersionListPage} path="/dashboard/:orgId/project/:projectId/myApps/:appId/versions" exact />

      {/* *********************** Content Pages *********************** */}

      <Route component={ContentGroupTypePage} path="/dashboard/:orgId/project/:projectId/group" exact />

      <Route component={ContentGroupPage} path="/dashboard/:orgId/project/:projectId/group/:type" exact />

      <PrivateRoute component={ContentPage} path="/dashboard/:orgId/project/:projectId/:pageType/:type/:groupId/content/:pageId?" exact />

      {/* *********************** Social Pages *********************** */}

      <PrivateRoute component={SocialPage} path="/dashboard/:orgId/project/:projectId/social" exact />

      <PrivateRoute component={ModerationPage} path="/dashboard/:orgId/project/:projectId/social/moderation" exact />

      <PrivateRoute component={TopicManagementPage} path="/dashboard/:orgId/project/:projectId/social/topics" exact />
      <PrivateRoute component={UnauthorizedPage} path="/unauthorized" exact />

      {/* *********************** User mangement *********************** */}

      <PrivateRoute component={OrganisationUsersPage} path="/dashboard/:orgId/users" exact />

      <PrivateRoute component={ProjectUserManagementPage} path="/dashboard/:orgId/project/:projectId/users" exact />

      <PrivateRoute component={ProjectUsersPage} path="/dashboard/:orgId/project/:projectId/users/public" exact />

      <PrivateRoute component={ProjectTeamPage} path="/dashboard/:orgId/project/:projectId/users/team" exact />

      {/* *********************** Library Pages *********************** */}

      <PrivateRoute component={LibraryHomePage} path="/dashboard/:orgId/project/:projectId/library" exact />

      <PrivateRoute component={LibraryAssetPage} path="/dashboard/:orgId/project/:projectId/library/asset/:type" exact />

      <PrivateRoute component={LibraryAssetPageNew} path="/dashboard/:orgId/project/:projectId/library/assets/:type" exact />

      <PrivateRoute component={LibraryFormPage} path="/dashboard/:orgId/project/:projectId/library/forms" exact />

      <PrivateRoute component={ContentPage} path="/dashboard/:orgId/project/:projectId/:pageType/:type/:groupId/" exact />

      <PrivateRoute component={LibrarySliderPage} path="/dashboard/:orgId/project/:projectId/library/slider" exact />

      <PrivateRoute
        component={SliderBuilderPage}
        path="/dashboard/:orgId/project/:projectId/slider-builder/:sliderId/:containerId?/:groupId?"
        exact
      />

      <PrivateRoute component={LibraryImageMapPage} path="/dashboard/:orgId/project/:projectId/library/imageMap" exact />

      <PrivateRoute
        component={ImageMapBuilderPage}
        path="/dashboard/:orgId/project/:projectId/image-map-builder/:imageMapId/:containerId?/:groupId?"
        exact
      />

      <PrivateRoute component={LibraryTagsPage} path="/dashboard/:orgId/project/:projectId/library/tags" exact />

      <PrivateRoute component={LibraryDirectoryPage} path="/dashboard/:orgId/project/:projectId/library/directory" exact />

      {/* *********************** Report Pages *********************** */}

      <PrivateRoute component={ReportBuilderPage} path="/dashboard/:orgId/project/:projectId/report-builder/" exact />

      <PrivateRoute component={ReportBuilderPage} path="/dashboard/:orgId/project/:projectId/report-builder/:reportId" exact />

      <PrivateRoute component={ReportViewerPage} path="/dashboard/:orgId/project/:projectId/report-viewer/:reportId" exact />

      <PrivateRoute component={ReportPage} path="/dashboard/:orgId/project/:projectId/reporting" exact />

      <PrivateRoute
        component={ExportAllModulePagesPage}
        path="/dashboard/:orgId/project/:projectId/reporting/export-all-module-pages"
        exact
      />

      <PrivateRoute
        component={ExportAllModulePageCompletionsPage}
        path="/dashboard/:orgId/project/:projectId/reporting/export-all-module-page-completions"
        exact
      />

      <Route component={CartoonPdfPage} path="/cartoonPdf/:contentGroupId" exact />

      <PrivateRoute component={ExportBookmarksPage} path="/dashboard/:orgId/project/:projectId/reporting/export-bookmarks" exact />

      <PrivateRoute component={ExportCommentsPage} path="/dashboard/:orgId/project/:projectId/reporting/export-comments" exact />

      <PrivateRoute component={ExportFeedbackPage} path="/dashboard/:orgId/project/:projectId/reporting/export-feedback" exact />

      <PrivateRoute component={ExportGroupsPage} path="/dashboard/:orgId/project/:projectId/reporting/export-groups" exact />
      <PrivateRoute
        component={ExportGroupsContactsPage}
        path="/dashboard/:orgId/project/:projectId/reporting/export-groups-contacts"
        exact
      />

      <PrivateRoute component={ExportLoginsPage} path="/dashboard/:orgId/project/:projectId/reporting/export-logins" exact />

      <PrivateRoute component={ExportUserSessionsPage} path="/dashboard/:orgId/project/:projectId/reporting/export-sessions" exact />

      <PrivateRoute
        component={ExportMobileAppAccessPage}
        path="/dashboard/:orgId/project/:projectId/reporting/export-mobile-app-access"
        exact
      />

      <PrivateRoute component={ExportModulePagesPage} path="/dashboard/:orgId/project/:projectId/reporting/export-module-pages" exact />

      <PrivateRoute component={ExportNotificationsPage} path="/dashboard/:orgId/project/:projectId/reporting/export-notifications" exact />

      <PrivateRoute
        component={ExportModulePageCompletionsPage}
        path="/dashboard/:orgId/project/:projectId/reporting/export-module-page-completions"
        exact
      />

      <PrivateRoute component={ExportPostsPage} path="/dashboard/:orgId/project/:projectId/reporting/export-posts" exact />

      <PrivateRoute
        component={ExportQuestionnairesPage}
        path="/dashboard/:orgId/project/:projectId/reporting/export-questionnaires"
        exact
      />

      <PrivateRoute component={ExportOrdersPage} path="/dashboard/:orgId/project/:projectId/reporting/export-orders" exact />

      <PrivateRoute component={ExportUsersPage} path="/dashboard/:orgId/project/:projectId/reporting/export-users" exact />

      <PrivateRoute component={NotificationsPage} path="/dashboard/:orgId/project/:projectId/notifications" exact />

      <PrivateRoute component={PushNotificationPage} path="/dashboard/:orgId/project/:projectId/notifications/push-notification" exact />

      <PrivateRoute
        component={NotificationTemplatesPage}
        path="/dashboard/:orgId/project/:projectId/notifications/notification-templates"
        exact
      />

      <PrivateRoute component={CreateNotificationPage} path="/dashboard/:orgId/project/:projectId/create-notification" exact />

      <PrivateRoute component={SubscriptionsPage} path="/dashboard/:orgId/project/:projectId/subscriptions" exact />

      <PrivateRoute
        component={GroupsOrderSummaryPage}
        path="/dashboard/:orgId/project/:projectId/subscriptions/groups-order-summary"
        exact
      />

      <PrivateRoute
        component={GroupOrdersDetailsPage}
        path="/dashboard/:orgId/project/:projectId/subscriptions/groups-order-summary/:groupId/orders-details"
        exact
      />

      <PrivateRoute
        component={InvoicePage}
        path="/dashboard/:orgId/project/:projectId/subscriptions/groups-order-summary/:groupId/orders-details/:orderId/invoice"
        exact
      />

      <PrivateRoute component={DiscountsPage} path="/dashboard/:orgId/project/:projectId/subscriptions/discounts" exact />
    </Switch>
  </>
);

export default Routes;
