import React, { useState } from 'react';

import { ButtonIconOnly, ConfirmationMessages, SingleDatePicker } from '@netfront/ui-library';
import orderBy from 'lodash.orderby';
import pluralize from 'pluralize';
import YAML from 'yaml';

import { getHtmlElementOnChangeValueFromEvent, getInputFieldClassNames } from '../Snippets.helpers';
import { SummaryFeedbackConfigurationMultiResponseText } from '../SummarySnippet/SummaryFeedbackConfigurationMultiResponseText';

import { DEFAULT_MULTI_RESPONSE_TEXT_ITEM, DEFAULT_SELECTED_INDEX, PREFIX } from './MultiResponseTextSnippet.constants';
import { getOriginalResponsesAfterCancel, hasMinimumResponsesRequired } from './MultiResponseTextSnippet.helpers';
import { MultiResponseTextSnippetProps } from './MultiResponseTextSnippet.interfaces';

import {
  DBQuestionAnswerMultiResponseTextItemStatusType,
  IDBQuestionConfigurationMultiResponseHeader,
  IQuestionAnswerMultiResponseTextItem,
  IQuestionAnswerMultiResponseTextItemInputType,
  ISummaryFeedback,
} from '../../../interfaces';

import './MultiResponseTextSnippet.css';

const MultiResponseTextSnippet = ({
  accessToken,
  behavior,
  headers = [],
  id,
  maxResponse,
  message,
  minResponse,
  responses,
  onChange,
  summaryFeedbackConfiguration,
  summaryFeedbackMode,
}: MultiResponseTextSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  const firstEmptyMultiResponseTextIndex = responses.findIndex((response) => !response);
  const totalAnsweredResponses = responses.length;

  const [currentSavedResponses, setCurrentSavedResponses] = useState<IQuestionAnswerMultiResponseTextItem[]>(() => {
    if (summaryFeedbackConfiguration) {
      return orderBy(responses, ['summaryFeedback.value'], ['desc']);
    }

    return responses.length
      ? responses
      : Array.from<IQuestionAnswerMultiResponseTextItem>({
          length: minResponse,
        }).fill(DEFAULT_MULTI_RESPONSE_TEXT_ITEM);
  });

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(() => {
    if (firstEmptyMultiResponseTextIndex === -1 && totalAnsweredResponses > 0) {
      return DEFAULT_SELECTED_INDEX;
    }

    return firstEmptyMultiResponseTextIndex > -1 ? firstEmptyMultiResponseTextIndex : 0;
  });

  const [isAddNewResponseButtonDisabled, setIsAddNewResponseButtonDisabled] = useState<boolean>(
    Boolean(currentSavedResponses.filter((currentSavedResponse) => !currentSavedResponse.value.length).length)
  );

  const [originalSavedResponse, setOriginalSavedResponse] = useState<IQuestionAnswerMultiResponseTextItem>();
  const [remainingResponses, setRemainingResponses] = useState<number>(maxResponse - currentSavedResponses.length);

  const handleAddNewResponse = () => {
    const updatedSavedResponses = [...currentSavedResponses, DEFAULT_MULTI_RESPONSE_TEXT_ITEM];

    setRemainingResponses((previousResponses) => previousResponses - 1);
    setCurrentSavedResponses(updatedSavedResponses);

    setCurrentSelectedIndex(updatedSavedResponses.length - 1);
    setIsAddNewResponseButtonDisabled(true);
  };

  const handleMultiResponseTextCancel = (selectedIndex: number) => {
    const originalSavedResponses = getOriginalResponsesAfterCancel(
      behavior,
      currentSavedResponses,
      minResponse,
      originalSavedResponse as IQuestionAnswerMultiResponseTextItem,
      selectedIndex
    );

    setCurrentSavedResponses(originalSavedResponses);
    setCurrentSelectedIndex(DEFAULT_SELECTED_INDEX);
    setIsAddNewResponseButtonDisabled(!hasMinimumResponsesRequired(behavior, minResponse, originalSavedResponses));

    const savedResponse = currentSavedResponses[selectedIndex];

    if (savedResponse.status === 'NEW') {
      setRemainingResponses(maxResponse - originalSavedResponses.length);
    }
  };

  const handleMultiResponseTextChange = (selectedIndex: number, currentSavedResponseValue: string) => {
    setCurrentSavedResponses((previousSavedResponses) =>
      previousSavedResponses.map((previousSavedResponse, index) =>
        selectedIndex === index
          ? {
              ...previousSavedResponse,
              value: currentSavedResponseValue,
            }
          : previousSavedResponse
      )
    );
  };

  const handleMultiResponseTextDelete = (selectedIndex: number) => {
    const savedResponse = currentSavedResponses[selectedIndex];
    const canDeleteFromDatabase = savedResponse.status === 'SAVED';
    const updatedSavedResponses = currentSavedResponses.filter((_, index) => selectedIndex !== index);

    setCurrentSavedResponses(updatedSavedResponses);
    setCurrentSelectedIndex(DEFAULT_SELECTED_INDEX);
    setIsAddNewResponseButtonDisabled(!hasMinimumResponsesRequired(behavior, minResponse, updatedSavedResponses));
    setRemainingResponses(maxResponse - updatedSavedResponses.length);

    if (!canDeleteFromDatabase) {
      return;
    }

    const inputResponses: IQuestionAnswerMultiResponseTextItemInputType[] = updatedSavedResponses.map(
      ({ value }) =>
        ({
          status: 'SAVED',
          value,
        } as IQuestionAnswerMultiResponseTextItemInputType)
    );

    onChange(inputResponses);
  };

  const handleMultiResponseTextEdit = (selectedIndex: number, currentSavedResponse: IQuestionAnswerMultiResponseTextItem) => {
    setCurrentSelectedIndex(selectedIndex);
    setOriginalSavedResponse(currentSavedResponse);
  };

  const handleMultiResponseTextSaveWithHeaders = (
    selectedIndex: number,
    header: IDBQuestionConfigurationMultiResponseHeader,
    currentSavedResponseValue?: string
  ) => {
    const status: DBQuestionAnswerMultiResponseTextItemStatusType = hasMinimumResponsesRequired(
      behavior,
      minResponse,
      currentSavedResponses
    )
      ? 'SAVED'
      : 'DRAFT';

    const updatedSavedResponses = currentSavedResponses.map((currentSavedResponse, index) => {
      if (selectedIndex !== index) {
        return currentSavedResponse;
      }

      const updatedResponse = {
        ...currentSavedResponse,
        status,
      };

      const { id: headerId } = header;

      const yml = updatedResponse.value !== '' ? YAML.parseDocument(updatedResponse.value) : new YAML.Document();
      yml.set(headerId.toString(), currentSavedResponseValue);

      updatedResponse.value = yml.toString();

      return updatedResponse;
    });

    setCurrentSavedResponses(updatedSavedResponses);
    setCurrentSelectedIndex(DEFAULT_SELECTED_INDEX);
    setIsAddNewResponseButtonDisabled(!hasMinimumResponsesRequired(behavior, minResponse, updatedSavedResponses));

    const canSaveResponsesToDatabase = hasMinimumResponsesRequired(behavior, minResponse, updatedSavedResponses);
    if (!canSaveResponsesToDatabase) {
      return;
    }

    const inputResponses: IQuestionAnswerMultiResponseTextItemInputType[] = updatedSavedResponses.map(
      ({ value }) =>
        ({
          status,
          value,
        } as IQuestionAnswerMultiResponseTextItemInputType)
    );

    onChange(inputResponses);
  };

  const handleMultiResponseTextSave = (selectedIndex: number, currentSavedResponseValue?: string) => {
    const status: DBQuestionAnswerMultiResponseTextItemStatusType = hasMinimumResponsesRequired(
      behavior,
      minResponse,
      currentSavedResponses
    )
      ? 'SAVED'
      : 'DRAFT';

    const updatedSavedResponses = currentSavedResponses.map((currentSavedResponse, index) => {
      if (selectedIndex !== index) {
        return currentSavedResponse;
      }

      const updatedResponse = {
        ...currentSavedResponse,
        status,
      };

      updatedResponse.value = currentSavedResponseValue ?? '';

      return updatedResponse;
    });

    setCurrentSavedResponses(updatedSavedResponses);
    setCurrentSelectedIndex(DEFAULT_SELECTED_INDEX);
    setIsAddNewResponseButtonDisabled(!hasMinimumResponsesRequired(behavior, minResponse, updatedSavedResponses));

    const canSaveResponsesToDatabase = hasMinimumResponsesRequired(behavior, minResponse, updatedSavedResponses);
    if (!canSaveResponsesToDatabase) {
      return;
    }

    const inputResponses: IQuestionAnswerMultiResponseTextItemInputType[] = updatedSavedResponses.map(
      ({ value }) =>
        ({
          status,
          value,
        } as IQuestionAnswerMultiResponseTextItemInputType)
    );

    onChange(inputResponses);
  };

  const firstEditableResponseIndex = currentSavedResponses.findIndex((currentSavedResponse) => !currentSavedResponse.value.length);
  const isAddNewResponseButtonVisible = remainingResponses > 0;

  const howManyMoreResponsesCanBeAddedText = `${remainingResponses} more ${pluralize('response', remainingResponses)} can be added`;
  const minResponsesRequiredHelpText =
    minResponse > 1 ? `A minimum of ${minResponse} ${pluralize('response', minResponse)} is required to be filled before proceeding` : '';

  return (
    <React.Fragment>
      {currentSavedResponses.length === 0 && summaryFeedbackConfiguration && (
        <p className="ek-summary-feedback-read-only--no-response">
          It seems you may have missed some responses from a previous page, please navigate back and complete all questions before
          continuing.
        </p>
      )}
      {currentSavedResponses.map((currentSavedResponse, index) => {
        const { value: currentSavedResponseValue = '', status, summaryFeedback } = currentSavedResponse;
        const { value: summaryFeedbackValue } = (summaryFeedback ?? {}) as ISummaryFeedback;

        const classNames = getInputFieldClassNames('textarea');
        const identifier = `${PREFIX}text-area-${index}`;

        const isDeleteButtonAutoSaveVisible = behavior === 'AUTO_SAVE' && !summaryFeedbackMode && currentSavedResponses.length > 1;
        const isDeleteButtonVisible =
          currentSavedResponses.length > minResponse && Boolean(currentSavedResponseValue) && status === 'SAVED';

        const isEditButtonDisabled =
          firstEditableResponseIndex !== index &&
          (currentSelectedIndex !== DEFAULT_SELECTED_INDEX || (currentSelectedIndex !== index && !currentSavedResponseValue));

        const isEditButtonVisible = currentSelectedIndex !== index;
        const isSaveButtonDisabled = !currentSavedResponseValue.length;
        const isTextAreaDisabled = (behavior === 'DRAFT_AND_EDIT' && isEditButtonVisible) || Boolean(summaryFeedbackMode);

        const placeholderText =
          currentSavedResponses.length > 0 && currentSelectedIndex === DEFAULT_SELECTED_INDEX && firstEditableResponseIndex === index
            ? 'Click the "Edit" button to enter a response for this item'
            : '';

        const placeholder = behavior === 'AUTO_SAVE' ? '' : placeholderText;
        const hasHeaders = !!headers.length;

        if (hasHeaders) {
          if (behavior !== 'AUTO_SAVE') {
            throw new Error('The only behavior handled with a multi response text question with headers is AUTO_SAVE');
          }
        }

        return (
          <div key={identifier} className="h-flex h-vertical-flex-direction">
            <div className="h-flex">
              {headers.map((header) => {
                const { content, id: headerId, inputType } = header;

                const value = currentSavedResponseValue ? YAML.parseDocument(currentSavedResponseValue).get(headerId.toString()) ?? '' : '';

                return (
                  <div key={`c-header-${id}`} className="c-header__container">
                    {index === 0 && <p className="c-header__content">{content}</p>}
                    {inputType === 'TEXT' && (
                      <textarea
                        className={classNames}
                        disabled={isTextAreaDisabled}
                        id={`${identifier}-${index}`}
                        placeholder={placeholder}
                        value={String(value) || ''}
                        onChange={(event) => {
                          const updatedValue = getHtmlElementOnChangeValueFromEvent(event);
                          handleMultiResponseTextSaveWithHeaders(index, header, updatedValue);
                        }}
                      />
                    )}
                    {inputType === 'DATE' && (
                      <SingleDatePicker
                        selectedDate={value ? new Date(value as string) : new Date()}
                        onChangeHandler={(event) => {
                          handleMultiResponseTextSaveWithHeaders(index, header, event.toString());
                        }}
                      />
                    )}
                  </div>
                );
              })}
              {!hasHeaders && (
                <textarea
                  className={classNames}
                  disabled={isTextAreaDisabled}
                  id={identifier}
                  placeholder={placeholder}
                  value={currentSavedResponseValue}
                  onChange={(event) => {
                    const updatedValue = getHtmlElementOnChangeValueFromEvent(event);

                    if (behavior === 'AUTO_SAVE') {
                      handleMultiResponseTextSave(index, updatedValue);
                      return;
                    }

                    handleMultiResponseTextChange(index, updatedValue);
                  }}
                />
              )}
            </div>
            {summaryFeedbackMode === 'EDIT' && (
              <div className="ek-summary-feedback">
                <SummaryFeedbackConfigurationMultiResponseText
                  accessToken={accessToken}
                  questionAnswerMultiResponseTextItem={currentSavedResponse}
                  summaryFeedbackConfiguration={summaryFeedbackConfiguration}
                />
              </div>
            )}
            {summaryFeedbackMode === 'READ_ONLY' && (
              <div className="ek-summary-feedback-read-only">
                <p>
                  Your response: <span>{summaryFeedbackValue}</span>
                </p>
              </div>
            )}
            <div className="c-buttons__container h-flex">
              {behavior === 'DRAFT_AND_EDIT' && (
                <React.Fragment>
                  {isEditButtonVisible && !summaryFeedbackMode && (
                    <button
                      className={`${PREFIX}edit-button`}
                      disabled={isEditButtonDisabled}
                      id={`${PREFIX}edit-button-${index}`}
                      type="button"
                      onClick={() => handleMultiResponseTextEdit(index, currentSavedResponse)}
                    >
                      Edit
                    </button>
                  )}
                  {!isEditButtonVisible && (
                    <React.Fragment>
                      {isDeleteButtonVisible && (
                        <button
                          className={`${PREFIX}delete-button`}
                          id={`${PREFIX}delete-button-${index}`}
                          type="button"
                          onClick={() => handleMultiResponseTextDelete(index)}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        className={`${PREFIX}save-button`}
                        disabled={isSaveButtonDisabled}
                        id={`${PREFIX}save-button-${index}`}
                        type="button"
                        onClick={() => handleMultiResponseTextSave(index)}
                      >
                        Save
                      </button>
                      <button
                        className={`${PREFIX}cancel-button`}
                        id={`${PREFIX}cancel-button-${index}`}
                        type="button"
                        onClick={() => handleMultiResponseTextCancel(index)}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {isDeleteButtonAutoSaveVisible && (
                <div className={`${PREFIX}delete`} id={`${PREFIX}delete-${index}`}>
                  <span>Delete response</span>

                  <ButtonIconOnly
                    additionalClassNames={`${PREFIX}delete-button`}
                    iconId="id_bin_icon"
                    text="Delete response"
                    type="button"
                    variant="danger--tertiary"
                    onClick={() => handleMultiResponseTextDelete(index)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className="c-footer__container h-flex h-vertical-flex-direction">
        <div className="c-add-new-response-button__container h-flex">
          <div className="c-confirmation-message__container h-flex">
            <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
          </div>
          {isAddNewResponseButtonVisible && !summaryFeedbackMode && (
            <button
              className={`${PREFIX}add-new-response-button`}
              disabled={isAddNewResponseButtonDisabled}
              id={`${PREFIX}add-new-response-button`}
              type="button"
              onClick={() => handleAddNewResponse()}
            >
              <span className={`${PREFIX}add-new-response-button__icon`}>+</span>
              <span>Add new response</span>
            </button>
          )}
        </div>
        {remainingResponses > 0 && !summaryFeedbackMode && (
          <div className="c-messages__container h-flex h-vertical-flex-direction">
            <div className={`${PREFIX}how-many-more-responses-can-be-added`}>{howManyMoreResponsesCanBeAddedText}</div>
            <div className={`${PREFIX}minimum-responses-required-help-text`}>{minResponsesRequiredHelpText}</div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export { MultiResponseTextSnippet };
