import React from 'react';

import cx from 'classnames';

import { RadioButtonItemProps } from './RadioButtonItem.types';

import { Label } from '../../Label';

import './RadioButtonItem.css';

const RadioButtonItem = ({
  additionalClassNames,
  id,
  isChecked,
  isDisabled,
  labelContentComponent,
  labelText,
  name,
  onChange,
  value,
}: RadioButtonItemProps) => {
  const radioButtonItemClassNames = cx('c-radio-button', additionalClassNames);

  return (
    <div className={radioButtonItemClassNames}>
      <input
        checked={isChecked}
        className="c-radio-button__item"
        disabled={isDisabled}
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
      />
      <Label additionalClassNames="c-radio-button__label" forId={id} labelText={labelText} spacing="none">
        {labelContentComponent}
      </Label>
    </div>
  );
};

export { RadioButtonItem };
