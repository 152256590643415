import React from 'react';

import './Buttons.css';

const Buttons = ({ onClose, onDelete, onSave }) => (
  <div className="c-sidebar-button-container c-flex-center">
    {onDelete && (
      <button className="c-sidebar-button c-delete" type="button" onClick={onDelete}>
        Delete
      </button>
    )}
    {onClose && (
      <button className="c-sidebar-button c-close" type="button" onClick={onClose}>
        Close
      </button>
    )}
    {onSave && (
      <button className="c-sidebar-button c-save" type="button" onClick={onSave}>
        Save
      </button>
    )}
  </div>
);

export default Buttons;
