import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_VIDEO_CONTENT_SNIPPET = gql`
  mutation createContentSnippet($containerId: Int!, $visible: Boolean!, $startAt: Int!, $assetId: String!) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible }
        contentSnippetVideo: { startAt: $startAt, videoId: $assetId }
      ) {
        id
        sort
      }
    }
  }
`;

const createVideoContentSnippetRequest = ({ containerId, isVisible, assetId, onError, onSuccessResult }) => (
  <Mutation mutation={CREATE_VIDEO_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        startAt: 0,
        assetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createVideoContentSnippetRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  containerId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

createVideoContentSnippetRequest.defaultProps = {
  isVisible: true,
};

export default createVideoContentSnippetRequest;
