import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_CONTENT_SECTION = gql`
  mutation CreateContentSection(
    $contentPageId: Int!
    $contentLayoutId: Int!
    $sort: Int!
    $containers: [CreateContentSectionContainerInput]!
  ) {
    contentSection {
      createSection(
        contentSection: {
          contentPageId: $contentPageId
          contentLayoutId: $contentLayoutId
          sort: $sort
          containers: $containers
        }
      ) {
        id
        sort
        contentLayoutId
        sectionContainers {
          id
          sort
        }
      }
    }
  }
`;

const createContentSectionRequest = ({
  contentPageId,
  contentLayoutId,
  sort,
  containers,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_CONTENT_SECTION}>
    {(createSection, { loading, error, data }) => {
      const variables = {
        contentPageId,
        contentLayoutId,
        sort,
        containers,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSection.createSection);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createSection}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createContentSectionRequest.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  containers: PropTypes.array.isRequired,
  contentLayoutId: PropTypes.number.isRequired,
  contentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
};

createContentSectionRequest.defaultProps = {};

export default createContentSectionRequest;
