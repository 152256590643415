import React from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Table, Tbody, Td, Tr } from 'react-super-responsive-table';
import styled from 'styled-components';

import { getAmountInDollarFormat, UtcDateStringToCurrentTimeZone } from '../../utils';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const BaseTableCell = styled(Td)`
  border: 1px solid rgba(9, 30, 66, 0.08);
  padding: 0.5rem 1rem;
`;

const LabelTableCell = styled(BaseTableCell)`
  font-weight: bold;
  width: 15rem;
`;

const ValueTableCell = styled(BaseTableCell)`
  font-weight: normal;
`;

function InvoiceTable(props) {
  const { orderSummary, transaction } = props;

  const { items: orderItems, totalAmountExTaxInDollars, totalDiscountInDollars } = orderSummary;

  const {
    billingAddress,
    internalPaymentReference,
    invoiceId,
    modifiedAtUtc,
    primaryContactEmail,
    primaryContactName,
    salesTaxAmountInDollars,
    totalAmountIncTaxInDollars,
    description,
  } = transaction;

  return (
    <Table>
      <Tbody>
        <Tr>
          <LabelTableCell>Invoice number</LabelTableCell>
          <ValueTableCell>{invoiceId}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Purchase order</LabelTableCell>
          <ValueTableCell>{internalPaymentReference}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Invoice date</LabelTableCell>
          <ValueTableCell>{format(UtcDateStringToCurrentTimeZone(modifiedAtUtc), 'dd-MM-yyyy')}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Billed to</LabelTableCell>
          <ValueTableCell>{billingAddress || 'N/A'}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Description</LabelTableCell>
          <ValueTableCell>{description}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Subscription</LabelTableCell>
          <ValueTableCell>
            {orderItems.map((orderItem) => {
              const {
                id: orderItemId,
                quantity,
                userFlow: { title: userFlowTitle },
              } = orderItem;

              return <div key={`order-item-user-flow-${orderItemId}`}>{`${userFlowTitle} x${quantity}`}</div>;
            })}
          </ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Date</LabelTableCell>
          <ValueTableCell>{format(UtcDateStringToCurrentTimeZone(modifiedAtUtc), 'dd-MM-yyyy')}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Amount</LabelTableCell>
          <ValueTableCell>{`$${getAmountInDollarFormat(totalAmountExTaxInDollars)}`}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Total GST</LabelTableCell>
          <ValueTableCell>{`$${getAmountInDollarFormat(salesTaxAmountInDollars)}`}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Discount amount</LabelTableCell>
          <ValueTableCell>{`-$${getAmountInDollarFormat(totalDiscountInDollars)}`}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Total including GST</LabelTableCell>
          <ValueTableCell>{`$${getAmountInDollarFormat(totalAmountIncTaxInDollars)}`}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Balance due</LabelTableCell>
          <ValueTableCell>{`$${getAmountInDollarFormat(0)}`}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Primary contact</LabelTableCell>
          <ValueTableCell>{primaryContactName}</ValueTableCell>
        </Tr>
        <Tr>
          <LabelTableCell>Email</LabelTableCell>
          <ValueTableCell>{primaryContactEmail}</ValueTableCell>
        </Tr>
      </Tbody>
    </Table>
  );
}

InvoiceTable.propTypes = {
  orderSummary: PropTypes.shape({}).isRequired,
  transaction: PropTypes.shape({}).isRequired,
};

export default InvoiceTable;
