import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const SEARCH_TAGS = gql`
  query searchTags($projectId: String!) {
    tag {
      searchTags(projectId: $projectId, text: "") {
        id
        name
        sort
      }
    }
  }
`;

const SearchProjectTags = ({ projectId, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={SEARCH_TAGS} variables={{ projectId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.tag.searchTags}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

SearchProjectTags.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default SearchProjectTags;
