import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Toolbar,
  TabBar,
  TabBtn,
  SearchBar,
  SearchInput,
  StyleView,
  ListViewBtn,
  GridViewBtn,
  AppContainer,
} from '../MyApplicationPage/styled';

import {
  NewAppBtn,
  NewIcon,
  ApprovalBtn,
  ApprovalImg,
  BtnGroup,
  AppCard,
} from './styled';

import Icon from '../../components/Icon/Icon';
import AppSideBar from '../../components/RightNavBar/AppSideBar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import Button from '../../components/UI/Button/SquareButton';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import GetAllApps from '../../middleware/Marketplace/GetAllAppsByPagination';
import keyIcon from '../../static/img/keyIcon.svg';

const tabs = [
  { id: 0, label: 'All', value: 'all' },
  { id: 1, label: 'Popular', value: 'popular' },
  { id: 2, label: 'Latest', value: 'latest' },
  { id: 3, label: 'My apps', value: 'myapp' },
];

const MarketplacePageNew = props => {
  const { match } = props;

  const [appLoaded, setAppLoaded] = useState(false);
  const [apps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [openPanel, setOpenPanel] = useState(false);
  const [cursor, setCursor] = useState(null);
  const [showLoadBtn, setShowLoadBtn] = useState(true);
  const [sorting, setSorting] = useState(null);

  const handleSearch = e => {
    const { value } = e.target;
    const filtered = apps.filter(item =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredApps(filtered);
  };

  const hanldeSorting = value => {
    setActiveTab(value);
    if (value === 'all') setSorting(null);
    if (value !== 'all' && value !== 'myapp') setSorting(value.toUpperCase());
    setAppLoaded(false);
    setApps([]);
    setShowLoadBtn(true);
    setCursor(null);
  };

  const handleClosePanel = (type, data) => {
    if (type === 'create') {
      const newList = [
        ...apps,
        {
          ...data,
          averageRateValue: 0,
        },
      ];
      setApps(newList);
      setFilteredApps(newList);
    }
    setOpenPanel(false);
  };

  const handleGetAppError = () => {
    setAppLoaded(true);
  };

  const handleGetAppSuccess = (data, endCursor, total) => {
    const tmpApps = data.map(d => d.node);
    const newApps = apps.concat(tmpApps);
    if (newApps.length === total) setShowLoadBtn(false);
    setApps(newApps);
    setCursor(endCursor);
    setFilteredApps(newApps);
    setAppLoaded(true);
  };

  const buttonGroup = (
    <BtnGroup>
      <ApprovalBtn href="/marketplace/pending-apps">
        <ApprovalImg alt="key" src={keyIcon} />
        Waiting for approval
      </ApprovalBtn>
      <NewAppBtn onClick={() => setOpenPanel(true)}>
        <NewIcon>
          <Icon id="icon_plus" />
        </NewIcon>
        Create new app
      </NewAppBtn>
    </BtnGroup>
  );

  const appList = (
    <AppContainer>
      {filteredApps.map(item => (
        <AppCard
          href={`/marketplace/app/${item.id}`}
          id={item.id}
          img="https://dev.assets.ekardo.com/logo/logo-dark.svg"
          key={item.id}
          name={item.title}
          owner=""
          rating={item.averageRateValue}
        />
      ))}
    </AppContainer>
  );

  const toolbar = (
    <Toolbar>
      {buttonGroup}
      <TabBar>
        {tabs.map(tab => (
          <TabBtn
            hasSelected={tab.value === activeTab}
            key={tab.id}
            onClick={() => hanldeSorting(tab.value)}
          >
            {tab.label}
          </TabBtn>
        ))}
      </TabBar>
      <SearchBar>
        <SearchInput onChange={e => handleSearch(e)} placeholder="Search" />
      </SearchBar>
    </Toolbar>
  );

  const breadPath = [
    {
      id: 1,
      path: '',
      title: 'Marketplace',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="App store"
        pageDescription="App store"
        pageTitle="App marketplace page"
        params={match.params}
      >
        {toolbar}
        <StyleView>
          <ListViewBtn />
          <GridViewBtn />
        </StyleView>
        {appList}
        {showLoadBtn && (
          <Button isClicked={() => setAppLoaded(false)}>Load more ...</Button>
        )}
        {openPanel && <AppSideBar onClose={handleClosePanel} />}
        {openPanel && <Backdrop isClicked={handleClosePanel} />}
      </PageWrapper>
      {!appLoaded && (
        <GetAllApps
          after={cursor}
          first={100}
          onError={handleGetAppError}
          onSuccess={handleGetAppSuccess}
          sorting={sorting}
        />
      )}
    </>
  );
};

MarketplacePageNew.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }),
};

MarketplacePageNew.defaultProps = {
  location: undefined,
};

export default MarketplacePageNew;
