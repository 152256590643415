import React, { useRef } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';
import GeladaClient from '../_Gelada/geladaClient';
import { useApolloClient } from '../apollo/useApolloClient';

export const CREATE_PROJECT = gql`
  mutation CreateProject($orgId: Int!, $name: String!) {
    project {
      createProject(project: { organisationId: $orgId, name: $name, isCustomBuild: false }) {
        id
      }
    }
  }
`;

const createProjectRequest = ({ orgId, name, description, onError, onSuccessResult }) => {
  const client = useRef(useApolloClient('gelada')).current;

  return (
    <Mutation client={client} mutation={CREATE_PROJECT}>
      {(createProject, { loading, error, data }) => {
        const variables = {
          orgId,
          name,
          description,
        };

        if (error) {
          return <ErrorModal error={error} onError={onError} />;
        }
        if (data) {
          onSuccessResult(data.project.createProject.id);
        }

        return <MutationRequest isLoading={loading} mutation={createProject} variables={variables} />;
      }}
    </Mutation>
  );
};

createProjectRequest.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
};

export default createProjectRequest;
