import React, { useEffect, useState } from 'react';

import { getS3SignedUrl } from '@netfront/common-library';
import to from 'await-to-js';

import { CodeSnippetProps } from './CodeSnippet.types';

import { ERROR_MESSAGES } from '../../../core';
import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

import './CodeSnippet.css';

const CodeSnippet = ({ location: { contentType, s3Key } }: CodeSnippetProps) => {
  const [html, setHtml] = useState<string>();

  useEffect(() => {
    const loadCodeSnippet = async () => {
      const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

      if (getS3SignedUrlError) {
        throw getS3SignedUrlError;
      }

      if (!s3SignedUrl) {
        throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
      }

      const [fetchError, response] = await to(fetch(s3SignedUrl));

      if (fetchError) {
        throw fetchError;
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!response) {
        throw new Error(`Unable to fetch code snippet with url=${s3SignedUrl}`);
      }

      const { ok: isOk, status } = response;

      if (!isOk) {
        throw new Error(`HTTP error with status: ${status}`);
      }

      const [error, code] = await to(response.text());

      if (error) {
        throw error;
      }

      if (!code) {
        throw new Error('Error serializing response');
      }

      setHtml(code);
    };

    loadCodeSnippet();
  }, [contentType, s3Key]);

  if (!html) {
    return null;
  }

  return (
    <div className="ek-code-snippet c-code-snippet">
      <DOMPurifySanitizedHtmlContent html={html} />
    </div>
  );
};

export { CodeSnippet };
