import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dropdown from '../UI/Dropdown/Dropdown';
import Input from '../UI/Input/InputWhite';
import Textarea from '../UI/Textarea/Textarea';

import { SnippetTypeConst } from './SnippetTypeConst';

import CreateEmbedSnippetRequest from '../../middleware/ContentSnippet/createEmbedSnippetRequest';
import UpdateEmbedSnippetRequest from '../../middleware/ContentSnippet/updateEmbedSnippetRequest';

const Title = styled.p`
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const typeOptions = [
  {
    id: 0,
    value: 'AUDIO',
    label: 'Audio',
  },
  {
    id: 1,
    value: 'VIDEO',
    label: 'Video',
  },
];

const EditableEmbedSnippet = props => {
  const { containerId, onCancel, onCreate, onUpdate, snippet } = props;

  const [sendRequest, setSendRequest] = useState(false);
  const [embedDetail, setEmbedDetail] = useState({
    title: '',
    embedType: '',
    code: '',
  });

  useEffect(() => {
    if (snippet) setEmbedDetail(snippet);
  }, [snippet]);

  const handleUpdate = (value, e) => {
    const newDetail = {
      ...embedDetail,
      [e.target.name]: value,
    };
    setEmbedDetail(newDetail);
  };

  const handleSnippetResponse = (id, sort) => {
    if (snippet) {
      onUpdate(id, snippet.sort, SnippetTypeConst.EMBED, containerId, {
        ...embedDetail,
      });
    } else {
      onCreate(id, sort, SnippetTypeConst.EMBED, containerId, {
        ...embedDetail,
      });
    }
    setSendRequest(false);
  };

  const handleSnippetError = () => {
    setSendRequest(false);
  };

  const handleSaveButton = () => {
    setSendRequest(true);
  };

  return (
    <>
      <Title>Title</Title>
      <Input isChanged={handleUpdate} name="title" text={embedDetail.title} />
      <Title>Type</Title>
      <Dropdown
        availableResponses={typeOptions}
        name="embedType"
        selectedValue={embedDetail.embedType}
        onChangeHandler={(_, value, event) => handleUpdate(value, event)}
      />
      <Title>Embed Code</Title>
      <Textarea isChanged={handleUpdate} name="code" text={embedDetail.code} />
      <div className="text-right">
        <button className="button--black mt-4 mr-3" onClick={onCancel} type="button">
          Cancel
        </button>
        <button className="button--blue" onClick={handleSaveButton} type="submit">
          Save
        </button>
      </div>
      {sendRequest && !snippet && (
        <CreateEmbedSnippetRequest
          code={embedDetail.code}
          containerId={containerId}
          isVisible
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          title={embedDetail.title}
          type={embedDetail.embedType}
        />
      )}
      {sendRequest && snippet && (
        <UpdateEmbedSnippetRequest
          code={embedDetail.code}
          onError={handleSnippetError}
          onSuccessResult={handleSnippetResponse}
          snippetId={snippet.id}
          title={embedDetail.title}
          type={embedDetail.embedType}
        />
      )}
    </>
  );
};

EditableEmbedSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape(),
};
EditableEmbedSnippet.defaultProps = {
  snippet: null,
};

export default EditableEmbedSnippet;
