/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';

import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import Popup from '../../components/Popup/Popup';
import PopupMessage from '../../components/PopupMessage/PopupMessage';
import {
  errorMessageEnterTimeout,
  errorMessageLeaveTimeout,
} from '../../config';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      subConsent: true,
      termsConsent: true,
      showPopupModal: false,
      showPopup: false,
      errorMessage: false,
      message: '',
    };

    this.handleToken = this.handleToken.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleAgreement = this.handleAgreement.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup() {
    this.setState(prevState => ({
      showPopupModal: !prevState.showPopupModal,
    }));
  }

  handleShow(message) {
    this.setState({
      showPopup: true,
      message,
    });

    setTimeout(() => {
      this.setState({
        showPopup: false,
        message: '',
      });
    }, 3000);
  }

  handleShowErrorMessage() {
    this.setState({
      errorMessage: true,
    });

    setTimeout(() => {
      this.setState({
        errorMessage: false,
      });
    }, 3000);
  }

  handleToken(e) {
    e.preventDefault();
    const { subConsent, termsConsent, name } = this.state;
    const { stripe } = this.props;
    if (subConsent && termsConsent) {
      stripe
        .createToken({ type: 'card', name })
        .then(({ token }) => {
          this.addPayment(token);
        })
        .catch(error => this.handleShowErrorMessage(error));
    }
  }

  addPayment() {
    this.setState({});
  }

  handleInput(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  handleAgreement(e) {
    const { name, checked } = e.target;

    this.setState({
      [name]: checked,
    });
  }

  render() {
    const {
      showPopupModal,
      showPopup,
      message,
      errorMessage,
      termsConsent,
    } = this.state;
    return (
      <>
        {/* {this.state.cardSuccess && <Redirect}    */}

        <form
          className="c-payment-form"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <label htmlFor="name">
              Name on card<span className="required">*</span>
              <input
                autoComplete="off"
                className="c-input-wrapper"
                name="name"
                onChange={this.handleInput}
                style={{ padding: '0.6em' }}
              />
            </label>
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <label>
              Number on card<span className="required">*</span>
            </label>
            <div className="c-input-wrapper">
              <CardNumberElement
                style={{
                  base: {
                    fontSize: '16px',
                    textAlign: 'center',
                    '::placeholder': { color: 'transparent' },
                  },
                }}
              />
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '200px',
                paddingRight: '0.8em',
              }}
            >
              <label>
                Expiry Date<span className="required">*</span>
              </label>
              <div className="c-input-wrapper">
                <CardExpiryElement
                  style={{ base: { fontSize: '16px', textAlign: 'center' } }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '200px',
              }}
            >
              <label>
                CVC<span className="required">*</span>
              </label>
              <div className="c-input-wrapper">
                <CardCVCElement
                  style={{ base: { fontSize: '16px', textAlign: 'center' } }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '1.5em',
            }}
          >
            <input
              defaultChecked={termsConsent}
              name="termsConsent"
              onChange={this.handleAgreement}
              type="checkbox"
            />
            <p>
              I agree to the{' '}
              <u onClick={() => this.togglePopup()} role="presentation">
                <strong>Terms & Conditions</strong>
              </u>
            </p>
          </div>

          {/* <p>{this.state.isFetching && 'loading'}</p> */}
        </form>
        {showPopupModal ? (
          <Popup
            closePopup={() => this.togglePopup()}
            text="Terms & Conditions"
          />
        ) : null}

        <button
          className="button--blue"
          onClick={this.handleToken}
          style={{ display: 'flex', margin: ' 1em 0 1em auto' }}
          type="button"
        >
          Save
        </button>
        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {showPopup && <PopupMessage message={message} />}
          {errorMessage && (
            <ErrorMessage message="Please check your card details and try again" />
          )}
        </ReactCSSTransitionGroup>
      </>
    );
  }
}

Form.propTypes = {
  stripe: PropTypes.shape({
    createToken: PropTypes.func,
  }).isRequired,
};

export default Form;
