import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_MYAPP_VERSION_DETAILS = gql`
  query getMyAppVersionDetailsRequest($versionId: Int!) {
    app {
      getAppVersionDetails(inputType: { versionId: $versionId }) {
        version
        id
        appId
        status
        publishStatus
        app {
          apiKey
          id
          identifier
          title
          logoId
          # logo {
          #   s3Key
          #   contentType
          # }
        }
        components {
          files {
            location {
              fileName
              uploaded
              assetId
            }
            id
            fileType
            locationId
          }
          id
          title
        }
      }
    }
  }
`;

const getMyAppVersionDetailsRequest = ({
  versionId,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={GET_MYAPP_VERSION_DETAILS}
    variables={{ versionId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.app.getAppVersionDetails
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getMyAppVersionDetailsRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  versionId: PropTypes.number.isRequired,
};

export default getMyAppVersionDetailsRequest;
