import React from 'react';

import { Td, Tr } from 'react-super-responsive-table';
import styled, { keyframes } from 'styled-components';

import { LinkBtn, LinkIcon } from '../MyApplicationPage/styled';

export const NewNotificationButton = styled(LinkBtn)`
  background-color: #4bc1c9;
`;

export const NewNotificationIcon = styled(LinkIcon)`
  background-color: #fff;

  svg,
  span {
    color: #4bc1c9;
  }
`;
const movingGradient = keyframes`
  0% { background-position: -250px 0; }
    100% { background-position: 250px 0; }
`;

export const SpanOD = styled.span`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${movingGradient};
  animation-timing-function: linear;
  background: linear-gradient(to right, #eee 30%, #ddd 60%, #eee 80%);
  background-size: 500px 100px;
  border-radius: 5px;
  height: 12px;
`;

export const NoDataBox = styled.div`
  margin: 0 auto;

  button {
    margin-left: 0;
    margin-top: 1em;
  }
`;

export const LoadingRows = ({ count = 7 }) => {
  const menuItems = [];
  for (let i = 0; i < count; i += 1) {
    menuItems.push(
      <Tr key={i}>
        <NotificationTd>
          <SpanOD />
        </NotificationTd>
        <NotificationTd>
          <SpanOD />
        </NotificationTd>
        <NotificationTd>
          <SpanOD />
        </NotificationTd>
        <NotificationTd>
          <SpanOD />
        </NotificationTd>
      </Tr>
    );
  }
  return <>{menuItems}</>;
};

export const NotificationTd = styled(Td)`
  height: 50px;
  padding: 8px;
  vertical-align: middle;

  span {
    display: block;
  }

  &.td-1 {
    width: 20px;

    span {
      height: 20px;
      width: 20px;
    }
  }

  &.td-2 {
    width: 50px;

    span {
      background-color: rgba(0, 0, 0, 0.15);
      height: 50px;
      width: 50px;
    }
  }

  &.td-3 {
    width: 400px;
  }

  &.td-4 {
  }

  &.td-5 {
    width: 100px;

    span {
      background-color: rgba(0, 0, 0, 0.15);
      height: 30px;
      width: 100%;
    }
  }
`;


export const Text = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;
