import flatten from 'lodash.flatten';
import isNil from 'lodash.isnil';
import orderBy from 'lodash.orderby';

import { IQuestionResponse } from '../../../../interfaces';

const getUpdatedQuestionResponses = ({
  questionResponses,
  shouldShowRankedItems,
  shouldShowUnRankedItems,
}: {
  questionResponses: IQuestionResponse[];
  shouldShowRankedItems: boolean;
  shouldShowUnRankedItems: boolean;
}) => {
  const rankedQuestionResponses = questionResponses
    .filter(({ rank }) => !isNil(rank))
    .map(
      (questionResponse, index): IQuestionResponse => ({
        ...questionResponse,
        rank: index + 1,
      })
    );

  const unRankedQuestionResponses = questionResponses.filter(({ rank }) => isNil(rank));

  return orderBy(
    flatten([shouldShowRankedItems ? rankedQuestionResponses : [], shouldShowUnRankedItems ? unRankedQuestionResponses : []]),
    ['rank'],
    ['asc']
  );
};

const getUpdatedQuestionResponsesWithRank = ({
  inputQuestionResponse,
  selectedQuestionResponses,
  updateRankBy,
}: {
  inputQuestionResponse: IQuestionResponse;
  selectedQuestionResponses: IQuestionResponse[];
  updateRankBy: number;
}): IQuestionResponse[] => {
  const { rank: currentRank } = inputQuestionResponse;
  const nextRank = currentRank ? currentRank + updateRankBy : currentRank;

  const questionResponseForNextRank = selectedQuestionResponses.find(({ rank }) => nextRank === rank) as IQuestionResponse;

  return orderBy(
    selectedQuestionResponses.map((questionResponse) => {
      if (inputQuestionResponse.id === questionResponse.id) {
        return {
          ...questionResponse,
          rank: nextRank,
        };
      }

      if (questionResponseForNextRank.id === questionResponse.id) {
        return {
          ...questionResponse,
          rank: currentRank,
        };
      }

      return questionResponse;
    }),
    ['rank'],
    ['asc']
  );
};

export { getUpdatedQuestionResponses, getUpdatedQuestionResponsesWithRank };
