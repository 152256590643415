import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { Alert, Label, NavigationButton, Spacing, Tooltip } from '@netfront/ui-library';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS, QUESTION_ACTION_PROP_TYPE, WRAPPER_PROP_TYPE } from '../QuestionConditions.constants';

import { QuestionConditionCheckboxNew } from './QuestionConditionCheckboxNew';
import { GET_QUESTION } from './QuestionConditionNew.graphql';
import { QuestionConditionRadioNew } from './QuestionConditionRadioNew';
import { QuestionConditionSliderNew } from './QuestionConditionSliderNew';
import { QuestionConditionTextNew } from './QuestionConditionTextNew';

import client from '../../../../../middleware/client';
import SearchIcon from '../../../../../static/img/search-icon.svg';
import { getApiErrorMessages } from '../../../../../utils/utils';
import { DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS } from '../../ActionTab.constants';
import { Title } from '../../Components/Title';
import { getQuestionText } from '../../helpers/dom';
import { getEventValue, getCharacterCount } from '../../helpers/inputEvent';

import '../../ActionTab.css';

const QuestionConditionNew = ({
  actionWrapper,
  activePageId,
  conditionAction,
  maxAutoCompleteSuggestions,
  onAddActionCompleted,
  onAddConditionCompleted,
  onClose,
  onCloseSidebar,
  questionAction,
  titleMaxCharacter,
}) => {
  const { projectId } = useParams();

  const [characterCount, setCharacterCount] = useState(titleMaxCharacter);
  const [questionConditionResponses, setQuestionConditionResponses] = useState([]);
  const [questionConditionLocations, setQuestionConditionLocations] = useState([]);
  const [conditionTitle, setConditionTitle] = useState('');
  const [selectedQuestionConfigurationTypeName, setSelectedQuestionConfigurationTypeName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [questions, setQuestions] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedQuestionItem, setSelectedQuestionItem] = useState(undefined);

  const [getQuestion] = useLazyQuery(GET_QUESTION, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        contentSnippet: { getQuestionsWithLabel },
      } = data;

      setQuestions(getQuestionsWithLabel);
    },
    onError(error) {
      const errorMessages = getApiErrorMessages(error);
      setErrorMessage(errorMessages.join(', '));
    },
  });

  const debounceGetQuestionQuery = useDebouncedCallback((inputText) => {
    getQuestion({
      variables: {
        label: inputText,
        projectId,
      },
    });
  }, DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS);

  const handleAddSelectedQuestionItem = (item) => {
    setSelectedQuestionItem(item);
  };

  const handleAddQuestionConditionLocations = (item) => {
    setQuestionConditionLocations(item);
  };

  const handleAddQuestionConditionResponses = (item) => {
    setQuestionConditionResponses(item);
  };

  const handleBackToCondition = () => {
    onClose();
  };

  const handleRecommendedSearchInput = (event) => {
    const value = getEventValue(event);

    setSearchInputValue(value);

    if (!value.length) {
      setQuestionConditionLocations([]);
      setQuestionConditionResponses([]);
      setSelectedQuestionItem(undefined);

      return;
    }

    if (value.length <= 2) {
      return;
    }

    debounceGetQuestionQuery(value);
  };

  const handleTitleChange = (event) => {
    const value = getEventValue(event);

    setCharacterCount(getCharacterCount(titleMaxCharacter, value));
    setConditionTitle(value);
  };

  const questionConditionNewArgs = {
    actionWrapper,
    activePageId,
    conditionAction,
    questionAction,
    questionLocations: questionConditionLocations,
    questionTitle: conditionTitle,
    selectedConditionQuestionItem: selectedQuestionItem,
    onAddActionCompleted,
    onAddConditionCompleted,
    onAddQuestionConditionLocations: handleAddQuestionConditionLocations,
    onAddSelectedQuestionItem: handleAddSelectedQuestionItem,
    onCloseSidebar,
  };

  const questionConditionNewMap = {
    Checkbox: (
      <QuestionConditionCheckboxNew
        args={questionConditionNewArgs}
        questionResponses={questionConditionResponses}
        onAddQuestionConditionResponses={handleAddQuestionConditionResponses}
      />
    ),
    Radio: (
      <QuestionConditionRadioNew
        args={questionConditionNewArgs}
        questionResponses={questionConditionResponses}
        onAddQuestionConditionResponses={handleAddQuestionConditionResponses}
      />
    ),
    SingleText: <QuestionConditionTextNew args={questionConditionNewArgs} />,
    Slider: <QuestionConditionSliderNew args={questionConditionNewArgs} />,
  };

  const handleQuestionItemClick = (item) => {
    setQuestions([]);
    setSelectedQuestionItem(item);
    setSearchInputValue(getQuestionText(item.question));
  };

  useEffect(() => {
    if (!selectedQuestionItem) {
      return;
    }

    const {
      configuration: { __typename: questionConfigurationTypeName },
    } = selectedQuestionItem;

    setSelectedQuestionConfigurationTypeName(questionConfigurationTypeName);
  }, [selectedQuestionItem]);

  return (
    <>
      <NavigationButton
        additionalClassNames="c-action__back-to-action"
        direction="previous"
        iconId="id_caret_icon"
        rotationCssSuffix="90"
        text="Condition"
        onClick={handleBackToCondition}
      />
      <Spacing size="2x-large" />
      <Title
        characterCount={characterCount}
        handleTitleChange={handleTitleChange}
        title={conditionTitle}
        titleMaxCharacter={titleMaxCharacter}
      />
      <Spacing size="2x-large" />
      <div className="c-action__item h-flex">
        <Label additionalClassNames="c-action__item__label h-flex" forId="question" labelText="Question" isRequired>
          <Tooltip placement="left" text="Search question to add the selected show or hide action" />
        </Label>
        <Downshift itemToString={(item) => (item ? getQuestionText(item.question) : '')} onChange={handleQuestionItemClick}>
          {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, getRootProps }) => (
            <div className="c-autocomplete">
              <div {...getRootProps({}, { suppressRefError: true })}>
                <img alt="search" className="c-autocomplete__search-icon" src={SearchIcon} />
                <input
                  className="c-autocomplete__search"
                  placeholder="Search"
                  required
                  {...getInputProps({
                    onChange: handleRecommendedSearchInput,
                    value: searchInputValue,
                  })}
                />
              </div>
              <ul className="c-autocomplete-list" {...getMenuProps()}>
                {isOpen && inputValue.trim()
                  ? questions
                      .slice(0, maxAutoCompleteSuggestions)
                      .filter((item) => getQuestionText(item.question).toLowerCase().includes(inputValue))
                      .map((item, index) => (
                        <li
                          className="c-autocomplete-list__item"
                          {...getItemProps({
                            key: getQuestionText(item.question),
                            index,
                            item,
                          })}
                        >
                          {getQuestionText(item.question)}
                        </li>
                      ))
                  : null}
              </ul>
            </div>
          )}
        </Downshift>
      </div>
      {errorMessage ? (
        <Alert iconId="id_error_icon" message={errorMessage} timeAlertIsVisibleInMilliseconds={NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS} />
      ) : null}
      {questionConditionNewMap[selectedQuestionConfigurationTypeName]}
    </>
  );
};

QuestionConditionNew.propTypes = {
  actionWrapper: WRAPPER_PROP_TYPE,
  activePageId: PropTypes.number,
  conditionAction: PropTypes.string,
  maxAutoCompleteSuggestions: PropTypes.number,
  onAddActionCompleted: PropTypes.func,
  onAddConditionCompleted: PropTypes.func,
  onClose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  questionAction: QUESTION_ACTION_PROP_TYPE,
  titleMaxCharacter: PropTypes.number,
};

export { QuestionConditionNew };
