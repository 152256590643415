import gql from 'graphql-tag';

export const EDIT_TOPIC = gql`
  mutation editTopic($topicId: Int!, $title: String!) {
    topic {
      editTopic(topicId: $topicId, title: $title) {
        id
        title
        description
      }
    }
  }
`;
