import { ApolloError } from '@apollo/react-hooks';
import { COMMON_LIBRARY_CONSTANTS, getApolloErrorMessages } from '@netfront/common-library';

const getToastErrorMessages = ({ error, shouldUseFriendlyErrorMessage = false }) => {
  const {
    messages: {
      error: { unexpected: defaultErrorMessage },
    },
  } = COMMON_LIBRARY_CONSTANTS;

  let errorMessages = [defaultErrorMessage];

  if (!shouldUseFriendlyErrorMessage) {
    return errorMessages;
  }

  if (error instanceof ApolloError) {
    errorMessages = getApolloErrorMessages(error);
  } else if (error instanceof Error) {
    errorMessages = [error.message];
  }

  return errorMessages;
};

export { getToastErrorMessages };
