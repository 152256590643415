import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import enGBLocale from 'antd/lib/date-picker/locale/en_GB';
import enUSLocale from 'antd/lib/date-picker/locale/en_US';
import { endOfDay, parseISO } from 'date-fns';

const getDisabledDates = (currentDate: Date, maxDateString?: string, minDateString?: string) => {
  const endOfDayToday = endOfDay(new Date());
  const parsedMaxDate = maxDateString ? endOfDay(parseISO(maxDateString)) : undefined;
  const parsedMinDate = minDateString ? endOfDay(parseISO(minDateString)) : undefined;

  if (parsedMaxDate && parsedMinDate) {
    return currentDate > parsedMaxDate || currentDate < parsedMinDate;
  }

  if (parsedMaxDate) {
    return currentDate > parsedMaxDate;
  }

  if (parsedMinDate) {
    return currentDate < parsedMinDate;
  }

  return currentDate > endOfDayToday;
};

const getAntdLocale = (): PickerLocale => {
  const {
    lang: { locale: antdEnGBLocale },
  } = enGBLocale;

  const {
    lang: { locale: antdEnUSLocale },
  } = enUSLocale;

  const { locale: intlLocale } = Intl.DateTimeFormat().resolvedOptions();

  const antdEnGBLocaleParts = antdEnGBLocale.split('_');
  if (antdEnGBLocaleParts.length === 1) {
    return enGBLocale;
  }

  const antdEnUSLocaleParts = antdEnUSLocale.split('_');
  if (antdEnUSLocaleParts.length === 1) {
    return enUSLocale;
  }

  const antdEnGBLocaleCode = antdEnGBLocaleParts[1]?.toUpperCase();
  const antdEnUSLocaleCode = antdEnUSLocaleParts[1]?.toUpperCase();

  const intlLocaleParts = intlLocale.split('-');
  const intlLocaleCode = intlLocaleParts.length > 0 ? intlLocaleParts[1]?.toUpperCase() : undefined;

  // TODO: Support more locales if needed

  switch (intlLocaleCode) {
    case antdEnGBLocaleCode: {
      return enGBLocale;
    }
    case antdEnUSLocaleCode: {
      return enUSLocale;
    }
    default: {
      return enGBLocale;
    }
  }
};

export { getAntdLocale, getDisabledDates };
