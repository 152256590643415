/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import { SnippetTypeConst } from '../ContentSnippet/SnippetTypeConst';
import Dropdown from '../UI/Dropdown/Dropdown';
import Input from '../UI/Input/InputWhite';

import { questionActions } from './DropdownOptions';
import RightNavBar from './RightNavBar';

import AppContext from '../../context/AppContext';
import TextEquals from '../../middleware/AddConditionOnAction/TextEquals';
import ActionHide from '../../middleware/QuestionAction/ActionHide';
import ActionShow from '../../middleware/QuestionAction/ActionShow';
import SkipToPage from '../../middleware/QuestionAction/SkipToPage';
import SkipToSnippet from '../../middleware/QuestionAction/SkipToSnippet';

const snippetDDL = snippets => {
  const options = snippets.map((item, index) => {
    if (item.__typename === SnippetTypeConst.FORM) {
      // eslint-disable-next-line max-len
      const formOpt = item.form.contentPages[0].contentSections[0].sectionContainers[0].snippets.map((fItem, fIndex) => ({
        id: `${index + 1}.${fIndex + 1}`,
        value: fItem.id,
        label: `form item ${index + 1}.${fIndex + 1}`,
      }));
      return {
        id: index.toString(),
        value: item.id,
        label: `snippet ${index + 1}`,
        formItems: formOpt,
      };
    }
    return {
      id: index.toString(),
      value: item.id,
      label: `snippet ${index + 1}`,
    };
  });

  const sOption = [];
  let sOptLength = 0;
  options.map((opt, index) => {
    sOption.push(opt);
    if (opt.formItems) {
      sOptLength += index;
      sOption.splice(sOptLength + 1, 0, ...opt.formItems);
      sOptLength += opt.formItems.length;
    }
    return null;
  });
  return sOption;
};

const ContentPageQuestionSideBar = props => {
  const { questionId, snippets } = props;

  const { contentGroup, handleShowSidebar } = useContext(AppContext);

  const [condition, setCondition] = useState({ questionId });

  const [sendRequest, setSendRequest] = useState(false);
  const [addRequest, setAddRequest] = useState(false);

  const options = snippetDDL(snippets);
  const questionIndex = options.find(opt => opt.value === questionId).id;

  const handleConditionUpdate = (value, e) => {
    const newCondition = {
      ...condition,
      [e.target.name]: value,
    };
    setCondition(newCondition);
  };

  const handleCloseSideBar = () => {
    handleShowSidebar([false, '', null]);
  };

  const handleSaveBtn = () => {
    setSendRequest(true);
  };

  const handleActionError = () => {
    setSendRequest(false);
  };
  const handleActionSuccess = data => {
    const newCondition = {
      ...condition,
      questionActionId: data,
    };
    setCondition(newCondition);
    setSendRequest(false);
    setAddRequest(true);
  };
  const handleConditionError = () => {
    setAddRequest(false);
  };
  const handleConditionSuccess = () => {
    setAddRequest(false);
  };

  return (
    <>
      <RightNavBar onCancel={handleCloseSideBar} onSave={handleSaveBtn}>
        <div>
          <h3>Question Condition</h3>
          If the answer of the Question-{questionIndex} is
          <Input isChanged={handleConditionUpdate} name="text" text={condition.text || ''} />
          it will
          <Dropdown
            availableResponses={questionActions}
            name="actionType"
            onChangeHandler={(_, value, event) => handleConditionUpdate(value, event)}
          />
          to
          <Dropdown
            availableResponses={options}
            name="targetId"
            onChangeHandler={(_, value, event) => handleConditionUpdate(value, event)}
          />
        </div>
      </RightNavBar>
      {/* Create an Action query */}
      {sendRequest && condition.actionType === 'skipToSnippet' && (
        <SkipToSnippet
          contentGroupId={contentGroup.id}
          onError={handleActionError}
          onSuccessResult={handleActionSuccess}
          targetSnippetId={condition.targetId}
        />
      )}
      {sendRequest && condition.actionType === 'skipToPage' && (
        <SkipToPage
          contentGroupId={contentGroup.id}
          contentPageId={condition.targetId}
          onError={handleActionError}
          onSuccessResult={handleActionSuccess}
        />
      )}
      {sendRequest && condition.actionType === 'actionHide' && (
        <ActionHide
          contentGroupId={contentGroup.id}
          onError={handleActionError}
          onSuccessResult={handleActionSuccess}
          targetSnippetId={condition.targetId}
        />
      )}
      {sendRequest && condition.actionType === 'actionShow' && (
        <ActionShow
          contentGroupId={contentGroup.id}
          onError={handleActionError}
          onSuccessResult={handleActionSuccess}
          targetSnippetId={condition.targetId}
        />
      )}
      {/* Add condition on the action */}

      {addRequest && (
        <TextEquals
          contentSnippetQuestionId={condition.questionId}
          onError={handleConditionError}
          onSuccessResult={handleConditionSuccess}
          questionActionId={condition.questionActionId}
          text={condition.text}
        />
      )}
    </>
  );
};

ContentPageQuestionSideBar.propTypes = {
  questionId: PropTypes.number.isRequired,
  snippets: PropTypes.arrayOf({
    id: PropTypes.number,
    __typename: PropTypes.string,
  }).isRequired,
};

export default ContentPageQuestionSideBar;
