import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_ORGANISATIONS = gql`
  query {
    organisation {
      getOrganisationsForConnectedUser {
        description
        id
        name
        tag
        url
      }
    }
  }
`;

const getOrganisations = ({ onError, onSuccessResult }) => (
  <Query client={Client()} fetchPolicy="network-only" query={GET_ALL_ORGANISATIONS}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.organisation.getOrganisationsForConnectedUser}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getOrganisations.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getOrganisations;
