import { gql } from '@apollo/client';

export const GET_DIRECTORY_USING_FRIENDLY_URL_QUERY = gql`
  query getDirectoryUsingFriendlyUrl($friendlyUrl: String!, $projectId: String!) {
    directory {
      getDirectoryUsingFriendlyUrl: get(projectId: $projectId, url: $friendlyUrl) {
        address {
          city
          country
          latitude
          line1
          longitude
          postcode
          state
          suburb
        }
        description
        email
        friendlyUrl
        id
        phoneNumber
        title
        url
      }
    }
  }
`;
