import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SNIPPET_ANSWERS } from './UseConditionSnippetAnswers.graphql';

const useConditionSnippetAnswers = (options) => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? {};

  const [executeGetSnippetAnswers, { loading: isLoading }] = useEkardoLazyQuery({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentSnippet: {
            question: {
              contentPage: { contentGroupId },
              question: formQuestion,
              configuration,
            },
          },
        } = data;

        onCompleted({
          contentGroupId,
          formQuestion,
          configuration,
        });
      },
      onError,
    },
    query: query ?? GET_SNIPPET_ANSWERS,
    token,
  });

  const handleGetSnippetAnswers = ({ contentSnippetQuestionId }) => {
    executeGetSnippetAnswers({
      variables: {
        contentSnippetQuestionId,
      },
    });
  };

  return {
    handleGetSnippetAnswers,
    isLoading,
  };
};

export { useConditionSnippetAnswers };
