import React, { useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { addMonths, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';
import CONSTANTS from './Shared/Constants';
import getDropdownOptions from './Shared/getDropdownOptions';
import {
  DropdownListOptionContainer,
  DropdownListOptionsContainer,
  SwitchButtonContainer,
  SwitchButtonItemContainer,
} from './Shared/styled';

import SwitchButton from '../../../components/UI/Button/SwitchButton';
import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import client from '../../../middleware/client';
import { EXPORT_QUESTIONNAIRES } from '../../../middleware/Export/exportQuestionnaires';
import { GET_USERFLOW_STEPS } from '../../../middleware/UserFlow/getUserFlowSteps';
import { downloadFileByUrl } from '../../../utils/utils';

const ColumnHeadingTypeEnum = {
  IDENTIFIER: 'IDENTIFIER',
  QUESTION: 'QUESTION',
};

const ExportQuestionnairesPage = () => {
  const { projectId } = useParams();

  const [apiError, setApiError] = useState(undefined);
  const [columnHeadingTypeIndex, setColumnHeadingTypeIndex] = useState(CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID);
  const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [hasLoadingUserFlowStepsError, setHasLoadingUserFlowStepsError] = useState(false);
  const [includeNonAccountData, setIncludeNonAccountData] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [stepId, setStepId] = useState(CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID);
  const [stepOptions, setStepOptions] = useState(null);
  const [receiver, setReceiver] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { loading: isLoadingUserFlowSteps } = useQuery(GET_USERFLOW_STEPS, {
    client,
    onCompleted: (data) => {
      setIsFormValid(isValid(dateFrom) && isValid(dateTo) && stepId > -1);
      setStepOptions(
        getDropdownOptions(
          CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID,
          CONSTANTS.DROPDOWN.DEFAULT_OPTION_LABEL.STEP,
          data.userFlowStep.getStepsOfProject,
          ['stepName'],
          'id',
        ),
      );
    },
    onError: (error) => {
      setApiError(error);
      setHasLoadingUserFlowStepsError(true);
    },
    variables: {
      projectId,
    },
  });

  const [sendExportQuestionnairesQuery, { loading: isGeneratingReport }] = useLazyQuery(EXPORT_QUESTIONNAIRES, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {},
    onError: (error) => {
      setApiError(error);
    },
  });

  const columnHeadingTypeDefaultOption = [
    {
      id: -1,
      label: 'Select a column heading type',
      value: '',
    },
  ];

  const columnHeadingTypeKeys = Object.keys(ColumnHeadingTypeEnum);
  const columnHeadingTypeOptions = columnHeadingTypeKeys.map((key, index) => ({
    id: index,
    label: ColumnHeadingTypeEnum[key],
    value: index,
  }));

  const validateEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

  const download = (fileType = 'EXCEL' | 'CSV' | 'SPSS') => {
    if (stepId === CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID) {
      return;
    }

    if (!validateEmail(receiver)) {
      console.warn('Invalid email provided');
      return;
    }

    sendExportQuestionnairesQuery({
      variables: {
        columnHeadingType: columnHeadingTypeKeys[columnHeadingTypeIndex],
        dateFrom,
        dateTo,
        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        includeNonAccountData,
        stepId,
        fileType,
        receiver,
      },
    });
  };

  const handleColumnHeadingTypeSelected = (selectedColumnHeadingTypeIndex) => {
    setColumnHeadingTypeIndex(selectedColumnHeadingTypeIndex);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo) && selectedColumnHeadingTypeIndex > -1 && stepId > -1);
  };

  const handleIncludeNonAccountDataChanged = () => {
    setIncludeNonAccountData(!includeNonAccountData);
  };

  const handleDateFromChange = (value) => {
    setDateFrom(value);
    setIsFormValid(isValid(value) && isValid(dateTo) && columnHeadingTypeIndex > -1 && stepId > -1);
  };

  const handleDateToChange = (value) => {
    setDateTo(value);
    setIsFormValid(isValid(dateFrom) && isValid(value) && columnHeadingTypeIndex > -1 && stepId > -1);
  };

  const handleOptionSelected = (selectedId) => {
    setStepId(selectedId);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo) && columnHeadingTypeIndex > -1 && selectedId > -1);
  };

  const handleReceiverOnChange = (event) => {
    const {
      target: { value },
    } = event;
    setReceiver(value);
  };

  const dataConfigurationComponent = (
    <>
      <h2>Receiver</h2>
      <input
        aria-label="Component Title"
        className="w-100 bg-transparent p-2 border"
        name="appVersion"
        placeholder="Email address to send the export to"
        required=""
        value={receiver}
        onChange={handleReceiverOnChange}
      />
      <DropdownListOptionsContainer>
        <h2>Data options</h2>

        <DropdownListOptionContainer>
          <Dropdown
            availableResponses={[...columnHeadingTypeDefaultOption, ...columnHeadingTypeOptions]}
            name="column-heading-types"
            selectedValue={columnHeadingTypeIndex.toString()}
            onChangeHandler={(id) => handleColumnHeadingTypeSelected(id)}
          />
        </DropdownListOptionContainer>

        <SwitchButtonContainer>
          <SwitchButtonItemContainer>
            <SwitchButton defaultChecked={includeNonAccountData} id={1} onChange={() => handleIncludeNonAccountDataChanged()} />
          </SwitchButtonItemContainer>
          <SwitchButtonItemContainer>Include non-account data</SwitchButtonItemContainer>
        </SwitchButtonContainer>
      </DropdownListOptionsContainer>
    </>
  );

  const options = {
    apiError,
    dataConfiguration: {
      component: dataConfigurationComponent,
    },
    downloadCSVCallback: () => download('CSV'),
    downloadExcelCallback: () => download('EXCEL'),
    downloadSPSSCallback: () => download('SPSS'),
    filtering: {
      date: {
        dateFrom,
        dateTo,
        handleDateFromChangeCallback: handleDateFromChange,
        handleDateToChangeCallback: handleDateToChange,
      },
      userFlowSteps: {
        handleOptionSelectedCallback: handleOptionSelected,
        hasLoadError: hasLoadingUserFlowStepsError,
        options: stepOptions,
        selectedId: stepId,
      },
    },
    flags: {
      isFormValid,
      preloader: {
        isGeneratingReport,
        isLoadingUserFlowSteps,
      },
    },
    infoBannerText:
      'This page exports user questionnaire responses based on the selected filters. The result of the export will be transfered by email directly in your inbox.',
  };

  return <ExportPage options={options} />;
};

export default ExportQuestionnairesPage;
