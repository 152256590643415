import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import Cookies from 'universal-cookie';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const REGISTER_USER = gql`
  mutation Register(
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
  ) {
    register(
      user: { firstname: $firstname, lastname: $lastname }
      credential: { email: $email, password: $password }
    )
  }
`;

const cookies = new Cookies();
const custom = cookies.get('customBuild');
const projectId = custom === undefined ? null : custom.projectId;

const registerRequest = ({
  firstname,
  lastname,
  email,
  password,
  onRegisterError,
  onSuccessRequest,
}) => (
  <Mutation client={Client()} mutation={REGISTER_USER}>
    {(registerUser, { loading, error, data }) => {
      const variables = {
        firstname,
        lastname,
        email,
        password,
        projectId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onRegisterError} />;
      }
      if (data) {
        onSuccessRequest();
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={registerUser}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

registerRequest.propTypes = {
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  onRegisterError: PropTypes.func.isRequired,
  onSuccessRequest: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
};

export default registerRequest;
