import { gql } from '@apollo/client';

const GET_CONTENT_SNIPPETS_FOR_TOOL_BOX = gql`
  query getContentSnippetsForToolbox(
    $shouldIncludeApps: Boolean = false
    $shouldIncludeAudios: Boolean = false
    $shouldIncludeContentPage: Boolean = false
    $shouldIncludeDocuments: Boolean = false
    $shouldIncludeEmbeds: Boolean = false
    $shouldIncludeForms: Boolean = false
    $shouldIncludeUserFlowStep: Boolean = false
    $shouldIncludeVideos: Boolean = false
    $projectId: String!
    $userFlowIds: [Int]!
  ) {
    contentSnippet {
      getContentSnippetsForToolbox(projectId: $projectId, userFlowIds: $userFlowIds) {
        contentPage @include(if: $shouldIncludeContentPage) {
          ...contentPageFragment
        }
        id
        ... on ContentSnippetAppType @include(if: $shouldIncludeApps) {
          app {
            title
          }
        }
        ... on ContentSnippetAudioType @include(if: $shouldIncludeAudios) {
          audio: asset {
            ...assetFragment
          }
        }
        ... on ContentSnippetDocumentType @include(if: $shouldIncludeDocuments) {
          document: asset {
            ...assetFragment
          }
        }
        ... on ContentSnippetEmbedType @include(if: $shouldIncludeEmbeds) {
          embed: code
          title
        }
        ... on ContentSnippetFormType @include(if: $shouldIncludeForms) {
          form {
            title
          }
        }
        ... on ContentSnippetVideoType @include(if: $shouldIncludeVideos) {
          video: asset {
            ...assetFragment
          }
        }
      }
    }
  }

  fragment assetFragment on AssetType {
    assetId
    contentType
    fileName
    fileSizeInBytes
    s3Key
  }

  fragment contentPageFragment on ContentPageType {
    id
    sort
    title
    url
    userFlowStep @include(if: $shouldIncludeUserFlowStep) {
      ...userFlowStepFragment
    }
  }

  fragment userFlowStepFragment on UserFlowStepType {
    contentGroup {
      groupingContentGroup {
        id
        name
        sort
        url
      }
    }
    id
    description
    sort
    stepName
    type
    url
    userFlow {
      id
      sort
      url
    }
  }
`;

export { GET_CONTENT_SNIPPETS_FOR_TOOL_BOX };
