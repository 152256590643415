/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

//   display: flex;
//   align-items: center;
//   height: ${props => `${props.height}px` || '10px'};
//   justify-content: ${props => props.alignment || 'center'};
// `;

//   border: 1px solid #e4e4e3;
//   margin: 0;
//   width: ${props => `${props.widthPercentage}%` || '100%'};
// `;

const EmbedSnippet = props => {
  const { code } = props;
  const [htmlContent, setHtmlContent] = useState(null);
  useEffect(() => {
    setHtmlContent({ __html: code });
  }, [code]);

  return (
    <div>{htmlContent && <div dangerouslySetInnerHTML={htmlContent} />}</div>
  );
};

EmbedSnippet.propTypes = {
  code: PropTypes.string.isRequired,
};

export default EmbedSnippet;
