import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

class LandingPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const scrollFunction = () => {
      if (
        (document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20) &&
        document.getElementById('myBtn')
      ) {
        document.getElementById('myBtn').style.display = 'block';
      } else if (document.getElementById('myBtn')) {
        document.getElementById('myBtn').style.display = 'none';
      }
    };

    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // };

    // When the user scrolls down 20px from the top of the document,
    // show the button
    window.onscroll = scrollFunction;

    //   {
    //     title: 'CUSTOMISE',
    //     description:
    //       'All our trial sites are mobile friendly.
    // Get access anywhere, anytime on any device.',
    //   },
    //   {
    //     title: 'RESPONSIVE',
    //     description:
    //       'All our trial sites are mobile friendly.
    // Get access anywhere, anytime on any device.',
    //   },
    //   {
    //     title: 'LIBRARIES',
    //     description:
    //       'Keep all your documents, images and
    // audio files in one place, ready for use.',
    //   },
    // ];

    return (
      <Redirect to="/login" />
      // <div>
      //   <Helmet>
      //     <title>
      //       Ekardo: Building accessible and
      // engaging educational content better
      //     </title>
      //     <meta
      //       content="Login to Ekardo to access and manage your projects"
      //       name="description"
      //     />
      //   </Helmet>
      //   <div className="landing-page">
      //     <div className="landing-page__fold">
      //       <Header
      //         active="features"
      //         location={location && location.pathname}
      //         theme="green"
      //       />
      //       <button
      //         className="button--full myBtn"
      //         id="myBtn"
      //         onClick={topFunction}
      //         title="Go to top"
      //         type="button"
      //       >
      //         Top
      //       </button>

      //       <div className="landing-page__grid">
      //         <div className="landing-page__col">
      //           <h1 className="heading--primary fw-700">FEATURES</h1>
      //           <p className="text--cta">
      //             Our trial packages include everything
      // needed to build a unique
      //             and custom experience for your audience.
      //           </p>
      //           <div className="landing-page__cta">
      //             <AnchorLink
      //               href="#features"
      //               offset="80"
      //               style={{ width: 'auto' }}
      //             >
      //               <button className="button--outline" type="button">
      //                 Learn more
      //               </button>
      //             </AnchorLink>
      //             <p>OR</p>
      //             <Link to="/register">
      //               <button className="button--red" type="button">
      //                 Try now for free
      //               </button>
      //             </Link>
      //           </div>
      //         </div>
      //         <div className="landing-page__col">
      //           <img
      //             alt=""
      //             src="https://assets.kanzi.io/images/home-set.svg"
      //             srcSet=""
      //           />
      //         </div>
      //       </div>
      //       <div
      //         className="flex-column text-right pr-3 mb-3 d-none d-lg-flex"
      //         href="#"
      //       >
      //         <div className="mb-2">
      //           <img alt="fbLogin" height="24px" src={fbRed} width="24px" />
      //         </div>
      //         <div className="">
      //           <img
      //             alt="twiterLogin"
      //             height="24px"
      //             src={twitterRed}
      //             width="24px"
      //           />
      //         </div>
      //       </div>
      //     </div>

      //     <div className="features-learn" id="features">
      //       <div className="features-learn__image">
      //         <img
      //           alt=""
      //           src={`${getAssetsUrl()}/graphics/laptop.svg`}
      //           srcSet=""
      //         />
      //       </div>
      //       <div className="features-learn__content">
      //         <p className="heading-pre">
      //           CONTROL AND BUILD YOUR CONTENT, YOUR WAY.
      //         </p>
      //         <h2 className="heading--primary fw-700">EASY TO LEARN
      // & BUILD</h2>
      //         <p>
      //           Surveypod&apos;s toolkit allows you to quickly and easily
      // set up
      //           and customise surveys ready for your trial.Questionnaires and
      //           responses are fully customisable and can be set up
      // according to
      //           your trials exact needs. Maintaining a secure database
      // of trial
      //           participants in one place helps reduce administration
      // tasks for
      //           your team and allows you to focus on research.
      //         </p>
      //       </div>
      //     </div>

      //     <div className="features-custom container-fluid
      // position-relative row">
      //       <div className="row pt-5 pb-0 pb-md-5 customise-container">
      //         <div className="pl-lg-4 pt-0 pt-lg-5 pb-0 pb-md-5 ml-lg-5
      // ml-auto mr-auto features-custom__content--box">
      //           <div className="features-custom__content">
      //             <p className="heading-pre">
      //               TAILOR THE PLAYER TO YOUR WEBSITE
      //             </p>
      //             <h2 className="heading--primary fw-700">CUSTOMISE</h2>
      //           </div>

      //           <div className="d-flex">
      //             <ul className="features-custom__ul">
      //               <li>Build custom reports</li>
      //               <li>Up-to-date user data</li>
      //               <li>Filter and find</li>
      //             </ul>
      //             <ul className="features-custom__ul">
      //               <li>Customise dates</li>
      //               <li>Export and share</li>
      //               <li>Save for later</li>
      //             </ul>
      //           </div>
      //         </div>

      //         <div className="">
      //           <img
      //             alt="Ekardo configurator"
      //             className="features-custom__image"
      //             src={`${getAssetsUrl()}/graphics/browser.svg`}
      //             srcSet=""
      //           />
      //         </div>
      //       </div>
      //     </div>

      //     <div className="features-function">
      //       <div className="features-function__container py-5">
      //         {featureEkardo.map(item => (
      //           <div className="features-function__field" key={item.title}>
      //             <h3 className="heading--primary fw-700">{item.title}</h3>
      //             <p>{item.description}</p>
      //           </div>
      //         ))}
      //       </div>
      //       <div>
      //         {/* <img alt="image" src="http://assets.ekardo.com/banner-graphic/grey-panel.svg"/> */}
      //       </div>
      //     </div>

      //     {/* <ContactPage /> */}

      //     <div className="features-prompt">
      //       <div className="features-prompt__container">
      //         <h2 className="heading--primary fw-700">
      //           Get started
      //           <br />
      //           today
      //         </h2>
      //         {
      //           <p className="text--cta">
      //             It&apos;s easy to get set up, try the tool today for free!
      //           </p>
      //         }
      //         <Link to="/register">
      //           <button className="button--full" type="button">
      //             Register now
      //           </button>
      //         </Link>
      //       </div>
      //     </div>

      //     <Clients />
      //     <Footer onClick={topFunction} />
      //   </div>
      //   {/* <ul>
      //           <li><Link to="/">Home</Link></li>
      //           <li><Link to="/signup">Signup</Link></li>
      //       </ul> */}
      // </div>
    );
  }
}

// LandingPage.propTypes = {
//   // eslint-disable-next-line react/forbid-prop-types
//   location: PropTypes.object,
// };

// LandingPage.defaultProps = {
//   location: undefined,
// };

export default LandingPage;
