import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const REMOVE_FAVORITE_ASSET = gql`
  mutation RemoveAssetToFavorite($assetId: String!) {
    user {
      removeFavouriteAsset(assetId: $assetId)
    }
  }
`;

const removeAssetToFavRequest = ({ assetId, onError, onSuccessResult }) => (
  <Mutation mutation={REMOVE_FAVORITE_ASSET}>
    {(RemoveAssetToFavorite, { loading, error, data }) => {
      const variables = {
        assetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.user.addFavouriteAsset);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={RemoveAssetToFavorite}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

removeAssetToFavRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default removeAssetToFavRequest;
