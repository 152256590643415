import gql from 'graphql-tag';

export const FRAGMENT_MATCH = gql`
  fragment matchConf on Match {
    description
    id
    randomise
    matchItems {
      id
      questionConfigurationMatchId
      sort
      text
    }
    responseSet {
      availableResponses {
        asset {
          id: assetId
          s3Key
          contentType
          alt
          fileName
          tags
          type
          xAxisFocus
        }
        id
        label
        questionResponseSetId
        value
        ... on QuestionResponseMatchType {
          correctPosition
        }
      }
      id
      projectId
      title
      type
    }
    type
    __typename
  }
`;
