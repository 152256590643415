import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input`
  /* max-width: 10em; */
  background: #fff;
  border: 0.8px solid #e4e4e3;
  border-radius: 5px;
  display: block;
  line-height: 1rem;
  margin: 0.5rem 0;
  width: 100%;

  &::-webkit-inner-spin-button {
    opacity: 1;
  }

  &:focus {
    background: #fff;
    border: 2px solid lightgray;
    outline: none;
  }
`;

const NumberInput = props => {
  const { isChanged, number, name, min, max } = props;
  const [typed, setTyped] = useState(false);
  const [value, setValue] = useState(number);

  const handleText = e => {
    setTyped(true);
    setValue(e.target.value);
  };

  const handleSubmit = e => {
    isChanged(value, e);
  };

  //   if (e.key === 'Enter') isSubmit();
  // };

  return (
    <StyledInput
      name={name}
      onBlur={e => handleSubmit(e)}
      onChange={e => handleText(e)}
      // onKeyPress={e => handlePressEnter(e)}
      type="number"
      value={typed ? value : number}
      min={min}
      max={max}
    />
  );
};

NumberInput.propTypes = {
  isChanged: PropTypes.func,
  name: PropTypes.string,
  number: PropTypes.number,
};

NumberInput.defaultProps = {
  isChanged: () => {},
  name: '',
  number: 0,
};

export default NumberInput;
