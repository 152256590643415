import React from 'react';

import dompurify from 'dompurify';

import { DOMPurifySanitizedHtmlContentProps } from './DOMPurifySanitizedHtmlContent.types';

const DOMPurifySanitizedHtmlContent = ({ config, html = '' }: DOMPurifySanitizedHtmlContentProps) => {
  const { sanitize } = dompurify;

  const sanitizedHtml = config ? (sanitize(html, config) as string) : sanitize(html);

  return (
    <div
      className="c-dompurify-sanitized-html-content"
      // We are sanitising input here as content comes from a third party and will evaluate if there is a performance implication
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    />
  );
};

export { DOMPurifySanitizedHtmlContent };
