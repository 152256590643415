import gql from 'graphql-tag';

export const FRAGMENT_SPACER = gql`
  fragment spacer on ContentSnippetSpacerType {
    height
    lineBreak
    widthPercentage
    alignment
  }
`;
