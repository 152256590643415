import React from 'react';

import { IKeyValuePair } from '@netfront/common-library';
import { IAvatarConfiguration } from '@netfront/gelada-identity-library';
import Avatar from 'avataaars';

import { AvatarSnippetProps } from './AvatarSnippet.types';

const AvatarSnippet = ({ avatar: { configurations }, id }: AvatarSnippetProps) => {
  const avatarConfiguration = configurations?.reduce((accumulator: IAvatarConfiguration, { key, value }: IKeyValuePair<string, string>) => {
    const updatedKey = `${key.substring(0, 1).toLowerCase() + key.substring(1)}`;

    return {
      ...accumulator,
      [updatedKey]: value,
    };
  }, {} as IAvatarConfiguration);

  return (
    <Avatar
      key={`avatar-${id}`}
      accessoriesType={avatarConfiguration?.accessoriesType}
      avatarStyle="Circle"
      clotheColor={avatarConfiguration?.clotheColor}
      clotheType={avatarConfiguration?.clotheType}
      eyebrowType={avatarConfiguration?.eyebrowType}
      eyeType={avatarConfiguration?.eyeType}
      facialHairColor={avatarConfiguration?.facialHairColor}
      facialHairType={avatarConfiguration?.facialHairType}
      graphicType={avatarConfiguration?.graphicType}
      hairColor={avatarConfiguration?.hairColor}
      mouthType={avatarConfiguration?.mouthType}
      pieceSize={avatarConfiguration?.pieceSize}
      pieceType={avatarConfiguration?.pieceType}
      skinColor={avatarConfiguration?.skinColor}
      topType={avatarConfiguration?.topType}
    />
  );
};

export { AvatarSnippet };
