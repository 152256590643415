import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const TabBar = styled.div`
  width: fit-content;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 0.6em;
`;

export const TabBtn = styled.button`
  border: none;
  /* color: #7a7a7a;
  background-color: #f4f4f4; */
  background-color: ${({ hasSelected }) =>
    hasSelected ? 'rgba(9, 30, 66, 0.1)' : '#f4f4f4'};
  color: ${({ hasSelected }) => (hasSelected ? '#000' : '#7a7a7a;')};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 0.5em 0.8em;
  margin-right: 10px;
  &:focus {
    outline: none;
  }
`;

export const LinkIcon = styled.div`
  height: 1.4em;
  width: 1.4em;
  border-radius: 50%;
  margin-right: 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #4bc1c9;
  transform: rotate(90deg);
`;

export const LinkBtn = styled.a`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border: none;
  background-color: #e1e1e1;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 0.8em;
  border-radius: 5px;
`;

export const SearchBar = styled.div`
  width: 35%;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  margin: 0;
  background: #f4f4f4
    url('//dev.assets.ekardo.com/icons/myapp-dashboard/search-tool.svg') 1em
    center/16px no-repeat;
  border-radius: 5px;
  padding: 5px 15px 5px 45px;
`;

export const StyleView = styled.div`
  margin: 15px 0;
  text-align: right;
`;

export const ListViewBtn = styled.button`
  background: url('//dev.assets.ekardo.com/icons/myapp-dashboard/list-view.svg')
    center/20px no-repeat;
  border: none;
  height: 22px;
  padding: 0;
  width: 22px;
`;

export const GridViewBtn = styled.button`
  background: url('//dev.assets.ekardo.com/icons/myapp-dashboard/grid-view.svg')
    center/18px no-repeat;
  border: none;
  height: 22px;
  margin-left: 8px;
  padding: 0;
  width: 22px;
`;

export const AppContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -2em;
`;
