import { gql } from '@apollo/client';

const CREATE_BOOKMARK_MUTATION = gql`
  mutation createBookmark($contentPageId: Int!) {
    bookmark {
      createBookmark: addBookmark(input: { contentPageId: $contentPageId }) {
        contentPageId
        id
      }
    }
  }
`;

export { CREATE_BOOKMARK_MUTATION };
