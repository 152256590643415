/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';

function AddCard(props) {
  const { text, children, styles, onClick } = props;

  return (
    <>
      <div
        className="c-add-card"
        onClick={onClick}
        onKeyPress={onClick}
        style={{ ...styles }}
      >
        <span className="c-add-card__icon icon-plus" />
        <span className="c-add-card__text">{text}</span>
      </div>
      {children}
    </>
  );
}

AddCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.element,
  styles: PropTypes.string,
};

AddCard.defaultProps = {
  children: null,
  styles: '',
};

export default AddCard;
