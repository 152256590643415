import { gql } from '@apollo/client';

export const CREATE_DIRECTORY_MUTATION = gql`
  mutation createDirectory($address: AddressInputType, $directory: CreateDirectoryInput!) {
    directory {
      createDirectory(address: $address, directory: $directory) {
        id
      }
    }
  }
`;
