import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import styled from 'styled-components';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {
  Filters,
  SearchContainer,
  ButtonReset,
  PushRight,
  ContainerScroll,
  TableContainer,
  EditButton,
  FirstnameTd,
  ResendBtn,
  PendingStatus,
  ActiveStatus,
} from '../OrganisationUsersPage/styled';

import Button from '../../components/Buttons/Button';
import DropDownSelect from '../../components/DropDownSelect/DropDownSelect';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import ProjectTeamSideBar from '../../components/RightNavBar/ProjectTeamSideBar';
import SearchInput from '../../components/SearchInput/SearchInput';
import Avatar from '../../components/UI/Avatar/colorAvatar';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import NoDataAlerts from '../../components/UI/NoDataComp/NoDataAlert';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import GetPendingUsers from '../../middleware/UsersManagement/getProjectInvitationListRequest';
import GetProjectUsers from '../../middleware/UsersManagement/getProjectMemberShipListRequest';
import ResendInvitation from '../../middleware/UsersManagement/resendInvitation';
import { getBreadCrumbPath } from '../../utils/utils';

const Star = styled.span`
  color: #ffc96c;
  margin-left: 1.5em;
`;

const permissionTypes = {
  MANAGE_USERS: 'Manage users',
  EDIT: 'Edit',
  READ: 'View',
};

const ProjectTeamPage = props => {
  const { match } = props;
  const { pathname } = useLocation();

  const [userLoaded, setUserLoaded] = useState(false);
  const [pendingUserLoaded, setPendingUserLoaded] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredPendingUsers, setFilteredPendingUsers] = useState([]);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [permissionFilter, setPermissionFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [resendRequest, setResendRequest] = useState(false);
  const [invitationId, setInvitationId] = useState(null);
  const [message, setMessage] = useState('');

  const handleGetUsersError = () => {
    setUserLoaded(true);
  };

  const handleGetUsersSuccess = data => {
    const users = [];
    data.map(item => {
      if (!users) users.push(item);
      else {
        const found = users.find(u => u.userId === item.userId);
        if (!found) users.push(item);
      }
      return null;
    });
    setProjectUsers(users);
    setFilteredUsers(users);
    setUserLoaded(true);
  };

  const handleGetPendingUsersError = () => {
    setPendingUserLoaded(true);
  };

  const handleGetPendingUsersSuccess = data => {
    setPendingUsers(data);
    setFilteredPendingUsers(data);
    setPendingUserLoaded(true);
  };

  const handleResendError = () => {
    setResendRequest(false);
  };

  const handleResendSuccess = data => {
    setMessage(`Resend the invitation to ${data.firstName} (${data.invitedUserEmail})`);
    setResendRequest(false);
  };

  const handleResend = user => {
    setInvitationId(user.id);
    setResendRequest(true);
  };

  const handleMsgDisappear = () => {
    setTimeout(() => {
      setMessage('');
    }, 500);
  };

  const handleEditBtn = item => {
    setSelectedUser(item);
    setIsPanelOpen(true);
  };

  const handleClosePanel = update => {
    setSelectedUser({});
    setIsPanelOpen(false);
    if (update === 'pending') {
      setPendingUserLoaded(false);
    }
    if (Array.isArray(update)) {
      projectUsers.map(user => {
        if (user.userId === update[0]) {
          const tmp = projectUsers.filter(item => item.userId !== update[0]);
          const newUsers = [
            ...tmp,
            {
              ...user,
              permission: update[1],
            },
          ];
          setProjectUsers(newUsers);
          setFilteredUsers(newUsers);
        }
        return null;
      });
    }
  };

  const handleSearchFilter = e => {
    const { value } = e.target;
    const orgColumns = ['firstname', 'lastname'];
    const pendingColumns = ['firstName', 'lastName', 'invitedUserEmail'];
    const filteredOrg = projectUsers.filter(item => orgColumns.some(key => item.user[key].toLowerCase().includes(value.toLowerCase())));
    setFilteredUsers(filteredOrg);
    const filteredPending = pendingUsers.filter(item => pendingColumns.some(key => item[key].toLowerCase().includes(value.toLowerCase())));
    setFilteredPendingUsers(filteredPending);
    setPermissionFilter('');
  };

  const handlePermissionFilter = value => {
    setPermissionFilter(permissionTypes[value]);
    if (value !== 'All permissions') {
      const tmpProject = projectUsers.filter(item => item.permission === value);
      const tmpPending = pendingUsers.filter(item => item.permission === value);
      setFilteredUsers(tmpProject);
      setFilteredPendingUsers(tmpPending);
    } else {
      setFilteredUsers(projectUsers);
      setFilteredPendingUsers(pendingUsers);
    }
  };

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'User management'), ...getBreadCrumbPath(pathname, 0, 'Project team')];

  const toolbar = (
    <Filters>
      <SearchContainer>
        <SearchInput
          onChange={e => {
            handleSearchFilter(e);
          }}
        />
      </SearchContainer>
      <DropDownSelect label="permissions" name="permissions" value={permissionFilter || 'All permissions'}>
        <ul>
          <li key="all1">
            <ButtonReset onClick={() => handlePermissionFilter('All permissions')} type="button">
              All permissions
            </ButtonReset>
          </li>
          {Object.keys(permissionTypes).map(type => (
            <li key={type}>
              <ButtonReset onClick={() => handlePermissionFilter(type)} type="button">
                <span>{permissionTypes[type]}</span>
              </ButtonReset>
            </li>
          ))}
        </ul>
      </DropDownSelect>

      <PushRight>
        <Button
          onClick={() => {
            setIsPanelOpen(true);
          }}
        >
          Invite member
        </Button>
      </PushRight>
    </Filters>
  );

  const userTable = (
    <ContainerScroll>
      <TableContainer>
        <Thead>
          <Tr key="table-head">
            <Th data-sort="firstname" scope="col" style={{ paddingLeft: '2.3em' }}>
              First name
            </Th>
            <Th data-sort="lastname" scope="col">
              Last name
            </Th>
            <Th data-sort="email" scope="col">
              Email
            </Th>
            <Th data-sort="permission" scope="col">
              Permission
            </Th>
            <Th data-sort="status" scope="col">
              Status
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredUsers.length !== 0 &&
            filteredUsers.map(user => (
              <Tr key={user.user.email}>
                <FirstnameTd>
                  <Avatar name={`${user.user.firstname} ${user.user.lastname}`} />
                  <p style={{ marginLeft: '1em' }}> {user.user.firstname}</p>
                </FirstnameTd>
                <Td>{user.user.lastname}</Td>
                <Td>{user.user.credential.email}</Td>
                <Td>{permissionTypes[user.permission]}</Td>
                <Td>
                  <ActiveStatus isActive={user.user.status === 'ACTIVE'} />
                  {user.user.status.charAt(0) + user.user.status.toLowerCase().slice(1)}
                </Td>
                <Td>
                  {user.permission === 'NONE' ? <EditButton onClick={() => handleEditBtn(user)}>···</EditButton> : <Star>&#9733;</Star>}
                </Td>
              </Tr>
            ))}
          {filteredPendingUsers.length !== 0 &&
            filteredPendingUsers.map(user => (
              <Tr key={user.invitedUserEmail}>
                <FirstnameTd>
                  <Avatar name={`${user.firstName} ${user.lastName}`} />
                  <p style={{ marginLeft: '1em' }}>{user.firstName}</p>
                </FirstnameTd>
                <Td>{user.lastName}</Td>
                <Td>{user.invitedUserEmail}</Td>
                <Td>{permissionTypes[user.permission]}</Td>
                <Td>
                  <PendingStatus>!</PendingStatus>
                  Pending
                </Td>
                <Td>
                  <ResendBtn onClick={() => handleResend(user)}>Resend</ResendBtn>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </TableContainer>
    </ContainerScroll>
  );

  return (
    <>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage project team members"
        pageDescription="Manage project team members "
        pageTitle="Project team page"
        params={match.params}
      >
        <ProjectNavBar title="Project team" />
        {isPanelOpen && (
          <>
            <ProjectTeamSideBar onCancel={handleClosePanel} user={selectedUser} />
            <Backdrop isClicked={handleClosePanel} />
          </>
        )}
        {toolbar}
        {filteredUsers.length > 0 || filteredPendingUsers.length > 0 ? userTable : <NoDataAlerts />}
        {message !== '' && <PopUpMsg message={message} msgDisappear={handleMsgDisappear} />}
      </PageWrapper>
      {!userLoaded && (
        <GetProjectUsers onError={handleGetUsersError} onSuccessResult={handleGetUsersSuccess} projectId={match.params.projectId} />
      )}

      {!pendingUserLoaded && (
        <GetPendingUsers
          onError={handleGetPendingUsersError}
          onSuccessResult={handleGetPendingUsersSuccess}
          pending
          projectId={match.params.projectId}
        />
      )}
      {resendRequest && <ResendInvitation invitationId={invitationId} onError={handleResendError} onSuccessResult={handleResendSuccess} />}
    </>
  );
};

ProjectTeamPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
    }),
  }).isRequired,
};

export default ProjectTeamPage;
