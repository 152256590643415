import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const COPY_CONTENT_PAGE = gql`
  mutation CopyContentPage($contentPageId: Int!) {
    contentPage {
      copyContentPage(contentPageId: $contentPageId)
    }
  }
`;

const copyContentPageRequest = ({
  contentPageId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={COPY_CONTENT_PAGE}>
    {(copyContentPage, { loading, error, data }) => {
      const variables = {
        contentPageId,
        onError,
        onSuccessResult,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }

      if (data) {
        onSuccessResult(data.contentPage.copyContentPage);
        return null;
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={copyContentPage}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

copyContentPageRequest.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default copyContentPageRequest;
