import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_ASSET = gql`
  mutation CreateAsset(
    $appVersionId: Int!
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String!
    $fileSizeInBytes: Int!
    $transcript: String
  ) {
    asset {
      createAppVersionAsset(
        asset: {
          appVersionId: $appVersionId
          type: $type
          fileName: $fileName
          contentType: $contentType
          fileSizeInBytes: $fileSizeInBytes
          alt: $alt
          transcript: $transcript
        }
      ) {
        signedUrl
        asset {
          assetId
          s3Key
          contentType
        }
      }
    }
  }
`;

const createAppAssetRequest = ({
  appVersionId,
  contentType,
  type,
  fileName,
  fileSizeInBytes,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_ASSET}>
    {(createAsset, { loading, error, data }) => {
      const variables = {
        appVersionId,
        type,
        contentType,
        fileName,
        fileSizeInBytes,
        alt: 'title',
        transcript: 'transcript',
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.asset.createAppVersionAsset.signedUrl,
          data.asset.createAppVersionAsset.asset
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAsset}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createAppAssetRequest.propTypes = {
  appVersionId: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSizeInBytes: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default createAppAssetRequest;
