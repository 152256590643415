import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const SUBMIT_APP_FOR_APPROVAL = gql`
  mutation publishMyApp($appVersionId: Int!) {
    app {
      appVersionMutation {
        publishAppVersion(inputType: { appVersionId: $appVersionId })
      }
    }
  }
`;

const publishAppVersionRequest = ({
  appVersionId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={SUBMIT_APP_FOR_APPROVAL}>
    {(publishMyApp, { loading, error, data }) => {
      const variables = {
        appVersionId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.appVersionMutation.publishAppVersion);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={publishMyApp}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

publishAppVersionRequest.propTypes = {
  appVersionId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default publishAppVersionRequest;
