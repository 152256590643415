import { IQuestionAnswerMultiResponseTextItem } from '../../../interfaces';

const DEFAULT_MULTI_RESPONSE_TEXT_ITEM_ID = 0;

const DEFAULT_MULTI_RESPONSE_TEXT_ITEM: IQuestionAnswerMultiResponseTextItem = {
  id: DEFAULT_MULTI_RESPONSE_TEXT_ITEM_ID,
  status: 'NEW',
  value: '',
};

const DEFAULT_SELECTED_INDEX = -1;

const PREFIX = 'multi-response-text-';

export { DEFAULT_MULTI_RESPONSE_TEXT_ITEM, DEFAULT_MULTI_RESPONSE_TEXT_ITEM_ID, DEFAULT_SELECTED_INDEX, PREFIX };
