import React, { ChangeEvent } from 'react';

import cx from 'classnames';

import { Message } from '../Message';
import { Spacing } from '../Spacing';

import { Checkbox } from './Checkbox';
import { ICheckboxItem } from './Checkbox/Checkbox.types';
import { ICheckboxGroup } from './CheckboxGroup.types';

const CheckboxGroup = ({
  errorMessage,
  isDisabled = false,
  isLabelHidden = false,
  isRequired = false,
  items = [],
  legendText,
  name,
  onChange,
  values = [],
}: ICheckboxGroup) => {
  return (
    <fieldset aria-describedby={errorMessage ? `id_${name}_error` : undefined} data-testid="qa-checkbox-group-fieldset">
      <legend className="c-label">
        <Spacing>
          <span
            className={cx({
              'c-label__text': true,
              'h-hide-visually': isLabelHidden,
            })}
            data-testid="qa-label"
          >
            {legendText}
          </span>
          {isRequired && (
            <abbr aria-label="required" className="is-required" title="required">
              *
            </abbr>
          )}
        </Spacing>
      </legend>
      {items.map((item: ICheckboxItem) => {
        const { deleteButton, id, imageSrc, labelText: checkboxItemLabelText, value } = item;
        return (
          <Checkbox
            key={`checkbox-${id}`}
            data-testid="qa-checkbox"
            deleteButton={deleteButton}
            id={id}
            imageSrc={imageSrc}
            isChecked={values.includes(id.toString())}
            isDisabled={isDisabled}
            labelText={checkboxItemLabelText}
            name={name}
            value={value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const {
                target: { value: inputValue },
              } = event;

              const updatedValues = values.includes(inputValue)
                ? values.filter((currentValue) => currentValue !== inputValue)
                : [...values, inputValue];

              onChange(updatedValues);
            }}
          />
        );
      })}
      {Boolean(errorMessage) && <Message id={`id_${name}_error`} text={errorMessage} type="ERROR" />}
    </fieldset>
  );
};

export { CheckboxGroup };
