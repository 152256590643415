import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  input {
    opacity: 0;
    position: absolute;
  }
`;

export const ToggleButton = styled.div`
  background-color: ${({ isChecked, checkedColor, uncheckedColor }) => (isChecked ? checkedColor : uncheckedColor)};
  border-radius: 2rem;
  height: 1.6rem;
  position: relative;
  transition: 0.1s ease-in-out;
  width: 3.1rem;

  &:before {
    background-color: #fff;
    border: 0.12rem solid ${({ isChecked, checkedColor, uncheckedColor }) => (isChecked ? checkedColor : uncheckedColor)};
    border-radius: 50%;
    content: '';
    height: 1.6rem;
    left: ${({ isChecked }) => (isChecked ? '100%' : '0')};
    position: absolute;
    top: 50%;
    transform: translate(${({ isChecked }) => (isChecked ? '-100%' : '0')}, -50%);
    transition: 0.1s ease-in-out;
    width: 1.6rem;
  }
`;
