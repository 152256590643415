import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
`;

const Flex = styled.div`
  align-items: center;
  border: 1px solid rgba(9, 30, 66, 0.08);
  border-radius: 5px;
  color: #231f20;
  display: flex;
  justify-content: space-between;
  margin: 1.5em 0;
  padding: 0.8em 1em;
  position: relative;
`;

const Icon = styled.span`
  align-items: center;
  background-color: #4bc1c9;
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 1.5em;
  justify-content: center;
  margin: 0 1em 0 0;
  min-width: 1.5em;
`;

const InfoBanner = props => {
  const { text, url, linkText } = props;

  return (
    <Flex>
      <Container>
        <Icon>i</Icon>
        <div>{text}</div>
      </Container>
      {url ? (
        <Link className="button--blue" to={url}>
          {linkText}
        </Link>
      ) : null}
    </Flex>
  );
};

InfoBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  linkText: PropTypes.string,
  url: PropTypes.string,
};

InfoBanner.defaultProps = {
  linkText: null,
  url: null,
};

export default InfoBanner;
