import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import GeladaClient from '../_Gelada/geladaClient';
import ErrorModal from '../ErrorModal';

import Preloader from '../../components/Preloader/Preloader';

export const LOGIN = gql`
  query ($login: String!, $password: String!) {
    login(login: $login, password: $password) {
      token
      refreshToken
      user {
        id
        firstname
        lastname
        credential {
          email
        }
      }
    }
  }
`;
const cookies = new Cookies();
const custom = cookies.get('customBuild');
const projectId = custom === undefined ? null : custom.projectId;

const loginRequest = ({ userName, password, onLoginError }) => (
  <Query
    client={GeladaClient()}
    fetchPolicy="network-only"
    query={LOGIN}
    variables={{
      login: userName,
      password,
      customProjectId: projectId,
    }}
  >
    {({ loading, error, data }) => {
      cookies.remove('_a', { path: '/' });
      if (loading) return <Preloader />;
      if (error) {
        return <ErrorModal error={error} onError={onLoginError} />;
      }

      if (data && Object.keys(data).length > 0) {
        cookies.set('_a', data.login.token, {
          path: '/',
        });
        cookies.set('REFRESH_TOKEN', data.login.refreshToken, {
          path: '/',
        });
      }
      const abc = cookies.get('customBuild');
      if (abc && abc.projectId) {
        return <Redirect to={`/dashboard/${abc.orgId}/project/${abc.projectId}`} />;
      }
      return <Redirect to="/dashboard" />;
    }}
  </Query>
);
loginRequest.propTypes = {
  onLoginError: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  // customprojectId: PropTypes.string,
};

// loginRequest.defaultProps = {
//   customProjectId: null,
// };

export default loginRequest;
