import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Checkbox, Form, FormButtons, Input, Label, RadioButtonGroup, Spacing, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { useToast } from '../../../../../../hooks';
import client from '../../../../../../middleware/client';
import { CREATE_ACTION_QUERY_MAP } from '../../../ActionTab.constants';
import { getEventValue, getEventChecked } from '../../../helpers';
import { useConditionAdd, useConditionLocations } from '../../../hooks';
import {
  NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS,
  QUESTION_ACTION_PROP_TYPE,
  QUESTION_LOCATION_PROP_TYPE,
  SELECTED_CONDITION_QUESTION_PROP_TYPE,
  WRAPPER_PROP_TYPE,
} from '../../QuestionConditions.constants';

import '../../../ActionTab.css';

const DEFAULT_TEXT_CONDITION = {
  contentPageId: undefined,
  contentSnippetQuestionId: undefined,
  isTemplate: false,
  questionActionId: undefined,
  text: undefined,
  title: undefined,
};

const QuestionConditionTextNew = ({ args }) => {
  const {
    actionWrapper,
    activePageId,
    conditionAction,
    questionAction,
    questionLocations,
    questionTitle,
    selectedConditionQuestionItem,
    onAddActionCompleted,
    onAddConditionCompleted,
    onAddQuestionConditionLocations,
    onAddSelectedQuestionItem,
    onCloseSidebar,
  } = args;

  const { handleToastError } = useToast();

  const { id: wrapperId, type: wrapper } = actionWrapper;

  const [currentAction, setCurrentAction] = useState(questionAction);
  const [isTemplateChecked, setIsTemplateChecked] = useState(false);
  const [newCondition, setNewCondition] = useState({
    ...DEFAULT_TEXT_CONDITION,
    title: questionTitle,
  });

  const [questionSnippetForms, setQuestionSnippetForms] = useState([]);

  const createConditionVariables = {
    contentPageId: newCondition.contentPageId,
    contentSnippetQuestionId: newCondition.contentSnippetQuestionId,
    isTemplate: isTemplateChecked,
    questionActionId: questionAction ? questionAction.id : currentAction?.id,
    text: newCondition.text,
    title: newCondition.title,
  };

  const handleAddConditionCompleted = ({ condition }) => {
    const { conditions = [] } = currentAction;

    const updatedAction = {
      ...currentAction,
      conditions: [...conditions, condition],
    };

    setTimeout(() => {
      setNewCondition(DEFAULT_TEXT_CONDITION);
      onAddSelectedQuestionItem(undefined);

      onAddConditionCompleted(updatedAction);
    }, NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
  };

  const handleGetConditionLocationsCompleted = ({ getSnippetForms }) => {
    setQuestionSnippetForms(getSnippetForms);

    const conditionItemLocations = getSnippetForms.map(
      ({
        contentPage: {
          id,
          title: contentPageTitle,
          contentGroup: { title: contentGroupTitle },
        },
      }) => ({
        id,
        labelText: `${contentPageTitle} - ${contentGroupTitle}`,
        value: id.toString(),
      }),
    );

    onAddQuestionConditionLocations(conditionItemLocations);
  };

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleAddCondition } = useConditionAdd({
    onCompleted: handleAddConditionCompleted,
    onError: handleGetError,
    conditionType: 'text',
    variables: createConditionVariables,
  });

  const { handleGetLocations } = useConditionLocations({
    onCompleted: handleGetConditionLocationsCompleted,
    onError: handleGetError,
  });

  const [executeCreateAction] = useMutation(CREATE_ACTION_QUERY_MAP[wrapper][conditionAction], {
    client,
    onCompleted(data) {
      const {
        questionAction: { addAction: action },
      } = data;

      setCurrentAction(action);

      onAddActionCompleted(action);

      createConditionVariables.questionActionId = action.id;

      handleAddCondition({
        createConditionVariables,
      });
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleIsTemplateChecked = (event) => {
    setIsTemplateChecked(getEventChecked(event));
  };

  const handleQuestionLocationInputChange = (event) => {
    setNewCondition({
      ...newCondition,
      contentPageId: Number(getEventValue(event)),
    });
  };

  const handleSaveCondition = (event) => {
    event.preventDefault();

    const { contentPageId, contentSnippetQuestionId, text, title } = newCondition;

    if (questionAction) {
      handleAddCondition({
        createConditionVariables,
      });

      return;
    }

    if (!contentSnippetQuestionId || !text || !title || !contentPageId) {
      return;
    }

    executeCreateAction({
      variables: {
        contentPageId: activePageId,
        wrapperId,
      },
    });
  };

  const handleTextChange = (event) => {
    setNewCondition({
      ...newCondition,
      text: getEventValue(event),
    });
  };

  useEffect(() => {
    if (!questionSnippetForms.length) {
      return;
    }
    const [firstContentSnippetForm] = questionSnippetForms;

    const {
      contentPage: { id },
    } = firstContentSnippetForm;

    setNewCondition((currentLocation) => ({
      ...currentLocation,
      contentPageId: id,
    }));
  }, [questionSnippetForms]);

  useEffect(() => {
    if (!selectedConditionQuestionItem) {
      return;
    }

    const {
      id,
      contentPage: { contentGroupId },
    } = selectedConditionQuestionItem;

    handleGetLocations({
      contentGroupId,
    });

    setNewCondition((currentCondition) => ({
      ...currentCondition,
      contentSnippetQuestionId: id,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConditionQuestionItem]);

  const { contentPageId, text } = newCondition;

  const isSaveButtonDisabled = Boolean(!questionTitle) || Boolean(!text);

  return (
    <Form onSubmit={handleSaveCondition}>
      {selectedConditionQuestionItem && Boolean(questionLocations.length) ? (
        <div className="c-action">
          <Spacing size="2x-large" />
          <div className="c-action__item h-flex">
            <Label additionalClassNames="c-action__item__label h-flex" forId="question response" labelText="Question response" isRequired>
              <Tooltip placement="left" text="This action will execute if the text added is as defined below" />
            </Label>
            <Input value={text} onChange={handleTextChange} />
          </div>
          <Spacing size="2x-large" />
          <div className="c-action__item h-flex">
            <Label additionalClassNames="c-action__item__label h-flex" forId="question location" labelText="Question location">
              <Tooltip placement="left" text="This action will execute if the radio selection is as selected below" />
            </Label>
            <RadioButtonGroup
              items={questionLocations}
              name="location"
              selectedValue={contentPageId ? contentPageId.toString() : ''}
              onChange={handleQuestionLocationInputChange}
            />
          </div>
          <Spacing size="2x-large" />
          <div className="c-action__item h-flex">
            <Label additionalClassNames="c-action__item__label h-flex" forId="template" labelText="Template">
              <Tooltip placement="left" text="Save condition to reuse later" />
            </Label>
            <div className="c-action__item__checkbox h-flex">
              <Checkbox
                id="template"
                isChecked={isTemplateChecked}
                labelText="Save conditional configuration for reuse"
                name="template"
                onChange={handleIsTemplateChecked}
              />
            </div>
          </div>
        </div>
      ) : null}
      {selectedConditionQuestionItem ? (
        <FormButtons
          additionalClassNames="c-form-buttons__action-tab"
          isSaveButtonDisabled={isSaveButtonDisabled}
          onClose={onCloseSidebar}
        />
      ) : null}
    </Form>
  );
};

QuestionConditionTextNew.propTypes = {
  args: PropTypes.shape({
    actionWrapper: WRAPPER_PROP_TYPE,
    activePageId: PropTypes.number,
    conditionAction: PropTypes.string,
    onAddActionCompleted: PropTypes.func,
    onAddConditionCompleted: PropTypes.func,
    onAddQuestionConditionLocations: PropTypes.func,
    onAddSelectedQuestionItem: PropTypes.func,
    onCloseSidebar: PropTypes.func,
    questionAction: QUESTION_ACTION_PROP_TYPE,
    questionLocations: QUESTION_LOCATION_PROP_TYPE,
    questionTitle: PropTypes.string,
    selectedConditionQuestionItem: SELECTED_CONDITION_QUESTION_PROP_TYPE,
  }),
};

export { QuestionConditionTextNew };
