import React, { useState, useEffect } from 'react';

import {
  Checkbox,
  Dialog,
  Form,
  FormButtons,
  Input,
  Label,
  NavigationButton,
  Tooltip,
  RadioButtonGroup,
  Spacing,
} from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { useToast } from '../../../../../../hooks';
import { Title } from '../../../Components/Title';
import { getEventValue, getEventChecked, getCharacterCount } from '../../../helpers';
import { getQuestionText } from '../../../helpers/dom';
import { useConditionDelete, useConditionLocations, useConditionSnippetAnswers, useConditionUpdate } from '../../../hooks';
import { NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS, QUESTION_ACTION_PROP_TYPE } from '../../QuestionConditions.constants';

import '../../../ActionTab.css';

const QuestionConditionSliderEdit = ({ args }) => {
  const { onClose, onCloseSidebar, onUpdateConditionCompleted, questionAction, questionCondition, titleMaxCharacter } = args;

  const { handleToastError, handleToastSuccess } = useToast();

  const { title } = questionCondition;

  const [characterCount, setCharacterCount] = useState(getCharacterCount(titleMaxCharacter, title));
  const [conditionQuestion, setConditionQuestion] = useState([]);
  const [currentQuestionCondition, setCurrentQuestionCondition] = useState(questionCondition);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [isTemplateChecked, setIsTemplateChecked] = useState(false);
  const [questionConditionContentGroupId, setQuestionConditionContentGroupId] = useState(undefined);
  const [conditionQuestionLocations, setConditionQuestionLocations] = useState([]);

  const { id: questionConditionId, contentSnippetQuestionId } = questionCondition;

  const handleClickRemoveConditionButton = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleGetConditionLocationsCompleted = ({ getSnippetForms }) => {
    const conditionItemLocations = getSnippetForms.map(
      ({
        contentPage: {
          id,
          title: contentPageTitle,
          contentGroup: { title: contentGroupTitle },
        },
      }) => ({
        id,
        labelText: `${contentPageTitle} - ${contentGroupTitle}`,
        value: id.toString(),
      }),
    );

    setConditionQuestionLocations(conditionItemLocations);
  };

  const handleGetSnippetAnswersCompleted = (data) => {
    const { contentGroupId, formQuestion } = data;

    setConditionQuestion(getQuestionText(formQuestion));
    setQuestionConditionContentGroupId(contentGroupId);
  };

  const handleDeleteConditionCompleted = () => {
    const updatedAction = {
      ...questionAction,
      conditions: questionAction.conditions.filter((condition) => condition.id !== questionConditionId),
    };

    handleToastSuccess({
      message: `Condition deleted successfully`,
    });

    setTimeout(() => {
      onUpdateConditionCompleted(updatedAction);
    }, NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
  };

  const handleUpdateConditionCompleted = () => {
    const updatedAction = {
      ...questionAction,
      conditions: questionAction.conditions.map((condition) =>
        condition.id === currentQuestionCondition.id ? currentQuestionCondition : condition,
      ),
    };

    handleToastSuccess({
      message: `Condition updated successfully`,
    });

    setTimeout(() => {
      onUpdateConditionCompleted(updatedAction);
    }, NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
  };

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleDeleteCondition } = useConditionDelete({
    onCompleted: handleDeleteConditionCompleted,
    onError: handleGetError,
  });

  const updateConditionVariables = {
    contentPageId: currentQuestionCondition.contentPageId,
    contentSnippetQuestionId: currentQuestionCondition.contentSnippetQuestionId,
    isTemplate: isTemplateChecked,
    questionActionId: questionAction.id,
    questionConditionId: currentQuestionCondition.id,
    minValue: currentQuestionCondition.minValue,
    maxValue: currentQuestionCondition.maxValue,
    title: currentQuestionCondition.title,
  };

  const { handleUpdateCondition } = useConditionUpdate({
    onCompleted: handleUpdateConditionCompleted,
    onError: handleGetError,
    conditionType: 'slider',
    variables: updateConditionVariables,
  });

  const { handleGetLocations } = useConditionLocations({
    onCompleted: handleGetConditionLocationsCompleted,
    onError: handleGetError,
  });

  const { handleGetSnippetAnswers } = useConditionSnippetAnswers({
    onCompleted: handleGetSnippetAnswersCompleted,
    onError: handleGetError,
  });

  const handleDeleteQuestionCondition = () => {
    handleDeleteCondition({
      questionConditionId: currentQuestionCondition.id,
    });
  };

  const handleUpdateQuestionCondition = (event) => {
    event.preventDefault();

    handleUpdateCondition({
      updateConditionVariables,
    });
  };

  useEffect(() => {
    handleGetSnippetAnswers({
      contentSnippetQuestionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToCondition = () => {
    onClose();
  };

  const handleIsTemplateChecked = (event) => {
    setIsTemplateChecked(getEventChecked(event));
  };

  const handleQuestionLocationChange = (event) => {
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      contentPageId: Number(getEventValue(event)),
    });
  };

  const handleMinValueChange = (event) => {
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      minValue: getEventValue(event),
    });
  };

  const handleMaxValueChange = (event) => {
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      maxValue: getEventValue(event),
    });
  };

  const handleTitleChange = (event) => {
    setCharacterCount(getCharacterCount(titleMaxCharacter, getEventValue(event)));
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      title: getEventValue(event),
    });
  };

  useEffect(() => {
    if (!questionConditionContentGroupId) {
      return;
    }

    handleGetLocations({
      contentGroupId: questionConditionContentGroupId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionConditionContentGroupId]);

  const isSaveButtonDisabled =
    Boolean(!currentQuestionCondition.title) || Boolean(!currentQuestionCondition.minValue) || Boolean(!currentQuestionCondition.maxValue);

  return (
    <Form onSubmit={handleUpdateQuestionCondition}>
      <div className="c-action">
        <NavigationButton
          additionalClassNames="c-action__back-to-action"
          direction="previous"
          iconId="id_caret_icon"
          rotationCssSuffix="90"
          text="Condition"
          onClick={handleBackToCondition}
        />
        <Spacing size="2x-large" />
        <Title
          characterCount={characterCount}
          handleTitleChange={handleTitleChange}
          title={currentQuestionCondition.title}
          titleMaxCharacter={titleMaxCharacter}
        />
        <Spacing size="2x-large" />
        <div className="c-action__item h-flex">
          <Label additionalClassNames="c-action__item__label h-flex" forId="question" labelText="Question">
            <Tooltip placement="left" text="Question name" />
          </Label>
          <span>{conditionQuestion}</span>
        </div>
        <div>
          {conditionQuestionLocations.length ? (
            <>
              <Spacing size="2x-large" />
              <div className="c-action__item c-action__item__slider h-flex">
                <div className="c-action__item__slider--item">
                  <Label additionalClassNames="c-action__item__label h-flex" forId="slider-min" labelText="Min value" isRequired>
                    <Tooltip placement="top" text="The max value of slider" />
                  </Label>
                  <Input id="slider-min" type="number" value={currentQuestionCondition.minValue} onChange={handleMinValueChange} />
                </div>
                <div className="c-action__item__slider--item">
                  <Label additionalClassNames="c-action__item__label h-flex" forId="slider-max" labelText="Max value" isRequired>
                    <Tooltip placement="left" text="The min value of slider" />
                  </Label>
                  <Input id="slider-max" type="number" value={currentQuestionCondition.maxValue} onChange={handleMaxValueChange} />
                </div>
              </div>
              <Spacing size="2x-large" />
              <div className="c-action__item h-flex">
                <Label additionalClassNames="c-action__item__label h-flex" forId="question location" labelText="Question location">
                  <Tooltip placement="left" text="This action will execute if the radio selection is as selected below" />
                </Label>
                <RadioButtonGroup
                  items={conditionQuestionLocations}
                  name="location"
                  selectedValue={currentQuestionCondition.contentPageId.toString()}
                  onChange={handleQuestionLocationChange}
                />
              </div>
            </>
          ) : null}
          <Spacing size="2x-large" />
          <div className="c-action__item h-flex">
            <Label additionalClassNames="c-action__item__label h-flex" forId="template" labelText="Template">
              <Tooltip placement="left" text="Save condition for reuse later" />
            </Label>
            <div className="c-action__item__checkbox h-flex">
              <Checkbox
                id="isTemplate"
                isChecked={isTemplateChecked}
                labelText="Save conditional configuration for reuse"
                name="isTemplate"
                onChange={handleIsTemplateChecked}
              />
            </div>
          </div>
        </div>
        <FormButtons
          additionalClassNames="c-form-buttons__action-tab"
          isSaveButtonDisabled={isSaveButtonDisabled}
          onClose={onCloseSidebar}
          onDelete={handleClickRemoveConditionButton}
        />
      </div>

      <Dialog
        isOpen={isDeleteDialogOpen}
        title={`Delete condition: ${currentQuestionCondition.title}?`}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteQuestionCondition}
      />
    </Form>
  );
};

QuestionConditionSliderEdit.propTypes = {
  onClose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  onUpdateConditionCompleted: PropTypes.func,
  questionAction: QUESTION_ACTION_PROP_TYPE,
  questionCondition: PropTypes.shape({
    contentPageId: PropTypes.number,
    contentSnippetQuestionId: PropTypes.number,
    id: PropTypes.number,
    isTemplate: PropTypes.bool,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    title: PropTypes.string,
  }),
  titleMaxCharacter: PropTypes.number,
};

export { QuestionConditionSliderEdit };
