import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import TranscriptInputTab from './TranscriptInputTab';
import { inputTypeMap } from './transcriptInputTypes';
import TranscriptListTab from './TranscriptListTab';

import { useContentPageState } from '../../../context/ContentPage/ContentPageContext';
import { ADD_BUBBLE_TEXT } from '../../../middleware/bubbleText/addBubbleText';
import { GET_SHAPE_TYPES } from '../../../middleware/bubbleText/getShapeTypes';
import { GET_TAIL_TYPES } from '../../../middleware/bubbleText/getTailTypes';
import { REMOVE_BUBBLE_TEXT } from '../../../middleware/bubbleText/removeBubbleText';
import { UPDATE_BUBBLE_TEXT } from '../../../middleware/bubbleText/updateBubbleText';
import client from '../../../middleware/client';
import { GET_CHARACTERS_BY_PROJECT } from '../../../middleware/ContentPage/getCharacterByProjectRequest';
import { getEnumValueDropdown } from '../../../utils';

const TranscriptTab = ({ bubbleTexts, updateRequest, assetId, projectId }) => {
  const [pageState, dispatch] = useContentPageState();
  const { isNewPage } = pageState;
  const [transcriptText, setTranscriptText] = useState('');
  const [angle, setAngle] = useState(0.0);
  const [xAxis, setXAxis] = useState(0);
  const [yAxis, setYAxis] = useState(0);
  const [width, setWidth] = useState(0);
  const [shape, setShape] = useState(undefined);
  const [tailType, setTailType] = useState(undefined);
  const [characterId, setCharacterId] = useState(null);

  const [bubbleTextId, setBubbleTextId] = useState(null);

  const [characters, setCharacters] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [tails, setTails] = useState([]);

  const [isShowTranscriptListTab, setIsShowTranscriptListTab] = useState(true);
  const [createBubbleTextRequest, setCreateBubbleTextRequest] = useState(false);
  const [updateBubbleTextRequest, setUpdateBubbleTextRequest] = useState(false);
  const [getCharacters, { called: isGetCharactersCalled }] = useLazyQuery(GET_CHARACTERS_BY_PROJECT, {
    client,
    fetchPolicy: 'cache-and-network',
    variables: {
      projectId,
    },
    onCompleted(data) {
      const {
        character: { getByProject: allCharacters },
      } = data;

      const characterDropdownList = allCharacters.map((value) => {
        const { id, name } = value;
        return {
          id,
          value: id,
          label: name,
        };
      });

      setCharacters(() => characterDropdownList.sort((a, b) => a.label.localeCompare(b.label)));
    },
  });

  const [getShapes, { called: isGetShapesCalled }] = useLazyQuery(GET_SHAPE_TYPES, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        __type: { enumValues: shapeValues },
      } = data;

      setShapes(getEnumValueDropdown(shapeValues));
    },
  });

  const [getTailTypes, { called: isGetTailTypesCalled }] = useLazyQuery(GET_TAIL_TYPES, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        __type: { enumValues: tailValues },
      } = data;

      setTails(getEnumValueDropdown(tailValues));
    },
  });

  const [addBubbleText] = useMutation(ADD_BUBBLE_TEXT, {
    client,
    onCompleted(response) {
      dispatch({
        type: 'addContentPageBubbleText',
        newState: response.bubbleText.add,
      });
      dispatch({
        type: 'setIsNewPage',
        newState: false,
      });
      setCreateBubbleTextRequest(false);
    },
  });

  const [updateBubbleText] = useMutation(UPDATE_BUBBLE_TEXT, {
    client,
    onCompleted(response) {
      const {
        bubbleText: {
          update: { character },
        },
      } = response;
      dispatch({
        type: 'updateContentPageBubbleText',
        newState: response.bubbleText.update,
      });
      if (!character) {
        return;
      }
      setCharacterId(character.id);
    },
  });

  const [removeBubbleText] = useMutation(REMOVE_BUBBLE_TEXT, {
    client,
    onCompleted() {
      dispatch({
        type: 'deleteContentPageBubbleText',
        newState: bubbleTextId,
      });
    },
  });

  const handleBubbleTextRequest = () => {
    if (updateBubbleTextRequest) {
      updateBubbleText({
        variables: {
          bubbleTextId,
          text: transcriptText,
          angle: Number(angle).toFixed(1),
          xAxis: Number(xAxis),
          yAxis: Number(yAxis),
          width: Number(width),
          shape,
          tailType,
          characterId: characterId ? Number(characterId) : null,
          assetId,
        },
      });
    } else if (createBubbleTextRequest) {
      addBubbleText({
        variables: {
          text: transcriptText,
          angle: Number(angle).toFixed(1),
          xAxis: Number(xAxis),
          yAxis: Number(yAxis),
          width: Number(width),
          shape,
          tailType,
          assetId,
        },
      });
    }
  };

  const handleEditTranscript = (id) => {
    const activeTranscript = bubbleTexts.find((item) => item.id === id);

    const { angle, id: bubbleTextId, character, shape, tailType, text, w, x, y } = activeTranscript;
    const { id: characterId } = character || {};

    setUpdateBubbleTextRequest(true);
    setCreateBubbleTextRequest(false);
    setBubbleTextId(bubbleTextId);
    setIsShowTranscriptListTab(!isShowTranscriptListTab);
    setTranscriptText(text);
    setAngle(angle);
    setXAxis(x);
    setYAxis(y);
    setWidth(w);
    setShape(shape);
    setTailType(tailType);
    setCharacterId(characterId);
  };

  const handleCreateTranscript = () => {
    setCreateBubbleTextRequest(true);
    setUpdateBubbleTextRequest(false);
    setTranscriptText('');
    setAngle(0.0);
    setXAxis(0);
    setYAxis(0);
    setWidth(0);
    setShape('OVAL');
    setTailType('DEFAULT');
    setCharacterId(null);
    setIsShowTranscriptListTab(false);
  };

  const handleDeleteTranscript = () => {
    removeBubbleText({
      variables: {
        id: bubbleTextId,
      },
    });

    setIsShowTranscriptListTab(!isShowTranscriptListTab);
  };

  const transcriptConfig = [
    {
      inputType: 'text',
      isChanged: (value) => setTranscriptText(value),
      text: transcriptText,
      title: 'Transcript Text',
      tooltip: 'Transcript text',
    },
    {
      inputType: 'input',
      isChanged: (value) => setAngle(value),
      text: angle,
      title: 'Angle',
      tooltip: 'Angle',
      type: 'number',
    },
    {
      inputType: 'input',
      isChanged: (value) => setXAxis(value),
      text: xAxis,
      title: 'X axis / %',
      tooltip: 'X Axis',
      type: 'number',
    },
    {
      inputType: 'input',
      isChanged: (value) => setYAxis(value),
      text: yAxis,
      title: 'Y axis / %',
      tooltip: 'Y Axis',
      type: 'number',
    },
    {
      inputType: 'input',
      isChanged: (value) => setWidth(value),
      text: width,
      title: 'Width / %',
      tooltip: 'Width',
      type: 'number',
    },
    {
      availableResponses: shapes,
      inputType: 'dropdown',
      onChangeHandler: (_, value) => setShape(value),
      selectedValue: shape,
      title: 'Shape',
      tooltip: 'Shape of the transcript bubble',
    },
    {
      availableResponses: tails,
      inputType: 'dropdown',
      onChangeHandler: (_, value) => setTailType(value),
      selectedValue: tailType,
      title: 'Tail',
      tooltip: 'Speech bubble tail type',
    },
    {
      availableResponses: characters,
      inputType: 'dropdown',
      onChangeHandler: (_, value) => setCharacterId(value),
      selectedValue: characterId,
      title: 'Character',
      tooltip: 'Transcripts associated character',
    },
  ];

  useEffect(() => {
    handleBubbleTextRequest();
  }, [updateRequest]);

  useEffect(() => {
    if (isGetCharactersCalled) {
      return;
    }

    getCharacters();
  }, [isGetCharactersCalled]);

  useEffect(() => {
    if (isGetShapesCalled) {
      return;
    }

    getShapes();
  }, [isGetShapesCalled]);

  useEffect(() => {
    if (isGetTailTypesCalled) {
      return;
    }

    getTailTypes();
  }, [isGetTailTypesCalled]);

  useEffect(() => {
    if (isNewPage) {
      handleCreateTranscript();
    }
  }, [isNewPage]);

  return (
    <>
      {isShowTranscriptListTab ? (
        <TranscriptListTab
          handleCreateTranscript={handleCreateTranscript}
          handleEditTranscript={(id) => handleEditTranscript(id)}
          transcriptList={bubbleTexts}
        />
      ) : (
        <TranscriptInputTab
          createBubbleTextRequest={createBubbleTextRequest}
          handleBubbleTextRequest={handleBubbleTextRequest}
          handleDeleteTranscript={handleDeleteTranscript}
          inputTypeMap={inputTypeMap}
          isShowTranscriptListTab={isShowTranscriptListTab}
          setIsShowTranscriptListTab={(value) => setIsShowTranscriptListTab(value)}
          transcriptConfig={transcriptConfig}
          updateBubbleTextRequest={updateBubbleTextRequest}
        />
      )}
    </>
  );
};

export default TranscriptTab;
