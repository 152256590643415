import React from 'react';

import { Layout, LayoutItem, Spacing } from '@netfront/ui-library';
import sortBy from 'lodash.sortby';

import { CMSContainer } from '../CMSContainer';
import { CMSSection } from '../CMSSection';
import { Snippet } from '../Snippet';

import { CMSSectionGroupProps } from './CMSSectionGroup.interfaces';

import { getSectionGroup, useContentPageContext } from '../../contexts';

const CMSSectionGroup = ({ accessToken, contentSections = [], sectionGroupId, userFlowStepTrackId }: CMSSectionGroupProps) => {
  const { state: contentPage } = useContentPageContext();

  const { contentSections: sectionGroupContentSections, visible: isVisible = true } = getSectionGroup(sectionGroupId, contentPage) ?? {};

  const actualContentSections = contentSections.length ? contentSections : sectionGroupContentSections;
  const sortedContentSections = sortBy(actualContentSections, 'sort');

  return (
    <React.Fragment>
      {isVisible &&
        sortedContentSections.map(({ contentLayoutId, id: contentSectionId, sectionContainers: contentSectionContainers }) => {
          const sortedContentSectionContainers = sortBy(contentSectionContainers, 'sort');

          return (
            <Spacing key={`section-${contentSectionId}`}>
              <CMSSection contentSectionId={contentSectionId}>
                <Layout gap="large" layoutId={contentLayoutId}>
                  {sortedContentSectionContainers.map(({ id: contentSectionContainerId, snippets }) => {
                    const sortedContentSnippets = sortBy(snippets, 'sort');

                    return (
                      <LayoutItem key={`layout-${contentSectionContainerId}`}>
                        <CMSContainer contentSectionContainerId={contentSectionContainerId}>
                          {sortedContentSnippets.map(({ id: contentSnippetId }) => {
                            return (
                              <Snippet
                                key={`content-snippet-${contentSnippetId}`}
                                accessToken={accessToken}
                                contentSnippetId={contentSnippetId}
                                userFlowStepTrackId={userFlowStepTrackId}
                              />
                            );
                          })}
                        </CMSContainer>
                      </LayoutItem>
                    );
                  })}
                </Layout>
              </CMSSection>
            </Spacing>
          );
        })}
    </React.Fragment>
  );
};

export { CMSSectionGroup };
