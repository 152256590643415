import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_APP_SNIPPET_DETAILS = gql`
  query getAppSnippetDetailRequest($id: Int!) {
    contentSnippet {
      app(id: $id) {
        appComponentId
        app {
          description
          id
          title
          files {
            appComponentId
            fileType
            id
            locationId
          }
        }
      }
    }
  }
`;

const getAppSnippetDetailRequest = ({ id, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_APP_SNIPPET_DETAILS}
    variables={{ id }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.contentSnippet.app.app
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAppSnippetDetailRequest.propTypes = {
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAppSnippetDetailRequest;
