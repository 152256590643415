import { createContext } from 'react';

const AppContext = createContext({
  app: {},
  contentGroup: {},
  hasSubscriptions: false,
  isEditMode: '', // TODO: May need to be removed
  isLoading: false,
  org: {},
  project: {},
  projects: undefined,
  showRightSidebar: [false, '', null], // [showSidebar, type, id]
  handleShowSidebar: () => {},
  setIsLoading: () => {},
  toggleSubscriptions: () => {},
  updateApp: () => {},
  updateContentGroup: () => {},
  updateOrg: () => {},
  updateProject: () => {},
  updateProjects: () => {},
  updateIsEditMode: () => {},
});

export default AppContext;
