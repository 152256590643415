import React, { MutableRefObject, useEffect, useState, useRef } from 'react';

import cx from 'classnames';

import { PlayButtonProps } from './PlayButton.interfaces';

import { Icon } from '../../Icon';

import './PlayButton.css';

const PlayButton = ({ additionalClassNames, audioSrc, size = 'x-small' }: PlayButtonProps) => {
  const audioRef = useRef() as MutableRefObject<HTMLAudioElement>;
  const [isAudioPlayEnabled, setIsAudioPlayEnabled] = useState(false);

  const playButtonIconClassNames = cx('c-play-button__icon', {
    'c-play-button__play-icon': !isAudioPlayEnabled,
    'c-play-button__pause-icon': isAudioPlayEnabled,
  });

  const handleClickAudioPlay = () => {
    setIsAudioPlayEnabled(!isAudioPlayEnabled);
  };

  useEffect(() => {
    const { readyState: audioReadyState } = audioRef.current;

    if (!audioReadyState) {
      return;
    }

    if (audioReadyState < 4) {
      return;
    }

    if (isAudioPlayEnabled) {
      audioRef.current.play().catch((error) => {
        throw error;
      });
    } else {
      audioRef.current.pause();
    }
  }, [isAudioPlayEnabled, audioRef]);

  return (
    <React.Fragment>
      <button
        className={cx('c-play-button', additionalClassNames, {
          [`c-play-button--${size}`]: size,
        })}
        data-testid="qa-play-button"
        type="button"
        onClick={handleClickAudioPlay}
      >
        <span className="h-hide-visually">{isAudioPlayEnabled ? 'Pause audio' : 'Play audio'}</span>
        <Icon className={playButtonIconClassNames} id={isAudioPlayEnabled ? 'id_pause_icon' : 'id_play_icon'} />
      </button>
      <audio ref={audioRef} aria-label="audio" className="h-hide-visually" controls onEnded={handleClickAudioPlay}>
        <track kind="captions" />
        <source src={audioSrc} type="audio/mpeg" />
      </audio>
    </React.Fragment>
  );
};

export { PlayButton };
