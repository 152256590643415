import React from 'react';

import cx from 'classnames';

import { ButtonProps } from './Button.types';

import { Icon } from '../../Icon';

import './Button.css';

const Button = ({
  additionalClassNames,
  backgroundColor,
  borderColor,
  iconId,
  isDisabled = false,
  linkButton,
  onClick,
  text,
  type = 'button',
  variant = 'primary',
}: ButtonProps) => {
  const ButtonComponent = linkButton ? linkButton.linkComponent ?? 'a' : 'button';

  const buttonClassNames = cx('c-button', additionalClassNames, {
    [`c-button--${variant}`]: Boolean(variant),
  });

  return (
    <ButtonComponent
      className={buttonClassNames}
      data-testid="qa-button"
      disabled={isDisabled}
      href={linkButton?.url}
      id={linkButton?.id}
      rel={linkButton?.url.indexOf('http') === 0 ? 'noopener noreferrer' : undefined}
      style={{
        backgroundColor /* stylelint-disable-line value-keyword-case */,
        borderColor /* stylelint-disable-line value-keyword-case */,
      }}
      target={linkButton?.target}
      type={type}
      onClick={onClick}
    >
      <span className="c-button__text">
        {text} {iconId ? <Icon className="c-button__icon" id={iconId} /> : null}
      </span>
    </ButtonComponent>
  );
};

export { Button };
