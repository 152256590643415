import gql from 'graphql-tag';

export const GET_QUESTION = gql`
  query getQuestion($label: String!, $projectId: String!) {
    contentSnippet {
      getQuestionsWithLabel(label: $label, projectId: $projectId) {
        question
        contentPage {
          contentGroupId
        }
        id
        configuration {
          id
        }
      }
    }
  }
`;

export const CREATE_ACTION_SHOW_SECTION = gql`
  mutation questionActionShowSection($contentPageId: Int!, $wrapperId: Int!) {
    questionAction {
      addAction(actionShowSection: { contentPageId: $contentPageId, sectionId: $wrapperId }) {
        id
        ... on QuestionActionShowSectionType {
          sectionId
        }
      }
    }
  }
`;

export const CREATE_ACTION_HIDE_SECTION = gql`
  mutation questionActionHideSection($contentPageId: Int!, $wrapperId: Int!) {
    questionAction {
      addAction(actionHideSection: { contentPageId: $contentPageId, sectionId: $wrapperId }) {
        id
        ... on QuestionActionHideSectionType {
          sectionId
        }
      }
    }
  }
`;

export const CREATE_ACTION_SHOW_CONTAINER = gql`
  mutation questionActionShowContainer($contentPageId: Int!, $wrapperId: Int!) {
    questionAction {
      addAction(actionShowContainer: { contentPageId: $contentPageId, containerId: $wrapperId }) {
        id
        ... on QuestionActionShowContainerType {
          containerId
        }
      }
    }
  }
`;

export const CREATE_ACTION_HIDE_CONTAINER = gql`
  mutation questionActionHideContainer($contentPageId: Int!, $wrapperId: Int!) {
    questionAction {
      addAction(actionHideContainer: { contentPageId: $contentPageId, containerId: $wrapperId }) {
        id
        ... on QuestionActionHideContainerType {
          containerId
        }
      }
    }
  }
`;

export const CREATE_ACTION_SHOW_SNIPPET = gql`
  mutation questionActionShowTargetSnippet($contentPageId: Int!, $wrapperId: Int!) {
    questionAction {
      addAction(actionShow: { contentPageId: $contentPageId, targetSnippetId: $wrapperId }) {
        id
        ... on QuestionActionShowType {
          targetSnippetId
        }
      }
    }
  }
`;

export const CREATE_ACTION_HIDE_SNIPPET = gql`
  mutation questionActionHideTargetSnippet($contentPageId: Int!, $wrapperId: Int!) {
    questionAction {
      addAction(actionHide: { contentPageId: $contentPageId, targetSnippetId: $wrapperId }) {
        id
        ... on QuestionActionHideType {
          targetSnippetId
        }
      }
    }
  }
`;
