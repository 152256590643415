import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';

import { Title, ChooseFromLibraryButton } from '../styled';

import AudioComponent from './AudioComponent';

import { useContentPageState } from '../../../context/ContentPage/ContentPageContext';
import { CREATE_ASSET } from '../../../middleware/Asset/createAssetRequest';
import { DELETE_ASSET } from '../../../middleware/Asset/deleteAssetRequest';
import client from '../../../middleware/client';
import { ADD_CONTENT_PAGE_AUDIO } from '../../../middleware/ContentPage/addContentPageAudio';
import { createAudioFileString } from '../../../utils';
import LibraryAssets from '../../ContentSnippet/LibraryAssets';

const AudioTab = ({ projectId, pageId }) => {
  const [pageState] = useContentPageState();
  const { contentPage } = pageState;

  const { audio } = contentPage;
  const { s3Key, presignedUrl, assetId } = audio || {};

  const [hasAudioData, setHasAudioData] = useState(Boolean(audio));
  const [droppedFile, setDroppedFile] = useState('');
  const [fileName, setDroppedFileName] = useState('');
  const [assetIdState, setAssetIdState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLibrary, setIsOpenLibrary] = useState(false);

  const [addContentPageAudio] = useMutation(ADD_CONTENT_PAGE_AUDIO, {
    client,
  });

  const [createAsset] = useMutation(CREATE_ASSET, {
    client,
  });
  const [deleteAsset] = useMutation(DELETE_ASSET, {
    client,
  });

  const handleFileDrop = (files) => {
    setDroppedFile(files[0]);
  };

  const handleSaveFile = () => {
    setIsLoading(true);
    createAsset({
      variables: {
        projectId,
        type: 'AUDIO',
        alt: 'asset',
        fileName: droppedFile.name,
        contentType: droppedFile.type,
        fileSizeInBytes: droppedFile.size,
      },
    }).then((res) => {
      const { signedUrl } = res.data.asset.createAsset;
      const { assetId } = res.data.asset.createAsset.asset;
      axios.put(signedUrl, droppedFile, { headers: {} });
      addContentPageAudio({
        variables: {
          contentPageId: pageId,
          assetId,
        },
      });
      setAssetIdState(assetId);
      setDroppedFileName(createAudioFileString(res.data.asset.createAsset.asset.s3Key));
      setHasAudioData(true);
      setIsLoading(false);
    });
  };

  const handleSelectAsset = (asset) => {
    setIsLoading(true);
    const selectedAssetId = asset.assetId;
    addContentPageAudio({
      variables: {
        contentPageId: pageId,
        assetId: selectedAssetId,
      },
    });
    setHasAudioData(true);
    setIsLoading(false);
    setIsOpenLibrary(false);
    setAssetIdState(selectedAssetId);
    setDroppedFileName(createAudioFileString(asset.s3Key));
  };

  const handleDeleteAsset = () => {
    setIsLoading(true);
    deleteAsset({
      variables: {
        projectId,
        assetId: assetIdState,
      },
    }).then((res) => res.data.asset.deleteAsset && setHasAudioData(false), setIsLoading(false));
  };

  const handleOpenLibrary = () => {
    setIsOpenLibrary(true);
  };

  const handleCloseLibrary = () => {
    setIsOpenLibrary(false);
  };

  useEffect(() => {
    if (!audio) {
      return;
    }
    setHasAudioData(true);
  }, [audio]);

  useEffect(() => {
    if (!s3Key) {
      return;
    }
    setAssetIdState(assetId);
    setDroppedFileName(createAudioFileString(s3Key));
  }, [s3Key, assetId]);

  useEffect(() => {
    if (droppedFile) {
      handleSaveFile();
    }
  }, [droppedFile]);

  const chooseFromLibraryButton = {
    onClickHandler: isOpenLibrary ? handleCloseLibrary : handleOpenLibrary,
    text: isOpenLibrary ? 'Go back' : 'Choose from library',
  };

  return (
    <>
      <Title margin="0 0 2rem 1rem">Audio tab</Title>
      {isOpenLibrary ? (
        <>
          <LibraryAssets
            display="flex"
            goBack={() => setIsOpenLibrary(false)}
            isClicked={handleSelectAsset}
            justifyContent="center"
            type="AUDIO"
          />
          <ChooseFromLibraryButton className="button--green" onClick={chooseFromLibraryButton.onClickHandler}>
            {chooseFromLibraryButton.text}
          </ChooseFromLibraryButton>
        </>
      ) : (
        <AudioComponent
          fileName={fileName}
          handleCloseLibrary={handleCloseLibrary}
          handleDeleteAsset={handleDeleteAsset}
          handleFileDrop={(file) => handleFileDrop(file)}
          handleOpenLibrary={handleOpenLibrary}
          hasAudioData={hasAudioData}
          isLoading={isLoading}
          isOpenLibrary={isOpenLibrary}
          presignedUrl={presignedUrl}
          setHasAudioData={(value) => setHasAudioData(value)}
          setIsOpenLibrary={(value) => setIsOpenLibrary(value)}
        />
      )}
    </>
  );
};

export default AudioTab;
