import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from 'react-apollo';
import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_MEMBERSHIP_DETAILS = gql`
  mutation updatePublicUser($membershipId: Int!, $groupId: Int, $userTypeId: Int) {
    membership {
      updateGroupMembership(request: { membershipId: $membershipId, groupId: $groupId, userTypeId: $userTypeId }) {
        id
        user {
          id
          communityName
          firstname
          lastname
          status
          credential {
            email
            services
          }
        }
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;

const updatePublicUserDetail = ({ membershipId, groupId, userTypeId, onError, onSuccessResult }) => (
  <Mutation client={Client()} mutation={UPDATE_MEMBERSHIP_DETAILS}>
    {(updatePublicUser, { loading, error, data }) => {
      const variables = {
        membershipId,
        groupId,
        userTypeId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.membership.updateGroupMembership);
      }

      return <MutationRequest isLoading={loading} mutation={updatePublicUser} variables={variables} />;
    }}
  </Mutation>
);

updatePublicUserDetail.propTypes = {
  groupId: PropTypes.number.isRequired,
  membershipId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  userTypeId: PropTypes.number,
};

updatePublicUserDetail.defaultProps = {};

export default updatePublicUserDetail;
