/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-len */
import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

/* TODO check with cyrille about fields such as description to be switched to not required */
export const updateImageMapMutation = gql`
  mutation updateImageMap(
    $imageMapId: Int!
    $title: String!
    $iconAssetId: String!
  ) {
    contentGroup {
      updateContentGroupGeneralInformation(
        contentGroupId: $imageMapId
        title: $title
        assetId: $iconAssetId
      )
    }
  }
`;

const updateImageMap = ({
  imageMapId,
  title,
  iconAssetId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={updateImageMapMutation}>
    {(updateImageMapFunc, { loading, error, data }) => {
      const variables = {
        imageMapId,
        iconAssetId,
        title,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.imageMap.updateImageMap.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateImageMapFunc}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateImageMap.propTypes = {
  iconAssetId: PropTypes.string.isRequired,
  imageMapId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default updateImageMap;
