import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_SLIDER = gql`
  query getSlider($sliderId: Int!) {
    slider {
      getSlider(sliderId: $sliderId) {
        id
        title
        arrows
        autoplay
        autoplaySpeed
        animation
        dots
        infinite
        slidesToScroll
        slidesToShow
        speed
      }
    }
  }
`;

const getSlider = ({ sliderId, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_SLIDER} variables={{ sliderId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={Object.entries(data).length !== 0 && data.slider.getSlider}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);
getSlider.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sliderId: PropTypes.number.isRequired,
};

export default getSlider;
