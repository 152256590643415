import React, { useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Tooltip, { WithTooltipWrapper } from '../UI/Tooltip/Tooltips';

import EditableAppSnippet from './EditableAppSnippet';
import EditableAudioSnippet from './EditableAudioSnippet';
import EditableButtonSnippet from './EditableButtonSnippet';
import EditableCodeSnippet from './EditableCodeSnippet';
import EditableDocumentSnippet from './EditableDocumentSnippet';
import EditableEmbedSnippet from './EditableEmbedSnippet';
import EditableImageSnippet from './EditableImageSnippet';
import EditableMapSnippet from './EditableMapSnippet';
import EditableQuestionSnippet from './EditableQuestionSnippet';
import EditableQuoteSnippet from './EditableQuoteSnippet';
import EditableSliderSnippet from './EditableSliderSnippet';
import EditableSpacerSnippet from './EditableSpacerSnippet';
import EditableSummarySnippet from './EditableSummarySnippet';
import EditableVideoSnippet from './EditableVideoSnippet';
import EditableFormSnippet from './FormSnippet';
import EditableImageMapSnippet from './ImageMapSnippet';
import { SnippetTypeConst } from './SnippetTypeConst';
import EditableTextSnippet from './TextSnippet';
import EditableAvatarSnippet from './EditableAvatarSnippet';

import { getAssetsUrl } from '../../config';
import AppIcon from '../../static/img/icon-marketplace.svg';
import SliderIcon from '../../static/img/slider.svg';
import SpacerIcon from '../../static/img/spacer.svg';

const typeTitleMap = {
  [SnippetTypeConst.APP]: 'App',
  [SnippetTypeConst.AUDIO]: 'Audio',
  [SnippetTypeConst.BUTTON]: 'Button link',
  [SnippetTypeConst.CODE]: 'Code',
  [SnippetTypeConst.DOCUMENT]: 'File',
  [SnippetTypeConst.EMBED]: 'Embed',
  [SnippetTypeConst.FORM]: 'Form',
  [SnippetTypeConst.IMAGE]: 'Image',
  [SnippetTypeConst.IMAGE_MAP]: 'Interactive regions',
  [SnippetTypeConst.MAP]: 'Map',
  [SnippetTypeConst.QUESTION]: 'Question',
  [SnippetTypeConst.QUOTE]: 'Quote',
  [SnippetTypeConst.SLIDER]: 'Slider',
  [SnippetTypeConst.SPACER]: 'Spacer',
  [SnippetTypeConst.SUMMARY]: 'Summary',
  [SnippetTypeConst.TABLE]: 'Table',
  [SnippetTypeConst.TEXT]: 'Text',
  [SnippetTypeConst.VIDEO]: 'Video',
  [SnippetTypeConst.AVATAR]: 'Avatar',
};

const typeTitlesShortMap = {
  ...typeTitleMap,
  [SnippetTypeConst.IMAGE_MAP]: 'Regions',
};

const typeDescriptionMap = {
  [SnippetTypeConst.APP]: 'Add an app snippet',
  [SnippetTypeConst.AUDIO]: 'Add an audio file snippet',
  [SnippetTypeConst.BUTTON]: 'Add a button link snippet',
  [SnippetTypeConst.CODE]: 'Add code-based files snippet',
  [SnippetTypeConst.DOCUMENT]: 'Add a file snippet',
  [SnippetTypeConst.EMBED]: 'Add embed code snippet',
  [SnippetTypeConst.FORM]: 'Add a form snippet',
  [SnippetTypeConst.IMAGE]: 'Add an image file snippet',
  [SnippetTypeConst.IMAGE_MAP]: 'Add an interactive regions snippet',
  [SnippetTypeConst.MAP]: 'Add a map snippet',
  [SnippetTypeConst.QUESTION]: 'Add a question snippet',
  [SnippetTypeConst.QUOTE]: 'Add a quote snippet',
  [SnippetTypeConst.SLIDER]: 'Add a slider snippet',
  [SnippetTypeConst.SPACER]: 'Add a spacer snippet',
  [SnippetTypeConst.SUMMARY]: 'Add a summary snippet',
  [SnippetTypeConst.TABLE]: 'Add a table snippet',
  [SnippetTypeConst.TEXT]: 'Add text content',
  [SnippetTypeConst.VIDEO]: 'Add a video file snippet',
  [SnippetTypeConst.AVATAR]: 'Add a avatar snippet',
};

const typeIconMap = {
  [SnippetTypeConst.APP]: AppIcon,
  [SnippetTypeConst.AUDIO]: `${getAssetsUrl()}/icons/content-snippet/audio.svg`,
  [SnippetTypeConst.BUTTON]: `${getAssetsUrl()}/icons/content-snippet/button.svg`,
  [SnippetTypeConst.CODE]: `${getAssetsUrl()}/icons/content-snippet/code.svg`,
  [SnippetTypeConst.DOCUMENT]: `${getAssetsUrl()}/icons/content-snippet/personalise.svg`,
  [SnippetTypeConst.EMBED]: `${getAssetsUrl()}/icons/content-snippet/personalise.svg`,
  [SnippetTypeConst.FORM]: `${getAssetsUrl()}/icons/content-snippet/form.svg`,
  [SnippetTypeConst.IMAGE]: `${getAssetsUrl()}/icons/content-snippet/image.svg`,
  [SnippetTypeConst.IMAGE_MAP]: `${getAssetsUrl()}/icons/content-snippet/image.svg`,
  [SnippetTypeConst.MAP]: `${getAssetsUrl()}/icons/content-snippet/map.svg`,
  [SnippetTypeConst.QUESTION]: `${getAssetsUrl()}/icons/content-snippet/form.svg`,
  [SnippetTypeConst.QUOTE]: `${getAssetsUrl()}/icons/content-snippet/quote.svg`,
  [SnippetTypeConst.SLIDER]: SliderIcon,
  [SnippetTypeConst.SPACER]: SpacerIcon,
  [SnippetTypeConst.SUMMARY]: `${getAssetsUrl()}/icons/content-snippet/image.svg`,
  [SnippetTypeConst.TABLE]: `${getAssetsUrl()}/icons/content-snippet/table.svg`,
  [SnippetTypeConst.TEXT]: `${getAssetsUrl()}/icons/content-snippet/text.svg`,
  [SnippetTypeConst.VIDEO]: `${getAssetsUrl()}/icons/content-snippet/video.svg`,
  [SnippetTypeConst.AVATAR]: `${getAssetsUrl()}/icons/content-snippet/video.svg`,
};

const editableSnippetMap = {
  [SnippetTypeConst.APP]: EditableAppSnippet,
  [SnippetTypeConst.AUDIO]: EditableAudioSnippet,
  [SnippetTypeConst.BUTTON]: EditableButtonSnippet,
  [SnippetTypeConst.CODE]: EditableCodeSnippet,
  [SnippetTypeConst.DOCUMENT]: EditableDocumentSnippet,
  [SnippetTypeConst.EMBED]: EditableEmbedSnippet,
  [SnippetTypeConst.FORM]: EditableFormSnippet,
  [SnippetTypeConst.IMAGE]: EditableImageSnippet,
  [SnippetTypeConst.IMAGE_MAP]: EditableImageMapSnippet,
  [SnippetTypeConst.MAP]: EditableMapSnippet,
  [SnippetTypeConst.QUESTION]: EditableQuestionSnippet,
  [SnippetTypeConst.QUOTE]: EditableQuoteSnippet,
  [SnippetTypeConst.SLIDER]: EditableSliderSnippet,
  [SnippetTypeConst.SPACER]: EditableSpacerSnippet,
  [SnippetTypeConst.SUMMARY]: EditableSummarySnippet,
  [SnippetTypeConst.TEXT]: EditableTextSnippet,
  [SnippetTypeConst.VIDEO]: EditableVideoSnippet,
  [SnippetTypeConst.AVATAR]: EditableAvatarSnippet,
};

function SnippetSelector({ onClick, isActive, iconSrc, title }) {
  return (
    <li style={{ display: 'inline' }}>
      <button onClick={onClick} style={{ width: '12px' }} type="button">
        <span
          className={classnames('c-icon-holder', {
            'background-blue': isActive,
          })}
        >
          <img alt="edit" height="12px" src={iconSrc} width="12px" />
        </span>
        {title}
      </button>
    </li>
  );
}

SnippetSelector.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const formSnippets = [
  SnippetTypeConst.AUDIO,
  SnippetTypeConst.IMAGE,
  SnippetTypeConst.QUESTION,
  SnippetTypeConst.TEXT,
  SnippetTypeConst.VIDEO,
  SnippetTypeConst.SUMMARY,
];

const contentPageSnippets = [
  SnippetTypeConst.TEXT,
  SnippetTypeConst.IMAGE,
  SnippetTypeConst.VIDEO,
  SnippetTypeConst.AUDIO,
  SnippetTypeConst.BUTTON,
  SnippetTypeConst.QUOTE,
  SnippetTypeConst.CODE,
  SnippetTypeConst.MAP,
  SnippetTypeConst.FORM,
  SnippetTypeConst.APP,
  SnippetTypeConst.EMBED,
  SnippetTypeConst.DOCUMENT,
  SnippetTypeConst.IMAGE_MAP,
  SnippetTypeConst.SLIDER,
  SnippetTypeConst.SPACER,
  SnippetTypeConst.SUMMARY,
  SnippetTypeConst.AVATAR,
];

const imageMapPageSnippets = [
  SnippetTypeConst.TEXT,
  SnippetTypeConst.IMAGE,
  SnippetTypeConst.VIDEO,
  SnippetTypeConst.AUDIO,
  SnippetTypeConst.BUTTON,
  SnippetTypeConst.QUOTE,
  SnippetTypeConst.CODE,
  SnippetTypeConst.MAP,
  SnippetTypeConst.FORM,
  SnippetTypeConst.APP,
  SnippetTypeConst.EMBED,
  SnippetTypeConst.DOCUMENT,
  SnippetTypeConst.SLIDER,
  SnippetTypeConst.SPACER,
  SnippetTypeConst.SUMMARY,
  SnippetTypeConst.AVATAR,
];

const availableSnippets = {
  activity: contentPageSnippets,
  calendar: contentPageSnippets,
  cartoon: contentPageSnippets,
  consent: contentPageSnippets,
  blog: contentPageSnippets,
  events: contentPageSnippets,
  form: formSnippets,
  help: contentPageSnippets,
  learning: contentPageSnippets,
  news: contentPageSnippets,
  page: contentPageSnippets,
  questionnaire: contentPageSnippets,
  regions: imageMapPageSnippets,
  resource: contentPageSnippets,
};

export default function EditableSnippet({ containerId, onCancel, onCreate, onUpdate, onRefreshPage, snippet }) {
  const { type: contentType } = useParams();

  const [type, setType] = useState(snippet ? snippet.type : (availableSnippets[contentType] || [])[0]);

  const Editable = editableSnippetMap[type];

  return (
    <div className="row">
      {!snippet && (
        <div className="col-sm-4 content-snippet">
          <WithTooltipWrapper style={{ marginBottom: '1rem' }}>
            <h4>Type</h4>
            <Tooltip text="Select the type of content" />
          </WithTooltipWrapper>
          <ul className="content-snippet" style={{ listStyle: 'none' }}>
            {(availableSnippets[contentType] || []).map(snippetType => (
              <SnippetSelector
                iconSrc={typeIconMap[snippetType]}
                isActive={type === snippetType}
                key={snippetType}
                onClick={() => setType(snippetType)}
                title={typeTitlesShortMap[snippetType]}
              />
            ))}
          </ul>
        </div>
      )}
      <div className="col-sm-8" style={{ margin: '0 auto' }}>
        <WithTooltipWrapper style={{ marginBottom: '1rem' }}>
          <h4>{typeTitleMap[type]}</h4>
          <Tooltip text={typeDescriptionMap[type]} />
        </WithTooltipWrapper>

        <Editable
          containerId={containerId}
          onCancel={onCancel}
          onCreate={onCreate}
          onRefreshPage={onRefreshPage}
          onUpdate={onUpdate}
          snippet={snippet}
        />
      </div>
    </div>
  );
}

EditableSnippet.propTypes = {
  containerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  snippet: PropTypes.shape(),
};

EditableSnippet.defaultProps = {
  snippet: null,
};
