import gql from 'graphql-tag';

export const ATTACH_TO_SECTION = gql`
    mutation($sectionId: Int!, $styleId: Int!) {
        cssStyle {
            attachStyleToContentSection(contentSectionId: $sectionId, styleId: $styleId)
        }
    }
`;








