import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import MutationRequest from '../MutationRequest';

export const DELETE_CONTENT_EVENT = gql`
  mutation($contentEventId: Int!) {
    contentEvent {
      deleteContentEvent(contentEventId: $contentEventId)
    }
  }
`;

const deleteContentEventRequest = ({
  contentEventId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={DELETE_CONTENT_EVENT}>
    {(deleteContentEvent, { loading, error, data }) => {
      const variables = {
        contentEventId,
      };

      if (error) {
        if (error.networkError) {
          onError(error.networkError.message);
        } else {
          onError(error.message);
        }
      }
      if (data) {
        onSuccessResult(data.contentEvent.deleteContentEvent);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={deleteContentEvent}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

deleteContentEventRequest.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  contentEventId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default deleteContentEventRequest;
