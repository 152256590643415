import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_FILES_INTO_COMPONENT = gql`
  mutation createMyApp(
    $componentId: Int!
    $htmlLocationId: String!
    $jsLocationId: String!
    $cssLocationId: String!
  ) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          saveFilesIntoComponent(
            inputType: {
              componentId: $componentId
              files: [
                { locationId: $htmlLocationId, type: HTML }
                { locationId: $jsLocationId, type: JAVASCRIPT }
                { locationId: $cssLocationId, type: CSS }
              ]
            }
          )
        }
      }
    }
  }
`;

const createFilesIntoComponentRequest = ({
  componentId,
  htmlLocationId,
  jsLocationId,
  cssLocationId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_FILES_INTO_COMPONENT}>
    {(createMyApp, { loading, error, data }) => {
      const variables = {
        componentId,
        htmlLocationId,
        jsLocationId,
        cssLocationId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.submitApp);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createMyApp}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createFilesIntoComponentRequest.propTypes = {
  componentId: PropTypes.number.isRequired,
  cssLocationId: PropTypes.string.isRequired,
  htmlLocationId: PropTypes.string.isRequired,
  jsLocationId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

createFilesIntoComponentRequest.defaultProps = {};

export default createFilesIntoComponentRequest;
