import gql from 'graphql-tag';

export const ADD_CONTENT_GROUP_TO_GROUPING = gql`
  mutation addContentGroupIntoGroup($groupId: Int!, $contentGroupId: Int!) {
    contentGroup {
      addContentGroupIntoGroup(
        groupId: $groupId
        contentGroupId: $contentGroupId
      ) {
        id
        name
      }
    }
  }
`;
