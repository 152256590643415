import React, { Fragment, useEffect, useState } from 'react';

import { getS3SignedUrl } from '@netfront/common-library';
import to from 'await-to-js';

import { AudioSnippetProps } from './AudioSnippet.types';

import { ERROR_MESSAGES } from '../../../core';

import './AudioSnippet.css';

const AudioSnippet = ({ audio: { contentType, s3Key }, figureCaption }: AudioSnippetProps) => {
  const [audioUrl, setAudioUrl] = useState<string>();

  useEffect(() => {
    const loadAudioUrl = async () => {
      const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

      if (getS3SignedUrlError) {
        throw getS3SignedUrlError;
      }

      if (!s3SignedUrl) {
        throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
      }

      setAudioUrl(s3SignedUrl);
    };

    loadAudioUrl();
  }, [contentType, s3Key]);

  return (
    <Fragment>
      {audioUrl && (
        <div className="c-audio-snippet">
          <figure className="c-audio-snippet__figure">
            <audio className="c-audio-snippet__audio" controls>
              <source src={audioUrl} />
              <track kind="captions" srcLang="en" />
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            {figureCaption ? <figcaption className="c-audio-snippet__figure-caption">{figureCaption}</figcaption> : null}
          </figure>
        </div>
      )}
    </Fragment>
  );
};

export { AudioSnippet };
