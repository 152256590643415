import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

// TODO add name to mutation once it is made available by cyrille
export const updateImageMapRegionMutation = gql`
  mutation UpdateImageMapRegion(
    $regionId: Int!
    $x: Int!
    $y: Int!
    $name: String!
    $iconType: ERegionIconType!
    $size: ERegionIconSize
    $alternativeImageId: String
  ) {
    region {
      updateRegion(
        regionId: $regionId
        x: $x
        y: $y
        icon: $iconType
        name: $name
        size: $size
        alternativeImageId: $alternativeImageId
      )
    }
  }
`;

const updateImageMapRegion = ({
  regionId,
  name,
  x,
  y,
  alternativeImageId,
  iconType,
  size,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={updateImageMapRegionMutation}>
    {(updateImageMapRegionFunc, { loading, error, data }) => {
      const variables = {
        regionId,
        name,
        x,
        y,
        alternativeImageId,
        iconType,
        size,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.imageMap.createImageMapItem.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateImageMapRegionFunc}
          variables={variables}
        />
      );
    }}
  </Mutation>
);
updateImageMapRegion.propTypes = {
  iconType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  regionId: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  alternativeImageId: PropTypes.string,
};

updateImageMapRegion.defaultProps = {
  alternativeImageId: null,
};

export default updateImageMapRegion;
