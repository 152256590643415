import React from 'react';

import { Wrapper, Hr, ButtonWrapper, Select } from './Pagination.style';

import { createKey } from '../../../utils';
import { DEFAULT_PAGE_SIZE } from '../../../utils/utils';
import Button from '../../Button/styled/ButtonOutline';

function Pagination({ isDisabled, onLoadMoreHandler, onPageSizeChangeHandler, pageSize }) {
  const pageSizes = Array.from({ length: 5 })
    .fill(DEFAULT_PAGE_SIZE)
    .map((item, index) => item * (index + 1));

  return (
    <Wrapper>
      <Hr />
      <div className="d-load-more-container">
        <ButtonWrapper>
          <Button color="#b3b3b3" disabled={isDisabled} text="Load More" onClick={onLoadMoreHandler} />
        </ButtonWrapper>
        <Select
          className="d-load-more-container-select"
          disabled={isDisabled}
          value={pageSize}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            onPageSizeChangeHandler(Number(value));
          }}
        >
          {pageSizes.map((item, index) => (
            <option key={createKey(`pagination-${index}`)} value={Number(item)}>
              Show {item}
            </option>
          ))}
        </Select>
      </div>
      <Hr />
    </Wrapper>
  );
}

export default Pagination;
