import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_MYAPP_SECTION = gql`
  mutation createMyApp(
    $title: String!
    $identifier: String!
    $applicationDescription: String! # $logoId: String!
  ) {
    app {
      submitApp(
        inputType: {
          title: $title
          identifier: $identifier
          applicationDescription: $applicationDescription
          # logoId: $logoId
        }
      ) {
        id
      }
    }
  }
`;

const createMyAppRequest = ({
  title,
  identifier,
  onError,
  onSuccessResult,
  applicationDescription,
  // logoId,
}) => (
  <Mutation mutation={CREATE_MYAPP_SECTION}>
    {(createMyApp, { loading, error, data }) => {
      const variables = {
        title,
        identifier,
        applicationDescription,
        // logoId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.submitApp);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createMyApp}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createMyAppRequest.propTypes = {
  applicationDescription: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  identifier: PropTypes.string.isRequired,
  // logoId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

createMyAppRequest.defaultProps = {};

export default createMyAppRequest;
