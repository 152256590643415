import { gql } from '@apollo/client';

export const ADD_SUMMARY_FEEDBACK_RANK_AND_NOTE_MATCH_MUTATION = gql`
  mutation addSummaryFeedbackRankAndNoteMatch(
    $contentSnippetSummaryId: Int!
    $note: String
    $questionAnswerId: Int!
    $questionResponseId: Int!
    $rank: Int
  ) {
    summaryFeedback {
      addSummaryFeedbackRankAndNoteMatch: addFeedbackRankAndNoteMatch(
        contentSnippetSummaryId: $contentSnippetSummaryId
        matchResponseId: $questionResponseId
        note: $note
        questionAnswerId: $questionAnswerId
        sort: $rank
      )
    }
  }
`;
