import { gql } from '@apollo/client';

export const SEARCH_DIRECTORIES_QUERY = gql`
  query searchDirectories(
    $directoryTypeId: Int
    $geoPositionGeolocalisation: GeoPositionGeolocalisationInputType
    $postcodeGeolocalisation: PostcodeGeolocalisationInputType
    $projectId: String
    $sorting: ESorting
    $status: EDirectoryStatus
    $tag: String
    $title: String
  ) {
    directory {
      searchDirectories(
        directoryTypeId: $directoryTypeId
        geoPositionGeolocalisation: $geoPositionGeolocalisation
        postcodeGeolocalisation: $postcodeGeolocalisation
        projectId: $projectId
        sorting: $sorting
        status: $status
        tag: $tag
        title: $title
      ) {
        address {
          city
          country
          latitude
          line1
          longitude
          postcode
          state
          suburb
        }
        asset {
          contentType
          s3Key
        }
        description
        directoryType {
          id
          name
        }
        email
        files {
          asset {
            contentType
            fileName
            s3Key
          }
        }
        friendlyUrl
        id
        phoneNumber
        status
        subTitle
        tags
        title
        url
      }
    }
  }
`;
