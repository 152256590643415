import PropTypes from 'prop-types';

export const NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS = 1000;

export const QUESTION_ACTION_PROP_TYPE = PropTypes.shape({
  __typename: PropTypes.string,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      contentPageId: PropTypes.number,
      contentSnippetQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.number,
      isTemplate: PropTypes.bool,
      questionResponseRadioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    }),
  ),
  id: PropTypes.number,
  sectionId: PropTypes.number,
});

export const QUESTION_CONDITION_CHECKBOX_PROP_TYPE = PropTypes.shape({
  contentPageId: PropTypes.number,
  contentSnippetQuestionId: PropTypes.number,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  questionResponseCheckboxId: PropTypes.number,
  title: PropTypes.string,
});

export const QUESTION_CONDITION_RADIO_PROP_TYPE = PropTypes.shape({
  contentPageId: PropTypes.number,
  contentSnippetQuestionId: PropTypes.number,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  questionResponseRadioId: PropTypes.number,
  title: PropTypes.string,
});

export const QUESTION_CONDITION_SLIDER_PROP_TYPE = PropTypes.shape({
  contentPageId: PropTypes.number,
  contentSnippetQuestionId: PropTypes.number,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  title: PropTypes.string,
});

export const QUESTION_CONDITION_TEXT_PROP_TYPE = PropTypes.shape({
  contentPageId: PropTypes.number,
  contentSnippetQuestionId: PropTypes.number,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
});

export const QUESTION_LOCATION_PROP_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    labelText: PropTypes.string,
    value: PropTypes.string,
  }),
);

export const SELECTED_CONDITION_QUESTION_PROP_TYPE = PropTypes.shape({
  __typename: PropTypes.string,
  configuration: PropTypes.shape({
    __typename: PropTypes.string,
    id: PropTypes.number,
  }),
  contentPage: PropTypes.shape({
    __typename: PropTypes.string,
    contentGroupId: PropTypes.number,
  }),
  id: PropTypes.number,
  question: PropTypes.string,
});

export const WRAPPER_PROP_TYPE = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.oneOf(['container', 'section', 'snippet']),
});
