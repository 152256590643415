import gql from 'graphql-tag';

export const EXPORT_LOGINS = gql`
  query ExportLogins($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime, $fileType: FileType) {
    report {
      exportLogins(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType) {
        contentType
        s3Key
      }
    }
  }
`;
