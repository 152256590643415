import React, { useContext } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import client from '../client';
import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

import AppContext from '../../context/AppContext';

export const CREATE_ASSET = gql`
  mutation createAsset(
    $projectId: String!
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String!
    $fileSizeInBytes: Int!
    $transcript: String
  ) {
    asset {
      createAsset(
        asset: {
          projectId: $projectId
          type: $type
          fileName: $fileName
          contentType: $contentType
          fileSizeInBytes: $fileSizeInBytes
          alt: $alt
          transcript: $transcript
        }
      ) {
        signedUrl
        asset {
          assetId
          s3Key
          fileName
          contentType
          presignedUrl
        }
      }
    }
  }
`;

const CreateAssetRequest = ({ clientOverride, contentType, type, fileName, projectId, fileSizeInBytes, onError, onSuccessResult }) => {
  const {
    project: { id },
  } = useContext(AppContext);
  if (!projectId) {
    projectId = id;
  }
  return (
    <Mutation client={clientOverride === null ? client : clientOverride} mutation={CREATE_ASSET}>
      {(createAsset, { loading, error, data }) => {
        const variables = {
          projectId,
          type,
          contentType,
          fileName,
          fileSizeInBytes,
          alt: 'asset',
          transcript: 'transcript',
        };

        if (error) {
          return <ErrorModal error={error} onError={onError} />;
        }
        if (data) {
          onSuccessResult(data);
        }

        return <MutationRequest isLoading={loading} mutation={createAsset} variables={variables} />;
      }}
    </Mutation>
  );
};

CreateAssetRequest.propTypes = {
  contentType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSizeInBytes: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  clientOverride: PropTypes.shape({}),
};

CreateAssetRequest.defaultProps = {
  clientOverride: null,
};

export default CreateAssetRequest;
