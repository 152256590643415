import gql from 'graphql-tag';

export const GET_TAIL_TYPES = gql`
  query getTails {
    __type(name: "EBubbleTail") {
      enumValues {
        name
      }
    }
  }
`;
