export const responseTypeShortConst = {
  SINGLE_TEXT: 'SL',
  MULIT_TEXT: 'ML',
  RADIO: 'RB',
  CHECKBOX: 'CB',
  DROPDOWNLIST: 'Dd',
  NUMBER: 'Nu',
  SLIDER: 'Sl',
  MATCH: 'Ma',
  CALENDAR: 'Ca',
};

export const responseTypeLongConst = {
  SINGLE_TEXT: 'SingleText',
  MULIT_TEXT: 'MultiText',
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  DROPDOWNLIST: 'DropDownList',
  NUMBER: 'Number',
  SLIDER: 'Slider',
  MATCH: 'Match',
  CALENDAR: 'Calendar',
};

export const responseShortToLongConst = {
  SL: 'SingleText',
  ML: 'MultiText',
  RB: 'Radio',
  CB: 'Checkbox',
  Dd: 'DropDownList',
  Nu: 'Number',
  Sl: 'Slider',
  Ma: 'Match',
  Ca: 'Calendar',
  DD: 'Match',
  MR: 'MultiResponseText',
};

export const responseLongToShortConst = {
  SingleText: 'SL',
  MultiText: 'ML',
  Radio: 'RB',
  Checkbox: 'CB',
  DropDownList: 'Dd',
  Number: 'Nu',
  Slider: 'Sl',
  Match: 'Ma',
  Calendar: 'Ca',
  MultiResponseText: 'MR',
};

export const responseShortToFriendly = {
  SL: 'Single line text',
  ML: 'Multi line text',
  RB: 'Single select',
  CB: 'Multi select',
  Dd: 'Drop down list',
  Nu: 'Number',
  Sl: 'Slider',
  Ma: 'Match',
  Ca: 'Calendar',
  DD: 'Drag and Drop',
  MR: 'Multi response',
};

export const responseFriendlyToShort = {
  'Single line text': 'SL',
  'Multi line text': 'ML',
  'Single select': 'RB',
  'Multi select': 'CB',
  'Drop down list': 'Dd',
  Number: 'Nu',
  Slider: 'Sl',
  Match: 'Ma',
  Calendar: 'Ca',
  'Drag and Drop': 'DD',
  'Multi response': 'MR',
};
