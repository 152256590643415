import styled from 'styled-components';

export const BaseLozenge = styled.div`
  border: 1px solid;
  border-color: #262542;
  border-radius: 50px;
  color: #262542;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  padding: 0.5em 1.4em;
`;

export const Success = styled(BaseLozenge)`
  background-color: #4fbd9c;
  border-color: #4fbd9c;
  color: #fff;
`;

export const Warning = styled(BaseLozenge)`
  border-color: #e25169;
  color: #e25169;
`;

export const Danger = styled(BaseLozenge)`
  background-color: #e25169;
  border-color: #e25169;
  color: #fff;
`;
