/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import InputHolder from '../../components/InputHolder/InputHolder';
import PopupMessageSideBar from '../../components/PopupMessageSideBar/PopupMessageSideBar';
import SideBarRight from '../../components/SideBarRight/SideBarRight';
import {
  errorMessageEnterTimeout,
  errorMessageLeaveTimeout,
} from '../../config';
import ChangePasswordRequest from '../../middleware/User/changePasswordRequest';

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      password: '',
      repeatPassword: '',
      errorMessage: '',
      requestSent: false,
    };

    this.handleInputs = this.handleInputs.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.validate = this.validate.bind(this);
    this.handleClearErrorMessages = this.handleClearErrorMessages.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.handleOnUserData = this.handleOnUserData.bind(this);
  }

  handleClearErrorMessages() {
    const { errorMessage } = this.state;
    if (errorMessage !== '') {
      this.setState({
        errorMessage: '',
      });
    }
  }

  handleInputs(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  validate() {
    const { currentPassword, password, repeatPassword } = this.state;
    return {
      CurrentPasswordEmpty: {
        validation: currentPassword.length > 0,
        errorMessage: 'Current Password must not be empty.',
      },
      PasswordsMatch: {
        validation: password === repeatPassword,
        errorMessage: 'Passwords do not match.',
      },
      PasswordsMinLength: {
        validation: password.length >= 6,
        errorMessage: 'New Password must have minimum 6 chars.',
      },
      PasswordEmpty: {
        validation: password.length > 0,
        errorMessage: 'New Password must not be empty.',
      },
    };
  }

  handleOnError(message) {
    this.setState({
      requestSent: false,
      errorMessage: message,
    });
  }

  handleOnUserData() {
    const { handleClosePanel } = this.props;

    this.setState({
      requestSent: false,
    });
    handleClosePanel();
  }

  handleUpdateUser(e) {
    e.preventDefault();
    const validationErrors = ValidationProcessor(this.validate());
    if (!validationErrors.modelValid) {
      this.setState({ errorMessage: validationErrors.validations });
    } else {
      this.setState({
        requestSent: true,
      });
    }
  }

  render() {
    const {
      currentPassword,
      password,
      repeatPassword,
      requestSent,
      errorMessage,
    } = this.state;
    const { isPanelOpen, handleClosePanel } = this.props;

    return (
      <SideBarRight open={isPanelOpen}>
        <button
          className="c-sidebar-right__close"
          onClick={handleClosePanel}
          onKeyDown={handleClosePanel}
          type="button"
        >
          <span className="icon-plus" />
        </button>
        <div className="c-sidebar-right-form">
          <p className="c-sidebar-right-title">Update password</p>
          <form autoComplete="off">
            <InputHolder
              label="Current Password"
              name="currentPassword"
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
              required
              type="password"
              value={currentPassword}
            />

            <InputHolder
              label="New Password"
              name="password"
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
              required
              type="password"
              value={password}
            />

            <InputHolder
              label="Repeat Password"
              name="repeatPassword"
              onChange={this.handleInputs}
              onClick={this.handleClearErrorMessages}
              required
              type="password"
              value={repeatPassword}
            />
          </form>

          <div className="text-right">
            <button
              className="button--black"
              onClick={handleClosePanel}
              style={{ margin: '1.5em 1em 1.5em 0' }}
              type="button"
            >
              Cancel
            </button>
            <button
              className="button--blue"
              onClick={this.handleUpdateUser}
              style={{ margin: '1.5em 0' }}
              type="button"
            >
              Save
            </button>
          </div>
        </div>

        <ReactCSSTransitionGroup
          transitionEnterTimeout={errorMessageEnterTimeout}
          transitionLeaveTimeout={errorMessageLeaveTimeout}
          transitionName="dialog-popup"
        >
          {errorMessage !== '' && (
            <PopupMessageSideBar message={errorMessage} />
          )}
        </ReactCSSTransitionGroup>
        {requestSent && (
          <ChangePasswordRequest
            newPassword={repeatPassword}
            oldPassword={currentPassword}
            onCreateError={this.handleOnError}
            onSuccessResult={this.handleOnUserData}
          />
        )}
      </SideBarRight>
    );
  }
}

UpdatePassword.propTypes = {
  handleClosePanel: PropTypes.func.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
};

export default UpdatePassword;
