import React from 'react';

import { Link } from 'react-router-dom';

import Clients from '../../components/Clients/Clients';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

function ContactConfirmationPage() {
  return (
    <div>
      <div className="login-page">
        <div className="login-page__fold">
          <Header theme="red" />
          <div className="login-page__container">
            <h2 className="thanks">THANK YOU</h2>
          </div>
        </div>
        <div className="login-page__card">
          <div className="login-page__col login-page__image">
            <img
              alt="Laptop locked"
              src="https://netfront.com.au/img/kanzi/thank-you.svg"
            />
          </div>
          <div
            className="login-page__col"
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              alt="tick"
              src="https://netfront.com.au/img/kanzi/static/tick.svg"
              style={{ width: '40px', marginTop: '1em' }}
            />
            <p
              style={{
                margin: '1em 0',
                padding: '0 1em',
                textAlign: 'center',
              }}
            >
              Your message has been sent. Thank you for contacting us.
            </p>
            <Link to="/login">
              <button
                className="button--red"
                style={{
                  marginBottom: '1em',
                }}
                type="button"
              >
                LOGIN
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}
export default ContactConfirmationPage;
