import React from 'react';

import Clients from '../../components/Clients/Clients';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

function PricingConfirmationPage() {
  return (
    <div>
      <div className="login-page">
        <div className="login-page__fold">
          <Header theme="red" />
          <div className="login-page__container">
            <h2 className="heading-page">THANK YOU</h2>
          </div>
        </div>
        <div className="login-page__card">
          <div className="login-page__col login-page__image">
            <img alt="Laptop locked" src="https://ekardo-assets-prod.s3.ap-southeast-2.amazonaws.com/thank-you.svg" />
          </div>
          <div
            className="login-page__col"
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1em 0',
            }}
          >
            <img alt="tick_image" src="https://assets.kanzi.io/images/tick.svg" style={{ width: '40px' }} />
            <p style={{ margin: '1em 0' }}>Success! The tribe has been notified!</p>
            We will advise the elders and get back once we have a response
          </div>
        </div>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default PricingConfirmationPage;
