import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash.isempty';

import Buttons from '../Buttons/Buttons';

import { Post } from './Post';

import { useSocialState } from '../../../context/SocialPage/SocialPageContext';
import { createKey, getTimeFromDateTime } from '../../../utils';
import ListItemEditDelete from '../../List/ListItemEditDelete';

const UserPosts = ({ onCloseHandler }) => {
  const [pageState, dispatch] = useSocialState();
  const { activePostId, activeUserId, users } = pageState;

  const [currentUser, setCurrentUser] = useState(undefined);

  const handleEditPost = (id, item) => {
    dispatch({
      type: 'setActiveUserPost',
      newState: item,
    });
    dispatch({
      type: 'setActivePostId',
      newState: id,
    });
  };

  useEffect(() => {
    const selectedUser = users.filter((item) => item.id === activeUserId);
    setCurrentUser(selectedUser);
  }, [activeUserId, users]);

  if (isEmpty(currentUser)) {
    return null;
  }

  if (activePostId) {
    return <Post />;
  }

  const { posts = [] } = currentUser[0];

  return (
    <div>
      <fieldset className="c-view-wrapper">
        <legend className="h-hide">POSTS</legend>
        <strong className="c-view-title h-margin-bottom-sm">Posts</strong>
        <ul className="c-list-container">
          {isEmpty(posts) ? (
            <div className="c-no-items-block c-item-border">
              <p className="c-no-item-text">This user has not made any posts</p>
            </div>
          ) : (
            posts.map((item) => {
              const { message, id, author, createdDateTime } = item;
              const { firstName, lastName } = author;

              const dateTime = new Date(createdDateTime);
              const commentTime = getTimeFromDateTime(dateTime);
              const commentDate = dateTime.toLocaleDateString();

              return (
                <div key={`post-comment-${createKey(String(id))}`} className="h-box-drop-shadow h-margin-top-sm h-margin-bottom-sm">
                  <ListItemEditDelete text={message} isTextFewLines onEdit={() => handleEditPost(id, item)} />
                  <div className="h-flex  c-item-padding">
                    <div>
                      <label className="c-list-item-info-text h-bold-text h-margin-right-sm">{`${firstName}  ${lastName}`}</label>
                    </div>
                    <div>
                      <label className="c-list-item-info-text c-light-text">
                        {commentDate} {commentTime}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </ul>
      </fieldset>
      <Buttons onClose={onCloseHandler} />
    </div>
  );
};

export { UserPosts };
