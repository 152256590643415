import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import AddNewCard from '../../components/UI/Card/AddNewCard';
import { LoadingMenuCard } from '../../components/UI/Card/LoadingMenuCard';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import CreateSliderRequest from '../../middleware/Slider/createSlider';
import SearchSliders from '../../middleware/Slider/searchSliders';

const CardsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -1.7em;

  @media (max-width: 768px) {
    padding-bottom: 8em;
  }
`;

const CardAvatar = styled.div`
  align-items: center;
  background: transparent linear-gradient(65deg, #4bc1c9 0%, #4bc1d9 68%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6em;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: 0 auto 0.6em auto;
  max-height: 50px;
  max-width: 50px;
  width: 100%;
`;

const CardInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const LibrarySliderPage = (props) => {
  const { match, history } = props;
  const { org, project } = useContext(AppContext);

  const [createRequest, setCreateRequest] = useState(false);
  const [loadedSliders, setLoadedSliders] = useState(false);
  const [allSliders, setAllSliders] = useState([]);

  const breadPath = [
    {
      id: 1,
      path: `/dashboard/${org.key}/project/${project.id}/library`,
      title: 'Library',
    },
    {
      id: 2,
      path: ``,
      title: 'Slider library',
    },
  ];

  const handleAddSlider = () => {
    setCreateRequest(true);
  };
  const handleSliderOpen = (url) => {
    history.push(url);
  };
  const handleCreateError = () => {
    setCreateRequest(false);
  };
  const handleCreateSuccess = (data) => {
    setLoadedSliders(false);
    setCreateRequest(false);
    handleSliderOpen(`/dashboard/${org.key}/project/${project.id}/slider-builder/${data}?library=true`);
  };

  const handleLoadedSliderError = () => {
    setLoadedSliders(true);
  };

  const handleLoadedSliderSuccess = (data) => {
    setAllSliders(data);
    setLoadedSliders(true);
  };

  const handleGroupAvatar = (title) => {
    const splitTitle = title.split(' ');
    return splitTitle.length > 1 ? `${splitTitle[0].split('')[0]}${splitTitle[1].split('')[0]}` : title.split('')[0];
  };

  const slidersMenu = allSliders.map((item) => (
    <MenuCard key={item.id} href={`/dashboard/${org.key}/project/${project.id}/slider-builder/${item.id}?library=true`} name={item.title}>
      <CardInner>
        <CardAvatar>{handleGroupAvatar(item.title)}</CardAvatar>
      </CardInner>
    </MenuCard>
  ));

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Manage your sliders"
        pageDescription="Manage all your sliders"
        pageTitle="Slider library page"
        params={match.params}
      >
        <ProjectNavBar title="Slider" />
        <CardsWrapper>
          {!loadedSliders && <LoadingMenuCard count={11} />}
          {slidersMenu}
          <AddNewCard onClick={handleAddSlider} />
        </CardsWrapper>
      </PageWrapper>
      {!loadedSliders && project.id && (
        <SearchSliders projectId={project.id} onError={handleLoadedSliderError} onSuccessResult={handleLoadedSliderSuccess} />
      )}
      {createRequest && (
        <CreateSliderRequest
          // Animation: fade / linear
          animation="LINEAR"
          autoplay={false}
          autoplaySpeed={3000}
          projectId={project.id}
          slidesToScroll={1} //	Direction navigation
          // adaptiveHeight: false,
          slidesToShow={1} // Loop
          speed={3000}
          title={`new slider ${allSliders.length + 1}`}
          arrows //	Slideshow
          dots //	Animation speed
          infinite //	Slide show speed
          onError={handleCreateError}
          onSuccessResult={handleCreateSuccess}
        />
      )}
    </>
  );
};

LibrarySliderPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.any,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      org: PropTypes.string,
      projectId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default LibrarySliderPage;
