import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../../QueryRequest';

export const SEARCH_CONTENT_GROUP_REQUEST = gql`
  query searchResponseSetRequest(
    $type: EQuestionResponseSetType
    $projectId: String!
    $title: String!
  ) {
    questionResponseSet {
      searchResponseSet(projectId: $projectId, type: $type, title: $title) {
        id
        title
        availableResponses {
          id
          label
          questionResponseSetId
          value
        }
      }
    }
  }
`;

const searchResponseSetRequest = ({
  projectId,
  type,
  title,
  onError,
  onSuccessResult,
}) => (
  <Query
    fetchPolicy="network-only"
    query={SEARCH_CONTENT_GROUP_REQUEST}
    variables={{ type, projectId, title }}
  >
    {({ error, data }) => (
      <QueryRequest
        data={
          data &&
          Object.entries(data).length !== 0 &&
          data.questionResponseSet.searchResponseSet
        }
        error={error}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

searchResponseSetRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default searchResponseSetRequest;
