import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import IconDiscount from '../../static/img/discounts.svg';
import IconOrder from '../../static/img/subscriptions.svg';
import { getBreadCrumbPath } from '../../utils/utils';

const CardsBox = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-right: -1.7em;
`;

const subscriptionsCards = [
  {
    image: IconOrder,
    key: 'orders',
    path: 'groups-order-summary',
    title: 'Orders',
  },
  {
    image: IconDiscount,
    key: 'discount-codes',
    path: 'discounts',
    title: 'Discount codes',
  },
];

function SubscriptionsPage() {
  const { pathname } = useLocation();
  const params = useParams();

  const breadCrumbPath = getBreadCrumbPath(pathname, 0, 'Subscriptions');

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="Review orders and manage discount codes"
      pageDescription=""
      pageTitle="Subscriptions"
      params={params}
    >
      <ProjectNavBar title="Subscriptions" />
      <CardsBox>
        {subscriptionsCards.map(subscriptionsCard => {
          const { image, key, path, title } = subscriptionsCard;
          return <MenuCard href={`${pathname}/${path}`} key={`subscriptions-card-${key}`} name={title} src={image} />;
        })}
      </CardsBox>
    </PageWrapper>
  );
}

export default SubscriptionsPage;
