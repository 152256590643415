import React from 'react';

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '2rem',
    maxWidth: '600px',
  },
}));

const NormalModal = props => {
  const classes = useStyles();

  const { isOpen, onClose, children } = props;

  return (
    <Modal
      aria-describedby="transition-modal-description"
      aria-labelledby="transition-modal-title"
      className={classes.modal}
      closeAfterTransition
      onClose={onClose}
      open={isOpen}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>{children}</div>
      </Fade>
    </Modal>
  );
};

NormalModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NormalModal;
