import React, { useRef, useState, useEffect } from 'react';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { Client } from '@netfront/gelada-react-shared';
import { addMonths, isValid } from 'date-fns';
import { useParams } from 'react-router-dom';

import ExportPage from './ExportPage';
import CONSTANTS from './Shared/Constants';
import Constants from './Shared/Constants';
import FilterOptionsError from './Shared/FilterOptionsError';
import getDropdownOptions from './Shared/getDropdownOptions';
import {
  DropdownListOptionContainer,
  DropdownListOptionsContainer,
  InputFieldFilterContainer,
  NumberInputFieldWithNoArrows,
} from './Shared/styled';

import Dropdown from '../../../components/UI/Dropdown/Dropdown';
import { getSignedUrl } from '../../../middleware/AWS/getSignedUrl';
import { EXPORT_USERS } from '../../../middleware/Export/exportUsers';
import { GET_GROUP_CODES } from '../../../middleware/Group/getGroupCodesQuery';
import { downloadFileByUrl } from '../../../utils/utils';

const ExportUsersPage = () => {
  const { projectId } = useParams();

  const client = useRef(Client()).current;

  const [apiError, setApiError] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(addMonths(new Date(), -1));
  const [dateTo, setDateTo] = useState(new Date());
  const [defaultGroupId] = useState(CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID);
  const [hasLoadingGroupCodesError, setHasLoadingGroupCodesError] = useState(false);
  const [groupCodeOptions, setGroupCodeOptions] = useState(null);
  const [groupId, setGroupId] = useState(defaultGroupId);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    if (!projectId) return;
    sendGetGroups({
      variables: {
        projectId,
      },
    });
  }, [projectId]);

  const [sendGetGroups, { loading: isLoadingGroupCodes }] = useLoggedGeladaLazyQuery({
    query: GET_GROUP_CODES,
    options: {
      client,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setIsFormValid(isValid(dateFrom) && isValid(dateTo));
        setGroupCodeOptions(
          getDropdownOptions(
            CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID,
            CONSTANTS.DROPDOWN.DEFAULT_OPTION_LABEL.GROUP_CODE,
            data.group.searchGroups,
            ['identifier', 'name'],
            'id',
          ),
        );
      },
      onError: (error) => {
        setApiError(error);
        setHasLoadingGroupCodesError(true);
      },
    },
  });

  const [sendExportUsersQuery, { loading: isGeneratingReport }] = useLoggedGeladaLazyQuery({
    query: EXPORT_USERS,
    options: {
      client,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { contentType, s3Key } = data.report.exportGroupUsers;
        const signedUrl = getSignedUrl(s3Key, contentType);

        downloadFileByUrl(signedUrl);
      },
      onError: (error) => {
        setApiError(error);
      },
    },
  });

  const download = (fileType = 'EXCEL' | 'CSV') => {
    sendExportUsersQuery({
      variables: {
        ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        projectId,
        dateFrom,
        dateTo,
        groupId: groupId === CONSTANTS.DROPDOWN.DEFAULT_OPTION_ID ? null : groupId,
        phoneNumber,
        fileType,
      },
    });
  };

  const handleDateFromChange = (value) => {
    setDateFrom(value);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const handleDateToChange = (value) => {
    setDateTo(value);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const handleOptionSelected = (selectedId) => {
    setGroupId(selectedId);
    setIsFormValid(isValid(dateFrom) && isValid(dateTo));
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;

    if (value.length > CONSTANTS.PHONE_NUMBER.MAX_LENGTH) {
      return;
    }

    setPhoneNumber(value);
  };

  const groupCodeFilterComponent = hasLoadingGroupCodesError ? (
    <FilterOptionsError />
  ) : (
    groupCodeOptions && (
      <DropdownListOptionsContainer key="group-code-filter">
        <h2>Group code {CONSTANTS.TEXT.OPTIONAL}</h2>

        <DropdownListOptionContainer>
          {groupCodeOptions && (
            <Dropdown
              availableResponses={groupCodeOptions}
              name="group-codes"
              selectedValue={groupId.toString()}
              onChangeHandler={(id) => handleOptionSelected(Number(id))}
            />
          )}
        </DropdownListOptionContainer>
      </DropdownListOptionsContainer>
    )
  );

  const phoneNumberFilterComponent = (
    <InputFieldFilterContainer key="phone-number-filter">
      <h2>Phone number {CONSTANTS.TEXT.OPTIONAL}</h2>

      <NumberInputFieldWithNoArrows
        key={CONSTANTS.PHONE_NUMBER.INPUT_FIELD_KEY}
        id={CONSTANTS.PHONE_NUMBER.INPUT_FIELD_KEY}
        name={CONSTANTS.PHONE_NUMBER.INPUT_FIELD_KEY}
        type="number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
    </InputFieldFilterContainer>
  );

  const options = {
    apiError,
    downloadCSVCallback: () => download('CSV'),
    downloadExcelCallback: () => download('EXCEL'),
    filtering: {
      date: {
        dateFrom,
        dateTo,
        handleDateFromChangeCallback: handleDateFromChange,
        handleDateToChangeCallback: handleDateToChange,
      },
      pageSpecificComponents: [groupCodeFilterComponent, phoneNumberFilterComponent],
    },
    flags: {
      isFormValid,
      preloader: {
        isGeneratingReport,
        isLoadingGroupCodes,
        isLoadingUserFlowSteps: false,
      },
    },
    infoBannerText: 'This page exports user profile information based on the selected filters',
  };

  return <ExportPage options={options} />;
};

export default ExportUsersPage;
