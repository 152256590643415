import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const SORT_CONTENT_CONTAINER = gql`
  mutation SortContainer($containerId: Int!, $sectionId: Int!, $sort: Int!) {
    contentSection {
      sortContainer(
        containerId: $containerId
        sectionId: $sectionId
        sort: $sort
      ) {
        id
        sort
      }
    }
  }
`;

const sortContainerRequest = ({
  sectionId,
  containerId,
  sort,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={SORT_CONTENT_CONTAINER}>
    {(updateSection, { loading, error, data }) => {
      const variables = {
        sectionId,
        containerId,
        sort,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateSection}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

sortContainerRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sectionId: PropTypes.number.isRequired,
  sort: PropTypes.number.isRequired,
};

sortContainerRequest.defaultProps = {};

export default sortContainerRequest;
