import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import MutationRequest from '../MutationRequest';

export const CREATE_USER_TYPE = gql`
  mutation (
    $projectId: String!
    # $userFlowId: Int
    $name: String!
    $userTypeCode: String
    $permission: EMemberPermission!
    $approvalRequired: Boolean!
  ) {
    userType {
      createUserType(
        userType: {
          projectId: $projectId
          name: $name
          userTypeCode: $userTypeCode
          permission: $permission
          approvalRequired: $approvalRequired
        }
      ) {
        id
        name
        userTypeCode
        permission
      }
    }
  }
`;

const createUserTypeRequest = ({ projectId, name, userTypeCode, permission, approvalRequired, onError, onSuccessResult }) => (
  <Mutation client={Client()} mutation={CREATE_USER_TYPE}>
    {(createUserType, { loading, error, data }) => {
      const variables = {
        projectId,
        name,
        userTypeCode,
        permission,
        approvalRequired,
      };

      if (error) {
        if (error.networkError.result) {
          onError(error.networkError.result.message);
        } else {
          onError(error.message);
        }
      }
      if (data) {
        onSuccessResult(data.userType.createUserType);
      }

      return <MutationRequest isLoading={loading} mutation={createUserType} variables={variables} />;
    }}
  </Mutation>
);

createUserTypeRequest.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  approvalRequired: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  userTypeCode: PropTypes.string.isRequired,
  // userFlowId: PropTypes.number,
};

export default createUserTypeRequest;
