import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

const REMOVE_APP_FROM_ENTITY = gql`
  mutation removeAppLink($appId: String!, $eId: String!, $eType: EEntityType!) {
    app {
      removeAuthorizedEntity(
        inputType: {
          appIdentifier: $appId
          entity: { entityId: $eId, entityType: $eType }
        }
      )
    }
  }
`;

const RemoveAppFromEntity = ({ eId, eType, appId, onError, onSuccess }) => (
  <Mutation mutation={REMOVE_APP_FROM_ENTITY}>
    {(removeAppLink, { loading, error, data }) => {
      const variables = {
        eId,
        eType,
        appId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccess(data.app.removeAuthorizedEntity);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={removeAppLink}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

RemoveAppFromEntity.propTypes = {
  appId: PropTypes.string.isRequired,
  eId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eType: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default RemoveAppFromEntity;
