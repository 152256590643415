import React, { Component } from 'react';

import Clients from '../../components/Clients/Clients';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { getAssetsUrl } from '../../config';
import ContactUserRequest from '../../middleware/Account/contactUserRequest';

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      organisation: '',
      email: '',
      phoneNumber: '',
      message: '',
      errorMessage: '',
      messageSent: false,
    };
    this.handleInputs = this.handleInputs.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSubmit = e => {
    e.preventDefault();

    const validationErrors = ValidationProcessor(this.validate());

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
    } else {
      this.setState({
        errorMessage: '',
        messageSent: true,
      });
    }
  };

  handleError = message => {
    this.setState({
      errorMessage: message,
    });
  };

  handleClear = () => {
    this.setState({
      errorMessage: '',
    });
  };

  validate() {
    const { fullName, organisation, email, phoneNumber, message } = this.state;
    return {
      FullNameEmpty: {
        validation: fullName.trim().length > 0,
        errorMessage: 'Fullname must not be empty',
      },
      OrganisationEmpty: {
        validation: organisation.trim().length > 0,
        errorMessage: 'Organisation must not be empty',
      },
      EmailEmpty: {
        validation: email.trim().length > 0,
        errorMessage: 'Email must not be empty',
      },
      PhoneNumberEmpty: {
        validation: phoneNumber.trim().length > 0,
        errorMessage: 'Phone number must not be empty',
      },
      MessageEmpty: {
        validation: message.trim().length > 0,
        errorMessage: 'Message must not be empty',
      },
    };
  }

  handleInputs(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const {
      fullName,
      organisation,
      email,
      phoneNumber,
      message,
      messageSent,
      errorMessage,
    } = this.state;

    return (
      <>
        {/* {messageSent && <Redirect to='/contact-thank-you' />} */}
        <div className="login-page">
          <div className="login-page__fold">
            <Header location={window.location.pathname} theme="red" />
            <div className="login-page__container">
              <h2 className="heading-page">Contact</h2>
              <p>Get in touch with the tribe at Ekardo</p>
            </div>
          </div>

          <div className="login-page__card">
            <div className="login-page__col login-page__image">
              <img
                alt="Laptop locked"
                src={`${getAssetsUrl()}graphics/register.svg`}
                style={{}}
              />
            </div>
            <div className="login-page__col">
              <form
                className="login-page__form"
                // method="post"
              >
                <label htmlFor="fullName">
                  Full name<span style={{ color: '#e15169' }}>*</span>
                  <input
                    aria-label="fullName"
                    className="w-100"
                    name="fullName"
                    onChange={this.handleInputs}
                    onClick={this.handleClear}
                    required=""
                    value={fullName}
                  />
                </label>

                <label htmlFor="organisation">
                  Organisation<span style={{ color: '#e15169' }}>*</span>
                  <input
                    aria-label="organisation"
                    className="w-100"
                    name="organisation"
                    onChange={this.handleInputs}
                    onClick={this.handleClear}
                    required=""
                    value={organisation}
                  />
                </label>

                <label htmlFor="email">
                  Email<span style={{ color: '#e15169' }}>*</span>
                  <input
                    aria-label="email"
                    className="w-100"
                    name="email"
                    onChange={this.handleInputs}
                    onClick={this.handleClear}
                    required=""
                    value={email}
                  />
                </label>

                <label htmlFor="phoneNumber">
                  Phone number<span style={{ color: '#e15169' }}>*</span>
                  <input
                    aria-label="phoneNumber"
                    className="w-100"
                    name="phoneNumber"
                    onChange={this.handleInputs}
                    onClick={this.handleClear}
                    required=""
                    value={phoneNumber}
                  />
                </label>

                <label htmlFor="message">
                  Message<span style={{ color: '#e15169' }}>*</span>
                  <textarea
                    aria-label="message"
                    className="w-100"
                    name="message"
                    onChange={this.handleInputs}
                    onClick={this.handleClear}
                    required=""
                    value={message}
                  />
                </label>

                <button
                  className="button--outline"
                  onClick={this.handleSubmit}
                  style={{
                    margin: '1em auto',
                  }}
                  type="button"
                >
                  <span>Send</span>
                </button>
              </form>
              {errorMessage !== '' && <ErrorMessage message={errorMessage} />}
              {messageSent && (
                <ContactUserRequest
                  content={message}
                  email={email}
                  firstname={fullName}
                  lastname={organisation}
                  onError={this.handleError}
                  phoneNumber={phoneNumber}
                />
              )}
            </div>
          </div>
        </div>
        <Clients />
        <Footer />
      </>
    );
  }
}
export default ContactPage;
