/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
import React from 'react';

import { capitalCase } from 'change-case';
import cx from 'classnames';
import { useSortBy, useTable } from 'react-table';

import { Icon } from '../Icon';

import { TableProps } from './Table.interfaces';

import './Table.css';

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<T extends object>({
  additionalClassNames,
  addIconUpToHeaderNumber,
  areColumnHeadingsSortable = true,
  cellAlignment = 'left',
  columnNumbers = [],
  columns,
  columnWidth = 'auto',
  data,
  emptyTableMessage = 'No matching records found in the table',
  hasButton,
  headerIcon,
  headerAlignment = 'left',
  isStripedRows,
  isLinkRow,
  url,
}: TableProps<T>) {
  const tableClassNames = cx('c-table', additionalClassNames);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      columns,
      data,
    },
    useSortBy
  );

  const selectedColumns = columnNumbers.map((item) => `c-table__tbody-td--${item}--${columnWidth}`).join(' ');

  return (
    <div className="c-table-container">
      <React.Fragment>
        <table className={tableClassNames} {...getTableProps()}>
          <thead className="c-table__thead">
            {headerGroups.map(({ getHeaderGroupProps, headers }) => (
              <tr className="c-table__thead-tr" {...getHeaderGroupProps()}>
                {headers.map(({ getHeaderProps, getSortByToggleProps, isSorted, isSortedDesc, minWidth, render, width }, index) => {
                  const tableSortByToggleProps = areColumnHeadingsSortable ? getSortByToggleProps() : undefined;

                  return (
                    <th
                      className="c-table__thead-th"
                      {...getHeaderProps({
                        style: {
                          minWidth,
                          width,
                        },
                      })}
                      {...tableSortByToggleProps}
                    >
                      <div className="c-table__thead-th-contents-container">
                        <div
                          className={cx('c-table__thead-th-contents-text', {
                            [`c-table__thead-th-contents-text--${headerAlignment}`]: headerAlignment,
                          })}
                        >
                          {headerIcon && addIconUpToHeaderNumber && index + 1 <= addIconUpToHeaderNumber ? (
                            <Icon className="c-table__thead-th__icon" id={headerIcon} />
                          ) : null}
                          {render('Header')}
                        </div>
                        {isSorted && (
                          <div className="c-table__thead-th-contents-sort">
                            <Icon className="c-table__sort-icon" id={isSortedDesc ? 'id_caret_down_icon' : 'id_caret_up_icon'} />
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {Boolean(rows.length) && (
            <tbody className="c-table__tbody" {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                const { cells, getRowProps } = row;

                return (
                  <tr
                    className={cx('c-table__tbody-tr', {
                      ['c-table__tbody-tr--striped']: isStripedRows,
                    })}
                    {...getRowProps()}
                  >
                    {cells.map(({ column: { id, minWidth, width }, getCellProps, render }, index) => {
                      const linkCell = (
                        <td
                          className={cx('c-table__tbody-td', {
                            ['c-table__tbody-td--has-button']: hasButton,
                            [selectedColumns]: Boolean(columnNumbers.length) && columnWidth,
                            [`c-table__tbody-td--${cellAlignment}`]: cellAlignment,
                          })}
                          data-hr={capitalCase(id)}
                          {...getCellProps({
                            style: {
                              minWidth,
                              width,
                            },
                          })}
                        >
                          <a className="c-table-row-link" href={url}>
                            {render('Cell')}
                          </a>
                        </td>
                      );

                      const textCell = (
                        <td
                          className={cx('c-table__tbody-td', {
                            ['c-table__tbody-td--has-button']: hasButton,
                            [selectedColumns]: Boolean(columnNumbers.length) && columnWidth,
                            [`c-table__tbody-td--${cellAlignment}`]: cellAlignment,
                          })}
                          data-hr={capitalCase(id)}
                          {...getCellProps({
                            style: {
                              minWidth,
                              width,
                            },
                          })}
                        >
                          {render('Cell')}
                        </td>
                      );

                      if (!isLinkRow) {
                        return textCell;
                      }

                      if (hasButton && index + 1 === cells.length) {
                        return textCell;
                      }

                      return linkCell;
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>

        {Boolean(!rows.length) && (
          <div className="c-table__empty">
            <span className="c-table__empty-message">{emptyTableMessage}</span>
          </div>
        )}
      </React.Fragment>
    </div>
  );
}

export { Table };
