import gql from 'graphql-tag';

export const FRAGMENT_IMAGE = gql`
  fragment image on ContentSnippetImageType {
    assetId
    asset {
      s3Key
      contentType
    }
  }
`;
