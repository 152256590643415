import gql from 'graphql-tag';

export const CREATE_ASSET = gql`
  mutation createPostAsset(
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $fileSizeInBytes: Int!
    $alt: String!
    $postId: Int!
  ) {
    asset {
      createPostAssets(
        postId: $postId
        assets: { type: $type, fileName: $fileName, contentType: $contentType, fileSizeInBytes: $fileSizeInBytes, alt: $alt }
      ) {
        signedUrl
        asset {
          assetId
          s3Key
          fileName
          contentType
          presignedUrl
        }
      }
    }
  }
`;
