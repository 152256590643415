import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import ImageMapCreation from '../../components/ImageMap/ImageMapCreation';
import ContentPageNavBar from '../../components/LeftNavBar/ContentPageNavBar';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import Preview from '../../components/Preview/Preview';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SnippetContainer from '../../containers/SnippetContainer/SnippetContainer';
import client from '../../middleware/client';
import { getImageMapRequest } from '../../middleware/ImageMap/getImageMap';

const ImageMapBuilderPage = (props) => {
  const { match, history } = props;
  const { projectId, orgId, imageMapId } = useParams();
  const [detail, setDetail] = useState(null);
  const [isEditMode, setIsEditMode] = useState(true);

  const { loading, data, refetch } = useQuery(getImageMapRequest, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      imageMapId,
    },
  });

  useEffect(() => {
    if (data) setDetail(data.contentGroup.getContentGroup);
  }, [data]);

  const breadPath = [
    {
      id: 1,
      path: `/dashboard/${orgId}/project/${projectId}/library`,
      title: 'Library',
    },
    {
      id: 2,
      path: `/dashboard/${orgId}/project/${projectId}/library/imageMap`,
      title: 'Interactive Regions library',
    },
    {
      id: 3,
      path: ``,
      title: 'Interactive Regions builder',
    },
  ];

  const onUpdate = () => {
    refetch();
  };

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info="Manage your Interactive Regions. Add or edit regions by clicking on the image"
        linkText="Go to region content pages"
        pageDescription="Manage your Interactive Regions. Add or edit regions by clicking on the image"
        pageTitle="Interactive Regions builder page"
        params={match.params}
        url={`/dashboard/${orgId}/project/${projectId}/group/regions/${imageMapId}/content/`}
      >
        <ProjectNavBar title="Interactive Regions Builder" />
        {!loading && Boolean(detail) ? (
          <>
            <Preview editMode={() => setIsEditMode(!isEditMode)} groupId={detail.id} />
            <ContentPageNavBar contentGroupId={detail.id} contentGroupName={detail.title} />
            <SnippetContainer>
              <ImageMapCreation history={history} imageMapDetail={detail} match={match} onUpdate={onUpdate} />
            </SnippetContainer>
          </>
        ) : null}
      </PageWrapper>
    </>
  );
};

ImageMapBuilderPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: { imageMapId: PropTypes.string },
  }).isRequired,
};

export default ImageMapBuilderPage;
