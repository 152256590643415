import React from 'react';

import cx from 'classnames';

import { Icon } from '../Icon';
import { Label } from '../Label';
import { Message } from '../Message';

import { getValidationResult } from './Input.helpers';
import { InputProps } from './Input.types';

import './Input.css';

const Input = ({
  additionalClassNames,
  autoComplete,
  errorMessage,
  id,
  isCorrect = false,
  isDirty = false,
  isReadOnly = false,
  isDisabled = false,
  isLabelHidden = false,
  isRequired = false,
  labelText,
  maxLength,
  maxNumber,
  minLength,
  minNumber,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  placeholder,
  successMessage,
  type,
  value,
}: InputProps) => {
  const { iconId, isValid } = getValidationResult(errorMessage, isCorrect, isDirty);

  const inputClassNames = cx('c-input', additionalClassNames, {
    'c-input--is-correct': isCorrect,
    'c-input--is-error': Boolean(errorMessage),
    'c-input--is-disabled': isDisabled,
    'c-input--is-required': isRequired && !isValid,
  });

  const labelClassNames = cx('c-input__label', {
    'c-input__label--is-required': isRequired && !isValid,
  });

  return (
    <React.Fragment>
      <Label additionalClassNames={labelClassNames} forId={id} isHidden={isLabelHidden} isRequired={isRequired} labelText={labelText} />
      <div className="c-input__container h-flex">
        <input
          autoComplete={autoComplete}
          className={inputClassNames}
          data-testid="qa-input"
          disabled={isDisabled}
          id={id}
          max={maxNumber}
          maxLength={maxLength}
          min={minNumber}
          minLength={minLength}
          name={name}
          placeholder={placeholder}
          readOnly={isReadOnly}
          required={isRequired}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
        />
        {iconId ? <Icon className="c-input__icon" id={iconId} /> : null}
      </div>
      <div className="c-input__condition">
        <div className="c-input-message">
          <Message id={`c-input__error-message-${id}`} text={errorMessage} type="ERROR" />
          <Message id={`c-input__success-message-${id}`} text={successMessage} type="SUCCESS" />
        </div>
        {maxLength && typeof value === 'string' ? (
          <span className="c-input__character-count">{maxLength - (value ? value.length : 0)} characters left</span>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export { Input };
