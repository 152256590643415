import React, { useState, useEffect } from 'react';

import { Checkbox, Dialog, Form, FormButtons, Label, NavigationButton, Tooltip, RadioButtonGroup, Spacing } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { useToast } from '../../../../../../hooks';
import { Title } from '../../../Components/Title';
import { getEventValue, getEventChecked, getCharacterCount, getQuestionText } from '../../../helpers';
import { useConditionDelete, useConditionSnippetLocations, useConditionSnippetAnswers, useConditionUpdate } from '../../../hooks';
import { NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS, QUESTION_ACTION_PROP_TYPE } from '../../QuestionConditions.constants';

import '../../../ActionTab.css';

const QuestionConditionRadioEdit = ({ args }) => {
  const { onClose, onCloseSidebar, onUpdateConditionCompleted, questionAction, questionCondition, titleMaxCharacter } = args;

  const { handleToastError, handleToastSuccess } = useToast();

  const { title } = questionCondition;
  const inputCharacterCount = getCharacterCount(titleMaxCharacter, title);

  const [characterCount, setCharacterCount] = useState(inputCharacterCount);
  const [conditionQuestion, setConditionQuestion] = useState([]);
  const [questionConditionResponses, setQuestionConditionResponses] = useState([]);
  const [questionConditionLocations, setQuestionConditionLocations] = useState([]);
  const [currentQuestionCondition, setCurrentQuestionCondition] = useState(questionCondition);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isTemplateChecked, setIsTemplateChecked] = useState(false);
  const [questionConditionContentGroupId, setQuestionConditionContentGroupId] = useState(undefined);

  const { id: questionConditionId } = questionCondition;

  const handleClickRemoveConditionButton = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConditionCompleted = () => {
    const updatedAction = {
      ...questionAction,
      conditions: questionAction.conditions.filter((condition) => condition.id !== questionConditionId),
    };

    handleToastSuccess({
      message: `Condition deleted successfully`,
    });

    setTimeout(() => {
      onUpdateConditionCompleted(updatedAction);
    }, NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
  };

  const handleUpdateConditionCompleted = () => {
    const updatedAction = {
      ...questionAction,
      conditions: questionAction.conditions.map((condition) =>
        condition.id === currentQuestionCondition.id ? currentQuestionCondition : condition,
      ),
    };

    handleToastSuccess({
      message: `Condition updated successfully`,
    });

    setTimeout(() => {
      onUpdateConditionCompleted(updatedAction);
    }, NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
  };

  const handleGetSnippetAnswersCompleted = (data) => {
    const {
      contentGroupId,
      formQuestion,
      configuration: {
        responseSet: { availableResponses },
      },
    } = data;

    setConditionQuestion(getQuestionText(formQuestion));
    setQuestionConditionContentGroupId(contentGroupId);
    setQuestionConditionResponses(
      availableResponses.map(({ id, label }) => ({
        id,
        labelText: label,
        value: id.toString(),
      })),
    );
  };

  const handleGetSnippetLocationsCompleted = (data) => {
    const conditionItemLocations = data.map(
      ({
        contentPage: {
          id,
          title: contentPageTitle,
          contentGroup: { title: contentGroupTitle },
        },
      }) => ({
        id,
        labelText: `${contentPageTitle} - ${contentGroupTitle}`,
        value: id.toString(),
      }),
    );

    setQuestionConditionLocations(conditionItemLocations);
  };

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleDeleteCondition } = useConditionDelete({
    onCompleted: handleDeleteConditionCompleted,
    onError: handleGetError,
  });

  const updateConditionVariables = {
    contentPageId: currentQuestionCondition.contentPageId,
    contentSnippetQuestionId: currentQuestionCondition.contentSnippetQuestionId,
    isTemplate: isTemplateChecked,
    questionActionId: questionAction.id,
    questionConditionId: currentQuestionCondition.id,
    questionResponseRadioId: currentQuestionCondition.questionResponseRadioId,
    title: currentQuestionCondition.title,
  };

  const { handleUpdateCondition } = useConditionUpdate({
    onCompleted: handleUpdateConditionCompleted,
    onError: handleGetError,
    conditionType: 'radio',
    variables: updateConditionVariables,
  });

  const { handleGetSnippetAnswers } = useConditionSnippetAnswers({
    onCompleted: handleGetSnippetAnswersCompleted,
    onError: handleGetError,
  });

  const { handleGetSnippetLocations } = useConditionSnippetLocations({
    onCompleted: handleGetSnippetLocationsCompleted,
    onError: handleGetError,
  });

  useEffect(() => {
    handleGetSnippetAnswers({
      contentSnippetQuestionId: currentQuestionCondition.contentSnippetQuestionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!questionConditionContentGroupId) {
      return;
    }

    handleGetSnippetLocations({
      contentGroupId: questionConditionContentGroupId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionConditionContentGroupId]);

  const handleBackToCondition = () => {
    onClose();
  };

  const handleDeleteQuestionCondition = () => {
    handleDeleteCondition({
      questionConditionId: currentQuestionCondition.id,
    });
  };

  const handleUpdateQuestionCondition = (event) => {
    event.preventDefault();

    handleUpdateCondition({
      updateConditionVariables,
    });
  };

  const handleIsTemplateChecked = (event) => {
    setIsTemplateChecked(getEventChecked(event));
  };

  const handleQuestionLocationChange = (event) => {
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      contentPageId: Number(getEventValue(event)),
    });
  };

  const handleQuestionResponseChange = (event) => {
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      questionResponseRadioId: Number(getEventValue(event)),
    });
  };

  const handleTitleChange = (event) => {
    const value = getEventValue(event);

    setCharacterCount(getCharacterCount(titleMaxCharacter, value));
    setCurrentQuestionCondition({
      ...currentQuestionCondition,
      title: value,
    });
  };

  return (
    <Form onSubmit={handleUpdateQuestionCondition}>
      <div className="c-action">
        <NavigationButton
          additionalClassNames="c-action__back-to-action"
          direction="previous"
          iconId="id_caret_icon"
          rotationCssSuffix="90"
          text="Condition"
          onClick={handleBackToCondition}
        />
        <Spacing size="2x-large" />
        <Title
          characterCount={characterCount}
          handleTitleChange={handleTitleChange}
          title={currentQuestionCondition.title}
          titleMaxCharacter={titleMaxCharacter}
        />
        <Spacing size="2x-large" />
        <div className="c-action__item h-flex">
          <Label additionalClassNames="c-action__item__label h-flex" forId="question" labelText="Question">
            <Tooltip placement="left" text="Question name" />
          </Label>
          <span>{conditionQuestion}</span>
        </div>
        <div>
          {Boolean(questionConditionResponses.length) && questionConditionLocations.length ? (
            <>
              <Spacing size="2x-large" />
              <div className="c-action__item h-flex">
                <Label
                  additionalClassNames="c-action__item__label h-flex"
                  forId="question response"
                  labelText="Question response"
                  isRequired
                >
                  <Tooltip placement="left" text="This action will execute if the radio selection is as selected below" />
                </Label>
                <RadioButtonGroup
                  items={questionConditionResponses}
                  name="radio"
                  selectedValue={currentQuestionCondition.questionResponseRadioId.toString()}
                  onChange={handleQuestionResponseChange}
                />
              </div>
              <Spacing size="2x-large" />
              <div className="c-action__item h-flex">
                <Label additionalClassNames="c-action__item__label h-flex" forId="question location" labelText="Question location">
                  <Tooltip placement="left" text="This action will execute if the radio selection is as selected below" />
                </Label>
                <RadioButtonGroup
                  items={questionConditionLocations}
                  name="location"
                  selectedValue={currentQuestionCondition.contentPageId.toString()}
                  onChange={handleQuestionLocationChange}
                />
              </div>
            </>
          ) : null}
          <Spacing size="2x-large" />
          <div className="c-action__item h-flex">
            <Label additionalClassNames="c-action__item__label h-flex" forId="template" labelText="Template">
              <Tooltip placement="left" text="Save condition for reuse later" />
            </Label>
            <div className="c-action__item__checkbox h-flex">
              <Checkbox
                id="isTemplate"
                isChecked={isTemplateChecked}
                labelText="Save conditional configuration for reuse"
                name="isTemplate"
                onChange={handleIsTemplateChecked}
              />
            </div>
          </div>
        </div>
        <FormButtons
          additionalClassNames="c-form-buttons__action-tab"
          isSaveButtonDisabled={Boolean(!currentQuestionCondition.title)}
          onClose={onCloseSidebar}
          onDelete={handleClickRemoveConditionButton}
        />

        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete condition: ${currentQuestionCondition.title}?`}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDeleteQuestionCondition}
        />
      </div>
    </Form>
  );
};

QuestionConditionRadioEdit.propTypes = {
  onClose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  onUpdateConditionCompleted: PropTypes.func,
  questionAction: QUESTION_ACTION_PROP_TYPE,
  questionCondition: PropTypes.shape({
    contentPageId: PropTypes.number,
    contentSnippetQuestionId: PropTypes.number,
    id: PropTypes.number,
    isTemplate: PropTypes.bool,
    questionResponseRadioId: PropTypes.number,
    title: PropTypes.string,
  }),
  titleMaxCharacter: PropTypes.number,
};

export { QuestionConditionRadioEdit };
