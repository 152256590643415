import React, { CSSProperties, useEffect, useState } from 'react';

import { getS3SignedUrl, IDBAsset } from '@netfront/common-library';
import to from 'await-to-js';
import cx from 'classnames';

import { CMSEventWrapper } from '../CMSEventWrapper/CMSEventWrapper';

import { CMSSectionProps } from './CMSSection.interfaces';

import './CMSSection.css';
import { getContentSection, useContentPageContext } from '../../contexts';
import { ERROR_MESSAGES } from '../../core';
import { DBCssStyleIconXPositionType, DBCssStyleIconYPositionType, IAssetIdS3SignedUrlType } from '../../interfaces';

const CMSSection = ({ children, contentSectionId }: CMSSectionProps) => {
  const { state: contentPage } = useContentPageContext();

  const [assetIdS3SignedUrls, setAssetIdS3SignedUrls] = useState<IAssetIdS3SignedUrlType[]>([]);

  const contentSection = getContentSection(contentSectionId, contentPage);

  const { background, contentEvent, cssStyle, visible: isVisible = true } = contentSection ?? {};
  const { class: cssStyleClass, icons = [] } = cssStyle ?? {};
  const backgroundImage: CSSProperties['backgroundImage'] = background ? `url("${background.presignedUrl}")` : undefined;

  const getIconPositionClass = (x: DBCssStyleIconXPositionType = 'LEFT', y: DBCssStyleIconYPositionType = 'TOP') =>
    `c-snippet__icon--${x.toLowerCase()}-${y.toLowerCase()}`;

  useEffect(() => {
    Promise.all(
      icons.map(async ({ asset }): Promise<IAssetIdS3SignedUrlType> => {
        const { assetId, contentType, s3Key } = asset ?? ({} as IDBAsset);

        const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

        if (getS3SignedUrlError) {
          throw getS3SignedUrlError;
        }

        if (!s3SignedUrl) {
          throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
        }

        return {
          assetId,
          s3SignedUrl,
        };
      })
    ).then(setAssetIdS3SignedUrls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isVisible && (
        <CMSEventWrapper contentEvent={contentEvent}>
          <div
            className={cx('c-cms-section', 'ek-section', cssStyleClass, {
              'c-cms-section--has-background-image': Boolean(background),
              [`ek-section-${contentSectionId}`]: contentSectionId,
            })}
            id={`ek-section-${contentSectionId}`}
            style={{
              backgroundImage,
              ...cssStyle,
            }}
          >
            {icons.map(({ asset, x, y }, index) => {
              const { alt, assetId } = asset ?? ({} as IDBAsset);
              const { s3SignedUrl } = assetIdS3SignedUrls.find((item) => item.assetId === assetId) ?? {};

              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`snippet-${contentSectionId}-icon-${assetId}-${index}`}
                  className={cx('c-snippet__icon', getIconPositionClass(x, y))}
                >
                  <img alt={alt} src={s3SignedUrl} />
                </div>
              );
            })}
            {children}
          </div>
        </CMSEventWrapper>
      )}
    </React.Fragment>
  );
};

export { CMSSection };
