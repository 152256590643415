import React, { Fragment, useEffect, useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { Dialog } from '@netfront/ui-library';
import update from 'immutability-helper';
import isNil from 'lodash.isnil';
import max from 'lodash.max';
import maxBy from 'lodash.maxby';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDebouncedCallback } from 'use-debounce';

import { INITIAL_DIALOG_STATE } from './SummaryFeedbackConfigurationMatchItem.constants';
import { getUpdatedQuestionResponses, getUpdatedQuestionResponsesWithRank } from './SummaryFeedbackConfigurationMatchItem.helpers';
import { IDialogState, ISummaryFeedbackConfigurationMatchItemProps } from './SummaryFeedbackConfigurationMatchItem.interfaces';

import { QuestionResponseDraggableItem } from '../../../../components/DragDrop';
import { IHandleCreateSummaryFeedbackRankAndNoteMatchParams, useCreateSummaryFeedbackRankAndNoteMatch } from '../../../../hooks';
import {
  IDBQuestionAnswerMatchResponseMatch,
  IDBSummaryFeedback,
  IDBSummaryFeedbackConfiguration,
  IQuestionResponse,
} from '../../../../interfaces';
import { handleApolloError } from '../../QuestionSnippet/QuestionSnippet.handlers';

const SummaryFeedbackConfigurationMatchItem = ({
  accessToken,
  answers,
  availableResponses,
  questionAnswerId,
  summaryFeedbackConfiguration,
}: ISummaryFeedbackConfigurationMatchItemProps) => {
  const {
    configuration,
    contentSnippetSummaryId,
    itemsToRank = 0,
    noteMaxLength = 0,
  } = summaryFeedbackConfiguration ?? ({} as IDBSummaryFeedbackConfiguration);

  const shouldAllowDragAndDrop = configuration.includes('DRAG_AND_DROP_SORT');
  const shouldShowRankedItems = configuration.includes('RANKED_ITEMS');
  const shouldShowUnRankedItems = configuration.includes('UN_RANKED_ITEMS');
  const shouldAllowSingleRowSort = configuration.includes('SINGLE_ROW_SORT');
  const shouldAllowNote = configuration.includes('NOTE');

  const [canRankQuestionResponses, setCanRankQuestionResponses] = useState<boolean>(true);
  const [currentSelectedQuestionResponses, setCurrentSelectedQuestionResponses] = useState<IQuestionResponse[]>([]);
  const [dialogState, setDialogState] = useState<IDialogState>(INITIAL_DIALOG_STATE);
  const [lastSetRank, setLastSetRank] = useState<number>(0);

  const { handleCreateSummaryFeedbackRankAndNoteMatch } = useCreateSummaryFeedbackRankAndNoteMatch({
    onError: handleApolloError,
    token: accessToken,
  });

  const debouncedMutation = useDebouncedCallback(
    ({ questionResponseId, note, rank }: IHandleCreateSummaryFeedbackRankAndNoteMatchParams) => {
      handleCreateSummaryFeedbackRankAndNoteMatch({
        contentSnippetSummaryId,
        note,
        questionAnswerId,
        questionResponseId,
        rank,
      });
    },
    API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS
  );

  const handleConfirmQuestionResponseDeletion = (inputQuestionResponse: IQuestionResponse) => {
    setDialogState({
      children: `${inputQuestionResponse.label}`,
      isVisible: true,
      selectedId: inputQuestionResponse.id,
      title: `Are you sure you want to un-rank the following life value?`,
    });
  };

  const handleDecrementQuestionResponseRank = (inputQuestionResponse: IQuestionResponse) => {
    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponsesWithRank({
        inputQuestionResponse,
        selectedQuestionResponses: currentSelectedQuestionResponses,
        updateRankBy: 1,
      })
    );

    const { id, note, rank } = inputQuestionResponse;

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: id,
      note,
      rank: rank ? rank + 1 : rank,
    });
  };

  const handleIncrementQuestionResponseRank = (inputQuestionResponse: IQuestionResponse) => {
    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponsesWithRank({
        inputQuestionResponse,
        selectedQuestionResponses: currentSelectedQuestionResponses,
        updateRankBy: -1,
      })
    );

    const { id, note, rank } = inputQuestionResponse;

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: id,
      note,
      rank: rank ? rank - 1 : rank,
    });
  };

  const handleDragEnd = (result: DropResult) => {
    if (!shouldAllowDragAndDrop) {
      return;
    }

    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const { id: questionResponseId } = currentSelectedQuestionResponses[source.index];
    const rank = destination.index + 1;

    const updatedQuestionResponses = update(currentSelectedQuestionResponses, {
      $splice: [
        [source.index, 1],
        [destination.index, 0, currentSelectedQuestionResponses[source.index]],
      ],
    });

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: updatedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );

    const selectedQuestionResponse = currentSelectedQuestionResponses.find(({ id }) => id === questionResponseId) as IQuestionResponse;

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: selectedQuestionResponse.id,
      note: selectedQuestionResponse.note,
      rank,
    });
  };

  const handleQuestionResponseDeletionConfirmation = () => {
    const { selectedId: questionResponseId } = dialogState;

    if (!questionResponseId) {
      return;
    }

    const selectedQuestionResponse = currentSelectedQuestionResponses.find(({ id }) => id === questionResponseId) as IQuestionResponse;

    const updatedQuestionResponses = currentSelectedQuestionResponses.map(
      (questionResponse): IQuestionResponse => ({
        ...questionResponse,
        rank: questionResponseId === questionResponse.id ? undefined : questionResponse.rank,
      })
    );

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: updatedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );

    setDialogState(INITIAL_DIALOG_STATE);

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId,
      note: selectedQuestionResponse.note,
      rank: null,
    });
  };

  const handleQuestionResponseNoteChange = (inputQuestionResponse: IQuestionResponse, note: string) => {
    setCurrentSelectedQuestionResponses((questionResponses) =>
      questionResponses.map((questionResponse) => {
        return inputQuestionResponse.id === questionResponse.id
          ? {
              ...questionResponse,
              note,
            }
          : questionResponse;
      })
    );

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: inputQuestionResponse.id,
      note,
      rank: inputQuestionResponse.rank,
    });
  };

  const handleRankQuestionResponse = (inputQuestionResponse: IQuestionResponse) => {
    if (inputQuestionResponse.rank) {
      return;
    }

    const questionResponseWithMaxRank = maxBy(currentSelectedQuestionResponses, 'rank');

    const { rank: maxRank } = questionResponseWithMaxRank ?? {};
    const nextRank = maxRank ? maxRank + 1 : 1;

    const updatedQuestionResponses = currentSelectedQuestionResponses.map((questionResponse) => {
      return inputQuestionResponse.id === questionResponse.id
        ? {
            ...questionResponse,
            rank: nextRank,
          }
        : questionResponse;
    });

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: updatedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );

    debouncedMutation({
      contentSnippetSummaryId,
      questionAnswerId,
      questionResponseId: inputQuestionResponse.id,
      note: inputQuestionResponse.note,
      rank: nextRank,
    });
  };

  const handleShowHideQuestionResponseNote = (inputQuestionResponse: IQuestionResponse) => {
    setCurrentSelectedQuestionResponses((questionResponses) =>
      questionResponses.map((questionResponse): IQuestionResponse => {
        return inputQuestionResponse.id === questionResponse.id
          ? {
              ...questionResponse,
              isNoteVisible: !questionResponse.isNoteVisible,
            }
          : questionResponse;
      })
    );
  };

  useEffect(() => {
    const filteredAnswers = answers.filter(({ definedPosition }) => definedPosition === 0);

    const questionResponseMatchIdSummaryFeedbackMap = filteredAnswers.reduce(
      (accumulator, { questionResponseMatchId, summaryFeedback }) => {
        if (!summaryFeedback) {
          return accumulator;
        }

        return {
          ...accumulator,
          [questionResponseMatchId]: summaryFeedback,
        };
      },
      {} as Record<IDBQuestionAnswerMatchResponseMatch['questionResponseMatchId'], IDBSummaryFeedback | undefined>
    );

    const selectedQuestionResponseMatchIds = filteredAnswers.map(({ questionResponseMatchId }) => questionResponseMatchId);
    const filteredQuestionResponses = availableResponses.filter(({ id }) => selectedQuestionResponseMatchIds.includes(id));

    const selectedQuestionResponses = filteredQuestionResponses.map((questionResponse): IQuestionResponse => {
      const { note, rank } = questionResponseMatchIdSummaryFeedbackMap[questionResponse.id] ?? {};

      return {
        ...questionResponse,
        isNoteVisible: false,
        note,
        rank: isNil(rank) ? rank : rank + 1,
      };
    });

    setCurrentSelectedQuestionResponses(
      getUpdatedQuestionResponses({
        questionResponses: selectedQuestionResponses,
        shouldShowRankedItems,
        shouldShowUnRankedItems,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentSelectedQuestionResponses.length) {
      return;
    }

    const totalRankedQuestionResponses = currentSelectedQuestionResponses.flatMap(({ rank }) => {
      if (isNil(rank)) {
        return [];
      }

      return [rank];
    });

    setCanRankQuestionResponses(totalRankedQuestionResponses.length < itemsToRank);
    setLastSetRank(max(totalRankedQuestionResponses) ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedQuestionResponses]);

  const { children: dialogChildren, isVisible: isDialogVisible, title: dialogTitle } = dialogState;

  return (
    <Fragment>
      {isDialogVisible && (
        <Dialog
          isOpen={isDialogVisible}
          title={dialogTitle}
          onCancel={() => setDialogState(INITIAL_DIALOG_STATE)}
          onClose={() => setDialogState(INITIAL_DIALOG_STATE)}
          onConfirm={handleQuestionResponseDeletionConfirmation}
        >
          {dialogChildren}
        </Dialog>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {currentSelectedQuestionResponses.map((questionResponse, index) => {
                const { id, label = '', note = '', rank } = questionResponse;

                return (
                  <QuestionResponseDraggableItem
                    key={`question-response-drag-drop-item-${id}`}
                    canIncrementOrDecrementQuestionResponseRank={shouldAllowSingleRowSort}
                    canRankQuestionResponses={canRankQuestionResponses}
                    id={String(id)}
                    index={index}
                    label={label}
                    lastSetRank={lastSetRank}
                    note={note}
                    noteMaxLength={noteMaxLength}
                    questionResponse={questionResponse}
                    rank={rank}
                    shouldAllowNote={shouldAllowNote}
                    isNoteVisible /** Default to true until API option */
                    onDecrementRank={handleDecrementQuestionResponseRank}
                    onDeleteConfirm={handleConfirmQuestionResponseDeletion}
                    onIncrementRank={handleIncrementQuestionResponseRank}
                    onNoteChange={shouldAllowNote ? handleQuestionResponseNoteChange : undefined}
                    onRank={handleRankQuestionResponse}
                    onShowHideNote={shouldAllowNote ? handleShowHideQuestionResponseNote : undefined}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
};

export { SummaryFeedbackConfigurationMatchItem };
