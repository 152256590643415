import { gql } from '@apollo/client';

const GET_USER_FLOW_STEP_BY_URL_QUERY = gql`
  query getUserFlowStepByUrl($userFlowId: Int!, $userFlowStepUrl: String!) {
    userFlowStep {
      getUserFlowStepByName(userFlowId: $userFlowId, userFlowStepUrl: $userFlowStepUrl) {
        id
        userRepetition {
          completed
          id
        }
      }
    }
  }
`;

export { GET_USER_FLOW_STEP_BY_URL_QUERY };
