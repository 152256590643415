export const groupTableHeadings = [
  {
    Header: 'Title',
    accessor: 'title',
    width: '30%',
  },
  {
    Header: 'Smart codes',
    accessor: 'smartCodesList',
    width: '30%',
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: '30%',
  },
  {
    Header: '',
    accessor: 'button',
    width: '10%',
  },
];
