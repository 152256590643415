import gql from 'graphql-tag';

export const CREATE_TOPIC = gql`
  mutation createTopic($projectId: String!, $title: String!) {
    topic {
      addTopic(projectId: $projectId, title: $title) {
        id
        title
        description
      }
    }
  }
`;
