import React from 'react';

import { ConfirmationMessages, RangeInput } from '@netfront/ui-library';

import { getAriaDescribedBy } from '../Snippets.helpers';

import { RangeInputSnippetProps } from './RangeInputSnippet.interfaces';

const RangeInputSnippet = ({ describedById, id, message, maxValue, minValue, onChange, selectedValues }: RangeInputSnippetProps) => {
  const { error: errorMessage, success: successMessage } = message ?? {};

  const ariaDescribedBy = getAriaDescribedBy(describedById, id, Boolean(errorMessage));

  return (
    <React.Fragment>
      <RangeInput
        aria-describedby={ariaDescribedBy}
        aria-invalid={Boolean(errorMessage)}
        maxValue={maxValue}
        minValue={minValue}
        selectedValues={selectedValues}
        onChange={onChange}
      />
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </React.Fragment>
  );
};

export { RangeInputSnippet };
