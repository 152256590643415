import gql from 'graphql-tag';

import { FRAGMENT_RESPONSE_SET } from './ResponseSetFragment';

export const FRAGMENT_CHECKBOX = gql`
  fragment checkboxConf on Checkbox {
    allowUserGeneratedResponses
    description
    id
    maxCheckedResponse
    messages {
      correct
      incomplete
      incorrect
    }
    responseSet {
      ...ResponseSetFragment
    }
    __typename
  }
  ${FRAGMENT_RESPONSE_SET}
`;
