import React from 'react';

import PropTypes from 'prop-types';

import { Container, InputField } from './styled';

const TextArea = props => {
  const { name, placeholder, value, onChange } = props;

  return (
    <Container>
      <InputField
        autoComplete="off"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder !== null ? placeholder : ''}
      />
    </Container>
  );
};

TextArea.defaultProps = {
  name: '',
  onChange: () => undefined,
  placeholder: '',
};

TextArea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextArea;
