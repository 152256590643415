import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_APP_CONTENT_SNIPPET = gql`
  mutation updateContentSnippet($id: Int!, $appComponentId: Int!) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $id }
        contentSnippetApp: { appComponentId: $appComponentId }
      ) {
        id
      }
    }
  }
`;

const updateAppContentSnippet = ({
  id,
  appComponentId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_APP_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        id,
        appComponentId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.contentSnippet.updateContentSnippet.id,
          data.contentSnippet.updateContentSnippet.sort
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateAppContentSnippet.propTypes = {
  appComponentId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

// updateAppContentSnippet.defaultProps = {
//   isVisible: true,
// };

export default updateAppContentSnippet;
