import React from 'react';

import { ConfirmationMessages } from '../ConfirmationMessages';
import { Icon } from '../Icon';
import { Label } from '../Label';

import { IMessage, InputFieldWrapperProps } from './InputFieldWrapper.interfaces';

import './InputFieldWrapper.css';

const DEFAULT_MESSAGE: IMessage = {
  error: '',
  success: '',
};

const InputFieldWrapper = ({
  children,
  iconId,
  id,
  isLabelHidden = true,
  isRequired = false,
  label = '',
  message = DEFAULT_MESSAGE,
}: InputFieldWrapperProps) => {
  const { error: errorMessage, success: successMessage } = message;

  return (
    <div className="c-input-field">
      <Label forId={id} isHidden={isLabelHidden} isRequired={isRequired} labelText={label} />
      <div className="c-input-field__inner">
        {iconId ? <Icon className="c-input-field__icon" id={iconId} /> : null}
        {children}
      </div>
      <ConfirmationMessages errorMessage={errorMessage} id={id} successMessage={successMessage} />
    </div>
  );
};

export { InputFieldWrapper };
