import gql from 'graphql-tag';

export const GET_COMPLETED_ORDERS_FOR_PROJECT = gql`
  query getCompletedOrdersForProject($projectGuid: String!) {
    orders {
      getCompletedOrdersForProject(projectGuid: $projectGuid) {
        id
        guid
        createdAtUtc
        group {
          id
          name
        }
        items {
          id
          licences {
            id
          }
        }
      }
    }
  }
`;
