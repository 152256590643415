import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_MYAPP_VERSIONS_LIST_SECTIONS = gql`
  query getMyAppVersionList($appId: Int!) {
    app {
      getAppDetails(inputType: { appId: $appId }) {
        id
        title
        logoId
        identifier
        averageRateValue
        users {
          userId
          status
        }
        versions {
          id
          version
          appId
          uploadedDateTime
          status
          publishStatus
          usageCount
          components {
            files {
              fileType
              appComponentId
            }
            title
          }
        }
      }
    }
  }
`;

const getMyAppVersionList = ({ appId, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_MYAPP_VERSIONS_LIST_SECTIONS}
    variables={{ appId }}
  >
    {({ loading, error, data }) => (
      <QueryRequest
        data={
          data && Object.entries(data).length !== 0 && data.app.getAppDetails
        }
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getMyAppVersionList.propTypes = {
  appId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getMyAppVersionList;
