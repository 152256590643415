import gql from 'graphql-tag';

export const FRAGMENT_SLIDER = gql`
  fragment sliderConf on Slider {
    description
    id
    defaultValue
    maxValue
    minValue
    step
    ticks {
      label
      sliderValue
    }
    messages {
      correct
      incomplete
      incorrect
    }
    __typename
  }
`;
