import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const RESEND_ORG_INVITATION = gql`
  mutation resendInvitation($invitationId: Int!) {
    invitation {
      resendInvitation(invitationId: $invitationId) {
        id
        firstName
        lastName
        invitedUserEmail: emailUserInvited
      }
    }
  }
`;

const resendInvitation = ({ invitationId, onError, onSuccessResult }) => (
  <Mutation client={Client()} mutation={RESEND_ORG_INVITATION}>
    {(createOrgInvite, { loading, error, data }) => {
      const variables = {
        invitationId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.invitation.resendInvitation);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createOrgInvite}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

resendInvitation.propTypes = {
  invitationId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default resendInvitation;
