import React from 'react';

import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import client from '../client';
import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';
import { ENABLE_USER } from '../User/enableUser';

const activateEkardoAccountRequest = ({ onError, onSuccessResult }) => (
  <Mutation client={client} mutation={ENABLE_USER}>
    {(activateAccount, { loading, error, data }) => {
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.user);
      }

      return <MutationRequest isLoading={loading} mutation={activateAccount} />;
    }}
  </Mutation>
);

activateEkardoAccountRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

activateEkardoAccountRequest.defaultProps = {};

export default activateEkardoAccountRequest;
