const updateContainerLayout = layout => {
  if (layout === 1) {
    return 1;
  }
  if (layout === 2) {
    return 2;
  }
  if (layout === 3) {
    return 7;
  }
  if (layout === 4) {
    return 10;
  }
  return null;
};

export function getIsFormBuilder(pageType) {
  return pageType.toLowerCase() === 'form-builder';
}

// handle onDragEnd function
export const handleOnDragEnd = (result, sectionSortArray, dndSections) => {
  const { destination, draggableId, source, type } = result;

  // ***************************** not moved **************************
  if (!destination) {
    return null;
  }

  if (destination.droppableId === source.droppableId && destination.index === source.index) {
    return null;
  }

  // // ***************** moving to Bin and delete it *******************
  if (destination.droppableId === 'bin') {
    if (type === 'container') {
      const { containerId, layoutId, sectionId } = result;

      const updatedDndSections = {
        ...dndSections,
      };

      Object.keys(dndSections).forEach(dndSectionKey => {
        const dndSection = dndSections[dndSectionKey];

        const { containerOrder: containerOrders, containers } = dndSection;

        const updatedContainers = {
          ...containers,
        };

        const containerKey = `container-${containerId}`;

        delete updatedContainers[containerKey];

        updatedDndSections[dndSectionKey] = {
          ...dndSection,
          containerOrder: containerOrders.map(containerOrder => containerOrder !== containerKey),
          containers: updatedContainers,
        };
      });

      return {
        container: {
          delete: {
            containerId,
            layoutId,
            sectionId,
          },
        },
        dndSections: updatedDndSections,
      };
    }

    if (type === 'section') {
      const { sectionId } = result;

      const updatedDndSections = {
        ...dndSections,
      };

      delete updatedDndSections[draggableId];

      return {
        delete: {
          id: sectionId,
        },
        sectionSortArray: Object.keys(updatedDndSections),
      };
    }

    if (type === 'snippet') {
      const { snippetId } = result;

      const updatedDndSections = {
        ...dndSections,
      };

      Object.keys(updatedDndSections).forEach(dndSectionKey => {
        const dndSection = updatedDndSections[dndSectionKey];

        const { containers } = dndSection;

        const updatedContainers = {
          ...containers,
        };

        Object.keys(updatedContainers).forEach(containerKey => {
          const container = updatedContainers[containerKey];

          const { dndSnippets, snippetOrder: snippetOrders, snippets } = container;

          const snippetKey = `snippet-${snippetId}`;

          delete dndSnippets[snippetKey];

          updatedContainers[containerKey] = {
            ...container,
            snippetOrder: snippetOrders.filter(snippetOrder => snippetOrder !== snippetKey),
            snippets: snippets.filter(snippet => snippet.id !== snippetId),
          };
        });

        updatedDndSections[dndSectionKey] = {
          ...dndSection,
          containers: updatedContainers,
        };
      });

      return {
        dndSections: updatedDndSections,
        snippet: {
          delete: {
            id: snippetId,
          },
        },
      };
    }
  }
  // ********************************************************************

  // // // ****************** move section ******************************
  if (type === 'section') {
    const newSectionOrder = Array.from(sectionSortArray);
    newSectionOrder.splice(source.index, 1);
    newSectionOrder.splice(destination.index, 0, draggableId);

    const containers = [];
    dndSections[draggableId].containerOrder.map(item =>
      containers.push({
        id: dndSections[draggableId].containers[item].id,
        sort: dndSections[draggableId].containers[item].sort,
      }),
    );

    return {
      sectionSortArray: newSectionOrder,
      update: {
        id: dndSections[draggableId].id,
        sort: destination.index,
        layoutId: dndSections[draggableId].contentLayoutId,
        containers,
      },
    };
  }

  // // // ****************** move container ****************************
  if (type === 'container') {
    const start = dndSections[source.droppableId];
    const finish = dndSections[destination.droppableId];
    if (start !== finish && Object.keys(finish.containers).length >= 4) {
      return null;
    }

    // // // // ************** in same section **************************
    if (start === finish) {
      const newContainerOrder = Array.from(start.containerOrder);
      let isDropDisabled = false;
      if (start.containerOrder.length === 4) isDropDisabled = true;
      newContainerOrder.splice(source.index, 1);
      newContainerOrder.splice(destination.index, 0, draggableId);

      const newSection = {
        ...start,
        containerOrder: newContainerOrder,
        isDropDisabled,
      };

      const newSections = {
        ...dndSections,
        [newSection.dndId]: newSection,
      };
      return {
        dndSections: newSections,
        container: {
          update: {
            sectionId: start.id,
            containerId: Number(draggableId.substring(draggableId.indexOf('-') + 1)),
            sort: destination.index,
          },
        },
      };
    }
    // // // // ********* move container to different section ***********
    const startContainerOrder = Array.from(start.containerOrder);
    startContainerOrder.splice(source.index, 1);
    // add the dragged container to destionation
    finish.containers[draggableId] = start.containers[draggableId];
    // delete the dragged container from source
    delete start.containers[draggableId];

    // change source layout
    const startLayout = Object.keys(start.containers).length;
    const startLayoutId = updateContainerLayout(startLayout);
    const finishLayout = Object.keys(finish.containers).length;
    const finishLayoutId = updateContainerLayout(finishLayout);

    const startSection = {
      ...start,
      containerOrder: startContainerOrder,
      contentLayoutId: startLayoutId,
    };

    const finishContainerOrder = Array.from(finish.containerOrder);
    finishContainerOrder.splice(destination.index, 0, draggableId);
    const finishSection = {
      ...finish,
      containerOrder: finishContainerOrder,
      contentLayoutId: finishLayoutId,
    };

    const newSections = {
      ...dndSections,
      [startSection.dndId]: startSection,
      [finishSection.dndId]: finishSection,
    };
    return {
      dndSections: newSections,
      container: {
        update: {
          sectionId: finish.id,
          startSectionId: start.id,
          containerId: Number(draggableId.substring(draggableId.indexOf('-') + 1)),
          sort: destination.index,
          startLayoutId,
          finishLayoutId,
        },
      },
    };
  }

  // // // ********************* move snippet ***************************
  if (type === 'snippet') {
    let startSectionId;
    let finishSecionId;
    const id = Number(draggableId.substring(draggableId.indexOf('-') + 1));
    const sort = destination.index;
    const containerId = Number(destination.droppableId.substring(destination.droppableId.indexOf('-') + 1));
    Object.values(dndSections).forEach(value =>
      value.containerOrder.map(elem => {
        if (elem === source.droppableId) startSectionId = value.dndId;
        if (elem === destination.droppableId) finishSecionId = value.dndId;
        return null;
      }),
    );

    const start = dndSections[startSectionId].containers[source.droppableId];
    const finish = dndSections[finishSecionId].containers[destination.droppableId];

    // // // // // **************** in the same container *****************
    if (start === finish) {
      const newsnippetOrder = Array.from(start.snippetOrder);
      newsnippetOrder.splice(source.index, 1);
      newsnippetOrder.splice(destination.index, 0, draggableId);
      const newContainer = {
        ...start,
        snippetOrder: newsnippetOrder,
      };
      const newContainers = {
        ...dndSections[startSectionId].containers,
        [newContainer.dndId]: newContainer,
      };
      const newDndSections = {
        ...dndSections,
        [startSectionId]: {
          ...dndSections[startSectionId],
          containers: newContainers,
        },
      };
      return {
        dndSections: newDndSections,
        snippet: {
          update: {
            id,
            containerId,
            sort,
          },
        },
      };
    }
    // // // // // ************** move to another container ***************
    const startsnippetOrder = Array.from(start.snippetOrder);
    finish.dndSnippets[start.snippetOrder[source.index]] = start.dndSnippets[start.snippetOrder[source.index]];
    finish.snippets.push(start.dndSnippets[start.snippetOrder[source.index]]);
    delete start.dndSnippets[start.snippetOrder[source.index]];
    const startSnippets = start.snippets.filter(elem => elem.dndId !== start.snippetOrder[source.index]);

    startsnippetOrder.splice(source.index, 1);
    const startContainer = {
      ...start,
      snippetOrder: startsnippetOrder,
      snippets: startSnippets,
    };
    const startContainers = {
      ...dndSections[startSectionId].containers,
      [startContainer.dndId]: startContainer,
    };

    const finishsnippetOrder = Array.from(finish.snippetOrder);

    finishsnippetOrder.splice(destination.index, 0, draggableId);
    const finishContainer = {
      ...finish,
      snippetOrder: finishsnippetOrder,
    };

    const finishContainers = {
      ...dndSections[finishSecionId].containers,
      [finishContainer.dndId]: finishContainer,
    };
    // // // // // // ***************************************************
    // *** move sinppet to different container in the same section *** //
    // // // // // // ***************************************************
    if (startSectionId === finishSecionId) {
      const newContainers = {
        ...dndSections[startSectionId].containers,
        [startContainer.dndId]: startContainer,
        [finishContainer.dndId]: finishContainer,
      };
      const newDndSections = {
        ...dndSections,
        [startSectionId]: {
          ...dndSections[startSectionId],
          containers: newContainers,
        },
      };

      return {
        dndSections: newDndSections,
        snippet: {
          update: {
            id,
            containerId,
            sort,
          },
        },
      };
    }
    const newDndSections = {
      ...dndSections,
      [startSectionId]: {
        ...dndSections[startSectionId],
        containers: startContainers,
      },
      [finishSecionId]: {
        ...dndSections[finishSecionId],
        containers: finishContainers,
      },
    };

    return {
      dndSections: newDndSections,
      snippet: {
        update: {
          id,
          containerId,
          sort,
        },
      },
    };
  }
  return null;
};
