import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  ul {
    list-style-type: none;
    padding-left: 10px;
    li {
      &::before {
        margin-right: 5px;
        content: '\\26AC';
        /* this is list icon color */
        color: ${props => props.listIconColor || '#000'};
      }
      color: ${props => props.fontColor || '#000'};
    }
  }
  p {
    color: ${props => props.fontColor || '#000'};
    margin-bottom: 1em;
  }
  a {
    color: ${props => props.linkColor || '#000'};
  }
`;

const TextSnippet = props => {
  const { text, linkColor, listIconColor, fontColor } = props;
  return (
    <Wrapper
      fontColor={fontColor}
      linkColor={linkColor}
      listIconColor={listIconColor}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Wrapper>
  );
};

TextSnippet.propTypes = {
  text: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
  linkColor: PropTypes.string,
  listIconColor: PropTypes.string,
};

TextSnippet.defaultProps = {
  fontColor: null,
  linkColor: null,
  listIconColor: null,
};

export default TextSnippet;
