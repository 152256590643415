import gql from 'graphql-tag';

export const SEARCH_DIRECTORIES = gql`
  query searchDirectories($directoryTypeId: Int, $projectId: String!, $title: String) {
    directory {
      searchDirectories(projectId: $projectId, sorting: ALPHABETIC, title: $title, directoryTypeId: $directoryTypeId) {
        id
        asset {
          assetId
          alt
          contentType
          fileName
          fileSizeInBytes
          s3Key
          type
          presignedUrl
        }
        address {
          city
          country
          line1
          longitude
          latitude
          postcode
          state
          suburb
        }
        files {
          asset {
            assetId
            s3Key
            fileName
            contentType
            presignedUrl
          }
          assetId
        }
        description
        directoryType {
          name
        }
        directoryTypeId
        email
        phoneNumber
        tags
        title
        status
        subTitle
        url
      }
    }
  }
`;
