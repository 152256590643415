import React from 'react';

import { Label, Spacing, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

const Title = ({ characterCount, isRequired = false, title, titleMaxCharacter, handleTitleChange }) => (
  <div className="c-action__item h-flex">
    <Label additionalClassNames="c-action__item__label h-flex" forId="title" labelText="Title" isRequired>
      <Tooltip placement="left" text="The title of the condition" />
    </Label>
    <input
      className="c-action__item__input"
      id="title"
      maxLength={titleMaxCharacter}
      name="title"
      placeholder="Create your title"
      required={isRequired}
      type="text"
      value={title}
      onChange={handleTitleChange}
    />
    <Spacing size="x-small" />
    <span className="c-action__item__input__character-count">{characterCount} characters left</span>
  </div>
);

Title.propTypes = {
  characterCount: PropTypes.number,
  handleTitleChange: PropTypes.func,
  isRequired: PropTypes.bool,
  title: PropTypes.string,
  titleMaxCharacter: PropTypes.number,
};

export { Title };
