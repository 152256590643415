import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_SPACER_SNIPPET = gql`
  mutation updateSpacerSnippet(
    $snippetId: Int!
    $height: Int!
    $lineBreak: Boolean!
    $widthPercentage: Int
    $alignment: EHorizontalAlignment
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId }
        contentSnippetSpacer: {
          height: $height
          lineBreak: $lineBreak
          widthPercentage: $widthPercentage
          alignment: $alignment
        }
      ) {
        id
      }
    }
  }
`;

const updateSpacerSnippetRequest = ({
  snippetId,
  height,
  lineBreak,
  widthPercentage,
  alignment,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={UPDATE_SPACER_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        height,
        lineBreak,
        alignment,
        widthPercentage,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateContentSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateSpacerSnippetRequest.propTypes = {
  height: PropTypes.number.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  lineBreak: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  snippetId: PropTypes.number.isRequired,
  alignment: PropTypes.string,
  widthPercentage: PropTypes.number,
};

updateSpacerSnippetRequest.defaultProps = {
  alignment: 'CENTER',
  widthPercentage: 100,
};

export default updateSpacerSnippetRequest;
