import { useEkardoMutation } from '../useEkardoMutation';

import { DELETE_USER_GENERATED_RESPONSE_MUTATION } from './useDeleteUserGeneratedResponse.graphql';
import {
  IDeleteUserGeneratedResponseMutationGraphQLResponse,
  IDeleteUserGeneratedResponseMutationVariables,
  IHandleDeleteUserGeneratedResponseParams,
  IUseDeleteUserGeneratedResponseOptions,
  IUseDeleteUserGeneratedResponse,
} from './useDeleteUserGeneratedResponse.interfaces';

const useDeleteUserGeneratedResponse = (options?: IUseDeleteUserGeneratedResponseOptions): IUseDeleteUserGeneratedResponse => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseDeleteUserGeneratedResponseOptions);

  const [executeDeleteUserGeneratedResponse, { loading: isLoading }] = useEkardoMutation<
    IDeleteUserGeneratedResponseMutationGraphQLResponse,
    IDeleteUserGeneratedResponseMutationVariables
  >({
    mutation: mutation ?? DELETE_USER_GENERATED_RESPONSE_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionSet: { removeAvailableAnswer: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleDeleteUserGeneratedResponse = async ({
    questionResponseId,
    questionResponseSetId,
  }: IHandleDeleteUserGeneratedResponseParams) => {
    await executeDeleteUserGeneratedResponse({
      variables: {
        questionResponseId,
        questionResponseSetId,
      },
    });
  };

  return {
    handleDeleteUserGeneratedResponse,
    isLoading,
  };
};

export { useDeleteUserGeneratedResponse };
