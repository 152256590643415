import React, { useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Link } from 'react-router-dom';

import ValidationProcessor from '../ErrorMessage/ValidationProcessor';
import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import PopUpRating from '../PopUpRating/PopUpRating';
import EditBtn from '../UI/Button/EditButton';

import {
  errorMessageEnterTimeout,
  errorMessageLeaveTimeout,
} from '../../config';
import GetAsset from '../../middleware/Asset/getAsset';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import RateAppRequest from '../../middleware/MyAppDashboard/rateAppRequest';
import starImage from '../../static/img/star-gray.svg';
import starFilledImage from '../../static/img/star-yellow.svg';

function MyAppCard(props) {
  const {
    title,
    byFrom,
    status,
    url,
    assetId,
    rating,
    identifier,
    onGettingRatingResponse,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [getAssetSent, setGetAssetSent] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [rateAppRequest, setRateAppRequest] = useState(false);
  const [comment, setComment] = useState('');
  const [appRating, setAppRating] = useState('');
  const [showPopupModal, setShowPopupModal] = useState(false);
  const handleAssetError = () => {
    setGetAssetSent(true);
  };
  const validate = () => ({
    AppRatingEmpty: {
      validation: appRating >= 1 && appRating <= 5,
      errorMessage: 'App rating must be between 1 to 5',
    },
    CommentEmpty: {
      validation: comment.trim().length > 0,
      errorMessage: 'Comment must not be empty',
    },
  });
  const handleAssetResponse = (s3Key, contentType) => {
    setGetAssetSent(true);
    setFileUrl(getSignedUrl(s3Key, contentType));
  };
  const starComponent = [];
  for (let i = 1; i <= 5; i += 1) {
    if (i <= Math.round(rating)) {
      starComponent.push(
        <img
          alt="Star"
          className="star"
          key={`star_${i}`}
          src={starFilledImage}
        />
      );
    } else {
      starComponent.push(
        <img alt="Star" className="star" key={`star_${i}`} src={starImage} />
      );
    }
  }
  const togglePopup = () => {
    setShowPopupModal(!showPopupModal);
  };

  const handleInputs = e => {
    const { name, value } = e.target;
    if (name === 'appRating') {
      setAppRating(value);
    } else if (name === 'comment') {
      setComment(value);
    }
  };

  const onSubmitRating = () => {
    const validationErrors = ValidationProcessor(validate());

    if (!validationErrors.modelValid) {
      setErrorMessage(validationErrors.validations);
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    } else {
      setRateAppRequest(true);
    }
  };

  const handleRateAppResponse = () => {
    togglePopup();
    onGettingRatingResponse();
    setRateAppRequest(false);
  };
  const handleRateAppError = message => {
    togglePopup();
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
    setRateAppRequest(false);
  };

  const handleOnClickStars = id => {
    setAppRating(id);
  };

  return (
    <>
      <div className="app-item" key={title}>
        <div className="dropdown">
          <EditBtn />
          <div
            aria-labelledby="dropdownMenuButton"
            className="dropdown-menu"
            style={{ marginTop: '28px', transform: 'translateX(-57%)' }}
          >
            <button className="dropdown-item" type="button">
              Customise
            </button>
            <button className="dropdown-item" type="button">
              Edit code
            </button>
            <button className="dropdown-item" type="button">
              Duplicate
            </button>
            {!rating && (
              <button
                className="dropdown-item"
                onClick={togglePopup}
                type="button"
              >
                Give rating
              </button>
            )}
            <button className="dropdown-item" type="button">
              Delete
            </button>
          </div>
        </div>
        <Link to={url}>
          <div className="app-img">
            <img alt="img" src={fileUrl} />
            <div
              className={classnames(
                'app-badge',
                { 'badge-green': status === 'PUBLISHED' },
                { 'badge-red': status === 'UNPUBLISHED' }
              )}
            >
              {status}
            </div>
          </div>
          <div className="app-content">
            <div className="app-title">{title}</div>
            <div className="app-from">{byFrom}</div>
            <div className="app-rating">
              {starComponent}
              <span>{rating === 0 ? 'No reviews' : rating}</span>
            </div>
          </div>
        </Link>
      </div>
      {showPopupModal ? (
        <PopUpRating
          appRating={appRating}
          closePopup={togglePopup}
          comment={comment}
          handleInputs={handleInputs}
          onClickStar={handleOnClickStars}
          onSubmit={onSubmitRating}
        />
      ) : null}
      {!getAssetSent && (
        <GetAsset
          assetId={assetId}
          onError={handleAssetError}
          onSuccessResult={handleAssetResponse}
        />
      )}
      {rateAppRequest && (
        <RateAppRequest
          appIdentifier={identifier}
          comment={comment}
          onError={handleRateAppError}
          onSuccessResult={handleRateAppResponse}
          rate={appRating}
        />
      )}
      <ReactCSSTransitionGroup
        transitionEnterTimeout={errorMessageEnterTimeout}
        transitionLeaveTimeout={errorMessageLeaveTimeout}
        transitionName="dialog-popup"
      >
        {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
      </ReactCSSTransitionGroup>
    </>
  );
}

MyAppCard.propTypes = {
  assetId: PropTypes.string.isRequired,
  byFrom: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  onGettingRatingResponse: PropTypes.func.isRequired,
  rating: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  status: PropTypes.string,
};

MyAppCard.defaultProps = {
  status: 'UNPUBLISHED',
};

export default MyAppCard;
