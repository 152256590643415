import gql from 'graphql-tag';

export const UPDATE_TEMPLATE = gql`
  mutation(
    $name: String!
    $type: ETemplateType!
    $content: String!
    $templateId: Int!
    $usage: EEmailUsage
    $projectId: String!
    $smsUsage: ESmsUsage
    $subject: String!
  ) {
    templates {
      editTemplate(
        templateId: $templateId
        emailUsage: $usage
        smsUsage: $smsUsage
        template: {
          name: $name
          subject: $subject
          type: $type
          content: $content
          messageType: APPLICATION
          projectId: $projectId
        }
      )
    }
  }
`;
