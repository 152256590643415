import { IconIdType } from '../../types';

const getValidationResult = (
  errorMessage: string | undefined,
  isCorrect: boolean,
  isDirty: boolean
): { iconId: IconIdType | undefined; isValid: boolean | undefined } => {
  const defaultResult = {
    iconId: undefined,
    isValid: true,
  };

  if (!isDirty) {
    return defaultResult;
  }

  const errorIconId: IconIdType = 'id_invalid_icon';
  const errorResult = {
    iconId: errorIconId,
    isValid: false,
  };

  if (errorMessage) {
    return errorResult;
  }

  const successIconId: IconIdType = 'id_success_icon';
  const successResult = {
    iconId: successIconId,
    isValid: true,
  };

  if (isCorrect) {
    return successResult;
  }

  return defaultResult;
};

export { getValidationResult };
