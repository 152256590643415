/* eslint-disable react/boolean-prop-naming */
import React, { useContext } from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

import AppContext from '../../context/AppContext';

export const CREATE_SLIDER = gql`
  mutation CreateSlider(
    $projectId: String!
    $title: String!
    $arrows: Boolean
    $autoplay: Boolean
    $dots: Boolean
    $animation: ESlideAnimation
    $infinite: Boolean
    $slidesToScroll: Int
    $slidesToShow: Int
    $speed: Int
  ) {
    slider {
      createSlider(
        slider: {
          projectId: $projectId
          title: $title
          arrows: $arrows
          autoplay: $autoplay
          dots: $dots
          animation: $animation
          infinite: $infinite
          slidesToScroll: $slidesToScroll
          slidesToShow: $slidesToShow
          speed: $speed
        }
      ) {
        id
      }
    }
  }
`;

const CreateSlider = ({
  title,
  arrows,
  autoplay,
  dots,
  animation,
  infinite,
  slidesToScroll,
  slidesToShow,
  speed,
  onError,
  onSuccessResult,
}) => {
  const {
    project: { id: projectId },
  } = useContext(AppContext);

  return (
    <Mutation mutation={CREATE_SLIDER}>
      {(CreateSliderFunc, { loading, error, data }) => {
        const variables = {
          projectId,
          title,
          arrows,
          autoplay,
          dots,
          animation,
          infinite,
          slidesToScroll,
          slidesToShow,
          speed,
        };

        if (error) {
          return <ErrorModal error={error} onError={onError} />;
        }

        if (data) {
          onSuccessResult(data.slider.createSlider.id);
        }

        return (
          <MutationRequest
            isLoading={loading}
            mutation={CreateSliderFunc}
            variables={variables}
          />
        );
      }}
    </Mutation>
  );
};

CreateSlider.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  animation: PropTypes.string,
  arrows: PropTypes.bool,
  autoplay: PropTypes.bool,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  speed: PropTypes.number,
};

CreateSlider.defaultProps = {
  animation: 'LINEAR',
  arrows: true,
  autoplay: false,
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 2000,
};

export default CreateSlider;
