import React from 'react';

import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Clients from '../../components/Clients/Clients';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import InputHolder from '../../components/InputHolder/InputHolder';
import PopupMessage from '../../components/PopupMessage/PopupMessage';
import Preloader from '../../components/Preloader/Preloader';
import Registration from '../../components/Registration/Registration';
import { getAssetsUrl, errorMessageEnterTimeout, errorMessageLeaveTimeout } from '../../config';
import LoginRequestWithoutRedirection from '../../middleware/Account/loginRequestWithoutRedirection';
import ResendEmailRequest from '../../middleware/Account/resendEmailRequest';
import GetEndpoint from '../../middleware/CustomBuild/getByBackofficeEndpoint';
import AcceptInvitationRequest from '../../middleware/UsersManagement/acceptInvitationRequest';
import AcceptInvitationUserConnectedRequest from '../../middleware/UsersManagement/acceptInvitationRequestConnectedUser';
import GetInvitationDetailRequest from '../../middleware/UsersManagement/getInvitationDetailRequest';
import fbRed from '../../static/img/fb-red.svg';
import twitterRed from '../../static/img/twitter-red.svg';

class AcceptInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      type: '',
      // projectName: '',
      accessToken: '',
      refreshToken: '',
      isLoginSuccess: false,
      entityName: '',
      organisationName: '',
      performLogin: false,
      // groupName: '',
      email: '',
      isUserExists: false,
      projectGuid: '',
      password: '',
      organisationId: null,
      invitedUserId: null,
      repeatPassword: '',
      errorMessage: '',
      endpoint: false,
      checkTerms: false,
      isThankyou: false,
      resendRequest: false,
      emailSent: false,
      getInvitation: true,
      accpetInvitationReq: false,
      // checkExistUserRequest: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  validate = () => {
    const { firstname, lastname, email, password, repeatPassword, checkTerms } = this.state;

    return {
      FirstnameEmpty: {
        validation: firstname.trim().length > 0,
        errorMessage: 'Firstname must not be empty.',
      },
      LastnameEmpty: {
        validation: lastname.trim().length > 0,
        errorMessage: 'Lastname must not be empty.',
      },
      EmailEmpty: {
        validation: email.trim().length > 0,
        errorMessage: 'Email must not be empty.',
      },
      PasswordsMatch: {
        validation: password === repeatPassword,
        errorMessage: 'Passwords do not match.',
      },
      PasswordsMinLength: {
        validation: password.length >= 6,
        errorMessage: 'Password must have minimum 6 chars.',
      },
      PasswordEmpty: {
        validation: password.trim().length > 0,
        errorMessage: 'Password must not be empty.',
      },
      UnCheckedTerms: {
        validation: checkTerms,
        errorMessage: 'You must agree to the terms.',
      },
    };
  };

  onhandleClearErrorMessages = () => {
    this.setState({
      errorMessage: '',
    });
  };

  handleError = () => {};

  handleEndpointSuccess = (data) => {
    const cookies = new Cookies();
    cookies.remove('customBuild', { path: '/' });
    if (data.getByBackofficeEndpoint) {
      cookies.set('customBuild', {
        isCustomBuild: true,
        projectId: data.getByBackofficeEndpoint.id,
        orgId: data.getByBackofficeEndpoint.organisationId,
      });
    }
    this.setState({
      endpoint: true,
    });
  };

  onhandleInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onhandleCheck = () => {
    const { checkTerms } = this.state;
    this.setState({
      checkTerms: !checkTerms,
    });
  };

  onhandleSubmit = (e) => {
    const { match } = this.props;
    const { params } = match;
    const { token } = params;
    e.preventDefault();
    const validationErrors = !token ? ValidationProcessor(this.validate()) : { modelValid: true };

    if (!validationErrors.modelValid) {
      this.setState({
        errorMessage: validationErrors.validations,
      });
    } else if (token) {
      this.setState({
        errorMessage: '',
        accpetInvitationReq: true,
      });
    } else {
      this.setState({
        errorMessage: '',
      });
    }
  };

  onResendButton = () => {
    this.setState({
      resendRequest: true,
    });
  };

  handleEmailSend = () => {
    this.setState({
      emailSent: true,
      resendRequest: false,
    });

    setTimeout(() => {
      this.setState({
        emailSent: false,
      });
    }, 3000);
  };

  handleOnResponseGetInv = (data) => {
    this.setState({
      firstname: data.firstName,
      invitedUserId: data.invitedUserId,
      organisationId: data.organisation.id,
      projectGuid: data.project !== null ? data.project.guid : '',
      // projectName: data.project !== null ? data.project.name : '',
      organisationName: data.organisation !== null ? data.organisation.name : '',
      // groupName: data.group !== null ? data.group.name : '',
      type: data.type,
      isUserExists: data.userExists,
      lastname: data.lastName,
      email: data.invitedUserEmail,
      getInvitation: false,
      // checkExistUserRequest: false,
      isLoading: false,
    });

    this.setState({ entityName: data[data.type.toLowerCase()].name });
  };

  handleOnErrorAcceptInvitation = (msg) => {
    this.setState({
      performLogin: false,
      isLoginSuccess: false,
      errorMessage: msg,
    });
    setInterval(() => {
      this.setState({
        errorMessage: '',
      });
    }, 3000);
  };

  handleOnErrorGetInv = (msg) => {
    this.setState({
      getInvitation: false,
      errorMessage: msg,
    });
    setInterval(() => {
      this.setState({
        errorMessage: '',
      });
    }, 3000);
  };

  handleOnResponseAcceptInv = () => {
    this.setState({
      accpetInvitationReq: false,
      errorMessage: 'Invitation accepted succesfully.',
      isThankyou: true,
    });
    setInterval(() => {
      this.setState({
        errorMessage: '',
      });
    }, 3000);
  };

  handleOnErrorAcceptInv = (msg) => {
    this.setState({
      accpetInvitationReq: false,
      errorMessage: msg,
    });
    setInterval(() => {
      this.setState({
        errorMessage: '',
      });
    }, 3000);
  };

  handleOnResponseCheckExistUser = (isExist) => {
    if (isExist) {
      this.setState({
        accpetInvitationReq: true,
        // checkExistUserRequest: false,
        isLoading: false,
      });
    } else {
      this.setState({
        // checkExistUserRequest: false,
        isLoading: false,
      });
    }
  };

  handleOnErrorCheckExistUser = (msg) => {
    this.setState({
      errorMessage: msg,
      // checkExistUserRequest: false,
    });
    setInterval(() => {
      this.setState({
        errorMessage: '',
      });
    }, 3000);
  };

  render() {
    const {
      firstname,
      lastname,
      endpoint,
      email,
      password,
      organisationId,
      repeatPassword,
      isUserExists,
      errorMessage,
      isLoginSuccess,
      isThankyou,
      resendRequest,
      emailSent,
      organisationName,
      accessToken,
      refreshToken,
      projectGuid,
      getInvitation,
      performLogin,
      entityName,
      type,
      invitedUserId,
      accpetInvitationReq,
      // checkExistUserRequest,
      isLoading,
    } = this.state;

    const { match } = this.props;
    const { params } = match;
    const { token } = params;
    let redirect = '/dashboard';
    if (type === 'PROJECT') redirect = `/dashboard/${organisationId}/project/${projectGuid}`;
    return (
      <>
        <div className="login-page">
          <div className="login-page__fold">
            <Header location="/register" theme="red" />
            <div className="login-page__container">
              {!isThankyou && (
                <h2
                  className="heading-page"
                  style={{
                    fontSize: '3em',
                  }}
                >
                  GET STARTED
                </h2>
              )}
              <h2 className="heading-page">{!isThankyou ? 'TODAY' : 'THANK YOU'}</h2>
            </div>
            <div className="flex-column text-right pr-3 mb-3 d-none d-lg-flex" href="#">
              <div className="mb-2">
                <img alt="fbLogin" height="24px" src={fbRed} width="24px" />
              </div>
              <div className="">
                <img alt="twiterLogin" height="24px" src={twitterRed} width="24px" />
              </div>
            </div>
          </div>

          <div className="login-page__card">
            <div className="login-page__col login-page__image">
              <img alt="Laptop locked" src={`${getAssetsUrl()}/graphics/register.svg`} />
            </div>
            <div className="login-page__col text-center d-flex align-items-center">
              <div className="w-100">
                {invitedUserId !== null && <Redirect to="/login" />}
                {isThankyou && <Redirect to={redirect} />}
                {!isThankyou && isUserExists && (
                  <>
                    <form className="login-page__form">
                      <h2 className="heading-page" style={{ fontSize: '1.5em' }}>
                        Accept invitation
                      </h2>
                      <br />
                      <p>
                        You have been invited to join the {type.toLocaleLowerCase()} {entityName} on {organisationName}
                      </p>
                      <p>To accept the invitation please log-in</p>
                      <br />
                      <InputHolder label="Email" name="email" value={email} isDisabled required />
                      <InputHolder
                        label="Password"
                        name="password"
                        type="password"
                        required
                        onChange={(e) => this.setState({ password: e.target.value })}
                      />
                      <button
                        className="button--outline"
                        style={{ margin: '1em auto' }}
                        type="button"
                        onClick={() => this.setState({ performLogin: true })}
                      >
                        <span>Accept invitation</span>
                      </button>
                    </form>
                  </>
                )}
                {!endpoint && (
                  <GetEndpoint
                    endpoint={window.location.hostname}
                    onError={this.handleError}
                    onSuccessResult={this.handleEndpointSuccess}
                  />
                )}
                {!isThankyou && !isUserExists && (
                  <Registration
                    email={email}
                    firstname={firstname}
                    handleCheck={this.onhandleCheck}
                    handleClearErrorMessages={this.onhandleClearErrorMessages}
                    handleInputs={this.onhandleInputs}
                    handleSubmit={this.onhandleSubmit}
                    lastname={lastname}
                    password={password}
                    repeatPassword={repeatPassword}
                    token={token}
                  />
                )}
                {errorMessage !== '' && <ErrorMessage message={errorMessage} />}
              </div>
            </div>
          </div>
        </div>
        <Clients />
        <Footer />
        {performLogin && (
          <LoginRequestWithoutRedirection
            password={password}
            userName={email}
            onLoginError={this.handleOnErrorAcceptInvitation}
            onSuccess={(data) => {
              this.setState({
                performLogin: false,
                refreshToken: data.login.refreshToken,
                accessToken: data.login.token,
                isLoginSuccess: true,
              });
            }}
          />
        )}
        {isLoginSuccess && (
          <AcceptInvitationUserConnectedRequest
            accessToken={accessToken}
            organisationId={organisationId}
            projectGuid={projectGuid}
            refreshToken={refreshToken}
            tokenId={token}
            type={type}
            onError={this.handleOnErrorAcceptInvitation}
          />
        )}
        {resendRequest && <ResendEmailRequest email={email} onError={this.handleRegisterError} onSuccessResult={this.handleEmailSend} />}
        {
          <ReactCSSTransitionGroup
            transitionEnterTimeout={errorMessageEnterTimeout}
            transitionLeaveTimeout={errorMessageLeaveTimeout}
            transitionName="dialog-popup"
          >
            {emailSent && <PopupMessage message="Email Sent" />}
          </ReactCSSTransitionGroup>
        }
        {getInvitation && token && endpoint && (
          <GetInvitationDetailRequest token={token} onError={this.handleOnErrorGetInv} onSuccessResult={this.handleOnResponseGetInv} />
        )}
        {accpetInvitationReq && (
          <AcceptInvitationRequest
            firstName={firstname}
            lastName={lastname}
            password={password}
            tokenId={token}
            onError={this.handleOnErrorAcceptInv}
            onSuccessResult={this.handleOnResponseAcceptInv}
          />
        )}
        {isLoading && <Preloader />}
      </>
    );
  }
}

AcceptInvitation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default AcceptInvitation;
