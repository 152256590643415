import React from 'react';

import PropTypes from 'prop-types';

import { Wrapper, Title, Description } from './styled';

const AppComponent = props => {
  const { id, title, description, isClicked, isSelected } = props;
  return (
    <Wrapper isSelected={isSelected} onClick={() => isClicked(id)}>
      <Title>{title}</Title>
      <Description>{description || 'No Decription'}</Description>
    </Wrapper>
  );
};

AppComponent.propTypes = {
  id: PropTypes.number.isRequired,
  isClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // eslint-disable-next-line react/boolean-prop-naming
  isSelected: PropTypes.bool,
};

AppComponent.defaultProps = {
  description: '',
  isSelected: false,
};

export default AppComponent;
