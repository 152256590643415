// TODO: Expose more DatePicker props so it can be customized further

import React, { useState } from 'react';

import generatePicker from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { getDisabledDates } from '../DatePickers.helpers';

import { SingleDatePickerProps } from './SingleDatePicker.interfaces';

import '../../../css/netfront-ui-external.min.css';

const SingleDatePicker = ({ maxDate, minDate, onChangeHandler, selectedDate }: SingleDatePickerProps) => {
  const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

  const [currentSavedDate, setCurrentSavedDate] = useState<Date | undefined>(selectedDate);

  const disabledDate = (currentDate: Date) => {
    return getDisabledDates(currentDate, maxDate, minDate);
  };

  const handleSingleDatePickerChange = (date: Date | null) => {
    if (!date) {
      return;
    }

    setCurrentSavedDate(date);

    if (!onChangeHandler) {
      return;
    }

    onChangeHandler(date);
  };

  return (
    <div className="netfront-ui-external-antd">
      <DatePicker
        defaultValue={currentSavedDate}
        disabledDate={disabledDate}
        format="DD/MM/YYYY"
        size="large"
        showToday
        onChange={(value) => handleSingleDatePickerChange(value)}
      />
    </div>
  );
};

export { SingleDatePicker };
