import React, { useState } from 'react';

import { ContentWrapper } from '@netfront/ekardo-shared-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EditIcon from '../../static/img/device/EditMode.svg';
import PCIcon from '../../static/img/device/PC.svg';
import PhoneIcon from '../../static/img/device/Phone.svg';
import TabletIcon from '../../static/img/device/Tablet.svg';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const ToolbarWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 1em;
  width: 100%;
`;

const Emulator = styled.div`
  background: #ebeded 0% 0% no-repeat padding-box;
  border: 1px solid;
  border-radius: 26px;
  box-shadow: 2px 2px 3px #00000029;
  display: ${props => props.device === 'edit' && 'none'};
  height: ${props => (props.device === 'tablet' && '750px') || (props.device === 'phone' && '500px') || '750px'};
  margin-bottom: 2em;
  padding: 2em 1em;
  width: ${props => (props.device === 'tablet' && '560px') || (props.device === 'phone' && '250px') || '1300px'};
`;

const DeviceWrapper = styled.div`
  background-color: #fff;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${props => (props.device === 'phone' ? 'unset' : '0 2em')};

  /* display: ${props => props.device === 'edit' && 'none'}; */
  width: 100%;
`;

const IconBox = styled.img`
  filter: ${props => (props.active ? 'brightness(45%) sepia(100%) hue-rotate(135deg) saturate(800%)' : 'none')};
  height: 100%;
`;

const ModeBtn = styled.button`
  background: transparent;
  border: none;
  height: 2em;
  margin-right: 0.5em;
  padding: 0;
  width: 2em;

  &:focus {
    outline: none;
  }
`;

const Preview = props => {
  const { handleNavigation, editMode, pageId, groupId } = props;

  const [activeItem, setActiveItem] = useState('edit');
  const [customWidth, setCustomWidth] = useState(1268);

  const handleActiveIcon = value => {
    setActiveItem(value);
    if (value === 'phone') setCustomWidth(218);
    else if (value === 'tablet') setCustomWidth(528);
    else setCustomWidth(1268);
    editMode(false);
  };

  const handleEditMode = () => {
    setActiveItem('edit');
    editMode(true);
  };

  return (
    <Wrapper>
      <ToolbarWrapper>
        <ModeBtn onClick={handleEditMode}>
          <IconBox active={activeItem === 'edit'} alt="edit icon" src={EditIcon} />
        </ModeBtn>
        <ModeBtn onClick={() => handleActiveIcon('pc')}>
          <IconBox active={activeItem === 'pc'} alt="pc icon" src={PCIcon} />
        </ModeBtn>
        <ModeBtn onClick={() => handleActiveIcon('tablet')}>
          <IconBox active={activeItem === 'tablet'} alt="tablet icon" src={TabletIcon} />
        </ModeBtn>
        <ModeBtn onClick={() => handleActiveIcon('phone')}>
          <IconBox active={activeItem === 'phone'} alt="phone icon" src={PhoneIcon} style={{ marginLeft: '-1em' }} />
        </ModeBtn>
      </ToolbarWrapper>
      <Emulator device={activeItem}>
        <DeviceWrapper device={activeItem}>
          <ContentWrapper contentGroupId={groupId} mode={activeItem === 'edit'} pageId={pageId} width={customWidth} />
        </DeviceWrapper>
      </Emulator>
    </Wrapper>
  );
};

Preview.propTypes = {
  editMode: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  pageId: PropTypes.number,
};

Preview.defaultProps = {
  pageId: null,
};

export default Preview;
