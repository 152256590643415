import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_QUE_CALENDER_CONFIGURATION = gql`
  mutation createCalenderQuesConfigRequest(
    $projectId: String!
    $description: String!
    $defaultDate: DateTime
    $minDate: DateTime
    $maxDate: DateTime
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationCalendar: {
          defaultDate: $defaultDate
          minDate: $minDate
          maxDate: $maxDate
        }
      ) {
        id
      }
    }
  }
`;

const createCalenderQuesConfigRequest = ({
  projectId,
  description,
  defaultDate,
  minDate,
  maxDate,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_QUE_CALENDER_CONFIGURATION}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        projectId,
        description,
        defaultDate,
        minDate,
        maxDate,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.questionConfiguration.createQuestionConfiguration.id
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createCalenderQuesConfigRequest.propTypes = {
  defaultDate: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

createCalenderQuesConfigRequest.defaultProps = {};

export default createCalenderQuesConfigRequest;
