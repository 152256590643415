import gql from 'graphql-tag';

export const EXPORT_ORDERS = gql`
  query exportOrders($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime, $dateTo: DateTime) {
    reportQueries {
      orders(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo) {
        contentType
        s3Key
      }
    }
  }
`;
