import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

//   ACTIVATE_ACCOUNT,
// } from '../../middleware/Account/activateAccountRequest';
import GeladaClient from '../../middleware/_Gelada/geladaClient';
import { ACTIVATE_ACCOUNT } from '../../middleware/Account/activateAccountRequest';
import client from '../../middleware/client';
import { ENABLE_USER } from '../../middleware/User/enableUser';

function ActivatePage(props) {
  // eslint-disable-next-line no-unused-vars
  const [activateAccount, { called, loading, data, error }] = useMutation(ACTIVATE_ACCOUNT, { client: GeladaClient(false) });
  const [enableAccount] = useMutation(ENABLE_USER, { client });
  const [userMustEnable, setUserMustEnable] = useState(true);
  const [userEnabled, setUserEnabled] = useState(false);

  const {
    match: { params },
  } = props;
  const { token } = params;

  if (userMustEnable === true) {
    setUserMustEnable(false);
    activateAccount({ variables: { code: token } }).then((c) => {
      const cookies = new Cookies();
      cookies.set('_a', c.data.activateAccount.token, {
        path: '/',
      });
      cookies.set('REFRESH_TOKEN', c.data.activateAccount.refreshToken, {
        path: '/',
      });
      enableAccount().then(() => {
        setUserEnabled(true);
      });
    });
  }

  return <>{userEnabled && <Redirect to="/dashboard" />}</>;
}

ActivatePage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({}),
};

ActivatePage.defaultProps = {
  location: undefined,
};

export default ActivatePage;
