import gql from 'graphql-tag';

export const FRAGMENT_SINGLELINE = gql`
  fragment singleTextConf on SingleText {
    singleText: description
    id
    maxLength
    minLength
    regex
    messages {
      correct
      incomplete
      incorrect
    }
    __typename
  }
`;
