import React from 'react';

import PropTypes from 'prop-types';

import { Option, Select, Wrapper } from './styled';

const Dropdown = (props) => {
  const { availableResponses, isDisabled = false, name, onChangeHandler, selectedValue = '' } = props;

  const handleChange = (event) => {
    if (!onChangeHandler) {
      return;
    }

    const { target } = event;
    const { selectedIndex } = target;
    const { id, value } = target[selectedIndex];

    onChangeHandler(id, value, event);
  };

  const dropdownOptions = availableResponses.map((item) => {
    const { id, label, value } = item;

    return (
      <Option key={`${name}-${id}`} id={id} value={value}>
        {label}
      </Option>
    );
  });

  return (
    <Wrapper>
      <Select disabled={isDisabled} name={name} value={selectedValue} onChange={(event) => handleChange(event)}>
        {!selectedValue && <Option label="Select an option" />}
        {dropdownOptions}
      </Select>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  availableResponses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Dropdown;
