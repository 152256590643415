import { gql } from '@apollo/client';

const CHECK_USER_AUTHORIZATION_BY_USER_ID_QUERY = gql`
  query checkUserAuthorizationByUserId($userId: Int!) {
    user {
      checkUserAuthorizationByUserId(userId: $userId)
    }
  }
`;

export { CHECK_USER_AUTHORIZATION_BY_USER_ID_QUERY };
