import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_QUE_SINGLE_TEXT_CONFIGURATION = gql`
  mutation createSingleTextQuesConfigRequest(
    $projectId: String!
    $description: String!
    $maxLength: Int!
    $minLength: Int!
    $regex: String!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationSinglelineText: {
          maxLength: $maxLength
          minLength: $minLength
          regex: $regex
        }
      ) {
        id
      }
    }
  }
`;

const createSingleTextQuesConfigRequest = ({
  projectId,
  description,
  maxLength,
  minLength,
  regex,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_QUE_SINGLE_TEXT_CONFIGURATION}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        projectId,
        description,
        maxLength,
        minLength,
        regex,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.questionConfiguration.createQuestionConfiguration.id
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createSingleTextQuesConfigRequest.propTypes = {
  description: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  minLength: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  regex: PropTypes.string.isRequired,
};

createSingleTextQuesConfigRequest.defaultProps = {};

export default createSingleTextQuesConfigRequest;
