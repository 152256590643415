/* eslint-disable @typescript-eslint/indent */

import {
  createAccessTokenCookie,
  createCookie,
  createCustomBuildProjectGuidCookie,
  createLastProjectVisitedCookie,
  createLastUrlBeforeAuthenticationExpiredCookie,
  createOrganisationIdCookie,
  createProjectGuidCookie,
  createProjectLogoUrlCookie,
  createProjectNameCookie,
  createRedirectAfterLoginUrlCookie,
  createRefreshTokenCookie,
  createTemporaryTokenCookie,
  createUserDataCookie,
  deleteAccessTokenCookie,
  deleteCookie,
  deleteCustomBuildProjectGuidCookie,
  deleteLastProjectVisitedCookie,
  deleteLastUrlBeforeAuthenticationExpiredCookie,
  deleteOrganisationIdCookie,
  deleteProjectGuidCookie,
  deleteProjectLogoUrlCookie,
  deleteProjectNameCookie,
  deleteRedirectAfterLoginUrlCookie,
  deleteRefreshTokenCookie,
  deleteTemporaryTokenCookie,
  deleteUserDataCookie,
  deserializeCookie,
  getAccessTokenCookie,
  getAllCookies,
  getCookieValue,
  getCustomBuildProjectGuidCookie,
  getLastProjectVisitedCookie,
  getLastUrlBeforeAuthenticationExpiredCookie,
  getOrganisationIdCookie,
  getProjectGuidCookie,
  getProjectLogoUrlCookie,
  getProjectNameCookie,
  getRedirectAfterLoginUrlCookie,
  getRefreshTokenCookie,
  getTemporaryTokenCookie,
  getUserDataCookie,
  serializeCookie,
  setCookie,
} from './useCookie.helpers';
import { IUseCookie } from './useCookie.interfaces';

const useCookie = (): IUseCookie => {
  return {
    createAccessTokenCookie,
    createCookie,
    createCustomBuildProjectGuidCookie,
    createLastProjectVisitedCookie,
    createLastUrlBeforeAuthenticationExpiredCookie,
    createOrganisationIdCookie,
    createProjectGuidCookie,
    createProjectLogoUrlCookie,
    createProjectNameCookie,
    createRedirectAfterLoginUrlCookie,
    createRefreshTokenCookie,
    createTemporaryTokenCookie,
    createUserDataCookie,
    deleteAccessTokenCookie,
    deleteCookie,
    deleteCustomBuildProjectGuidCookie,
    deleteLastProjectVisitedCookie,
    deleteLastUrlBeforeAuthenticationExpiredCookie,
    deleteOrganisationIdCookie,
    deleteProjectGuidCookie,
    deleteProjectLogoUrlCookie,
    deleteProjectNameCookie,
    deleteRedirectAfterLoginUrlCookie,
    deleteRefreshTokenCookie,
    deleteTemporaryTokenCookie,
    deleteUserDataCookie,
    deserializeCookie,
    getAccessTokenCookie,
    getAllCookies,
    getCookieValue,
    getCustomBuildProjectGuidCookie,
    getLastProjectVisitedCookie,
    getLastUrlBeforeAuthenticationExpiredCookie,
    getOrganisationIdCookie,
    getProjectGuidCookie,
    getProjectLogoUrlCookie,
    getProjectNameCookie,
    getRedirectAfterLoginUrlCookie,
    getRefreshTokenCookie,
    getTemporaryTokenCookie,
    getUserDataCookie,
    serializeCookie,
    setCookie,
  };
};

export { useCookie };
