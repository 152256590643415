import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, FlexVertical } from '../../pages/styles';

const Avatar = styled.div`
  align-items: center;
  background: transparent linear-gradient(65deg, #4bc1c9 0%, #4bc1d9 68%);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.6rem;
  font-weight: bold;
  height: 3.25rem;
  justify-content: center;
  margin: 0 0.6rem 0 0;
  width: 3.25rem;
`;

const Container = styled.div`
  display: none;

  @media (min-width: 415px) {
    align-items: center;
    display: flex;
    margin-top: 1.5rem;
  }
`;

const BreadCrumbsContainer = styled(Flex)`
  font-size: 1rem;
`;

const Header = styled(Flex)`
  font-size: 1.3rem;
  font-weight: bold;
`;

const Breadcrumb = props => {
  const { breads, title } = props;

  const breadCrumbs = breads.map((item, index) => (
    <div key={`breadcrumb-${index}`}>
      <Link to={item.path || '#'}>
        <span>{item.title}</span>
      </Link>
      {index < breads.length - 1 && <span>&nbsp;&gt;&nbsp;</span>}
    </div>
  ));

  return (
    <Container>
      <Avatar>{title.substring(0, 1).toUpperCase()}</Avatar>
      <FlexVertical>
        {Boolean(breadCrumbs.length) && <BreadCrumbsContainer>{breadCrumbs}</BreadCrumbsContainer>}
        <Header>{title}</Header>
      </FlexVertical>
    </Container>
  );
};

Breadcrumb.propTypes = {
  breads: PropTypes.instanceOf(Array),
  title: PropTypes.string,
};

Breadcrumb.defaultProps = {
  breads: [],
  title: '',
};

export default Breadcrumb;
