import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: #bfbfbf 1px solid;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  line-height: 25px;
  cursor: pointer;
  font-size: 1em;
  user-select: none;
    &:hover {
        ${Input} ~ ${Checkmark} {
  background-color: #ccc;
}
    }
    ${Input}:checked ~ ${Checkmark} {
  background-color: #4bc2ca;
}

${Input}:checked ~ ${Checkmark}:after {
  display: block;
}

${Checkmark}:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

const CheckBox = props => {
  const { htmlFor, children, name, value, isChanged, isChecked } = props;
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (isChecked) {
      document.getElementById(htmlFor).defaultChecked = true;
      setCheck(true);
    }
  }, [isChecked, htmlFor]);

  const handleChecked = () => {
    setCheck(!check);
    isChanged(!check);
  };
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <Label htmlFor={htmlFor}>
      {children}
      <Input
        id={htmlFor}
        name={name}
        onClick={handleChecked}
        type="checkbox"
        value={value}
      />
      <Checkmark />
    </Label>
  );
};

CheckBox.propTypes = {
  children: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChanged: PropTypes.func,
  isChecked: PropTypes.bool,
};

CheckBox.defaultProps = {
  isChanged: () => {},
  isChecked: false,
};

export default CheckBox;
