import gql from 'graphql-tag';

export const GET_USERFLOW_STEPS = gql`
  query($projectId: String!) {
    userFlowStep {
      getStepsOfProject(projectId: $projectId) {
        id
        stepName
      }
    }
  }
`;
