declare global {
  interface Number {
    formatWithThousandsSeparator: (decimalPlaces?: number) => string;
  }
}

Number.prototype.formatWithThousandsSeparator = function (decimalPlaces = 2): string {
  const numberParts = this.toFixed(decimalPlaces).toString().split('.');
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numberParts.join('.');
};

export {};
