import React, { useEffect, useRef, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';

import { DEFAULT_FILTER_DATES, USER_TABLE_COLUMNS } from '../Moderation.constants';
import { getUserTableData } from '../Moderation.handlers';

import { useSocialState } from '../../../../context/SocialPage/SocialPageContext';
import { useApolloClient } from '../../../../middleware/apollo/useApolloClient';
import { GET_USERS } from '../../../../middleware/Social/getUsers';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/utils';
import Preloader from '../../../Preloader/Preloader';
import { SideBar } from '../../../SideBar/SideBar';
import { UserPosts } from '../../../SideBar/Views/UserPosts';
import { EKReactTable } from '../../../Table/EKReactTable/EKReactTable';

const UsersTable = () => {
  const client = useRef(useApolloClient('bonobo')).current;

  const [pageState, dispatch] = useSocialState();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [filterDates, setFilterDates] = useState(DEFAULT_FILTER_DATES);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilteringDisabled, setIsFilteringDisabled] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isPaginationDisabled, setIsPaginationDisabled] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [lastUserCursor, setLastUserCursor] = useState(undefined);
  const [nextFetchedUsers, setNextFetchedUsers] = useState(undefined);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [totalPosts, setTotalPosts] = useState(0);

  const [getUsers, { fetchMore, loading: isLoading }] = useLazyQuery(GET_USERS, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const {
        user: {
          getUsers: { edges: userEdges, totalCount },
        },
      } = data;

      if (totalCount === 0) {
        setLastUserCursor(undefined);
        setIsPaginationDisabled(true);
        setTotalPosts(totalCount);

        return;
      }

      const users = userEdges.map((userEdge) => userEdge.node);
      const { cursor } = userEdges[userEdges.length - 1];

      setLastUserCursor(cursor);
      setIsPaginationDisabled(users.length >= totalCount);
      setTotalPosts(totalCount);

      dispatch({
        newState: users,
        type: 'setUsers',
      });
    },
    onError() {
      setErrorMessage('Something went wrong! Please try again later.');
      setIsFilteringDisabled(true);
      setIsPaginationDisabled(true);
    },
  });

  const handleApplyFilters = (filters) => {
    const { endDate, searchInputValue: message, startDate } = filters;

    getUsers({
      variables: {
        first: pageSize,
        from: startDate,
        message: message.toLowerCase(),
        to: endDate,
      },
    });

    setIsFilterApplied(true);
  };

  const handleClearFilters = () => {
    getUsers({
      variables: {
        first: pageSize,
        from: DEFAULT_FILTER_DATES.startDate,
        to: DEFAULT_FILTER_DATES.endDate,
      },
    });

    setFilterDates(DEFAULT_FILTER_DATES);
    setIsFilterApplied(false);
    setSearchInputValue('');
  };

  const handleFilterDateChange = (dates) => {
    setFilterDates(dates);
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    setNextFetchedUsers(undefined);

    const data = await fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          user: {
            getUsers: { edges: newUsers },
          },
        } = fetchMoreResult;

        if (!newUsers.length) {
          return previousQueryResult;
        }

        const { user: previousQueryResultUser } = previousQueryResult;
        const { getUsers: previousQueryResultGetUsers } = previousQueryResultUser;
        const { edges: previousUsers } = previousQueryResultGetUsers;

        const allFetchedUsers = [...previousUsers, ...newUsers];

        return {
          ...previousQueryResult,
          user: {
            ...previousQueryResultUser,
            getUsers: {
              ...previousQueryResultGetUsers,
              edges: allFetchedUsers,
            },
          },
        };
      },
      variables: {
        after: lastUserCursor,
        first: pageSize,
        isPrevious: false,
      },
    });

    const {
      data: {
        user: {
          getUsers: { edges: currentUserEdges },
        },
      },
    } = data;

    const currentFetchedUsers = currentUserEdges.map((currentPostEdge) => currentPostEdge.node);
    const allFetchedUsers = [...pageState.users, ...currentFetchedUsers];

    setIsLoadingMore(false);
    setIsPaginationDisabled(allFetchedUsers.length >= totalPosts);
    setLastUserCursor(currentUserEdges[currentUserEdges.length - 1].cursor);
    setNextFetchedUsers(currentFetchedUsers);

    dispatch({
      newState: allFetchedUsers,
      type: 'setUsers',
    });
  };

  const handleSearchInputChange = (value) => {
    setSearchInputValue(value);
  };

  const handleSideBarClose = () => {
    dispatch({
      newState: null,
      type: 'setActiveUserId',
    });
    setIsSideBarOpen(false);
  };

  const handleSideBarOpen = (userId) => {
    dispatch({
      newState: userId,
      type: 'setActiveUserId',
    });
    setIsSideBarOpen(true);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
  };

  useEffect(() => {
    getUsers({
      variables: {
        first: pageSize,
        from: filterDates.startDate,
        to: filterDates.endDate,
      },
    });
  }, [getUsers, pageSize]);

  const { users } = pageState;

  if (isLoading || !users || (isLoadingMore && !nextFetchedUsers)) {
    return <Preloader />;
  }

  const filter = {
    date: {
      dates: filterDates,
      onDateChangeHandler: handleFilterDateChange,
    },
    isApplied: isFilterApplied,
    isDisabled: isFilteringDisabled,
    onApplyFiltersHandler: handleApplyFilters,
    onClearFiltersHandler: handleClearFilters,
    searchInput: {
      onSearchInputChangeHandler: handleSearchInputChange,
      searchInputValue,
    },
  };

  const pagination = {
    isDisabled: isPaginationDisabled,
    onLoadMoreHandler: handleLoadMore,
    onPageSizeChangeHandler: handlePageSizeChange,
    pageSize,
  };

  const sideBarTabViews = [
    {
      component: <UserPosts onCloseHandler={handleSideBarClose} />,
      label: 'Users',
      id: 0,
    },
  ];

  return (
    <>
      <EKReactTable
        columns={USER_TABLE_COLUMNS}
        data={getUserTableData(users, (userId) => handleSideBarOpen(userId))}
        errorMessage={errorMessage}
        filter={filter}
        pagination={pagination}
      />
      <SideBar isOpen={isSideBarOpen} selectedTabId={0} tabViews={sideBarTabViews} onCloseHandler={handleSideBarClose} />
    </>
  );
};

export { UsersTable };
