import { BYTES_IN_KILOBYTE, HOURS_PER_DAY, MILLISECONDS_PER_SECOND, MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from './number.constants';

const getGigabytesFromBytes = (value: number): number => {
  return value / Math.pow(BYTES_IN_KILOBYTE, 3);
};

const getMegabytesFromBytes = (value: number): number => {
  return value / Math.pow(BYTES_IN_KILOBYTE, 2);
};

const getTimeInMillisecondsFromSeconds = (value: number): number => {
  return value * MILLISECONDS_PER_SECOND;
};

const getTimeInMillisecondsFromMinutes = (value: number): number => {
  return value * getTimeInMillisecondsFromSeconds(SECONDS_PER_MINUTE);
};

const getTimeInMillisecondsFromHours = (value: number): number => {
  return value * getTimeInMillisecondsFromMinutes(MINUTES_PER_HOUR);
};

const getTimeInMillisecondsFromDays = (value: number): number => {
  return value * getTimeInMillisecondsFromHours(HOURS_PER_DAY);
};

export {
  getGigabytesFromBytes,
  getMegabytesFromBytes,
  getTimeInMillisecondsFromDays,
  getTimeInMillisecondsFromHours,
  getTimeInMillisecondsFromMinutes,
  getTimeInMillisecondsFromSeconds,
};
