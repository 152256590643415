import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import Clients from '../../components/Clients/Clients';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResetConfirmation from '../../components/ResetConfirmation/ResetConfirmation';
import LoginUser from '../../containers/LoginUser/LoginUser';
import fbRed from '../../static/img/fb-red.svg';
import twitterRed from '../../static/img/twitter-red.svg';

function ForgotPasswordPage() {
  const [isThankyou, setIsThankyou] = useState(false);

  const handleSuccessResult = () => {
    setIsThankyou(true);
  };

  return (
    <div>
      <Helmet>
        <title>
          Ekardo: Building accessible and engaging educational content better
        </title>
        <meta
          content="Login to Ekardo to access and manage your projects"
          name="description"
        />
      </Helmet>

      <div className="login-page">
        <div className="login-page__fold">
          <Header theme="red" />
          <div className="login-page__container">
            {isThankyou ? (
              <h2 style={{ fontSize: '3em' }}>THANK YOU</h2>
            ) : (
              <>
                <h2 className="heading-page" style={{ fontSize: '3em' }}>
                  RESET
                </h2>
                <h2 className="heading-page">PASSWORD</h2>
              </>
            )}
          </div>
          <div
            className="flex-column text-right pr-3 mb-3 d-none d-lg-flex"
            href="#"
          >
            <div className="mb-2">
              <img alt="fbLogin" height="24px" src={fbRed} width="24px" />
            </div>
            <div className="">
              <img
                alt="twiterLogin"
                height="24px"
                src={twitterRed}
                width="24px"
              />
            </div>
          </div>
        </div>
        <div className="login-page__card">
          <div className="login-page__col login-page__image">
            <img
              alt="Laptop locked"
              src="https://assets.kanzi.io/images/login.svg"
              // src={`${getAssetsUrl()}graphics/login.svg`}
            />
          </div>
          <div
            className="login-page__col d-flex align-items-center text-center flex-column"
            style={{ backgroundColor: '#fff' }}
          >
            {isThankyou ? (
              <ResetConfirmation />
            ) : (
              <LoginUser
                isLogin={false}
                onResetPassword={handleSuccessResult}
              />
            )}
          </div>
        </div>
      </div>
      <Clients />
      <Footer />
    </div>
  );
}

export default ForgotPasswordPage;
