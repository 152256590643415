import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInput = styled.input.attrs(({ type }) => ({
  type,
}))`
  display: block;
  width: 100%;
  background: #fff;
  line-height: 1rem;
  border: 1px solid #e4e4e3;
  border-radius: 5px;
  margin: 6px 0 1em 0;
  padding: 0.8em;
  overflow: visible;
  font-family: calibri, Arial, sans-serif;
  font-size: inherit;
  &:focus {
    outline: none;
    background: '#fff';
    border: 1px solid ${props => props.focusedBorderColor ?? '#lightgray'};
  }
`;

const InputWhite = props => {
  const {
    isAddressChanged,
    isChanged,
    isDisabled,
    isSubmit,
    name,
    placeholder,
    style,
    focusedBorderColor,
    text,
    onChange,
    id,
    type,
  } = props;

  const [typed, setTyped] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleText = e => {
    setTyped(true);
    setValue(e.target.value);
    isAddressChanged(e.target.value);
  };

  const handleSubmit = e => {
    e.persist();
    onChange(e);
    isChanged(value, e);
  };

  const handlePressEnter = e => {
    if (e.key === 'Enter') isSubmit();
  };

  return (
    <StyledInput
      disabled={isDisabled}
      id={id}
      name={name}
      onBlur={e => handleSubmit(e)}
      onChange={e => handleText(e)}
      onKeyPress={e => handlePressEnter(e)}
      placeholder={placeholder}
      type={type}
      focusBorderColor={focusedBorderColor}
      style={{ ...style }}
      value={typed ? value : text}
      type={type}
    />
  );
};

InputWhite.propTypes = {
  id: PropTypes.string.isRequired,
  isAddressChanged: PropTypes.func,
  isChanged: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSubmit: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
};

InputWhite.defaultProps = {
  isAddressChanged: () => {},
  isChanged: () => {},
  isDisabled: false,
  isSubmit: () => {},
  name: '',
  onChange: () => {},
  placeholder: '',
  style: {},
  text: '',
  type: 'text',
};

export default InputWhite;
