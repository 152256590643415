import toast from 'react-hot-toast';

import { getToastErrorMessages } from './useToast.helpers';

const useToast = () => {
  const handleToastError = ({ error, shouldUseFriendlyErrorMessage = false }) => {
    getToastErrorMessages({
      error,
      shouldUseFriendlyErrorMessage,
    }).forEach((errorMessage) => toast.error(errorMessage));
  };

  const handleToastSuccess = ({ durationInMilliseconds: duration, message }) => {
    toast.success(message, {
      duration,
    });
  };

  return {
    handleToastError,
    handleToastSuccess,
  };
};

export { useToast };
