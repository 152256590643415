import React, { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getS3SignedUrl, IDBAsset } from '@netfront/common-library';
import { ButtonIconOnly, CheckboxGroup, ICheckboxItem, IMessage } from '@netfront/ui-library';
import to from 'await-to-js';

import { DEFAULT_API_ERROR_MESSAGE, DEFAULT_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { SHOULD_HIDE_UNSELECTED_CHECKBOXES_IN_SUMMARY } from './QuestionAnswerCheckboxSnippet.constants';
import { QuestionAnswerCheckboxSnippetProps } from './QuestionAnswerCheckboxSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerCheckboxMutationVariables,
  ISetUserAnswerMutationGraphQLResponse,
  useContentPageContext,
} from '../../../../contexts';
import { ERROR_MESSAGES } from '../../../../core';
import { useDeleteUserGeneratedResponse, useEkardoMutation } from '../../../../hooks';
import {
  IContentPage,
  IDBQuestionResponse,
  IQuestionAnswerCheckbox,
  IQuestionAnswerCheckboxResponseCheckbox,
  IQuestionConfigurationCheckbox,
} from '../../../../interfaces';
import { IUserGeneratedResponseOnSaveCompletedResponse, UserGeneratedResponse } from '../../../UserGeneratedResponse';
import { getMutationBaseVariables } from '../../Snippets.helpers';

const QuestionAnswerCheckboxSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  userFlowStepTrackId,
}: QuestionAnswerCheckboxSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false } = contentSnippet ?? {};
  const { selectedAnswers = [] } = answer ?? ({} as IQuestionAnswerCheckbox);
  const {
    allowUserGeneratedResponses: shouldAllowUserGeneratedResponses,
    responseSet: { availableResponses, id: questionResponseSetId, title = '' },
  } = configuration ?? ({} as IQuestionConfigurationCheckbox);

  const [checkboxItems, setCheckboxItems] = useState<ICheckboxItem[]>([]);
  const [deletedQuestionResponseCheckboxId, setDeletedQuestionResponseCheckboxId] = useState<number>();
  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);
  const [savedAvailableResponses, setSavedAvailableResponses] = useState<IDBQuestionResponse[]>(availableResponses);
  const [savedQuestionResponseCheckboxIds, setSavedQuestionResponseCheckboxIds] = useState<number[]>(
    selectedAnswers.map((selectedAnswer) => selectedAnswer.questionResponseCheckboxId)
  );
  const [savedSelectedAnswers, setSavedSelectedAnswers] = useState<IQuestionAnswerCheckboxResponseCheckbox[]>(selectedAnswers);

  const [executeSetUserAnswerCheckbox] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerCheckboxMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerCheckbox,
    options: {
      onCompleted: (data) => {
        const {
          questionAnswer: {
            setUserAnswer: { id: questionAnswerCheckboxId },
          },
        } = data;

        const updatedSelectedAnswers: IQuestionAnswerCheckboxResponseCheckbox[] = [
          ...savedQuestionResponseCheckboxIds.map(
            (savedQuestionResponseCheckboxId) =>
              ({
                questionAnswerCheckboxId,
                questionResponseCheckboxId: savedQuestionResponseCheckboxId,
              } as IQuestionAnswerCheckboxResponseCheckbox)
          ),
        ];

        setSavedSelectedAnswers(updatedSelectedAnswers);

        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          availableResponses: savedAvailableResponses,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            checkboxSelectedAnswers: updatedSelectedAnswers,
          },
        });
      },
      onError: (error) => {
        handleApolloError(error);
        setMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const handleAddUserGeneratedResponseSaveCompleted = (data: IUserGeneratedResponseOnSaveCompletedResponse) => {
    if (!contentSnippetFormId) {
      return;
    }

    const { updatedAvailableResponses } = data;

    const currentQuestionResponseIds = availableResponses.map(({ id }) => id);

    setSavedAvailableResponses(updatedAvailableResponses);

    const newAvailableResponseIds = updatedAvailableResponses
      .filter(({ id }) => !currentQuestionResponseIds.includes(id))
      .map(({ id }) => id);

    const allSavedQuestionResponseCheckboxIds = [...savedQuestionResponseCheckboxIds, ...newAvailableResponseIds];

    setSavedQuestionResponseCheckboxIds(allSavedQuestionResponseCheckboxIds);

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    executeSetUserAnswerCheckbox({
      variables: {
        ...baseVariables,
        selectedAnswers: allSavedQuestionResponseCheckboxIds.map((questionResponseCheckboxId) => ({
          questionResponseCheckboxId,
        })),
      },
    });
  };

  const handleCheckboxChange = (inputValues: string[]) => {
    if (!contentSnippetFormId) {
      return;
    }

    const convertedQuestionResponseCheckboxIds = inputValues.map((inputValue) => Number(inputValue));

    setSavedQuestionResponseCheckboxIds(convertedQuestionResponseCheckboxIds);

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    executeSetUserAnswerCheckbox({
      variables: {
        ...baseVariables,
        selectedAnswers: [
          ...convertedQuestionResponseCheckboxIds.map((convertedQuestionResponseCheckboxId) => ({
            questionResponseCheckboxId: convertedQuestionResponseCheckboxId,
          })),
        ],
      },
    });
  };

  const handleDeleteUserGeneratedResponseCompleted = () => {
    const updatedSavedAvailableResponses = savedAvailableResponses.filter(({ id }) => id !== deletedQuestionResponseCheckboxId);
    const updatedSavedQuestionResponseCheckboxIds = savedQuestionResponseCheckboxIds.filter(
      (savedQuestionResponseCheckboxId) => savedQuestionResponseCheckboxId !== deletedQuestionResponseCheckboxId
    );
    const updatedSelectedAnswers = savedSelectedAnswers.filter(
      ({ questionResponseCheckboxId }) => questionResponseCheckboxId !== deletedQuestionResponseCheckboxId
    );

    setSavedAvailableResponses(updatedSavedAvailableResponses);
    setSavedQuestionResponseCheckboxIds(updatedSavedQuestionResponseCheckboxIds);
    setSavedSelectedAnswers(updatedSelectedAnswers);

    saveContentSnippetQuestionAndApplyQuestionActions({
      actions,
      answer,
      availableResponses: updatedSavedAvailableResponses,
      contentPage,
      contentSnippetId,
      dispatch,
      selectedQuestionAnswers: {
        checkboxSelectedAnswers: updatedSelectedAnswers,
      },
    });
  };

  const handleDeleteUserGeneratedResponseError = (error: ApolloError) => {
    handleApolloError(error);
    setMessage({
      error: DEFAULT_API_ERROR_MESSAGE,
      success: '',
    });
  };

  const { handleDeleteUserGeneratedResponse } = useDeleteUserGeneratedResponse({
    onCompleted: handleDeleteUserGeneratedResponseCompleted,
    onError: handleDeleteUserGeneratedResponseError,
  });

  useEffect(() => {
    if (!savedSelectedAnswers.length) {
      return;
    }

    saveContentSnippetQuestionAndApplyQuestionActions({
      actions,
      answer,
      contentPage,
      contentSnippetId,
      dispatch,
      selectedQuestionAnswers: {
        checkboxSelectedAnswers: savedSelectedAnswers,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkboxItemsPromises = savedAvailableResponses
      .filter(({ id }) =>
        Boolean(contentSnippetQuestion) && SHOULD_HIDE_UNSELECTED_CHECKBOXES_IN_SUMMARY
          ? savedQuestionResponseCheckboxIds.includes(id)
          : true
      )
      .map(async (availableResponse): Promise<ICheckboxItem> => {
        const { asset, id, label = '', userId } = availableResponse;
        const { contentType, s3Key } = asset ?? ({} as IDBAsset);

        const idAsString = id.toString();

        let signedUrl;

        if (s3Key) {
          const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

          if (getS3SignedUrlError) {
            throw getS3SignedUrlError;
          }

          if (!s3SignedUrl) {
            throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
          }

          signedUrl = s3SignedUrl;
        }

        const deleteButton = userId ? (
          <div className="checkbox-item-delete-button-container">
            <ButtonIconOnly
              iconId="id_bin_icon"
              text="Delete"
              onClick={() => {
                setDeletedQuestionResponseCheckboxId(id);

                handleDeleteUserGeneratedResponse({
                  questionResponseId: id,
                  questionResponseSetId,
                });
              }}
            />
          </div>
        ) : null;

        return {
          deleteButton,
          id: idAsString,
          imageSrc: signedUrl,
          labelText: label,
          value: idAsString,
        };
      });

    const loadCheckboxItems = async () => {
      setCheckboxItems(await Promise.all(checkboxItemsPromises));
    };

    loadCheckboxItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAvailableResponses]);

  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <React.Fragment>
      <CheckboxGroup
        errorMessage={message.error}
        isDisabled={isSummary}
        isRequired={isMandatory}
        items={checkboxItems}
        legendText={title}
        name={`checkbox-group-${contentSnippetId}`}
        values={savedQuestionResponseCheckboxIds.map((savedQuestionResponseCheckboxId) => String(savedQuestionResponseCheckboxId))}
        isLabelHidden
        onChange={(values: string[]) => handleCheckboxChange(values)}
      />
      {contentSnippet && !isSummary && shouldAllowUserGeneratedResponses && SHOULD_HIDE_UNSELECTED_CHECKBOXES_IN_SUMMARY && (
        <UserGeneratedResponse
          accessToken={accessToken}
          contentSnippetId={contentSnippet.id}
          currentAvailableResponses={availableResponses}
          questionConfigurationType="Checkbox"
          questionResponseSetId={questionResponseSetId}
          onSaveCompleted={handleAddUserGeneratedResponseSaveCompleted}
        />
      )}
    </React.Fragment>
  );
};

export { QuestionAnswerCheckboxSnippet };
