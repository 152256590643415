import React, { ChangeEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { ButtonIconOnly, Icon, Input } from '@netfront/ui-library';

import { UserGeneratedResponseProps } from './UserGeneratedResponse.interfaces';

import { IUseAddUserGeneratedResponseOnCompletedResponse, useAddUserGeneratedResponse } from '../../hooks';

import './UserGeneratedResponse.css';

const UserGeneratedResponse = ({
  accessToken,
  addNewItemButtonText = 'Add new item',
  contentSnippetId,
  onSaveCompleted,
  questionConfigurationType,
  questionResponseSetId,
}: UserGeneratedResponseProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [isAddingNewItem, setIsAddingNewItem] = useState<boolean>(false);

  const handleAddNewItemClick = () => {
    setIsAddingNewItem(true);
    setInputValue('');
  };

  const handleAddUserGeneratedResponseCompleted = (data?: IUseAddUserGeneratedResponseOnCompletedResponse) => {
    setIsAddingNewItem(false);
    setInputValue('');

    if (!data) {
      return;
    }

    const {
      questionResponseSet: { availableResponses: updatedAvailableResponses },
    } = data;

    onSaveCompleted({
      updatedAvailableResponses,
    });
  };

  const handleAddUserGeneratedResponseError = (error: ApolloError) => {
    setErrorMessage(error.message);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setInputValue(value);
  };

  const handleDiscardNewItemClick = () => {
    setIsAddingNewItem(false);
    setInputValue('');
  };

  const { handleAddUserGeneratedResponse } = useAddUserGeneratedResponse({
    onCompleted: handleAddUserGeneratedResponseCompleted,
    onError: handleAddUserGeneratedResponseError,
    questionConfigurationType,
    token: accessToken,
  });

  return (
    <div className="c-user-generated-response__container">
      {isAddingNewItem ? (
        <React.Fragment>
          <div className="c-user-generated-response__input-container">
            <Input
              additionalClassNames="c-user-generated-response__input"
              errorMessage={errorMessage}
              id="user-generated-response"
              labelText=""
              name="user-generated-response"
              type="text"
              value={inputValue}
              isLabelHidden
              onChange={handleChange}
            />
          </div>
          <div className="c-user-generated-response__save-button-container">
            <ButtonIconOnly
              additionalClassNames="c-user-generated-response__save-button"
              iconId="id_tick_icon"
              isDisabled={!inputValue}
              text="Save"
              onClick={() => {
                handleAddUserGeneratedResponse({
                  contentSnippetId,
                  label: inputValue,
                  questionResponseSetId,
                });
              }}
            />
          </div>
          <div className="c-user-generated-response__discard-button-container">
            <ButtonIconOnly
              additionalClassNames="c-user-generated-response__discard-button"
              iconId="id_bin_icon"
              text="Discard"
              onClick={handleDiscardNewItemClick}
            />
          </div>
        </React.Fragment>
      ) : (
        <button className="c-user-generated-response__add-new-item-button" type="button" onClick={handleAddNewItemClick}>
          <div className="c-user-generated-response__add-new-item-button-content-container">
            <Icon className="c-user-generated-response__add-new-item-button-icon" id="id_plus_icon" />
            <div className="c-user-generated-response__add-new-item-button-text">{addNewItemButtonText}</div>
          </div>
        </button>
      )}
    </div>
  );
};

export { UserGeneratedResponse };
