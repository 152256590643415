import React from 'react';

import PropTypes from 'prop-types';

import { BaseLozenge, Success, Warning, Danger } from './styled';

const Lozenge = props => {
  const { appearance, children } = props;

  const appearanceTypes = {
    default: BaseLozenge,
    success: Success,
    warning: Warning,
    danger: Danger,
  };

  const Theme = appearanceTypes[appearance || 'default'];

  return (
    <Theme>
      <span>{children}</span>
    </Theme>
  );
};

Lozenge.defaultProps = {
  appearance: '',
};

Lozenge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  appearance: PropTypes.string,
};

export default Lozenge;
