import { gql } from '@apollo/client';

const DELETE_BOOKMARK_MUTATION = gql`
  mutation deleteBookmark($bookmarkId: Int, $contentPageId: Int) {
    bookmark {
      deleteBookmark: removeBookmark(input: { bookmarkId: $bookmarkId, contentPageId: $contentPageId })
    }
  }
`;

export { DELETE_BOOKMARK_MUTATION };
