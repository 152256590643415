// TODO: Expose more DatePicker props so it can be customized further

import React, { useState } from 'react';

import generatePicker from 'antd/lib/date-picker/generatePicker';
import PropTypes from 'prop-types';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { getAntdLocale } from '../DatePickers.helpers';

import { getDisabledDates } from './EKSingleDagePicker.helpers';

import 'antd/dist/antd.css';

const EKSingleDatePicker = ({ maxDate, minDate, onChangeHandler, selectedDate }) => {
  const DatePicker = generatePicker(dateFnsGenerateConfig);

  const [currentSavedDate, setCurrentSavedDate] = useState(selectedDate);

  const disabledDate = (currentDate) => getDisabledDates(currentDate, maxDate, minDate);

  const handleSingleDatePickerChange = (date) => {
    if (!date) {
      return;
    }

    setCurrentSavedDate(date);

    if (!onChangeHandler) {
      return;
    }

    onChangeHandler(date);
  };

  const {
    lang: { dateFormat: format },
  } = getAntdLocale();

  return (
    <DatePicker
      defaultValue={currentSavedDate}
      disabledDate={disabledDate}
      format={format}
      size="large"
      showToday
      onChange={(value) => handleSingleDatePickerChange(value)}
    />
  );
};

EKSingleDatePicker.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

export { EKSingleDatePicker };
