import React from 'react';

import cx from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { DraggableItemProps } from './DraggableItem.interfaces';

import './DraggableItem.css';

const DraggableItem = ({ children, id, index, shouldAllowDragAndDrop = true }: DraggableItemProps) => {
  return (
    <Draggable draggableId={id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => {
        return (
          <div
            ref={innerRef}
            className={cx('c-draggable-item__container', {
              'c-draggable-item__container--hover': shouldAllowDragAndDrop,
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dragHandleProps}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
};

export { DraggableItem };
