import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import StarRating from '../StarRating/StarRating';
import Input from '../UI/Input/InputWhite';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import RightNavBar from './RightNavBar';
import { Container, Item, Title } from './styled';

import DeleteAppRate from '../../middleware/MyAppDashboard/deleteRateApp';
import RateAppRequest from '../../middleware/MyAppDashboard/rateAppRequest';
import UpdateAppRate from '../../middleware/MyAppDashboard/updateRateApp';

const AppCommentSideBar = props => {
  const { identifier, onClose, comment } = props;
  const [rateAppRequest, setRateAppRequest] = useState(false);
  const [updateRequest, setUpdateRequest] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [newRate, setNewRate] = useState(comment);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (Object.keys(comment).length > 0) setIsEdit(true);
  }, [comment]);

  const handleInfoUpdate = (value, e) => {
    const newInfo = {
      ...newRate,
      [e.target.name]: value,
    };
    setNewRate(newInfo);
  };

  const handleSaveBtn = () => {
    if (!isEdit) setRateAppRequest(true);
    else setUpdateRequest(true);
  };

  const handleRateAppError = () => {
    setRateAppRequest(false);
    setUpdateRequest(false);
  };

  const handleRateAppSuccess = data => {
    setRateAppRequest(false);
    const finalRate = {
      ...newRate,
      id: data.id,
    };
    onClose('create', finalRate);
  };

  const handleUpdateSuccess = () => {
    setUpdateRequest(false);
    onClose('update', newRate);
  };

  const handleDeleteSuccess = () => {
    setDeleteRequest(false);
    onClose('delete', newRate);
  };

  const content = (
    <Container>
      <Item>
        <Title>
          <b>Rate</b>
          <Tooltip />
        </Title>
        <StarRating
          isChanged={handleInfoUpdate}
          name="rate"
          rating={newRate.rate}
        />
      </Item>
      <Item>
        <Title>
          <b>Title</b>
          <Tooltip />
        </Title>
        <Input
          isChanged={handleInfoUpdate}
          name="title"
          text={newRate.title || ''}
        />
      </Item>
      <Item>
        <Title>
          <b>Comment</b>
          <Tooltip />
        </Title>
        <Textarea
          isChanged={handleInfoUpdate}
          name="comment"
          text={newRate.comment || ''}
        />
      </Item>
    </Container>
  );
  return (
    <>
      <RightNavBar
        hasDelete={isEdit}
        onCancel={onClose}
        onDelete={() => setDeleteRequest(true)}
        onSave={handleSaveBtn}
      >
        <div>
          <h2>{isEdit ? 'Edit the comment' : 'Give a comment'}</h2>
          {content}
        </div>
      </RightNavBar>
      {rateAppRequest && (
        <RateAppRequest
          appIdentifier={identifier}
          comment={newRate.comment}
          onError={handleRateAppError}
          onSuccessResult={handleRateAppSuccess}
          rate={newRate.rate}
          title={newRate.title}
        />
      )}
      {deleteRequest && (
        <DeleteAppRate
          onError={handleRateAppError}
          onSuccessResult={handleDeleteSuccess}
          rateId={newRate.id}
        />
      )}
      {updateRequest && (
        <UpdateAppRate
          comment={newRate.comment}
          onError={handleRateAppError}
          onSuccessResult={handleUpdateSuccess}
          rate={newRate.rate}
          rateId={newRate.id}
          title={newRate.title}
        />
      )}
    </>
  );
};

AppCommentSideBar.propTypes = {
  identifier: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  comment: PropTypes.shape({}),
};

AppCommentSideBar.defaultProps = {
  comment: {},
};

export default AppCommentSideBar;
