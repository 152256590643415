import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import Preloader from '../../components/Preloader/Preloader';

export const GET_REPORT = gql`
  query GetReport($projectId: String!, $reportId: Int!) {
    report {
      getReport(projectId: $projectId, reportId: $reportId) {
        id
        query
        title
        pivot
        arguments {
          id
          type
          value
        }
        includes {
          id
          value
        }
        queriedFields {
          parent
          value
        }
      }
    }
  }
`;

const getReport = ({ projectId, reportId, onError, onSuccessResult }) => (
  <Query
    fetchPolicy="network-only"
    query={GET_REPORT}
    variables={{ projectId, reportId }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Preloader />;
      if (error) {
        onError(error.networkError.result.messages);
        return <></>;
      }
      if (data) {
        onSuccessResult(data.report.getReport);
      }

      return <></>;
    }}
  </Query>
);

getReport.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
};

export default getReport;
