import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_AUDIO_CONTENT_SNIPPET = gql`
  mutation createContentSnippet($containerId: Int!, $visible: Boolean!, $duration: Float!, $startAt: Float!, $assetId: String!) {
    contentSnippet {
      createContentSnippet(
        baseSnippet: { containerId: $containerId, visible: $visible }
        contentSnippetAudio: { duration: $duration, startAt: $startAt, audioId: $assetId }
      ) {
        id
        sort
      }
    }
  }
`;

const createAudioContentSnippetRequest = ({ containerId, isVisible, duration, assetId, onError, onSuccessResult }) => (
  <Mutation mutation={CREATE_AUDIO_CONTENT_SNIPPET}>
    {(createContentSnippet, { loading, error, data }) => {
      const variables = {
        containerId,
        visible: isVisible,
        duration,
        startAt: 0.0,
        assetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.createContentSnippet.id, data.contentSnippet.createContentSnippet.sort);
      }

      return <MutationRequest isLoading={loading} mutation={createContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

createAudioContentSnippetRequest.propTypes = {
  assetId: PropTypes.string.isRequired,
  containerId: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

createAudioContentSnippetRequest.defaultProps = {
  isVisible: true,
};

export default createAudioContentSnippetRequest;
