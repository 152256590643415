import React, { useContext } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Filters, PushRight } from '../OrganisationUsersPage/styled';

import TemplateTable from './TemplateTable';

import Button from '../../components/Buttons/Button';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import { getBreadCrumbPath } from '../../utils/utils';

const NotificationTemplatesPage = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const { org, project } = useContext(AppContext);
  const history = useHistory();

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Notifications'), ...getBreadCrumbPath(pathname, 0, 'Notification templates')];

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="Manage your push notifications"
      pageDescription="Manage push notifications"
      pageTitle="Push notifications"
      params={params}
    >
      <Filters>
        <PushRight>
          <Button
            onClick={() => {
              history.push(`/dashboard/${org.key}/project/${project.id}/create-notification`);
            }}
          >
            New template
          </Button>
        </PushRight>
      </Filters>
      <ProjectNavBar title={project.name} />
      <TemplateTable />
    </PageWrapper>
  );
};

export default NotificationTemplatesPage;
