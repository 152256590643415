import gql from 'graphql-tag';

export const FRAGMENT_VIDEO = gql`
  fragment video on ContentSnippetVideoType {
    assetId
    asset {
      s3Key
      contentType
    }
  }
`;
