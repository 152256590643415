import React from 'react';

import { useLocation } from 'react-router-dom';

import Moderation from '../../components/Social/Moderation/Moderation';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SocialPageState from '../../context/SocialPage/SocialPageState';
import { getBreadCrumbPath } from '../../utils/utils';

const SocialPage = (props) => {
  const { match } = props;

  const { pathname } = useLocation();
  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Social'), ...getBreadCrumbPath(pathname, 0, 'Moderation')];

  return (
    <SocialPageState>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Moderate social content"
        pageDescription="Moderate social content"
        pageTitle="Social media"
        params={match.params}
      >
        <Moderation />
      </PageWrapper>
    </SocialPageState>
  );
};

export default SocialPage;
