import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { useLocation, useParams } from 'react-router-dom';

import { SearchContainer } from '../OrganisationUsersPage/styled';
import { StyledTableFilterContainer } from '../styles';

import GroupsOrderSummaryTable from './GroupsOrderSummaryTable';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import SearchInput from '../../components/SearchInput/SearchInput';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { GET_COMPLETED_ORDERS_FOR_PROJECT } from '../../middleware/_Vervet/Queries/GetCompletedOrdersForProject';
import vervetClient from '../../middleware/vervetClient';
import { getBreadCrumbPath, getFilteredResults } from '../../utils/utils';

function GroupsOrderSummaryPage() {
  const { pathname } = useLocation();
  const params = useParams();

  const { projectId: projectGuid } = params;

  const [allGroupsOrderSummaries, setAllGroupsOrderSummaries] = useState(undefined);
  const [filteredGroupsOrderSummaries, setFilteredGroupsOrderSummaries] = useState(undefined);
  const [message, setMessage] = useState('');

  const [executeGetCompletedOrdersForProject, { loading }] = useLazyQuery(GET_COMPLETED_ORDERS_FOR_PROJECT, {
    client: vervetClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const {
        orders: { getCompletedOrdersForProject: orders },
      } = data;

      const uniqueGroupIds = [
        ...new Set(
          orders.map((order) => {
            const {
              group: { id: groupId },
            } = order;
            return groupId;
          }),
        ),
      ];

      const groupsOrderSummaries = uniqueGroupIds.map((groupId) => {
        const groupOrders = orders.filter((order) => order.group.id === groupId);

        const totalLicences = groupOrders.reduce(
          (accumulator1, currentOrder) =>
            accumulator1 +
            currentOrder.items.reduce((accumulator2, currentOrderItem) => accumulator2 + currentOrderItem.licences.length, 0),
          0,
        );

        return {
          groupId,
          groupName: groupOrders[0].group.name,
          latestOrderDate: groupOrders[groupOrders.length - 1].createdAtUtc,
          totalLicences,
        };
      });

      setAllGroupsOrderSummaries(groupsOrderSummaries);
      setFilteredGroupsOrderSummaries(groupsOrderSummaries);
    },
    onError: (error) => {
      console.error(error.message);
      setMessage('Something went wrong! Please try again later.');
    },
  });

  function handleMsgDisappear() {
    setTimeout(() => {
      setMessage('');
    }, 500);
  }

  function handleSearchFilter(event, groupOrders) {
    const {
      target: { value },
    } = event;

    setFilteredGroupsOrderSummaries(getFilteredResults(groupOrders, ['groupName'], value));
  }

  function handleViewGroupOrders(groupId) {
    window.location.href = `${pathname}/${groupId}/orders-details`;
  }

  useEffect(() => {
    if (!projectGuid) {
      return;
    }

    executeGetCompletedOrdersForProject({
      variables: {
        projectGuid,
      },
    });
  }, [projectGuid]);

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Subscriptions'), ...getBreadCrumbPath(pathname, 0, 'Group orders')];

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="All available orders by active groups"
      isLoaderVisible={loading}
      pageDescription="order of the console"
      pageTitle="Orders page"
      params={params}
    >
      <ProjectNavBar title="Orders" />
      {allGroupsOrderSummaries && (
        <>
          <StyledTableFilterContainer>
            <SearchContainer minWidth="30%">
              <SearchInput placeholder="Filter by group" onChange={(event) => handleSearchFilter(event, allGroupsOrderSummaries)} />
            </SearchContainer>
          </StyledTableFilterContainer>

          <GroupsOrderSummaryTable groupsOrderSummaries={filteredGroupsOrderSummaries} onView={handleViewGroupOrders} />
        </>
      )}
      <PopUpMsg message={message} msgDisappear={handleMsgDisappear} />
    </PageWrapper>
  );
}

export default GroupsOrderSummaryPage;
