import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

function ContentAppCard(props) {
  const { title, byFrom, onClick, id, status = 'PUBLISHED' } = props;

  return (
    <>
      <button
        className="app-item button-none"
        key={title}
        onClick={onClick.bind(this, id)}
        type="button"
      >
        <>
          <div className="app-img">
            <div
              className={classnames(
                'app-badge',
                { 'badge-green': status === 'PUBLISHED' },
                { 'badge-red': status === 'UNPUBLISHED' }
              )}
            >
              {status}
            </div>
          </div>
          <div className="app-content">
            <div className="app-title">{title}</div>
            <div className="app-from">{byFrom}</div>
          </div>
        </>
      </button>
    </>
  );
}

ContentAppCard.propTypes = {
  byFrom: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContentAppCard;
