/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon/Icon';
import { Preview } from '../RightNavBar/styled';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

const sizeMap = {
  SMALL: 1,
  MEDIUM: 1.5,
  LARGE: 2,
};

const Wrapper = styled(Preview)`
  display: flex;
  height: 12em;
  justify-content: center;
  margin: 0.5em 0 0 0;
  max-width: unset;
  overflow-x: hidden;
`;

const ImageMapImg = styled.img`
  height: 100%;
  width: inherit;
  z-index: 1;
`;

const ImageMap = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  position: relative;
  width: 220px;
`;

const RegionArea = styled.div`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  outline: none;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;

  &:hover,
  &:focus {
    outline: none;
  }
  height: ${props => sizeMap[props.size] || 1}rem;
  width: ${props => sizeMap[props.size] || 1}rem;
  padding: 0;
  ${props =>
    props.isTemp &&
    `
    &:after {
      content: '';
      background-color: red;
      opacity: 0.8;
      height: ${sizeMap[props.size] || 1}rem;
      width: ${sizeMap[props.size] || 1}rem;
      position: absolute;
    }
  `}
`;

const ImageMapCreation = props => {
  const {
    imageMapDetail,
    regions,
    onImageClick,
    onRegionClick,
    isInteractive,
  } = props;

  const getIconStyle = size => ({
    height: `${sizeMap[size]}rem`,
    width: `${sizeMap[size]}rem`,
  });

  return (
    <Wrapper>
      <ImageMap>
        <ImageMapImg
          alt={imageMapDetail.iconAsset.alt}
          onClick={onImageClick}
          src={getSignedUrl(
            imageMapDetail.iconAsset.s3Key,
            imageMapDetail.iconAsset.contentType
          )}
        />
        {regions.map(region => (
          <RegionArea
            as={isInteractive ? 'button' : 'div'}
            isTemp={region.isTemp}
            key={region.id}
            onClick={isInteractive ? () => onRegionClick(region.id) : undefined}
            size={region.iconSize}
            style={{ left: region.x, top: region.y }}
          >
            <Icon
              id={region.iconType}
              style={{ width: '100%', height: '100%' }}
            />
          </RegionArea>
        ))}
      </ImageMap>
    </Wrapper>
  );
};

ImageMapCreation.propTypes = {
  imageMapDetail: PropTypes.shape({
    iconAsset: PropTypes.shape({
      alt: PropTypes.string,
      presignedUrl: PropTypes.string,
    }),
    iconAssetId: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      alternativeImageId: PropTypes.string,
      contentGroupId: PropTypes.number,
      contentPage: PropTypes.shape({
        id: PropTypes.number,
      }),
      isTemp: PropTypes.bool,
      name: PropTypes.string,
      x: PropTypes.number,
      y: PropTypes.number,
    })
  ).isRequired,
  isInteractive: PropTypes.bool,
  onImageClick: PropTypes.func,
  onRegionClick: PropTypes.func,
};

ImageMapCreation.defaultProps = {
  isInteractive: false,
  onImageClick: () => {},
  onRegionClick: () => {},
};

export default ImageMapCreation;
