import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import DropDownButton from '../UI/Button/ShowHideButton';
import Input from '../UI/Input/InputWhite';
import ClickableTag from '../UI/Tag/ClickableTag';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import {
  Wrapper,
  Container,
  TagsWrapper,
  Select,
  Option,
  PlusButton,
  InputWrapper,
  AdvanceContainer,
  AdvanceItem,
  AdvanceTitle,
  Title,
} from './styled';

import AppContext from '../../context/AppContext';
import CreateContentPageRequest from '../../middleware/ContentPage/createContentPageRequest';
import GetContentPage from '../../middleware/ContentPage/getContentPage';
import GetContentPageTypes from '../../middleware/ContentPage/getContentPageTypes';
import { convertToURL } from '../../utils/utils';

const PageLevelFooter = props => {
  const { currentPageId, contentGroupId, onAdded, sideOpen, onContentPageLoaded } = props;

  const { contentGroup, project } = useContext(AppContext);

  const [showAdvance, setShowAdvance] = useState(false);
  const [loadPage, setLoadPage] = useState(false);
  const [createPageRequest, setCreatePageRequest] = useState(false);
  const [name, setName] = useState('');
  const [level, setLevel] = useState('current');
  const [parentId, setParentId] = useState(null);
  const [getGrandId, setGetGrandId] = useState(false);
  const [seoDescription, setSeoDescription] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [activeTags, setActiveTags] = useState([]);
  const [typeLoaded, setTypeLoaded] = useState(false);
  const [types, setTypes] = useState([]);
  const [activeType, setActiveType] = useState({});

  const handleNameInput = value => {
    setName(value);
  };

  const handleLevelSelect = e => {
    setLevel(e.target.value);
  };

  const handleAddNewPage = () => {
    switch (level) {
      case 'top':
        setParentId(null);
        break;
      case 'previous':
        setGetGrandId(true);
        break;
      case 'current':
        break;
      case 'next':
        setParentId(currentPageId);
        break;
      default:
        break;
    }
    if (level !== 'previous') setCreatePageRequest(true);
  };

  const handleGetPageError = () => {};

  const handleGetPageSuccess = data => {
    onContentPageLoaded(data);
    setParentId(data.parentId);
    setLoadPage(true);
    if (level === 'previous') {
      setCreatePageRequest(true);
    }
  };

  const handleCreateRequest = id => {
    onAdded(id);
    setCreatePageRequest(false);
  };

  const handleCreateError = () => {
    setCreatePageRequest(false);
  };

  const handleActiveTags = (active, item) => {
    if (active) {
      setActiveTags([...activeTags, item.id]);
    }
  };

  const handleActiveType = (active, item) => {
    setActiveType(item);
  };

  const handlePageTypesError = () => {
    setTypeLoaded(true);
  };

  const handlePageTypesSuccess = data => {
    setTypeLoaded(true);
    setTypes(data);
  };

  const allTags = contentGroup.tags.map(item => (
    <ClickableTag isActive={activeTags.find(t => t === item.id) !== undefined} isClicked={handleActiveTags} key={item.id} tag={item} />
  ));

  const allTypes = types.map(item => (
    <ClickableTag isActive={item.id === activeType.id} isClicked={handleActiveType} key={item.id} tag={item} />
  ));

  return (
    <>
      <Wrapper sideOpen={sideOpen}>
        <Container>
          <Select onChange={handleLevelSelect} value={level}>
            <Option value="top">Top level</Option>
            <Option disabled={!parentId} value="previous">
              Previous level
            </Option>
            <Option value="next">Next level</Option>
            <Option value="current">Current level</Option>
          </Select>
          <InputWrapper>
            <Input
              id={'page-level-footer-page-name'}
              isChanged={handleNameInput}
              placeholder="Enter page name"
              style={{ padding: '7px', margin: 0 }}
            />
          </InputWrapper>
          <PlusButton onClick={handleAddNewPage}>
            <span alt="plus" className="icon-plus c-icon-add d-flex align-items-center justify-content-center" />
          </PlusButton>
          <DropDownButton
            isClicked={() => {
              setShowAdvance(!showAdvance);
            }}
          />
        </Container>
        {showAdvance && (
          <AdvanceContainer>
            <AdvanceItem>
              <AdvanceTitle>
                <Title htmlFor={'page-level-footer-url'}>URL</Title>
                <Tooltip />
              </AdvanceTitle>
              <Input id={'page-level-footer-url'} text={convertToURL(`${name}`)} />
            </AdvanceItem>
            <AdvanceItem>
              <AdvanceTitle>
                <Title htmlFor={'page-level-footer-seo'}>SEO Title</Title>
                <Tooltip />
              </AdvanceTitle>
              <Input id={'page-level-footer-seo'} isChanged={value => setSeoTitle(value)} text={seoTitle || name} />
            </AdvanceItem>
            <AdvanceItem>
              <AdvanceTitle>
                <Title htmlFor={'page-level-footer-seo-desc'}>SEO Description</Title>
                <Tooltip />
              </AdvanceTitle>
              <Textarea id={'page-level-footer-seo-desc'} isChanged={value => setSeoDescription(value)} text={seoDescription} />
            </AdvanceItem>
            {contentGroup.type === 'RESOURCE' && (
              <>
                <AdvanceItem>
                  <AdvanceTitle>
                    <Title htmlFor={'page-level-footer-select-tags'}>Select tags</Title>
                    <Tooltip />
                  </AdvanceTitle>
                  <TagsWrapper id={'page-level-footer-select-tags'}>{allTags}</TagsWrapper>
                </AdvanceItem>
                <AdvanceItem>
                  <AdvanceTitle>
                    <Title htmlFor={'page-level-footer-content-types'}>Content type</Title>
                    <Tooltip />
                  </AdvanceTitle>
                  <TagsWrapper id={'page-level-footer-content-types'}>{allTypes}</TagsWrapper>
                </AdvanceItem>
              </>
            )}
          </AdvanceContainer>
        )}
      </Wrapper>
      {currentPageId && !loadPage && (
        <GetContentPage contentPageId={currentPageId} onError={handleGetPageError} onSuccessResult={handleGetPageSuccess} />
      )}
      {getGrandId && <GetContentPage contentPageId={parentId} onError={handleGetPageError} onSuccessResult={handleGetPageSuccess} />}
      {createPageRequest && (
        <CreateContentPageRequest
          contentGroupId={contentGroupId}
          contentPageType={activeType.id}
          onError={handleCreateError}
          onSuccessResult={handleCreateRequest}
          parentId={parentId}
          seoDescription={seoDescription}
          seoTitle={seoTitle || name}
          tags={activeTags}
          title={name}
          url={convertToURL(`/${name}`)}
        />
      )}
      {!typeLoaded && (
        <GetContentPageTypes onError={handlePageTypesError} onSuccessResult={handlePageTypesSuccess} projectId={project.id} />
      )}
    </>
  );
};

PageLevelFooter.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  currentPageId: PropTypes.number.isRequired,
  onAdded: PropTypes.func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  sideOpen: PropTypes.bool.isRequired,
};

// PageLevelFooter.defaultProps = {
//   parentId: null,
// };

export default PageLevelFooter;
