import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const DELETE_APP_COMPONENT = gql`
  mutation deleteAppComponent($componentId: Int!) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          deleteComponent(inputType: { componentId: $componentId })
        }
      }
    }
  }
`;

const deleteAppComponentRequest = ({
  componentId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={DELETE_APP_COMPONENT}>
    {(deleteAppComponent, { loading, error, data }) => {
      const variables = {
        componentId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.submitApp);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={deleteAppComponent}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

deleteAppComponentRequest.propTypes = {
  componentId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

deleteAppComponentRequest.defaultProps = {};

export default deleteAppComponentRequest;
