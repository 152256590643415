import React from 'react';

import cx from 'classnames';

import { animationDirectionMap, animationSpeedMap, animationTypeMap } from './CMSAnimationWrapper.constants';
import { CMSAnimationWrapperProps } from './CMSAnimationWrapper.types';

const CMSAnimationWrapper = ({
  animation,
  animationDirection,
  children,
  infinite,
  millisecondDelay,
  millisecondSpeed,
}: CMSAnimationWrapperProps) => {
  return (
    <div
      className={cx('animate__animated', `animate__${animationTypeMap[animation]}${animationDirectionMap[animationDirection]}`, {
        animate__infinite: infinite,
        [`animate__delay-${millisecondDelay / 1000}s`]: millisecondDelay,
        [`animate__${animationSpeedMap[millisecondSpeed]}`]: millisecondSpeed,
      })}
    >
      {children}
    </div>
  );
};

export { CMSAnimationWrapper };
