import React from 'react';

import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import EditBtn from '../Button/EditButton';

const BaseSkeleton = styled(Skeleton)`
  margin-left: 1rem;
  width: 9rem !important;
`;

const BottomSkeleton = styled(BaseSkeleton)`
  height: 7rem;
  margin-top: 0.75rem;
`;

const EditBox = styled.div`
  display: none;
`;

const Icon = styled.span`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const Image = styled.img`
  margin: auto;
  max-height: 70%;
  width: 45%;
`;

const Name = styled.p`
  border-bottom: 1px solid rgba(0, 42, 76, 0.1);
  font-weight: 600;
  margin: 0 1em;
  padding: 0.6em 0;

  @media only screen and (max-width: 540px) {
    font-size: 1em;
    padding: 0.2em;
  }
`;

const TopSkeleton = styled(BaseSkeleton)`
  margin-top: 1rem;
`;

const Wrapper = styled.a`
  animation: fadeIn 0.5s;
  background-color: #fff;
  border: 1px solid rgba(0, 42, 76, 0.1);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 11em;
  margin: 0 2em 1.6em 0;
  overflow: hidden;
  position: relative;
  transition: 0.25s ease-in-out;
  width: 11em;

  @media only screen and (max-width: 540px) {
    height: 10em;
    margin: 0 0.2em 0.6em 0.2em;
    width: 8.5em;
  }

  &:hover,
  &:focus {
    box-shadow: 0 5px 15px 0 rgba(0, 42, 76, 0.12);
    transform: scale(1.04);
    ${EditBox} {
      display: block;
      position: absolute;
      right: 0.7em;
      top: 0.7em;
    }
  }
`;

const MenuCard = ({ children, edit, href, isLoading, name, onClick, src }) => (
  <Wrapper href={href} onClick={onClick}>
    {isLoading && (
      <>
        <TopSkeleton />
        <BottomSkeleton />
      </>
    )}
    {!isLoading && (
      <>
        {edit && (
          <EditBox>
            <EditBtn isClicked={edit} />
          </EditBox>
        )}
        <Name onClick={onClick}>{name}</Name>
        {src && (
          <Icon onClick={onClick}>
            <Image alt="icon" src={src} />
          </Icon>
        )}
      </>
    )}
    {children}
  </Wrapper>
);

MenuCard.propTypes = {
  edit: PropTypes.func,
  href: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string,
};

MenuCard.defaultProps = {
  edit: undefined,
  href: undefined,
  isLoading: false,
  name: undefined,
  onClick: () => {},
  src: undefined,
};

export default MenuCard;
