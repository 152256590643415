import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: rgb(250, 251, 252);
  box-sizing: border-box;
  color: rgb(9, 30, 66);
  cursor: text;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  max-width: 100%;
  overflow-wrap: break-word;
  vertical-align: top;
  pointer-events: auto;
  flex: 1 1 100%;
  overflow: hidden;
`;

export const InputField = styled.textarea`
  background-color: transparent;
  border-color: rgb(223, 225, 230);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  font-size: 14px;
  margin-top: 0;
  min-width: 0;
  width: 100%;
  height: 100px;
  line-height: 1.42857;
  border-image: initial;
  outline: none;
  padding: 8px 6px;
  transition: background-color 0.2s ease-in-out 0s,
    border-color 0.2s ease-in-out 0s;

  &:focus {
    border-color: #00c4cc;
  }
`;
