import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import AudioPlayer from '../UI/AudioPlayer/AudioPlayer';
import Button from '../UI/Button/SquareButton';
import NoDataAlerts from '../UI/NoDataComp/NoDataAlert';

import AppContext from '../../context/AppContext';
import GetAllAssets from '../../middleware/AssetLibrary/getAllAssetsRequest';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import { ImgBox, AssetImg, VideoBox, AssetVideo, AudioBox, FileName, IconImg, IconImgS } from '../../pages/LibraryAssetPage/styled';
import DocIcon from '../../static/img/FileType/DocIcon.svg';
import PdfIcon from '../../static/img/FileType/PdfIcon.svg';
import XlsIcon from '../../static/img/FileType/XlsIcon.svg';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 16em;
  overflow-y: scroll;
`;

const NoDataBox = styled.div`
  margin: 0 auto;

  button {
    margin-left: 0;
    margin-top: 1em;
  }
`;

const LibraryAssets = (props) => {
  const { type, isClicked, goBack, display, justifyContent } = props;
  const { project } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [assets, setAssets] = useState([]);
  const [selectedId, setSelectedId] = useState('');

  const handleGetAssetsError = () => {
    setLoaded(true);
  };

  const handleGetAssetsSuccess = (data) => {
    const {
      asset: { searchAssets },
    } = data;

    const tmpAssets = [];
    searchAssets.map((asset) =>
      tmpAssets.push({
        ...asset,
        src: getSignedUrl(asset.s3Key, asset.contentType),
      }),
    );
    setAssets(tmpAssets);
    setLoaded(true);
  };

  const handleClick = (ass) => {
    isClicked(ass);
    setSelectedId(ass.assetId);
  };

  const content = (
    <Wrapper>
      {assets.length > 0 ? (
        assets.map((asset) => {
          const index = asset.s3Key.lastIndexOf('.') + 1;
          const fileType = asset.s3Key.substr(index);
          switch (type.toLowerCase()) {
            case 'image':
              return (
                <ImgBox isSelected={asset.assetId === selectedId} key={asset.assetId}>
                  <AssetImg alt={asset.fileName} onClick={() => handleClick(asset)} src={asset.src} />
                </ImgBox>
              );
            case 'video':
              return (
                <VideoBox isSelected={asset.assetId === selectedId} key={asset.assetId}>
                  <AssetVideo onClick={() => handleClick(asset)} src={asset.src}>
                    <track kind="captions" srcLang="en" />
                  </AssetVideo>
                </VideoBox>
              );
            case 'audio':
              return (
                <AudioBox isSelected={asset.assetId === selectedId} key={asset.assetId} onClick={() => handleClick(asset)}>
                  <AudioPlayer isClicked={() => {}} selected={asset.src} />
                  <FileName>{asset.fileName}</FileName>
                </AudioBox>
              );
            case 'document':
              return (
                <AudioBox
                  display={display}
                  justifyContent={justifyContent}
                  isSelected={asset.assetId === selectedId}
                  key={asset.assetId}
                  onClick={() => handleClick(asset)}
                >
                  <a className="a_document" download href={asset.src} key={asset.assetId} rel="noopener noreferrer" target="_blank">
                    {fileType === 'pdf' && <IconImg alt="file" src={PdfIcon} />}
                    {fileType === ('docx' || 'doc') && <IconImgS alt="file" src={DocIcon} />}
                    {fileType === ('xlsx' || 'xls') && <IconImgS alt="file" src={XlsIcon} />}
                    <FileName>{asset.fileName}</FileName>
                  </a>
                </AudioBox>
              );
            default:
              return null;
          }
        })
      ) : (
        <NoDataBox>
          <NoDataAlerts />
          <Button isClicked={goBack}>Go back</Button>
        </NoDataBox>
      )}
    </Wrapper>
  );

  return (
    <div>
      {content}
      {!loaded && Object.keys(project).length > 0 && (
        <GetAllAssets onError={handleGetAssetsError} onSuccessResult={handleGetAssetsSuccess} projectId={project.id} type={type} />
      )}
    </div>
  );
};

LibraryAssets.propTypes = {
  goBack: PropTypes.func.isRequired,
  isClicked: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default LibraryAssets;
