import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './UserTypeCount.scss';
function UserTypeCount(props) {
  return (
    <div className="flex">
      <p className="count-container">{props.count}</p>
      <p className="label-p">{props.label}</p>
    </div>
  );
}

UserTypeCount.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default UserTypeCount;
