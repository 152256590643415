/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import { useCookie } from '@netfront/common-library';
import { DEFAULT_STORAGE_EXPIRY_OPTION, useAuthentication, useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export function PrivateRoute({ component: Comp, ...rest }) {
  const { isAuthenticated } = useAuthentication();
  const { createLastUrlBeforeAuthenticationExpiredCookie, getLastUrlBeforeAuthenticationExpiredCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  useEffect(() => {
    if (isAuthenticated || !isDomainReady) {
      return;
    }

    const {
      location: { href },
    } = window;

    const lastUrlBeforeAuthenticationExpiredCookie = getLastUrlBeforeAuthenticationExpiredCookie();

    if (!lastUrlBeforeAuthenticationExpiredCookie) {
      createLastUrlBeforeAuthenticationExpiredCookie({
        optionalCookieAttributesInput: {
          domain: getDomain(),
          secure: true,
          storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
        },
        value: href,
      });
    }

    window.location.href = getLoginUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady]);

  return <Route {...rest} render={(props) => <Comp {...props} />} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape(),
};

PrivateRoute.defaultProps = {
  location: {},
};
