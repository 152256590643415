/* eslint-disable react/boolean-prop-naming */
import React from 'react';

import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* min-width: 300px; */
  height: 300px;
  border-radius: 5px;
  filter: ${props => (props.grayscale ? 'grayscale(99%)' : 'grayscale(0)')};
`;

const Blocker = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  /* background-color: yellow;
  opacity: 0.5; */
`;

const StyledMarker = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  border-radius: 50% 50% 50% 0;
  border: 4px solid red;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  &::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: red;
  }
  &:hover {
    border-color: transparent;
  }
`;

const key = 'AIzaSyBqXU8wNCH619eid2pnacRraqqyoTarW9U';

const MapSnippet = props => {
  const { latitude, longitude, zoom, draggable, grayscale } = props;
  return (
    <>
      <Wrapper grayscale={grayscale}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key,
            language: 'en',
          }}
          center={{
            lat: latitude,
            lng: longitude,
          }}
          defaultZoom={zoom}
          options={{
            fullscreenControl: false,
          }}
        >
          <StyledMarker lat={latitude} lng={longitude} text="marker" />
        </GoogleMapReact>
        {!draggable && <Blocker />}
      </Wrapper>
    </>
  );
};

MapSnippet.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  grayscale: PropTypes.bool,
};

MapSnippet.defaultProps = {
  draggable: true,
  grayscale: false,
};

export default MapSnippet;
