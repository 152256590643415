import gql from 'graphql-tag';

export const FRAGMENT_BUTTON = gql`
  fragment button on ContentSnippetButtonType {
    hyperlink
    innerText
    fontColor
    buttonBackgroundColor
    target
    hoverColor
    style
    page {
      id
      title
      url
      contentGroup {
        title
        type
        url
      }
    }
  }
`;
