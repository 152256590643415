import gql from 'graphql-tag';

export const UPDATE_COMMENT = gql`
  mutation updateComment($id: Int!, $message: String!) {
    comment {
      update(request: { id: $id, message: $message }) {
        author {
          displayedName
        }
        id
        authorId
        author {
          firstName
          lastName
        }
        message
        createdDate
        createdDateTime
      }
    }
  }
`;
