import { useMutation } from '@apollo/react-hooks';
import { isAfter, isValid } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AppContext from '../../context/AppContext';
import vervetClient from '../../middleware/vervetClient';
import { CREATE_DISCOUNT } from '../../middleware/_Vervet/Mutations/CreateDiscount';
import { DELETE_DISCOUNT } from '../../middleware/_Vervet/Mutations/DeleteDiscount';
import { UPDATE_DISCOUNT } from '../../middleware/_Vervet/Mutations/UpdateDiscount';
import { DISCOUNTS_PAGE_ACTION_COPY, DISCOUNTS_PAGE_ACTION_CREATE, DISCOUNTS_PAGE_ACTION_EDIT } from '../../pages/DiscountsPage/DiscountsPage';
import { getDateWithoutTime } from '../../utils/utils';
import ValidationProcessor from '../ErrorMessage/ValidationProcessor';
import Preloader from '../Preloader/Preloader';
import DatePicker from '../UI/Input/DatePicker';
import Input from '../UI/Input/InputWhite';
import NumberInput from '../UI/Input/NumberInput';
import PopUpMsg from '../UI/PopUp/PopUpMsg';
import Tooltip from '../UI/Tooltip/Tooltips';
import RightNavBar from './RightNavBar';

const AdvanceContainer = styled.div`
  overflow-y: scroll;
`;

const AdvanceItem = styled.div`
  margin: 1rem auto;
  width: 100%;
`;

const AdvanceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Header = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

function DiscountSideBarEditor(props) {
  const {
    action: { titlePrefix, value: actionValue, verb: actionVerb },
    allDiscounts,
    discount,
    onCancel,
    onChange,
  } = props;
  const { project } = useContext(AppContext);

  const [currentDiscount, setCurrentDiscount] = useState(discount);
  const [previousDiscountCode] = useState(discount.code);
  const [message, setMessage] = useState('');

  const [executeCreateDiscountMutation, { loading: isCreateDiscountLoading }] = useMutation(CREATE_DISCOUNT, {
    client: vervetClient,
    onCompleted: data => {
      const {
        discounts: { create: newDiscount },
      } = data;

      onChange([...allDiscounts, newDiscount]);
    },
    onError: () => {
      setMessage(getMutationErrorMessage(actionVerb));
    },
  });

  const [executeDeleteDiscountMutation, { loading: isDeleteDiscountLoading }] = useMutation(DELETE_DISCOUNT, {
    client: vervetClient,
    onCompleted: () => {
      // TODO: Get the status values from GraphQL
      const updatedDiscounts = allDiscounts.map(discount =>
        discount.id === currentDiscount.id ? { ...currentDiscount, status: 'INACTIVE' } : discount,
      );
      onChange(updatedDiscounts);
    },
    onError: () => {
      setMessage(getMutationErrorMessage(actionVerb));
    },
  });

  const [executeUpdateDiscountMutation, { loading: isUpdateDiscountLoading }] = useMutation(UPDATE_DISCOUNT, {
    client: vervetClient,
    onCompleted: data => {
      const {
        discounts: {
          update: { id: discountId },
        },
      } = data;
      const updatedDiscounts = allDiscounts.map(discount => (discount.id === discountId ? currentDiscount : discount));

      onChange(updatedDiscounts);
    },
    onError: () => {
      setMessage(getMutationErrorMessage(actionVerb));
    },
  });

  function getMutationErrorMessage(discountPageActionVerb) {
    return `Error ${discountPageActionVerb} the discount! Please try again later.`;
  }

  function getMutationVariables(discount) {
    const { code, description, expiresAtUtc, id, maxUsage, percentage, startsAtUtc } = discount;

    return {
      code,
      description,
      id,
      maxUsage,
      percentage,
      projectGuid: project.id,
      period: {
        startsAtUtc: getDateWithoutTime(startsAtUtc),
        expiresAtUtc: getDateWithoutTime(expiresAtUtc),
      },
    };
  }

  function getValidation(discount) {
    const { code = '', description, expiresAtUtc, maxUsage, percentage, startsAtUtc } = discount;

    const allDiscountCodes = allDiscounts.map(discount => discount.code.toUpperCase());
    const isDiscountCodeUnique = !allDiscountCodes.includes(code.toUpperCase());
    const canUpdateDiscountCode = actionValue === DISCOUNTS_PAGE_ACTION_EDIT && code.toUpperCase() === previousDiscountCode.toUpperCase();

    return {
      titleEmpty: {
        errorMessage: 'Title cannot be empty',
        validation: Boolean(description),
      },
      codeEmpty: {
        errorMessage: 'Discount code cannot be empty',
        validation: Boolean(code),
      },
      codeMustBeUnique: {
        errorMessage: 'Discount code must be unique',
        validation: isDiscountCodeUnique || canUpdateDiscountCode,
      },
      percentageEmpty: {
        errorMessage: 'Percentage must be greater than zero',
        validation: percentage > 0,
      },
      maxUsageEmpty: {
        errorMessage: 'Max usage must be greater than zero',
        validation: maxUsage > 0,
      },
      startDateIsInvalid: {
        errorMessage: 'Start date must be a valid date',
        validation: isValid(new Date(startsAtUtc)),
      },
      startDateMustBeAFutureDate: {
        errorMessage: 'Start date must be a future date',
        validation: isAfter(new Date(startsAtUtc), new Date()),
      },
      endDateIsInvalid: {
        errorMessage: 'End date must be a valid date',
        validation: isValid(new Date(expiresAtUtc)),
      },
      endDateMustBeAfterStartDate: {
        errorMessage: 'End date must be after start date',
        validation: isAfter(new Date(expiresAtUtc), new Date(startsAtUtc)),
      },
    };
  }

  function handleDateUpdate(event) {
    const {
      target: { name, value },
    } = event;

    const newDiscount = {
      ...currentDiscount,
      [name]: value,
    };

    setCurrentDiscount(newDiscount);
  }

  function handleDiscountUpdate(value, event) {
    const newDiscount = {
      ...currentDiscount,
      [event.target.name]: value,
    };

    setCurrentDiscount(newDiscount);
  }

  function handleMsgDisappear() {
    setTimeout(() => {
      setMessage('');
    }, 2000);
  }

  function onDelete(discount) {
    executeDeleteDiscountMutation({
      variables: {
        id: discount.id,
      },
    });
  }

  function onSave(discount, discountPageActionValue) {
    const validationErrors = ValidationProcessor(getValidation(discount));

    if (!validationErrors.modelValid) {
      setMessage(validationErrors.validations);
      return;
    }

    const variables = getMutationVariables(currentDiscount);

    switch (discountPageActionValue) {
      case DISCOUNTS_PAGE_ACTION_COPY:
      case DISCOUNTS_PAGE_ACTION_CREATE:
        executeCreateDiscountMutation({
          variables,
        });
        break;
      case DISCOUNTS_PAGE_ACTION_EDIT:
        executeUpdateDiscountMutation({
          variables,
        });
        break;
      default:
        break;
    }
  }

  const content = (
    <AdvanceContainer>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Title</b>
          <Tooltip text="Description of the discount percentage" />
        </AdvanceTitle>
        <Input id="discount-description" name="description" text={currentDiscount.description} isChanged={handleDiscountUpdate} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Discount code</b>
          <Tooltip text="Discount code" />
        </AdvanceTitle>
        <Input id="discount-code" name="code" text={currentDiscount.code} isChanged={handleDiscountUpdate} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Percentage discount</b>
          <Tooltip text="Percentage discount" />
        </AdvanceTitle>
        <NumberInput
          name="percentage"
          defaultValue={1}
          isRequired
          max={9999}
          min={0}
          number={Number(currentDiscount.percentage)}
          isChanged={handleDiscountUpdate}
        />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Max usage</b>
          <Tooltip text="Max usage" />
        </AdvanceTitle>
        <NumberInput
          name="maxUsage"
          defaultValue={1}
          isRequired
          max={9999}
          min={0}
          number={Number(currentDiscount.maxUsage)}
          isChanged={handleDiscountUpdate}
        />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Start date</b>
          <Tooltip text="Start date" />
        </AdvanceTitle>
        <DatePicker id={1} name="startsAtUtc" onChange={handleDateUpdate} value={currentDiscount.startsAtUtc} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <b>End date</b>
          <Tooltip text="End date" />
        </AdvanceTitle>
        <DatePicker id={2} name="expiresAtUtc" onChange={handleDateUpdate} value={currentDiscount.expiresAtUtc} />
      </AdvanceItem>
      {message && <PopUpMsg type="error" message={message} msgDisappear={handleMsgDisappear} />}
    </AdvanceContainer>
  );

  const isLoading = isCreateDiscountLoading || isDeleteDiscountLoading || isUpdateDiscountLoading;

  return (
    <RightNavBar
      hasDelete={actionValue !== DISCOUNTS_PAGE_ACTION_CREATE}
      onCancel={() => onCancel()}
      onDelete={() => onDelete(currentDiscount)}
      onSave={() => onSave(currentDiscount, actionValue)}
    >
      <>
        {isLoading && <Preloader />}
        <Header>{`${titlePrefix} discount code`}</Header>
        {content}
      </>
    </RightNavBar>
  );
}

DiscountSideBarEditor.propTypes = {
  action: PropTypes.object.isRequired,
  allDiscounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  discount: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DiscountSideBarEditor;
