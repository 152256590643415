import 'animate.css';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  bottom: 5em;
  max-width: 30em;
  position: fixed;
  right: 1em;
  z-index: 99999;
`;

const MessageBox = styled.div`
  background: ${props => (props.type === 'error' ? 'red' : '#00c4cc')};
  border-radius: 10px;
  color: #fff;
  font-size: 1.2em;
  padding: 1em;
`;

const PopUpMsg = props => {
  const { msgDisappear: hideMessage, message, type } = props;

  const [className, setClassName] = useState('animated fadeInUp');

  useEffect(() => {
    const timerId = setTimeout(() => {
      setClassName('animated fadeOutDown');
      hideMessage();
    }, 3000);

    return () => clearTimeout(timerId);
  }, [message]);

  return (
    message && (
      <Container className={className}>
        <MessageBox type={type}>{message}</MessageBox>
      </Container>
    )
  );
};

PopUpMsg.propTypes = {
  message: PropTypes.string.isRequired,
  msgDisappear: PropTypes.func.isRequired,
  type: PropTypes.string,
};

PopUpMsg.defaultProps = {
  type: 'message',
};

export default PopUpMsg;
