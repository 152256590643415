import { gql } from '@apollo/client';

const GET_NEXT_OR_PREVIOUS_CONTENT_PAGE_QUERY = gql`
  query getNextOrPreviousContentPage(
    $contentPageId: Int!
    $navigationDirection: EContentPageNavigationDirection!
    $userFlowStepTrackId: Int!
  ) {
    contentPage {
      getNextOrPreviousPage(currentPageId: $contentPageId, navigation: $navigationDirection, trackingId: $userFlowStepTrackId) {
        id
        isCompleted(stepTrackingId: $userFlowStepTrackId)
        title
        url
      }
    }
  }
`;

export { GET_NEXT_OR_PREVIOUS_CONTENT_PAGE_QUERY };
