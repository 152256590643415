import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import MutationRequest from '../MutationRequest';

export const UPDATE_USER_TYPE = gql`
  mutation(
    $userTypeId: Int!
    $name: String!
    $permission: EMemberPermission!
    $approvalRequired: Boolean!
  ) {
    userType {
      updateUserType(
        userType: {
          userTypeId: $userTypeId
          name: $name
          permission: $permission
          approvalRequired: $approvalRequired
        }
      ) {
        id
        projectId
        name
        userTypeCode
        permission
        status
      }
    }
  }
`;

const updateUserTypeRequest = ({
  userTypeId,
  name,
  permission,
  approvalRequired,
  onError,
  onSuccessResult,
}) => (
  <Mutation client={Client()} mutation={UPDATE_USER_TYPE}>
    {(updateUserType, { loading, error, data }) => {
      const variables = {
        userTypeId,
        name,
        permission,
        approvalRequired,
      };

      if (error) {
        if (error.networkError.result) {
          onError(error.networkError.result.message);
        } else {
          onError(error.message);
        }
      }
      if (data) {
        onSuccessResult(data.userType.updateUserType);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={updateUserType}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

updateUserTypeRequest.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  approvalRequired: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  userTypeId: PropTypes.number.isRequired,
};

export default updateUserTypeRequest;
