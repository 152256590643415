import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

export const GET_ALL_APPS = gql`
  query getApps(
    $sorting: EAppSorting
    $after: String
    $first: Int
    $title: String
  ) {
    app {
      searchApps(
        sorting: $sorting
        after: $after
        first: $first
        title: $title
      ) {
        totalCount
        pageInfo {
          endCursor
        }
        edges {
          cursor
          node {
            id
            description
            identifier
            title
            averageRateValue
          }
        }
      }
    }
  }
`;

export default function GetAllAppsByPagination(props) {
  const { sorting, after, first, onError, onSuccess, title } = props;

  return (
    <Query
      fetchPolicy="network-only"
      query={GET_ALL_APPS}
      variables={{ sorting, after, first, title }}
    >
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) onError(error);
        if (!data || data.apps) return <span>No apps available</span>;

        onSuccess(
          data.app.searchApps.edges,
          data.app.searchApps.pageInfo.endCursor,
          data.app.searchApps.totalCount
        );
        return null;
      }}
    </Query>
  );
}

GetAllAppsByPagination.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  after: PropTypes.string,
  first: PropTypes.number,
  sorting: PropTypes.string,
  title: PropTypes.string,
};

GetAllAppsByPagination.defaultProps = {
  after: null,
  first: 10,
  sorting: null,
  title: undefined,
};
