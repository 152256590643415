import React, { useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import EditableText from '../EditableText/EditableText';
import ValidationProcessor from '../ErrorMessage/ValidationProcessor';
import PopupMessage from '../PopupMessage/PopupMessage';
import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import SideBarRight from '../SideBarRight/SideBarRight';

import {
  getAssetsUrl,
  errorMessageEnterTimeout,
  errorMessageLeaveTimeout,
} from '../../config';
import CreateMyAppComponentRequest from '../../middleware/MyAppDashboard/createMyAppComponentRequest';
import DeleteAppComponentRequest from '../../middleware/MyAppDashboard/deleteAppComponentRequest';
import GetMyAppVersionDetailsRequest from '../../middleware/MyAppDashboard/getMyAppVersionDetailsRequest';
import UpdateMyAppComponentNameRequest from '../../middleware/MyAppDashboard/updateMyAppComponentNameRequest';

function MyAppDetailPageSideBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [componentTitle, setComponentTitle] = useState('');
  const [sendRequest, setSendRequest] = useState(false);
  const [isRightOpen, setIsRightOpen] = useState(false);
  const [activeComponentId, setActiveComponentId] = useState(null);
  const [componentLists, setComponentLists] = useState([]);
  const [deleteComponentRequest, setDeleteComponentRequest] = useState(false);
  const [appIdentifier, setAppIdentifier] = useState('');
  const [appVersion, setAppVersion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(true);
  const [errorMessageSideLeft, setErrorMessageSideLeft] = useState('');
  const [updateComponentNameRequest, setUpdateComponentNameRequest] = useState(
    false
  );
  const [renameComponentFlag, setRenameComponentFlag] = useState(false);
  const [getAppVersionDetailRequest, setGetAppVersionDetailRequest] = useState(
    true
  );

  const handleToggleRightSideBar = () => {
    setIsRightOpen(!isRightOpen);
    setRenameComponentFlag(false);
  };

  const handleToggleRightSideBarForRename = () => {
    setIsRightOpen(!isRightOpen);
    setRenameComponentFlag(true);
  };
  const handleInputs = e => {
    const { value } = e.target;
    setComponentTitle(value);
  };

  const handleClearErrorMessages = () => {};

  const validate = () => ({
    AppVersionEmpty: {
      validation: componentTitle.trim().length > 0,
      errorMessage: 'Component title must not be empty',
    },
  });

  const handleOnSubmit = () => {
    const validationErrors = ValidationProcessor(validate());

    if (!validationErrors.modelValid) {
      setErrorMessage(validationErrors.validations);
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    } else if (renameComponentFlag) {
      setUpdateComponentNameRequest(true);
    } else {
      setSendRequest(true);
    }
  };

  const { appName, versionId } = props;
  const handleUpdatePageOpen = () => {
    // setOpenPanel(true);
    // setIsCreate(false);
  };

  const handleSideToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDownToggle = (id, title) => {
    const { onClickComponent } = props;
    setActiveComponentId(id);
    onClickComponent(id, title);
  };

  const handleNameSubmit = paraName => {
    if (!paraName.trim()) {
      setErrorMessageSideLeft('Component title must not be empty');
      setTimeout(() => {
        setErrorMessageSideLeft('');
      }, 2000);
    } else {
      setSendRequest(true);
      setComponentTitle(paraName);
    }
  };

  const handleOnCreateMyAppComponentResponse = data => {
    const { onComponentCreate, onClickComponent } = props;
    onComponentCreate();
    setSendRequest(false);
    setGetAppVersionDetailRequest(true);
    setIsRightOpen(false);
    if (data.appVersionComponentMutation.addComponentInVersion) {
      const dataObj = data.appVersionComponentMutation.addComponentInVersion;
      onClickComponent(dataObj.id, dataObj.title);
      setActiveComponentId(dataObj.id);
    }
  };

  const handleOnCreateMyAppComponentError = msg => {
    setSendRequest(false);
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
  };

  const handleDeleteComponentOnError = msg => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
    setDeleteComponentRequest(false);
  };

  const handleDeleteComponentOnResponse = () => {
    setGetAppVersionDetailRequest(true);
    setDeleteComponentRequest(false);
    setIsError(false);
    setErrorMessageSideLeft('Component deleted succesfully!');
    setTimeout(() => {
      setErrorMessageSideLeft('');
      setIsError(true);
    }, 2000);
  };

  const handleOnDeleteComponent = () => {
    setDeleteComponentRequest(true);
  };

  const handleGetAppDetailResponse = data => {
    const { onVersionDetailResponse, onClickComponent } = props;
    setAppIdentifier(data.app.identifier);
    if (!activeComponentId && data.components.length) {
      onClickComponent(data.components[0].id, data.components[0].title);
      setActiveComponentId(data.components[0].id);
    }
    setComponentLists(data.components);
    setAppVersion(data.version);
    onVersionDetailResponse(
      data.app.title,
      data.version,
      data.app.logoId,
      data.status,
      data.publishStatus
    );
    setGetAppVersionDetailRequest(false);
  };

  const handleGetAppDetailError = msg => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
    setGetAppVersionDetailRequest(false);
  };

  const handleRenameComponentOnResponse = () => {
    setGetAppVersionDetailRequest(true);
    setRenameComponentFlag(false);
    setUpdateComponentNameRequest(false);
    setIsRightOpen(!isRightOpen);
  };

  const handleRenameComponentOnError = msg => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
    setRenameComponentFlag(false);
    setUpdateComponentNameRequest(false);
    setIsRightOpen(!isRightOpen);
  };
  return (
    <>
      <div
        className={`content-sidebar-nav ${
          isOpen
            ? 'content-sidebar-nav__active'
            : 'content-sidebar-nav__inActive'
        }`}
      >
        <button
          className={`button-none content-sidebar-nav__trigger ${
            isOpen
              ? 'content-sidebar-nav__trigger__active'
              : 'content-sidebar-nav__trigger__inActive'
          }`}
          onClick={handleSideToggle}
          onKeyPress={handleSideToggle}
          type="button"
        >
          <span className="icon-chevron" />
        </button>
        <div className="sidebar-title bg-white">{appName}</div>
        {componentLists && !componentLists.length && (
          <div className="add-page  bg-white">
            <EditableText onSubmit={handleNameSubmit} text="Add Component" />
          </div>
        )}
        <nav className={classnames({ 'content-sidebar-nav--closed': !isOpen })}>
          <ul>
            {componentLists &&
              componentLists.map(component => (
                <li
                  className={classnames(
                    'content-sidebar-nav__item',
                    {
                      '--is-active-content': component.id === activeComponentId,
                    },
                    'd-flex align-items-center justify-content-between'
                  )}
                  key={component.id}
                >
                  <button
                    className="content-sidebar-nav__button button-none padding-1-left"
                    onClick={handleDownToggle.bind(
                      this,
                      component.id,
                      component.title
                    )}
                    type="button"
                  >
                    : {component.title}
                  </button>
                  <div className="content-sidebar-nav__btn-actions">
                    {activeComponentId === component.id && (
                      <div className="mr-3">
                        <div className="d-flex align-items-center">
                          <div className="dropdown">
                            <button
                              className="button-none menu-icon-container d-flex justify-content-center"
                              type="button"
                            >
                              <img
                                alt="edit"
                                aria-expanded="false"
                                aria-haspopup="true"
                                className="content-edit-icon"
                                data-toggle="dropdown"
                                id="dropdownMenuButton"
                                src={`${getAssetsUrl()}/icons/button-of-three-vertical-squares.svg`}
                              />
                            </button>
                            {/* <EditBox>
                              <EditBtn />
                            </EditBox> */}
                            <div
                              aria-labelledby="dropdownMenuButton"
                              className="dropdown-menu"
                            >
                              <button
                                className="dropdown-item"
                                onClick={handleToggleRightSideBar}
                                type="button"
                              >
                                New component
                              </button>
                              <button className="dropdown-item" type="button">
                                Duplicate
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={handleToggleRightSideBarForRename}
                                type="button"
                              >
                                Rename
                              </button>
                              <button className="dropdown-item" type="button">
                                Share
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={handleOnDeleteComponent}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          <button
                            className="button-none menu-icon-container ml-1 d-flex justify-content-center"
                            onClick={handleUpdatePageOpen}
                            onKeyPress={handleUpdatePageOpen}
                            type="button"
                          >
                            <img
                              alt="edit"
                              className="content-edit-icon"
                              src={`${getAssetsUrl()}/icons/settings-work-tool.svg`}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              ))}
          </ul>
        </nav>
      </div>
      <SideBarRight open={isRightOpen}>
        <button
          className="c-sidebar-right__close"
          onClick={handleToggleRightSideBar}
          onKeyDown={handleToggleRightSideBar}
          type="button"
        >
          <span className="icon-plus" />
        </button>
        <strong className="c-sidebar-right-title text-bold">
          {renameComponentFlag ? 'Rename Component' : 'Create a Component'}
        </strong>

        <label className="text-left mt-2 " htmlFor="name">
          <strong>Component Title</strong>
          <br />
          <input
            aria-label="Component Title"
            className="w-100 bg-transparent p-2 border"
            name="appVersion"
            onChange={handleInputs}
            onClick={handleClearErrorMessages}
            required=""
            value={componentTitle}
          />
        </label>

        <div className="text-center">
          <button
            className="button--black mt-4 mr-3"
            onClick={handleToggleRightSideBar}
            type="button"
          >
            Cancel
          </button>
          <button
            className="mt-4  button--blue"
            onClick={handleOnSubmit}
            type="submit"
          >
            Save
          </button>
        </div>
      </SideBarRight>
      {sendRequest && (
        <CreateMyAppComponentRequest
          componentTitle={componentTitle}
          identifier={appIdentifier}
          onError={handleOnCreateMyAppComponentError}
          onSuccessResult={handleOnCreateMyAppComponentResponse}
          version={appVersion}
        />
      )}
      {deleteComponentRequest && (
        <DeleteAppComponentRequest
          componentId={activeComponentId}
          onError={handleDeleteComponentOnError}
          onSuccessResult={handleDeleteComponentOnResponse}
        />
      )}
      {getAppVersionDetailRequest && (
        <GetMyAppVersionDetailsRequest
          onError={handleGetAppDetailError}
          onSuccessResult={handleGetAppDetailResponse}
          versionId={Number(versionId)}
        />
      )}
      {updateComponentNameRequest && (
        <UpdateMyAppComponentNameRequest
          componentId={activeComponentId}
          onError={handleRenameComponentOnError}
          onSuccessResult={handleRenameComponentOnResponse}
          title={componentTitle}
        />
      )}
      <ReactCSSTransitionGroup
        transitionEnterTimeout={errorMessageEnterTimeout}
        transitionLeaveTimeout={errorMessageLeaveTimeout}
        transitionName="dialog-popup"
      >
        {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
        {errorMessageSideLeft !== '' && (
          <PopupMessage isError={isError} message={errorMessageSideLeft} />
        )}
      </ReactCSSTransitionGroup>
    </>
  );
}

MyAppDetailPageSideBar.propTypes = {
  appName: PropTypes.string.isRequired,
  onClickComponent: PropTypes.func.isRequired,
  onComponentCreate: PropTypes.func.isRequired,
  onVersionDetailResponse: PropTypes.func.isRequired,
  versionId: PropTypes.number.isRequired,
};

MyAppDetailPageSideBar.defaultProps = {};

export default MyAppDetailPageSideBar;
