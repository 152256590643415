import React, { useState } from 'react';

import { useCookie } from '@netfront/common-library';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginForm } from './LoginForm';

import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { graphqlErrorToString } from '../../utils/utils';

function LoginUser({ isLogin, onResetPassword, projectId }) {
  let redirection = '/dashboard';

  const cookies = new Cookies();
  const customBuildCookie = cookies.get('customBuild');

  if (customBuildCookie !== undefined) {
    const { projectId: cookieProjectId, orgId } = cookies.get('customBuild');
    redirection = `console/${orgId}/project/${cookieProjectId}`;
  }

  const { getAccessTokenCookie } = useCookie();

  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);

  function onComplete(data, result) {
    if (result === 'data') {
      setRedirect(true);
    } else {
      setErrorMessage(graphqlErrorToString(data));
    }
  }

  return (
    <div className="w-100 h-100  d-flex flex-column justify-content-between">
      {isLogin ? (
        <LoginForm projectId={projectId} setError={setErrorMessage} onComplete={onComplete} />
      ) : (
        <ForgotPasswordForm setError={setErrorMessage} onComplete={onComplete} onResetPassword={onResetPassword} />
      )}
      {(redirect || getAccessTokenCookie()) && <Redirect to={redirection} />}

      {errorMessage !== '' && <ErrorMessage message={errorMessage} />}
    </div>
  );
}

LoginUser.propTypes = {
  isLogin: PropTypes.bool,
  onResetPassword: PropTypes.func,
  projectId: PropTypes.string,
};

LoginUser.defaultProps = {
  isLogin: false,
  onResetPassword: () => {},
  projectId: undefined,
};

export default LoginUser;
