import isEqual from 'lodash.isequal';
import isNil from 'lodash.isnil';

import { ISaveContentSnippetQuestionAndApplyQuestionActionsParams } from './QuestionSnippet.interfaces';

import { getUpdatedContentGroupingElements } from '../../../contexts';
import { IQuestionAnswer } from '../../../interfaces';

const saveContentSnippetQuestionAndApplyQuestionActions = ({
  actions,
  answer,
  availableResponses,
  contentPage,
  contentSnippetId,
  dispatch,
  selectedQuestionAnswers,
}: ISaveContentSnippetQuestionAndApplyQuestionActionsParams): void => {
  if (!contentPage) {
    return;
  }

  const {
    calendarDateAnswered,
    checkboxSelectedAnswers = [],
    dropdownListId,
    multiResponseTextItems = [],
    numberAnswered,
    radioId,
    text,
    value,
  } = selectedQuestionAnswers;

  const updatedAnswer: IQuestionAnswer = answer
    ? {
        ...answer,
      }
    : ({} as IQuestionAnswer);

  if (!isNil(calendarDateAnswered) && !isEqual(calendarDateAnswered, updatedAnswer.dateAnswered)) {
    updatedAnswer.dateAnswered = calendarDateAnswered;
  }

  if (!isNil(checkboxSelectedAnswers) && !isEqual(checkboxSelectedAnswers, updatedAnswer.selectedAnswers)) {
    updatedAnswer.selectedAnswers = checkboxSelectedAnswers;
  }

  if (!isNil(dropdownListId) && !isEqual(dropdownListId, updatedAnswer.questionResponseDropDownListId)) {
    updatedAnswer.questionResponseDropDownListId = Number(dropdownListId);
  }

  if (!isNil(radioId) && !isEqual(radioId, updatedAnswer.questionResponseRadioId)) {
    updatedAnswer.questionResponseRadioId = Number(radioId);
  }

  if (!isNil(multiResponseTextItems) && !isEqual(multiResponseTextItems, updatedAnswer.responses)) {
    updatedAnswer.responses = multiResponseTextItems;
  }

  if (!isNil(numberAnswered) && !isEqual(numberAnswered, updatedAnswer.numberAnswered)) {
    updatedAnswer.numberAnswered = Number(numberAnswered);
  }

  if (!isNil(text) && !isEqual(text, updatedAnswer.text)) {
    updatedAnswer.text = text;
  }

  if (!isNil(value) && !isEqual(value, updatedAnswer.value)) {
    updatedAnswer.value = Number(value);
  }

  if (isEqual(answer, updatedAnswer)) {
    return;
  }

  const { contentSectionContainers, contentSections, contentSnippets, sectionGroups } = getUpdatedContentGroupingElements(
    contentPage,
    contentSnippetId,
    actions,
    updatedAnswer
  );

  dispatch({
    payload: {
      answer: updatedAnswer,
      availableResponses,
      contentSnippetQuestionId: contentSnippetId,
    },
    type: 'saveContentSnippetQuestion',
  });

  dispatch({
    payload: {
      contentSectionContainers,
      contentSections,
      contentSnippets,
      sectionGroups,
    },
    type: 'applyQuestionActions',
  });
};

export { saveContentSnippetQuestionAndApplyQuestionActions };
