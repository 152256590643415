import { gql } from '@apollo/client';

const SET_USER_ANSWER_MULTI_LINE_TEXT_MUTATION = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $text: String!
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        contentSnippetFormId: $contentSnippetFormId
        answerMultilineText: {
          contentPageId: $contentPageId
          contentSnippetFormId: $contentSnippetFormId
          contentSnippetQuestionId: $contentSnippetQuestionId
          status: $status
          text: $text
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

export { SET_USER_ANSWER_MULTI_LINE_TEXT_MUTATION };
