import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  background-color: #fff;
  border: 2px solid ${({ color }) => color};
  border-color: ${({ color }) => color};
  border-radius: 5px;
  color: ${({ color }) => color};
  font-weight: 600;
  line-height: 2em;
  margin: ${({ margin }) => margin};
  padding: 0.5em 1em;
  transition: 0.25s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ color }) => color};
    color: #fff;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'cursor')};
  }
`;

export default function ButtonOutline(props) {
  const { color, margin, onClick, text, disabled = false } = props;

  return (
    <Button color={color} disabled={disabled} margin={margin} onClick={onClick}>
      {text}
    </Button>
  );
}

ButtonOutline.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ButtonOutline.defaultProps = {
  color: '#262542',
};
