import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import ColorInput from '../UI/ColorPicker/ColorInput';
import Dropdown from '../UI/Dropdown/Dropdown';
import AnimationPart from '../UI/SidebarComp/AnimationPart';
import EventPart from '../UI/SidebarComp/EventPart';
import SidebarTab from '../UI/Tab/SidebarTab';

import { ActionTab } from './ActionTab/ActionTab';
import RightNavBar from './RightNavBar';
import { MAX_CHARACTER_COUNT_TITLE, MAX_AUTOCOMPLETE_SUGGESTIONS } from './RightNavBar.constants';
import { Container, Title, ColorItem, Item } from './styled';

import AppContext from '../../context/AppContext';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import { GET_CONTAINER } from '../../middleware/Container/get';
import CreateContentEventRequest from '../../middleware/ContentEvent/createContentEventRequest';
import DeleteContentEventRequest from '../../middleware/ContentEvent/deleteContentEventRequest';
import UpdateContentEventRequest from '../../middleware/ContentEvent/updateContentEventRequest';
import UpdateContainerStyle from '../../middleware/ContentSection/updateContainerStyle';
import { ATTACH_TO_CONTAINER } from '../../middleware/CssStyle/attachToContainer';
import { DETACH_STYLE } from '../../middleware/CssStyle/detachStyle';
import { GET_CSS_STYLE_PER_PROJECT } from '../../middleware/CssStyle/getCssPerProject';

const ContentContainerSideBar = (props) => {
  const { projectId } = useParams();

  const { activePageId, onRefreshPage, onContainerDelete } = props;

  const { showRightSidebar, handleShowSidebar } = useContext(AppContext);

  const [customStyle, setCustomStyle] = useState(null);
  const [customThemes, setCustomThemes] = useState([]);
  const [selectedCustomTheme, setSelectedCustomTheme] = useState('NONE');
  const [updateRequest, setUpdateRequest] = useState(false);
  const [container, setContainer] = useState({
    ...showRightSidebar[2][2],
    containerId: showRightSidebar[2][1],
    sectionId: showRightSidebar[2][0],
  });
  const [selectedTab, setSelectedTab] = useState(undefined);

  const [attachStyle] = useMutation(ATTACH_TO_CONTAINER, {
    client,
  });

  const [detach] = useMutation(DETACH_STYLE, {
    client,
  });

  const [getStyle] = useLazyQuery(GET_CSS_STYLE_PER_PROJECT, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      projectId,
    },
    onCompleted: (data) => {
      const defaultThemeOption = [
        {
          id: 0,
          value: 'NONE',
          label: 'Please select a custom theme',
        },
      ];

      const projectThemeOptions = data.cssStyle.getPerProject.map((style) => ({
        id: style.id,
        value: style.name,
        label: style.name,
      }));

      const themes = [...defaultThemeOption, ...projectThemeOptions];

      if (customStyle) {
        const theme = themes.find((c) => c.id === customStyle.id);
        setSelectedCustomTheme(theme.value);
      }
      setCustomThemes(themes);
    },
  });

  useQuery(GET_CONTAINER, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      containerId: showRightSidebar[2][1],
    },
    onCompleted: (data) => {
      setCustomStyle(data.container.get?.cssStyle);
      getStyle();
    },
  });

  const [createRequest, setCreateRequest] = useState(false);
  const [edit, setEdit] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [eventDetail, setEventDetail] = useState({
    title: '',
    type: 'NONE',
    eventDirection: 'TOP',
    assetId: null,
    backgroundColor: null,
    borderColor: null,
    titleColor: null,
    style: 'NONE',
    triggerStyle: 'CIRCLE_ARROW',
    iconHorizontalAlignment: null,
  });
  const [animationDetail, setAnimationDetail] = useState({
    animationType: 'NONE',
    delayTime: 1000,
    animationSpeed: 1000,
    infinite: false,
  });
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    if (showRightSidebar[2][2].contentEvent) {
      let tmpAssetId = null;
      let iconUrl = null;
      if (
        showRightSidebar[2][2].contentEvent &&
        showRightSidebar[2][2].contentEvent.asset &&
        Object.keys(showRightSidebar[2][2].contentEvent.asset).length > 0
      ) {
        iconUrl = getSignedUrl(showRightSidebar[2][2].contentEvent.asset.s3Key, showRightSidebar[2][2].contentEvent.asset.contentType);
        tmpAssetId = showRightSidebar[2][2].contentEvent.asset.assetId;
      }
      setLogoUrl(iconUrl);
      setEventDetail({
        title: showRightSidebar[2][2].contentEvent.eventTitle,
        type: showRightSidebar[2][2].contentEvent.event,
        eventDirection: showRightSidebar[2][2].contentEvent.eventDirection,
        borderColor: showRightSidebar[2][2].contentEvent.borderColor,
        backgroundColor: showRightSidebar[2][2].contentEvent.backgroundColor,
        titleColor: showRightSidebar[2][2].contentEvent.titleColor,
        assetId: tmpAssetId,
        style: showRightSidebar[2][2].contentEvent.style,
        triggerStyle: showRightSidebar[2][2].contentEvent.triggerStyle,
        iconHorizontalAlignment: showRightSidebar[2][2].contentEvent.iconHorizontalAlignment,
      });
      setAnimationDetail({
        animationType: showRightSidebar[2][2].contentEvent.animation,
        delayTime: showRightSidebar[2][2].contentEvent.millisecondDelay,
        animationSpeed: showRightSidebar[2][2].contentEvent.millisecondSpeed,
        infinite: showRightSidebar[2][2].contentEvent.infinite,
      });
      setEventId(showRightSidebar[2][2].contentEventId);
      setEdit(true);
    }
  }, [showRightSidebar]);

  const handleCloseSideBar = () => {
    handleShowSidebar([false, '', null]);
  };
  const handleSaveBtn = () => {
    if (selectedCustomTheme !== 'NONE') {
      attachStyle({
        variables: {
          containerId: showRightSidebar[2][1],
          styleId: customThemes.find((c) => c.value === selectedCustomTheme).id,
        },
      });
    } else {
      detach({
        variables: {
          containerId: showRightSidebar[2][1],
        },
      });
    }
    setUpdateRequest(true);
  };

  const handleEventUpdate = (value) => {
    setEventDetail(value);
  };

  const handleAnimationUpdate = (value) => {
    setAnimationDetail(value);
  };

  const handleStyleUpdate = (value, e) => {
    const newInfo = {
      ...container,
      [e.target.name]: value,
    };
    setContainer(newInfo);
  };

  // ********************** middleware *************************

  const handleUpdateError = () => {
    setUpdateRequest(false);
    setCreateRequest(true);
  };

  const handleUpdateSuccess = () => {
    setUpdateRequest(false);
    setCreateRequest(true);
  };

  const handleCreateEventError = () => {
    setCreateRequest(false);
    handleCloseSideBar();
  };

  const handleCreateEventSuccess = () => {
    setCreateRequest(false);
    onRefreshPage();
    handleCloseSideBar();
  };

  const handleUpdateEventError = () => {
    setCreateRequest(false);
    handleCloseSideBar();
  };

  const handleUpdateEventSuccess = () => {
    setCreateRequest(false);
    onRefreshPage();
    handleCloseSideBar();
  };

  const handleDeleteEventError = () => {
    setDeleteRequest(true);
  };

  const handleDeleteEventSuccess = () => {
    setDeleteRequest(true);
  };

  const handleContainerDelete = () => {
    handleCloseSideBar();
    const updatedContainer = {
      ...container,
      id: container.containerId,
    };
    onContainerDelete(updatedContainer);
  };

  const handleSetSelectedTab = (_, event) => {
    const {
      target: { textContent },
    } = event;

    setSelectedTab(textContent);
  };

  const eventTab = (
    <EventPart
      currentDetail={eventDetail}
      icon={logoUrl}
      projectId={projectId}
      setIcon={(value) => setLogoUrl(value)}
      updateEventDetail={handleEventUpdate}
    />
  );

  const animationTab = <AnimationPart currentDetail={animationDetail} updateEventDetail={handleAnimationUpdate} />;

  const handleSelectedThemeChanged = (id) => {
    const theme = customThemes.find((c) => c.id === Number(id));
    setSelectedCustomTheme(theme.value);
  };

  const styleTab = (
    <Container>
      {customThemes.length > 0 && (
        <Item>
          <Title>Custom theme</Title>
          <Dropdown
            availableResponses={customThemes}
            name="customTheme"
            selectedValue={selectedCustomTheme}
            onChangeHandler={handleSelectedThemeChanged}
          />
        </Item>
      )}
      <ColorItem>
        <Title>Border colour Container</Title>
        <ColorInput currentColor={container.borderColor} getColor={handleStyleUpdate} id="color-1" name="borderColor" />
      </ColorItem>
      <ColorItem>
        <Title>Background colour</Title>
        <ColorInput currentColor={container.backgroundColor} getColor={handleStyleUpdate} id="color-2" name="backgroundColor" />
      </ColorItem>
    </Container>
  );

  const actionWrapper = {
    id: container.containerId,
    type: 'container',
  };

  const isActionTab = selectedTab === 'Action';

  const actionTab = (
    <Container>
      <ActionTab
        actionWrapper={actionWrapper}
        activePageId={activePageId}
        maxAutoCompleteSuggestions={MAX_AUTOCOMPLETE_SUGGESTIONS}
        maxCharacterCountTitle={MAX_CHARACTER_COUNT_TITLE}
        onClose={handleCloseSideBar}
      />
    </Container>
  );

  const tabItems = [
    {
      title: 'Event',
      content: eventTab,
    },
    {
      title: 'Animation',
      content: animationTab,
    },
    {
      title: 'Style',
      content: styleTab,
    },
    {
      title: 'Action',
      content: isActionTab && actionTab,
    },
  ];

  return (
    <>
      <RightNavBar
        hasConfirmButton={!isActionTab}
        hasDelete={!isActionTab}
        onCancel={handleCloseSideBar}
        onDelete={() => {
          setDeleteRequest(true);
          handleContainerDelete();
        }}
        onSave={handleSaveBtn}
      >
        <div>
          <h3>Container Advance</h3>
          <SidebarTab items={tabItems} selectedTab={handleSetSelectedTab} />
        </div>
      </RightNavBar>
      {updateRequest && (
        <UpdateContainerStyle
          backgroundColor={container.backgroundColor}
          borderColor={container.borderColor}
          containerId={container.containerId}
          sectionId={container.sectionId}
          onError={handleUpdateError}
          onSuccessResult={handleUpdateSuccess}
        />
      )}
      {createRequest && !edit && (
        <CreateContentEventRequest
          animation={animationDetail.animationType}
          animationDirection={animationDetail.animationDirection || null}
          assetId={eventDetail.assetId}
          backgroundColor={eventDetail.backgroundColor}
          borderColor={eventDetail.borderColor}
          contentEntity={showRightSidebar[1]}
          entityId={showRightSidebar[2][1]}
          event={eventDetail.type}
          eventDirection={eventDetail.eventDirection || 'TOP'}
          eventTitle={eventDetail.title}
          iconHorizontalAlignment={eventDetail.iconHorizontalAlignment}
          infinite={animationDetail.infinite}
          millisecondDelay={animationDetail.delayTime}
          millisecondSpeed={animationDetail.animationSpeed}
          style={eventDetail.style}
          titleColor={eventDetail.titleColor}
          triggerStyle={eventDetail.triggerStyle}
          onError={handleCreateEventError}
          onSuccessResult={handleCreateEventSuccess}
        />
      )}
      {createRequest && edit && eventId && (
        <UpdateContentEventRequest
          animation={animationDetail.animationType}
          animationDirection={animationDetail.animationDirection}
          assetId={eventDetail.assetId}
          backgroundColor={eventDetail.backgroundColor}
          borderColor={eventDetail.borderColor}
          contentEventId={eventId}
          event={eventDetail.type}
          eventDirection={eventDetail.eventDirection || 'TOP'}
          eventTitle={eventDetail.title}
          iconHorizontalAlignment={eventDetail.iconHorizontalAlignment}
          infinite={animationDetail.infinite}
          millisecondDelay={animationDetail.delayTime}
          millisecondSpeed={animationDetail.animationSpeed}
          style={eventDetail.style}
          titleColor={eventDetail.titleColor}
          triggerStyle={eventDetail.triggerStyle}
          onError={handleUpdateEventError}
          onSuccessResult={handleUpdateEventSuccess}
        />
      )}
      {deleteRequest && eventDetail.id && (
        <DeleteContentEventRequest
          contentEventId={eventDetail.id}
          onError={handleDeleteEventError}
          onSuccessResult={handleDeleteEventSuccess}
        />
      )}
    </>
  );
};

ContentContainerSideBar.propTypes = {
  activePageId: PropTypes.number.isRequired,
  onRefreshPage: PropTypes.func.isRequired,
};

export default ContentContainerSideBar;
