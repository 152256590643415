import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import teamImage from '../../static/img/team.svg';
import userImage from '../../static/img/users.svg';

const CardBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -1.7em;
`;

const ProjectUserManagementPage = props => {
  const {
    match: { url, params },
  } = props;

  const { project } = useContext(AppContext);
  const featureCard = [
    {
      id: 0,
      image: userImage,
      title: 'Public User',
      path: `${url}/public`,
    },
    {
      id: 1,
      image: teamImage,
      title: 'Team',
      path: `${url}/team`,
    },
  ];

  const content = (
    <CardBox>
      {featureCard.length &&
        featureCard.map(item => (
          <MenuCard
            href={item.path}
            key={item.id}
            name={item.title}
            src={item.image}
            url={item.path}
          />
        ))}
    </CardBox>
  );

  const breadPath = [
    {
      id: 1,
      path: ``,
      title: 'User management',
    },
  ];

  return (
    <>
      <PageWrapper
        breadPath={breadPath}
        info={`Manage your users in ${project.name}`}
        pageDescription={`Manage your users in ${project.name}`}
        pageTitle="Project user management page"
        params={params}
      >
        <ProjectNavBar title={project.name} />
        {/* <BottomNavBar /> */}
        {content}
      </PageWrapper>
    </>
  );
};

ProjectUserManagementPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string,
      projectId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
};

export default ProjectUserManagementPage;
