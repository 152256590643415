import React from 'react';

import PropTypes from 'prop-types';

import SideBarRight from '../../components/SideBarRight/SideBarRight';

export default function UpdatePayment({
  closePanel,
  openPanel,
  saveDefaultPayment,
}) {
  return (
    <SideBarRight open={openPanel}>
      <button
        className="c-sidebar-right__close"
        onClick={closePanel}
        onKeyDown={closePanel}
        type="button"
      >
        <span className="icon-plus" />
      </button>
      <div className="c-sidebar-right-form">
        <p className="c-sidebar-right-title">
          Update this payment method as default payment method
        </p>
        <div className="text-right">
          <button
            className="button--black"
            onClick={closePanel}
            style={{ margin: '1.5em 1em 1.5em 0' }}
            type="button"
          >
            Cancel
          </button>
          <button
            className="button--blue"
            onClick={saveDefaultPayment}
            style={{ margin: '1.5em 0' }}
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </SideBarRight>
  );
}

UpdatePayment.propTypes = {
  closePanel: PropTypes.func.isRequired,
  openPanel: PropTypes.string.isRequired,
  saveDefaultPayment: PropTypes.func.isRequired,
};
