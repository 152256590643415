import { gql } from '@apollo/client';

import { CONTENT_PAGE_CONTEXT_GRAPHQL } from '../../contexts';

const GET_CONTENT_GROUP_QUERY = gql`
  query getContentGroup(
    $contentGroupId: Int!
    $contentPageId: Int
    $topLevelOnly: Boolean
    $userFlowStepTrackId: Int!
    $withAnswers: Boolean!
  ) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        contentPages(status: [PUBLISHED, UNPUBLISHED], topLevelOnly: $topLevelOnly) {
          ...contentPage
        }
        description
        iconAsset {
          contentType
          s3Key
        }
        regions {
          iconSize
          iconType
          id
          x
          y
        }
        title
        url
      }
    }
  }

  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.asset}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.bubbleText}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentEvent}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentEventOnPage}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentPage}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.app}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.audio}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.avatar}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.button}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.cluster}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.code}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.document}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.embed}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.form}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.image}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.imageMap}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.map}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.question}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.quote}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.slider}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.spacer}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.summary}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.summaryResult}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.text}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.contentSnippet.video}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.cssStyle}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.margin}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.calendar}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.checkbox}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.checkboxResponseCheckbox}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.dropdownList}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.interface}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.match}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.multiLineText}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.multiResponseText}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.multiResponseTextItem}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.number}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.radio}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.singleLineText}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionAnswer.slider}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.questionConfigurationMessage}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.responseSet}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.sectionGroupTab}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.summaryFeedbackConfigurationRange}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.summaryFeedbackConfigurationRankAndNote}
  ${CONTENT_PAGE_CONTEXT_GRAPHQL.fragments.tag}
`;

export { GET_CONTENT_GROUP_QUERY };
