import React from 'react';
import editSVG from '../../../static/img/icon-edit.svg';
import deleteIcon from '../../../static/img/icon-bin.svg';
import AudioPlayer from '../../UI/AudioPlayer/AudioPlayer';
import { TextItem, AudioContainer, EditButton, DeleteButton, ButtonContainer, AudioItemContainer } from '../styled';

const UploadAudioTab = ({ handleDeleteAsset, handleEditAudio, presignedUrl, fileName }) => {
  return (
    <AudioContainer>
      <AudioItemContainer justifyContent="space-between">
        <AudioPlayer margin="0 1rem 0 1rem" sideLengths="2.5rem" selected={presignedUrl} />
        <TextItem lineHeight="3.3rem">{fileName}</TextItem>
      </AudioItemContainer>
      <ButtonContainer>
        <DeleteButton onClick={handleDeleteAsset}>
          <img src={deleteIcon} alt="Delete the audio" />
        </DeleteButton>
        <EditButton onClick={handleEditAudio}>
          <img src={editSVG} alt="Edit the audio" />
        </EditButton>
      </ButtonContainer>
    </AudioContainer>
  );
};

export default UploadAudioTab;
