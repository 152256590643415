import { gql } from '@apollo/client';

const DELETE_USER_GENERATED_RESPONSE_MUTATION = gql`
  mutation deleteUserGeneratedResponse($questionResponseId: Int!, $questionResponseSetId: Int!) {
    questionSet {
      removeAvailableAnswer(responseId: $questionResponseId, responseSetId: $questionResponseSetId)
    }
  }
`;

export { DELETE_USER_GENERATED_RESPONSE_MUTATION };
