import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  left: 0px;
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  position: absolute;
  right: 0px;
  width: 90%;
  z-index: 999;
  background-color: #fff;
`;
// removed pointer-events: none; from above style for

function UpdatePopup(props) {
  const { closePopup, updateConfirmation, type } = props;

  const handleToggle = e => {
    e.preventDefault();
    if (e.currentTarget === e.target) {
      closePopup();
    }
  };

  return (
    <div
      className="popup d-flex align-items-center justify-content-center"
      onClick={handleToggle}
      role="presentation"
    >
      <Modal>
        <h3 className="bg-light-blue py-3 pl-3 text-white">
          Deactivate {type === 'organisation' ? 'organisation' : 'project'}
        </h3>

        <div className="p-3">
          <p className="mb-3">
            {type === 'organisation'
              ? 'By deactivating your organisation, all project users will not be able to access this project.'
              : 'By deactivating your project, all project users will not be able to acces this project'}
          </p>

          <div className="d-flex justify-content-end">
            <button
              className="button--red mr-3 px-5"
              onClick={updateConfirmation}
              type="button"
            >
              DEACTIVATE
            </button>
            <button
              className="button--outline px-4"
              onClick={closePopup}
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

UpdatePopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  updateConfirmation: PropTypes.func.isRequired,
};

export default UpdatePopup;
