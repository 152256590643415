import React, { useState } from 'react';

import { API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS } from '@netfront/common-library';
import { useDebouncedCallback } from 'use-debounce';

import { DEFAULT_MESSAGE, DEFAULT_API_SUCCESS_MESSAGE, DEFAULT_API_ERROR_MESSAGE } from '../QuestionSnippet.constants';
import { handleApolloError } from '../QuestionSnippet.handlers';
import { saveContentSnippetQuestionAndApplyQuestionActions } from '../QuestionSnippet.helpers';

import { QuestionAnswerSingleLineTextSnippetProps } from './QuestionAnswerSingleLineTextSnippet.interfaces';

import {
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  getContentSnippet,
  getContentSnippetFormId,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerTextMutationVariables,
  useContentPageContext,
} from '../../../../contexts';
import { useEkardoMutation } from '../../../../hooks';
import { IContentPage, IQuestionAnswerSingleLineText, IQuestionConfigurationSingleLineText } from '../../../../interfaces';
import { SingleLineTextSnippet } from '../../SingleLineTextSnippet';
import { getMutationBaseVariables } from '../../Snippets.helpers';
import { IMessage } from '../../Snippets.interfaces';

const QuestionAnswerSingleLineTextSnippet = ({
  accessToken,
  contentSnippetId,
  contentSnippetQuestion,
  userFlowStepTrackId,
}: QuestionAnswerSingleLineTextSnippetProps) => {
  const { dispatch, state: contentPage } = useContentPageContext();
  const { actions, id: contentPageId } = contentPage as IContentPage;

  const contentSnippet = contentSnippetQuestion ?? getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { answer, configuration, mandatory: isMandatory = false } = contentSnippet ?? {};
  const { text = '' } = answer ?? ({} as IQuestionAnswerSingleLineText);
  const { maxLength, minLength } = configuration ?? ({} as IQuestionConfigurationSingleLineText);

  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);
  const [savedText, setSavedText] = useState<string>(text);

  const [executeSetUserAnswerSingleLineText] = useEkardoMutation<
    ISetUserAnswerMutationGraphQLResponse,
    ISetUserAnswerTextMutationVariables
  >({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerSingleLineText,
    options: {
      onCompleted: () => {
        saveContentSnippetQuestionAndApplyQuestionActions({
          actions,
          answer,
          contentPage,
          contentSnippetId,
          dispatch,
          selectedQuestionAnswers: {
            text: savedText,
          },
        });

        setMessage({
          error: '',
          success: DEFAULT_API_SUCCESS_MESSAGE,
        });
      },
      onError: (error) => {
        handleApolloError(error);
        setMessage({
          error: DEFAULT_API_ERROR_MESSAGE,
          success: '',
        });
      },
    },
    token: accessToken,
  });

  const clearMessage = () => {
    setMessage(DEFAULT_MESSAGE);
  };

  const debouncedMutation = useDebouncedCallback((inputText: string) => {
    if (!contentSnippetFormId) {
      return;
    }

    const baseVariables = getMutationBaseVariables(contentPageId, contentSnippetFormId, contentSnippetId, Number(userFlowStepTrackId));

    const variables: ISetUserAnswerTextMutationVariables = {
      ...baseVariables,
      text: inputText,
    };

    executeSetUserAnswerSingleLineText({
      variables,
    });
  }, API_MUTATION_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS);

  const handleTextChange = (inputText: string) => {
    clearMessage();
    debouncedMutation(inputText);
    setSavedText(inputText);
  };

  const isSummary = Boolean(contentSnippetQuestion);

  return (
    <SingleLineTextSnippet
      id={`single-line-text-snippet-${contentSnippetId}`}
      isDisabled={isSummary}
      isRequired={isMandatory}
      maxLength={maxLength}
      message={message}
      minLength={minLength}
      value={savedText}
      onChange={(value) => handleTextChange(value)}
    />
  );
};

export { QuestionAnswerSingleLineTextSnippet };
