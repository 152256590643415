import React from 'react';

import PropTypes from 'prop-types';

import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';

function ImageSnippet(props) {
  const { asset } = props;

  const tmpURL = getSignedUrl(asset.s3Key, asset.contentType);

  return (
    <>
      <img
        alt="asset"
        className="img-fluid"
        src={tmpURL}
        style={{ width: '100%' }}
      />
    </>
  );
}

ImageSnippet.propTypes = {
  asset: PropTypes.shape({
    contentType: PropTypes.string,
    s3Key: PropTypes.string,
  }).isRequired,
};

export default ImageSnippet;
