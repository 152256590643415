import React, { useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { Avatar, Dropdown, UserNavigation } from '@netfront/ui-library';
import PropTypes from 'prop-types';

export default function AvatarMenu({ user }) {
  const { isDomainReady } = useDomain();
  const { getLogoutUrl, getProfileUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [isSearchContentVisible, setSearchIsContentVisible] = useState(false);
  const [logoutUrl, setLogoutUrl] = useState();
  const [profileUrl, setProfileUrl] = useState();

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLogoutUrl(getLogoutUrl());
    setProfileUrl(getProfileUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      {user && (
        <Dropdown
          dropdownId="profile-dropdown"
          isDisplayContent={isSearchContentVisible}
          trigger={<Avatar size="small" title={`${user.firstName} ${user.lastName}`} />}
          onDisplayContent={setSearchIsContentVisible}
        >
          <UserNavigation
            userNavigationListItems={[
              {
                href: String(profileUrl),
                name: 'Profile',
              },
              {
                href: logoutUrl,
                name: 'Logout',
              },
            ]}
          />
        </Dropdown>
      )}
    </>
  );
}

AvatarMenu.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
  }).isRequired,
};
