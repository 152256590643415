import React from 'react';

import SquareButton from '../Button/SquareButton';

import NormalModal from './NormalModal';

import { ModalButtonGroup } from '../../RightNavBar/RightNavBar';

const ConfirmModal = ({ modal, onCloseModal, onConfirm }) => (
  <NormalModal isOpen={Boolean(modal)} onClose={onCloseModal}>
    {modal.message}
    <ModalButtonGroup>
      <SquareButton isClicked={onCloseModal}>No, cancel</SquareButton>
      <SquareButton bgColor="#dc3545" color="white" isClicked={onConfirm}>
        Yes, delete
      </SquareButton>
    </ModalButtonGroup>
  </NormalModal>
);

export { ConfirmModal };
