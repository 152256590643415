import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_GROUP_INVITATION_LIST_REQUEST = gql`
  query getGroupsInvitations($projectId: String!) {
    invitation {
      getGroupsInvitationsWithinProject(projectId: $projectId) {
        id
        invitedUserEmail: emailUserInvited
        firstName
        lastName
        communityName
        groupId
        invitationEmailStatus
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;

const getGroupsInvitationsWithinProject = ({ projectId, onError, onSuccessResult }) => (
  <Query client={Client()} fetchPolicy="network-only" query={GET_GROUP_INVITATION_LIST_REQUEST} variables={{ projectId }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.invitation.getGroupsInvitationsWithinProject}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getGroupsInvitationsWithinProject.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

getGroupsInvitationsWithinProject.defaultProps = {};

export default getGroupsInvitationsWithinProject;
