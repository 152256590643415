import React from 'react';

const Clients = () => (
  <div className="clients">
    {/* <div className="clients__container">
      <h2>clients</h2>
      <div className="clients__affilates">
        <img
          alt="department of health"
          src="https://assets.kanzi.io/images/doh-logo.svg"
          style={{ maxWidth: '20%' }}
        />
        <img
          alt="premise"
          src="https://assets.kanzi.io/images/premise-logo.svg"
        />
        <img
          alt="newcastle"
          src="https://assets.kanzi.io/images/uon-logo.svg"
        />
        <img
          alt="everymind"
          src="https://assets.kanzi.io/images/everymind-logo.svg"
        />
        <img
          alt="unsw"
          src="https://assets.kanzi.io/images/unsw-sydney-logo.svg"
        />
      </div>
    </div> */}
  </div>
);

export default Clients;
