import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const ADD_TAG_GROUP = gql`
  mutation addTag($contentGroupId: Int!, $tagId: Int!) {
    tagMutation {
      addTagInContentGroup(contentGroupId: $contentGroupId, tagId: $tagId) {
        id
        name
      }
    }
  }
`;

const addTagInContentGroup = ({
  contentGroupId,
  tagId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={ADD_TAG_GROUP}>
    {(addTag, { loading, error, data }) => {
      const variables = {
        contentGroupId,
        tagId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.tagMutation.addTagInContentGroup);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={addTag}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

addTagInContentGroup.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  tagId: PropTypes.number.isRequired,
};

export default addTagInContentGroup;
