import { useEkardoMutation } from '@netfront/ekardo-content-library';

import {
  UPDATE_CONDITION_CHECKBOX,
  UPDATE_CONDITION_RADIO,
  UPDATE_CONDITION_SLIDER,
  UPDATE_CONDITION_TEXT,
} from './UseConditionUpdate.graphql';

const useConditionUpdate = (options) => {
  const { mutation, onCompleted, onError, token, conditionType, variables } = options ?? {};

  const updateConditionMutationQueryMap = {
    checkbox: UPDATE_CONDITION_CHECKBOX,
    radio: UPDATE_CONDITION_RADIO,
    slider: UPDATE_CONDITION_SLIDER,
    text: UPDATE_CONDITION_TEXT,
  };

  const [executeUpdateCondition, { loading: isLoading }] = useEkardoMutation({
    mutation: mutation ?? updateConditionMutationQueryMap[conditionType],
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAction: { updateCondition: updatedQuestionCondition },
        } = data;

        onCompleted({
          updatedQuestionCondition,
        });
      },
      onError,
    },
    token,
    conditionType,
    variables,
  });

  const handleUpdateCondition = () => {
    executeUpdateCondition({
      variables,
    });
  };

  return {
    handleUpdateCondition,
    isLoading,
  };
};

export { useConditionUpdate };
