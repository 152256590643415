import React, { useContext, useEffect, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import PopupMessageSideBar from '../PopupMessageSideBar/PopupMessageSideBar';
import Groups from '../SearchInput/SearchContentGroups';
import ImageUploader from '../UI/AssetUploader/ImageUploader';
import SwitchButton from '../UI/Button/SwitchButton';
import Dropdown from '../UI/Dropdown/Dropdown';
import DatePicker from '../UI/Input/DatePicker';
import Input from '../UI/Input/InputWhite';
import SidebarTab from '../UI/Tab/SidebarTab';
import ClickableTag from '../UI/Tag/ClickableTag';
import EditableTag from '../UI/Tag/EditableTag';
import Textarea from '../UI/Textarea/Textarea';
import Tooltip from '../UI/Tooltip/Tooltips';

import AudioTab from './Audio/AudioTab';
import RightNavBar from './RightNavBar';
import {
  Logo,
  SearchSuggetionsBox,
  SearchSuggetionsRow,
  Search,
  Title,
  TagInput,
  AdvanceItem,
  AdvanceTitle,
  NavigationOptionGroupContainer,
  NavigationOptionHeaderRow,
  NavigationOptionBodyRow,
  SwitchButtonContainer,
  SwitchButtonLabelContainer,
  SwitchButtonWithLabelContainer,
  TextFieldLabel,
  TagsWrapper,
  Preview,
  EditBtn,
} from './styled';
import TranscriptTab from './Transcript/TranscriptTab';

import AppContext from '../../context/AppContext';
import { useContentPageState } from '../../context/ContentPage/ContentPageContext';
import { UPDATE_ASSET } from '../../middleware/Asset/updateAssetRequest';
import { getSignedUrl } from '../../middleware/AWS/getSignedUrl';
import client from '../../middleware/client';
import CopyContentPageRequest from '../../middleware/ContentPage/copyContentPageRequest';
import CreateContentPageRequest from '../../middleware/ContentPage/createContentPageRequest';
import GetContentPage from '../../middleware/ContentPage/getContentPage';
import GetContentPageTypes from '../../middleware/ContentPage/getContentPageTypes';
import RelatePage from '../../middleware/ContentPage/relateContentPage';
import LoadAllResources from '../../middleware/ContentPage/searhContentPages';
import ToggleContentPage from '../../middleware/ContentPage/toggleContentPage';
import UpdateContentPageRequest from '../../middleware/ContentPage/updateContentPageRequest';
import UpdateContentPageStatusRequest from '../../middleware/ContentPage/updateContentPageStatusRequest';
import { ATTACH_TO_PAGE } from '../../middleware/CssStyle/attachToPage';
import { DETACH_STYLE } from '../../middleware/CssStyle/detachStyle';
import { GET_CSS_STYLE_PER_PROJECT } from '../../middleware/CssStyle/getCssPerProject';
import { insertAt } from '../../utils';
import { convertToURL, ContentGroupTypeEnum } from '../../utils/utils';

const status = {
  UNPUBLISHED: 0,
  PUBLISHED: 1,
  DELETED: 2,
};

const PageSideBar = ({ action, handleCloseSideBar, handleRefresh, item }) => {
  const [pageState, dispatch] = useContentPageState();

  const { type: pageType, projectId } = useParams();
  const { contentGroup } = useContext(AppContext);

  const textInput = useRef(null);
  const [copyRequest, setCopyRequest] = useState(false);
  const [createRequest, setCreateRequest] = useState(false);
  const [sendRequest, setSendRequest] = useState(true);
  const [statusRequest, setStatusRequest] = useState(false);
  const [updateRequest, setUpdateRequest] = useState(false);

  const [curPage, setCurPage] = useState({});
  const [pageId, setPageId] = useState({});
  const [title, setTitle] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageTypes, setPageTypes] = useState([]);
  const [sort, setSort] = useState(0);
  const [url, setUrl] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [editLogo, setEditLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [activeTags, setActiveTags] = useState([]);
  const [toggleRequest, setToggleRequest] = useState(false);
  const [activeType, setActiveType] = useState({});
  const [statusCode, setStatusCode] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [activityMode, setActivityMode] = useState();
  const [assessmentMode, setAssessmentMode] = useState();

  const [isDisabled, setIsDisabled] = useState(false);
  const [loadPageTypes, setLoadPageTypes] = useState(false);
  const [loadRes, setLoadRes] = useState(false);
  const [allResources, setAllResources] = useState([]);
  const [searchedPages, setSearchedPages] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [selectRelated, setSelectRelated] = useState(null);
  const [relateRequest, setRelateRequest] = useState(false);
  const [relatedPages, setRelatedPages] = useState([]);
  const [isLink, setIsLink] = useState(true);
  const [customThemes, setCustomThemes] = useState([]);
  const [selectedCustomTheme, setSelectedCustomTheme] = useState('NONE');
  const [customStyle, setCustomStyle] = useState(null);
  const [searchGroupTitle, setSearchGroupTitle] = useState(null);
  const [backButtonToolTip, setBackButtonToolTip] = useState('');
  const [nextButtonToolTip, setNextButtonToolTip] = useState('');
  const [enableBackButton, setEnableBackButton] = useState(false);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [releaseDate, setReleaseDate] = useState(new Date(Date.now()).toLocaleString().split(',')[0]);
  const [isShowBackButtonToolTip, setIsShowBackButtonToolTip] = useState(enableBackButton);
  const [isShowNextButtonToolTip, setIsShowNextButtonToolTip] = useState(enableNextButton);
  const [isAnimatedImage, setIsAnimatedImage] = useState(false);
  const isCartoon = pageType === ContentGroupTypeEnum.CARTOON.toLowerCase();

  const [updateAssetRequest] = useMutation(UPDATE_ASSET, {
    client,
    onCompleted() {
      setUpdateRequest(false);
    },
  });

  const [detach] = useMutation(DETACH_STYLE, {
    client,
  });

  const [attachStyle] = useMutation(ATTACH_TO_PAGE, {
    client,
  });

  const [getStyle] = useLazyQuery(GET_CSS_STYLE_PER_PROJECT, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      projectId,
    },

    onCompleted: (data) => {
      const defaultThemeOption = [
        {
          id: 0,
          value: 'NONE',
          label: 'Please select a custom theme',
        },
      ];

      const projectThemeOptions = data.cssStyle.getPerProject.map((style) => ({
        id: style.id,
        value: style.name,
        label: style.name,
      }));

      const themes = [...defaultThemeOption, ...projectThemeOptions];
      if (customStyle) {
        const theme = themes.find((c) => c.id === customStyle.id);
        setSelectedCustomTheme(theme.value);
      }
      setCustomThemes(themes);
    },
  });

  useEffect(() => {
    textInput.current.focus();
  }, [searchTitle]);

  const handleLoadSuccess = (value) => {
    const { asset } = value;
    const { assetId: currentAssetId, contentType, s3Key, isAnimatedImage: isAnimated } = asset || {};

    if (currentAssetId) {
      setAssetId(currentAssetId);
      setIsAnimatedImage(isAnimated);
    }

    setPageId(value.id);
    setGroupId(value.contentGroupId);
    setCustomStyle(value?.cssStyle);
    getStyle();

    if (action === 'duplicate page') {
      setIsDisabled(true);
      setTitle(`Duplicate "${item.data.title}"`);
      setActiveType(value.contentPageType);
      setPageTitle(value.title);
      setRelatedPages(value.relatedPage);
      setSeoDescription(value.seoDescription);
      setSeoTitle(value.seoTitle);
      setUrl(value.url);

      if (asset) {
        setIsAnimatedImage(isAnimated);

        if (contentType && s3Key) {
          setLogoUrl(getSignedUrl(s3Key, contentType));
        }
      }
    }

    if (action === 'setting') {
      const tmp = value.tags.map((t) => t.id);
      setActiveTags(tmp);
      setActiveType(value.contentPageType);
      setActivityMode(value.mode.includes('ACTIVITY'));
      setAssessmentMode(value.mode.includes('ASSESSMENT'));
      setCurPage(value);
      setPageTitle(value.title);
      setParentId(value.parentId);
      setRelatedPages(value.relatedPage);
      setSeoDescription(value.seoDescription);
      setSeoTitle(value.seoTitle);
      setSort(value.sort);
      setStatusCode(status[value.status]);
      setTitle(value.title);
      setUrl(value.url);
      setReleaseDate(value.releaseDate);
      const { contentPageConfigurationNavigation } = value;

      if (contentPageConfigurationNavigation) {
        setBackButtonToolTip(contentPageConfigurationNavigation.backButtonToolTip);
        setEnableBackButton(contentPageConfigurationNavigation.enableBackButton);
        setIsShowBackButtonToolTip(contentPageConfigurationNavigation.enableBackButton);
        setNextButtonToolTip(contentPageConfigurationNavigation.nextButtonToolTip);
        setEnableNextButton(contentPageConfigurationNavigation.enableNextButton);
        setIsShowNextButtonToolTip(contentPageConfigurationNavigation.enableNextButton);
      }

      if (value.asset && Object.keys(value.asset).length > 0) {
        const tmpURL = getSignedUrl(value.asset.s3Key, value.asset.contentType);

        setLogoUrl(tmpURL);
      }
    }

    if (action === 'new page') {
      setActiveTags([]);
      setAssetId(null);
      setPageTitle('');
      setParentId(null);
      setSeoDescription('');
      setSeoTitle('');
      setTitle('Add new page');
      setUrl('');
      dispatch({
        type: 'setIsNewPage',
        newState: true,
      });
    }

    if (action === 'new subpage') {
      setTitle(`Add sub page (Under ${item.data.title})`);
      setParentId(item.id);
    }

    setSendRequest(false);
  };

  const handleUpdateStatusSuccess = () => {
    setStatusRequest(false);
    if (statusCode === 2) {
      handleCloseSideBar();
      handleRefresh();
    }
  };

  const handleUpdateContentPage = (contentPage) => {
    dispatch({
      type: 'setContentPage',
      newState: contentPage,
    });
    updateAssetRequest({
      variables: {
        assetId,
        isAnimatedImage,
      },
    });
    handleRefresh();
    handleCloseSideBar();
  };

  const handleAddPageSuccess = (id, _, contentPage) => {
    dispatch({
      type: 'setContentPage',
      newState: contentPage,
    });

    updateAssetRequest({
      variables: {
        assetId,
        isAnimatedImage,
      },
    });

    setUpdateRequest(false);
    setCreateRequest(false);

    handleCloseSideBar();
    handleRefresh();
  };

  const handleCopyPageSuccess = () => {
    setCopyRequest(false);
    handleCloseSideBar();
    handleRefresh();
  };

  const handleError = (err) => {
    if (statusRequest) {
      setStatusCode(status[curPage.status]);
    }
    setErrorMessage(err.toString());
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
    setCopyRequest(false);
    setCreateRequest(false);
    setSendRequest(false);
    setStatusRequest(false);
    setUpdateRequest(false);
  };

  const handleDeletePage = () => {
    setStatusRequest(true);
    setStatusCode(2);
  };

  const handleAddPage = () => {
    if (action === 'duplicate page') {
      setCopyRequest(true);
    } else if (action === 'setting') {
      setUpdateRequest(true);

      if (selectedCustomTheme !== 'NONE') {
        attachStyle({
          variables: {
            contentPageId: curPage.id,
            styleId: customThemes.find((c) => c.value === selectedCustomTheme).id,
          },
        });
      } else {
        detach({
          variables: {
            contentPageId: curPage.id,
          },
        });
      }
    } else {
      setCreateRequest(true);
    }
  };

  const handlePageTitle = (value) => {
    setPageTitle(value);
    setUrl(convertToURL(value));
  };

  const handleUrl = (value) => {
    setUrl(convertToURL(value));
  };

  const getLogoData = (value) => {
    const tmpURL = getSignedUrl(value.s3Key, value.contentType);
    setEditLogo(false);
    setLogoUrl(tmpURL);
    setAssetId(value.assetId);
  };

  const handleTogglePublish = () => {
    setStatusCode(statusCode === 1 ? 0 : 1);
    setStatusRequest(true);
  };

  const handleToggle = () => {
    setToggleRequest(false);
  };

  const handlePageTypesError = () => {
    setLoadPageTypes(true);
  };

  const handlePageTypesSuccess = (data) => {
    setLoadPageTypes(true);
    setPageTypes(data);
  };

  const handleActiveTags = (active, tag) => {
    if (active) {
      setActiveTags([...activeTags, tag.id]);
    } else {
      const tmpTags = activeTags.filter((t) => t !== tag.id);
      setActiveTags(tmpTags);
    }
  };

  const handleActiveType = (active, t) => {
    setActiveType(t);
  };

  const handleLoadResError = () => {
    setLoadRes(true);
  };

  const handleLoadResSuccess = (data) => {
    const tmp = data.edges.map((page) => page.node);
    setAllResources(tmp);
    setLoadRes(true);
  };

  const handleSearchInput = (e) => {
    const filtered = allResources.filter((res) => res.title.toLowerCase().includes(e.target.value.toLowerCase()));
    setSearchedPages(filtered);
    setSearchTitle(e.target.value);
  };

  const handleSearchGroup = (e) => {
    setSearchGroupTitle(e.target.value);
  };

  const handleLinkPage = (page) => {
    const link = relatedPages.find((p) => p.id === page.id);
    setIsLink(!link);
    setSelectRelated(page);
    setRelateRequest(true);
  };

  const handleRelateError = () => {
    setRelateRequest(false);
    setSelectRelated({});
    setSearchedPages([]);
    setSearchTitle('');
  };

  const handleRelateSuccess = () => {
    setRelateRequest(false);

    if (isLink) {
      const tmp = [...relatedPages];
      tmp.push(selectRelated);
      setRelatedPages(tmp);
    } else {
      const tmp = relatedPages.filter((p) => p.id !== selectRelated.id);
      setRelatedPages(tmp);
    }
    setSelectRelated({});
    setSearchedPages([]);
    setSearchTitle('');
  };

  const handleEnableBackButtonChecked = (checked) => {
    setBackButtonToolTip(checked ? backButtonToolTip : '');
    setEnableBackButton(checked);
    setIsShowBackButtonToolTip(checked);
  };

  const handleEnableNextButtonChecked = (checked) => {
    setNextButtonToolTip(checked ? nextButtonToolTip : '');
    setEnableNextButton(checked);
    setIsShowNextButtonToolTip(checked);
  };

  const handleReleaseDate = (date) => setReleaseDate(date);

  const handleIsAnimatedImage = () => {
    setIsAnimatedImage(!isAnimatedImage);
  };

  const handleUploadImage = (droppedAssetId) => {
    setAssetId(droppedAssetId);
  };

  const audioTab = <AudioTab pageId={pageId} projectId={projectId} updateRequest={updateRequest} />;

  const generalTab = (
    <>
      <AdvanceItem>
        <AdvanceTitle>
          <Title htmlFor="input-page-title">Title</Title>
          <Tooltip />
        </AdvanceTitle>
        <Input id="input-page-title" isChanged={handlePageTitle} isDisabled={isDisabled} text={pageTitle} value={pageTitle} />
      </AdvanceItem>

      {pageType === 'events' && (
        <AdvanceItem>
          <AdvanceTitle>
            <Title htmlFor="ek-release-date">Event date</Title>
            <Tooltip />
          </AdvanceTitle>
          <DatePicker answer={releaseDate} id="ek-release-date" onChange={({ target: { value } }) => handleReleaseDate(value)} />
        </AdvanceItem>
      )}

      <AdvanceItem>
        <AdvanceTitle>
          <Title htmlFor="input-page-url">URL</Title>
          <Tooltip />
        </AdvanceTitle>
        <Input id="input-page-url" isChanged={handleUrl} isDisabled={isDisabled} text={url} value={url} />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <Title htmlFor="input-page-url">Image</Title>
          <Tooltip />
        </AdvanceTitle>
        {logoUrl && !editLogo ? (
          <Preview>
            {!isDisabled && (
              <EditBtn
                onClick={() => {
                  setEditLogo(true);
                }}
              >
                Change the image
              </EditBtn>
            )}
            <Logo alt="logo" src={logoUrl} />
          </Preview>
        ) : (
          <>
            {!isDisabled && (
              <ImageUploader getData={getLogoData} name="bgImg" projectId={projectId} type="IMAGE" onImageUpload={handleUploadImage} />
            )}
          </>
        )}
      </AdvanceItem>
      {isCartoon && (
        <AdvanceItem>
          <AdvanceTitle>
            <Title htmlFor="is-image-animated">Is image animated?</Title>
            <Tooltip text="Pages are visible to end users when they have been published" />
          </AdvanceTitle>
          <SwitchButton id="is-image-animated" name="Is this slide animated?" value={isAnimatedImage} onChange={handleIsAnimatedImage} />
        </AdvanceItem>
      )}

      {action === 'setting' && (
        <>
          <AdvanceItem>
            <AdvanceTitle>
              <Title htmlFor="input-page-publish">Publish page</Title>
              <Tooltip text="Pages are visible to end users when they have been published" />
            </AdvanceTitle>
            <SwitchButton id="input-page-publish" value={statusCode === 1} onChange={handleTogglePublish} />
          </AdvanceItem>
          <AdvanceItem>
            <AdvanceTitle>
              <Title htmlFor="input-page-content-move">Move to another Content Group</Title>
              <Tooltip />
            </AdvanceTitle>
            <TagInput>
              <Search
                autoComplete="off"
                id="input-page-content-move"
                name="searchGroup"
                placeholder="search content group "
                type="text"
                value={searchGroupTitle}
                onChange={(e) => handleSearchGroup(e)}
              />
              {searchGroupTitle && searchGroupTitle !== '' && (
                <Groups
                  group={contentGroup}
                  // onGroupSelected={handleSelectGroup}

                  pageId={curPage.id}
                  projectId={projectId}
                  title={searchGroupTitle}
                />
              )}
            </TagInput>
          </AdvanceItem>
        </>
      )}

      {pageTypes.length > 0 && (
        <AdvanceItem>
          <AdvanceTitle>
            <Title htmlFor="clickable-page-type">Select page type</Title>
            <Tooltip />
          </AdvanceTitle>
          <TagsWrapper id="clickable-page-type">
            {pageTypes.map((type) => (
              <ClickableTag key={type.id} isActive={activeType && type.id === activeType.id} isClicked={handleActiveType} tag={type} />
            ))}
          </TagsWrapper>
        </AdvanceItem>
      )}

      {pageType === 'activity' && (
        <AdvanceItem>
          <AdvanceTitle>
            <Title htmlFor="page-display-mode">Page display mode</Title>
            <Tooltip text="What mode should this page display on" />
          </AdvanceTitle>
          <AdvanceTitle>
            <Title htmlFor="input-page-activity">Activity</Title>
            {activityMode != null && (
              <SwitchButton
                defaultChecked={activityMode}
                id="input-page-activity"
                value={activityMode}
                onChange={() => setActivityMode((val) => !val)}
              />
            )}
          </AdvanceTitle>
          <AdvanceTitle>
            <Title htmlFor="input-page-assesment">Assessment</Title>
            {assessmentMode != null && (
              <SwitchButton
                defaultChecked={assessmentMode}
                id="assessmentPage"
                value={assessmentMode}
                onChange={() => setAssessmentMode((val) => !val)}
              />
            )}
          </AdvanceTitle>
        </AdvanceItem>
      )}
    </>
  );

  const seoTab = (
    <>
      <AdvanceItem>
        <AdvanceTitle>
          <Title htmlFor="input-page-seo">SEO Title</Title>
          <Tooltip />
        </AdvanceTitle>
        <Input
          id="input-page-seo"
          isChanged={(value) => setSeoTitle(value)}
          isDisabled={isDisabled}
          text={seoTitle || pageTitle}
          value={seoTitle || pageTitle}
        />
      </AdvanceItem>
      <AdvanceItem>
        <AdvanceTitle>
          <Title htmlFor="input-page-seo-description">SEO Description</Title>
          <Tooltip />
        </AdvanceTitle>
        <Textarea
          id="input-page-seo-description"
          isChanged={(value) => setSeoDescription(value)}
          isDisabled={isDisabled}
          text={seoDescription}
          value={seoDescription}
        />
      </AdvanceItem>
    </>
  );

  const relatedTab = (
    <>
      <AdvanceItem>
        <AdvanceTitle>
          <Title htmlFor="input-page-resources">Resources</Title>
          <Tooltip />
        </AdvanceTitle>
        <TagInput>
          <Search
            ref={textInput}
            autoComplete="off"
            disabled={isDisabled}
            id="input-page-resources"
            name="searchTitle"
            placeholder="Search pages "
            type="text"
            value={searchTitle}
            onChange={(e) => handleSearchInput(e)}
          />
          <SearchSuggetionsBox>
            {searchedPages.map((p) => (
              <SearchSuggetionsRow key={p.id} onClick={() => handleLinkPage(p)}>
                {p.title}
              </SearchSuggetionsRow>
            ))}
          </SearchSuggetionsBox>
        </TagInput>
      </AdvanceItem>
      {relatedPages.length > 0 &&
        relatedPages.map((p) => (
          <AdvanceItem key={p.id}>
            <EditableTag groupTitle={p.contentGroup.title} tag={p} title={p.title} onDelete={handleLinkPage} />
          </AdvanceItem>
        ))}
    </>
  );

  const navigationTab = (
    <>
      <AdvanceItem>
        <AdvanceTitle>
          <Title>Navigation</Title>
          <Tooltip text="Configure back/next buttons to navigate between content pages" />
        </AdvanceTitle>
      </AdvanceItem>
      <NavigationOptionGroupContainer>
        <NavigationOptionHeaderRow>Back button settings</NavigationOptionHeaderRow>
        <NavigationOptionBodyRow showToolTip={isShowBackButtonToolTip}>
          <SwitchButtonWithLabelContainer>
            <SwitchButtonLabelContainer>Enabled</SwitchButtonLabelContainer>
            <SwitchButtonContainer>
              <SwitchButton
                defaultChecked={enableBackButton}
                id="enable-back-button"
                value={enableBackButton}
                onChange={() => handleEnableBackButtonChecked(!enableBackButton)}
              />
            </SwitchButtonContainer>
          </SwitchButtonWithLabelContainer>
          {isShowBackButtonToolTip && (
            <>
              <TextFieldLabel>Tool tip text</TextFieldLabel>
              <Input
                id="input"
                isChanged={(value) => setBackButtonToolTip(value)}
                isDisabled={isDisabled}
                text={backButtonToolTip}
                value={backButtonToolTip}
              />
            </>
          )}
        </NavigationOptionBodyRow>
      </NavigationOptionGroupContainer>
      <NavigationOptionGroupContainer>
        <NavigationOptionHeaderRow>Next button settings</NavigationOptionHeaderRow>
        <NavigationOptionBodyRow showToolTip={isShowNextButtonToolTip}>
          <SwitchButtonWithLabelContainer>
            <SwitchButtonLabelContainer>Enabled</SwitchButtonLabelContainer>
            <SwitchButtonContainer>
              <SwitchButton
                defaultChecked={enableNextButton}
                id="enable-next-button"
                value={enableNextButton}
                onChange={() => handleEnableNextButtonChecked(!enableNextButton)}
              />
            </SwitchButtonContainer>
          </SwitchButtonWithLabelContainer>
          {isShowNextButtonToolTip && (
            <>
              <TextFieldLabel>Tool tip text</TextFieldLabel>
              <Input
                id="input"
                isChanged={(value) => setNextButtonToolTip(value)}
                isDisabled={isDisabled}
                text={nextButtonToolTip}
                value={nextButtonToolTip}
              />
            </>
          )}
        </NavigationOptionBodyRow>
      </NavigationOptionGroupContainer>
    </>
  );

  const tagsTab = (
    <div>
      <AdvanceItem>
        <AdvanceTitle>
          <b>Select tags</b>
          <Tooltip />
        </AdvanceTitle>
        <TagsWrapper>
          {contentGroup.tags.map((tag) => (
            <ClickableTag
              key={tag.id}
              isActive={activeTags && activeTags.find((t) => t === tag.id) !== undefined}
              isClicked={handleActiveTags}
              isDisabled={isDisabled}
              tag={tag}
            />
          ))}
        </TagsWrapper>
      </AdvanceItem>
    </div>
  );

  const handleSelectedThemeChanged = (id) => {
    const theme = customThemes.find((c) => c.id === Number(id));
    setSelectedCustomTheme(theme.value);
  };

  const styleTab = (
    <div>
      <AdvanceItem>
        <AdvanceTitle>
          <Title>Custom theme</Title>
          <Tooltip />
        </AdvanceTitle>
        <Dropdown
          availableResponses={customThemes}
          name="customTheme"
          selectedValue={selectedCustomTheme}
          onChangeHandler={handleSelectedThemeChanged}
        />
      </AdvanceItem>
    </div>
  );

  const transcriptTab = (
    <TranscriptTab assetId={assetId} bubbleTexts={pageState.contentPage.bubbleTexts} projectId={projectId} updateRequest={updateRequest} />
  );

  const mode = [];

  if (assessmentMode) mode.push('ASSESSMENT');
  if (activityMode) mode.push('ACTIVITY');

  const generalTabItem = {
    title: 'General',
    content: generalTab,
  };

  const seoTabItem = {
    title: 'SEO',
    content: seoTab,
  };

  const audioTabItem = {
    title: 'Audio',
    content: audioTab,
  };

  const transcriptTabItem = {
    title: 'Transcript',
    content: transcriptTab,
  };

  const styleTabItem = {
    title: 'Style',
    content: styleTab,
  };

  const relatedTabItem = {
    title: 'Related',
    content: relatedTab,
  };

  const navigationTabItem = {
    title: 'Navigation',
    content: navigationTab,
  };

  const tagsTabItem = {
    title: 'Tags',
    content: tagsTab,
  };

  const sidebarTabItems = [generalTabItem, seoTabItem, styleTabItem, relatedTabItem, navigationTabItem, tagsTabItem];

  if (isCartoon) {
    insertAt(sidebarTabItems, 2, audioTabItem, transcriptTabItem);
  }

  return (
    <>
      <RightNavBar
        hasDelete={action === 'setting'}
        onCancel={() => handleCloseSideBar()}
        onDelete={handleDeletePage}
        onSave={handleAddPage}
      >
        <div>
          <h3>{title}</h3>
          <SidebarTab items={sidebarTabItems} />
        </div>
      </RightNavBar>

      {errorMessage !== '' && <PopupMessageSideBar message={errorMessage} />}
      {sendRequest && item.id && <GetContentPage contentPageId={item.id} onError={handleError} onSuccessResult={handleLoadSuccess} />}
      {!loadRes && (
        <LoadAllResources
          contentGroupType="RESOURCE"
          projectId={projectId}
          onError={handleLoadResError}
          onSuccessResult={handleLoadResSuccess}
        />
      )}

      {!loadPageTypes && (
        <GetContentPageTypes projectId={projectId} onError={handlePageTypesError} onSuccessResult={handlePageTypesSuccess} />
      )}

      {copyRequest && <CopyContentPageRequest contentPageId={item.id} onError={handleError} onSuccessResult={handleCopyPageSuccess} />}

      {createRequest && (
        <CreateContentPageRequest
          assetId={assetId}
          contentGroupId={groupId}
          contentPageType={activeType ? activeType.id : undefined}
          parentId={parentId}
          seoDescription={seoDescription}
          seoTitle={seoTitle || pageTitle}
          tags={activeTags}
          title={pageTitle}
          url={url}
          onError={handleError}
          onSuccessResult={handleAddPageSuccess}
        />
      )}

      {updateRequest && (
        <UpdateContentPageRequest
          assetId={assetId}
          backButtonToolTip={backButtonToolTip}
          contentPageId={item.id}
          contentPageType={activeType ? activeType.id : undefined}
          enableBackButton={enableBackButton}
          enableNextButton={enableNextButton}
          mode={mode}
          nextButtonToolTip={nextButtonToolTip}
          parentId={parentId}
          releaseDate={releaseDate}
          seoDescription={seoDescription}
          seoTitle={seoTitle || pageTitle}
          sort={sort}
          tags={activeTags}
          title={pageTitle}
          url={url}
          onError={handleError}
          onSuccessResult={(res) => handleUpdateContentPage(res)}
        />
      )}

      {statusRequest && statusCode !== null && (
        <UpdateContentPageStatusRequest
          contentPageId={item.id}
          status={statusCode}
          onError={handleError}
          onSuccessResult={handleUpdateStatusSuccess}
        />
      )}

      {toggleRequest && <ToggleContentPage contentPageId={item.id} onError={handleToggle} onSuccessResult={handleToggle} />}
      {relateRequest && (
        <RelatePage
          fromContentPageId={curPage.id}
          toContentPageId={selectRelated.id}
          onError={handleRelateError}
          onSuccessResult={handleRelateSuccess}
        />
      )}
    </>
  );
};

PageSideBar.propTypes = {
  action: PropTypes.string.isRequired,
  handleCloseSideBar: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  item: PropTypes.shape({
    children: PropTypes.instanceOf(Array),
    data: PropTypes.shape({}),
    hasChildren: PropTypes.bool,
    id: PropTypes.number,
    isChildrenLoading: PropTypes.bool,
    isExpanded: PropTypes.bool,
  }).isRequired,
};

PageSideBar.defaultProps = {};

export default PageSideBar;
