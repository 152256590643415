import gql from 'graphql-tag';

import { SummaryFragment } from '../QuestionConfiguration/Fragments/Collection';

export const CREATE_AVATAR_SNIPPET = gql`
  mutation($containerId: Int!) {
    contentSnippet {
      createContentSnippet(baseSnippet: { containerId: $containerId, visible: true }, contentSnippetAvatar: { value: true }) {
        id
        sort
      }
    }
  }
`;
