import gql from 'graphql-tag';

export const UPDATE_QUESTION_CONFIGURATION_STYLE = gql`
  mutation($configurationId: Int!, $style: EQuestionSelectionStyle!) {
    questionConfiguration {
      updateStyle(configurationId: $configurationId, style: $style) {
        id
      }
    }
  }
`;
