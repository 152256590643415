import isEmpty from 'lodash.isempty';
import isNil from 'lodash.isnil';
import orderBy from 'lodash.orderby';
import uniqBy from 'lodash.uniqby';

import {
  DBQuestionActionTypeNameType,
  DBQuestionConfigurationTypeType,
  IContentGroup,
  IContentPage,
  IContentSection,
  IContentSectionContainer,
  IContentSnippet,
  IContentSnippetForm,
  IQuestionAction,
  IQuestionAnswer,
  IQuestionAnswerMultiResponseText,
  IQuestionAnswerNumber,
  IQuestionCondition,
  ISectionGroupTab,
} from '../../interfaces';

const getAllFlattenedContentPages = (contentGroup: IContentGroup | undefined): IContentPage[] => {
  if (!contentGroup) {
    return [];
  }

  const { contentPages = [] } = contentGroup;

  const nestedContentPages = contentPages.flatMap(({ contentPageChildren }) => contentPageChildren);

  return [...contentPages, ...nestedContentPages];
};

const getAllFlattenedContentSectionContainers = (contentPage: IContentPage | undefined): IContentSectionContainer[] => {
  if (!contentPage) {
    return [];
  }

  const { sectionGroups = [] } = contentPage;

  const contentSectionContainers = sectionGroups.flatMap(({ contentSections }) =>
    contentSections.flatMap(({ sectionContainers }) => sectionContainers)
  );

  const nestedContentSectionContainers = contentSectionContainers.flatMap((contentSectionContainer) =>
    contentSectionContainer.snippets.flatMap((snippet) => {
      const { form } = snippet;

      if (!form) {
        return [];
      }

      const { contentPages } = form;

      if (!contentPages.length) {
        return [];
      }

      return getAllFlattenedContentSectionContainers(contentPages[0]);
    })
  );

  return [...contentSectionContainers, ...nestedContentSectionContainers];
};

const getAllFlattenedContentSections = (contentPage: IContentPage | undefined): IContentSection[] => {
  if (!contentPage) {
    return [];
  }

  const { sectionGroups = [] } = contentPage;

  const contentSections = sectionGroups.flatMap(({ contentSections: sectionGroupContentSections }) => sectionGroupContentSections);

  const nestedContentSections = contentSections.flatMap(({ sectionContainers }) =>
    sectionContainers.flatMap(({ snippets }) =>
      snippets.flatMap((snippet) => {
        const { form } = snippet;

        if (!form) {
          return [];
        }

        const { contentPages } = form;

        if (!contentPages.length) {
          return [];
        }

        return getAllFlattenedContentSections(contentPages[0]);
      })
    )
  );

  return [...contentSections, ...nestedContentSections];
};

const getAllFlattenedContentSnippetForms = (contentPage: IContentPage | undefined): IContentSnippetForm[] => {
  if (!contentPage) {
    return [];
  }

  const { sectionGroups = [] } = contentPage;

  const contentSnippetForms = sectionGroups.flatMap(({ contentSections: sectionGroupContentSections }) =>
    sectionGroupContentSections.flatMap(({ sectionContainers }) =>
      sectionContainers.flatMap(({ snippets }) =>
        snippets.flatMap((snippet) => {
          const { form } = snippet;

          if (!form) {
            return [];
          }

          return snippet;
        })
      )
    )
  );

  const nestedContentSnippetForms = contentSnippetForms.flatMap((snippet) => {
    const { form } = snippet;

    if (!form) {
      return [];
    }

    const { contentPages } = form;

    if (!contentPages.length) {
      return [];
    }

    return getAllFlattenedContentSnippetForms(contentPages[0]);
  });

  return [...contentSnippetForms, ...nestedContentSnippetForms];
};

const getAllFlattenedContentSnippets = (contentPage: IContentPage | undefined): IContentSnippet[] => {
  if (!contentPage) {
    return [];
  }

  const { sectionGroups = [] } = contentPage;

  const contentSnippets = sectionGroups.flatMap(({ contentSections: sectionGroupContentSections }) =>
    sectionGroupContentSections.flatMap(({ sectionContainers }) => sectionContainers.flatMap(({ snippets }) => snippets))
  );

  const nestedContentSnippets = contentSnippets.flatMap((snippet) => {
    const { form } = snippet;

    if (!form) {
      return [];
    }

    const { contentPages } = form;

    if (!contentPages.length) {
      return [];
    }

    return getAllFlattenedContentSnippets(contentPages[0]);
  });

  return [...contentSnippets, ...nestedContentSnippets];
};

const getAllContentSnippetQuestions = (contentPage: IContentPage | undefined): IContentSnippet[] => {
  if (!contentPage) {
    return [];
  }

  const allFlattenedContentSnippets = getAllFlattenedContentSnippets(contentPage);

  return allFlattenedContentSnippets.filter(({ __typename: typeName }) => typeName === 'ContentSnippetQuestionType');
};

const getAllFlattenedSectionGroups = (contentPage: IContentPage | undefined): ISectionGroupTab[] => {
  if (!contentPage) {
    return [];
  }

  const { sectionGroups = [] } = contentPage;

  const contentSnippetForms = getAllFlattenedContentSnippetForms(contentPage);

  const nestedSectionGroups = contentSnippetForms.flatMap((contentSnippetForm) => {
    const { form } = contentSnippetForm;

    if (!form) {
      return [];
    }

    const { contentPages } = form;

    if (!contentPages.length) {
      return [];
    }

    return getAllFlattenedSectionGroups(contentPages[0]);
  });

  return [...sectionGroups, ...nestedSectionGroups];
};

const getContentSection = (contentSectionId: number, contentPage: IContentPage | undefined): IContentSection | undefined => {
  const allFlattenedContentSections = getAllFlattenedContentSections(contentPage);

  return allFlattenedContentSections.find(({ id }) => contentSectionId === id);
};

const getContentSectionContainer = (
  contentSectionContainerId: number,
  contentPage: IContentPage | undefined
): IContentSectionContainer | undefined => {
  const allFlattenedContentSectionContainers = getAllFlattenedContentSectionContainers(contentPage);

  return allFlattenedContentSectionContainers.find(({ id }) => contentSectionContainerId === id);
};

const getContentSnippet = (contentSnippetId: IContentSnippet['id'], contentPage: IContentPage | undefined): IContentSnippet | undefined => {
  const allFlattenedContentSnippets = getAllFlattenedContentSnippets(contentPage);

  return allFlattenedContentSnippets.find(({ id }) => contentSnippetId === id);
};

const getContentSnippetFormId = (
  childContentSnippetId: IContentSnippet['id'],
  contentPage: IContentPage | undefined
): number | undefined => {
  const allFlattenedContentSnippetForms = getAllFlattenedContentSnippetForms(contentPage);

  const foundContentSnippetForm = allFlattenedContentSnippetForms.find((contentSnippetForm) => {
    const { form } = contentSnippetForm;

    if (!form) {
      return undefined;
    }

    const { contentPages } = form;

    const allFlattenedContentSnippets = getAllFlattenedContentSnippets(contentPages[0]);

    const contentSnippet = allFlattenedContentSnippets.find(({ id }) => id === childContentSnippetId);

    return contentSnippet ? contentSnippetForm : undefined;
  });

  if (!foundContentSnippetForm) {
    return undefined;
  }

  return foundContentSnippetForm.id;
};

const getContentSnippetNumberAnswer = (contentSnippetId: IContentSnippet['id'], contentPage: IContentPage | undefined): number => {
  const contentSnippet = getContentSnippet(contentSnippetId, contentPage);
  if (!contentSnippet) {
    return 0;
  }

  const { answer } = contentSnippet;
  const { numberAnswered = 0 } = (answer ?? {}) as IQuestionAnswerNumber;

  return Number(numberAnswered);
};

const getContentSnippetsByQuestionConfigurationType = (
  contentPage: IContentPage | undefined,
  questionConfigurationTypeName: DBQuestionConfigurationTypeType
): IContentSnippet[] => {
  const contentSnippetQuestions = getAllContentSnippetQuestions(contentPage);

  return contentSnippetQuestions.filter((contentSnippetQuestion) => {
    const { configuration } = contentSnippetQuestion;
    const { __typename: typeName } = configuration ?? {};

    return typeName === questionConfigurationTypeName;
  });
};

const getHasValidQuestionConditions = (
  contentSnippetId: IContentSnippet['id'],
  questionAnswer: IQuestionAnswer,
  questionConditions: IQuestionCondition[]
) => {
  return questionConditions
    .filter(({ contentSnippetQuestionId }) => contentSnippetQuestionId === contentSnippetId)
    .map(
      ({
        maxValue: sliderMaxValueSelectedCondition,
        minValue: sliderMinValueSelectedCondition,
        numberSelected: numberSelectedCondition,
        questionResponseCheckboxId: checkboxSelectedCondition,
        questionResponseDropDownListId: dropDownListSelectedCondition,
        questionResponseRadioId: radioSelectedCondition,
        selectedDate: dateSelectedCondition,
        text: textEnteredCondition,
      }) => {
        const {
          dateAnswered: selectedDateAnswer,
          numberAnswered: selectedNumberAnswer,
          questionResponseDropDownListId: selectedDropDownListAnswer,
          questionResponseRadioId: selectedRadioAnswer,
          selectedAnswers: selectedCheckboxAnswers = [],
          text: selectedTextAnswer,
          value: selectedSliderValueAnswer,
        } = questionAnswer;

        const hasValidCheckboxCondition =
          !isNil(checkboxSelectedCondition) &&
          Boolean(
            selectedCheckboxAnswers.filter(({ questionResponseCheckboxId }) => questionResponseCheckboxId === checkboxSelectedCondition)
              .length
          );

        const hasValidDateQuestionCondition =
          Boolean(dateSelectedCondition && selectedDateAnswer) && dateSelectedCondition === selectedDateAnswer;

        const hasValidDropDownListCondition =
          !isNil(dropDownListSelectedCondition) &&
          !isNil(selectedDropDownListAnswer) &&
          dropDownListSelectedCondition === selectedDropDownListAnswer;

        const hasValidNumberCondition =
          !isNil(numberSelectedCondition) && !isNil(selectedNumberAnswer) && numberSelectedCondition === selectedNumberAnswer;

        const hasValidRadioCondition =
          !isNil(radioSelectedCondition) && !isNil(selectedRadioAnswer) && radioSelectedCondition === selectedRadioAnswer;

        const hasValidSliderCondition =
          !isNil(sliderMaxValueSelectedCondition) &&
          !isNil(sliderMinValueSelectedCondition) &&
          !isNil(selectedSliderValueAnswer) &&
          selectedSliderValueAnswer >= sliderMinValueSelectedCondition &&
          selectedSliderValueAnswer <= sliderMaxValueSelectedCondition;

        const hasValidTextCondition = Boolean(textEnteredCondition && selectedTextAnswer) && textEnteredCondition === selectedTextAnswer;

        return (
          hasValidCheckboxCondition ||
          hasValidDateQuestionCondition ||
          hasValidDropDownListCondition ||
          hasValidNumberCondition ||
          hasValidRadioCondition ||
          hasValidSliderCondition ||
          hasValidTextCondition
        );
      }
    )
    .reduce((accumulator, currentValue) => accumulator || currentValue, false);
};

const getQuestionAnswerNumberTotals = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configurations: any,
  contentPage: IContentPage | undefined,
  parentKey: string,
  previousTotal = 0
): number => {
  const configuration = configurations[parentKey];

  if (!configuration) {
    throw new Error(`Invalid parentKey ${parentKey} in configuration`);
  }

  return Object.keys(configuration).reduce((accumulator: number, configurationKey: string) => {
    const { contentSnippetId, numberMultiplier = 1 } = configuration[configurationKey];

    if (!contentSnippetId) {
      return getQuestionAnswerNumberTotals(configuration, contentPage, configurationKey, accumulator);
    }

    if (configurationKey === 'total') {
      return accumulator;
    }

    return accumulator + getContentSnippetNumberAnswer(contentSnippetId, contentPage) * numberMultiplier;
  }, previousTotal);
};

const getRequiredSectionGroups = (contentPage: IContentPage | undefined, formContentSnippetId?: number): ISectionGroupTab[] => {
  if (!contentPage) {
    return [];
  }

  const { sectionGroups } = contentPage;

  if (!formContentSnippetId) {
    return sectionGroups;
  }

  const snippet = getContentSnippet(formContentSnippetId, contentPage);

  if (!snippet) {
    return sectionGroups;
  }

  const { form } = snippet;
  const { contentPages } = form as IContentGroup;
  const { sectionGroups: formSectionGroups } = contentPages[0];

  return formSectionGroups;
};

const getSectionGroup = (sectionGroupId: number, contentPage: IContentPage | undefined): ISectionGroupTab | undefined => {
  const allFlattenedSectionGroups = getAllFlattenedSectionGroups(contentPage);

  return allFlattenedSectionGroups.find(({ id }) => id === sectionGroupId);
};

const getUpdatedContentSectionContainers = (
  contentPage: IContentPage | undefined,
  contentSnippetId: IContentSnippet['id'],
  questionActions: IQuestionAction[],
  questionAnswer: IQuestionAnswer,
  visible: boolean
): IContentSectionContainer[] => {
  if (!(contentPage && questionActions.length)) {
    return [];
  }

  return questionActions
    .map((questionAction) => {
      const { conditions: questionConditions = [], containerId, id } = questionAction;

      const contentSectionContainer = getContentSectionContainer(containerId, contentPage);

      if (!contentSectionContainer) {
        // eslint-disable-next-line no-console
        console.warn(
          `Question action (id=${id}) is configured with a content section container (id=${containerId}) that does not belong in this content page (id=${contentPage.id})`
        );
        return undefined;
      }

      if (!getHasValidQuestionConditions(contentSnippetId, questionAnswer, questionConditions)) {
        return undefined;
      }

      return {
        ...contentSectionContainer,
        visible,
      };
    })
    .filter((contentSectionContainer) => contentSectionContainer) as IContentSectionContainer[];
};

const getUpdatedContentSections = (
  contentPage: IContentPage | undefined,
  contentSnippetId: IContentSnippet['id'],
  questionActions: IQuestionAction[],
  questionAnswer: IQuestionAnswer,
  visible: boolean
): IContentSection[] => {
  if (!(contentPage && questionActions.length)) {
    return [];
  }

  return questionActions
    .map((questionAction) => {
      const { conditions: questionConditions = [], id, sectionId } = questionAction;

      const contentSection = getContentSection(sectionId, contentPage);

      if (!contentSection) {
        // eslint-disable-next-line no-console
        console.warn(
          `Question action (id=${id}) is configured with a content section (id=${sectionId}) that does not belong in this content page (id=${contentPage.id})`
        );
        return undefined;
      }

      if (!getHasValidQuestionConditions(contentSnippetId, questionAnswer, questionConditions)) {
        return undefined;
      }

      return {
        ...contentSection,
        visible,
      };
    })
    .filter((contentSnippet) => contentSnippet) as IContentSection[];
};

const getUpdatedContentSnippets = (
  contentPage: IContentPage | undefined,
  contentSnippetId: IContentSnippet['id'],
  questionActions: IQuestionAction[],
  questionAnswer: IQuestionAnswer,
  visible: boolean
): IContentSnippet[] => {
  if (!(contentPage && questionActions.length)) {
    return [];
  }

  return questionActions
    .map((questionAction) => {
      const { conditions: questionConditions = [], id, targetSnippetId } = questionAction;

      const targetContentSnippet = getContentSnippet(targetSnippetId, contentPage);

      if (!targetContentSnippet) {
        // eslint-disable-next-line no-console
        console.warn(
          `Question action (id=${id}) is configured with a content snippet (id=${targetContentSnippet}) that does not belong in this content page (id=${contentPage.id})`
        );
        return undefined;
      }

      if (!getHasValidQuestionConditions(contentSnippetId, questionAnswer, questionConditions)) {
        return undefined;
      }

      return {
        ...targetContentSnippet,
        visible,
      };
    })
    .filter((contentSnippet) => contentSnippet) as IContentSnippet[];
};

const getUpdatedSectionGroups = (
  contentPage: IContentPage | undefined,
  contentSnippetId: IContentSnippet['id'],
  questionActions: IQuestionAction[],
  questionAnswer: IQuestionAnswer,
  visible: boolean
): ISectionGroupTab[] => {
  if (!(contentPage && questionActions.length)) {
    return [];
  }

  return questionActions
    .map((questionAction) => {
      const { conditions: questionConditions = [], id, sectionGroupId } = questionAction;

      const sectionGroup = getSectionGroup(sectionGroupId, contentPage);
      if (!sectionGroup) {
        // eslint-disable-next-line no-console
        console.warn(
          `Question action (id=${id}) is configured with a section group (id=${sectionGroupId}) that does not belong in this content page (id=${contentPage.id})`
        );
        return undefined;
      }

      if (!getHasValidQuestionConditions(contentSnippetId, questionAnswer, questionConditions)) {
        return undefined;
      }

      return {
        ...sectionGroup,
        visible,
      };
    })
    .filter((contentSnippet) => contentSnippet) as ISectionGroupTab[];
};

const getValidQuestionAction = (
  action: IQuestionAction,
  contentSnippetId: IContentSnippet['id'],
  questionAnswer: IQuestionAnswer,
  typeName: DBQuestionActionTypeNameType
): IQuestionAction | undefined => {
  const { conditions: questionConditions = [], __typename: typename } = action;

  if (typename !== typeName) {
    return undefined;
  }

  if (!getHasValidQuestionConditions(contentSnippetId, questionAnswer, questionConditions)) {
    return undefined;
  }

  return action;
};

const getValidQuestionActions = (
  actions: IQuestionAction[],
  contentSnippetId: IContentSnippet['id'],
  questionAnswer: IQuestionAnswer,
  typeName: DBQuestionActionTypeNameType
): IQuestionAction[] => {
  return actions.filter((action) => Boolean(getValidQuestionAction(action, contentSnippetId, questionAnswer, typeName)));
};

const getUpdatedContentGroupingElements = (
  contentPage: IContentPage | undefined,
  contentSnippetId: IContentSnippet['id'],
  questionActions: IQuestionAction[],
  questionAnswer: IQuestionAnswer
): {
  contentSectionContainers: IContentSectionContainer[];
  contentSections: IContentSection[];
  contentSnippets: IContentSnippet[];
  sectionGroups: ISectionGroupTab[];
} => {
  if (isEmpty(questionAnswer)) {
    return {
      contentSectionContainers: [],
      contentSections: [],
      contentSnippets: [],
      sectionGroups: [],
    };
  }

  const hideContentSectionContainerQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionHideContainerType'
  );

  const hideContentSectionQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionHideSectionType'
  );

  const hideSectionGroupQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionHideSectionGroupType'
  );

  const hideContentSnippetQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionHideType'
  );

  const showContentSectionContainerQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionShowContainerType'
  );

  const showContentSectionQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionShowSectionType'
  );

  const showSectionGroupQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionShowSectionGroupType'
  );

  const showContentSnippetQuestionActions = getValidQuestionActions(
    questionActions,
    contentSnippetId,
    questionAnswer,
    'QuestionActionShowType'
  );

  const hideContentSectionContainers = getUpdatedContentSectionContainers(
    contentPage,
    contentSnippetId,
    hideContentSectionContainerQuestionActions,
    questionAnswer,
    false
  );

  const hideContentSections = getUpdatedContentSections(
    contentPage,
    contentSnippetId,
    hideContentSectionQuestionActions,
    questionAnswer,
    false
  );

  const hideContentSnippets = getUpdatedContentSnippets(
    contentPage,
    contentSnippetId,
    hideContentSnippetQuestionActions,
    questionAnswer,
    false
  );

  const hideSectionGroups = getUpdatedSectionGroups(contentPage, contentSnippetId, hideSectionGroupQuestionActions, questionAnswer, false);

  const showContentSectionContainers = getUpdatedContentSectionContainers(
    contentPage,
    contentSnippetId,
    showContentSectionContainerQuestionActions,
    questionAnswer,
    true
  );

  const showContentSections = getUpdatedContentSections(
    contentPage,
    contentSnippetId,
    showContentSectionQuestionActions,
    questionAnswer,
    true
  );

  const showContentSnippets = getUpdatedContentSnippets(
    contentPage,
    contentSnippetId,
    showContentSnippetQuestionActions,
    questionAnswer,
    true
  );

  const showSectionGroups = getUpdatedSectionGroups(contentPage, contentSnippetId, showSectionGroupQuestionActions, questionAnswer, true);

  return {
    contentSectionContainers: [...hideContentSectionContainers, ...showContentSectionContainers],
    contentSections: [...hideContentSections, ...showContentSections],
    contentSnippets: [...hideContentSnippets, ...showContentSnippets],
    sectionGroups: [...hideSectionGroups, ...showSectionGroups],
  };
};

const getUpdatedContentGroupingElementsFromQuestionActionConditions = (contentPage: IContentPage) => {
  const { actions } = contentPage;

  const contentSnippetQuestionIdsAndAnswers = orderBy(
    actions.flatMap(({ conditions }) =>
      conditions.flatMap(({ answer, contentSnippetQuestionId, id }) => ({
        answer,
        contentSnippetQuestionId,
        id,
      }))
    ),
    'contentSnippetQuestionId'
  );

  return contentSnippetQuestionIdsAndAnswers
    .map(({ answer, contentSnippetQuestionId }) => {
      const { contentSectionContainers, contentSections, contentSnippets, sectionGroups } = getUpdatedContentGroupingElements(
        contentPage,
        contentSnippetQuestionId,
        actions,
        answer
      );

      return {
        contentSectionContainers,
        contentSections,
        contentSnippets,
        sectionGroups,
      };
    })
    .reduce(
      (accumulator, current) => ({
        contentSectionContainers: uniqBy([...accumulator.contentSectionContainers, ...current.contentSectionContainers], ({ id }) => id),
        contentSections: uniqBy([...accumulator.contentSections, ...current.contentSections], ({ id }) => id),
        contentSnippets: uniqBy([...accumulator.contentSnippets, ...current.contentSnippets], ({ id }) => id),
        sectionGroups: uniqBy([...accumulator.sectionGroups, ...current.sectionGroups], ({ id }) => id),
      }),
      {
        contentSectionContainers: [],
        contentSections: [],
        contentSnippets: [],
        sectionGroups: [],
      }
    );
};

const hasAnsweredMultiResponseTextMinResponses = (contentPage: IContentPage | undefined): boolean => {
  const multiResponseTextContentSnippets = getContentSnippetsByQuestionConfigurationType(contentPage, 'MultiResponseText');

  return multiResponseTextContentSnippets.reduce((accumulator: boolean, contentSnippet: IContentSnippet) => {
    const { answer, configuration } = contentSnippet;
    const { minResponse = 0 } = configuration ?? {};
    const { responses = [] } = (answer ?? {}) as IQuestionAnswerMultiResponseText;

    return accumulator && minResponse <= responses.length;
  }, true);
};

export {
  getAllContentSnippetQuestions,
  getAllFlattenedContentPages,
  getAllFlattenedContentSectionContainers,
  getAllFlattenedContentSections,
  getAllFlattenedContentSnippetForms,
  getAllFlattenedContentSnippets,
  getAllFlattenedSectionGroups,
  getContentSection,
  getContentSectionContainer,
  getContentSnippet,
  getContentSnippetsByQuestionConfigurationType,
  getContentSnippetFormId,
  getContentSnippetNumberAnswer,
  getHasValidQuestionConditions,
  getQuestionAnswerNumberTotals,
  getRequiredSectionGroups,
  getSectionGroup,
  getUpdatedContentGroupingElements,
  getUpdatedContentGroupingElementsFromQuestionActionConditions,
  getUpdatedContentSectionContainers,
  getUpdatedContentSections,
  getUpdatedContentSnippets,
  getUpdatedSectionGroups,
  getValidQuestionActions,
  hasAnsweredMultiResponseTextMinResponses,
};
