import React, { useEffect, useRef } from 'react';

import { DangerouslySetHtmlContentProps } from './DangerouslySetHtmlContent.interfaces';

const DangerouslySetHtmlContent = ({ html, dangerouslySetInnerHTML, ...rest }: DangerouslySetHtmlContentProps) => {
  // Destructure 'dangerouslySetInnerHTML' to remove it from props passed to the div
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(divRef.current && html)) {
      throw new Error("html prop can't be null");
    }

    const fragment = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = '';
    divRef.current.appendChild(fragment);
  }, [divRef, html]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...rest} ref={divRef} />;
};

export { DangerouslySetHtmlContent };
