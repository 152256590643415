import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_MYAPP_VERSION = gql`
  mutation createAppVersionRequest(
    $version: String!
    $type: EAppReleaseType!
    $identifier: String!
  ) {
    app {
      appVersionMutation {
        createAppVersion(
          inputType: { version: $version, type: $type, identifier: $identifier }
        ) {
          id
          appId
        }
      }
    }
  }
`;

const createAppVersionRequest = ({
  version,
  type,
  identifier,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_MYAPP_VERSION}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        version,
        type,
        identifier,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.app.appVersionMutation.createAppVersion);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createAppVersionRequest.propTypes = {
  identifier: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

createAppVersionRequest.defaultProps = {};

export default createAppVersionRequest;
