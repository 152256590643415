/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react';

import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import Tree, { moveItemOnTree, mutateTree } from '@atlaskit/tree';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dropdown from './Dropdown';

import SortContentPage from '../../../middleware/ContentPage/sortContentPage';
import PageSideBar from '../../RightNavBar/PageSideBar';
import Backdrop from '../../UI/Backdrop/Backdrop';

const Container = styled.div`
  height: calc(100vh - 9.5rem);
  padding-bottom: 4rem;
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px grey;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent linear-gradient(65deg, #173567 0%, #972868 68%);
    border-radius: 10px;
  }
`;

const EditBox = styled.div`
  position: relative;
  top: -10px;
  visibility: hidden;
`;

const DragHandler = styled.span`
  padding: 0 0.5em;
  visibility: hidden;
`;

const TreeItem = styled.span.attrs(({ pageId }) => ({
  id: `tree-item${pageId}`,
}))`
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#4bc2ca' : 'transparent')};
  -webkit-box-align: center;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  min-height: 2rem;
  min-width: 10em;
  padding: 8px 12px 7px;
  text-decoration: none;

  &:hover {
    background-color: #fff;
    color: #000;
    text-decoration: none;
    ${EditBox} {
      visibility: visible;
    }
    ${DragHandler} {
      visibility: visible;
    }
  }
`;

const IconBox = styled.div`
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  transition: padding 200ms ease 0s;
`;

const TitleBox = styled.span`
  -webkit-box-flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  overflow: hidden;
`;

const TitleContent = styled.span`
  display: block;
  flex: 1 1 auto;
  line-height: 1.5;
  overflow: hidden;
  padding: 0.2rem 0.5rem 0.2rem 0.3rem;
`;

export default class NavTree extends Component {
  constructor(props) {
    super(props);
    const { defaultTree, activeId } = this.props;
    this.navItemRef = React.createRef();
    this.state = {
      tree: defaultTree,
      updateNode: {},
      isSideBarOpen: false,
      currentItem: {},
      pageSideBarAction: '',
      sortRequest: false,
      activeId,
    };
  }

  static getIcon(item, onExpand, onCollapse) {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <Button appearance="subtle-link" onClick={() => onCollapse(item.id)} spacing="none">
          <ChevronDownIcon label="" onClick={() => onCollapse(item.id)} primaryColor="#fff" size="medium" />
        </Button>
      ) : (
        <Button appearance="subtle-link" onClick={() => onExpand(item.id)} spacing="none">
          <ChevronRightIcon label="" onClick={() => onExpand(item.id)} primaryColor="#fff" size="medium" />
        </Button>
      );
    }
  }

  handleDropDown = () => {
    const { isDropDownOpen } = this.state;
    this.setState({
      isDropDownOpen: !isDropDownOpen,
    });
  };

  handleDeleteItem = id => {
    const { activeId, tree } = this.state;
    const { getActivePageId } = this.props;
    delete tree.items[id];
    const tmpChildren = tree.items.root.children.filter(item => item !== id);
    const nTree = {
      ...tree,
      items: {
        ...tree.items,
        root: {
          ...tree.items.root,
          children: tmpChildren,
        },
      },
    };

    this.setState({ tree: nTree });
    if (id === activeId) {
      if (nTree.items.root.children.length > 0) {
        getActivePageId(nTree.items.root.children[0]);
        this.setState({ activeId: nTree.items.root.children[0] });
      } else {
        getActivePageId(null);
      }
    }
  };

  renderItem = ({ item, onExpand, onCollapse, provided, snapshot }) => {
    const { handleRefresh, getActivePageId, isSettingsOnly } = this.props;
    const { activeId } = this.state;

    return (
      <div ref={provided.innerRef} {...provided.draggableProps}>
        <TreeItem pageId={item.id} ref={this.navItemRef} isActive={activeId === item.id} isExpanded={item.isExpanded}>
          <DragHandler {...provided.dragHandleProps} isDragging={snapshot.isDragging}>
            ⁝
          </DragHandler>
          <IconBox>{NavTree.getIcon(item, onExpand, onCollapse)}</IconBox>
          <TitleBox onClick={() => getActivePageId(item.id)}>
            {item.data && item.data.title && <TitleContent>{item.data.title || ''}</TitleContent>}
          </TitleBox>
          <EditBox>
            <Dropdown
              handleDeleteItem={this.handleDeleteItem}
              handleRefresh={handleRefresh}
              isSettingsOnly={isSettingsOnly}
              item={item}
              onSideBarOpen={action =>
                this.setState({
                  isSideBarOpen: true,
                  currentItem: item,
                  pageSideBarAction: action,
                })
              }
            />
          </EditBox>
        </TreeItem>
      </div>
    );
  };

  onExpand = itemId => {
    const { tree } = this.state;
    this.setState({
      tree: mutateTree(tree, itemId, { isExpanded: true }),
    });
  };

  onCollapse = itemId => {
    const { tree } = this.state;
    this.setState({
      tree: mutateTree(tree, itemId, { isExpanded: false }),
    });
  };

  onDragEnd = (source, destination) => {
    const { tree } = this.state;
    if (!destination) {
      return;
    }
    const parentId = destination.parentId === 'root' ? null : Number(destination.parentId);

    const newTree = moveItemOnTree(tree, source, destination);

    newTree.items[destination.parentId].isExpanded = true;
    this.setState({
      updateNode: {
        movedId: Number(tree.items[source.parentId].children[source.index]),
        parentId,
        sort: Number(destination.index || 0),
      },
      sortRequest: true,
      tree: newTree,
    });
  };

  handleError = () => {
    this.setState({
      sortRequest: false,
    });
  };

  handleSortSuccess = () => {
    this.setState({
      sortRequest: false,
    });
  };

  componentDidMount() {
    // Temporary solution because cannot give a ref to styled-component and the dnd parent
    document.getElementById(`tree-item${this.state.activeId}`).scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { tree, isSideBarOpen, currentItem, pageSideBarAction, updateNode, sortRequest } = this.state;
    const { handleRefresh } = this.props;
    return (
      <Container className="page-nav-bar">
        <Tree
          isDragEnabled
          isNestingEnabled
          onCollapse={this.onCollapse}
          onDragEnd={this.onDragEnd}
          onExpand={this.onExpand}
          renderItem={this.renderItem}
          tree={tree}
        />
        {isSideBarOpen && currentItem && (
          <div>
            <PageSideBar
              action={pageSideBarAction}
              handleCloseSideBar={() => this.setState({ isSideBarOpen: false })}
              handleRefresh={handleRefresh}
              item={currentItem}
            />
            <Backdrop isClicked={() => this.setState({ isSideBarOpen: false })} />
          </div>
        )}
        {sortRequest && Object.entries(updateNode).length !== 0 && (
          <SortContentPage
            contentPageId={updateNode.movedId}
            onError={this.handleError}
            onSuccessResult={this.handleSortSuccess}
            parentId={updateNode.parentId}
            sort={updateNode.sort}
          />
        )}
      </Container>
    );
  }
}

NavTree.propTypes = {
  defaultTree: PropTypes.shape({
    items: PropTypes.object,
    rootId: PropTypes.string,
  }).isRequired,
  getActivePageId: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  activeId: PropTypes.number,
  isSettingsOnly: PropTypes.bool,
};

NavTree.defaultProps = {
  activeId: null,
  isSettingsOnly: false,
};
