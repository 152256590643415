import gql from 'graphql-tag';

export const GET_ORDER_ITEMS = gql`
  query getOrderSummary($orderGuid: Guid!) {
    orders {
      getOrderSummary(orderGuid: $orderGuid) {
        items {
          id
          quantity
          userFlow {
            id
            title
          }
        }
        totalAmountExTaxInDollars
        totalDiscountInDollars
      }
    }
  }
`;
