import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import LeftNavBar from './LeftNavBar';
import { ContentItem, Icon } from './styled';

import AppContext from '../../context/AppContext';
import IconSocial from '../../static/img/icon-social.svg';
import IconContent from '../../static/img/leftNavBar/Content.svg';
import IconLibrary from '../../static/img/leftNavBar/Library.svg';
import IconReporting from '../../static/img/leftNavBar/Reporting.svg';
import IconScheduler from '../../static/img/leftNavBar/Scheduler.svg';
import IconSubscription from '../../static/img/leftNavBar/subscription.svg';
import IconUsers from '../../static/img/leftNavBar/Users.svg';
import '../../styles/_fonts.css';

const ProjectNavBar = (props) => {
  const { title } = props;
  const { org, project, hasSubscriptions } = useContext(AppContext);
  const { services = [] } = project;

  const items = [
    {
      id: 1,
      name: 'Content',
      src: IconContent,
      path: '/group',
      isVisible: services.includes('EKARDO'),
    },
    {
      id: 2,
      name: 'Library',
      src: IconLibrary,
      path: '/library',
      isVisible: services.includes('EKARDO'),
    },
    {
      id: 4,
      name: 'Reporting',
      src: IconReporting,
      path: '/reporting/export-logins',
      isVisible: true,
    },
    {
      id: 6,
      name: 'Users',
      src: IconUsers,
      path: `/users`,
      isVisible: true,
    },
    {
      id: 8,
      name: 'Subscriptions',
      src: IconSubscription,
      path: '/subscriptions',
      isVisible: hasSubscriptions,
    },
    {
      id: 10,
      name: 'Notifications',
      src: IconScheduler,
      path: '/notifications',
      isVisible: services.includes('HOWLER'),
    },
    {
      id: 11,
      name: 'Social',
      src: IconSocial,
      path: '/social',
      isVisible: services.includes('BONOBO_MANAGEMENT'),
    },
  ];

  const menuItems = items
    .filter((item) => item.isVisible)
    .map((item) => (
      <a key={item.id} href={`/dashboard/${org.key}/project/${project.id}${item.path}`}>
        <ContentItem>
          <Icon alt="icon" src={item.src} />
          <p>{item.name}</p>
        </ContentItem>
      </a>
    ));
  return (
    <LeftNavBar title={title} hasUserType>
      <div>{menuItems}</div>
    </LeftNavBar>
  );
};

ProjectNavBar.propTypes = {
  title: PropTypes.string,
};

ProjectNavBar.defaultProps = {
  title: 'Projects',
};
export default ProjectNavBar;
