import React, { Fragment, useEffect, useState } from 'react';

import { getS3SignedUrl } from '@netfront/common-library';
import to from 'await-to-js';

import { VideoSnippetProps } from './VideoSnippet.types';

import { ERROR_MESSAGES } from '../../../core';

const VideoSnippet = ({ figureCaption, video: { contentType, s3Key } }: VideoSnippetProps) => {
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    const loadVideoUrl = async () => {
      const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

      if (getS3SignedUrlError) {
        throw getS3SignedUrlError;
      }

      if (!s3SignedUrl) {
        throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
      }

      setVideoUrl(s3SignedUrl);
    };

    loadVideoUrl();
  }, [contentType, s3Key]);

  return (
    <Fragment>
      {videoUrl && (
        <div className="c-video-snippet">
          <figure className="c-video-snippet__figure">
            <video className="c-video-snippet__video" controls>
              <source src={videoUrl} />
              <track kind="captions" srcLang="en" />
              Sorry, your browser does not support embedded videos.
            </video>
            {figureCaption ? <figcaption className="c-video-snippet__figure-caption">{figureCaption}</figcaption> : null}
          </figure>
        </div>
      )}
    </Fragment>
  );
};

export { VideoSnippet };
