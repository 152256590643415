import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const SNIP_TO_SNIPPET = gql`
  mutation SkipSnippet($contentGroupId: Int!, $targetSnippetId: Int!) {
    contentGroup {
      addAction(
        skipToSnippet: {
          contentGroupId: $contentGroupId
          targetSnippetId: $targetSnippetId
        }
      ) {
        id
      }
    }
  }
`;

const SkipToSnippet = ({
  contentGroupId,
  targetSnippetId,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={SNIP_TO_SNIPPET}>
    {(SkipSnippet, { loading, error, data }) => {
      const variables = {
        contentGroupId,
        targetSnippetId,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentGroup.addAction.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={SkipSnippet}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

SkipToSnippet.propTypes = {
  contentGroupId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  targetSnippetId: PropTypes.number.isRequired,
};

export default SkipToSnippet;
