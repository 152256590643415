/* eslint-disable react/jsx-props-no-spreading */
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TabPanel from '../TabPanel/TabPanel';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    fontFamily: 'calibri, Arial, sans-serif',
    width: '100%',
  },
  tabs: {
    backgroundColor: 'rgb(244, 245, 247)',
    margin: '2em -2em 0 -2em',
  },
  tab: {
    fontFamily: 'calibri, Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold',
    minWidth: 'fit-content',
    padding: '0 1.5em',
    textTransform: 'unset',

    '&:focus': {
      outline: 'none',
    },
  },
  panel: {
    margin: '0 -2em',
  },
}));

const SidebarTab = (props) => {
  const classes = useStyles();
  const {
    item0,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
    item9,
    label0,
    label1,
    label2,
    label3,
    label4,
    label5,
    label6,
    label7,
    label8,
    label9,
    items,
    selectedTab,
  } = props;
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    const value = parseInt(newValue, 10);
    setTabValue(value);

    if (selectedTab) {
      selectedTab(value, event);
    }
  };

  const a11yProps = (index) => ({
    id: `sidebar-tab-${index}`,
    'aria-controls': `sidebar-tabpanel-${index}`,
  });

  return (
    <div className={classes.root}>
      {!items.length && (
        <>
          <Tabs
            aria-label="sidebar tabs example"
            className={classes.tabs}
            indicatorColor="primary"
            onChange={handleTabChange}
            scrollButtons="auto"
            // eslint-disable-next-line
            TabIndicatorProps={{ style: { background: '#4bc1d9' } }}
            value={tabValue}
            variant="scrollable"
          >
            <Tab className={classes.tab} label={label0} {...a11yProps(0)} />
            {label1 && <Tab className={classes.tab} label={label1} {...a11yProps(1)} />}
            {label2 && <Tab className={classes.tab} label={label2} {...a11yProps(2)} />}
            {label3 && <Tab className={classes.tab} label={label3} {...a11yProps(3)} />}
            {label4 && <Tab className={classes.tab} label={label4} {...a11yProps(4)} />}
            {label5 && <Tab className={classes.tab} label={label5} {...a11yProps(5)} />}
            {label6 && <Tab className={classes.tab} label={label6} {...a11yProps(6)} />}
            {label7 && <Tab className={classes.tab} label={label7} {...a11yProps(7)} />}
            {label8 && <Tab className={classes.tab} label={label8} {...a11yProps(8)} />}
            {label9 && <Tab className={classes.tab} label={label9} {...a11yProps(9)} />}
          </Tabs>
          <div style={{ overflowY: 'scroll !important' }}>
            <TabPanel className={classes.panel} index={0} value={tabValue}>
              {item0}
            </TabPanel>
            {item1 && (
              <TabPanel className={classes.panel} index={1} value={tabValue}>
                {item1}
              </TabPanel>
            )}
            {item2 && (
              <TabPanel className={classes.panel} index={2} value={tabValue}>
                {item2}
              </TabPanel>
            )}
            {item3 && (
              <TabPanel className={classes.panel} index={3} value={tabValue}>
                {item3}
              </TabPanel>
            )}
            {item4 && (
              <TabPanel className={classes.panel} index={4} value={tabValue}>
                {item4}
              </TabPanel>
            )}
            {item5 && (
              <TabPanel className={classes.panel} index={5} value={tabValue}>
                {item5}
              </TabPanel>
            )}
            {item6 && (
              <TabPanel className={classes.panel} index={6} value={tabValue}>
                {item6}
              </TabPanel>
            )}
            {item7 && (
              <TabPanel className={classes.panel} index={7} value={tabValue}>
                {item7}
              </TabPanel>
            )}
            {item8 && (
              <TabPanel className={classes.panel} index={8} value={tabValue}>
                {item8}
              </TabPanel>
            )}
            {item9 && (
              <TabPanel className={classes.panel} index={9} value={tabValue}>
                {item9}
              </TabPanel>
            )}
          </div>
        </>
      )}

      {items.length > 1 ? (
        <Tabs
          aria-label="sidebar tabs example"
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleTabChange}
          scrollButtons="auto"
          // eslint-disable-next-line
          TabIndicatorProps={{ style: { background: '#4bc1d9 ' } }}
          value={tabValue}
          variant="scrollable"
        >
          {items.map((item, index) => (
            <Tab
              aria-controls={`sidebar-tabpanel-${index}`}
              className={classes.tab}
              id={`sidebar-tab-${index}`}
              key={item.title}
              label={item.title}
            />
          ))}
        </Tabs>
      ) : null}
      {items.map((item, index) => (
        <TabPanel
          className={classes.panel}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          value={tabValue}
        >
          {item.content}
        </TabPanel>
      ))}
    </div>
  );
};

SidebarTab.propTypes = {
  item0: PropTypes.node,
  item1: PropTypes.node,
  item2: PropTypes.node,
  item3: PropTypes.node,
  item4: PropTypes.node,
  item5: PropTypes.node,
  item6: PropTypes.node,
  item7: PropTypes.node,
  item8: PropTypes.node,
  item9: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  label0: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  label4: PropTypes.string,
  label5: PropTypes.string,
  label6: PropTypes.string,
  label7: PropTypes.string,
  label8: PropTypes.string,
  label9: PropTypes.string,
};

SidebarTab.defaultProps = {
  item0: null,
  item1: null,
  item2: null,
  item3: null,
  item4: null,
  item5: null,
  item6: null,
  item7: null,
  item8: null,
  item9: null,
  items: [],
  label0: '',
  label1: '',
  label2: '',
  label3: '',
  label4: '',
  label5: '',
  label6: '',
  label7: '',
  label8: '',
  label9: '',
};

export default SidebarTab;
