import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { Checkbox, Form, FormButtons, Label, RadioButtonGroup, Spacing, Tooltip } from '@netfront/ui-library';
import PropTypes from 'prop-types';

import { useToast } from '../../../../../../hooks';
import client from '../../../../../../middleware/client';
import { CREATE_ACTION_QUERY_MAP } from '../../../ActionTab.constants';
import { getEventValue, getEventChecked } from '../../../helpers';
import { useConditionAdd, useConditionLocations, useConditionSnippetAnswers } from '../../../hooks';
import {
  NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS,
  QUESTION_ACTION_PROP_TYPE,
  QUESTION_LOCATION_PROP_TYPE,
  SELECTED_CONDITION_QUESTION_PROP_TYPE,
  WRAPPER_PROP_TYPE,
} from '../../QuestionConditions.constants';

import '../../../ActionTab.css';

const DEFAULT_RADIO_CONDITION = {
  contentPageId: undefined,
  contentSnippetQuestionId: undefined,
  isTemplate: false,
  questionActionId: undefined,
  questionResponseRadioId: undefined,
  title: undefined,
};

const QuestionConditionRadioNew = ({ args, onAddQuestionConditionResponses, questionResponses }) => {
  const {
    actionWrapper,
    activePageId,
    conditionAction,
    questionAction,
    questionLocations,
    questionTitle,
    selectedConditionQuestionItem,
    onAddActionCompleted,
    onAddConditionCompleted,
    onAddQuestionConditionLocations,
    onAddSelectedQuestionItem,
    onCloseSidebar,
  } = args;

  const { handleToastError } = useToast();

  const { id: wrapperId, type: wrapper } = actionWrapper;

  const [currentAction, setCurrentAction] = useState(questionAction);
  const [isTemplateChecked, setIsTemplateChecked] = useState(false);
  const [newCondition, setNewCondition] = useState({
    ...DEFAULT_RADIO_CONDITION,
    title: questionTitle,
  });
  const [questionSnippetForms, setQuestionSnippetForms] = useState([]);

  const createConditionVariables = {
    contentPageId: newCondition.contentPageId,
    contentSnippetQuestionId: newCondition.contentSnippetQuestionId,
    isTemplate: isTemplateChecked,
    questionActionId: questionAction ? questionAction.id : currentAction?.id,
    questionResponseRadioId: newCondition.questionResponseRadioId,
    title: newCondition.title,
  };

  const handleAddConditionCompleted = ({ condition }) => {
    const { conditions = [] } = currentAction;

    const updatedAction = {
      ...currentAction,
      conditions: [...conditions, condition],
    };

    setTimeout(() => {
      setNewCondition(DEFAULT_RADIO_CONDITION);
      onAddSelectedQuestionItem(undefined);

      onAddConditionCompleted(updatedAction);
    }, NOTIFICATION_DISPLAY_TIME_IN_MILLISECONDS);
  };

  const handleGetConditionLocationsCompleted = ({ getSnippetForms }) => {
    setQuestionSnippetForms(getSnippetForms);

    const conditionItemLocations = getSnippetForms.map(
      ({
        contentPage: {
          id,
          title: contentPageTitle,
          contentGroup: { title: contentGroupTitle },
        },
      }) => ({
        id,
        labelText: `${contentPageTitle} - ${contentGroupTitle}`,
        value: id.toString(),
      }),
    );

    onAddQuestionConditionLocations(conditionItemLocations);
  };

  const handleGetSnippetAnswersCompleted = (data) => {
    const {
      configuration: {
        responseSet: { availableResponses },
      },
    } = data;

    if (availableResponses.length) {
      const [firstQuestionResponse] = availableResponses;

      setNewCondition((currentCondition) => ({
        ...currentCondition,
        questionResponseRadioId: firstQuestionResponse.id,
      }));
    }

    onAddQuestionConditionResponses(
      availableResponses.map(({ id, label }) => ({
        id,
        labelText: label,
        value: id.toString(),
      })),
    );
  };

  const handleGetError = (error) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleAddCondition } = useConditionAdd({
    onCompleted: handleAddConditionCompleted,
    onError: handleGetError,
    conditionType: 'radio',
    variables: createConditionVariables,
  });

  const { handleGetLocations } = useConditionLocations({
    onCompleted: handleGetConditionLocationsCompleted,
    onError: handleGetError,
  });

  const { handleGetSnippetAnswers } = useConditionSnippetAnswers({
    onCompleted: handleGetSnippetAnswersCompleted,
    onError: handleGetError,
  });

  useEffect(() => {
    handleGetSnippetAnswers({
      contentSnippetQuestionId: selectedConditionQuestionItem.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [executeCreateAction] = useMutation(CREATE_ACTION_QUERY_MAP[wrapper][conditionAction], {
    client,
    onCompleted(data) {
      const {
        questionAction: { addAction: action },
      } = data;

      setCurrentAction(action);

      onAddActionCompleted(action);

      createConditionVariables.questionActionId = action.id;

      handleAddCondition({
        createConditionVariables,
      });
    },
    onError(error) {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleIsTemplateChecked = (event) => {
    setIsTemplateChecked(getEventChecked(event));
  };

  const handleQuestionLocationInputChange = (event) => {
    setNewCondition({
      ...newCondition,
      contentPageId: Number(getEventValue(event)),
    });
  };

  const handleQuestionResponseSearchInputChange = (event) => {
    setNewCondition({
      ...newCondition,
      questionResponseRadioId: Number(getEventValue(event)),
    });
  };

  const handleSaveCondition = (event) => {
    event.preventDefault();

    const { contentPageId, contentSnippetQuestionId, questionResponseRadioId, title } = newCondition;

    if (questionAction) {
      handleAddCondition({
        createConditionVariables,
      });

      return;
    }

    if (!contentSnippetQuestionId || !questionResponseRadioId || !title || !contentPageId) {
      return;
    }

    executeCreateAction({
      variables: {
        contentPageId: activePageId,
        wrapperId,
      },
    });
  };

  useEffect(() => {
    if (!questionSnippetForms.length) {
      return;
    }
    const [firstContentSnippetForm] = questionSnippetForms;

    const {
      contentPage: { id },
    } = firstContentSnippetForm;

    setNewCondition((currentLocation) => ({
      ...currentLocation,
      contentPageId: id,
    }));
  }, [questionSnippetForms]);

  useEffect(() => {
    if (!selectedConditionQuestionItem) {
      return;
    }

    const {
      id,
      contentPage: { contentGroupId },
    } = selectedConditionQuestionItem;

    handleGetLocations({
      contentGroupId,
    });

    setNewCondition((currentCondition) => ({
      ...currentCondition,
      contentSnippetQuestionId: id,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConditionQuestionItem]);

  const { contentPageId, questionResponseRadioId } = newCondition;

  return (
    <>
      <Form onSubmit={handleSaveCondition}>
        {selectedConditionQuestionItem && Boolean(questionResponses.length) && Boolean(questionLocations.length) ? (
          <div className="c-action">
            <Spacing size="2x-large" />
            <div className="c-action__item h-flex">
              <>
                <Label
                  additionalClassNames="c-action__item__label h-flex"
                  forId="question response"
                  labelText="Question response"
                  isRequired
                >
                  <Tooltip placement="left" text="This action will execute if the radio selection is as selected below" />
                </Label>
                <RadioButtonGroup
                  items={questionResponses}
                  name="radio"
                  selectedValue={questionResponseRadioId ? questionResponseRadioId.toString() : ''}
                  onChange={handleQuestionResponseSearchInputChange}
                />
              </>
            </div>
            <Spacing size="2x-large" />
            <div className="c-action__item h-flex">
              <Label additionalClassNames="c-action__item__label h-flex" forId="question location" labelText="Question location">
                <Tooltip placement="left" text="This action will execute if the radio selection is as selected below" />
              </Label>
              <RadioButtonGroup
                items={questionLocations}
                name="location"
                selectedValue={contentPageId ? contentPageId.toString() : ''}
                onChange={handleQuestionLocationInputChange}
              />
            </div>
            <Spacing size="2x-large" />
            <div className="c-action__item h-flex">
              <Label additionalClassNames="c-action__item__label h-flex" forId="template" labelText="Template">
                <Tooltip placement="left" text="Save condition to reuse later" />
              </Label>
              <div className="c-action__item__checkbox h-flex">
                <Checkbox
                  id="template"
                  isChecked={isTemplateChecked}
                  labelText="Save conditional configuration for reuse"
                  name="template"
                  onChange={handleIsTemplateChecked}
                />
              </div>
            </div>
          </div>
        ) : null}
        {selectedConditionQuestionItem ? (
          <FormButtons
            additionalClassNames="c-form-buttons__action-tab"
            isSaveButtonDisabled={Boolean(!questionTitle)}
            onClose={onCloseSidebar}
          />
        ) : null}
      </Form>
    </>
  );
};

QuestionConditionRadioNew.propTypes = {
  actionWrapper: WRAPPER_PROP_TYPE,
  activePageId: PropTypes.number,
  conditionAction: PropTypes.string,
  onAddActionCompleted: PropTypes.func,
  onAddConditionCompleted: PropTypes.func,
  onAddQuestionConditionLocations: PropTypes.func,
  onAddQuestionConditionResponses: PropTypes.func,
  onAddSelectedQuestionItem: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  questionAction: QUESTION_ACTION_PROP_TYPE,
  questionLocations: QUESTION_LOCATION_PROP_TYPE,
  questionResponses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  questionTitle: PropTypes.string,
  selectedConditionQuestionItem: SELECTED_CONDITION_QUESTION_PROP_TYPE,
};

export { QuestionConditionRadioNew };
