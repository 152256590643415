import React from 'react';

import { ButtonSizeType, LinkButton } from '@netfront/ui-library';

import { ButtonSnippetProps } from './ButtonSnippet.types';

const ButtonSnippet = ({ cssStyle, hyperlink = '', innerText = '', page, style = 'SMALL', target = '_blank' }: ButtonSnippetProps) => {
  const { backgroundColor, borderColor } = cssStyle ?? {};
  const { url: contentPageUrl } = page ?? {};

  const url = contentPageUrl
    ? `${window.location.href.split('/').slice(0, -1).join('/')}/${contentPageUrl}`
    : decodeURIComponent(hyperlink);

  return (
    <LinkButton
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      size={style.toLowerCase() as ButtonSizeType}
      target={target}
      text={innerText}
      url={url}
    />
  );
};

export { ButtonSnippet };
