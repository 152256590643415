import React, { useEffect, useState, useRef } from 'react';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import Topic from '../TabViews/Topic';

import { topicsTableHeadings } from './TopicsTable.constants';
import { getTopicsTableData } from './TopicsTable.helpers';

import { useSocialState } from '../../../../context/SocialPage/SocialPageContext';
import { useApolloClient } from '../../../../middleware/apollo/useApolloClient';
import { CREATE_TOPIC } from '../../../../middleware/Social/createTopic';
import { GET_TOPICS_PER_PROJECT } from '../../../../middleware/Social/getAllTopics';
import addIcon from '../../../../static/img/add-icon.svg';
import closeIcon from '../../../../static/img/close-icon.svg';
import ImageIcon from '../../../Icon/ImageIcon';
import Preloader from '../../../Preloader/Preloader';
import { SideBar } from '../../../SideBar/SideBar';
import { EKReactTable } from '../../../Table/EKReactTable/EKReactTable';
import SquareButton from '../../../UI/Button/SquareButton';
import Input from '../../../UI/Input/InputWhite';
import NotificationMessage from '../../../UI/NotificationMessage/NotificationMessage';
import Tooltip from '../../../UI/Tooltip/Tooltips';

import '../../../SideBar/Views/Views.scss';

const TopicsTable = () => {
  const client = useRef(useApolloClient('bonobo')).current;
  const [pageState, dispatch] = useSocialState();
  const { topics } = pageState;
  const { projectId } = useParams();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [canCreateTopic, setCanCreateTopic] = useState(false);

  const [title, setTitle] = useState('');

  const [notifications, setNotifications] = useState([]);

  const handleClearNotifications = () => {
    setNotifications([]);
    setCanCreateTopic(false);
  };

  const [getTopicsRequest, { loading: isTopicsLoading }] = useLazyQuery(GET_TOPICS_PER_PROJECT, {
    client,
    onCompleted({ topic: { getTopicsPerProject } }) {
      dispatch({
        type: 'setTopics',
        newState: getTopicsPerProject,
      });
    },
  });

  const [createTopicRequest] = useMutation(CREATE_TOPIC, {
    client,
    onCompleted(response) {
      const {
        topic: { addTopic },
      } = response;
      dispatch({
        type: 'addTopic',
        newState: addTopic,
      });

      setNotifications([
        ...notifications,
        {
          group: 'topic',
          message: 'Topic successfully created',
          type: 'success',
        },
      ]);
    },
    onError(error) {
      setNotifications([
        ...notifications,
        {
          group: 'topic',
          message: error,
          type: 'error',
        },
      ]);
    },
  });

  const handleOpenSideBar = (id) => {
    dispatch({
      type: 'setActiveTopicId',
      newState: id,
    });

    setIsSideBarOpen(true);
  };

  const handleCloseSideBar = () => {
    setIsSideBarOpen(false);
  };

  const handleCreateTopicRequest = () => {
    createTopicRequest({
      variables: {
        projectId,
        title,
      },
    });
  };

  const handleShowAddTopicInput = () => {
    setCanCreateTopic(!canCreateTopic);
  };

  useEffect(() => {
    getTopicsRequest({ variables: { projectId } });
  }, []);

  const sideBarTabViews = [
    {
      component: <Topic onCloseHandler={handleCloseSideBar} />,
      label: 'Topic',
      id: 0,
    },
  ];

  const createTopicIcon = canCreateTopic ? closeIcon : addIcon;

  if (isTopicsLoading) {
    return <Preloader />;
  }

  return (
    <>
      <div className="h-flex h-justify-content-between h-align-items-center h-margin-bottom-sm">
        <div className="h-flex h-align-items-center">
          <label className="c-input-title h-margin-right-sm  h-margin-left-sm h-margin-bottom-none" htmlFor="create-topic">
            Add topic
          </label>
          <button className="c-icon-button" type="button" onClick={handleShowAddTopicInput}>
            <ImageIcon src={createTopicIcon} />
          </button>
        </div>
        <Tooltip text="Create a new topic" />
      </div>
      {canCreateTopic && (
        <div className="h-flex h-align-items-center h-margin-bottom-sm">
          <Input id="create-topic" isChanged={(value) => setTitle(value)} style={{ margin: 0 }} text={title} value={title} />
          <SquareButton isClicked={handleCreateTopicRequest}>Save</SquareButton>
        </div>
      )}
      {notifications.map(({ group, message, type }) => {
        if (!group.includes('topic')) {
          return null;
        }

        return <NotificationMessage message={message} type={type} onHideNotificationMessage={handleClearNotifications} />;
      })}
      <EKReactTable columns={topicsTableHeadings} data={getTopicsTableData(topics, (id) => handleOpenSideBar(id))} />
      <SideBar isOpen={isSideBarOpen} selectedTabId={0} tabViews={sideBarTabViews} onCloseHandler={handleCloseSideBar} />
    </>
  );
};

export default TopicsTable;
