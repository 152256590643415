import React from 'react';
import { AdvanceTitle, TitleText } from './styled';
import Tooltip from '../UI/Tooltip/Tooltips';

const AdvancedTitleWithTooltip = ({ title, tooltipText }) => {
  return (
    <AdvanceTitle>
      <TitleText>{title}</TitleText>
      <Tooltip text={tooltipText} />
    </AdvanceTitle>
  );
};

export default AdvancedTitleWithTooltip;
