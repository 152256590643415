import { gql } from '@apollo/client';

const START_USER_FLOW_STEP_MUTATION = gql`
  mutation startUserFlowStep($userFlowStepId: Int!) {
    userFlowStepTrack {
      startUserFlowStep(stepId: $userFlowStepId) {
        id
      }
    }
  }
`;

export { START_USER_FLOW_STEP_MUTATION };
