import React from 'react';

import { TextSnippetProps } from './TextSnippet.types';

import { DOMPurifySanitizedHtmlContent } from '../../DOMPurifySanitizedHtmlContent';

import './TextSnippet.css';

const TextSnippet = ({ placeholders, text = '', visible = true }: TextSnippetProps) => {
  let updatedText = text;

  if (placeholders && visible) {
    updatedText = Object.keys(placeholders).reduce((accumulator, placeholder) => {
      if (!accumulator.includes(placeholder)) {
        return accumulator;
      }

      return accumulator.replaceAll(placeholder, placeholders[placeholder]);
    }, text);
  }

  return (
    <React.Fragment>
      {updatedText && visible && (
        <div className="ek-text-snippet c-text-snippet">
          <DOMPurifySanitizedHtmlContent
            config={{
              ADD_ATTR: ['target'],
            }}
            html={updatedText}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export { TextSnippet };
