import styled from 'styled-components';

export const Icon = styled.img`
  margin: 1em;
  max-width: 1.5rem;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em;
  color: white;
  &:hover {
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    ${Icon} {
      background-color: #403167;
      border-radius: 1em;
    }
  }
`;
