import { gql } from '@apollo/client';

const GET_NEXT_OR_PREVIOUS_CONTENT_PAGE_WITHOUT_TRACKING_QUERY = gql`
  query getNextOrPreviousPageWithoutTracking($contentPageId: Int!, $navigationDirection: EContentPageNavigationDirection!) {
    contentPage {
      getNextOrPreviousPageWithoutTracking(currentPageId: $contentPageId, navigation: $navigationDirection) {
        id
        title
        url
      }
    }
  }
`;

export { GET_NEXT_OR_PREVIOUS_CONTENT_PAGE_WITHOUT_TRACKING_QUERY };
