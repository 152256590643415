import gql from 'graphql-tag';

export const SEARCH_CONTENT_GROUPINGS = gql`
  query SearchContentGroups($projectId: String!) {
    contentGroup {
      getGroupings(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
