import React from 'react';

import { useLocation, useParams } from 'react-router-dom';

import TopicsTable from '../../components/Social/Topics/Tables/TopicsTable';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SocialPageState from '../../context/SocialPage/SocialPageState';
import { getBreadCrumbPath } from '../../utils/utils';

const TopicManagementPage = () => {
  const params = useParams();

  const { pathname } = useLocation();
  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Social'), ...getBreadCrumbPath(pathname, 0, 'Topics')];

  return (
    <SocialPageState>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage social topics"
        pageDescription="Manage social topics"
        pageTitle="Social topics"
        params={params}
      >
        <TopicsTable />
      </PageWrapper>
    </SocialPageState>
  );
};

export default TopicManagementPage;
