import gql from 'graphql-tag';

export const FRAGMENT_NUMBER = gql`
  fragment numberConf on Number {
    description
    id
    maxValue
    minValue
    defaultValue
    messages {
      correct
      incomplete
      incorrect
    }
    __typename
  }
`;
