import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const createImageMapRegionMutation = gql`
  mutation CreateImageMapRegion(
    $imageMapId: Int!
    $name: String!
    $x: Int!
    $y: Int!
    $alternativeImageId: String
    $iconType: ERegionIconType!
    $size: ERegionIconSize
  ) {
    region {
      addRegion(
        contentGroupId: $imageMapId
        x: $x
        y: $y
        icon: $iconType
        name: $name
        size: $size
        alternativeImageId: $alternativeImageId
      ) {
        contentPage {
          id
        }
      }
    }
  }
`;

const createImageMapRegion = ({
  imageMapId,
  name,
  x,
  y,
  alternativeImageId,
  size,
  iconType,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={createImageMapRegionMutation}>
    {(createImageMapRegionFunc, { loading, error, data }) => {
      const variables = {
        imageMapId,
        name,
        x,
        y,
        alternativeImageId,
        size,
        iconType,
      };
      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.imageMap.createImageMapItem.id);
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createImageMapRegionFunc}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createImageMapRegion.propTypes = {
  iconType: PropTypes.string.isRequired,
  imageMapId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  alternativeImageId: PropTypes.string,
};

createImageMapRegion.defaultProps = {
  alternativeImageId: null,
};

export default createImageMapRegion;
