import React from 'react';

import cx from 'classnames';
import Modal from 'react-modal';

import { ButtonIconOnly } from '../ButtonIconOnly';
import { Button } from '../Buttons';
import { Icon } from '../Icon';

import { ICON_ID_TYPES } from './Dialog.constants';
import { DialogProps } from './Dialog.types';

import { IconIdType } from '../../types';

import './Dialog.css';

const Dialog = ({
  additionalClassNames,
  appRootId = 'root',
  children,
  confirmText = 'Confirm',
  isOpen = false,
  onCancel,
  onConfirm,
  onClose,
  title,
  type,
}: DialogProps) => {
  const iconId: IconIdType | undefined = type ? ICON_ID_TYPES[type] : undefined;

  return (
    <Modal
      appElement={document.getElementById(appRootId) as HTMLElement}
      bodyOpenClassName="h-no-scroll"
      className={cx('c-dialog', additionalClassNames)}
      isOpen={isOpen}
      overlayClassName="c-dialog-overlay"
      onRequestClose={onClose}
    >
      <ButtonIconOnly additionalClassNames="c-dialog__close-icon" iconId="id_close_icon" text="Close" onClick={onClose} />
      <div className="c-dialog__header">
        {iconId ? <Icon className="c-dialog-icon" id={iconId} /> : null}
        <h3 className="c-dialog__title">{title}</h3>
      </div>
      <div className="c-dialog__content">{children}</div>
      {onCancel || onConfirm ? (
        <div className="c-dialog__buttons">
          {onCancel ? <Button text="Cancel" onClick={onCancel} /> : null}
          {onConfirm ? <Button text={confirmText} variant="dark" onClick={onConfirm} /> : null}
        </div>
      ) : null}
    </Modal>
  );
};

export { Dialog };
