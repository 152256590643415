export const SnippetTypeConst = {
  TEXT: 'ContentSnippetTextType',
  IMAGE: 'ContentSnippetImageType',
  VIDEO: 'ContentSnippetVideoType',
  AUDIO: 'ContentSnippetAudioType',
  DOCUMENT: 'ContentSnippetDocumentType',
  BUTTON: 'ContentSnippetButtonType',
  REVEAL: 'Reveal',
  QUOTE: 'ContentSnippetQuoteType',
  TABLE: 'Table',
  CODE: 'ContentSnippetCodeType',
  MAP: 'ContentSnippetMapType',
  QUESTION: 'ContentSnippetQuestionType',
  FORM: 'ContentSnippetFormType',
  SLIDER: 'ContentSnippetSliderType',
  AVATAR: 'ContentSnippetAvatarType',
  SPACER: 'ContentSnippetSpacerType',
  APP: 'ContentSnippetAppType',
  SUMMARY: 'ContentSnippetSummaryType',
  EMBED: 'ContentSnippetEmbedType',
  IMAGE_MAP: 'ContentSnippetImageMapType',
};
