import React from 'react';

import cx from 'classnames';

import { LinkButtonProps } from './LinkButton.types';

import './LinkButton.css';

const LinkButton = ({
  additionalClassNames,
  backgroundColor,
  borderColor,
  id,
  linkComponent = 'a',
  size = 'small',
  target,
  text,
  url,
}: LinkButtonProps) => {
  const Link = linkComponent;

  const linkButtonClassNames = cx('c-link-button', additionalClassNames, {
    [`c-link-button--${size}`]: Boolean(size),
  });

  return (
    <Link
      className={linkButtonClassNames}
      href={url}
      id={id}
      rel={url.startsWith('http') ? 'noopener noreferrer' : undefined}
      style={{
        backgroundColor /* stylelint-disable-line value-keyword-case */,
        borderColor /* stylelint-disable-line value-keyword-case */,
      }}
      target={target}
    >
      <span className="c-link-button__text">{text}</span>
    </Link>
  );
};

export { LinkButton };
