import gql from 'graphql-tag';

export const GET_PROJECT_QUERY = gql`
  query($projectId: String!) {
    project {
      getProject(projectId: $projectId) {
        availableContentGroupType
      }
    }
  }
`;
