import React from 'react';

import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const PrettoSlider = withStyles({
  root: {
    color: '#e1e1e1',
    width: '100%',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const ResSlider = props => {
  const {
    title,
    max,
    min,
    defaultValue,
    step,
    ticks,
    isChanged,
    submit,
  } = props;

  const marks = [];
  ticks.map(tick => {
    marks.push({
      value: tick.sliderValue,
      label: tick.label,
    });
    return false;
  });

  const handleChange = (e, value) => {
    isChanged(value);
  };

  return (
    <PrettoSlider
      aria-label={title}
      defaultValue={defaultValue}
      marks={marks}
      max={max}
      min={min}
      onChange={(e, value) => handleChange(e, value)}
      onMouseLeave={submit}
      step={step}
      valueLabelDisplay="auto"
    />
  );
};
ResSlider.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  ticks: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string.isRequired,
  isChanged: PropTypes.func,
  submit: PropTypes.func,
};

ResSlider.defaultProps = {
  isChanged: () => {},
  submit: () => {},
};
export default ResSlider;
