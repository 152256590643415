import React from 'react';

import PropTypes from 'prop-types';

import { Container, InputField, TextFieldFeedbackBody, TextFieldFeedbackContainer } from './styled';

const TextField = props => {
  const { isDisabled, name, placeholder, onBlur, onChange, value, isPassword, isWarning, feedbackText } = props;

  return (
    <div>
      <Container>
        <InputField
          isWarning={isWarning}
          autoComplete="new-password"
          disabled={isDisabled}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder !== null ? placeholder : ''}
          type={isPassword ? 'password' : 'text'}
          value={value !== null ? value : ''}
        />
      </Container>
      {isWarning && (
        <TextFieldFeedbackContainer>
          <TextFieldFeedbackBody fontColor="#6f0f0f" bold>
            {feedbackText}
          </TextFieldFeedbackBody>
        </TextFieldFeedbackContainer>
      )}
    </div>
  );
};

TextField.defaultProps = {
  isDisabled: false,
  isPassword: false,
  isWarning: false,
  feedbackText: '',
  name: '',
  onBlur: () => {},
  onChange: () => undefined,
  placeholder: '',
  value: '',
};

TextField.propTypes = {
  isDisabled: PropTypes.bool,
  isPassword: PropTypes.bool,
  isWarning: PropTypes.bool,
  feedbackText: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default TextField;
