import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';
import {
  CalendarFragment,
  CheckboxFragment,
  DropDownFragment,
  MatchFragment,
  MultilineFragment,
  MultiResponseFragment,
  NumberFragment,
  RadioFragment,
  SingleLineFragment,
  SliderFragment,
} from '../QuestionConfiguration/Fragments/Collection';

export const UPDATE_QUESTION_CONTENT_SNIPPET = gql`
  mutation updateQuestionContentSnippet(
    $isMandatory: Boolean!
    $isVisible: Boolean!
    $majorNumber: String!
    $minorNumber: String!
    $question: String!
    $questionConfigurationId: Int!
    $snippetId: Int!
  ) {
    contentSnippet {
      updateContentSnippet(
        baseSnippet: { id: $snippetId, visible: $isVisible }
        contentSnippetQuestion: {
          mandatory: $isMandatory
          majorNumber: $majorNumber
          minorNumber: $minorNumber
          question: $question
          questionConfigurationId: $questionConfigurationId
        }
      ) {
        id
        sort
        containerId
        ... on ContentSnippetQuestionType {
          majorNumber
          question
          configuration {
            ...calendarConf
            ...checkboxConf
            ...dropDownListConf
            ...matchConf
            ...multiResponseConf
            ...multiTextConf
            ...numberConf
            ...radioConf
            ...singleTextConf
            ...sliderConf
            __typename
          }
        }
      }
    }
  }
  ${CalendarFragment}
  ${CheckboxFragment}
  ${DropDownFragment}
  ${MatchFragment}
  ${MultilineFragment}
  ${MultiResponseFragment}
  ${NumberFragment}
  ${RadioFragment}
  ${SingleLineFragment}
  ${SliderFragment}
`;

const updateQuestionContentSnippetRequest = ({
  containerId,
  isMandatory,
  isVisible,
  majorNumber,
  minorNumber,
  onError,
  onSuccessResult,
  question,
  questionConfigurationId,
  snippetId,
}) => (
  <Mutation mutation={UPDATE_QUESTION_CONTENT_SNIPPET}>
    {(updateContentSnippet, { loading, error, data }) => {
      const variables = {
        snippetId,
        question,
        isMandatory,
        isVisible,
        questionConfigurationId,
        majorNumber,
        minorNumber,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(data.contentSnippet.updateContentSnippet);
        onSuccessResult(
          snippetId,
          data.contentSnippet.updateContentSnippet.sort,
          // eslint-disable-next-line no-underscore-dangle
          data.contentSnippet.updateContentSnippet.configuration.__typename,
          containerId,
          {
            question,
            questionConfigurationId,
            majorNumber,
            minorNumber,
            ...data.contentSnippet.updateContentSnippet,
          },
        );
      }

      return <MutationRequest isLoading={loading} mutation={updateContentSnippet} variables={variables} />;
    }}
  </Mutation>
);

updateQuestionContentSnippetRequest.propTypes = {
  containerId: PropTypes.number.isRequired,
  isMandatory: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  questionConfigurationId: PropTypes.number.isRequired,
  snippetId: PropTypes.number.isRequired,
  majorNumber: PropTypes.string,
  minorNumber: PropTypes.string,
};

updateQuestionContentSnippetRequest.defaultProps = {
  majorNumber: '',
  minorNumber: '',
};

export default updateQuestionContentSnippetRequest;
