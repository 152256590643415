import React, { useEffect, useState } from 'react';

import { getS3SignedUrl, IDBAsset } from '@netfront/common-library';
import to from 'await-to-js';
import { enableAllPlugins } from 'immer';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { CMSSectionGroup } from '../CMSSectionGroup';

import { CMSContentPageProps } from './CMSContentPage.interfaces';

import { getRequiredSectionGroups, useContentPageContext } from '../../contexts';
import { ERROR_MESSAGES } from '../../core';
import { IAssetIdS3SignedUrlType } from '../../interfaces';

enableAllPlugins();

const CMSContentPage = ({ accessToken, contentPage, formContentSnippetId, userFlowStepTrackId }: CMSContentPageProps) => {
  const { dispatch, state: currentContentPage } = useContentPageContext();

  const [assetIdS3SignedUrls, setAssetIdS3SignedUrls] = useState<IAssetIdS3SignedUrlType[]>([]);

  const actualSectionGroups = getRequiredSectionGroups(currentContentPage, formContentSnippetId);
  const sortedActualSectionGroups = sortBy(actualSectionGroups);

  const sectionGroupsGroupedByConfigurationId = groupBy(actualSectionGroups, 'configurationId');
  delete sectionGroupsGroupedByConfigurationId.undefined;

  const processedConfigurationIds = new Map<number, unknown>();

  useEffect(() => {
    if (!contentPage) {
      return;
    }

    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  useEffect(() => {
    if (!sortedActualSectionGroups.length) {
      return;
    }

    Promise.all(
      sortedActualSectionGroups.flatMap(({ configurationId }) => {
        if (!configurationId) {
          return [];
        }

        const sectionGroupTabs = sectionGroupsGroupedByConfigurationId[configurationId];
        const sortedSectionGroupTabs = sortBy(sectionGroupTabs, 'tabSort');

        return sortedSectionGroupTabs.map(async ({ asset }): Promise<IAssetIdS3SignedUrlType> => {
          const { contentType, assetId, s3Key } = asset ?? ({} as IDBAsset);

          const [getS3SignedUrlError, s3SignedUrl] = await to(getS3SignedUrl(s3Key, contentType));

          if (getS3SignedUrlError) {
            throw getS3SignedUrlError;
          }

          if (!s3SignedUrl) {
            throw new Error(ERROR_MESSAGES.UNABLE_TO_GENERATE_SIGNED_URL);
          }

          return {
            assetId,
            s3SignedUrl,
          };
        });
      })
    ).then((response) => {
      setAssetIdS3SignedUrls((currentResponse) => [...currentResponse, ...response]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContentPage]);

  return (
    <React.Fragment>
      {sortedActualSectionGroups.map(({ configurationId, id }) => {
        if (!configurationId) {
          return (
            <CMSSectionGroup
              key={`section-group-${id}`}
              accessToken={accessToken}
              sectionGroupId={id}
              userFlowStepTrackId={userFlowStepTrackId}
            />
          );
        }

        if (processedConfigurationIds.has(configurationId)) {
          return null;
        }

        processedConfigurationIds.set(configurationId, null);

        const sectionGroupTabs = sectionGroupsGroupedByConfigurationId[configurationId];
        const sortedSectionGroupTabs = sortBy(sectionGroupTabs, 'tabSort');

        return (
          <div key={`tabs-container-${id}`} className="ek-group-tabs">
            <Tabs>
              <TabList>
                {sortedSectionGroupTabs.map(({ asset, id: sectionGroupTabId, text, visible: isSectionGroupTabVisible = true }) => {
                  const { alt, assetId } = asset ?? ({} as IDBAsset);
                  const { s3SignedUrl } = assetIdS3SignedUrls.find((item) => item.assetId === assetId) ?? {};

                  return (
                    <React.Fragment key={`tab-${sectionGroupTabId}`}>
                      {isSectionGroupTabVisible && (
                        <Tab>
                          {s3SignedUrl && (
                            <div className="c-tab-header__image_container">
                              <img alt={alt} className="c-tab-header__image" src={s3SignedUrl} />
                            </div>
                          )}
                          {text}
                        </Tab>
                      )}
                    </React.Fragment>
                  );
                })}
              </TabList>
              {sortedSectionGroupTabs.map(({ id: sectionGroupTabId }) => {
                return (
                  <TabPanel key={`tab-panel-${sectionGroupTabId}`}>
                    <CMSSectionGroup
                      accessToken={accessToken}
                      sectionGroupId={sectionGroupTabId}
                      userFlowStepTrackId={userFlowStepTrackId}
                    />
                  </TabPanel>
                );
              })}
            </Tabs>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export { CMSContentPage };
