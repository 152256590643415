import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PopupMessage from '../PopupMessage/PopupMessage';
import {
  SearchSuggetionsBox,
  SearchSuggetionsRow,
} from '../RightNavBar/styled';
import Button from '../UI/Button/SquareButton';
import Modal from '../UI/Modal/NormalModal';

import client from '../../middleware/client';
import { ADD_CONTENT_GROUP_TO_GROUPING } from '../../middleware/ContentPage/addContentGroupToGrouping';
import { REMOVE_CONTENT_GROUP_FROM_GROUPING } from '../../middleware/ContentPage/removeContentGroupFromGrouping';

const Text = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function SearchContentGroupings({
  availibleGroupings,
  contentGroup,
  onSelect,
  title,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [errMsg, setErrMsg] = useState('');

  const [addToGrouping] = useMutation(ADD_CONTENT_GROUP_TO_GROUPING, {
    client,
    onCompleted() {
      setIsOpen(false);
      onSelect(selectedItem);
    },
    onError(error) {
      setErrMsg(error.toString());
      setTimeout(() => {
        setErrMsg('');
      }, 3000);
    },
  });
  const [removeFromGrouping] = useMutation(REMOVE_CONTENT_GROUP_FROM_GROUPING, {
    client,
    onCompleted() {
      setIsOpen(false);
      onSelect(selectedItem);
    },
    onError(error) {
      setErrMsg(error.toString());
      setTimeout(() => {
        setErrMsg('');
      }, 3000);
    },
  });

  const handleSelect = select => {
    setIsOpen(true);
    setSelectedItem(select);
  };

  const handleMoveGroup = () => {
    if (selectedItem.id) {
      addToGrouping({
        variables: {
          groupId: selectedItem.id,
          contentGroupId: contentGroup.id,
        },
      });
    } else {
      removeFromGrouping({
        variables: {
          contentGroupId: contentGroup.id,
        },
      });
    }
  };
  const currentGroupingId =
    contentGroup.groupingContentGroup && contentGroup.groupingContentGroup.id;

  return (
    <SearchSuggetionsBox>
      {availibleGroupings
        .filter(g => g.id !== currentGroupingId)
        .filter(g => g.name.toLowerCase().includes(title.toLowerCase()))
        .map(g => (
          <SearchSuggetionsRow
            key={g.id}
            onClick={() => handleSelect(g)}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {g.name}
          </SearchSuggetionsRow>
        ))}
      {currentGroupingId && (
        <SearchSuggetionsRow
          onClick={() => handleSelect({})}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          Remove from group
        </SearchSuggetionsRow>
      )}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Text>
            Are you sure you want to add this content group to{' '}
            {selectedItem.title}?
          </Text>
          <ButtonGroup>
            <Button bgColor="#4bc2ca" color="#fff" isClicked={handleMoveGroup}>
              Confirm
            </Button>
            <Button isClicked={() => setIsOpen(false)}>Cancel</Button>
          </ButtonGroup>
          {errMsg !== '' && <PopupMessage isError message={errMsg} />}
        </Modal>
      )}
    </SearchSuggetionsBox>
  );
}

SearchContentGroupings.propTypes = {
  availibleGroupings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  contentGroup: PropTypes.shape({
    groupingContentGroup: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

SearchContentGroupings.defaultProps = {};

export default SearchContentGroupings;
