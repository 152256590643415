import React, { useState } from 'react';

import PropTypes from 'prop-types';

import AssetUploader from '../UI/AssetUploader/AppAssetUploader';
import DropDownButton from '../UI/Button/ShowHideButton';
import Input from '../UI/Input/InputWhite';

import {
  Wrapper,
  Container,
  PlusButton,
  InputWrapper,
  AssetContainer,
  AssetBox,
  AssetsLib,
  AssetItem,
} from './styled';

import GetAppAsset from '../../middleware/AppAssets/getAllAssetsInAppVersion';
import CreateAppComponent from '../../middleware/MyAppDashboard/createMyAppComponentRequest';

const AppAdvanceFooter = props => {
  const { onAdded, version } = props;

  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [createRequest, setCreateRequest] = useState(false);
  const [name, setName] = useState('');
  const [assets, setAssets] = useState([]);

  const handleNameInput = value => {
    setName(value);
  };

  const handleAddNewComp = () => {
    setCreateRequest(true);
  };

  const getAssetData = data => {
    const index1 = data.s3Key.lastIndexOf('/') + 1;
    const fileName = data.s3Key.substr(index1);
    setAssets([
      ...assets,
      {
        ...data,
        fileName,
      },
    ]);
  };

  const handleGetAppAssetError = () => {
    setAssetsLoaded(true);
  };

  const handleGetAppAssetSuccess = data => {
    if (data.length > 0) {
      setAssets(data);
    }
    setAssetsLoaded(true);
  };

  const handleCreateComponentSuccess = () => {
    setName('');
    setCreateRequest(false);
    onAdded();
  };

  const handleComponentError = () => {
    setName('');
    setCreateRequest(false);
  };

  const addComp = (
    <Container>
      <InputWrapper>
        <Input
          isChanged={handleNameInput}
          placeholder="Enter component name"
          style={{ padding: '7px', margin: 0 }}
          text={name}
        />
      </InputWrapper>
      <PlusButton onClick={handleAddNewComp}>
        <span
          alt="plus"
          className="icon-plus c-icon-add d-flex align-items-center justify-content-center"
        />
      </PlusButton>
      <DropDownButton
        direction="top"
        isClicked={() => {
          setShowAdvance(!showAdvance);
        }}
      />
    </Container>
  );

  const appAsset = (
    <AssetContainer>
      <AssetBox>
        <AssetUploader appVersionId={version.id} getData={getAssetData} />
      </AssetBox>
      {assets.length > 0 ? (
        <AssetsLib>
          {assets.map(asset => (
            <AssetItem asset={asset} key={asset.assetId} />
          ))}
        </AssetsLib>
      ) : (
        <AssetsLib>
          <p>There is no assets in current version.</p>
        </AssetsLib>
      )}
    </AssetContainer>
  );

  return (
    <>
      <Wrapper>
        {addComp}
        {showAdvance && appAsset}
      </Wrapper>
      {createRequest && (
        <CreateAppComponent
          componentTitle={name}
          identifier={version.app.identifier}
          onError={handleComponentError}
          onSuccessResult={handleCreateComponentSuccess}
          version={version.version}
        />
      )}
      {!assetsLoaded && (
        <GetAppAsset
          appVersionId={version.id}
          onError={handleGetAppAssetError}
          onSuccessResult={handleGetAppAssetSuccess}
        />
      )}
    </>
  );
};

AppAdvanceFooter.propTypes = {
  onAdded: PropTypes.func.isRequired,
  version: PropTypes.shape({
    app: PropTypes.shape(),
    components: PropTypes.array,
    id: PropTypes.number,
    version: PropTypes.string,
  }).isRequired,
};

// PageLevelFooter.defaultProps = {
//   parentId: null,
// };

export default AppAdvanceFooter;
