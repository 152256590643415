import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { useLocation, useParams } from 'react-router-dom';

import { SearchContainer } from '../OrganisationUsersPage/styled';
import { StyledTableFilterContainer } from '../styles';

import GroupOrdersDetailsTable from './GroupOrdersDetailsTable';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import SearchInput from '../../components/SearchInput/SearchInput';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { GET_COMPLETED_ORDERS_FOR_GROUP } from '../../middleware/_Vervet/Queries/GetCompletedOrdersForGroup';
import vervetClient from '../../middleware/vervetClient';
import { getBreadCrumbPath } from '../../utils/utils';

function GroupOrdersDetailsPage() {
  const { pathname } = useLocation();
  const params = useParams();

  const { groupId } = params;

  const [allOrdersDetails, setAllOrdersDetails] = useState(undefined);
  const [filteredOrdersDetails, setFilteredOrdersDetails] = useState(undefined);
  const [isUpdated, setIsUpdated] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [message, setMessage] = useState('');

  const [executeGetCompletedOrdersForGroup, { loading }] = useLazyQuery(GET_COMPLETED_ORDERS_FOR_GROUP, {
    client: vervetClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const {
        orders: { getCompletedOrdersForGroup: ordersDetails },
      } = data;

      // TODO: Find a good way to get the group name when there are no orders
      const actualGroupName = ordersDetails.length > 0 ? ordersDetails[0].group.name : 'Group';
      setGroupName(`${actualGroupName}'s`);

      setAllOrdersDetails(ordersDetails);
      setFilteredOrdersDetails(ordersDetails);
    },
    onError: (error) => {
      console.error(error.message);
      setMessage('Something went wrong! Please try again later.');
    },
  });

  function handleMsgDisappear() {
    setTimeout(() => {
      setMessage('');
    }, 500);
  }

  function handleSearchFilter(event) {
    const {
      target: { value },
    } = event;

    setFilteredOrdersDetails(
      allOrdersDetails.filter((ordersDetail) => {
        const {
          items,
          transaction: { invoiceId },
        } = ordersDetail;

        const hasFoundValueInInvoiceId = invoiceId.toString().toLowerCase().indexOf(value.toLowerCase()) > -1;

        const hasFoundValueInUserFlowTitle = items.filter((item) => {
          const {
            userFlow: { title: userFlowTitle },
          } = item;

          return userFlowTitle.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });

        return hasFoundValueInInvoiceId || Boolean(hasFoundValueInUserFlowTitle.length);
      }),
    );
  }

  function handleUpdate() {
    setIsUpdated(true);
  }

  useEffect(() => {
    executeGetCompletedOrdersForGroup({
      variables: {
        groupId,
      },
    });
  }, [isUpdated]);

  const breadCrumbPath = [
    ...getBreadCrumbPath(pathname, 3, 'Subscriptions'),
    ...getBreadCrumbPath(pathname, 2, 'Group orders'),
    ...getBreadCrumbPath(pathname, 0, 'Order details'),
  ];

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info={`${groupName} past orders`}
      isLoaderVisible={loading}
      pageDescription="past orders of the group"
      pageTitle="Orders details page"
      params={params}
    >
      <ProjectNavBar title="Orders details" />
      {allOrdersDetails && (
        <>
          <StyledTableFilterContainer>
            <SearchContainer minWidth="40%">
              <SearchInput placeholder="Filter by module or invoice number" onChange={(event) => handleSearchFilter(event)} />
            </SearchContainer>
          </StyledTableFilterContainer>

          <GroupOrdersDetailsTable ordersDetails={filteredOrdersDetails} onUpdateHandler={handleUpdate} />
        </>
      )}
      <PopUpMsg message={message} msgDisappear={handleMsgDisappear} />
    </PageWrapper>
  );
}

export default GroupOrdersDetailsPage;
