import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import Preloader from '../../components/Preloader/Preloader';

export const GET_REPORT_TYPES_AVAILABLE = gql`
  {
    __type(name: "ReportingPivotEnum") {
      name
      enumValues {
        name
        description
        deprecationReason
      }
    }
  }
`;

const getReportTypesAvailable = ({ onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_REPORT_TYPES_AVAILABLE}>
    {({ loading, error, data }) => {
      if (loading) return <Preloader />;
      if (error) {
        onError(error.networkError.result.messages);
        return <></>;
      }
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        onSuccessResult(data.__type.enumValues);
      }

      return <></>;
    }}
  </Query>
);

getReportTypesAvailable.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getReportTypesAvailable;
