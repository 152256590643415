import React from 'react';

import { Table, Tr, Td } from 'react-super-responsive-table';
import styled from 'styled-components';

import { FlexContainer } from '../MarketplaceAppDetailsPage/styled';

import SwitchBtn from '../../components/UI/Button/SwitchButton';
import Tooltip from '../../components/UI/Tooltip/Tooltips';

export const Filters = styled(FlexContainer)`
  align-items: center;
`;

export const EditButton = styled.button`
  background-color: #88929f;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  line-height: 1;
  margin-left: auto;
  padding-top: 5px;
  opacity: 0;
  width: 22px;
  &:focus {
    outline: none;
  }
`;

export const CopyButton = styled.button`
  background-color: #88929f;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  line-height: 1;
  margin-left: auto;
  padding-top: 5px;
  opacity: 0;
  width: 22px;
  &:focus {
    outline: none;
  }
`;

export const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  opacity: 0;

  button + button {
    margin-left: 0.5rem;
  }
`;

export const ResendBtn = styled.button`
  border: 1px solid #52e5ba;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  &:hover {
    background-color: #52e5ba;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
`;

export const PushRight = styled.div`
  margin-left: auto;
`;

export const ButtonReset = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
`;

export const FirstTd = styled(Td)`
  height: 50px;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2em !important;
  border-left: 5px solid transparent;
`;

export const FirstnameTd = styled(Td)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2em !important;
  border-left: 5px solid transparent;
`;

export const TableContainer = styled(Table)`
  th {
    padding: 0.6em;
  }

  tbody {
    tr {
      border: 1px solid #e1e1e1;
      td {
        padding: 0.6em;
      }

      &:hover {
        background-color: #f6f8f9;

        ${EditButton}, ${ActionsContainer} {
          opacity: 1;
        }

        ${FirstnameTd} {
          border-left-color: #4bc1c9;
        }
        ${FirstTd} {
          border-left-color: #4bc1c9;
        }
      }
    }
  }
`;

export const SearchContainer = styled.div`
  margin-right: 10px;
  max-width: 40%;
  min-width: ${props => props.minWidth ?? '20%'};
`;

export const ContainerScroll = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  table {
    width: 99%;
  }
`;

export const RowLoading = styled(Tr)`
  border: none !important;

  td {
    text-align: center;
  }
`;

export const EmptyPlaceholder = styled.div`
  background-color: #f4f5f7;
  border-radius: 5px;
  color: #505f79;
  padding: 12px;
  text-align: center;
`;

export const permissionTypes = {
  MANAGE_USERS: 'Manage users',
  EDIT: 'Edit',
  READ: 'View',
};

export const tabs = [
  { id: 1, label: 'Team' },
  { id: 2, label: 'Invited' },
];

export const ActiveStatus = styled.span`
  height: 1em;
  width: 1em;
  border-radius: 50%;
  background-color: ${props => (props.isActive ? '#52e5ba' : '	#FF6347')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.2em;
  margin-right: 0.5em;
`;

export const PendingStatus = styled(ActiveStatus)`
  background-color: #ffc96c;
  top: 0;
`;

export const ShowInactiveBtn = (
  <div>
    Show inavtive <Tooltip /> <SwitchBtn id={1} />
  </div>
);
