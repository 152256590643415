import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const CREATE_MYAPP_COMPONENT = gql`
  mutation createMyAppComponentRequest(
    $version: String!
    $componentTitle: String!
    $identifier: String!
  ) {
    app {
      appVersionMutation {
        appVersionComponentMutation {
          addComponentInVersion(
            inputType: {
              version: $version
              componentTitle: $componentTitle
              identifier: $identifier
            }
          ) {
            id
            title
          }
        }
      }
    }
  }
`;

const createMyAppComponentRequest = ({
  version,
  componentTitle,
  identifier,
  onError,
  onSuccessResult,
}) => (
  <Mutation mutation={CREATE_MYAPP_COMPONENT}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        version,
        componentTitle,
        identifier,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult(
          data.app.appVersionMutation.appVersionComponentMutation
            .addComponentInVersion
        );
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

createMyAppComponentRequest.propTypes = {
  componentTitle: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
};

createMyAppComponentRequest.defaultProps = {};

export default createMyAppComponentRequest;
