import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import MutationRequest from '../MutationRequest';

export const SAVE_REPORT = gql`
  mutation saveReport(
    $query: String!
    $pivot: ReportingPivotEnum!
    $select: String
    $order: String
    $queriedFields: [ReportQueryFieldInputType]!
    $title: String!
    $projectId: String!
    $reportArgs: [ArgumentInputType]!
  ) {
    report {
      createReport(
        query: $query
        includes: []
        pivot: $pivot
        select: $select
        order: $order
        queriedFields: $queriedFields
        title: $title
        projectId: $projectId
        reportArgs: $reportArgs
      ) {
        id
      }
    }
  }
`;

const saveReportRequest = ({
  query,
  includes,
  pivot,
  select,
  order,
  queriedFields,
  title,
  projectId,
  reportArgs,
  onCreateError,
  onSuccessResult,
}) => (
  <Mutation mutation={SAVE_REPORT}>
    {(saveReport, { loading, error, data }) => {
      const variables = {
        query,
        includes,
        pivot,
        select,
        order,
        queriedFields,
        title,
        projectId,
        reportArgs,
        onCreateError,
        onSuccessResult,
      };

      if (error) {
        if (error.networkError.result) {
          onCreateError(error.networkError.result.messages);
        } else {
          onCreateError(error.messages);
        }
      }
      if (data) {
        onSuccessResult();
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={saveReport}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

saveReportRequest.propTypes = {
  includes: PropTypes.string.isRequired,
  onCreateError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  pivot: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  queriedFields: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  reportArgs: PropTypes.string.isRequired,
  select: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default saveReportRequest;
