import { QuestionConditionCheckboxEdit } from './QuestionConditions/QuestionConditionEdit/QuestionConditionCheckboxEdit';
import { QuestionConditionRadioEdit } from './QuestionConditions/QuestionConditionEdit/QuestionConditionRadioEdit';
import { QuestionConditionSliderEdit } from './QuestionConditions/QuestionConditionEdit/QuestionConditionSliderEdit';
import { QuestionConditionTextEdit } from './QuestionConditions/QuestionConditionEdit/QuestionConditionTextEdit';
import {
  CREATE_ACTION_SHOW_SECTION,
  CREATE_ACTION_HIDE_SECTION,
  CREATE_ACTION_SHOW_CONTAINER,
  CREATE_ACTION_HIDE_CONTAINER,
  CREATE_ACTION_SHOW_SNIPPET,
  CREATE_ACTION_HIDE_SNIPPET,
} from './QuestionConditions/QuestionConditionNew/QuestionConditionNew.graphql';

const DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS = process.env.REACT_APP_API_QUERY_DEBOUNCE_WAIT_TIME_IN_MILLISECONDS;

const CREATE_ACTION_QUERY_MAP = {
  section: {
    QuestionActionShowSectionType: CREATE_ACTION_SHOW_SECTION,
    QuestionActionHideSectionType: CREATE_ACTION_HIDE_SECTION,
  },
  container: {
    QuestionActionShowContainerType: CREATE_ACTION_SHOW_CONTAINER,
    QuestionActionHideContainerType: CREATE_ACTION_HIDE_CONTAINER,
  },
  targetSnippet: {
    QuestionActionShowType: CREATE_ACTION_SHOW_SNIPPET,
    QuestionActionHideType: CREATE_ACTION_HIDE_SNIPPET,
  },
};

const QUESTION_CONDITION_EDIT_TYPE_COMPONENT_MAP = Object.freeze({
  QuestionConditionCheckboxSelectedType: QuestionConditionCheckboxEdit,
  QuestionConditionRadioResponseSelectedType: QuestionConditionRadioEdit,
  QuestionConditionSliderValueSelectedType: QuestionConditionSliderEdit,
  QuestionConditionTextType: QuestionConditionTextEdit,
});

const WRAPPER_TYPE_ACTION_KEY_MAP = Object.freeze({
  container: 'containerId',
  section: 'sectionId',
  targetSnippet: 'targetSnippetId',
});

export {
  CREATE_ACTION_QUERY_MAP,
  DEBOUNCE_QUERY_WAIT_TIME_IN_MILLISECONDS,
  QUESTION_CONDITION_EDIT_TYPE_COMPONENT_MAP,
  WRAPPER_TYPE_ACTION_KEY_MAP,
};
