import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import SliderCreation from '../../components/Slider/SliderCreation';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import SnippetContainer from '../../containers/SnippetContainer/SnippetContainer';
import AppContext from '../../context/AppContext';
import GetSliderDetail from '../../middleware/Slider/getSlider';

const SliderBuilderPage = (props) => {
  const { match, history } = props;
  const { org, project } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [detail, setDetail] = useState({});
  const handleLoadError = () => {
    setLoaded(true);
  };
  const handleLoadSuccess = (data) => {
    setDetail(data);
    setLoaded(true);
  };

  const breadPath = [
    {
      id: 1,
      path: `/dashboard/${org.key}/project/${project.id}/library`,
      title: 'Library',
    },
    {
      id: 2,
      path: `/dashboard/${org.key}/project/${project.id}/library/slider`,
      title: 'Slider library',
    },
    {
      id: 3,
      path: ``,
      title: 'Slider builder',
    },
  ];

  return (
    <div>
      <PageWrapper
        breadPath={breadPath}
        info="Build your own slider"
        pageDescription="Build your own sliders"
        pageTitle="Slider builder page"
        params={match.params}
      >
        <ProjectNavBar title="Slider Builder" />
        <SnippetContainer>
          <SliderCreation history={history} match={match} sliderDetail={detail} />
        </SnippetContainer>
      </PageWrapper>
      {!loaded && (
        <GetSliderDetail sliderId={Number(match.params.sliderId)} onError={handleLoadError} onSuccessResult={handleLoadSuccess} />
      )}
    </div>
  );
};

SliderBuilderPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: { sliderId: PropTypes.string },
  }).isRequired,
};

export default SliderBuilderPage;
