// TODO: Expose more DatePicker/RangePicker props so it can be customized further

import React, { useState } from 'react';

import generatePicker from 'antd/es/date-picker/generatePicker';
import PropTypes from 'prop-types';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { getAntdLocale } from '../DatePickers.helpers';

import { getPresetRanges } from './EKDateRangePicker.helpers';

import './EKDateRangePicker.css';

import 'antd/dist/antd.css';

const EKDateRangePicker = ({ defaultEndDate, defaultStartDate, isDisabled = false, onChangeHandler }) => {
  const { RangePicker } = generatePicker(dateFnsGenerateConfig);

  const [defaultValue, setDefaultValue] = useState([defaultStartDate, defaultEndDate]);

  const handleDateRangePickerChange = (dates) => {
    const [startDate, endDate] = dates;

    onChangeHandler({
      endDate,
      startDate,
    });

    setDefaultValue(dates);
  };

  const {
    lang: { dateFormat: format },
  } = getAntdLocale();

  return (
    <RangePicker
      defaultValue={defaultValue}
      disabled={isDisabled}
      format={format}
      ranges={getPresetRanges()}
      size="large"
      onChange={(value) => handleDateRangePickerChange(value)}
    />
  );
};

EKDateRangePicker.propTypes = {
  defaultEndDate: PropTypes.instanceOf(Date).isRequired,
  defaultStartDate: PropTypes.instanceOf(Date).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export { EKDateRangePicker };
