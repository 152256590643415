import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import { useCookie } from '@netfront/common-library';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PushRight } from '../OrganisationUsersPage/styled';

import { Text, ButtonGroup } from './styled';

import Button from '../../components/Buttons/Button';
import ValidationProcessor from '../../components/ErrorMessage/ValidationProcessor';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import { AdvanceContainer, AdvanceItem, FlexHorizontal } from '../../components/Notes/styled';
import Preloader from '../../components/Preloader/Preloader';
import AdvancedTitleWithTooltip from '../../components/RightNavBar/AdvancedTitleWithTooltip';
import SquareButton from '../../components/UI/Button/SquareButton';
import Input from '../../components/UI/Input/InputWhite';
import Modal from '../../components/UI/Modal/NormalModal';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import Textarea from '../../components/UI/Textarea/Textarea';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import howlerClient from '../../middleware/_Howler/howlerClient';
import { SEND_PUSH_NOTIFICATIONS } from '../../middleware/_Howler/Mutations/sendPushNotifications';
import { getBreadCrumbPath } from '../../utils/utils';

const EMPTY_NOTIFICATION = {
  title: '',
  content: '',
  topic: 'none',
};

function PushNotificationPage() {
  const { project } = useContext(AppContext);

  const { getAccessTokenCookie } = useCookie();
  const { pathname } = useLocation();
  const params = useParams();
  const history = useHistory();

  const { projectId } = params;
  const [notification, setNotification] = useState(EMPTY_NOTIFICATION);
  const [message, setMessage] = useState('');
  const [popupMessageType, setPopupMessageType] = useState('success');
  const [confirm, setConfirm] = useState(false);

  const context = {
    headers: {
      authorization: getAccessTokenCookie(),
    },
  };

  const [executeSendPush, { loading }] = useMutation(SEND_PUSH_NOTIFICATIONS, {
    context,
    client: howlerClient,
    onCompleted: () => {
      setPopupMessageType('success');
      setNotification(EMPTY_NOTIFICATION);
      setMessage('Push notification sent successfully!');
    },
    onError: () => {
      setPopupMessageType('error');
      setMessage('Something went wrong, please try again later.');
    },
  });

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Notifications'), ...getBreadCrumbPath(pathname, 0, 'Push notifications')];

  function getValidation() {
    const { title, content } = notification;
    return {
      titleEmpty: {
        errorMessage: 'Title cannot be empty',
        validation: Boolean(title),
      },
      bodyEmpty: {
        errorMessage: 'Message cannot be empty',
        validation: Boolean(content),
      },
    };
  }

  const handleInput = (value, event) => {
    setNotification((previousNotification) => ({ ...previousNotification, [event.target.name]: value }));
  };

  function handleMsgDisappear() {
    setTimeout(() => {
      setMessage('');
    }, 2000);
  }

  const confirmSendingPush = () => {
    const validationErrors = ValidationProcessor(getValidation());

    if (!validationErrors.modelValid) {
      setPopupMessageType('error');
      setMessage(validationErrors.validations);
    } else setConfirm(true);
  };

  const sendPushNotification = () => {
    setConfirm(false);
    const { title, content, topic } = notification;
    executeSendPush({
      variables: {
        title,
        content,
        topic,
        projectId,
      },
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const { title, content } = notification;
  const hasErrorMessage = Boolean(message !== '');

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info="Send push notifications to all mobile app users"
      pageDescription="Send push notifications to all mobile app users"
      pageTitle="Send push notifications"
      params={params}
    >
      <ProjectNavBar title={project.name} />
      {loading && <Preloader />}
      <AdvanceContainer>
        <AdvanceItem>
          <AdvancedTitleWithTooltip title="Title" tooltipText="Title of the push notification" />
          <Input focusedBorderColor="#44bed3" id="title" isChanged={handleInput} name="title" text={title} />
        </AdvanceItem>
        <AdvanceItem>
          <AdvancedTitleWithTooltip title="Content" tooltipText="Message content of the push notification" />
          <Textarea id="content" isChanged={handleInput} name="content" text={content} />
        </AdvanceItem>

        {hasErrorMessage && <PopUpMsg message={message} msgDisappear={handleMsgDisappear} type={popupMessageType} />}
      </AdvanceContainer>
      <PushRight>
        <FlexHorizontal>
          <Button styles={{ marginRight: '1rem', background: 'grey' }} onClick={goBack}>
            Cancel
          </Button>
          <Button onClick={confirmSendingPush}>Send</Button>
        </FlexHorizontal>
      </PushRight>
      {confirm && (
        <Modal isOpen={confirm} onClose={() => setConfirm(false)}>
          <Text>Are you sure you want to send this notification?</Text>
          <ButtonGroup>
            <SquareButton bgColor="#4bc2ca" color="#fff" isClicked={sendPushNotification}>
              Confirm
            </SquareButton>
            <SquareButton isClicked={() => setConfirm(false)}>Cancel</SquareButton>
          </ButtonGroup>
        </Modal>
      )}
    </PageWrapper>
  );
}

PushNotificationPage.propTypes = {};

export default PushNotificationPage;
