import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

import LoginUser from '../../containers/LoginUser/LoginUser';
import GetEndpoint from '../../middleware/CustomBuild/getByBackofficeEndpoint';

const Wrapper = styled.div`
  position: fixed;
  top: calc(50% - 180px);
  width: 100%;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  min-width: 300px;
`;

const cookies = new Cookies();

const BackofficeLoginPage = () => {
  const [endpoint, setEndpoint] = useState(false);

  const handleError = () => {
    setEndpoint(true);
  };

  const handleEndpointSuccess = data => {
    cookies.remove('customBuild', { path: '/' });
    if (data.getByBackofficeEndpoint) {
      cookies.set('customBuild', {
        isCustomBuild: true,
        projectId: data.getByBackofficeEndpoint.id,
        orgId: data.getByBackofficeEndpoint.organisationId,
      });
    }
    setEndpoint(true);
  };
  return (
    <Wrapper>
      <Helmet>
        <title>
          Building accessible and engaging educational content better
        </title>
        <meta content="Login and manage your projects" name="description" />
      </Helmet>
      <Container>
        <LoginUser isLogin />
      </Container>
      {!endpoint && (
        <GetEndpoint
          endpoint={window.location.hostname}
          onError={handleError}
          onSuccessResult={handleEndpointSuccess}
        />
      )}
    </Wrapper>
  );
};

export default BackofficeLoginPage;
