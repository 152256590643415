import { useEkardoMutation } from '../useEkardoMutation';

import { SET_USER_ANSWER_MATCH_MUTATION } from './useSetUserAnswerMatch.graphql';
import {
  IHandleSetUserAnswerMatchParams,
  ISetUserAnswerMatchMutationGraphQLResponse,
  ISetUserAnswerMatchMutationVariables,
  IUseSetUserAnswerMatch,
  IUseSetUserAnswerMatchOptions,
} from './useSetUserAnswerMatch.interfaces';

const useSetUserAnswerMatch = (options?: IUseSetUserAnswerMatchOptions): IUseSetUserAnswerMatch => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseSetUserAnswerMatchOptions);

  const [executeSetUserAnswerMatch, { loading: isLoading }] = useEkardoMutation<
    ISetUserAnswerMatchMutationGraphQLResponse,
    ISetUserAnswerMatchMutationVariables
  >({
    mutation: mutation ?? SET_USER_ANSWER_MATCH_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAnswer: { setUserAnswer },
        } = data;

        onCompleted({
          setUserAnswer,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleSetUserAnswerMatch = async ({
    answers,
    contentPageId,
    contentSnippetFormId,
    contentSnippetQuestionId,
    status,
    userFlowStepTrackId,
  }: IHandleSetUserAnswerMatchParams) => {
    await executeSetUserAnswerMatch({
      variables: {
        answers,
        contentPageId,
        contentSnippetFormId,
        contentSnippetQuestionId,
        status,
        userFlowStepTrackId,
      },
    });
  };

  return {
    handleSetUserAnswerMatch,
    isLoading,
  };
};

export { useSetUserAnswerMatch };
