import React from 'react';

import gql from 'graphql-tag';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import QueryRequest from '../QueryRequest';

export const GET_ALL_PROJECT_IMAGES = gql`
  query getAllImagesRequest($projectId: String!, $type: [EAssetType]!) {
    asset {
      searchAssets(type: $type, projectId: $projectId) {
        assetId
        contentType
        fileName
        projectId
        s3Key
        uploaded
        alt
        tags
        xAxisFocus
        type
        fileName
        transcript
        publicS3Key
        isPublic
        publicUrl
        presignedUrl
      }
    }
  }
`;

const getAllAssetsRequest = ({ projectId, type, onError, onSuccessResult }) => (
  <Query fetchPolicy="network-only" query={GET_ALL_PROJECT_IMAGES} variables={{ projectId, type }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={!isEmpty(data)}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={() => onSuccessResult(data)}
      />
    )}
  </Query>
);

getAllAssetsRequest.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default getAllAssetsRequest;
