import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

function ErrorModal(props) {
  const { error, onError } = props;

  useEffect(() => {
    if (error) {
      if (error.networkError) {
        onError(`Error: ${error.networkError.result.errors[0].message}`);
      } else {
        onError(error.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

ErrorModal.propTypes = {
  error: PropTypes.instanceOf(Object).isRequired,
  onError: PropTypes.func.isRequired,
};

export default ErrorModal;
