import React from 'react';

import { SpacingProps } from './Spacing.types';

import './Spacing.css';

/**
 * For creating vertical space between components.
 */
const Spacing = ({ children, size }: SpacingProps) => {
  return <div className={size ? `h-spacing-${size}` : 'h-spacing'}>{children}</div>;
};

export { Spacing };
