import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { ContentPage } from '../ContentSnippet/Fragments/Collection';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_CONTENT_PAGE = gql`
  mutation UpdateContentPage(
    $assetId: String
    $backButtonToolTip: String
    $contentPageId: Int!
    $contentPageType: Int
    $enableBackButton: Boolean
    $enableNextButton: Boolean
    $mode: [EMode]
    $nextButtonToolTip: String
    $parentId: Int
    $seoDescription: String
    $seoTitle: String
    $sort: Int!
    $tags: [Int]
    $title: String!
    $url: String!
    $releaseDate: Date
  ) {
    contentPage {
      updateContentPage(
        contentPage: {
          assetId: $assetId
          contentPageConfigurationNavigation: {
            backButtonToolTip: $backButtonToolTip
            contentPageId: $contentPageId
            enableBackButton: $enableBackButton
            enableNextButton: $enableNextButton
            nextButtonToolTip: $nextButtonToolTip
          }
          contentPageId: $contentPageId
          contentPageType: $contentPageType
          mode: $mode
          parentId: $parentId
          releaseDate: $releaseDate
          seoDescription: $seoDescription
          seoTitle: $seoTitle
          sort: $sort
          tags: $tags
          title: $title
          url: $url
        }
      ) {
        ...ContentPage
      }
    }
  }
  ${ContentPage}
`;

const updateContentPageRequest = ({
  assetId,
  backButtonToolTip,
  contentPageId,
  contentPageType,
  enableBackButton,
  enableNextButton,
  mode,
  onError,
  onSuccessResult,
  nextButtonToolTip,
  parentId,
  releaseDate,
  seoDescription,
  seoTitle,
  sort,
  tags,
  title,
  url,
}) => (
  <Mutation mutation={UPDATE_CONTENT_PAGE}>
    {(updateContentPage, { error, data, loading }) => {
      const variables = {
        assetId,
        backButtonToolTip,
        contentPageId,
        contentPageType,
        enableBackButton,
        enableNextButton,
        mode,
        nextButtonToolTip,
        parentId,
        releaseDate,
        seoDescription,
        seoTitle,
        sort,
        tags,
        title,
        url,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }

      if (data) {
        onSuccessResult(data.contentPage.updateContentPage);
      }

      return <MutationRequest isLoading={loading} mutation={updateContentPage} variables={variables} />;
    }}
  </Mutation>
);

updateContentPageRequest.propTypes = {
  contentPageId: PropTypes.number.isRequired,
  mode: PropTypes.arrayOf(PropTypes.string).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  sort: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  assetId: PropTypes.string,
  backButtonToolTip: PropTypes.string,
  contentPageType: PropTypes.number,
  // eslint-disable-next-line react/boolean-prop-naming
  enableBackButton: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  enableNextButton: PropTypes.bool,
  releaseDate: PropTypes.instanceOf(Date),
  nextButtonToolTip: PropTypes.string,
  parentId: PropTypes.number,
  seoDescription: PropTypes.string,
  seoTitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.number),
};

updateContentPageRequest.defaultProps = {
  assetId: null,
  backButtonToolTip: null,
  contentPageType: null,
  enableBackButton: false,
  enableNextButton: false,
  nextButtonToolTip: null,
  parentId: null,
  releaseDate: new Date(),
  seoDescription: '',
  seoTitle: '',
  tags: [],
};

export default updateContentPageRequest;
