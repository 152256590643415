/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import InputHolder from '../InputHolder/InputHolder';

function Registration(props) {
  const {
    firstname,
    lastname,
    email,
    password,
    repeatPassword,
    handleInputs,
    handleCheck,
    handleSubmit,
    handleClearErrorMessages,
    token,
  } = props;

  return (
    <form className="login-page__form">
      <InputHolder
        label="First name"
        name="firstname"
        onChange={handleInputs}
        onClick={handleClearErrorMessages}
        required
        value={firstname}
      />

      <InputHolder
        label="Last name"
        name="lastname"
        onChange={handleInputs}
        onClick={handleClearErrorMessages}
        required
        value={lastname}
      />

      <InputHolder
        label="Email"
        name="email"
        onChange={handleInputs}
        onClick={handleClearErrorMessages}
        required
        value={email}
      />

      <InputHolder
        label="Password"
        name="password"
        onChange={handleInputs}
        onClick={handleClearErrorMessages}
        required
        type="password"
        value={password}
      />

      <InputHolder
        label="Repeat password"
        name="repeatPassword"
        onChange={handleInputs}
        onClick={handleClearErrorMessages}
        required
        type="password"
        value={repeatPassword}
      />

      <div className="d-flex align-items-center justify-content-center">
        <input
          className="mr-2 my-0"
          name="agree"
          onChange={handleCheck}
          type="checkbox"
        />
        <label className="mb-0" htmlFor="agree">
          Agree to our
          <Link to="/terms">
            <strong> Terms & Conditions</strong>
          </Link>
        </label>
      </div>

      <button
        className="button--outline"
        onClick={handleSubmit}
        style={{
          margin: '1em auto',
        }}
        type="button"
      >
        <span>{token ? 'ACCEPT INVITAION' : 'SIGN UP'}</span>
      </button>
    </form>
  );
}

Registration.propTypes = {
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleClearErrorMessages: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lastname: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  repeatPassword: PropTypes.string.isRequired,
  token: PropTypes.string,
};

Registration.defaultProps = {
  token: '',
};

export default Registration;
