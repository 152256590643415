/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { createCustomEqual } from 'fast-equals';

const isLatLngLiteral = (obj: any) => {
  return obj != null && typeof obj === 'object' && Number.isFinite(obj.lat) && Number.isFinite(obj.lng);
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a: any, b: any) => {
  if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
  }

  // use fast-equals for other objects
  return deepEqual(a, b);
});

export { deepCompareEqualsForMaps, isLatLngLiteral };
