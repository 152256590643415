/* eslint-disable react/boolean-prop-naming */
import React from 'react';

import PropTypes from 'prop-types';

import { InputDefault } from './styled';

function InputHolder(props) {
  const {
    appearence,
    isDisabled,
    label,
    name,
    value,
    onChange,
    onClick,
    required,
    type,
  } = props;

  return (
    <>
      {/* eslint-disable-next-line */}
      <label className="text-left" htmlFor={name} id={name}>
        {label}
        <span style={{ color: '#E15169' }}>{required ? '*' : ''}</span>
        <br />
        <InputDefault
          appearence={appearence}
          aria-label={label}
          className="w-100 inputHolder"
          disabled={isDisabled}
          name={name}
          onChange={onChange}
          onClick={onClick}
          required=""
          type={type}
          value={value}
        />
      </label>
    </>
  );
}

InputHolder.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  appearence: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
};

InputHolder.defaultProps = {
  appearence: 'default',
  isDisabled: false,
  onClick: () => {},
  required: false,
  type: null,
};

export default InputHolder;
