import React from 'react';

import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

export const UPDATE_USER = gql`
  mutation UpdateUser($firstname: String!, $lastname: String!, $secondaryEmail: String!) {
    user {
      updateUser(firstname: $firstname, lastname: $lastname, secondaryEmail: $secondaryEmail)
    }
  }
`;

const updateUserRequest = ({ id, firstname, lastname, secondaryEmail, mobilePhone, onError, onSuccessResult }) => (
  <Mutation client={Client()} mutation={UPDATE_USER}>
    {(updateUser, { loading, error, data }) => {
      const variables = {
        id,
        firstname,
        lastname,
        secondaryEmail,
        mobilePhone,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        onSuccessResult();
      }

      return <MutationRequest isLoading={loading} mutation={updateUser} variables={variables} />;
    }}
  </Mutation>
);

updateUserRequest.propTypes = {
  firstname: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  lastname: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  mobilePhone: PropTypes.string,
  secondaryEmail: PropTypes.string,
};

updateUserRequest.defaultProps = {
  mobilePhone: null,
  secondaryEmail: null,
};

export default updateUserRequest;
