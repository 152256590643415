import React from 'react';

import settingsIcon from '../../../../static/img/settings-icon.svg';
import ImageIcon from '../../../Icon/ImageIcon';

import './roundSettingsButton.css';

const RoundSettingsButton = ({ onClick }) => (
  <button className="s-button-container" type="button" onClick={onClick}>
    <ImageIcon src={settingsIcon} />
  </button>
);

export default RoundSettingsButton;
