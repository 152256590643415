export const getEventValue = (event) => {
  const {
    target: { value },
  } = event;

  return value;
};

export const getEventChecked = (event) => {
  const {
    target: { checked },
  } = event;

  return checked;
};

export const getCharacterCount = (titleMaxCharacter, value) => titleMaxCharacter - value.length;
