import React, { useContext } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { CardBox } from '../ProjectConsolePage/ProjectConsolePage';

import MenuCard from '../../components/UI/Card/MenuCard';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import AppContext from '../../context/AppContext';
import SocialPageState from '../../context/SocialPage/SocialPageState';
import moderationIcon from '../../static/img/moderation.svg';
import topicIcon from '../../static/img/topic-icon.svg';
import { getBreadCrumbPath } from '../../utils/utils';

const SocialPage = () => {
  const { project } = useContext(AppContext);
  const { pathname } = useLocation();
  const params = useParams();

  const items = [
    {
      image: moderationIcon,
      key: 'moderation',
      path: 'moderation',
      title: 'Moderation',
      visible: true,
    },
    {
      image: topicIcon,
      key: 'topics',
      path: 'topics',
      title: 'Topics',
      visible: project?.features?.includes('HAS_BONOBO_TOPIC'),
    },
  ];

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 0, 'Social')];

  return (
    <SocialPageState>
      <PageWrapper
        breadPath={breadCrumbPath}
        info="Manage social content"
        pageDescription="Manage social content"
        pageTitle="Social media"
        params={params}
      >
        <CardBox>
          {items
            .filter((r) => r.visible)
            .map((featureCard) => {
              const { image, key, path, title } = featureCard;
              return <MenuCard key={`feature-card-${key}`} href={`${pathname}/${path}`} name={title} src={image} />;
            })}
        </CardBox>
      </PageWrapper>
    </SocialPageState>
  );
};

export default SocialPage;
