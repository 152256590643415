import { Client } from '@netfront/gelada-react-shared';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from 'react-apollo';
import QueryRequest from '../QueryRequest';

export const GET_ALL_PROJECTS = gql`
  query($id: Int!) {
    project {
      getProjectsForConnectedUser(organisationId: $id) {
        guid
        hasMobileApplication
        id
        logo {
          contentType
          s3Key
        }
        url
        description
        name
        services
        theme {
          backGroundColor
          buttonColor
          footerColor
          linkColor
          headerColor
          sideColor
        }
      }
    }
  }
`;

const getAllProjects = ({ id, onError, onSuccessResult }) => (
  <Query client={Client()} fetchPolicy="network-only" query={GET_ALL_PROJECTS} variables={{ id }}>
    {({ loading, error, data }) => (
      <QueryRequest
        data={data && Object.entries(data).length !== 0 && data.project.getProjectsForConnectedUser}
        error={error}
        isLoading={loading}
        onError={onError}
        onSuccessResult={onSuccessResult}
      />
    )}
  </Query>
);

getAllProjects.propTypes = {
  id: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
};

export default getAllProjects;
