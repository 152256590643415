import { gql } from '@apollo/client';

const GET_USER_FLOW_STEP_TRACKS_WITH_ANSWERS_QUERY = gql`
  query getUserFlowStepTracksWithAnswers($clusterIds: [Int], $projectId: String!, $userFlowUrl: String!, $userFlowStepUrls: [String]) {
    userFlowStepTrack {
      getUserFlowStepTracksWithAnswers: getTrackings(
        projectId: $projectId
        userFlowUrl: $userFlowUrl
        userFlowStepUrls: $userFlowStepUrls
      ) {
        answers {
          contentSnippetQuestion {
            id
            majorNumber
            questionText: question
          }
          id
          ... on QuestionAnswerCalendarType {
            dateAnswered
          }
          ... on QuestionAnswerCheckboxType {
            selectedAnswers {
              questionResponse {
                label
                scoreValue
              }
              questionResponseCheckboxId
            }
          }
          ... on QuestionAnswerDropDownListType {
            questionResponse {
              label
              scoreValue
            }
            questionResponseDropDownListId
          }
          ... on QuestionAnswerMultilineTextType {
            text
          }
          ... on QuestionAnswerMultiResponseTextType {
            responses {
              value
            }
          }
          ... on QuestionAnswerNumberType {
            numberAnswered
          }
          ... on QuestionAnswerRadioType {
            questionResponse {
              label
              scoreValue
            }
            questionResponseRadioId
          }
          ... on QuestionAnswerSinglelineTextType {
            text
          }
          ... on QuestionAnswerSliderType {
            value
          }
        }
        clustersResult(clusterIds: $clusterIds) {
          clusterId
          description
          result
        }
        completedLongDate
        id
        isCompleted
        userFlowStep {
          id
          url
        }
      }
    }
  }
`;

export { GET_USER_FLOW_STEP_TRACKS_WITH_ANSWERS_QUERY };
