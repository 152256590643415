const getQuestionText = (html) => {
  const document = new DOMParser().parseFromString(html, 'application/xml');
  const parserError = document.querySelector('parsererror');

  if (parserError) {
    return parserError.textContent;
  }

  return document.documentElement.textContent;
};

export { getQuestionText };
