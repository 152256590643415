/* eslint-disable react/boolean-prop-naming */
import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import MutationRequest from '../MutationRequest';

export const CREATE_CONTENT_EVENT = gql`
  mutation createEvent(
    $contentEntity: EContentEntity!
    $entityId: Int!
    $eventTitle: String
    $event: EContentEventType
    $eventDirection: EDirection
    $animation: EAnimation
    $animationDirection: EDirection
    $millisecondDelay: Int
    $millisecondSpeed: Int
    $infinite: Boolean
    $assetId: String
    $backgroundColor: String
    $borderColor: String
    $titleColor: String
    $style: EContentEventStyle
    $triggerStyle: EContentEventTriggerStyle
    $iconHorizontalAlignment: EHorizontalAlignment
  ) {
    contentEvent {
      createEvent(
        contentEvent: {
          contentEntity: $contentEntity
          entityId: $entityId
          eventTitle: $eventTitle
          event: $event
          eventDirection: $eventDirection
          animation: $animation
          animationDirection: $animationDirection
          millisecondDelay: $millisecondDelay
          millisecondSpeed: $millisecondSpeed
          infinite: $infinite
          assetId: $assetId
          backgroundColor: $backgroundColor
          borderColor: $borderColor
          titleColor: $titleColor
          style: $style
          triggerStyle: $triggerStyle
          iconHorizontalAlignment: $iconHorizontalAlignment
        }
      ) {
        id
        eventTitle
        event
        animation
      }
    }
  }
`;

const createContentEventRequest = ({
  animation,
  animationDirection,
  assetId,
  backgroundColor,
  borderColor,
  contentEntity,
  entityId,
  eventTitle,
  event,
  eventDirection,
  iconHorizontalAlignment,
  infinite,
  millisecondDelay,
  millisecondSpeed,
  onError,
  onSuccessResult,
  style,
  titleColor,
  triggerStyle,
}) => (
  <Mutation mutation={CREATE_CONTENT_EVENT}>
    {(createContentEvent, { loading, error, data }) => {
      const variables = {
        contentEntity,
        entityId,
        eventTitle,
        event,
        eventDirection,
        animation,
        animationDirection,
        millisecondDelay,
        millisecondSpeed,
        infinite,
        assetId,
        backgroundColor,
        borderColor,
        titleColor,
        style,
        triggerStyle,
        iconHorizontalAlignment,
      };

      if (error) {
        if (error.networkError.result) {
          onError(error.networkError);
        } else {
          onError(error.message);
        }
      }
      if (data) {
        onSuccessResult(data.contentEvent.createEvent);
      }

      return <MutationRequest isLoading={loading} mutation={createContentEvent} variables={variables} />;
    }}
  </Mutation>
);

createContentEventRequest.propTypes = {
  contentEntity: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired,
  animation: PropTypes.string,
  animationDirection: PropTypes.string,
  assetId: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  event: PropTypes.string,
  eventDirection: PropTypes.string,
  eventTitle: PropTypes.string,
  iconHorizontalAlignment: PropTypes.string,
  infinite: PropTypes.bool,
  millisecondDelay: PropTypes.number,
  millisecondSpeed: PropTypes.number,
  titleColor: PropTypes.string,
  triggerStyle: PropTypes.string,
};

createContentEventRequest.defaultProps = {
  animation: '',
  animationDirection: '',
  assetId: null,
  backgroundColor: null,
  borderColor: null,
  event: '',
  eventDirection: '',
  eventTitle: '',
  infinite: false,
  millisecondDelay: null,
  millisecondSpeed: null,
  titleColor: '',
  triggerStyle: null,
};

export default createContentEventRequest;
