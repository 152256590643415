/* eslint-disable no-underscore-dangle */
import React, { Fragment } from 'react';

import { makeComponentMap } from '@netfront/common-library';

import { SummaryFeedbackConfigurationMatchItem } from './SummaryFeedbackConfigurationMatchItem';
import { SummaryFeedbackConfigurationMultiResponseText } from './SummaryFeedbackConfigurationMultiResponseText';
import { SummarySnippetProps } from './SummarySnippet.interfaces';

import { QuestionSnippet } from '..';
import { getContentSnippet, getContentSnippetFormId, useContentPageContext } from '../../../contexts';

const getComponentByType = makeComponentMap({
  SummaryFeedbackConfigurationMultiResponseTextType: SummaryFeedbackConfigurationMultiResponseText,
  SummaryFeedbackConfigurationRankAndNoteType: SummaryFeedbackConfigurationMatchItem,
  SummaryFeedbackConfigurationRangeType: QuestionSnippet,
});

const SummarySnippet = ({
  accessToken,
  contentSnippetId,
  summaryFeedbackConfiguration,
  summaryFeedbackMode,
  userFlowStepTrackId,
}: SummarySnippetProps) => {
  const { state: contentPage } = useContentPageContext();

  const contentSnippet = getContentSnippet(contentSnippetId, contentPage);
  const contentSnippetFormId = getContentSnippetFormId(contentSnippetId, contentPage);

  const { contentSnippetQuestion } = contentSnippet ?? {};
  const { answer, configuration } = contentSnippetQuestion ?? {};
  const { answers = [], id: questionAnswerId, summaryFeedback } = answer ?? {};
  const { responseSet } = configuration ?? {};
  const { availableResponses = [] } = responseSet ?? {};

  const Component = summaryFeedbackConfiguration ? getComponentByType(summaryFeedbackConfiguration.__typename) : QuestionSnippet;

  return (
    <Fragment>
      {contentPage && contentSnippetQuestion && (
        <div className="c-summary-snippet">
          <Component
            accessToken={accessToken}
            answers={answers}
            availableResponses={availableResponses}
            contentPageId={contentPage.id}
            contentSnippetFormId={contentSnippetFormId}
            contentSnippetQuestion={contentSnippetQuestion}
            contentSnippetQuestionId={contentSnippetQuestion.id}
            questionAnswerId={questionAnswerId}
            summaryFeedback={summaryFeedback}
            summaryFeedbackConfiguration={summaryFeedbackConfiguration}
            summaryFeedbackMode={summaryFeedbackMode}
            userFlowStepTrackId={userFlowStepTrackId}
          />
        </div>
      )}
    </Fragment>
  );
};

export { SummarySnippet };
