import React, { useContext } from 'react';

import { userTableHeadings, pendingStatusHeadings } from './UserTable.constants';
import { createUserTableData } from './UserTable.helpers';

import { EKReactTable } from '../../../../components/Table/EKReactTable/EKReactTable';
import AppContext from '../../../../context/AppContext';

const UserTable = ({ data, onDeletePendingInvitation, onEdit, onResend, status }) => {
  const isPending = status === 'Pending';

  const {
    project: { services },
  } = useContext(AppContext);

  return (
    <EKReactTable
      columns={
        isPending
          ? pendingStatusHeadings.filter((r) => services.includes(r.service))
          : userTableHeadings.filter((r) => services.includes(r.service))
      }
      data={createUserTableData(data, onDeletePendingInvitation, onEdit, onResend)}
    />
  );
};

export default UserTable;
