import React, { useContext } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useCookie } from '@netfront/common-library';
import { useHistory } from 'react-router-dom';
import { Thead, Tbody, Tr, Th } from 'react-super-responsive-table';

import { ContainerScroll, TableContainer, EditButton, FirstTd } from '../OrganisationUsersPage/styled';

import { LoadingRows, NoDataBox, NotificationTd } from './styled';

import NoDataAlert from '../../components/UI/NoDataComp/NoDataAlert';
import AppContext from '../../context/AppContext';
import howlerClient from '../../middleware/_Howler/howlerClient';
import { GET_TEMPLATES } from '../../middleware/_Howler/Queries/GetTemplates';
import { capitalize } from '../../utils/utils';

const TemplateTable = () => {
  const { org, project } = useContext(AppContext);
  const { getAccessTokenCookie } = useCookie();
  const history = useHistory();

  const projectId = project.id;

  const { data, loading, error } = useQuery(GET_TEMPLATES, {
    client: howlerClient,
    context: {
      headers: {
        authorization: getAccessTokenCookie(),
      },
    },
    fetchPolicy: 'network-only',
    skip: project.id === undefined,
    variables: {
      projectId,
    },
  });

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <ContainerScroll>
      <TableContainer>
        <Thead>
          <Tr key="table-head">
            <Th data-sort="firstname" scope="col" style={{ paddingLeft: '2.3em' }}>
              Title
            </Th>
            <Th data-sort="lastname" scope="col">
              Type
            </Th>
            <Th data-sort="lastname" scope="col">
              Usage
            </Th>
            <Th scope="col" />
          </Tr>
        </Thead>
        <Tbody>
          {!data && <LoadingRows />}
          {!loading &&
            data &&
            data.project.getTemplates.map((template) => (
              <Tr key={template.id}>
                <FirstTd>
                  <p style={{ marginLeft: '1em' }}>{template.name}</p>
                </FirstTd>
                <NotificationTd>{template.type}</NotificationTd>
                <NotificationTd>
                  {template.type === 'EMAIL' && capitalize(template.usage[0]?.replaceAll('_', ' '))}
                  {template.type === 'SMS' && capitalize(template.smsUsage[0]?.replaceAll('_', ' '))}
                </NotificationTd>
                <NotificationTd>
                  <EditButton
                    onClick={() => {
                      history.replace({
                        pathname: `/dashboard/${org.key}/project/${project.id}/create-notification`,
                        state: { template },
                      });
                    }}
                  >
                    ···
                  </EditButton>
                </NotificationTd>
              </Tr>
            ))}
        </Tbody>
      </TableContainer>
      {!loading && data && data.project.getTemplates.length === 0 && (
        <NoDataBox>
          <NoDataAlert />
        </NoDataBox>
      )}
    </ContainerScroll>
  );
};
export default TemplateTable;
