import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/react-hooks';
import { addDays, addMonths } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';

import { PushRight, SearchContainer } from '../OrganisationUsersPage/styled';
import { StyledTableFilterContainer } from '../styles';

import DiscountTable from './DiscountsTable';

import Button from '../../components/Buttons/Button';
import ProjectNavBar from '../../components/LeftNavBar/ProjectNavBar';
import DiscountSideBarEditor from '../../components/RightNavBar/DiscountSideBarEditor';
import SearchInput from '../../components/SearchInput/SearchInput';
import Backdrop from '../../components/UI/Backdrop/Backdrop';
import PopUpMsg from '../../components/UI/PopUp/PopUpMsg';
import PageWrapper from '../../containers/PageWrapper/PageWrapper';
import { GET_ALL_PROJECT_DISCOUNTS } from '../../middleware/_Vervet/Queries/GetAllProjectDiscounts';
import vervetClient from '../../middleware/vervetClient';
import { getBreadCrumbPath, getDateWithoutTime, getFilteredResults } from '../../utils/utils';


export const DISCOUNTS_PAGE_ACTION_COPY = 'copy';
export const DISCOUNTS_PAGE_ACTION_CREATE = 'create';
export const DISCOUNTS_PAGE_ACTION_EDIT = 'edit';

const DiscountsPageActions = {
  [DISCOUNTS_PAGE_ACTION_COPY]: {
    titlePrefix: 'Copy',
    value: 'copy',
    verb: 'copying',
  },
  [DISCOUNTS_PAGE_ACTION_CREATE]: {
    titlePrefix: 'Create',
    value: 'create',
    verb: 'creating',
  },
  [DISCOUNTS_PAGE_ACTION_EDIT]: {
    titlePrefix: 'Edit',
    value: 'edit',
    verb: 'editing',
  },
};

function DiscountsPage() {
  const { pathname } = useLocation();
  const params = useParams();

  const { projectId: projectGuid } = params;

  const [action, setAction] = useState(undefined);
  const [allDiscounts, setAllDiscounts] = useState(undefined);
  const [filteredDiscounts, setFilteredDiscounts] = useState(undefined);
  const [isDiscountSideBarEditorOpen, setIsDiscountSideBarEditorOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedDiscount, setSelectedDiscount] = useState(undefined);

  const [executeGetAllProjectDiscounts, { error, loading }] = useLazyQuery(GET_ALL_PROJECT_DISCOUNTS, {
    client: vervetClient,
    onCompleted: data => {
      const {
        discounts: { getAllProjectDiscounts: discounts },
      } = data;

      setAllDiscounts(discounts);
      setFilteredDiscounts(discounts);
    },
    onError: error => {
      console.error(error.message);
      setMessage('Something went wrong! Please try again later.');
    },
  });

  function handleCloseDiscountSideBarEditor() {
    setIsDiscountSideBarEditorOpen(false);
    setSelectedDiscount(undefined);
  }

  function handleCreateDiscount() {
    const startsAtUtc = addDays(new Date(), 1);
    const expiresAtUtc = addMonths(startsAtUtc, 1);

    const newDiscount = {
      maxUsage: 0,
      percentage: 0,
      expiresAtUtc: getDateWithoutTime(expiresAtUtc),
      startsAtUtc: getDateWithoutTime(startsAtUtc),
    };

    setAction(DiscountsPageActions[DISCOUNTS_PAGE_ACTION_CREATE]);
    setIsDiscountSideBarEditorOpen(true);
    setSelectedDiscount(newDiscount);
  }

  function handleCopyDiscount(discount) {
    const { code, description } = discount;

    const startsAtUtc = addDays(new Date(), 1);
    const expiresAtUtc = addMonths(startsAtUtc, 1);

    const copiedDiscount = {
      ...discount,
      code: `${code}-COPY`,
      description: `Copy of ${description}`,
      expiresAtUtc: getDateWithoutTime(expiresAtUtc),
      startsAtUtc: getDateWithoutTime(startsAtUtc),
    };

    setAction(DiscountsPageActions[DISCOUNTS_PAGE_ACTION_COPY]);
    setIsDiscountSideBarEditorOpen(true);
    setSelectedDiscount(copiedDiscount);
  }

  function handleEditDiscount(discount) {
    setAction(DiscountsPageActions[DISCOUNTS_PAGE_ACTION_EDIT]);
    setIsDiscountSideBarEditorOpen(true);
    setSelectedDiscount(discount);
  }

  function handleMsgDisappear() {
    setTimeout(() => {
      setMessage('');
    }, 500);
  }

  function handleOnChange(discounts) {
    setAllDiscounts(discounts);
    setIsDiscountSideBarEditorOpen(false);
    setFilteredDiscounts(discounts);
    setSelectedDiscount(undefined);
  }

  function handleSearchFilter(event, discounts) {
    const {
      target: { value },
    } = event;

    const keys = ['code', 'description'];
    const filteredDiscounts = getFilteredResults(discounts, keys, value);

    setFilteredDiscounts(filteredDiscounts);
  }

  useEffect(() => {
    if (!projectGuid) {
      return;
    }

    executeGetAllProjectDiscounts({
      variables: {
        projectGuid,
      },
    });
  }, [projectGuid]);

  const breadCrumbPath = [...getBreadCrumbPath(pathname, 1, 'Subscriptions'), ...getBreadCrumbPath(pathname, 0, 'Discounts')];
  const isLoaderVisible = (loading || !allDiscounts) && !error;
  const pageDescription = 'Manage discount codes to apply to orders';

  return (
    <PageWrapper
      breadPath={breadCrumbPath}
      info={pageDescription}
      isLoaderVisible={isLoaderVisible}
      pageDescription={pageDescription}
      pageTitle="Discounts page"
      params={params}
    >
      <ProjectNavBar title="Discounts" />
      {isDiscountSideBarEditorOpen && (
        <>
          <DiscountSideBarEditor
            action={action}
            allDiscounts={allDiscounts}
            discount={selectedDiscount}
            onCancel={handleCloseDiscountSideBarEditor}
            onChange={handleOnChange}
          />
          <Backdrop isClicked={handleCloseDiscountSideBarEditor} />
        </>
      )}
      {allDiscounts && (
        <>
          <StyledTableFilterContainer>
            <SearchContainer minWidth="30%">
              <SearchInput placeholder="Filter by title or discount code" onChange={event => handleSearchFilter(event, allDiscounts)} />
            </SearchContainer>
            <PushRight>
              <Button isMarginUsed={false} onClick={() => handleCreateDiscount()}>
                New discount code
              </Button>
            </PushRight>
          </StyledTableFilterContainer>

          <DiscountTable discounts={filteredDiscounts} onEdit={handleEditDiscount} onCopy={handleCopyDiscount} />
        </>
      )}
      <PopUpMsg message={message} msgDisappear={handleMsgDisappear} />
    </PageWrapper>
  );
}

export default DiscountsPage;
