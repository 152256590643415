import React from 'react';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import Cookies from 'universal-cookie';

import GeladaClient from '../_Gelada/geladaClient';
import ErrorModal from '../ErrorModal';
import MutationRequest from '../MutationRequest';

import ActivateEkardoAccountRequest from './activateEkardoAccountRequest';

export const ACCEPT_INVITATION_REQUEST = gql`
  mutation acceptInvitationRequest(
    $tokenId: String!
    $firstName: String
    $lastName: String
    $password: String
  ) {
    acceptInvitation(
      acceptInvitation: {
        tokenId: $tokenId
        firstName: $firstName
        lastName: $lastName
        password: $password
      }
    ) {
      token
      refreshToken
    }
  }
`;

const acceptInvitationRequest = ({
  tokenId,
  firstName,
  lastName,
  password,
  onError,
  onSuccessResult,
}) => (
  <Mutation client={GeladaClient(false)} mutation={ACCEPT_INVITATION_REQUEST}>
    {(createAppVersion, { loading, error, data }) => {
      const variables = {
        tokenId,
        firstName,
        lastName,
        password,
      };

      if (error) {
        return <ErrorModal error={error} onError={onError} />;
      }
      if (data) {
        const cookies = new Cookies();
        cookies.set('_a', data.acceptInvitation.token);
        cookies.set('REFRESH_TOKEN', data.acceptInvitation.refreshToken);
        onSuccessResult(data.acceptInvitation);
        return <ActivateEkardoAccountRequest />;
      }

      return (
        <MutationRequest
          isLoading={loading}
          mutation={createAppVersion}
          variables={variables}
        />
      );
    }}
  </Mutation>
);

acceptInvitationRequest.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccessResult: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  tokenId: PropTypes.string.isRequired,
};

acceptInvitationRequest.defaultProps = {};

export default acceptInvitationRequest;
