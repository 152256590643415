import gql from 'graphql-tag';

export const GET_SHAPE_TYPES = gql`
  query getShapes {
    __type(name: "EBubbleShape") {
      enumValues {
        name
      }
    }
  }
`;
