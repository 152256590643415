import { gql } from '@apollo/client';

const GET_LATEST_USER_FLOW_STEP_TRACKING_QUERY = gql`
  query getLatestUserFlowStepTracking($userFlowStepId: Int!) {
    userFlowStepTrack {
      getLatestUserFlowStepTracking: latestTracking(userFlowStepId: $userFlowStepId) {
        completed
        completedLongDate
        configuration
        created
        createdLongDate
        id
        instance
        instanceDate
        isCompleted
        isMarked
        started
        startedLongDate
        userFlowStepId
        userId
      }
    }
  }
`;

export { GET_LATEST_USER_FLOW_STEP_TRACKING_QUERY };
