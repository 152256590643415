import React from 'react';

const QuizButton = ({ className, identifier, onClick, text }) => (
  <button className={`c-quiz-button ${className}`} color="tertiary" type="button" onClick={onClick}>
    <div className="c-quiz-button__index">{identifier}</div>
    <span
      className="c-quiz-button__text"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  </button>
);

export default QuizButton;
