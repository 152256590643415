import gql from 'graphql-tag';

export const GET_SNIPPET_LOCATION = gql`
  query getSnippetLocation($contentGroupId: Int!) {
    contentSnippet {
      getSnippetForms(contentGroupId: $contentGroupId) {
        id
        contentPage {
          title
          id
          contentGroup {
            title
          }
        }
      }
    }
  }
`;
