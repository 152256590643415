const DIRECTORY_STATUS_OPTIONS = [
  {
    id: 0,
    name: 'PENDING',
    value: 'PENDING',
  },
  {
    id: 1,
    name: 'PUBLISHED',
    value: 'PUBLISHED',
  },
  {
    id: 2,
    name: 'UNPUBLISHED',
    value: 'UNPUBLISHED',
  },
];

export { DIRECTORY_STATUS_OPTIONS };
